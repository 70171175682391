import React from "react";
import PropTypes from "prop-types";
import { Header, Button } from "semantic-ui-react";
import ReactModal from "react-modal";
import { MotifButton } from "@ey-xd/motif-react";

const DuplicateAccountsEditModal = ({
    showDuplicateAccountsEditModal,
    closeDuplicateAccountsEditModal,
    clientAccountCodeList
}) => {
    return (
        <ReactModal contentLabel="Duplicate Notification" isOpen={showDuplicateAccountsEditModal} className="react-modal react-modal-small" overlayClassName="react-modal-overlay">
            <Header content="Cannot Save" />
            <form>
                <h6>Your changes cannot be saved. The following Client Account Number(s) already exist in this chart:</h6>
                <br />
                <ul>
                    {clientAccountCodeList.map(code => <li key={code}>{code}</li>)}
                </ul>
                <br />                
                <MotifButton variant="primary" className="right" type="button"
                    onClick={closeDuplicateAccountsEditModal}>
                    Close
                </MotifButton>
            </form>
        </ReactModal>
    );
};

DuplicateAccountsEditModal.propTypes = {
    showDuplicateAccountsEditModal: PropTypes.bool,
    closeDuplicateAccountsEditModal: PropTypes.func,
    clientAccountCodeList: PropTypes.array
};

export default DuplicateAccountsEditModal;