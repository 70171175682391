import React from "react";
import PropTypes from "prop-types";
import EditSecuritiesSnapshotRow from "./EditSecuritiesSnapshotRow";

const editSecuritiesSnapshotTable = ({ securitiesSnapshots, showForm, handleEditButtonClick, handleDeleteButtonClick, canEdit }) => {
    return (
        <section className="table-responsive rtable">
            <table>
                <thead>
                <tr>
                    <th>Security</th>
                    <th>Issuer</th>
                    <th>Class</th>
                    <th className="tright">REIT Owned Shares Value</th>
                    <th className="tright">All Shares from Issuer Value</th>
                    <th className="tright">Percent of Vote</th>
                    <th />
                </tr>
                </thead>
                <tbody>
                {securitiesSnapshots.map(
                        securitiesSnapshot => <EditSecuritiesSnapshotRow
                            key={securitiesSnapshot.securitiesSnapshotID}
                            securitiesSnapshot={securitiesSnapshot}
                            showForm={showForm}
                            handleEditButtonClick={handleEditButtonClick}
                            handleDeleteButtonClick={handleDeleteButtonClick}
                            canEdit={canEdit}/>)}
                </tbody>
            </table>
        </section>
    );
};

editSecuritiesSnapshotTable.propTypes = {
    securitiesSnapshots: PropTypes.arrayOf(PropTypes.shape({
        allSharesFromIssuerValue: PropTypes.number,
        class: PropTypes.string,
        securitiesSnapshotID: PropTypes.number,
        issuer: PropTypes.string,
        securityName: PropTypes.string,
        reitOwnedSharesValue: PropTypes.number,
        percentOfVote: PropTypes.number,
        reportPeriodID: PropTypes.number,
    })),
    showForm: PropTypes.bool,
    handleEditButtonClick: PropTypes.func,
    handleDeleteButtonClick: PropTypes.func,
    canEdit: PropTypes.bool
};

export default editSecuritiesSnapshotTable;