import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';

import {
  MotifButton,
  MotifDropdown,
  MotifDropdownItem,
  MotifFormField,
  MotifIcon,
  MotifIconButton,
  MotifLabel,
  MotifOption,
  MotifSelect,
  MotifTooltip,
} from '@ey-xd/motif-react';
import {
  actionIcInfo24px,
  navigationIcArrowDropDown24px,
} from '@ey-xd/motif-react/assets/icons';

import { addAttribute } from '../../common/Attribute';

const ManageExternalChecklistHeader = ({
  clients,
  selectedClient,
  handleClientChange,
  calendarYears,
  handleCalendarYearChange,
  selectedChecklists,
  toggleShowDownloadChecklistsModal,
  toggleShowRequestToReopenModal,
  toggleShowSubmitForEYReviewModal,
  toggleShowClientSignoffModal,
  toggleShowClientUnsignoffModal
}) => {
  const [actionOptionsOpen, setActionOptionsOpen] = useState(false);
  useEffect(() => {
    //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
    addAttribute("button[role = 'combobox']", 'type', 'button');
  });

  let calendarYearsOptions = [];
  let disableCalendarYearDropdown = false;
  let preselectedCalendarYear = '';

  const clientsOptions = clients.map((c) => {
    return { key: c.id, value: c.name, text: c.name };
  });

  if (calendarYears.length > 0) {
    calendarYearsOptions = calendarYears.map((cy) => {
      return { key: cy, value: cy.toString(), text: cy.toString() };
    });

    preselectedCalendarYear = calendarYears.includes(new Date().getFullYear())
      ? parseInt(new Date().getFullYear()).toString()
      : calendarYears[0].toString();
  }

  // Section for download checklists
  let downloadChecklistsActionStatus = true;
  let downloadChecklistsToolTipMessage = '';
  let submitForReviewStatus = true;
  let submitForReviewToolTipMessage = "";
  const checklistStatusForReview = ["Created", "Sent", "InProgress"];
  if (selectedChecklists && selectedChecklists.length >= 1) {
    if (selectedChecklists.length > 100) {
      downloadChecklistsActionStatus = false;
      downloadChecklistsToolTipMessage =
        'Please select 100 or less items to download.';
    }
    if (
      selectedChecklists.find(
        (f) => !checklistStatusForReview.includes(f.checklistState)
      )
    ) {
      submitForReviewStatus = false;
      submitForReviewToolTipMessage =
        "Please make sure all selected checklists should be either in Created,Sent or In Progress state.";
    } else if (selectedChecklists.find(c => !c.canExternalUserSubmitChecklistForEYReview)) {
        submitForReviewStatus = false;
        submitForReviewToolTipMessage =
          "Please make sure to complete the checklist in order to submit for review.";
    }
  } else {
    downloadChecklistsActionStatus = false;
    downloadChecklistsToolTipMessage =
      'Please select at least 1 checklist to download.';
    submitForReviewStatus = false;
    submitForReviewToolTipMessage =
        "Please select at least 1 checklist for EY review";
    }

  let signoffActionStatus = true;
  let signoffToolTipMessage = '';
  if (!selectedChecklists || !selectedChecklists.length > 0) {
      signoffActionStatus = false;
      signoffToolTipMessage = 'Please select at least 1 checklist to sign off.';
  }
  else if (selectedChecklists && selectedChecklists.length > 0) {
      // Check if there is any selected checklist is already signed off
      if (selectedChecklists.some(record => record.isSignedOff)) {
          signoffActionStatus = false;
          signoffToolTipMessage = "Selected checklist(s) is signed off by the client.";
      }
    }
  let unsignoffActionStatus = true;
  let unsignoffToolTipMessage = '';
  if (!selectedChecklists || !selectedChecklists.length > 0) {
      unsignoffActionStatus = false;
      unsignoffToolTipMessage = 'Please select at least 1 checklist to unsign off.';
  }
  else if (selectedChecklists && selectedChecklists.length > 0) {
      // Check if there is any selected checklist is not signed off
      if (selectedChecklists.some(record => !record.isSignedOff)) {
          unsignoffActionStatus = false;
          unsignoffToolTipMessage = "Selected checklist(s) is not signed off by the client.";
      }
  }
      
  const checklistStatusList = ['completed', 'pendingeyreview'];
  let requestToReopenActionStatus = true;
  let requestToReopenToolTipMessage = '';
  
  if (!selectedChecklists || !selectedChecklists.length > 0) {
    requestToReopenActionStatus = false;
    requestToReopenToolTipMessage =
      'Please select at least 1 checklist to request to reopen.';
  }
  else if (selectedChecklists && selectedChecklists.length > 0) {
    // Check if there is any selected checklist status not in completed or pending ey review state
    if(selectedChecklists.some(record => !checklistStatusList.includes(record.checklistState.toLowerCase()))) {
      requestToReopenActionStatus = false;
      requestToReopenToolTipMessage = "Please make sure all selected checklists should be either in Completed or Pending EY Review state.";
  }
}

  return (
    <div>
      <div style={{ minHeight: '24px' }}></div>
      <form>
        <div className="row">
          <div className="col-md-2 pl-3">
            <MotifFormField>
              <MotifLabel data-testid="lblClientName">Client Name</MotifLabel>
              <MotifSelect
                name="clients"
                value={
                  clientsOptions.length > 1 ? null : clientsOptions[0].value
                }
                onChange={handleClientChange}
                data-testid="divClientName"
              >
                {clientsOptions.map((co) => (
                  <MotifOption
                    data-testvalue={co.text}
                    key={co.key}
                    value={co.value}
                  >
                    {co.text}
                  </MotifOption>
                ))}
              </MotifSelect>
            </MotifFormField>
          </div>
          {calendarYearsOptions.length > 0 && (
            <React.Fragment>
              <div className="col-md-2 pl-0">
                <MotifFormField>
                  <MotifLabel data-testid="lblCalenderYear">
                    Calendar Year
                  </MotifLabel>
                  <MotifSelect
                    name="calendarYears"
                    value={preselectedCalendarYear}
                    onChange={handleCalendarYearChange}
                    data-testid="divCalendarYear"
                    disabled={disableCalendarYearDropdown}
                  >
                    {calendarYearsOptions.map((cy) => (
                      <MotifOption
                        data-testvalue={cy.text}
                        key={cy.key}
                        value={cy.value}
                      >
                        {cy.value}
                      </MotifOption>
                    ))}
                  </MotifSelect>
                </MotifFormField>
              </div>
              <div className="col-md-6 pl-0"></div>
              <div className="col-md-2 d-flex justify-content-end" style={{ width: 'fit-content' }}>
                <MotifDropdown
                    open={actionOptionsOpen}
                    placement="bottom-right"
                    id={'action-dropdown'}
                    aria-labelledby={'manage-external-checklist-trigger'}
                    handleClickOutside={() => setActionOptionsOpen(false)}
                    trigger={
                        <MotifButton
                            type="button"
                            variant="primary-alt"
                            onClick={() => setActionOptionsOpen(!actionOptionsOpen)}
                            aria-controls={'action-dropdown'}
                            id={'action-dropdown-trigger'}
                            aria-expanded={actionOptionsOpen}
                            aria-label="Action dropdown"
                            data-testid="actions"
                        >
                            Actions <MotifIcon src={navigationIcArrowDropDown24px} />
                        </MotifButton>
                    }
                >
                            {(submitForReviewStatus && (
                                <MotifDropdownItem
                                    onClick={() => {
                                        setActionOptionsOpen(false);
                                        toggleShowSubmitForEYReviewModal();
                                    }}
                                    type="button"
                                    disabled={!submitForReviewStatus}
                                    data-testid="submitForEYReview"
                                >
                                    Submit for EY Review
                                </MotifDropdownItem>
                            )) || (
                                    <MotifTooltip
                                        placement="right"
                                        trigger={
                                            <MotifDropdownItem
                                                onclick={() => {
                                                    setActionOptionsOpen(false);
                                                    toggleShowSubmitForEYReviewModal();
                                                }}
                                                type="button"
                                                disabled={!submitForReviewStatus}
                                                data-testid="submitForEYReview"
                                            >
                                                Submit for EY Review
                                                <MotifIconButton
                                                    className="ml-2"
                                                    aria-label="Submit for EY Review"
                                                    type="button"
                                                >
                                                    <MotifIcon src={actionIcInfo24px} />
                                                </MotifIconButton>
                                            </MotifDropdownItem>
                                        }
                                    >
                                        {submitForReviewToolTipMessage}
                                    </MotifTooltip>
                                      )}
                                  {(signoffActionStatus && (
                                      <MotifDropdownItem
                                          onClick={() => {
                                              setActionOptionsOpen(false);
                                              toggleShowClientSignoffModal();
                                          }}
                                          type="button"
                                          disabled={!signoffActionStatus}
                                          data-testid="clientSignoff"
                                      >
                                          Client Sign Off
                                      </MotifDropdownItem>
                                  )) || (
                                          <MotifTooltip
                                              placement="right"
                                              trigger={
                                                  <MotifDropdownItem
                                                      onclick={() => {
                                                          setActionOptionsOpen(false);
                                                          toggleShowClientSignoffModal();
                                                      }}
                                                      type="button"
                                                      disabled={!signoffActionStatus}
                                                      data-testid="clientSignoff"
                                                  >
                                                      Client Sign Off
                                                      <MotifIconButton
                                                          className="ml-2"
                                                          aria-label="Client Sign Off"
                                                          type="button"
                                                      >
                                                          <MotifIcon src={actionIcInfo24px} />
                                                      </MotifIconButton>
                                                  </MotifDropdownItem>
                                              }
                                          >
                                          {signoffToolTipMessage}
                                          </MotifTooltip>
                                      )}

                                  {(unsignoffActionStatus && (
                                      <MotifDropdownItem
                                          onClick={() => {
                                              setActionOptionsOpen(false);
                                              toggleShowClientUnsignoffModal();
                                          }}
                                          type="button"
                                          disabled={!unsignoffActionStatus}
                                          data-testid="clientUnsignoff"
                                      >
                                          Client Unsign Off
                                      </MotifDropdownItem>
                                  )) || (
                                          <MotifTooltip
                                              placement="right"
                                              trigger={
                                                  <MotifDropdownItem
                                                      onclick={() => {
                                                          setActionOptionsOpen(false);
                                                          toggleShowClientUnsignoffModal();
                                                      }}
                                                      type="button"
                                                      disabled={!unsignoffActionStatus}
                                                      data-testid="clientUnsignoff"
                                                  >
                                                      Client Unsign Off
                                                      <MotifIconButton
                                                          className="ml-2"
                                                          aria-label="Client Unsign Off"
                                                          type="button"
                                                      >
                                                          <MotifIcon src={actionIcInfo24px} />
                                                      </MotifIconButton>
                                                  </MotifDropdownItem>
                                              }
                                          >
                                              {unsignoffToolTipMessage}
                                          </MotifTooltip>
                                      )}
                            {(requestToReopenActionStatus && (<MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowRequestToReopenModal(); }} type="button"
                                      disabled={!requestToReopenActionStatus} data-testid="requestToReopen">
                                      Request to Reopen
                                  </MotifDropdownItem>)) || (
                                          <MotifTooltip placement="right"
                                              trigger={
                                                  <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowRequestToReopenModal(); }} type="button"
                                                      disabled={!requestToReopenActionStatus} data-testid="requestToReopen">
                                                      Request to Reopen
                                                      <MotifIconButton className="ml-2" aria-label="Tooltip" type="button">
                                                          <MotifIcon src={actionIcInfo24px} />
                                                      </MotifIconButton>
                                                  </MotifDropdownItem>}
                                          >
                                              {requestToReopenToolTipMessage}
                                          </MotifTooltip>
                            )}
                            {/* Dropdown item for "Download - with Answers only */}
                                  {(downloadChecklistsActionStatus && (
                                      <MotifDropdownItem
                                          onClick={() => {
                                              setActionOptionsOpen(false);
                                              toggleShowDownloadChecklistsModal('WITH_ANSWERS');
                                          }}
                                          type="button"
                                          disabled={!downloadChecklistsActionStatus}
                                          data-testid="downloadWithAnswersOnly"
                                      >
                                          Download - with Answers Only
                                      </MotifDropdownItem>
                                  )) || (
                                          <MotifTooltip
                                              placement="right"
                                              trigger={
                                                  <MotifDropdownItem
                                                      onclick={() => {
                                                          setActionOptionsOpen(false);
                                                          toggleShowDownloadChecklistsModal('WITH_ANSWERS');
                                                      }}
                                                      type="button"
                                                      disabled={!downloadChecklistsActionStatus}
                                                      data-testid="downloadWithAnswersOnly"
                                                  >
                                                      Download - with Answers Only
                                                      <MotifIconButton
                                                          className="ml-2"
                                                          aria-label="Download - with Answers Only"
                                                          type="button"
                                                      >
                                                          <MotifIcon src={actionIcInfo24px} />
                                                      </MotifIconButton>
                                                  </MotifDropdownItem>
                                              }
                                          >
                                              {downloadChecklistsToolTipMessage}
                                          </MotifTooltip>
                            )}
                            {/* Dropdown item for "Download - with Answers and Comments" */}
                            {(downloadChecklistsActionStatus && (
                                    <MotifDropdownItem
                                        onClick={() => {
                                            setActionOptionsOpen(false);
                                            toggleShowDownloadChecklistsModal(
                                                'WITH_ANSWERS_COMMENTS'
                                            );
                                        }}
                                        type="button"
                                        disabled={!downloadChecklistsActionStatus}
                                        data-testid="downloadWithAnswersAndComments"
                                    >
                                        Download - with Answers and Comments
                                    </MotifDropdownItem>
                                )) || (
                                        <MotifTooltip
                                            placement="right"
                                            trigger={
                                                <MotifDropdownItem
                                                    onclick={() => {
                                                        setActionOptionsOpen(false);
                                                        toggleShowDownloadChecklistsModal(
                                                            'WITH_ANSWERS_COMMENTS'
                                                        );
                                                    }}
                                                    type="button"
                                                    disabled={!downloadChecklistsActionStatus}
                                                    data-testid="downloadWithAnswersAndComments"
                                                >
                                                    Download - with Answers and Comments
                                                    <MotifIconButton
                                                        className="ml-2"
                                                        aria-label="Download - with Answers and Comments"
                                                        type="button"
                                                    >
                                                        <MotifIcon src={actionIcInfo24px} />
                                                    </MotifIconButton>
                                                </MotifDropdownItem>
                                            }
                                        >
                                            {downloadChecklistsToolTipMessage}
                                        </MotifTooltip>
                            )}
                            {/* Dropdown items for "Download - without Answers or Comments" */}
                                  {(downloadChecklistsActionStatus && (
                                    <MotifDropdownItem
                                        onClick={() => {
                                            setActionOptionsOpen(false);
                                            toggleShowDownloadChecklistsModal('WITHOUT_ANSWERS');
                                        }}
                                        type="button"
                                        disabled={!downloadChecklistsActionStatus}
                                        data-testid="downloadWithoutAnswersOrComments"
                                    >
                                        Download - without Answers or Comments
                                    </MotifDropdownItem>
                                )) || (
                                        <MotifTooltip
                                            placement="right"
                                            trigger={
                                                <MotifDropdownItem
                                                    onclick={() => {
                                                        setActionOptionsOpen(false);
                                                        toggleShowDownloadChecklistsModal('WITHOUT_ANSWERS');
                                                    }}
                                                    type="button"
                                                    disabled={!downloadChecklistsActionStatus}
                                                    data-testid="downloadWithoutAnswersOrComments"
                                                >
                                                    Download - without Answers or Comments
                                                    <MotifIconButton
                                                        className="ml-2"
                                                        aria-label="Download - without Answers or Comments"
                                                        type="button"
                                                    >
                                                        <MotifIcon src={actionIcInfo24px} />
                                                    </MotifIconButton>
                                                </MotifDropdownItem>
                                            }
                                        >
                                            {downloadChecklistsToolTipMessage}
                                        </MotifTooltip>
                            )}                                             
                </MotifDropdown>
              </div>
            </React.Fragment>
          )}
          
        </div>
      </form>
    </div>
  );
};

ManageExternalChecklistHeader.propTypes = {
  clients: PropTypes.array,
  selectedClient: PropTypes.object,
  handleClientChange: PropTypes.func,
  calendarYears: PropTypes.array,
  handleCalendarYearChange: PropTypes.func,
  gridCurrentState: PropTypes.any,
  selectedChecklists: PropTypes.array,
  toggleShowDownloadChecklistsModal: PropTypes.bool,
  toggleShowRequestToReopenModal: PropTypes.func,
  toggleShowSubmitForEYReviewModal: PropTypes.func,
};

export default reduxForm({ form: 'manageExternalChecklistHeader' })(
  ManageExternalChecklistHeader
);
