import React from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "semantic-ui-react";
import { MotifIconButton, MotifIcon } from "@ey-xd/motif-react";
import { imageIcEdit24px, actionIcDelete24px } from '@ey-xd/motif-react/assets/icons';

const AuthListRow = ({ rowIndex, auth, handleEditButtonClick, handleDeleteButtonClick, currentUserId, authResource, currentUserIsSysAdmin, numberOfEngagementAdmins }) => {
    const deleteMessage = `Delete "${auth.userId}"`;
    const editMessage = `Edit "${auth.userId}"`;
    let roleText = "User";
    let realTime="No";
    let daily="No";
    let weekly="No";
    let psqArqcNotification="No";
    let showEmailPreferences=false;

    if (auth.role === 1) {
        if (auth.resource === 1) {
            roleText = "System Administrator";
        } else {
            roleText = "Engagement Administrator";
        }
    }

    if (auth.role == 4) {
        roleText = "External Checklist User";
    }

    if (auth.receiveRealTimeNotification) {
        realTime = "Yes";
    }

    if (auth.receiveDailyNewsletter) {
        daily = "Yes";
    }

    if (auth.receiveWeeklyNewsletter) {
        weekly = "Yes";
    }

    if (auth.receiveRealTimeNotificationForNewPsqArqcSent) {
        psqArqcNotification = "Yes";
    }
    // Hide Edit and Delete for current user
    let showEditIcon = true;
    let showDeleteIcon = true;

    // Edit never appears on SysAdmin list
    if (authResource === "system") {
        if (currentUserId.toLowerCase() == auth.userId.toLowerCase()) {
            showDeleteIcon = false;
        }
        showEditIcon = false;
    }

   // show Email prefrences column on client page
    if (authResource === "client") {
        showEmailPreferences = true;
    }

    const onEditButtonClick = () => {
        handleEditButtonClick(auth);
    };

    const onDeleteButtonClick = () => {
        handleDeleteButtonClick(auth);
    };

    return (
        <tr role="row" aria-rowindex={rowIndex}>
            <td role="gridcell" aria-colindex={1}>{auth.userId}</td>
            <td role="gridcell" aria-colindex={2}>{roleText}</td>
            {showEmailPreferences && <td role="gridcell" aria-colindex={3}>{daily}</td>}
            {showEmailPreferences && <td role="gridcell" aria-colindex={4}>{weekly}</td>}
            {showEmailPreferences && <td role="gridcell" aria-colindex={5}>{realTime}</td>} 
            {showEmailPreferences && <td role="gridcell" aria-colindex={6}>{psqArqcNotification}</td>} 
            <td className="tright" role="gridcell" aria-colindex={showEmailPreferences ? 7 : 3}>
                {(showEditIcon && (
                    <MotifIconButton aria-label={editMessage} type="button"
                        onClick={onEditButtonClick} data-testId="edit">
                        <MotifIcon src={imageIcEdit24px} title={editMessage} />
                    </MotifIconButton>
                )) || null}
                {(showDeleteIcon && (
                    <MotifIconButton aria-label={deleteMessage} type="button"
                        onClick={onDeleteButtonClick} data-testId="delete">
                        <MotifIcon src={actionIcDelete24px} title={deleteMessage} />
                    </MotifIconButton>
                )) || null}
            </td>
            <td role="gridcell" aria-colindex={showEmailPreferences ? 8 : 4} />
        </tr>
    );
};

AuthListRow.propTypes = {
    rowIndex: PropTypes.number,
    item: PropTypes.object,
    auth: PropTypes.object,
    handleEditButtonClick: PropTypes.func,
    handleDeleteButtonClick: PropTypes.func,
    currentUserId: PropTypes.string,
    authResource: PropTypes.string,
    currentUserIsSysAdmin: PropTypes.bool,
    numberOfEngagementAdmins: PropTypes.number
};

export default AuthListRow;