/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import {
  MotifAccordion,
  MotifAccordionContent,
  MotifAccordionTrigger,
  MotifIcon,
  MotifButton,
  MotifIconButton,
} from '@ey-xd/motif-react';
import CustomQuestionAttachmentList from './CustomQuestionAttachmentList';

import {
  imageIcEdit24px,
  actionIcDelete24px,
  actionIcLock24px,
  contentIcAdd24px,
} from '@ey-xd/motif-react/assets/icons';

const QuestionAccordion = ({
  index,
  question,
  internalSectionId,
  hasSection,
  handleDeleteClick,
  handleEditClick,
  interalParentQuestionId,
  handleAddNewChildQuestion,
  handleQuestionDropComponent,
  handleAvailableQuestionDragStart,
  handleSectionChildQuestionDragStart,
  dropCategory,
  handleOpenAccordion,
  handleCloseAccordion,
  parentQuestionSortOrder,
  visibleQuestionTypes,
  hideEditDelete = false,
  parentPicklistItems,
  allowChildQuestionEdit = true,
  isTemplateEditedByOtherUser,
  parentQuestionIndex,
  sectionID,
  sectionName,
  handleDownloadAttachmentClick
}) => {
  const questionSortOrder = question.parentQuestionID
    ? parentQuestionSortOrder
    : question.questionSortOrder;

  const parentQIndex = question.parentQuestionID
    ? null
    : index; 

  const onEditClick = (
    event,
    question,
    internalQuestionId,
    accordionCategory
  ) => {
    event.stopPropagation();
    let questionToEdit = {
      ...question,
      internalSectionId,
      internalQuestionId,
      accordionCategory,
      sectionID: question.parentQuestionID === null ? question.sectionID : sectionID,
      sectionName: question.parentQuestionID === null ? question.sectionName : sectionName,
    };
    handleEditClick(questionToEdit);
  };

  const onDeleteClick = (
    event,
    question,
    internalQuestionId,
    accordionCategory
  ) => {
    event.stopPropagation();
    let questionToDelete = {
      ...question,
      internalSectionId,
      internalQuestionId,
      interalParentQuestionId,
      accordionCategory,
    };
    handleDeleteClick(questionToDelete);
  };

  const onAddNewChildClick = (parentQuestion, hasSection) => {
    handleAddNewChildQuestion(parentQuestion, hasSection);
  };

  const getDisplayId = (hasChildQuestion, index) => {
    let id = '';
    // Genarate question id only for templates panel questions (i.e. hasSection == true)
    if (hasSection) {
      if (hasChildQuestion) {
        id = internalSectionId + 1 + '.' + (index + 1);
      } else {
        id =
          internalSectionId + 1 + '.' + (parentQuestionIndex + 1) + '.' + (index + 1);
      }
    }
    return id;
  };

  const childQuestionDisplayRule = (question) => {
    const childQuestionDisplayRule = question.childQuestionDisplayRule;
    if (
      childQuestionDisplayRule &&
      childQuestionDisplayRule.showChildQuestion == 'always'
    ) {
      return 'Always';
    } else if (
      childQuestionDisplayRule &&
      childQuestionDisplayRule.enabledByParentAnswers
    ) {
      let enabledParentAnswersCount = 0;
      let enabledByParentAnswers = [];
      if (parentPicklistItems === null || !parentPicklistItems) {
        enabledParentAnswersCount =
          childQuestionDisplayRule.enabledByParentAnswers.length;
        enabledByParentAnswers =
          childQuestionDisplayRule.enabledByParentAnswers;
      } else {
        enabledParentAnswersCount =
          childQuestionDisplayRule.enabledByParentAnswers.filter((c) =>
            parentPicklistItems.includes(c)
          ).length;
        enabledByParentAnswers =
          childQuestionDisplayRule.enabledByParentAnswers.filter((c) =>
            parentPicklistItems.includes(c)
          );
      }
      if (enabledParentAnswersCount > 0) {
        return 'Parent Answer is ' + enabledByParentAnswers.join(' / ');
      } else {
        return 'The selected parent answer is modified or removed. Please update the display rule for the question.';
      }
    }
  };

  return (
    <MotifAccordion
      name="questionAccordion"
      key={index}
      data-testid={
        question.parentQuestionID
          ? 'accordionChildQuestion'
          : 'accordionQuestion'
      }
      open={question.isAccordionOpen}
      onOpen={(e) => {
        handleOpenAccordion(
          e,
          question.isAccordionOpen,
          question.questionID,
          question.parentQuestionID
        );
      }}
      onClose={(e) => {
        handleCloseAccordion(
          e,
          question.isAccordionOpen,
          question.questionID,
          question.parentQuestionID
        );
      }}
    >
      <MotifAccordionTrigger>
        <div className="row">
          {dropCategory == 'sectionquestion' ||
            dropCategory == 'sectionchildquestion' ? (
            <div
              className="col-md-11 col-sm-11 sectionNavigation"
              style={{ marginRight: '0', width: '0' }}
            >
              {getDisplayId(question.parentQuestionID === null, index)}
              {'  '}
              {question.questionText}
            </div>
          ) : (
            <div
              className="col-md-11 col-sm-11 sectionNavigation"
              style={{ marginRight: '0', width: '0' }}
            >
              {question.questionText}
            </div>
          )}
          <div className="col-md-1 col-sm-1 d-flex justify-content-end">
            {('parentQuestionID' in question &&
              question.parentQuestionID !== null &&
              ((!question.isLocked && !hideEditDelete && allowChildQuestionEdit && visibleQuestionTypes.some(
                (x) => x.questionTypeID === question.questionTypeID
              ) && (
                <React.Fragment>
                {!isTemplateEditedByOtherUser &&
                  <React.Fragment>
                    <MotifIcon 
                      src={imageIcEdit24px}
                      data-testid="btnEditIcon"
                      onClick={(e) =>
                        onEditClick(e, question, index, 'childQuestion')
                      }
                      title="Edit this child question"
                    />
                    <div className="pl-3" />
                    <MotifIcon
                      src={actionIcDelete24px}
                      data-testid="btnDeleteIcon"
                      onClick={(e) =>
                        onDeleteClick(e, question, index, 'childQuestion')
                      }
                      title="Delete this child question"
                    />
                  </React.Fragment>
                }
                </React.Fragment>
              )) ||
                (hasSection && allowChildQuestionEdit &&
                  question.isLocked &&
                  visibleQuestionTypes.some(
                    (x) => x.questionTypeID === question.questionTypeID
                  ) &&
                  !hideEditDelete && (
                    <React.Fragment>
                    {!isTemplateEditedByOtherUser &&
                      <MotifIcon
                        src={imageIcEdit24px}
                        data-testid="btnEditIcon"
                        onClick={(e) =>
                          onEditClick(e, question, index, 'childQuestion')
                        }
                        title="Edit this child question"
                      />
                    }
                    </React.Fragment>
                  )) ||
                (!hideEditDelete && dropCategory != 'availablequestion' && (
                  <React.Fragment>
                    <MotifIcon
                      src={actionIcLock24px}
                      data-testid="btnLockIcon"
                      onClick={(event) => event.stopPropagation()}
                      title="This is a standard question and locked for edit"
                    />
                  </React.Fragment>
                )))) ||
              (visibleQuestionTypes.some(
                (x) => x.questionTypeID === question.questionTypeID
              ) &&
                dropCategory != 'availablequestion' &&
                !hideEditDelete &&
                ((hasSection && 'childQuestions' in question) ||
                  (!hasSection && !question.isLocked)) && (
                  <React.Fragment>
                    {!isTemplateEditedByOtherUser &&
                    <React.Fragment>
                      <MotifIcon
                        src={imageIcEdit24px}
                        data-testid="btnEditIcon"
                        onClick={(e) =>
                          onEditClick(e, question, index, 'parentQuestion')
                        }
                        title="Edit this question"
                      />
                  
                      {!question.isLocked && (
                        <React.Fragment>
                          <div className="pl-3" />
                          <MotifIcon
                            src={actionIcDelete24px}
                            data-testid="btnDeleteIcon"
                            onClick={(e) =>
                              onDeleteClick(e, question, index, 'parentQuestion')
                            }
                            title="Delete this question"
                          />
                        </React.Fragment>
                      )}
                    </React.Fragment>
                    }
                  </React.Fragment>
                )) ||
              // (!hasSection || !visibleQuestionTypes.some(x => x.questionTypeID === question.questionTypeID)&& (
              (!hideEditDelete && dropCategory != 'availablequestion' && (
                <React.Fragment>
                  <MotifIcon
                    src={actionIcLock24px}
                    data-testid="btnLockIcon"
                    onClick={(event) => event.stopPropagation()}
                    title="This is a standard question and locked for edit"
                  />
                </React.Fragment>
              ))}
          </div>
        </div>
      </MotifAccordionTrigger>
      <MotifAccordionContent className="psq-custom-accordion-question-list">
        <div className="container-fluid">
          <ul name="questionAccordionContent">
            <li />
            <li className="d-flex">
              <label>
                <h6>Question Text</h6>
              </label>
              <span className="word-break-text">{question.questionText}</span>
            </li>
            <li className="d-flex">
              <label>
                <h6>Help Text</h6>
              </label>
              <span className="word-break-text">
                {question.questionHelpText}
              </span>
            </li>
            <li className="d-flex">
              <label>
                <h6>Question Tag</h6>
              </label>
              <span className="word-break-text">{question.questionTag}</span>
            </li>
            <li>
              <label>
                <h6>Type</h6>
              </label>
              <span>{question.questionTypeDescription}</span>
            </li>
            {question?.questionTypeDescription?.startsWith('Picklist') && (
              <li className="d-flex">
                <label style={{ height: '100%' }}>
                  <h6>Picklist Items</h6>
                </label>
                <span>
                  {question.picklistItems &&
                    question.picklistItems.map((item) => (
                      <React.Fragment>
                        <span>{item.picklistItemName}</span>
                        <br />
                      </React.Fragment>
                    ))}
                </span>
              </li>
            )}
            {question.parentQuestionID && (
              <li className="d-flex">
                <label>
                  <h6>Display When</h6>
                </label>
                <span style={{ wordBreak: 'break-word' }}>
                  {childQuestionDisplayRule(question)}
                </span>
              </li>
            )}
            
            {question.questionAttachments &&
            question.questionAttachments.length > 0 &&
            <>
            <h6>Attachments</h6>
            <CustomQuestionAttachmentList attachmentList={question.questionAttachments} handleDownloadAttachmentClick={handleDownloadAttachmentClick} className="customQuestionAccordionAttachments" ></CustomQuestionAttachmentList>
           <br></br>
           </>
}
            {!question.parentQuestionID &&
              dropCategory != 'availablequestion' &&
              visibleQuestionTypes.some(
                (x) => x.questionTypeID === question.questionTypeID
              ) && (
                <li>
                  <div className="row">
                    <div className="col-md-12 justify-content-end mt-auto">
                      {((hasSection && 'childQuestions' in question) ||
                        (!hasSection &&
                          'childQuestions' in question &&
                          !question.isLocked)) && (
                          <React.Fragment>
                          {!isTemplateEditedByOtherUser &&
                            <MotifButton
                              variant="secondary"
                              className="right"
                              onClick={() =>
                                onAddNewChildClick(question, hasSection)
                              }
                            >
                            <MotifIcon
                              src={contentIcAdd24px}
                              data-testid="btnAddNewChildQuestion"
                            />
                            New Child Question
                            </MotifButton>
                          }
                          </React.Fragment>
                        )}
                    </div>
                  </div>
                </li>
              )}
            <li>
              {dropCategory == 'sectionquestion'
                ? 'childQuestions' in question &&
                question.childQuestions !== null &&
                question.childQuestions.length > 0 &&
                question.childQuestions.map((childQuestion, index) => (
                  <div
                    key={childQuestion.questionID}
                    onDragStart={(e) =>
                      handleSectionChildQuestionDragStart(
                        e,
                        childQuestion.questionID
                      )
                    }
                    draggable
                    className="draggable"
                    style={{ minWidth: '500px' }}
                    onDrop={(e) => {
                      handleQuestionDropComponent(
                        e,
                        'sectionchildquestion',
                        childQuestion.questionID
                      );
                    }}
                  >
                    <QuestionAccordion
                      index={index}
                      question={childQuestion}
                      internalSectionId={internalSectionId}
                      interalParentQuestionId={index}
                      handleDeleteClick={handleDeleteClick}
                      handleEditClick={handleEditClick}
                      hasSection={hasSection}
                      handleQuestionDropComponent={
                        handleQuestionDropComponent
                      }
                      handleAvailableQuestionDragStart={
                        handleAvailableQuestionDragStart
                      }
                      handleSectionChildQuestionDragStart={
                        handleSectionChildQuestionDragStart
                      }
                      dropCategory="sectionchildquestion"
                      handleOpenAccordion={handleOpenAccordion}
                      handleCloseAccordion={handleCloseAccordion}
                      parentQuestionSortOrder={questionSortOrder}
                      visibleQuestionTypes={visibleQuestionTypes}
                      hideEditDelete={
                        dropCategory == 'availablequestion' ? true : false
                      }
                      parentPicklistItems={question.picklistItems?.map(
                        (item) => item['picklistItemName']
                      )}
                      allowChildQuestionEdit={visibleQuestionTypes.some((x) => x.questionTypeID === question.questionTypeID)}
                      isTemplateEditedByOtherUser={isTemplateEditedByOtherUser}
                      parentQuestionIndex={parentQIndex}
                      sectionID={question.sectionID}
                      sectionName={question.sectionName}
                      handleDownloadAttachmentClick={handleDownloadAttachmentClick}
                    />
                  </div>
                ))
                : 'childQuestions' in question &&
                question.childQuestions !== null &&
                question.childQuestions.length > 0 &&
                question.childQuestions.map((childQuestion, index) => (
                  <QuestionAccordion
                    index={index}
                    question={childQuestion}
                    internalSectionId={internalSectionId}
                    interalParentQuestionId={index}
                    handleDeleteClick={handleDeleteClick}
                    handleEditClick={handleEditClick}
                    hasSection={hasSection}
                    handleQuestionDropComponent={handleQuestionDropComponent}
                    handleAvailableQuestionDragStart={
                      handleAvailableQuestionDragStart
                    }
                    handleSectionChildQuestionDragStart={
                      handleSectionChildQuestionDragStart
                    }
                    dropCategory="sectionchildquestion"
                    handleOpenAccordion={handleOpenAccordion}
                    handleCloseAccordion={handleCloseAccordion}
                    visibleQuestionTypes={visibleQuestionTypes}
                    hideEditDelete={
                      dropCategory == 'availablequestion' ? true : false
                    }
                    parentPicklistItems={question.picklistItems?.map(
                      (item) => item['picklistItemName']
                    )}
                    allowChildQuestionEdit={visibleQuestionTypes.some((x) => x.questionTypeID === question.questionTypeID)}
                    isTemplateEditedByOtherUser={isTemplateEditedByOtherUser}
                    parentQuestionIndex={parentQIndex}
                    sectionID={question.sectionID}
                    sectionName={question.sectionName}
                    handleDownloadAttachmentClick={handleDownloadAttachmentClick}
                  />
                ))}
            </li>
          </ul>
        </div>
      </MotifAccordionContent>
    </MotifAccordion>
  );
};

QuestionAccordion.propTypes = {
  index: PropTypes.number,
  question: PropTypes.objectOf(
    PropTypes.shape({
      isLocked: PropTypes.bool.isRequired,
      picklistItems: PropTypes.array,
    })
  ).isRequired,
  handleDeleteClick: PropTypes.func,
  handleEditClick: PropTypes.func,
  handleAddNewChildQuestion: PropTypes.func,
  handleOpenAccordion: PropTypes.func,
  handleCloseAccordion: PropTypes.func,
  parentQuestionSortOrder: PropTypes.number,
  internalSectionId: PropTypes.number,
  hasSection: PropTypes.bool,
  interalParentQuestionId: PropTypes.number,
  handleQuestionDropComponent: PropTypes.func,
  handleAvailableQuestionDragStart: PropTypes.func,
  handleSectionChildQuestionDragStart: PropTypes.func,
  dropCategory: PropTypes.string,
  visibleQuestionTypes: PropTypes.array,
  hideEditDelete: PropTypes.bool,
  parentPicklistItems: PropTypes.array,
  handleDownloadAttachmentClick:PropTypes.func
};

export default QuestionAccordion;
