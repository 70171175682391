import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import * as formHelpers from "../../scripts/formHelpers";
import * as formValidators from "../../scripts/formValidators";
import { MotifButton } from '@ey-xd/motif-react';

const MappingForm = ({ handleSubmit, handleCloseForm, initialValues, formPurpose, error, submitting, handleDismissErrorMessage, tbMode, change, lookupTypes }) => {
    let tbType = "REIT";
    if (initialValues.propertyID && initialValues.propertyID > 0) {
        tbType = "Property";
    }
    if (tbMode === 2) {
        tbType = "";
    }

    let reitTestingAttributeLookupOptions = lookupTypes.reitTestingAttributes
        .filter(rta => rta.reitTestingAttributeID !== 0 && rta.reitTestingAttributeID !== initialValues.reitTestingAttributeID)
        .map(rta => {
            return { label: rta.reitTestingAttributeDescription, value: rta.reitTestingAttributeID }
    });

    let formTitle = "";
    let selectionHeader = "Map to:";
    if (formPurpose === "EDIT") {
        formTitle = "Change " + tbType + " Mapping";
        selectionHeader = "Change Mapping to:";
    }

    return (
        <form onSubmit={handleSubmit} className="mapping">
            {formHelpers.showSubmissionError(error, handleDismissErrorMessage)}
            <h3>{formTitle}</h3>
            <div className="row col-md-12 field">
                <div className="field col-md-6">
                    <br />
                    <label htmlFor="name">Client Account Number: </label>
                    {(formPurpose === "EDIT" && (
                        <div>{initialValues.clientAccountCode}</div>
                    )) || (
                        <Field
                            name="clientAccountCode"
                            component={formHelpers.renderField}
                            type="text"
                            placeholder="Client Account Number"
                            maxLength={50}
                            validate={[
                                formValidators.required,
                                formValidators.maxLength50,
                            ]}
                        />
                    )}
                </div>
                <div className="field col-md-6">
                    <br />
                    <label htmlFor="name">Client Account Name: </label>
                    {(formPurpose === "EDIT" && (
                        <div>{initialValues.clientAccountDescription}</div>
                    )) || (
                        <Field
                            name="clientAccountDescription"
                            component={formHelpers.renderField}
                            type="text"
                            placeholder="Client Account Name"
                            maxLength={50}
                            validate={[
                                formValidators.required,
                                formValidators.maxLength50,
                            ]}
                        />
                    )}
                </div>
            </div>
            {formPurpose === "EDIT" && (
                <div className="row col-md-12 field">
                    <div className="field col-md-12">
                        <br />
                        <label htmlFor="name">
                            REIT Testing Attribute Description:{" "}
                        </label>
                        <br />
                        <div>
                            {initialValues.reitTestingAttributeCode +
                                " - " +
                                initialValues.reitTestingAttributeDescription}
                        </div>
                    </div>
                </div>
            )}
            <div className="row col-md-12 field">
                <div className="field col-md-12">
                    <br />
                    <Field
                        name="reitTestingAttribute"
                        label={selectionHeader}
                        component={formHelpers.renderReactSelect}
                        aria-label="Change mapping to"
                        options={reitTestingAttributeLookupOptions}
                        validate={[formValidators.requiredSelect]}
                    />
                </div>
            </div>
            <div className="row action">
                <br />
                <div className="col-md-12 container">
                    <MotifButton
                        variant="primary"
                        className="right ml-2"
                        type="submit"
                        disabled={submitting}>
                        Save
                    </MotifButton>
                    <MotifButton
                        variant="secondary"
                        className="right"
                        type="button"
                        onClick={handleCloseForm}>
                        Cancel
                    </MotifButton>
                </div>
            </div>
        </form>
    );
};

MappingForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleCloseForm: PropTypes.func.isRequired,
    trs: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        periodId: PropTypes.number,
        name: PropTypes.string,
        debt: PropTypes.number,
        equity: PropTypes.number
    })),
    initialValues: PropTypes.object,
    error: PropTypes.string,
    submitting: PropTypes.bool,
    formPurpose: PropTypes.string.isRequired,
    handleDismissErrorMessage: PropTypes.func.isRequired,
    tbMode: PropTypes.number,
};

export default reduxForm({ form: "mapping", enableReinitialize: true })(MappingForm);