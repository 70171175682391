import React from 'react';
import PropTypes from 'prop-types';

import { MotifIcon, MotifTooltip, MotifIconButton } from '@ey-xd/motif-react';
import {
  contentIcAddBox24px,
  toggleIcIndeterminateCheckBox24px,
  actionIcHelp24px
} from '@ey-xd/motif-react/assets/icons';

import SectionAccordionComponent from '../../components/customChecklistQuestions/CustomSectionAccordion';

const PsqCustomChecklistTemplate = ({
  sectionsWithQuestions,
  handleDeleteClick,
  handleEditClick,
  handleAddNewChildQuestion,
  handleSectionQuestionDragStart,
  handleSectionQuestionDragOver,
  handleQuestionDropComponent,
  handleQuestionDropPanel,
  handleSectionDragStart,
  handleSectionDragOver,
  handleAvailableQuestionDragStart,
  handleSectionChildQuestionDragStart,
  handleSectionCommentDragStart,
  dropCategory,
  expandAllAccordionsOnTemplate,
  handleToggleExpandCollapseAllAcordionsOnTemplate,
  handleOpenAccordionOnTemplate,
  handleCloseAccordionOnTemplate,
  templateName,
  visibleQuestionTypes,
  isTemplateEditedByOtherUser,
  handleDownloadAttachmentClick
}) => {
  return (
    <React.Fragment>
      <div
        className="psq-custom-checklist-template-section"
        data-testid="customChecklistTemplateSec"
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            borderBottom: '0.5px solid #C4C4C4',
            height: '43px',
            paddingLeft: '18px',
          }}
        >
          <div className='row w-100'>
            <div className="col-md-11 col-sm-11 d-flex align-items-center">
              {!expandAllAccordionsOnTemplate && (
                <MotifIcon
                  className="expand-collapse-all-template"
                  src={contentIcAddBox24px}
                  data-testid="btnExpandAllAccordionsOnTemplate"
                  onClick={(e) =>
                    handleToggleExpandCollapseAllAcordionsOnTemplate(e, 'expand')
                  }
                  title="Expand All"
                />
              )}
              {expandAllAccordionsOnTemplate && (
                <a style={{ cursor: 'grab' }}>
                  <MotifIcon
                    className="expand-collapse-all-template"
                    src={toggleIcIndeterminateCheckBox24px}
                    data-testid="btnCollapseAllAccordionsOnTemplate"
                    onClick={(e) =>
                      handleToggleExpandCollapseAllAcordionsOnTemplate(
                        e,
                        'collapse'
                      )
                    }
                    title="Collapse All"
                  />
                </a>
              )}
              <h5 className="pl-3 mt-2 mb-2" data-testid="headingTemplate">
                <span
                  className="sectionNavigation"
                  style={{ width: '42vw', cursor: 'pointer' }}
                  title={templateName}
                >
                  {templateName}
                </span>
              </h5>
            </div>
            <div className="col-md-1 col-sm-1 d-flex align-items-center justify-content-end">
              <MotifTooltip
                id="show-checklist-template-tooltip"
                allowHover
                placement="auto"
                trigger={
                  <MotifIcon src={actionIcHelp24px} />
                }
                variant="alt"
                hideCloseButton={true}
              >
                <div className='motif-tooltip-content-children'>
                  <div>The below displays available sections within a questionnaire. Sections can be created via the "+ New Section" button in the bottom left. Sections can be reordered via dragging and dropping.</div>
                  <br />
                  <div>Questions can be added to a section via drag and drop from the Available Question bank or can be created by selecting the applicable section and clicking the "+ New Question" button in the bottom left.</div>
                </div>
              </MotifTooltip>
            </div>
          </div>
        </div>
        <div className="container-drag">
          <div
            className="droppable"
            onDragOver={(e) => handleSectionDragOver(e)}
            onDrop={(e) => handleQuestionDropPanel(e, 'sectionquestion')}
          >
            <div className="psq-custom-checklist-template-section-content">
              <SectionAccordionComponent
                sections={sectionsWithQuestions}
                handleDeleteClick={handleDeleteClick}
                handleEditClick={handleEditClick}
                handleAddNewChildQuestion={handleAddNewChildQuestion}
                handleSectionQuestionDragOver={handleSectionQuestionDragOver}
                handleQuestionDropPanel={handleQuestionDropPanel}
                handleSectionQuestionDragStart={handleSectionQuestionDragStart}
                handleQuestionDropComponent={handleQuestionDropComponent}
                handleSectionDragStart={handleSectionDragStart}
                handleSectionDragOver={handleSectionDragOver}
                handleAvailableQuestionDragStart={
                  handleAvailableQuestionDragStart
                }
                handleSectionChildQuestionDragStart={
                  handleSectionChildQuestionDragStart
                }
                handleSectionCommentDragStart={handleSectionCommentDragStart}
                dropCategory={dropCategory}
                handleOpenAccordionOnTemplate={handleOpenAccordionOnTemplate}
                handleCloseAccordionOnTemplate={handleCloseAccordionOnTemplate}
                expandAllAccordionsOnTemplate={expandAllAccordionsOnTemplate}
                visibleQuestionTypes={visibleQuestionTypes}
                isTemplateEditedByOtherUser={isTemplateEditedByOtherUser}
                handleDownloadAttachmentClick={handleDownloadAttachmentClick}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment >
  );
};

PsqCustomChecklistTemplate.propTypes = {
  templateName: PropTypes.string,
  sectionsWithQuestions: PropTypes.array,
  handleDeleteClick: PropTypes.func,
  handleEditClick: PropTypes.func,
  handleAddNewChildQuestion: PropTypes.func,
  expandAllAccordionsOnTemplate: PropTypes.bool,
  handleToggleExpandCollapseAllAcordionsOnTemplate: PropTypes.func,
  handleOpenAccordionOnTemplate: PropTypes.func,
  handleCloseAccordionOnTemplate: PropTypes.func,
  handleSectionQuestionDragStart: PropTypes.func,
  handleSectionQuestionDragOver: PropTypes.func,
  handleQuestionDropComponent: PropTypes.func,
  handleQuestionDropPanel: PropTypes.func,
  handleSectionDragStart: PropTypes.func,
  handleSectionDragOver: PropTypes.func,
  handleAvailableQuestionDragStart: PropTypes.func,
  handleSectionChildQuestionDragStart: PropTypes.func,
  handleSectionCommentDragStart: PropTypes.func,
  dropCategory: PropTypes.string,
  visibleQuestionTypes: PropTypes.array,
  handleDownloadAttachmentClick:PropTypes.func
};

export default PsqCustomChecklistTemplate;
