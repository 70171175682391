import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import ReportPeriodRowContainer from "../../containers/reits/ReportPeriodRowContainer";

const ReportPeriodList = ({ clientId, reitId, reit, reportPeriods, activePeriodId, handleActivePeriodIdChanged, toggleShowClosePeriodModal, toggleShowOpenPeriodModal, tbMode, toggleShowModeModal, chartExistsForClient }) => {
    reportPeriods = reportPeriods || new Array();
    return (
        <div className="row">
            <div className="col-md-12">
                <section className="table-responsive rtable pt-0">
                    <table>
                        <thead>
                        <tr>
                            <th>Report Period</th>
                            <th>Upload/Download</th>
                            <th>Manage</th>
                            <th className="text-center">Services</th>
                            <th className="text-center">Period Status</th>
                            <th className="text-center">Sign Off</th>
                        </tr>
                        </thead>
                        <tbody>
                            {reportPeriods.map(
                                period => <ReportPeriodRowContainer key={period.reportPeriodID} clientId={clientId} reitId={reitId} reit={reit} period={period} activePeriodId={activePeriodId
                                } handleActivePeriodIdChanged={handleActivePeriodIdChanged} toggleShowClosePeriodModal={toggleShowClosePeriodModal} toggleShowOpenPeriodModal={toggleShowOpenPeriodModal}
                                    tbMode={tbMode} toggleShowModeModal={toggleShowModeModal} chartExistsForClient={chartExistsForClient}/>)}
                        </tbody>
                    </table>
                </section>
            </div>
        </div>
    );
};

ReportPeriodList.propTypes = {
    clientId: PropTypes.number,
    reitId: PropTypes.number,
    reit: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        periods: PropTypes.array
    }),
    reportPeriods: PropTypes.array,
    activePeriodId: PropTypes.number,
    handleActivePeriodIdChanged: PropTypes.func.isRequired,
    toggleShowClosePeriodModal: PropTypes.func.isRequired,
    toggleShowOpenPeriodModal: PropTypes.func.isRequired,
    tbMode: PropTypes.number,
    toggleShowModeModal: PropTypes.func.isRequired
};

export default ReportPeriodList;