import React from "react";
import PropTypes from "prop-types";
import { Message } from 'semantic-ui-react';
import { Link } from "react-router";
import ErrorSummary from '../shared/ErrorSummary';
import { MotifToast } from '@ey-xd/motif-react';

const Notification = ({ item, handleDismiss }) => {
    const onDismissNotification = () => {
        handleDismiss(item.notificationGUID);
    };

    if (!item) {
        return null;
    }

    if (item.notificationTypeDescription.toLowerCase() === "propertymappingdiff") {
        if (!item.notificationContent || !item.notificationContent.message) {
            return null;
        }

        return (
            <MotifToast className="mb-2" variant="info" onClose={onDismissNotification} closeButtonProps={{ariaLabel: "Clear information notification"}}>
                <p>{item.notificationContent.message}</p>
                <br/><br/>
                <Link className="info" to={item.notificationContent.linkUrl}>{item.notificationContent.linkText}</Link>
            </MotifToast>
        );
    }
    
    if (item.notificationTypeDescription.toLowerCase() === "trialbalanceinformation") {
        if (!item.notificationContent || !item.notificationContent.message) {
            return null;
        }

        return (
            <MotifToast className="mb-2" variant="info" onClose={onDismissNotification} closeButtonProps={{ "aria-label": "Clear information notification" }}>
                <p>{item.notificationContent.message}</p>
            </MotifToast>
        );
    }

    if (item.notificationTypeDescription.toLowerCase() === "trialbalancecomplete") {
        if (!item.notificationContent || !item.notificationContent.message) {
            return null;
        }

        return (
            <MotifToast className="mb-2" variant="success" onClose={onDismissNotification} closeButtonProps={{ "aria-label": "Clear success notification" }}>
                <p>{item.notificationContent.message}</p>
            </MotifToast>
        );
    }

    if (item.notificationTypeDescription.toLowerCase() === "trialbalancefailure") {
        if (!item.notificationContent || (!item.notificationContent.message && !item.notificationContent.error)) {
            return null;
        }

        if (item.notificationContent.error) {
            const errorResponse = item.notificationContent.error;

            if (Array.isArray(errorResponse.validationErrors) && errorResponse.validationErrors.filter(x => x.message).length > 0) {
                return (
                    <MotifToast className="mb-2" variant="error" onClose={onDismissNotification} closeButtonProps={{ "aria-label": "Clear error notification" }}>
                        <p style={{ fontWeight: "bold" }} className='mb-0 pb-0'>{errorResponse.message}:</p>
                        <ul className='ml-4'>
                            {errorResponse.validationErrors.map((x, i) => <li className='mt-0 mb-0 pt-0 pb-0' key={i}>{x.message}</li>)}
                        </ul>
                    </MotifToast>
                );
            }

            return (
                <MotifToast className="mb-2" variant="error" onClose={onDismissNotification} closeButtonProps={{ "aria-label": "Clear error notification" }}>
                    <p>{errorResponse.message}</p>
                </MotifToast>
            );
        }

        return (
            <MotifToast className="mb-2" variant="error" onClose={onDismissNotification} closeButtonProps={{ "aria-label": "Clear error notification" }}>
                <p>{item.notificationContent.message}</p>
            </MotifToast>
        );
    }

    if (!item.notificationContent || !item.notificationContent.message) {
        return null;
    }

    return (
        <MotifToast className="mb-2" variant="info" onClose={onDismissNotification} closeButtonProps={{ "aria-label": "Clear information notification" }}>
            <p>{item.notificationContent.message}</p>
        </MotifToast>
    );
};

Notification.propTypes = {
    item: PropTypes.object,
    handleDismiss: PropTypes.func.isRequired
};

export default Notification;