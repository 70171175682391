import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import * as actions from "../../actions/servicesActions";
import PropertyAmenitiesTableComponent from '../../components/shared/PropertyAmenitiesTableComponent';
import * as trsActions from '../../actions/trsActions';
import * as amenities from "../../constants/amenities";

/**
 * Container component used for uploading trial balance files
 */
export class PropertyAmenitiesTableContainer extends React.Component {
    static convertFromReduxFormValueToPropertyAmenityCollection(reduxFormValue, propertyId) {
        let propertyAmenities = [];

        if (Array.isArray(reduxFormValue)) {
            reduxFormValue.map(item => {

                propertyAmenities.push({
                    propertyAmenityName: (item.propertyAmenityName && item.propertyAmenityName.value === "Other" && item.otherName) || item.propertyAmenityName.value,
                    isProvidedByEmployee: item.isProvidedByEmployee === true ? true : false,
                    isProvidedByIndependentContractor: item.isProvidedByIndependentContractor === true ? true : false,
                    isProvidedByCustomary: item.isProvidedByCustomary === true ? true : false,
                    isProvidedByTaxableReitSubsidiary: item.isProvidedByTaxableReitSubsidiary === true ? true : false,
                    trsName: (item.isProvidedByTaxableReitSubsidiary === true && item.taxableReitSubsidiary && item.taxableReitSubsidiary.label) || null,
                    taxableREITSubsidiaryID: (item.isProvidedByTaxableReitSubsidiary === true && item.taxableReitSubsidiary && item.taxableReitSubsidiary.value) || null,
                    propertyId: propertyId
                });
            });
        }

        return propertyAmenities;
    }

    static convertFromPropertyAmenityCollectionToReduxFormValue(propertyAmenityCollection) {
        let propertyAmenities = [];

        if (Array.isArray(propertyAmenityCollection)) {
            propertyAmenityCollection.map(item => {
                let amenityOption = amenities.options.find(x => x.value === item.propertyAmenityName);
                let newAmenity = {
                    propertyAmenityName: amenityOption,
                    isProvidedByEmployee: item.isProvidedByEmployee,
                    isProvidedByIndependentContractor: item.isProvidedByIndependentContractor,
                    isProvidedByCustomary: item.isProvidedByCustomary,
                    isProvidedByTaxableReitSubsidiary: item.isProvidedByTaxableReitSubsidiary,
                    taxableReitSubsidiary: null,
                    propertyId: item.propertyId
                };

                if (!amenityOption) {
                    newAmenity.propertyAmenityName = amenities.options.find(x => x.value === "Other");
                    newAmenity.otherName = item.propertyAmenityName;
                }

                if (newAmenity.isProvidedByTaxableReitSubsidiary && item.taxableREITSubsidiaryID && item.trsName) {                    
                    newAmenity.taxableReitSubsidiary = { value: item.taxableREITSubsidiaryID, label: item.trsName };
                }
                
                propertyAmenities.push(newAmenity);
            });
        }

        return propertyAmenities;
    }

    /**
     * Creates a new PropertyAmenitiesTableContainer
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);
    }

    /**
     * Invoked immediately after mounting occurs
     * @returns {undefined}
     */
    componentDidMount() {
        // IMPORTANT: Fetching TRSes within this container will cause an endless loop in ARQC details.  Pass TRS as property instead.
        //if (this.props.periodId) {
        //    this.props.actions.fetchTrsByPeriod(this.props.periodId);
        //}
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        let options = [];
        if (Array.isArray(this.props.taxableReitSubsidiaries)) {
            this.props.taxableReitSubsidiaries.map(trs => options.push({ label: trs.trsName, value: trs.taxableREITSubsidiaryID }));
        }

        return (<PropertyAmenitiesTableComponent shouldSubmitReduxFormOnChange={this.props.shouldSubmitReduxFormOnChange} dispatch={this.props.dispatch} taxableReitSubsidiaryOptions={options} formName={this.props.formName} readOnly={!!this.props.readOnly} />);
    }
}

PropertyAmenitiesTableContainer.propTypes = {
    periodId: PropTypes.number,
    actions: PropTypes.object.isRequired,
    formName: PropTypes.string,
    readOnly: PropTypes.bool,
    taxableReitSubsidiaries: PropTypes.arrayOf(PropTypes.shape({
        taxableREITSubsidiaryID: PropTypes.number,
        reportPeriodID: PropTypes.number,
        trsName: PropTypes.string,
        trsValue: PropTypes.number,
        trsDebt: PropTypes.number,
        trsEquity: PropTypes.number
    })),
    shouldSubmitReduxFormOnChange: PropTypes.bool,
    dispatch: PropTypes.func
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps Existing properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    return {
        periodId: (state.period && state.period.reportPeriodID) || 0
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        dispatch: dispatch,
        actions: bindActionCreators(trsActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyAmenitiesTableContainer);