import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate } from 'react-intl';
import { Icon } from 'semantic-ui-react';
import { MotifFormField, MotifSearch, MotifLabel, MotifToggleSwitch, MotifButton, MotifIconButton, MotifIcon } from '@ey-xd/motif-react';
import { imageIcEdit24px, actionIcDelete24px, fileIcFileDownload24px } from '@ey-xd/motif-react/assets/icons';

/**
 * FileListItem component
 * @extends {React.Component}
 */
export default class FileListItem extends React.Component {
    /**
     * Creates a new TrialBalanceDownloadLink
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);

        this.onDownloadClick = this.onDownloadClick.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
    }

    /**
     * Fire the onDownloadClick function in props
     * @param {any} e The element that triggered this function
     */
    onDownloadClick(e) {
        e.preventDefault();
        this.props.onDownload(encodeURIComponent(this.props.item.fileName));
    }

    onDeleteClick(e) {
        e.preventDefault();
        this.props.onDelete(this.props.item.fileName);
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        let fileName = decodeURI(encodeURI(this.props.item.fileName));
        return (
            <tr>
                <td>{fileName}</td>
                <td><FormattedDate value={this.props.item.lastModified} /></td>
                <td className="tright">                    
                    <MotifIconButton aria-label="Download File" type="button"
                        onClick={this.onDownloadClick}>
                        <MotifIcon src={fileIcFileDownload24px}
                            title="Download File" />
                    </MotifIconButton>

                    {this.props.canUpload &&                        
                        <MotifIconButton aria-label="Delete File" type="button"
                            onClick={this.onDeleteClick}>
                        <MotifIcon src={actionIcDelete24px}
                            title="Delete File" />
                        </MotifIconButton>
                        ||                       
                        <MotifIconButton aria-label="Can't Delete File" type="button"
                             disabled>
                        <MotifIcon src={actionIcDelete24px}
                            />
                        </MotifIconButton>
                        }
                </td>
            </tr>
        );
    }
}


FileListItem.propTypes = {
    item: PropTypes.object.isRequired,
    onDownload: PropTypes.func,
    onDelete: PropTypes.func,
    canUpload: PropTypes.bool
};
