import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ClientListSlim from "../../components/clients/ClientListSlim";
import * as clientActions from "../../actions/clientActions";

/**
 * ClientsExternalPage container component
 * @extends {React.Component}
 */
export class ClientsExternalPage extends React.Component {
    /**
        * Creates a new ClientsExternalPage
        * @constructor
        * @param {Object} props The component properties
        * @param {Object} context The component context
        */
    constructor(props, context) {
        super(props, context);
        this.state = { filteredClients: [], clientsAreLoaded: false };
        this.reloadClients = this.reloadClients.bind(this);
    }

    /**
     * Invoked immediately after mounting occurs
     * @returns {undefined}
     */
    componentDidMount() {
        this.props.actions.fetchAllClientsSlim();
        this.setFilter();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.clients !== this.props.clients && nextProps.clients && this.props.clients) {
            const list = Object.assign([], nextProps.clients);
            this.setFilter(list);
            this.setState({ clientsAreLoaded: true });
        }
    }

    reloadClients(reloadClients) {
        if (reloadClients) {
            this.props.actions.fetchAllClientsSlim();
        }
    }

    setFilter(clients) {
        let filteredClients = clients || this.props.clients;
        this.setState({ filteredClients: filteredClients });
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        return (
            <React.Fragment>
                <ClientListSlim clients={this.state.filteredClients} clientsAreLoaded={this.state.clientsAreLoaded} />
            </React.Fragment>
        );
    }
}

ClientsExternalPage.propTypes = {
    clients: PropTypes.array.isRequired,
    actions: PropTypes.object,
    currentUserAuthorizations: PropTypes.array
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state) {
    const clients = state.clients;
    return {
        clients: clients,
        currentUserAuthorizations: state.currentUserAuthorizations
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(clientActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientsExternalPage);