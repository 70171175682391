import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import ClientDetails from '../../components/clients/ClientDetails';

/**
 * The ClientPage class
 * @extends {React.Component}
 */
export class ClientPage extends React.Component {     
    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        return (
            <ClientDetails client={this.props.client} />
        );
    }
}

ClientPage.propTypes = {
    client: PropTypes.object
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    return {
        client: state.client
    };
}

export default connect(mapStateToProps)(ClientPage);