import React from 'react';
import { MotifIcon, MotifIconButton } from '@ey-xd/motif-react';
import { actionIcDelete24px, fileIcFileDownload24px  } from '@ey-xd/motif-react/assets/icons';

export default (props) => {
    const bulkReportPackageId = props.data ? props.data.bulkReportPackageID : -1;
    const bulkReportPackageName = props.data ? props.data.bulkReportPackageName : '';
    const bulkReportPackageZipFileGUID = props.data ? props.data.bulkReportPackageZipFileGUID : '';
    const isSystemAdministrator = props.currentUserIsSysAdmin ? props.currentUserIsSysAdmin : false;
    const isEngagementAdmin = props.currentUserIsEngAdmin ? props.currentUserIsEngAdmin : false;
    const isEngagementUser = props.currentUserIsEngagementUser ? props.currentUserIsEngagementUser : false;
    const status = props.data ? props.data.bulkReportStatusDescription : '';

    return (
        <div>
            {(isSystemAdministrator || isEngagementAdmin || isEngagementUser)  &&
                <div>                    
                    <div className="justify-content-end align-items-center mt-2">
                        {status.toLowerCase() === 'completed' ?
                            <span style={{ width:'50%'} } >
                                <MotifIconButton

                                    aria-label="Download"
                                    type="button"
                                    data-testId="btnDownloadIcon"
                                    disabled={false}
                                    onClick={() => props.handleDownload(bulkReportPackageId, bulkReportPackageName, bulkReportPackageZipFileGUID)} >
                                    <MotifIcon src={fileIcFileDownload24px} title="Download" />
                                    </MotifIconButton>
                            </span>
                            : <span style={{ width: '50%' }}></span>
                        }
                        {(status.toLowerCase() === 'completed' || status.toLowerCase() === 'unexpected error') &&
                            <MotifIconButton aria-label="Delete"
                                type="button"
                                data-testId="btnDeleteIcon"
                                disabled={false}
                                onClick={() => props.handleDelete(bulkReportPackageId, bulkReportPackageName)}>
                                <MotifIcon src={actionIcDelete24px} title="Delete" />
                            </MotifIconButton>
                        }
                    </div>

                </div>
            }</div>);
};