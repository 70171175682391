import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TrialBalanceUpload from '../../components/tb/TrialBalanceUpload';
import TrialBalanceSummary from '../../components/tb/TrialBalanceSummary';
import TrialBalanceAdjustmentsModal from '../../components/tb/TrialBalanceAdjustmentsModal';
import NotFoundPage from '../../components/NotFoundPage';
import * as trialBalanceActions from '../../actions/trialBalanceActions';
import * as reitActions from '../../actions/reitActions';
import * as commonActions from "../../actions/commonActions";
import * as PeriodActions from "../../actions/periodActions";
import * as propertyActions from '../../actions/propertyActions';
import * as chartOfAccountsActions from '../../actions/chartOfAccountsActions';
import * as trialBalanceSummaryActions from '../../actions/trialBalanceSummaryActions';
import * as trialBalanceAdjustmentActions from '../../actions/trialBalanceAdjustmentActions';
import * as actions from '../../actions/actionTypes';
import { percentageOfOwnership } from '../../scripts/formValidators.js';
import * as ErrorCodes from '../../constants/errorCodes';
import * as Constants from '../../constants/other';
import $ from 'jquery';
const uuidv4 = require('uuid/v4');
import store from "../../store/configureStore";
import { reset } from "redux-form";
import {
    MotifToggleSwitch,
    MotifOption,
    MotifLabel,
    MotifButton,
    MotifFormField
} from '@ey-xd/motif-react';
import { Button, Message } from 'semantic-ui-react';

/**
 * Container component used for uploading trial balance files
 */
export class TrialBalanceUploadPage extends React.Component {
    /**
     * Creates a new TrialBalanceUploadPage
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);

        this.state = {
            file: null,
            canEdit: true,
            property: null,
            selectedTBOption: "0",
            percentageOfOwnership: "100",
            showDetails: false,
            hideZeroBalanceRows: true,  // Hide zero balance rows initially
            activePeriod: null,
            tbMode: 1,
            uploadId: null,
            errorResponse: null,
            showTrialBalanceAdjustmentsModal: false,
            selectedItemAdjustments: [],
            selectedItemPropertyName: "",
            itemAdjustment: { "toTrialBalanceItemId": null, "adjustment": null, "reason": "" },
            showAdjustmentForm: false,
            disableInputs: false,
            trialBalanceItemSelect: [],
            originalAdjustmentsHold: [],            
            adjustmentFormPurpose: null,
            chartExistsForClient: false,
            headerText : "Trial Balance Upload",
            enableTBUploadSection: false
        };

        this.showDetails = this.showDetails.bind(this);
        this.handleHideZeroBalanceRows = this.handleHideZeroBalanceRows.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.submit = this.submit.bind(this);
        this.createTrialBalancePurposeSelectItems = this.createTrialBalancePurposeSelectItems.bind(this);
        this.handleTrialBalancePurposeDropdownSelected = this.handleTrialBalancePurposeDropdownSelected.bind(this);
        this.onTrialBalanceSummaryDownload = this.onTrialBalanceSummaryDownload.bind(this);
        this.showTrialBalanceAdjustmentsModal = this.showTrialBalanceAdjustmentsModal.bind(this);
        this.handleDismissErrorMessage = this.handleDismissErrorMessage.bind(this);
        this.handleDismissSuccessMessage = this.handleDismissSuccessMessage.bind(this);
        this.handlePctChanged = this.handlePctChanged.bind(this);
        this.handleDownloadTbTemplates = this.handleDownloadTbTemplates.bind(this);
        this.cancelTrialBalanceAdjustmentsModal = this.cancelTrialBalanceAdjustmentsModal.bind(this);
        this.handlePreviewAdjustment = this.handlePreviewAdjustment.bind(this);
        this.doShowAdjustmentFormAdd = this.doShowAdjustmentFormAdd.bind(this);
        this.doShowAdjustmentFormEdit = this.doShowAdjustmentFormEdit.bind(this);
        this.doShowAdjustmentFormDelete = this.doShowAdjustmentFormDelete.bind(this);
        this.doCloseAdjustmentForm = this.doCloseAdjustmentForm.bind(this);
        this.doSubmitOfAdjustments = this.doSubmitOfAdjustments.bind(this);
        this.downloadTbTemplates = this.downloadTbTemplates.bind(this);
    }

    /**
     * Invoked immediately after mounting occurs
     * @returns {undefined}
     */
    componentDidMount() {
        // Check if state.period is null, if it is populate period
        if (!this.props.period || !this.state.activePeriod) {
            this.props.periodActions.fetchPeriodById(this.props.periodId).then(() => {
                this.props.commonActions.clearErrorResponse();
                this.props.actions.fetchReit(this.props.reitId).then(() => {
                    this.setCanEdit(this.props.period);

                    this.setState({ activePeriod: Object.assign({}, this.props.period), tbMode: this.props.period.trialBalanceModeID }, () => {
                        this.props.propertyActions.fetchPropertiesByPeriod(this.props.periodId).then(() => {
                            this.setState({ enableTBUploadSection: true });
                            if (this.props.properties && this.props.properties.length) {
                                if (this.props.properties.length === 1 && this.state.activePeriod && this.state.activePeriod.trialBalanceModeID === 2) {
                                    this.setState({ tbMode: 2, headerText : "Upload Consolidated Trial Balance"});
                                    let propertyId = this.props.properties[0].propertyID;
                                    let propertyPerctentageOfOwnership = this.props.properties[0].percentageOfOwnership;
                                    this.setState({ property: propertyId, percentageOfOwnership: propertyPerctentageOfOwnership.toString() });
                                } else {
                                    this.setState({ tbMode: 1, headerText : "Trial Balance Upload" });
                                }
                            }
                        });
                    });
                });
            });
        }
        
        //Get charts based on Client
        if (this.props && this.props.clientId > 0) {
            this.props.chartOfAccountsActions.fetchChartsByClientId(this.props.clientId).then((charts) => {
                if (charts && charts.length > 0) {
                    this.setState({ chartExistsForClient: true });
                }
            });
        }
    }

    /**
    * Invoked before a mounted component receives new props.
    * @param {Object} nextProps The properties that the component is receiving
    */
    componentWillReceiveProps(nextProps) {

    }

    componentWillUnmount() {
        this.props.commonActions.clearErrorResponse();
        this.props.actions.clearUploadTbSuccess();

        // null this out, so next time we re-enter page, it will fetch new data
        this.setState({ activePeriod: null });
    }

    setCanEdit(period) {
        const isEnabled = PeriodActions.isTestingEnabled(period);
        this.setState({ canEdit: isEnabled });
    }

    handleDismissErrorMessage() {
        this.props.commonActions.clearErrorResponse();
        this.setState({ errorResponse: null });
    }

    handleDismissSuccessMessage() {
        this.props.actions.clearUploadTbSuccess();
    }

    onFileChange(e) {
        this.props.actions.clearUploadTbSuccess();
        this.setState({ file: e.target.files[0] });
    }

    showDetails() {
        this.setState({ showDetails: !this.state.showDetails }, () => {
            if (this.state.showDetails) {
                this.props.trialBalanceSummaryActions.getTrialBalanceSummaryByPeriod(this.props.periodId, this.state.hideZeroBalanceRows, false, true, this.state.tbMode);
            }
        });
    }

    handleHideZeroBalanceRows() {
        // hide or show zero balance rows
        this.setState({ hideZeroBalanceRows: !this.state.hideZeroBalanceRows },
            () => this.props.trialBalanceSummaryActions.getTrialBalanceSummaryByPeriod(this.props.periodId, this.state.hideZeroBalanceRows, false, true, this.state.tbMode));
    }

    onTrialBalanceSummaryDownload() {
        this.props.commonActions.beginTask();
        return trialBalanceSummaryActions.downloadTrialBalanceSummary(this.props.periodId, this.state.hideZeroBalanceRows, false, true, this.state.tbMode).then(() => this.props.commonActions.endTask());
    }

    submit(e) {
        e.preventDefault();
        this.props.actions.clearUploadTbSuccess();
        this.props.commonActions.clearErrorResponse();
        this.setState({ errorResponse: null });

        const myFile = this.state.file;

        let uploadId = null;

        if (myFile) {
            if (this.state.tbMode === 2) {
                uploadId = uuidv4();
                this.props.actions.uploadTrialBalance(this.props.periodId, "adjusted", myFile, 2, this.state.property, this.state.percentageOfOwnership, uploadId, this.props.clientId);

                this.setState({ hideZeroBalanceRows: false, showDetails: false, uploadId: uploadId, file: null });
                return;
            }

            if (this.state.property === null) {
                return;
            }

            if (this.state.property === 0) {
                uploadId = uuidv4();
                this.props.actions.uploadTrialBalance(this.props.periodId, "adjusted", myFile, 1, null, 100.00, uploadId, this.props.clientId);
            }

            if (this.state.property > 0) {
                uploadId = uuidv4();
                this.props.actions.uploadTrialBalance(this.props.periodId, "adjusted", myFile, 2, this.state.property, this.state.percentageOfOwnership, uploadId, this.props.clientId);
            }

            this.setState({ hideZeroBalanceRows: false, showDetails: false, uploadId: uploadId, file: null, selectedTBOption: "0", property: null });
        }
        $('input[name=tbFileToUpload]').val("");
    }

    createTrialBalancePurposeSelectItems() {
        let items = [];
        items.push(<MotifOption key={1} value="1">REIT</MotifOption>);
        if (this.props.properties.length > 0) {
            for (let i = 0; i < this.props.properties.length; i++) {
                items.push(<MotifOption key={i + 2} value={String(i + 2)}>{this.props.properties[i].propertyName}</MotifOption>);
            }
        }
        return items;
    }

    handleTrialBalancePurposeDropdownSelected(_value) {
        const value = parseInt(_value);
        
        switch (value) {
            case 0:
                this.setState({ property: null, percentageOfOwnership: "100", selectedTBOption: "0" });
                break;
            case 1:
                this.setState({ property: 0, percentageOfOwnership: "100", selectedTBOption: "1" });
                break;
            default:
                if (this.props.properties.length > 0) {
                    // Note offset of 2 for index of property
                    const propertyId = this.props.properties[value - 2].propertyID;
                    const propertyPerctentageOfOwnership = this.props.properties[value - 2].percentageOfOwnership;
                    this.setState({ property: propertyId, percentageOfOwnership: propertyPerctentageOfOwnership.toString(), selectedTBOption: String(value) });
                }
                break;
        }
    }

    handlePctChanged(e) {
        const value = percentageOfOwnership(e.target.value);
        if (value === undefined) {
            return;
        }

        this.setState({ percentageOfOwnership: value.toString() });
    }

    handleDownloadTbTemplates(clientId, periodId) {
       
        return this.props.trialBalanceActions.downloadTbTemplates(clientId, false, periodId).then((blob) => {
            return blob;
        });
    }

    handlePreviewAdjustment(values) {
        this.setState({ showAdjustmentForm: false });
        if (this.state.adjustmentFormPurpose === 'ADD') {
            let toItem = this.props.trialBalanceItems.find(i => i.id === values.toTrialBalanceItemId.value);
            let newAdjustment = {
                "fromTrialBalanceItemId": this.state.selectedItem.id,
                "fromTrialBalanceItemAccountCode": this.state.selectedItem.clientAccountCode,
                "fromTrialBalanceItemAccountDescription": this.state.selectedItem.clientAccountDescription,
                "fromTrialBalanceItemBalance": this.state.selectedItem.balance,
                "toTrialBalanceItemId": toItem.id,
                "toTrialBalanceItemAccountCode": toItem.clientAccountCode,
                "toTrialBalanceItemAccountDescription": toItem.clientAccountDescription,
                "toTrialBalanceItemInternalAccountCode": toItem.internalAccountCode,
                "toTrialBalanceItemInternalAccountDescription": toItem.internalAccountDescription,
                "toTrialBalanceItemBalance": toItem.balance,
                "trialBalanceId": this.state.selectedItem.trialBalanceId,
                "adjustment": values.adjustment,
                "reason": values.reason,
                "percentageOfOwnership": values.percentageOfOwnership,
                "id": this.guid()
            };

            let newAdjustments = Object.assign([], this.state.selectedItemAdjustments);
            newAdjustments.push(newAdjustment);
            this.setState({ selectedItemAdjustments: Object.assign([], newAdjustments) });
        }

        if (this.state.adjustmentFormPurpose === 'DELETE') {
            let newAdjustments = [];
            let originalAdjustmentsHold = [];
            for (let adj of this.state.selectedItemAdjustments) {
                if (adj.id !== values.id) {
                    newAdjustments.push(adj);
                    continue;
                }

                // Store deleted original for restoration on cancel
                // Never put a 'new' adjustment in originalAdjustmentHold.
                // Don't duplicate an original on subsequent deletes.
                if (!this.state.originalAdjustmentsHold.some(a => a.id == adj.id) && Number.isInteger(adj.id)) {
                    originalAdjustmentsHold.push(adj);
                }
            }

            this.setState({
                originalAdjustmentsHold: Object.assign([], this.state.originalAdjustmentsHold, originalAdjustmentsHold),
                selectedItemAdjustments: Object.assign([], newAdjustments)
            });
        }

        if (this.state.adjustmentFormPurpose === 'EDIT') {
            let newSelectedItemAdjustments = [];
            let originalAdjustmentsHold = [];
            for (let adj of this.state.selectedItemAdjustments) {
                if (adj.id == values.id) {
                    // Store unedited original for restoration on cancel
                    // Never put a 'new' adjustment in originalAdjustmentHold.
                    // Don't duplicate an original on subsequent edits.
                    if (!this.state.originalAdjustmentsHold.some(a => a.id == adj.id) && Number.isInteger(adj.id)) {
                        originalAdjustmentsHold.push(adj);
                    }

                    let toItem = this.props.trialBalanceItems.find(i => i.id == values.toTrialBalanceItemId);


                    let editAdj = {
                        "fromTrialBalanceItemId": this.state.selectedItem.id,
                        "fromTrialBalanceItemAccountCode": this.state.selectedItem.clientAccountCode,
                        "fromTrialBalanceItemAccountDescription": this.state.selectedItem.clientAccountDescription,
                        "fromTrialBalanceItemBalance": this.state.selectedItem.balance,
                        "fromTrialBalanceItemInternalAccountCode": this.state.selectedItem.internalAccountCode,
                        "fromTrialBalanceItemInternalAccountDescription": this.state.selectedItem.internalAccountDescription,
                        "toTrialBalanceItemId": toItem.id,
                        "toTrialBalanceItemAccountCode": toItem.clientAccountCode,
                        "toTrialBalanceItemAccountDescription": toItem.clientAccountDescription,
                        "toTrialBalanceItemInternalAccountCode": toItem.internalAccountCode,
                        "toTrialBalanceItemInternalAccountDescription": toItem.internalAccountDescription,
                        "toTrialBalanceItemBalance": toItem.balance,
                        "trialBalanceId": this.state.selectedItem.trialBalanceId,
                        "adjustment": values.adjustment,
                        "reason": values.reason,
                        "percentageOfOwnership": values.percentageOfOwnership,
                        "id": values.id
                    };

                    if (this.state.selectedItem.id === values.toTrialBalanceItemId) {
                        // Flip reciprocals back after editing
                        editAdj.adjustment = values.adjustment * -1;
                        editAdj.fromTrialBalanceItemId = values.fromTrialBalanceItemId;
                        editAdj.fromTrialBalanceItemAccountCode = values.fromTrialBalanceItemAccountCode;
                        editAdj.fromTrialBalanceItemAccountDescription = values.fromTrialBalanceItemAccountDescription;
                        editAdj.fromTrialBalanceItemBalance = values.fromTrialBalanceItemBalance;
                        editAdj.fromTrialBalanceItemInternalAccountCode = values.fromTrialBalanceItemInternalAccountCode,
                        editAdj.fromTrialBalanceItemInternalAccountDescription = values.fromTrialBalanceItemInternalAccountDescription
                    }

                    newSelectedItemAdjustments.push(editAdj);
                    continue;
                }

                newSelectedItemAdjustments.push(adj);
            }

            this.setState({
                originalAdjustmentsHold: Object.assign([], [...this.state.originalAdjustmentsHold, ...originalAdjustmentsHold]),
                selectedItemAdjustments: Object.assign([], newSelectedItemAdjustments)
            });
        }

        store.dispatch(reset('addAdjustment'));
    }

    doShowAdjustmentFormAdd() {
        this.setState({ showAdjustmentForm: true, adjustmentFormPurpose: 'ADD', disableInputs: false, itemAdjustment: { "toTrialBalanceItemId": null, "adjustment": null, "reason": "" } });
    }

    doShowAdjustmentFormEdit(id) {
        let adjustmentToEdit = Object.assign({}, this.state.selectedItemAdjustments.find(a => a.id == id));

        if (this.state.selectedItem.id === adjustmentToEdit.toTrialBalanceItemId) {
            // Flip reciprocals for editing
            adjustmentToEdit.adjustment = adjustmentToEdit.adjustment * -1;
        }
        this.setState({ itemAdjustment: adjustmentToEdit, showAdjustmentForm: true, adjustmentFormPurpose: 'EDIT', disableInputs: false });
    }

    doShowAdjustmentFormDelete(id) {
        let adjustmentToDelete = Object.assign({}, this.state.selectedItemAdjustments.find(a => a.id == id));
        this.setState({ itemAdjustment: adjustmentToDelete, showAdjustmentForm: true, adjustmentFormPurpose: 'DELETE', disableInputs: true });
    }

    doSubmitOfAdjustments() {
        this.setState({ showTrialBalanceAdjustmentsModal: false, showAdjustmentForm: false, adjustmentFormPurpose: 'SUBMIT', disableInputs: false }, this.submitAdjustments());
    }

    submitAdjustments() {
        const request = { "trialBalanceId": this.state.selectedItem.trialBalanceId, "adjustments": this.state.selectedItemAdjustments };

        this.props.trialBalanceAdjustmentActions.applyAdjustments(request).then(() => {
            this.props.trialBalanceSummaryActions.getTrialBalanceSummaryByPeriod(this.props.periodId, this.state.hideZeroBalanceRows, false, true, this.state.tbMode).then(() => {
                this.props.trialBalanceAdjustmentActions.getTrialBalanceAdjustmentsByPeriodId(this.props.periodId);
            });
        });
    }

    doCloseAdjustmentForm() {
        this.setState({ itemAdjustment: {}, showAdjustmentForm: false, adjustmentFormPurpose: '', disableInputs: false });
    }

    cancelTrialBalanceAdjustmentsModal() {
        // First, remove any selectedItemAdjustments that are in the originaAdjustmentHold array (because they are preview deleted or preview edited)
        // Also remove any new adjustments, they have a GUID for their ID
        let adjustmentsToRetain = [];
        for (let adj of this.state.selectedItemAdjustments) {
            if (!this.state.originalAdjustmentsHold.some(a => a.id == adj.id) && Number.isInteger(adj.id)) {
                adjustmentsToRetain.push(adj);
            }
        }

        // Then concat the what remains with the originaAdjustmentHold array.
        // This has the selectedItemAdjustments back to where it was before previewing changes.
        this.setState({
            selectedItemAdjustments: Object.assign([], [...adjustmentsToRetain, ...this.state.originalAdjustmentsHold]),
            originalAdjustmentsHold: [], showTrialBalanceAdjustmentsModal: false, showAdjustmentForm: false, itemAdjustment: {}
        });
        store.dispatch(reset('addAdjustment'));
    }

    showTrialBalanceAdjustmentsModal(e) {
        e.preventDefault();
        const propName = e.target.dataset.propname;

        this.props.actions.getTrialBalanceItem(e.target.dataset.id).then(() => {
            this.setState({ selectedItem: Object.assign({}, this.props.trialBalanceItem) }, () => {
                this.props.trialBalanceAdjustmentActions.getTrialBalanceItemsByTrialBalanceId(this.props.trialBalanceItem.trialBalanceId).then(() => {
                    this.props.trialBalanceAdjustmentActions.getTrialBalanceAdjustmentsByPeriodId(this.props.periodId).then(() => {
                        this.setState({ showTrialBalanceAdjustmentsModal: true, selectedItemPropertyName: propName, selectedItemAdjustments: Object.assign([], this.props.trialBalanceAdjustments) }, () => {
                            this.createTrialBalanceItemSelect(this.props.trialBalanceItems);
                        });
                    });
                });
            });
        });

    }

    createTrialBalanceItemSelect(trialBalanceItems) {
        if (!trialBalanceItems) {
            return;
        }
        let items = [];
        let selectedItemId = this.state.selectedItem.id;
        for (let i = 0; i < trialBalanceItems.length; i++) {
            if (trialBalanceItems[i].id == selectedItemId) {
                continue;
            }

            items.push({
                value: trialBalanceItems[i].id, label: trialBalanceItems[i].clientAccountCode + " - " + trialBalanceItems[i].clientAccountDescription
                    + " - " + trialBalanceItems[i].internalAccountCode + " : " + trialBalanceItems[i].internalAccountDescription
            });
        }

        let sortedItems = items.sort(this.compareItems);
        this.setState({ trialBalanceItemSelect: sortedItems });
    }

    guid() {
        /**
         * Creates a new random-enough GUID.
         * @returns {string} A GID
         */
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    compareItems(a, b) {
        if (a.label < b.label)
            return -1;
        if (a.label > b.label)
            return 1;
        return 0;
    }

    downloadTbTemplates() {
        const { clientId, period } = this.props;
        this.handleDownloadTbTemplates(clientId, period.reportPeriodID);
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        const { headerText, canEdit, chartExistsForClient } = this.state;
        const {period} = this.props;

        const summaryIsAvailable = 
            this.props.trialBalanceSummary && 
            this.props.trialBalanceSummary.rows && 
            this.props.trialBalanceSummary.rows.length > 0;

        return (
            <div style={{ width: '100%', height: '100%' }}>
                
                <div className="row">
                    <div className="col">
                        <h1>{headerText} {canEdit ? "" : "- Testing Closed"}</h1>
                    </div>
                    <div className="col text-right">
                        <MotifButton variant="secondary" className="right mr-1" type="button"
                            onClick={this.downloadTbTemplates}
                            disabled={!chartExistsForClient} >
                            {period.reportPeriodDescription} TB Templates
                        </MotifButton>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        {this.state.enableTBUploadSection && <TrialBalanceUpload
                            onSubmit={this.submit}
                            onFileChange={this.onFileChange}
                            file={this.state.file}
                            reit={this.props.reit}
                            errorResponse={this.state.errorResponse || this.props.errorResponse}
                            tbUploadSuccess={this.props.tbUploadSuccess}
                            canEdit={this.state.canEdit}
                            createTrialBalancePurposeSelectItems={this.createTrialBalancePurposeSelectItems}
                            handleTrialBalancePurposeDropdownSelected={this.handleTrialBalancePurposeDropdownSelected}
                            property={this.state.property}
                            selectedTBOption={this.state.selectedTBOption}
                            handleDismissErrorMessage={this.handleDismissErrorMessage}
                            handleDismissSuccessMessage={this.handleDismissSuccessMessage}
                            percentageOfOwnership={this.state.percentageOfOwnership}
                            handlePctChanged={this.handlePctChanged}
                            tbMode={this.state.tbMode}
                            clientId={this.props.clientId}
                            period={this.props.period}
                            handleDownloadTbTemplates={this.handleDownloadTbTemplates}
                            chartExistsForClient={this.state.chartExistsForClient}
                        />}</div>
                </div>
                <div className="row">
                    <div className="col-md-5 col-lg-3 text-left">
                        <MotifLabel htmlFor="showTbDetails" className="mr-3"
                            style={{ display: 'inline-block', width: 'auto'}}>
                            Display Details and Adjustments:
                        </MotifLabel>
                        <MotifFormField style={{ display: 'inline-block', width: 'auto' }}>
                            <MotifToggleSwitch
                                name="showTbDetails"
                                id="switchOff"
                                aria-label="Display Details and Adjustments toggle"
                                onChange={this.showDetails}
                                checked={this.state.showDetails} />
                        </MotifFormField>
                    </div>
                    {this.state.showDetails && summaryIsAvailable &&
                        <div className="col-md-5 col-lg-3 text-left">
                            <MotifLabel htmlFor="switchOffZeroBalances" className="mr-3"
                                style={{ display: 'inline-block', width: 'auto'}}>
                                Display Zero Balance Rows:
                            </MotifLabel>
                            <MotifFormField style={{ display: 'inline-block', width: 'auto' }}>
                                <MotifToggleSwitch
                                    name="switchOffZeroBalances"
                                    id="switchOffZeroBalances"
                                    aria-label="Display Zero Balance Rows toggle"
                                    onChange={this.handleHideZeroBalanceRows}
                                    checked={!this.state.hideZeroBalanceRows} />
                            </MotifFormField>
                        </div>}
                </div>

                <div className="row">
                    <div className="col">
                        {this.state.showDetails &&
                            (summaryIsAvailable ?
                                <TrialBalanceSummary
                                    trialBalanceSummary={this.props.trialBalanceSummary}
                                    handleHideZeroBalanceRows={this.handleHideZeroBalanceRows}
                                    hideZeroBalanceRows={this.state.hideZeroBalanceRows}
                                    handleDownloadTrialBalanceSummary={this.onTrialBalanceSummaryDownload}
                                    showTrialBalanceAdjustmentsModal={this.showTrialBalanceAdjustmentsModal}
                                    tbMode={this.state.tbMode}
                                    canEdit={this.state.canEdit}
                                /> :
                                <p>Please upload trial balance for the period to view details.</p>)}
                    </div>
                </div>
                
                <TrialBalanceAdjustmentsModal showTrialBalanceAdjustmentsModal={this.state.showTrialBalanceAdjustmentsModal} onSubmit={this.handlePreviewAdjustment}
                    cancelTrialBalanceAdjustmentsModal={this.cancelTrialBalanceAdjustmentsModal} selectedItem={this.props.trialBalanceItem}
                    selectedItemAdjustments={this.state.selectedItemAdjustments} selectedItemPropertyName={this.state.selectedItemPropertyName} doShowAdjustmentFormAdd={this.doShowAdjustmentFormAdd}
                    doShowAdjustmentFormEdit={this.doShowAdjustmentFormEdit} doShowAdjustmentFormDelete={this.doShowAdjustmentFormDelete}
                    showAdjustmentForm={this.state.showAdjustmentForm} adjustmentFormPurpose={this.state.adjustmentFormPurpose} disableInputs={this.state.disableInputs}
                    trialBalanceItemSelect={this.state.trialBalanceItemSelect} doCloseAdjustmentForm={this.doCloseAdjustmentForm}
                    doSubmitOfAdjustments={this.doSubmitOfAdjustments} tbMode={this.state.tbMode} initialValues={this.state.itemAdjustment} />
            </div>
        );
    }
}

TrialBalanceUploadPage.propTypes = {
    clientId: PropTypes.number.isRequired,
    period: PropTypes.object,
    properties: PropTypes.array,
    reitId: PropTypes.number.isRequired,
    reit: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
    }),
    periodId: PropTypes.number.isRequired,
    actions: PropTypes.object.isRequired,
    errorResponse: PropTypes.object,
    tbUploadSuccess: PropTypes.bool,
    periodActions: PropTypes.object.isRequired,
    trialBalanceStatus: PropTypes.object,
    trialBalanceSummary: PropTypes.object,
    commonActions: PropTypes.object,
    propertyActions: PropTypes.object,
    chartOfAccountsActions: PropTypes.object.isRequired,
    trialBalanceSummaryActions: PropTypes.object,
    trialBalanceAdjustmentActions: PropTypes.object,
    trialBalanceAdjustments: PropTypes.array,
    trialBalanceItem: PropTypes.object,
    trialBalanceItems: PropTypes.array
};

TrialBalanceUploadPage.contextTypes = {
    router: PropTypes.object
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps Existing properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    return {
        periodId: Number.parseInt(ownProps.params.periodId),
        clientId: Number.parseInt(ownProps.params.clientId),
        reitId: Number.parseInt(ownProps.params.reitId),
        reit: state.reit,
        errorResponse: state.errorResponse,
        tbUploadSuccess: state.tbUploadSuccess,
        period: state.period,
        properties: state.properties,
        trialBalanceSummary: state.trialBalanceSummary,
        trialBalanceStatus: state.trialBalanceStatus,
        pendingTasks: state.pendingTasks,
        trialBalanceAdjustments: state.trialBalanceAdjustments,
        trialBalanceItem: state.trialBalanceItem,
        trialBalanceItems: state.trialBalanceItems
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, trialBalanceActions, reitActions), dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
        periodActions: bindActionCreators(PeriodActions, dispatch),
        trialBalanceActions: bindActionCreators(trialBalanceActions, dispatch),
        propertyActions: bindActionCreators(propertyActions, dispatch),
        chartOfAccountsActions: bindActionCreators(chartOfAccountsActions, dispatch),
        trialBalanceSummaryActions: bindActionCreators(trialBalanceSummaryActions, dispatch),
        trialBalanceAdjustmentActions: bindActionCreators(trialBalanceAdjustmentActions, dispatch),
        processingCompleteNotified: () => dispatch({ type: actions.TB_PROCESSING_COMPLETE_NOTIFIED })
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TrialBalanceUploadPage);