import React from "react";
import PropTypes from "prop-types";
import PropertyListRow from "../../components/reits/PropertyListRow";
import ReactModal from "react-modal";
import EditPropertyComponent from "../../components/reits/EditPropertyComponent";
import EditPropertyForm from "./EditPropertyForm";
import EndPropertyModal from "./EndPropertyModal";
import ClearEndDateForm from "./ClearEndDateForm";
import DeletePropertyModal from "../../components/reits/DeletePropertyModal";
import { MotifButton, MotifModal } from '@ey-xd/motif-react';

const PropertyList = ({
    period, properties, lookupTypes, property, formPurpose, formProperty, showForm, handleCloseForm, handleNewButtonClick, handleSubmit, handleEditButtonClick, handleDeleteButtonClick,
    showSuccess, isTestingEnabled, canAdd, canEdit, handleDismissErrorMessage, handleDismissSuccessMessage, periodPropertyCount, hideSuccessMessage, showComponent, handleCloseComponent,
    handleOpenComponent, taxableReitSubsidiaries, tbMode, showEndForm, handleShowEndForm, handleCloseEndForm, showClearEndDateForm, handleShowClearEndDateForm, handleOpenDeleteProperty, showDeletePropertyModal, deletePropertyDetail, handleYesDeleteProperty, handleNoDeleteProperty
}) => {
    const reitModalStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '60%',
            height: '100%', // <-- This sets the height
            overlfow: 'scroll' // <-- This tells the modal to scrol
        }
    };

    let buttonMessage = "Add a property to this REIT";
    if (!canAdd) {
        buttonMessage = "Period is closed to testing.";
    }
    if (tbMode === 2) {
        buttonMessage = "Consolidated Trial Balance Mode";
    }

    return (
        <React.Fragment>
            <div className="row">
                <div className="w-100">
                    <div className="col">
                        <div className="d-flex justify-content-between">
                            <div>
                                <h1>Properties {!canEdit ? "- Reporting Closed" : !canAdd ? "- Testing Closed" : ""} </h1>
                            </div>
                            <div className="align-self-center">                                
                                <MotifButton variant="primary" className="right" type="button"
                                    onClick={handleNewButtonClick}
                                    disabled={!canAdd || tbMode === 2}
                                    title={buttonMessage}>
                                    New Property
                                </MotifButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="table-responsive rtable">
                <table>
                    <thead>
                        <tr>
                            <th>PROPERTY NAME</th>
                            <th className="tcenter">TYPE</th>
                            <th className="tcenter">PURCHASE DATE</th>
                            <th className="tcenter">OWNERSHIP</th>
                            <th className="tcenter">TRACKING ID</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {properties.map(
                            property => <PropertyListRow
                                key={property.propertyID}
                                property={property} lookupTypes={lookupTypes} handleOpenComponent={handleOpenComponent} handleOpenDeleteProperty={handleOpenDeleteProperty} />)}
                    </tbody>
                </table>
            </section>
            <MotifModal show={showComponent} variant="alt" size="xl" style={{ minWidth: '65vw' }} onClose={handleCloseComponent} area-label="Add or Edit Property">
                <EditPropertyComponent
                    property={property}
                    lookupTypes={lookupTypes}
                    showForm={showForm}
                    handleCloseForm={handleCloseForm}
                    handleCloseComponent={handleCloseComponent}
                    handleNewButtonClick={handleNewButtonClick}
                    handleSubmit={handleSubmit}
                    handleEditButtonClick={handleEditButtonClick}
                    handleDeleteButtonClick={handleDeleteButtonClick}
                    showSuccess={showSuccess && !hideSuccessMessage}
                    canEdit={canEdit}
                    handleDismissErrorMessage={handleDismissErrorMessage}
                    handleDismissSuccessMessage={handleDismissSuccessMessage}
                    periodPropertyCount={periodPropertyCount}
                    taxableReitSubsidiaries={taxableReitSubsidiaries}
                    initialValues={property}
                    handleShowEndForm={handleShowEndForm}
                    handleShowClearEndDateForm={handleShowClearEndDateForm}
                />
            </MotifModal>
            <DeletePropertyModal
                showDeletePropertyModal={showDeletePropertyModal}
                deletePropertyDetail={deletePropertyDetail}
                property={property}
                handleYes={handleYesDeleteProperty}
                handleNo={handleNoDeleteProperty}
            />

            <MotifModal show={showForm} size="xl" style={{ minWidth: '65vw' }} variant="alt" onClose={handleCloseForm}
                area-label={formPurpose === "EDIT"? "Edit Property" : "Add Property"}>
                <EditPropertyForm lookupTypes={lookupTypes} onSubmit={handleSubmit} handleCloseForm={handleCloseForm} initialValues={formProperty} formPurpose={formPurpose} handleDismissErrorMessage={handleDismissErrorMessage} isTestingEnabled={isTestingEnabled} periodPropertyCount={periodPropertyCount} taxableReitSubsidiaries={taxableReitSubsidiaries} />
            </MotifModal>
            <MotifModal show={showEndForm} area-label="End Property" size="md" variant="alt" onClose={handleCloseEndForm}>
                <EndPropertyModal onSubmit={handleSubmit} initialValues={formProperty} formPurpose={formPurpose} handleDismissErrorMessage={handleDismissErrorMessage} handleCloseEndForm={handleCloseEndForm} />
            </MotifModal>
            <MotifModal show={showClearEndDateForm} area-label="Clear End Date" size="md" variant="alt" onClose={handleCloseEndForm}>
                <ClearEndDateForm onSubmit={handleSubmit} initialValues={formProperty} formPurpose={formPurpose} handleDismissErrorMessage={handleDismissErrorMessage} handleCloseForm={handleCloseEndForm} />
            </MotifModal>
        </React.Fragment>
    );
};

PropertyList.propTypes = {
    period: PropTypes.object,
    properties: PropTypes.array,
    property: PropTypes.object,
    formProperty: PropTypes.object,
    showForm: PropTypes.bool,
    handleCloseForm: PropTypes.func,
    handleNewButtonClick: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleEditButtonClick: PropTypes.func,
    handleDeleteButtonClick: PropTypes.func,
    showSuccess: PropTypes.bool,
    isTestingEnabled: PropTypes.bool,
    canAdd: PropTypes.bool,
    canEdit: PropTypes.bool,
    handleDismissErrorMessage: PropTypes.func,
    handleDismissSuccessMessage: PropTypes.func,
    periodPropertyCount: PropTypes.number,
    hideSuccessMessage: PropTypes.bool,
    showComponent: PropTypes.bool,
    handleCloseComponent: PropTypes.func,
    handleOpenComponent: PropTypes.func,
    taxableReitSubsidiaries: PropTypes.array,
    tbMode: PropTypes.number,
    showEndForm: PropTypes.bool,
    handleShowEndForm: PropTypes.func,
    handleCloseEndForm: PropTypes.func,
    formPurpose: PropTypes.string,
    showClearEndDateForm: PropTypes.bool,
    lookupTypes: PropTypes.object,
    handleShowClearEndDateForm: PropTypes.func.isRequired
};

export default PropertyList;