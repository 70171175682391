import React from "react";
import PropTypes from "prop-types";
import {
  MotifButton,
  MotifModal,
  MotifModalHeader,
  MotifIcon,
  MotifModalBody,
  MotifModalFooter,
} from "@ey-xd/motif-react";
import { alertIcError24px } from "@ey-xd/motif-react/assets/icons";

const DeleteChecklistUserModal = ({
  showDeleteChecklistUserModal,
  handleDeleteChecklistUser,
  toggleShowDeleteChecklistUserModal,
}) => {
  const HeaderWrapper = ({ children }) => (
    <div
      style={{
        display: "flex",
        alignItems: "start",
        justifyContent: "center",
      }}
    >
      {children}
    </div>
  );

  const closeButtonProps = {
    title: "Close delete checklist user modal",
    ariaLabel: "Close delete checklist user modal",
    dataTestid: "close",
  };

  return (
    <MotifModal
      variant="alt"
      show={showDeleteChecklistUserModal}
      onClose={toggleShowDeleteChecklistUserModal}
      data-testid="DeleteChecklistUserModal"
    >
      <MotifModalHeader
        style={{ display: "-webkit-box" }}
        closeButtonProps={closeButtonProps}
      >
        <HeaderWrapper>
          <MotifIcon style={{ color: "#F95D54" }} src={alertIcError24px} />
          <span className="ml-2">Delete Checklist User</span>
        </HeaderWrapper>
      </MotifModalHeader>
      <MotifModalBody>
        <span className="checklistActionMsg">Are you sure you want to delete selected user(s)?</span>
        <span className="checklistActionMsg">
          Once a user is deleted from a checklist, they will no longer be able
          to access the checklist.
              </span>
        <br />
      </MotifModalBody>
      <MotifModalFooter>
        <MotifButton
          variant="warn"
          className="right"
          data-testid="Delete"
          type="button"
          onClick={handleDeleteChecklistUser}
        >
          Delete
        </MotifButton>
        <MotifButton
          variant="secondary"
          className="right"
          data-testid="Cancel"
          type="button"
          onClick={toggleShowDeleteChecklistUserModal}
        >
          Cancel
        </MotifButton>
      </MotifModalFooter>
    </MotifModal>
  );
};

DeleteChecklistUserModal.propTypes = {
  showDeleteChecklistUserModal: PropTypes.bool,
  handleDeleteChecklistUser: PropTypes.func,
  toggleShowDeleteChecklistUserModal: PropTypes.func,
};

export default DeleteChecklistUserModal;
