import React from "react";
import PropTypes from "prop-types";
import { FormattedNumber } from "react-intl";
import { Button, Icon } from "semantic-ui-react";
import FormattedCurrency from "../shared/FormattedCurrency";
import { MotifIconButton, MotifIcon } from "@ey-xd/motif-react";
import { imageIcEdit24px, actionIcDelete24px } from '@ey-xd/motif-react/assets/icons';

//let NumberFormat = require("react-number-format");

const editSecuritiesSnapshotRow = ({ securitiesSnapshot, showForm, handleEditButtonClick, handleDeleteButtonClick, canEdit }) => {
    const onEditButtonClick = () => {
        handleEditButtonClick(securitiesSnapshot);
    };

    const onDeleteButtonClick = () => {
        handleDeleteButtonClick(securitiesSnapshot);
    };

    return (
        <tr>
            <td>{securitiesSnapshot.securityName}</td>
            <td>{securitiesSnapshot.issuer}</td>
            <td>{securitiesSnapshot.class}</td>
            <td className="tright">
                <FormattedCurrency value={securitiesSnapshot.reitOwnedSharesValue} currency="USD"/>
            </td>
            <td className="tright">
                <FormattedCurrency value={securitiesSnapshot.allSharesFromIssuerValue} currency="USD"/>
            </td>
            <td className="tright">
                <FormattedNumber value={securitiesSnapshot.percentOfVote / 100} style="percent" minimumFractionDigits={4}/>
            </td>
            <td className="tright">
                {canEdit &&
                    (<span>
                        <MotifIconButton aria-label="Edit this Security Snapshot" type="button"
                        onClick={onEditButtonClick}>
                            <MotifIcon src={imageIcEdit24px} title="Edit this Security Snapshot" />
                        </MotifIconButton>
                        <MotifIconButton aria-label="Delete this Security Snapshot" type="button"
                        onClick={onDeleteButtonClick}>
                            <MotifIcon src={actionIcDelete24px} title="Delete this Security Snapshot" />
                        </MotifIconButton>
                </span>)
                    || (<span>
                        <MotifIconButton aria-label="Can't edit Security Snapshot - Testing Closed" type="button" disabled>
                            <MotifIcon src={imageIcEdit24px} aria-label="Can't edit Security Snapshot - Testing Closed" />
                        </MotifIconButton>
                        <MotifIconButton aria-label="Can't delete Security Snapshot - Testing Closed" type="button" disabled>
                            <MotifIcon src={actionIcDelete24px} aria-label="Can't delete Security Snapshot - Testing Closed" />
                        </MotifIconButton>
                    </span>)}
            </td>
        </tr>
    );
};

editSecuritiesSnapshotRow.propTypes = {
    securitiesSnapshot: PropTypes.shape({
        allSharesFromIssuerValue: PropTypes.number,
        class: PropTypes.string,
        securitiesSnapshotID: PropTypes.number,
        issuer: PropTypes.string,
        securityName: PropTypes.string,
        reitOwnedSharesValue: PropTypes.number,
        percentOfVote: PropTypes.number,
        reportPeriodID: PropTypes.number,
    }),
    showForm: PropTypes.bool,
    handleEditButtonClick: PropTypes.func.isRequired,
    handleDeleteButtonClick: PropTypes.func.isRequired,
    canEdit: PropTypes.bool
};

export default editSecuritiesSnapshotRow;