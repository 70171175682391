import PropTypes from "prop-types";
import React from "react";
import { Header, Label, Button, Input, Dropdown } from "semantic-ui-react";
import ReactModal from "react-modal";
import { Field, reduxForm } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import * as formValidators from "../../scripts/formValidators";
import { connect } from "react-redux";
import { MotifIconButton, MotifIcon, MotifButton } from "@ey-xd/motif-react";

const EditCustomQuestionModal = ({
    showEditCustomQuestionModal,
    handleSubmit,
    toggleShowEditCustomQuestionModal,
    initialValues }) => {

    return (
        <ReactModal contentLabel="Edit Custom Question" isOpen={showEditCustomQuestionModal} className="react-modal react-modal-small" overlayClassName="react-modal-overlay">
            <Header content="Edit Custom Question" />
            <form onSubmit={handleSubmit}>
                <Field
                    name="customQuestionID"
                    type="hidden"
                    component={formHelpers.renderField} />
                <Field
                    name="defaultUserDefinedQuestionID"
                    type="text"
                    component={formHelpers.renderField}
                    label="Question ID"
                    maxLength={255}
                    validate={[formValidators.required, formValidators.maxLength255]} />
                <Field
                    name="questionText"
                    type="text"
                    component={formHelpers.renderField}
                    label="Question Text"
                    maxLength={4000}
                    validate={[formValidators.required, formValidators.maxLength4000]} />
                <Field
                    name="helpText"
                    type="text"
                    component={formHelpers.renderField}
                    label="Help Text"
                    maxLength={4000}
                    validate={[formValidators.maxLength4000]}
                />
                <h4>Answer Choice</h4>
                <label><Field name="answerChoice" component={formHelpers.renderField} type="radio" value="1" label="Free Text" /></label>
                <label className="ml-3"><Field name="answerChoice" component={formHelpers.renderField} type="radio" value="2" label="Yes, No or N/A"/></label>
                <br />                
                <MotifButton variant="primary" className="right ml-2" type="submit">Submit</MotifButton>
                <MotifButton variant="secondary" className="right" type="button"
                    onClick={toggleShowEditCustomQuestionModal}>Cancel</MotifButton>
            </form>
        </ReactModal>
    );
};

EditCustomQuestionModal.propTypes = {
    showEditCustomQuestionModal: PropTypes.bool,
    handleSubmit: PropTypes.func,
    toggleShowshowEditCustomQuestionModal: PropTypes.func,
    initialValues: PropTypes.object
};

export default connect(
    (state, props) => ({
        initialValues: {
            customQuestionID: props.initialValues ? props.initialValues.customQuestionID : 0,
            defaultUserDefinedQuestionID: props.initialValues ? props.initialValues.defaultUserDefinedQuestionID : "",
            questionText: props.initialValues ? props.initialValues.questionText : "",
            helpText: props.initialValues ? props.initialValues.helpText : "",
            answerChoice: props.initialValues && props.initialValues.answerChoice ? props.initialValues.answerChoice.toString() : "1"
        }
    }))(reduxForm({ form: "customquestionmodal", enableReinitialize: true })(EditCustomQuestionModal));