import React from 'react';
import PropTypes from 'prop-types';

import {
  MotifButton,
  MotifModal,
  MotifModalHeader,
  MotifIcon,
  MotifModalBody,
  MotifModalFooter,
} from '@ey-xd/motif-react';
import { alertIcError24px } from '@ey-xd/motif-react/assets/icons';

const DownloadChecklistsModal = ({
  showDownloadChecklistsModal,
  downloadType,
  modalTitle,
  modalContent,
  selectedChecklists,
  toggleShowDownloadChecklistsModal,
  handleDownloadChecklists,
}) => {
  const HeaderWrapper = ({ children }) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
      }}
    >
      {children}
    </div>
  );

  const closeButtonProps = {
    title: 'Close download checklists modal',
    'aria-label': 'Close download checklists modal',
    'data-testid': 'close',
  };

  const showHideDownloadChecklistsModal = () => {
    toggleShowDownloadChecklistsModal(downloadType);
  };

  return (
    <MotifModal
      variant="alt"
      show={showDownloadChecklistsModal}
      onClose={showHideDownloadChecklistsModal}
      size="xl"
      data-testid="DownloadChecklistsModal"
    >
      <MotifModalHeader
        style={{ display: '-webkit-box' }}
        closeButtonProps={closeButtonProps}
      >
        <HeaderWrapper>
          {/* <MotifIcon style={{ color: '#F95D54' }} src={alertIcError24px} /> */}
          <span className="ml-0">{modalTitle}</span>
        </HeaderWrapper>
      </MotifModalHeader>
      <MotifModalBody>
        <span className="checklistActionMsg">{modalContent}</span>
        <br />
        <span className="checklistActionMsg">You have selected {selectedChecklists?.length} checklist(s).</span>
      </MotifModalBody>
      <MotifModalFooter>
        <MotifButton
          className="right"
          data-testid="Download"
          type="button"
          onClick={() => handleDownloadChecklists(downloadType)}
        >
          Download
        </MotifButton>
        <MotifButton
          variant="secondary"
          className="right"
          data-testid="Cancel"
          type="button"
          onClick={showHideDownloadChecklistsModal}
        >
          Cancel
        </MotifButton>
      </MotifModalFooter>
    </MotifModal>
  );
};

DownloadChecklistsModal.propTypes = {
  showDownloadChecklistsModal: PropTypes.bool,
  downloadType: PropTypes.string,
  modalTitle: PropTypes.string,
  modalContent: PropTypes.string,
  selectedChecklists: PropTypes.array,
  toggleShowDownloadChecklistsModal: PropTypes.bool,
  handleDownloadChecklists: PropTypes.func,
};

export default DownloadChecklistsModal;
