import React from "react";

import { MotifIcon } from '@ey-xd/motif-react';
import { actionIcDone24px  } from '@ey-xd/motif-react/assets/icons';

export default (props) => {
    const value = props.data ? props.data[props.colDef.field] : null;

    if (props.data && value) {
        return (
            <span>
                <MotifIcon src={actionIcDone24px} />
            </span>
        );
    }

    return null;
}