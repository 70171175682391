import React from "react";
import PropTypes from "prop-types";
import { Link, IndexLink } from "react-router";
import { IntlProvider } from "react-intl";
import ExternalMain from "../containers/ExternalMain";
import ExternalNavBar from "./ExternalNavBar";
import { LicenseManager } from 'ag-grid-enterprise';

/**
 * The ExternalApp class.  This is the parent component for all routes.
 * @extends {React.Component}
 */
class ExternalApp extends React.Component {
    /**
      * Creates a new ExternalApp
      * @constructor
      * @param {Object} props The component properties
      * @param {Object} context The component context
      */
    constructor(props, context) {
        super(props, context);
    }

    componentDidMount() {
        
        //Set AG Grid license key
        LicenseManager.setLicenseKey(process.env.AG_GRID_UI_LICENSE);
    }

    render() {

        return (
            <IntlProvider locale="en">
                <div className="container-fluid no-padding">
                    <ExternalNavBar/>
                    <ExternalMain isPermExternalApp={false}>{this.props.children}</ExternalMain>
                </div>
            </IntlProvider>
        );
    }
}

ExternalApp.propTypes = {
    children: PropTypes.element
};

export default ExternalApp;