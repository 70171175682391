export default (headerName) => {
    return `<div class="ag-cell-label-container" role="presentation" data-testid=`+ headerName + "Header" +`>
        <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true" data-testid="menuHeader"></span>
        <div ref="eLabel" class="ag-header-cell-label" role="presentation">
            <span ref="eText" class="ag-header-cell-text"></span>
            <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true" data-testid="filter"></span>
            <span ref="eSortOrder" class="ag-header-icon ag-header-label-icon ag-sort-order" aria-hidden="true"></span>
            <span ref="eSortAsc" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true"></span>
            <span ref="eSortDesc" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true"></span>
            <span ref="eSortNone" class="ag-header-icon ag-header-label-icon ag-sort-none-icon" aria-hidden="true"></span>
        </div>
    </div>`;
}