import { pendingTask, begin, end } from 'react-redux-spinner';
import * as actions from "./actionTypes";
import { fetchFromReitApi as fetch } from "./fetchFromApi";
import * as actionHelpers from '../scripts/actionHelpers';

/**
 * Fetch all Securities Snapshot records belonging to the reit period. If successful this will dispatch the LOAD_SECURITIES_SNAPSHOTS_SUCCESS
 * action, otherwise it will dispatch the LOAD_SECURITIES_SNAPSHOTS_FAILURE action.
 * @param {number} periodId The id of the period that owns the snapshot.
 * @returns {funtion} A function that returns a Promise.
 */
export function fetchSecuritiesSnapshotByPeriod(periodId) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetch(`/securities?reportPeriodId=${periodId}`).then(response => {
            return response.json();
        }).then(securitiesSnapshots => {
            if (actionHelpers.isErrorResponse(securitiesSnapshots)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_SECURITIES_SNAPSHOTS_FAILURE, securitiesSnapshots);
            }

            dispatch({ type: actions.LOAD_SECURITIES_SNAPSHOTS_SUCCESS, securitiesSnapshots, [pendingTask]: end });
            }).catch(error => {
                actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_SECURITIES_SNAPSHOTS_FAILURE, null, error);
        });
    };
}

/**
 * Saves a securities snapshot to the REIT API database.
 * @param {any} secruitiesSnapshot The securities snapshot to save.
 * @returns {Promise} A Promise
 */
export function saveSecuritiesSnapshot(secruitiesSnapshot) {
    const payload = secruitiesSnapshot;

    return fetch("/Securities",
        {
            headers: {
                'Accept':
                "application/json, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                'Content-Type': "application/json"
            },
            method: "POST",
            body: JSON.stringify(payload)
        }).then(response => {
            if (response.ok) {
                return null;
            }

            return response.json();
        });
}

/**
 * Updates a securities snapshot to the REIT API database.
 * @param {any} securitiesSnapshot The securities snapshot
 * @returns {Promise} A Promise
 * http://localhost:5000/api/securities/3
 */
export function updateSecuritiesSnapshot(securitiesSnapshot) {
    const period = securitiesSnapshot.reportPeriodID;
    const payload = {
        "securitiesSnapshotID": securitiesSnapshot.securitiesSnapshotID,
        "allSharesFromIssuerValue": securitiesSnapshot.allSharesFromIssuerValue,
        "class": securitiesSnapshot.class,
        "issuer": securitiesSnapshot.issuer,
        "securityName": securitiesSnapshot.securityName,
        "reitOwnedSharesValue": securitiesSnapshot.reitOwnedSharesValue,
        "percentOfVote": securitiesSnapshot.percentOfVote
    };

    return fetch(`/securities/${securitiesSnapshot.securitiesSnapshotID}`,
        {
            headers: {
                'Accept':
                "application/json",
                'Content-Type': "application/json"
            },
            method: "PUT",
            body: JSON.stringify(payload)
        }).then(response => {
            if (response.ok) {
                return null;
            }

            return response.json();
        });
}

/**
 * Deletes a securities snapshot to the REIT API database.
 * @param {any} securitiesSnapshot The securities snapshot to delete
 * @returns {Promise} A Promise
 * http://localhost:5000/api/securitiesSnapshot/3
 */
export function deleteSecuritiesSnapshot(securitiesSnapshot) {
    return fetch(`/securities/${securitiesSnapshot.securitiesSnapshotID}`,
        {
            headers: {
                'Accept':
                "application/json",
                'Content-Type': "application/json"
            },
            method: "DELETE"
        }).then(response => {
            if (response.ok) {
                return null;
            }

            return response.json();
        });
}