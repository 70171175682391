import { pendingTask, begin, end } from 'react-redux-spinner';
import * as actions from './actionTypes';
import { fetchFromReitApi as fetch } from './fetchFromApi';
import * as actionHelpers from '../scripts/actionHelpers';
import { download, decodeURIComponentSafe } from '../scripts/fileHelpers';

/**
 * Uploads a file for the period
 * @param {number} periodId The period ID
 * @param {any} file The file to upload
 * @returns {function} A function that returns a Promise
 */
export function uploadFile(periodId, file) {
    let formData = new FormData();
    formData.append("periodId", periodId);
    formData.append("file", file);
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetch('/files',
            {
                method: 'POST',
                body: formData
            }).then(response => {
                if (response.ok) {
                    return null;
                } else {
                    return response.json();
                }
            }).then(json => {

                if (json) {
                    actionHelpers.dispatchErrorAndEndTask(dispatch, actions.UPLOAD_FILE_FAILURE, json);
                } else {
                    dispatch({ type: actions.UPLOAD_FILE_SUCCESS, [pendingTask]: end });
                }
            }).catch(error => {

                actionHelpers.dispatchErrorAndEndTask(dispatch, actions.UPLOAD_FILE_FAILURE, null, error);
            });
    };
}

/**
 * Uploads a file for the REIT
 * @param {number} reitId The REIT ID
 * @param {any} file The file to upload
 * @returns {function} A function that returns a Promise
 */
export function uploadReitFile(reitId, file) {
    let formData = new FormData();
    formData.append("reitId", reitId);
    formData.append("file", file);
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetch('/files/reit',
            {
                method: 'POST',
                body: formData
            }).then(response => {
                if (response.ok) {
                    return null;
                } else {
                    return response.json();
                }
            }).then(json => {

                if (json) {
                    actionHelpers.dispatchErrorAndEndTask(dispatch, actions.UPLOAD_FILE_FAILURE, json);
                } else {
                    dispatch({ type: actions.UPLOAD_REIT_FILE_SUCCESS, [pendingTask]: end });
                }
            }).catch(error => {

                actionHelpers.dispatchErrorAndEndTask(dispatch, actions.UPLOAD_FILE_FAILURE, null, error);
            });
    };
}

/**
 * Gets the uploaded files for the period
 * @param {number} periodId The period ID
 * @returns {function} A function that returns a Promise
 */
export function fetchUploadedFiles(periodId) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetch(`/files?periodid=${periodId}`).then(response => {
            return response.json();
        }).then(files => {
            if (actionHelpers.isErrorResponse(files)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_FILES_FAILURE, files);
            }
            dispatch({ type: actions.LOAD_FILES_SUCCESS, files, [pendingTask]: end });
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_FILES_FAILURE, null, error);
        });
    };
}

/**
 * Gets the uploaded files for the REIT
 * @param {number} reitId The REIT ID
 * @returns {function} A function that returns a Promise
 */
export function fetchUploadedReitFiles(reitId) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetch(`/files/reit?reitId=${reitId}`).then(response => {
            return response.json();
        }).then(files => {
            if (actionHelpers.isErrorResponse(files)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_REIT_FILES_FAILURE, files);
            }
            dispatch({ type: actions.LOAD_REIT_FILES_SUCCESS, files, [pendingTask]: end });
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_REIT_FILES_FAILURE, null, error);
        });
    };
}

/**
 * Downloads a file belonging to a period
 * @param {number} periodId The period ID
 * @param {any} fileName The file name
 * @returns {any} A Promise with the download
 */
export function downloadFile(periodId, fileName) {
    return fetch(`/files?periodid=${periodId}&fileName=${fileName}`)
        .then(function (response) {
            return response.blob().then(function (blob) {
                download(blob, decodeURIComponentSafe(fileName,1));
            });
        });
}

/**
 * Downloads a file belonging to a REIT
 * @param {number} reitId The REIT ID
 * @param {any} fileName The file name
 * @returns {any} A Promise with the download
 */
export function downloadReitFile(reitId, fileName) {
    return fetch(`/files/reit?reitId=${reitId}&fileName=${fileName}`)
        .then(function (response) {
            return response.blob().then(function (blob) {
                download(blob, decodeURIComponentSafe(fileName,1));
            });
        });
}


/**
 * Downloads a file belonging to a period
 * @param {number} checklistFileAttachmentId The id of the checklist file
 * @param {any} fileName The file name
 * @param {string} authHeader The Authorization header.  If not supplied it will be generated.
 * @returns {any} A Promise with the download
 */
export function downloadChecklistFile(checklistFileAttachmentId, fileName, authHeader) {
    return fetch(`/files/checklistFiles/${checklistFileAttachmentId}?fileName=${fileName}`, {
        headers: (authHeader && new Headers({ Authorization: authHeader })) || null,
    })
        .then(function (response) {
            return response.blob().then(function (blob) {
                download(blob, decodeURIComponentSafe(fileName,1));
            });
        });
}

export function downloadCustomQuestionAttachment(clientId,checklistFileAttachmentGuid, fileName,authHeader) {
    return fetch(`/customChecklistTemplate/downloadCustomQuestionAttachment?clientId=${clientId}&attachmentName=${fileName}&attachmentGUID=${checklistFileAttachmentGuid}`, {
        headers: (authHeader && new Headers({ Authorization: authHeader })) || null,
    })
        .then(function (response) {
            return response.blob().then(function (blob) {
                download(blob, decodeURIComponentSafe(fileName,1));
            });
        });
  }

  export function downloadChecklistQuestionnaireAttachment(checklistId,clientId,checklistFileAttachmentGuid, fileName,authHeader) {
    return fetch(`/customChecklistTemplate/DownloadChecklistQuestionnaireAttachment?checklistId=${checklistId}&clientId=${clientId}&attachmentName=${fileName}&attachmentGUID=${checklistFileAttachmentGuid}`, {
        headers: (authHeader && new Headers({ Authorization: authHeader })) || null,
    })
        .then(function (response) {
            return response.blob().then(function (blob) {
                download(blob, decodeURIComponentSafe(fileName,1));
            });
        });
  }


/**
 * Dispatches the UPLOAD_FILE_CLEAR action
 * @returns {function} A function that dispatches the UPLOAD_FILE_CLEAR action
 */
export function clearUploadFileSuccess() {
    return function (dispatch) {
        dispatch({ type: actions.UPLOAD_FILE_CLEAR });
    };
}

/**
 * Deletes a file belonging to a period
 * @param {number} periodId The period ID
 * @param {any} fileName The file name
 * @returns {any} A Promise with the response content
 */
export function deleteFile(periodId, fileName) {
    let body = JSON.stringify({ periodId, fileName: decodeURI(decodeURIComponentSafe(fileName,1)) });
    return fetch('/files',
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            body: body
        }).then(response => {
            if (response.ok) {
                return null;
            } else {
                return response.json();
            }
        });
}

/**
 * Deletes a file belonging to a REIT
 * @param {number} reitId The REIT ID
 * @param {any} fileName The file name
 * @returns {any} A Promise with the response content
 */
export function deleteReitFile(reitId, fileName) {
    let body = JSON.stringify({ reitId, fileName: decodeURI(decodeURIComponentSafe(fileName,1)) });
    return fetch('/files/reit',
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
            body: body
        }).then(response => {
            if (response.ok) {
                return null;
            } else {
                return response.json();
            }
        });
}