import React from "react";
import PropTypes from "prop-types";
import AuthListRow from "./AuthListRow";
import AddClientAuthModal from "./AddClientAuthModal";
import EditClientAuthModal from "./EditClientAuthModal";
import { MotifButton, MotifSearch, MotifFormField, MotifLabel } from "@ey-xd/motif-react";

const ClientAuthList = ({ auths, handleSearchChange, handleClearSearchText, clientUserSearchText, handleEditButtonClick, handleDeleteButtonClick,
    handleNewButtonClick, showAddClientAuthModal, handleAddClientAuthModalAction, auth, toggleShowAddClientAuthModal, currentUserId, showEditClientAuthModal,
    handleEditClientAuthModalAction, toggleShowEditClientAuthModal, currentUserIsSysAdmin, addAuthMessageText, numberOfEngagementAdmins, validateDomain,
    validateDomainWithRole, clientAuthFormValues,
    handleAddClientAuthModalValidateAction, validatingUser, myEyError,handleDailyNewsletter,receiveDailyNewsletter,receiveWeeklyNewsletter,handleWeeklyNewsletter,receiveRealTimeNotification
    ,handleRealTimeNotification,  receiveRealTimeNotificationForNewPsqArqcSent,
    handleInternalReviewNotification,handleOnRoleTypeChange
}) => {
    return (
        <section className="clientAuths">
            <div className="row">
                <div className="col-md-6">
                    <h2>Authorized Users</h2>
                </div>
                <div className="col-md-4">
                    <MotifFormField>
                        <MotifLabel htmlFor="searchAuthorizedUser">
                            Search for User
                            </MotifLabel>
                        <MotifSearch
                            type="text"
                            onChange={handleSearchChange}
                            value={clientUserSearchText}
                            title="Type in a user name"
                            aria-label="Search for User"
                            searchButtonProps={{tabIndex:"-1"}} />
                    </MotifFormField>
                </div>
                <div className="col-md-2">
                    <MotifButton variant="secondary" className="right" type="button"
                        onClick={handleNewButtonClick}>
                        Add Authorized User
                    </MotifButton>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <section className="table-responsive rtable">
                        <table>
                            <thead>
                                <tr role="row" aria-rowindex={1}>
                                    <th role="columnheader" aria-sort="none" aria-colindex={1}>User</th>
                                    <th role="columnheader" aria-sort="none" aria-colindex={2}>Role</th>
                                    <th role="columnheader" aria-sort="none" aria-colindex={3}>Daily Newsletter</th>
                                    <th role="columnheader" aria-sort="none" aria-colindex={4}>Weekly Newsletter</th>
                                    <th role="columnheader" aria-sort="none" aria-colindex={5}>Reopen Submitted or Closed Checklist</th>
                                    <th role="columnheader" aria-sort="none" aria-colindex={6}>PSQ/ARQC Sent/Internal Review Notification</th>
                                    <th role="columnheader" aria-sort="none" aria-colindex={7} />
                                    <th role="columnheader" aria-sort="none" aria-colindex={8} />
                                </tr>
                            </thead>
                            <tbody>
                                {auths.map((auth, index) => <AuthListRow rowIndex={index+2} key={auth.id} auth={auth} handleEditButtonClick={handleEditButtonClick}
                                    handleDeleteButtonClick={handleDeleteButtonClick} currentUserId={currentUserId} authResource="client" currentUserIsSysAdmin={currentUserIsSysAdmin} numberOfEngagementAdmins={numberOfEngagementAdmins} />)}
                            </tbody>
                            {((auths && auths.length === 0 && clientUserSearchText !== "") && <div><h4>No users match your search text '{clientUserSearchText}'</h4></div>) || null}
                        </table>
                    </section>
                </div>
            </div>
            <AddClientAuthModal
                showAddClientAuthModal={showAddClientAuthModal}
                onSubmit={handleAddClientAuthModalAction}
                toggleShowAddClientAuthModal={toggleShowAddClientAuthModal}
                initialValues={auth}
                addAuthMessageText={addAuthMessageText}
                validateDomainWithRole={validateDomainWithRole}
                clientAuthFormValues={clientAuthFormValues}
                handleAddClientAuthModalValidateAction={handleAddClientAuthModalValidateAction}
                validatingUser={validatingUser}
                myEyError={myEyError}
                handleDailyNewsletter={handleDailyNewsletter}
                receiveDailyNewsletter={receiveDailyNewsletter}
                handleWeeklyNewsletter={handleWeeklyNewsletter}
                receiveWeeklyNewsletter={receiveWeeklyNewsletter}
                handleRealTimeNotification={handleRealTimeNotification}
                receiveRealTimeNotification={receiveRealTimeNotification}
                receiveRealTimeNotificationForNewPsqArqcSent= {receiveRealTimeNotificationForNewPsqArqcSent}
                handleInternalReviewNotification= {handleInternalReviewNotification}
                handleOnRoleTypeChange={handleOnRoleTypeChange}
                />
            <EditClientAuthModal
                showEditClientAuthModal={showEditClientAuthModal}
                onSubmit={handleEditClientAuthModalAction}
                toggleShowEditClientAuthModal={toggleShowEditClientAuthModal}
                initialValues={auth}
                clientAuthFormValues={clientAuthFormValues}
                handleDailyNewsletter={handleDailyNewsletter}
                receiveDailyNewsletter={receiveDailyNewsletter}
                handleWeeklyNewsletter={handleWeeklyNewsletter}
                receiveWeeklyNewsletter={receiveWeeklyNewsletter}
                handleRealTimeNotification={handleRealTimeNotification}
                receiveRealTimeNotification={receiveRealTimeNotification}
                receiveRealTimeNotificationForNewPsqArqcSent= {receiveRealTimeNotificationForNewPsqArqcSent}
                handleInternalReviewNotification= {handleInternalReviewNotification}
                />
        </section>
    );
};

ClientAuthList.propTypes = {
    auths: PropTypes.array.isRequired,
    handleSearchChange: PropTypes.func,
    handleClearSearchText: PropTypes.func,
    clientUserSearchText: PropTypes.string,
    handleEditButtonClick: PropTypes.func,
    handleDeleteButtonClick: PropTypes.func,
    handleNewButtonClick: PropTypes.func,
    showAddClientAuthModal: PropTypes.bool,
    handleAddClientAuthModalAction: PropTypes.func,
    auth: PropTypes.object,
    toggleShowAddClientAuthModal: PropTypes.func,
    currentUserId: PropTypes.string,
    showEditClientAuthModal: PropTypes.bool,
    handleEditClientAuthModalAction: PropTypes.func,
    toggleShowEditClientAuthModal: PropTypes.func,
    currentUserIsSysAdmin: PropTypes.bool,
    addAuthMessageText: PropTypes.string,
    numberOfEngagementAdmins: PropTypes.number,
    validateDomain: PropTypes.func,
    clientAuthFormValues: PropTypes.object,
    handleAddClientAuthModalValidateAction: PropTypes.func,
    validatingUser: PropTypes.bool,
    myEyError: PropTypes.string,
    handleDailyNewsletter:PropTypes.func,
    receiveDailyNewsletter:PropTypes.bool,
    handleWeeklyNewsletter:PropTypes.func,
    receiveWeeklyNewsletter:PropTypes.bool,
    receiveRealTimeNotification: PropTypes.bool,
    handleRealTimeNotification: PropTypes.func,
    receiveRealTimeNotificationForNewPsqArqcSent: PropTypes.bool,
    handleInternalReviewNotification: PropTypes.func,
    handleOnRoleTypeChange:PropTypes.func
};

export default ClientAuthList;