import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Message } from 'semantic-ui-react';
import ReportExport from '../../components/reports/ReportExport';
import ReportItemList from '../../components/reports/ReportItemList';
import SelectedReportItems from '../../components/reports/SelectedReportItems';
import * as reportActions from '../../actions/reportActions';
import * as customFdrActions from '../../actions/customFdrTemplateActions';
import * as PeriodActions from "../../actions/periodActions";
import * as commonActions from "../../actions/commonActions";
import * as checklistActions from "../../actions/checklistActions";
import * as trialBalanceActions from "../../actions/trialBalanceActions";
import * as distributionsTestActions from "../../actions/distributionsTestActions";
import * as servicesActions from "../../actions/servicesActions";
import * as actionHelpers from '../../scripts/actionHelpers';
import * as serviceConstants from '../../constants/services';

/**
 * ReportPage container component
 * @extends {React.Component}
 */
export class ReportPage extends React.Component {
    /**
     * Creates a new ReportPage
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);

        this.state = {
            selectedItems: [],
            excludedPsqs: [],
            isReportingClosed: false,
            adjTrialBalanceUploaded: false,
            tbMode: 1,
            scopedServices: [],
            dataIsLoaded: false,
            includePsqComments: false,
            includeArqcComments: false,
            selectedTemplate: { templateId: -2, templateName: 'Standard Template' }
        };

        this.downloadReport = this.downloadReport.bind(this);
        this.handleTemplateSelect = this.handleTemplateSelect.bind(this);
        this.setItemSelection = this.setItemSelection.bind(this);
        this.setPsqInclusion = this.setPsqInclusion.bind(this);
        this.clearPsqItemIfAllExcluded = this.clearPsqItemIfAllExcluded.bind(this);
        this.setIncludePsqComments = this.setIncludePsqComments.bind(this);
        this.setIncludeArqcComments = this.setIncludeArqcComments.bind(this);
    }

    /**
     * Invoked immediately after mounting occurs
     * @returns {undefined}
     */
    componentDidMount() {
        // Check if state.period is null, if it is populate period
        if (!this.props.period) {
            this.props.periodActions.fetchPeriodById(this.props.periodId);
        }
        else {
            // Currently only retrieving REIT Trial Balance for the download. This call will need to pass/accept property ID once we have property TBs.
            trialBalanceActions.getTrialBalancesByPeriod(this.props.periodId, 'adjusted', 'consolidated').then((trialBalances) => {
                this.setState({ adjTrialBalanceUploaded: (Array.isArray(trialBalances) && trialBalances.length > 0) ? true : false });
                this.setIsReportingClosed(this.props.period);
            });
        }

        this.props.customFdrActions.fetchCustomFdrTemplates(this.props.clientId);
        
        this.props.actions.fetchReportItems(this.props.periodId);
        this.props.checklistActions.fetchPsqChecklistSummariesByPeriod(this.props.periodId);
        this.props.checklistActions.fetchArqcChecklistSummariesByPeriod(this.props.periodId);

        

        servicesActions.fetchServicesByPeriodWithoutDispatch(this.props.periodId).then((services) => {
            if (actionHelpers.isErrorResponse(services)) {
                return;
            }

            if (Array.isArray(services)) {
                this.setState({ scopedServices: services });
            }
        });
    }

    /**
     * Invoked before a mounted component receives new props.
     * @param {Object} nextProps The properties that the component is receiving
     */
    componentWillReceiveProps(nextProps) {
        if (nextProps.period !== this.props.period) {
            this.setIsReportingClosed(nextProps.period);
            if (nextProps.period && nextProps.period.trialBalanceModeID === 2) {
                this.setState({ tbMode: 2 });
            } else {
                this.setState({ tbMode: 1 });
            }
        }
        if (nextProps.period.reportPeriodDescription !== '' && nextProps.period !== this.props.period && nextProps.period.reportPeriodDescription !== undefined) {
            this.props.customFdrActions.getDefaultFdrTemplateForPeriod(nextProps.clientId, nextProps.params.reitId, nextProps.period.reportPeriodDescription.substring(0, 2)).then(template => {
                if (template && template.fdrTemplateID > 0) {
                    this.setState({
                        selectedTemplate: { templateId: template.fdrTemplateID, templateName: template.fdrTemplateName }
                    });
                }
                else {
                    this.setState({
                        selectedTemplate: { templateId: template.fdrTemplateID, templateName: 'Standard Template' }
                    });
                }
            });
        }

        if ((nextProps.availableReportItems !== this.props.availableReportItems
            && Array.isArray(nextProps.availableReportItems)
            && nextProps.availableReportItems.length > 0)
            || (nextProps.selectedReportItems !== this.props.selectedReportItems
                && Array.isArray(nextProps.selectedReportItems)
                && nextProps.selectedReportItems.length > 0)) {

            const selectedItems = JSON.parse(JSON.stringify(this.state.selectedItems));
            for (let i = 0; i < nextProps.availableReportItems.length; i++) {
                if (nextProps.availableReportItems[i].isRequired && selectedItems.find(x => x === nextProps.availableReportItems[i].type) === undefined) {
                    selectedItems.push(nextProps.availableReportItems[i].type);
                }
            }

            this.setState({ selectedItems: selectedItems, dataIsLoaded: true });
        }
    }
    
    setIncludePsqComments(includePsqComments)
    {
        this.setState({includePsqComments});
    }

    setIncludeArqcComments(includeArqcComments)
    {
        this.setState({includeArqcComments});
    }

    /**
     * Download the trial balance
     * @param {number} periodId The id of the period
     * @param {array} reportItems The report components to be included in the report
     * @param {array} excludedPsqs The ids of the PSQs to be excluded
     */
    downloadReport(periodId, reportItems, excludedPsqs) {
        this.props.commonActions.clearErrorResponse();

        // -2 = Standard Template, else Custom FDR Template
        if (this.state.selectedTemplate.templateId < 0) {
            this.props.actions.downloadReport(periodId, reportItems, excludedPsqs, this.state.includePsqComments, this.state.includeArqcComments);
        } else {
            // For custom fdr, we only want to include ARQC/PSQ items
            const filteredReportItems = reportItems.filter(item => item === 'PsqResults' || item === 'ArqcResults');

            return this.props.customFdrActions
                .downloadFdrTemplateDocument(this.state.selectedTemplate.templateId, periodId, filteredReportItems, excludedPsqs, this.state.includePsqComments, this.state.includeArqcComments, this.state.selectedTemplate.templateName);
        }
    }

    setItemSelection(itemType, selected) {
        const currentSelectedItems = this.state.selectedItems;

        if(selected) {
            // Use a set to prevent duplicates.
            const newItemSet = new Set([...currentSelectedItems, itemType]);
            this.setState({selectedItems : [...newItemSet]});
        } else {
            const selectedItems = 
                currentSelectedItems.filter(_type => _type !== itemType);

            this.setState({selectedItems});
        }
    }

    setPsqInclusion(checklistID, included)
    {
        const currentExcludedPsqs = this.state.excludedPsqs;

        if(included) {
            const excludedPsqs = 
                currentExcludedPsqs.filter(id => id !== checklistID);

            this.setState({excludedPsqs});
        } else {
            // Use a Set to prevent duplicates.
            const newExcludedSet = 
                new Set([...currentExcludedPsqs, checklistID]);
            
            const excludedPsqs = [...newExcludedSet];
            this.setState({ excludedPsqs }, () => this.clearPsqItemIfAllExcluded());
        }
    }

    clearPsqItemIfAllExcluded()
    {
        const {excludedPsqs} = this.state;
        const {psqChecklists} = this.props;

        if(excludedPsqs.length === psqChecklists.length)
        {
            const selectedItems = 
                this.state.selectedItems.filter(i => i !== 'PsqResults');
            
            const excludedPsqs = [];

            this.setState({selectedItems, excludedPsqs});
        }
    }

    setIsReportingClosed(period) {
        const isEnabled = PeriodActions.isReportingEnabled(period);
        this.setState({ isReportingClosed: !isEnabled });
    }

    handleTemplateSelect(newValue) {
        const selectedItems =
            this.state.selectedItems
                .filter(item => item != "ArqcResults" && item != "PsqResults");

        this.setState({
            selectedTemplate: {
                templateId: newValue.value,
                templateName: newValue.label
            },
            selectedItems,
            excludedPsqs: []
        });
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        const isNonEmptyArray = 
            _array => Array.isArray(_array) && _array.length > 0;

        return (
            <div>
                <h1>Final Deliverable Report</h1>
                <div style={{ textAlign: 'center', width: '100%' }} className="text-danger">
                    {!this.state.isReportingClosed ? "" : <Message negative>Reporting Closed</Message>}
                </div>
                <div>
                <ReportItemList
                    availableReportItems={this.props.availableReportItems}
                    selectedItems={this.state.selectedItems}
                    scopedServices={this.state.scopedServices}
                    hasTrialBalance={this.state.adjTrialBalanceUploaded}
                    psqChecklists={this.props.psqChecklists}
                    hasArqc={isNonEmptyArray(this.props.arqcChecklists)}
                    clientIsAudit={this.props.clientIsAudit}
                    setItemSelection={this.setItemSelection}
                    excludedPsqs={this.state.excludedPsqs}
                    setPsqInclusion={this.setPsqInclusion}
                    includePsqComments={this.state.includePsqComments}
                    setIncludePsqComments={this.setIncludePsqComments}
                    includeArqcComments={this.state.includeArqcComments}
                    setIncludeArqcComments={this.setIncludeArqcComments}
                    customFdrTemplates={this.props.customFdrTemplate}
                    handleTemplateSelect={this.handleTemplateSelect}
                    selectedTemplate={this.state.selectedTemplate} />
                </div>
                <div>
                    <div style={{ textAlign: 'center', width: '100%', minHeight: '65px' }}>
                        <div style={{ textAlign: 'right', width: '400px', marginLeft: 'auto', marginRight: 'auto' }}>
                            <ReportExport
                                periodId={this.props.periodId}
                                onExportClick={this.downloadReport}
                                selectedReportItems={this.state.selectedItems}
                                excludedPsqs={this.state.excludedPsqs}
                                canReport={!this.state.isReportingClosed}
                                />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ReportPage.propTypes = {
    periodId: PropTypes.number.isRequired,
    period: PropTypes.object,
    availableReportItems: PropTypes.array,
    selectedReportItems: PropTypes.array,
    actions: PropTypes.object,
    periodActions: PropTypes.object.isRequired,
    psqChecklists: PropTypes.array,
    arqcChecklists: PropTypes.array,
    checklistActions: PropTypes.object.isRequired,
    commonActions: PropTypes.object.isRequired,
    distributionsTestActions: PropTypes.object.isRequired,
    distributionDetails: PropTypes.array
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    return {
        periodId: Number.parseInt(ownProps.params.periodId),
        availableReportItems: state.availableReportItems,
        selectedReportItems: state.selectedReportItems,
        period: state.period,
        psqChecklists: state.psqChecklists,
        arqcChecklists: state.arqcChecklists,
        distributionDetails: state.distributionDetails,
        clientIsAudit: state.client && state.client.isAudit,
        clientId: Number.parseInt(ownProps.params.clientId),
        customFdrTemplate: state.customFdrTemplate
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(reportActions, dispatch),
        customFdrActions: bindActionCreators(customFdrActions, dispatch),
        periodActions: bindActionCreators(PeriodActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
        trialBalanceActions: bindActionCreators(trialBalanceActions, dispatch),
        checklistActions: bindActionCreators(checklistActions, dispatch),
        distributionsTestActions: bindActionCreators(distributionsTestActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportPage);