import React from 'react';

export default (props) => {
  let rowsChecked = 0;
  let totalRows = 0;

  // Check for total rows and rows checked within filtered rows
  props.api.forEachNodeAfterFilter((rowNode) => {
    if (rowNode.data && rowNode.data.isReitAvailableForSelection) {
      totalRows++;
      if (rowNode.data.reitSelection){
        rowsChecked++;
      }
    }
  });

  let headerCheckboxValue = false;
  const defaultCheckboxClass = "ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper";
  let checkboxClass = defaultCheckboxClass;

  if (rowsChecked === 0) {
    headerCheckboxValue = false;
  }
  else if (totalRows === rowsChecked) {
    headerCheckboxValue = true;
    checkboxClass = defaultCheckboxClass + " ag-checked";
  }
  else {
    headerCheckboxValue = false;
    checkboxClass = defaultCheckboxClass + " ag-indeterminate";
  }

  return (
    <div>
      <div className={checkboxClass} onClick={()=>props.onHeaderCheckboxChange(headerCheckboxValue)} role="presentation">
          <input class="ag-input-field-input ag-checkbox-input" type="checkbox" id="ag-header-checkbox" 
          aria-label="Press Space to toggle row selection" checked={headerCheckboxValue} tabindex="-1" data-testid="selectCheckboxHeader"/>
      </div>
      <div className="customHeaderLabel">{props.displayName}</div>
    </div>
  );
};