import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../actions/trialBalanceActions";
import * as checklistActions from "../../actions/checklistActions";
import * as propertyActions from "../../actions/propertyActions";
import * as servicesActions from "../../actions/servicesActions";
import * as reitActions from '../../actions/reitActions';
import * as chartOfAccountsActions from '../../actions/chartOfAccountsActions';
import * as testSnapshotActions from "../../actions/testSnapshotActions";
import * as commonActions from "../../actions/commonActions";
import ReportPeriodRow from "../../components/reits/ReportPeriodRow";
import * as authActions from '../../actions/authActions';
import * as actionHelpers from '../../scripts/actionHelpers';
import * as serviceConstants from '../../constants/services';

/**
 * The container that renders ReportPeriodRow
 */
export class ReportPeriodRowContainer extends React.Component {
    /**
     * Creates a new ReportPeriodRowContainer
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);

        this.state = {
            trialBalances: [],
            hasIncompleteChecklists: false,
            hideTbModeLink: false,
            hideTestSnapshotLink: true
        };

        this.setHideTestSnapshotLink = this.setHideTestSnapshotLink.bind(this);
        this.handleDownloadCurrentTestSnapshot = this.handleDownloadCurrentTestSnapshot.bind(this);
        this.handleDownloadTbTemplates = this.handleDownloadTbTemplates.bind(this);
        this.handleDownloadMappingTemplatePackage = this.handleDownloadMappingTemplatePackage.bind(this);
    }

    /**
     * Invoked immediately after mounting occurs
     * @returns {undefined}
     */
    componentDidMount() {
        // Currently only retrieving REIT Trial Balance for the download. This call will need to pass/accept property ID once we have property TBs.
        actions.getTrialBalancesByPeriod(this.props.period.reportPeriodID, 'adjusted', 'consolidated').then((trialBalances) => {
            this.setState({ trialBalances: trialBalances });
        });

        checklistActions.fetchHasIncompleteCheckistByPeriod(this.props.period.reportPeriodID).then((data) => {
            this.setState({ hasIncompleteChecklists: data });
        });

        propertyActions.fastFetchPropertiesByPeriod(this.props.period.reportPeriodID).then((properties) => {
            if (properties && properties.length > 1) {
                this.setState({ hideTbModeLink: true });
            }
        });

        this.setHideTestSnapshotLink();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.period.trialBalanceModeID !== this.props.period.trialBalanceModeID) {
            actions.getTrialBalancesByPeriod(nextProps.period.reportPeriodID, 'adjusted', 'consolidated').then((trialBalances) => {
                this.setState({ trialBalances: trialBalances });
            });
        }        

        if (nextProps.servicesInState != this.props.servicesInState) {
            this.setHideTestSnapshotLink();
        }
    }

    setHideTestSnapshotLink() {
        servicesActions.fetchServicesByPeriodWithoutDispatch(this.props.period.reportPeriodID).then((services) => {
            if (actionHelpers.isErrorResponse(services)) {
                return;
            }

            if (Array.isArray(services) && services.some(x => x === serviceConstants.ASSET_TEST_SERVICE || x === serviceConstants.INCOME_TEST_SERVICE || x === serviceConstants.DISTRIBUTION_TEST_SERVICE)) {
                this.setState({ hideTestSnapshotLink: false });
            } else {
                this.setState({ hideTestSnapshotLink: true });
            }
        });
    }

    /**
     * Download the trial balance
     * @param {any} trialBalanceId The id of the trial balance
     * @param {any} fileName The file name to save the trial balance as
     */
    downloadTrialBalance(trialBalanceId, fileName) {
        actions.downloadTrialBalance(trialBalanceId, fileName);
    }

    handleDownloadCurrentTestSnapshot(fileName) {
        this.props.commonActions.beginTask();
        return testSnapshotActions.downloadCurrentTestSnapshot(this.props.period.reportPeriodID, fileName)
            .then((blob) => {
                this.props.commonActions.endTask();
                return blob;
            });
    }

    handleDownloadTbTemplates(clientId, periodId) {
        return this.props.actions.downloadTbTemplates(clientId, false, periodId).then((blob) => {           
            return blob;
        });
    }

    handleDownloadMappingTemplatePackage(periodId) {
        this.props.commonActions.beginTask();
        return chartOfAccountsActions.downloadMappingTemplatePackage(periodId).then((blob) => {
            this.props.commonActions.endTask();
            return blob;
        });
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        let isEngAdminOrSysAdmin = false;
        if (Array.isArray(this.props.currentUserAuthorizations)) {
            isEngAdminOrSysAdmin = authActions.isSystemAdministrator(this.props.currentUserAuthorizations) || authActions.isEngagementAdministrator(this.props.currentUserAuthorizations, this.props.clientId);
        }

        return (
            <ReportPeriodRow
                period={this.props.period}
                reitId={this.props.reitId}
                reit={this.props.reit}
                clientId={this.props.clientId}
                trialBalances={this.state.trialBalances}
                downloadFunction={this.downloadTrialBalance}
                handleActivePeriodIdChanged={this.props.handleActivePeriodIdChanged}
                toggleShowClosePeriodModal={this.props.toggleShowClosePeriodModal}
                hasIncompleteChecklists={this.state.hasIncompleteChecklists}
                toggleShowOpenPeriodModal={this.props.toggleShowOpenPeriodModal}
                isEngAdminOrSysAdmin={isEngAdminOrSysAdmin}
                tbMode={this.props.tbMode}
                toggleShowModeModal={this.props.toggleShowModeModal}
                hideTbModeLink={this.state.hideTbModeLink}
                hideTestSnapshotLink={this.state.hideTestSnapshotLink}
                handleDownloadCurrentTestSnapshot={this.handleDownloadCurrentTestSnapshot}
                handleDownloadTbTemplates={this.handleDownloadTbTemplates}
                handleDownloadMappingTemplatePackage={this.handleDownloadMappingTemplatePackage}
                clientIsAudit={this.props.clientIsAudit}
                chartExistsForClient={this.props.chartExistsForClient}                
            />
        );
    }
}

ReportPeriodRowContainer.propTypes = {
    clientId: PropTypes.number.isRequired,
    reitId: PropTypes.number.isRequired,
    reit: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
    }),
    period: PropTypes.shape({
        reportPeriodID: PropTypes.number.isRequired
    }),
    trialBalances: PropTypes.shape({
        id: PropTypes.number.isRequired,
        type: PropTypes.string.isRequired
    }),
    handleActivePeriodIdChanged: PropTypes.func.isRequired,
    toggleShowClosePeriodModal: PropTypes.func,
    toggleShowOpenPeriodModal: PropTypes.func,
    currentUserAuthorizations: PropTypes.array,
    tbMode: PropTypes.number,
    toggleShowModeModal: PropTypes.func,
    servicesInState: PropTypes.array,
    chartOfAccountsActions: PropTypes.object,
    commonActions: PropTypes.object
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    return {
        clientId: ownProps.clientId,
        reitId: ownProps.reitId,
        reit: ownProps.reit,
        period: ownProps.period,
        handleActivePeriodIdChanged: ownProps.handleActivePeriodIdChanged,
        toggleShowClosePeriodModal: ownProps.toggleShowClosePeriodModal,
        toggleShowOpenPeriodModal: ownProps.toggleShowOpenPeriodModal,
        tbMode: ownProps.tbMode,
        toggleShowModeModal: ownProps.toggleShowModeModal,
        currentUserAuthorizations: state.currentUserAuthorizations,
        servicesInState: state.services,
        clientIsAudit: state.client && state.client.isAudit,
        chartExistsForClient: ownProps.chartExistsForClient
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        chartOfAccountsActions: bindActionCreators(chartOfAccountsActions, dispatch),
        actions: bindActionCreators(actions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportPeriodRowContainer);