import PropTypes from 'prop-types';
import React from 'react';
import {
  MotifIcon,
  MotifFileUploaderItem,
  MotifIconButton
} from '@ey-xd/motif-react';
import { fileIcFileDownload24px,actionIcDescription24px } from '@ey-xd/motif-react/assets/icons';

const CustomQuestionAttachmentList = ({
  attachmentList,
  handleDownloadAttachmentClick,
  className
}) => {
   function onDownloadClick(e,fileName,attachmentGuid)
  {
    e.preventDefault();
    handleDownloadAttachmentClick(attachmentGuid,fileName);
  }
  return (
    <React.Fragment>
  <div data-testid="attachmentList"> 
          {attachmentList &&
            attachmentList.length > 0 &&
            attachmentList.map((attachmentData) => (
              <MotifFileUploaderItem
              className={className}
              fileIcon={<MotifIcon src={actionIcDescription24px} />}
              title={attachmentData.attachmentName}
              customButton={ <MotifIconButton aria-label="Download Attachment" type="button" onClick={(e) =>
                onDownloadClick(e,attachmentData.attachmentName,attachmentData.attachmentGuid)}>  
              <MotifIcon src={fileIcFileDownload24px} title="Download Attachment" />

          </MotifIconButton>}
              fileName={attachmentData.attachmentName}
              fileSize={1}
            />
            ))}
        </div>
    </React.Fragment>
  );
};

CustomQuestionAttachmentList.propTypes = {
  attachmentList: PropTypes.array,
  handleDownloadAttachmentClick:PropTypes.func,
  className:PropTypes.string
};

export default CustomQuestionAttachmentList;