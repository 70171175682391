import React from "react";
import PropTypes from "prop-types";
import { Header, Button } from "semantic-ui-react";
import ReactModal from "react-modal";
import { MotifIconButton, MotifIcon, MotifButton } from "@ey-xd/motif-react";

const EditSaveModalMessage = ({
    showEditSaveModalMessage,
    toggleShowEditSaveModalMessage,
    handleAccountUpdateConfirmation
}) => {
    return (
        <ReactModal contentLabel="Edit Account Notification" isOpen={showEditSaveModalMessage} className="react-modal react-modal-medium" overlayClassName="react-modal-overlay">
            <Header content="Edit Client Accounts Confirmation" />
            <form>
                <h6>Changes will be processed as follows on submit:</h6>
                <br />
                <ol>
                    <li>New accounts will be all created on all assignments for this chart (including closed periods).</li>
                    <li>Updates to account numbers and account descriptions will be processed to all assignments for this chart (including closed periods).</li>
                    <li>Changes to the default REIT testing attribute will not be pushed to any assignments for this chart – default mappings are only assigned at REIT creation or can be restored on the Account Mappings screen for each REIT period.</li>
                </ol>
                <span><b>Note:</b> If you do not want to make changes to a historical period, please create a new chart.</span>
                <br />                
                <MotifButton variant="primary" className="right ml-2" type="button"
                    onClick={handleAccountUpdateConfirmation}>
                    Submit
                </MotifButton>                
                <MotifButton variant="secondary" className="right" type="button"
                    onClick={toggleShowEditSaveModalMessage} >
                    Cancel
                </MotifButton>
            </form>
        </ReactModal>
    );
};

EditSaveModalMessage.propTypes = {
    showEditSaveModalMessage: PropTypes.bool,
    toggleShowEditSaveModalMessage: PropTypes.func,
    handleAccountUpdateConfirmation: PropTypes.func
};

export default EditSaveModalMessage;