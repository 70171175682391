import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";

import { AgGridReact } from 'ag-grid-react';
import { MotifPagination, MotifPaginationSelect, MotifPaginationSelectItem } from "@ey-xd/motif-react";

import CustomHeaderTemplate from '../shared/customHeaders/CustomHeaderTemplate.js';
import ReitCheckboxHeaderRenderer from "./customRenderes/ReitCheckboxHeaderRenderer.js";
import ReitCheckboxCellRenderer from "./customRenderes/ReitCheckboxCellRenderer.js";
import AppliedServiceScopeCellRenderer from './customRenderes/AppliedServiceScopeCellRenderer.js';


const BulkProcessManagementTable = ({
    bulkProcessManagementData,
    gridColumnState,
    handleGridChanges,
    handleGridColumnStateChange,
}) => {
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);
    const rowDataRef = useRef({});
    rowDataRef.current = rowData;

    const onHeaderCheckboxChange = (isHeaderCheckboxSelected) => {
        let updatedData = rowDataRef.current;
        if (gridRef && gridRef.current && gridRef.current.api) {
            // Select checkbox for filtered rows only
            gridRef.current.api.forEachNodeAfterFilter((rowNode) => {
                // Invert the selection status for each row
                if (rowNode.level === 0) {
                    updatedData = rowDataRef.current.map(newUd => {
                        if (newUd.isReitAvailableForSelection) {
                            newUd.reitSelection = !isHeaderCheckboxSelected;
                            return newUd;
                        }

                        return newUd;
                    });
                }
            });

            setRowData(updatedData);
            gridRef.current.api.redrawRows();
            gridRef.current.api.refreshHeader();
        }
    };

    const onCellCheckboxChange = (reitId, rowNode) => {
        let updatedData = rowDataRef.current.map(newUd => {
            if (newUd.reitid == reitId) {
                newUd.reitSelection = !newUd.reitSelection;
            }
            return newUd;
        });

        setRowData(updatedData);
        gridRef.current.api.redrawRows(rowNode);
        gridRef.current.api.refreshHeader();
    };

    //Default column settings
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 65,
            filter: true
        };
    }, []);

    const getColumnDefs = () => {
        return [
            {
                field: 'reitSelection', headerName: '', suppressMenu: true,
                headerComponent: ReitCheckboxHeaderRenderer,
                headerComponentParams: { onHeaderCheckboxChange: onHeaderCheckboxChange },
                cellRenderer: ReitCheckboxCellRenderer,
                cellRendererParams: { onCellCheckboxChange: onCellCheckboxChange },
                maxWidth: 54,
                spanHeaderHeight: true,
                resizable: true
            },
            {
                field: 'reit', headerName: 'REIT', sortable: true, showRowGroup: false, rowGroup: false,
                minWidth: 300,
                valueGetter: params => {
                    return params.data && params.data.reitName;
                },
                filterValueGetter: params => { return params.data && params.data.reitName; },
                headerComponentParams: { template: CustomHeaderTemplate('reit') },
                spanHeaderHeight: true,
                resizable: true
            },
            {
                headerName: 'Services Scope',
                marryChildren: true,
                children: [
                    {
                        field: 'assetTesting', headerName: 'Asset Testing', sortable: true, showRowGroup: false, hide: false, rowGroup: false,
                        minWidth: 100,
                        valueGetter: params => { return params.data && params.data.assetTesting; },
                        filterValueGetter: params => { return params.data && params.data.assetTesting; },
                        cellRenderer: AppliedServiceScopeCellRenderer,
                        headerComponentParams: { template: CustomHeaderTemplate('assetTesting') },
                        cellStyle: { textAlign: 'center' },
                        resizable: true
                    },
                    {
                        field: 'incomeTesting', headerName: 'Income Testing', sortable: true, showRowGroup: false, hide: false, rowGroup: false,
                        minWidth: 100,
                        valueGetter: params => { return params.data && params.data.incomeTesting; },
                        filterValueGetter: params => { return params.data && params.data.incomeTesting; },
                        cellRenderer: AppliedServiceScopeCellRenderer,
                        headerComponentParams: { template: CustomHeaderTemplate('incomeTesting') },
                        cellStyle: { textAlign: 'center' },
                        resizable: true
                    },
                    {
                        field: 'distributionTesting', headerName: 'Distribution Testing', sortable: true, showRowGroup: false, hide: false, rowGroup: false,
                        minWidth: 100,
                        valueGetter: params => { return params.data && params.data.distributionTesting; },
                        filterValueGetter: params => { return params.data && params.data.distributionTesting; },
                        cellRenderer: AppliedServiceScopeCellRenderer,
                        headerComponentParams: { template: CustomHeaderTemplate('distributionTesting') },
                        cellStyle: { textAlign: 'center' },
                        resizable: true
                    },
                    {
                        field: 'psq', headerName: 'PSQ', sortable: true, showRowGroup: false, hide: false, rowGroup: false,
                        minWidth: 100,
                        valueGetter: params => { return params.data && params.data.psq; },
                        filterValueGetter: params => { return params.data && params.data.psq; },
                        cellRenderer: AppliedServiceScopeCellRenderer,
                        headerComponentParams: { template: CustomHeaderTemplate('psq') },
                        cellStyle: { textAlign: 'center' },
                        resizable: true
                    },
                    {
                        field: 'arqc', headerName: 'ARQC', sortable: true, showRowGroup: false, hide: false, rowGroup: false,
                        minWidth: 100,
                        valueGetter: params => { return params.data && params.data.arqc; },
                        filterValueGetter: params => { return params.data && params.data.arqc; },
                        cellRenderer: AppliedServiceScopeCellRenderer,
                        headerComponentParams: { template: CustomHeaderTemplate('arqc') },
                        cellStyle: { textAlign: 'center' },
                        resizable: true
                    },
                    {
                        field: 'leaseReview', headerName: 'Lease Review', sortable: true, showRowGroup: false, hide: false, rowGroup: false,
                        minWidth: 100,
                        valueGetter: params => { return params.data && params.data.leaseReview; },
                        filterValueGetter: params => { return params.data && params.data.leaseReview; },
                        cellRenderer: AppliedServiceScopeCellRenderer,
                        headerComponentParams: { template: CustomHeaderTemplate('leaseReview') },
                        cellStyle: { textAlign: 'center' },
                        resizable: true
                    },
                ]
            },
            {
                field: 'signOffStatus', headerName: 'Sign Off Status', sortable: true, showRowGroup: false, hide: false, rowGroup: false,
                minWidth: 200,
                valueGetter: params => { return params.data && params.data.signOffStatus; },
                filterValueGetter: params => { return params.data && params.data.signOffStatus; },
                headerComponentParams: { template: CustomHeaderTemplate('signOffStatus') },
                spanHeaderHeight: true,
                resizable: true
            },
        ];
    };

    const [columnDefs, setColumnDefs] = useState(getColumnDefs());
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(100);

    //Grid Global Listner
    const gridGlobalListner = function (type, event) {
        if (type.indexOf("columnVisible") >= 0) {
            handleGridColumnsChanged(event);
        }
    }

    //Handle Grid Ready event
    const handleGridReady = (event) => {
        //Remove event for column state change
        event.api.removeEventListener(gridGlobalListner);

        //Add event for column state change
        event.api.addGlobalListener(gridGlobalListner);

        //Apply column state
        var columnState = localStorage.getItem('REITSuiteBulkProcessManagementColumnState');
        if (columnState) {
            gridRef.current.columnApi.applyColumnState({
                state: JSON.parse(columnState),
                applyOrder: true,
            });
        }

        // Set the page size and total pages on the first load        
        handleItemsPerPageChange(100); // Setting it 100 to make sure the default number of items on load is always set to 100
    }

    // Handle OnFilterChanged event
    const handleFilterChanged = (event) => {
        let filteredRowData = [];
        if (gridRef && gridRef.current && gridRef.current.api) {
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);
            if (currentPage > calcTotalPages) {
                if (calcTotalPages == 0) {
                    setTimeout(function () { setCurrentPage(calcTotalPages); }, 0)
                }
                else {
                    setCurrentPage(calcTotalPages);
                }
            }
            const gridCurrentPage = gridRef.current.api.paginationGetCurrentPage() + 1; // Adding 1 since it returns zero based index
            if (currentPage < (gridCurrentPage) && calcTotalPages != 0) {
                setCurrentPage(gridCurrentPage);
            }
        }
    }

    //Handle First Data Renderered event
    const handleFirstDataRendered = (event) => {
        gridRef.current.columnApi.autoSizeAllColumns();
    }

    //Handle Grid Columns changed event
    const handleGridColumnsChanged = (event) => {
        var currentColumnState = gridRef.current.columnApi.getColumnState();
        var localStorageColumnState = localStorage.getItem('REITSuiteBulkProcessManagementColumnState');

        // If the column state details are not exists in local storage then save the changed Column state data
        if (currentColumnState && currentColumnState.filter(col => col.hide == true)?.length > 0 && !localStorageColumnState) {
            saveUserPreferenceDetails();
        }
        else if (localStorageColumnState) {
            // If the column state details are exists in local storage then save the changed Column state data only if difference is found
            var columnsStateArray = JSON.parse(localStorageColumnState);
            if (columnsStateArray && columnsStateArray.length > 0
                && currentColumnState && currentColumnState.length > 0
                && columnsStateArray.filter(col => col.hide == true)?.length != currentColumnState.filter(col => col.hide == true)?.length) {
                //If column state mismatch found then only save column state data to avoid saving same state
                saveUserPreferenceDetails();
            }
        }

        //Trigger Grid Column state change (of parent) to sync column state data
        handleGridColumnStateChange(currentColumnState);

        gridRef && gridRef.current && gridRef.current.columnApi && gridRef.current.columnApi.autoSizeAllColumns();
    }

    //Save User Preference details (Column state)
    const saveUserPreferenceDetails = useCallback(() => {
        var columnState = gridRef.current.columnApi.getColumnState();
        if (columnState) {
            localStorage.setItem('REITSuiteBulkProcessManagementColumnState', JSON.stringify(columnState));
        }
    }, []);

    //Handle Grid Items per Page change event
    const handleItemsPerPageChange = (val) => {
        gridRef.current.api.paginationGoToFirstPage();
        gridRef.current.api.paginationSetPageSize(Number(val));
        setCurrentPage(1);
        setItemsPerPage(val);
        // Get total pages from Grid's default pagination control and apply it to custom pagination control
        if (gridRef && gridRef.current && gridRef.current.api) {
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);
            //If total page is zero then set the current page as zero
            if (calcTotalPages == 0) {
                setCurrentPage(calcTotalPages);
            }
        }
    };

    //Handle Grid Page change event
    const handlePageChange = (val) => {
        setCurrentPage(val);
        gridRef.current.api.paginationGoToPage(val - 1);
    };

    useEffect(() => {
        handleGridChanges(rowData, gridRef && gridRef.current ? gridRef.current.api : null);
    }, [rowData]);

    useEffect(() => {
        setRowData(bulkProcessManagementData);

        if (gridRef && gridRef.current && gridRef.current.api) {
            gridRef.current.api.redrawRows();
            gridRef.current.api.refreshHeader();
            // Get total pages from Grid's default pagination control and apply it to custom pagination control
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);
        }
    }, [bulkProcessManagementData]);

    useEffect(() => {
        //Reset Grid to default column state
        //When Grid Column state changes due to Reset Column State action then Reset Grid to default column state
        var localStorageColumnState = localStorage.getItem('REITSuiteBulkProcessManagementColumnState');
        if (!localStorageColumnState && gridRef && gridRef.current && gridRef.current.api) {

            gridRef.current.api.setColumnDefs([]);
            gridRef.current.api.setColumnDefs(getColumnDefs());
        }
    }, [gridColumnState]);

    return (
        <>
            <div>
                <div className="row mb-10">
                    {bulkProcessManagementData && bulkProcessManagementData.length > 0 &&
                        <div className="col mt-3 bulk-processmanagement-grid">
                            <div className="ag-theme-alpine" style={{ height: 432 }}>
                                <AgGridReact
                                    rowData={rowData}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    accentedSort={true}
                                    rowSelection="multiple"
                                    suppressRowClickSelection={true}
                                    onGridReady={handleGridReady}
                                    onFirstDataRendered={handleFirstDataRendered}
                                    ref={gridRef}
                                    pagination={true}
                                    suppressPaginationPanel={true}
                                    paginationPageSize="100"
                                    groupDisplayType={'custom'}
                                    groupSelectsChildren={true}
                                    groupDefaultExpanded={0}
                                    onFilterChanged={handleFilterChanged}
                                    data-testid="divReportManagementGrid"
                                >
                                </AgGridReact>
                            </div>
                            <div className="mt-3 mr-4">
                                <span className="right">
                                    <MotifPagination currentPage={currentPage} onPageChange={handlePageChange} min={1} max={totalPages}>
                                        <MotifPaginationSelect data-testid="itemsPerPage">
                                            <MotifPaginationSelectItem onClick={() => handleItemsPerPageChange(50)}>
                                                Show 50
                                            </MotifPaginationSelectItem>
                                            <MotifPaginationSelectItem selected onClick={() => handleItemsPerPageChange(100)}>
                                                Show 100
                                            </MotifPaginationSelectItem>
                                            <MotifPaginationSelectItem onClick={() => handleItemsPerPageChange(150)}>
                                                Show 150
                                            </MotifPaginationSelectItem>
                                            <MotifPaginationSelectItem onClick={() => handleItemsPerPageChange(200)}>
                                                Show 200
                                            </MotifPaginationSelectItem>
                                        </MotifPaginationSelect>
                                    </MotifPagination>
                                    <div style={{ minHeight: '100px' }}></div>

                                </span>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    );
}

export default BulkProcessManagementTable;