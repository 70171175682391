import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import * as formValidators from "../../scripts/formValidators";
import * as parsers from "../../scripts/formParsers";
import * as states from "../../constants/states";
import { addAttribute } from "../../common/Attribute";
import { MotifModalHeader, MotifModalBody, MotifModalFooter } from "@ey-xd/motif-react"; 

import {
    DateField,
    MoneyField,
    NumberField,
    PhoneField
} from "../shared/formFields";

import PropertyAmenitiesTableContainer
    from "../../containers/shared/PropertyAmenitiesTableContainer";

import { MotifButton, MotifToast } from '@ey-xd/motif-react';

export const EditPropertyFields = ({formPurpose, lookupTypes, isTestingEnabled = true}) => {
    const [showPsqTypes, setShowPsqTypes] = useState(true);
    const [hideInfoMessage, setHideInfoMessage] = useState(false);

    const rerenderPsqTypes = function () {
        setShowPsqTypes(false);

        setTimeout(() => {
            setShowPsqTypes(true);
        });
    }

    return (
        <React.Fragment>
            <div className="row">
                <div className="field col-md-7">
                    <Field
                        name="propertyName"
                        component={formHelpers.renderField}
                        type="text"
                        label="Name"
                        maxLength={255}
                        isRequiredField={true}
                        validate={[
                            formValidators.required,
                            formValidators.minLength2,
                            formValidators.maxLength255,
                            formValidators.noWindowSpecialChars,
                        ]}
                    />
                </div>
            </div>
            <div className="row">
                <div className="field col-md-7">
                    <Field
                        name="locationAddressStreet"
                        component={formHelpers.renderField}
                        type="text"
                        label="Street Address"
                        maxLength={255}
                        isRequiredField={true}
                        validate={[
                            formValidators.required,
                            formValidators.minLength2,
                            formValidators.maxLength255,
                        ]}
                    />
                </div>
                <div className="field col-md-3">&nbsp;</div>
            </div>
            <div className="row">
                <div className="field col-md-3">
                    <Field
                        name="locationAddressCity"
                        component={formHelpers.renderField}
                        type="text"
                        label="City"
                        maxLength={255}
                        isRequiredField={true}
                        validate={[
                            formValidators.required,
                            formValidators.minLength2,
                            formValidators.maxLength255,
                        ]}
                    />
                </div>
                <div className="field col-md-3">
                    <Field
                        name="locationAddressState"
                        label="State (Required)"
                        component={formHelpers.SingleSelectComponent}
                        type="select"
                        placeholder="Select one"
                        isRequiredField={true}
                        validate={[
                            formValidators.required,
                            formValidators.minLength2,
                        ]}
                        options={states.usStates}
                        optionValue={(s) => s.value}
                        optionText={(s) => s.label}
                    />
                </div>
                <div className="field col-md-3">
                    <Field
                        name="locationAddressZipCode"
                        label="ZIP Code"
                        type="text"
                        maxLength={11}
                        isRequiredField={true}
                        component={formHelpers.renderField}
                        validate={[
                            formValidators.required,
                            formValidators.zipCode,
                        ]}
                    />
                </div>
            </div>

            <hr />

            <div className="row">
                <div className="field col-md-3">
                    <MoneyField
                        name="purchasePrice"
                        label="Purchase Price"
                        placeholder="0.00"
                        validate={[formValidators.number]}
                    />
                </div>

                <div className="field col-md-3">
                    <DateField
                        name="purchaseDate"
                        label="Purchase Date"
                        placeholder="MM/DD/YYYY"
                        isRequiredField={true}
                        validate={[
                            formValidators.required,
                            formValidators.isValidDate,
                        ]}
                    />
                </div>

                <div className="field col-md-3">
                    <Field
                        name="percentageOfOwnership"
                        component={formHelpers.renderField}
                        isPercentOwnership={true}
                        type="text"
                        disabled={!isTestingEnabled}
                        allowDecimal={true}
                        label="Ownership %"
                        placeholder="100.00"
                        maxLength={13}
                        parse={parsers.percentageOfOwnership}
                        isRequiredField={true}
                        validate={[
                            formValidators.number,
                            formValidators.required,
                            formValidators.greaterThan0,
                        ]}
                    />
                </div>

                <div className="field col-md-3">
                    <NumberField
                        name="rentableSquareFootage"
                        allowDecimal={false}
                        allowNegativeValues={false}
                        label="Rentable Sq Ft"
                        placeholder="0"
                        validate={[
                            formValidators.number,
                            formValidators.maxValueInt,
                        ]}
                    />
                </div>
            </div>

            <hr />

            <div className="row">
                <div className="field col-md-3">
                    <Field
                        name="propertyTypeID"
                        label="Type"
                        component={formHelpers.SingleSelectComponent}
                        type="select"
                        placeholder="Select one"
                        options={lookupTypes.propertyTypes.filter(
                            (pt) => pt.propertyTypeID !== 0
                        )}
                        optionValue={(opt) => opt.propertyTypeID}
                        optionText={(opt) => opt.propertyTypeDescription}
                        onChange={rerenderPsqTypes}
                    />
                </div>
                <div className="field col-md-5">
                    {showPsqTypes && (
                        <Field
                            name="psqPropertyTypes"
                            label="PSQ Property Type(s)"
                            placeholder="Select one or more"
                            options={lookupTypes.psqPropertyTypes.filter(
                                (ppt) => ppt.psqPropertyTypeID !== 0
                            )}
                            optionText={(o) => o.psqPropertyTypeDescription}
                            optionValue={(o) =>
                                o.psqPropertyTypeDescription.replace(
                                    "Multi-Family",
                                    "MultiFamily"
                                )
                            }
                            component={formHelpers.MultipleSelect}
                        />
                    )}
                </div>                
            </div>
            <div className="row">
                <div className="field col-md-12">
                    {!hideInfoMessage && showPsqTypes && formPurpose === "EDIT" && 
                        <MotifToast className="mb-3" variant="error" onClose={setHideInfoMessage} data-testid="psqPropertyTypeToastMsg">
                            Warning: Removing the PSQ Property Type(s) will remove this property from the checklist auto delivery calendar. Please note that this field needs to be populated to utilize the checklist auto delivery calendar for this property.
                        </MotifToast>
                    }
                    </div>
            </div>
            <div className="row">
                <div className="field col-md-2">
                    <Field
                        name="propertyClassID"
                        label="Class"
                        component={formHelpers.SingleSelectComponent}
                        type="select"
                        placeholder="Select one"
                        options={lookupTypes.propertyClasses.filter(
                            (pc) => pc.propertyClassID !== 0
                        )}
                        optionValue={(opt) => opt.propertyClassID}
                        optionText={(opt) => opt.propertyClassDescription}
                    />
                </div>
                <div className="field col-md-3">
                    <Field
                        name="tenantTypeId"
                        label="Tenant Type"
                        component={formHelpers.SingleSelectComponent}
                        type="select"
                        placeholder="Select one"
                        options={lookupTypes.tenantTypes.filter(
                            (tt) => tt.tenantTypeID != 0
                        )}
                        optionValue={(opt) => opt.tenantTypeID}
                        optionText={(opt) => opt.tenantTypeDescription}
                    />
                </div>
                <div className="field col-md-3">
                    <Field
                        name="propertyParkingTypeID"
                        label="Parking"
                        component={formHelpers.SingleSelectComponent}
                        type="select"
                        placeholder="Select one"
                        options={lookupTypes.propertyParkingTypes.filter(
                            (ppt) => ppt.propertyParkingTypeID != 0
                        )}
                        optionValue={(opt) => opt.propertyParkingTypeID}
                        optionText={(opt) => opt.propertyParkingTypeDescription}
                    />
                </div>
            </div>

            <hr />

            <div className="row">
                <div className="field col-md-6">
                    <Field
                        name="managementCompany"
                        component={formHelpers.renderField}
                        type="text"
                        maxLength={255}
                        validate={[formValidators.maxLength255]}
                        label="Management Company"
                    />
                </div>
            </div>
            <div className="row">
                <div className="field col-md-3">
                    <Field
                        name="managerName"
                        component={formHelpers.renderField}
                        type="text"
                        label="Manager Name"
                        maxLength={255}
                        validate={[
                            formValidators.noDoubleQuotes,
                            formValidators.maxLength255,
                        ]}
                    />
                </div>
                <div className="field col-md-3">
                    <Field
                        name="managerEmail"
                        component={formHelpers.renderField}
                        type="email"
                        label="Manager Email"
                        maxLength={255}
                        validate={[
                            formValidators.email,
                            formValidators.maxLength255,
                        ]}
                        
                    />
                </div>
                <div className="field col-md-3">
                    <PhoneField
                        name="managerPhone"
                        label="Manager Phone"
                        placeholder="(###) ###-####"
                        maxLength={50}
                        validate={[
                            formValidators.maxLength50,
                        ]}
                    />
                </div>
            </div>
            <div className="row">
                <div className="field col-md-3">
                    <Field
                        name="accountantName"
                        maxLength={255}
                        validate={[formValidators.maxLength255]}
                        component={formHelpers.renderField}
                        type="text"
                        label="Accountant Name"
                    />
                </div>
                <div className="field col-md-3">
                    <Field
                        name="accountantEmail"
                        component={formHelpers.renderField}
                        type="email"
                        label="Accountant Email"
                        maxLength={255}
                        validate={[
                            formValidators.email,
                            formValidators.maxLength255,
                        ]}                        
                    />
                </div>
                <div className="field col-md-3">
                    <PhoneField
                        name="accountantPhone"
                        label="Accountant Phone"
                        placeholder="(###) ###-####"
                        maxLength={50}
                        validate={[
                            formValidators.maxLength50,
                        ]}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

const EditPropertyForm = ({
    lookupTypes,
    handleSubmit,
    handleCloseForm,
    formPurpose,
    isTestingEnabled,
    error,
    submitting,
    handleDismissErrorMessage,
    taxableReitSubsidiaries
}) => {

    useEffect(() => {

        //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
        addAttribute("button[role = 'combobox']", "type", "button");
    });

    const HeaderWrapper = ({ children }) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'center',
          }}
        >
          {children}
        </div>
    );

    const formTitle = formPurpose === "ADD" ? "Add Property" : "Edit Property";

    const closeButtonProps = {
        title: 'Close ' + formTitle + ' Modal',
        ariaLabel: 'Close ' + formTitle + ' Modal',
        dataTestid: 'close',
    };

    return (
        <React.Fragment>
            <MotifModalHeader style={{ display: '-webkit-box' }} closeButtonProps={closeButtonProps}>
                <HeaderWrapper>
                    <span>{formTitle}</span>
                </HeaderWrapper>
            </MotifModalHeader>
            <MotifModalBody>
                <form onSubmit={handleSubmit} id="addEditPropertyForm">
                    {formHelpers.showSubmissionError(
                        error,
                        handleDismissErrorMessage
                    )}
                    <input name="id" type="hidden" label="id" />

                    <section className="property" style={{ minWidth: "800px" }}>
                        
                        <EditPropertyFields
                            formPurpose={formPurpose}
                            lookupTypes={lookupTypes}
                            isTestingEnabled={isTestingEnabled}                        
                        />

                        <div className="row">
                            <div className="field col-md-12">
                                <br />
                                <label>Tenant Services / Amenities</label>
                                <div>
                                    <PropertyAmenitiesTableContainer
                                        formName="property"
                                        taxableReitSubsidiaries={
                                            taxableReitSubsidiaries
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                </form>
            </MotifModalBody>
            <MotifModalFooter>
                <MotifButton
                    variant="primary"
                    type="submit"
                    disabled={submitting}
                    form="addEditPropertyForm"
                >
                    Save
                </MotifButton>
                <MotifButton
                    variant="secondary"
                    type="button"
                    onClick={handleCloseForm}
                    form="addEditPropertyForm"
                >
                    Cancel
                </MotifButton>
            </MotifModalFooter>
        </React.Fragment>
    );
};

EditPropertyForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleCloseForm: PropTypes.func.isRequired,
    lookupTypes: PropTypes.object,
    error: PropTypes.string,
    submitting: PropTypes.bool,
    formPurpose: PropTypes.string,
    isTestingEnabled: PropTypes.bool,
    handleDismissErrorMessage: PropTypes.func,
    taxableReitSubsidiaries: PropTypes.array
};

export default reduxForm({ form: "property" })(EditPropertyForm);
