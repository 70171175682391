import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactModal from 'react-modal';
import * as fileActions from '../../actions/fileActions';
import * as checklistActions from "../../actions/checklistActions";
import * as testSnapshotActions from '../../actions/testSnapshotActions';
import * as trialBalanceActions from '../../actions/trialBalanceActions';
import * as chartActions from "../../actions/chartOfAccountsManagerActions";
import * as trialBalanceSummaryActions from '../../actions/trialBalanceSummaryActions';
import * as finalDeliverableReportActions from '../../actions/finalDeliverableReportActions';
import * as commonActions from "../../actions/commonActions";
import FileNotifications from '../../components/fileManagement/FileNotifications';
import OtherFileUpload from '../../components/fileManagement/OtherFileUpload';
import FileList from '../../components/fileManagement/FileList';
import ChecklistAttachmentFileList from '../../components/fileManagement/ChecklistAttachmentFileList';
import CoreItems from '../../components/fileManagement/CoreItems';
import * as PeriodActions from "../../actions/periodActions";
import * as propertyActions from '../../actions/propertyActions';
import { percentageOfOwnership } from '../../scripts/formValidators.js';
import * as actions from '../../actions/actionTypes';
import * as ErrorCodes from '../../constants/errorCodes';
import * as Constants from '../../constants/other';
import * as actionHelpers from '../../scripts/actionHelpers';
import * as servicesActions from "../../actions/servicesActions";
import * as serviceConstants from '../../constants/services';
import ReitFileManagementPage from './ReitFileManagementPage';
import { MotifOption, MotifButton } from '@ey-xd/motif-react';
import DeleteModal from "../../components/shared/DeleteModal";
const uuidv4 = require('uuid/v4');

/**
 * Container component used for uploading trial balance files
 */
export class FileManagementPage extends React.Component {
    /**
     * Creates a new TrialBalanceUploadPage
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);

        this.state = {
            tbFile: null,
            finalReportFile: null,
            otherFile: null,
            confirmAction: null,
            canEdit: true,
            canReport: true,
            canUpload: true,
            propertyId: 0,
            percentageOfOwnership: "100",
            propertyTbFile: null,
            activePeriod: null,
            tbMode: 1,
            uploadId: null,
            errorResponse: null,
            disableCurrentTestSnapshotButton: true
        };
        this.onOtherFileChange = this.onOtherFileChange.bind(this);
        this.onUploadOtherFile = this.onUploadOtherFile.bind(this);
        this.handleTbFileChange = this.handleTbFileChange.bind(this);
        this.handlePropertyTbFileChange = this.handlePropertyTbFileChange.bind(this);
        this.handleReportFileChange = this.handleReportFileChange.bind(this);
        this.handleUploadTrialBalance = this.handleUploadTrialBalance.bind(this);
        this.handleUploadPropertyTrialBalance = this.handleUploadPropertyTrialBalance.bind(this);
        this.handleUploadReport = this.handleUploadReport.bind(this);
        this.onOtherFileDownload = this.onOtherFileDownload.bind(this);
        this.onChecklistAttachmentDownload = this.onChecklistAttachmentDownload.bind(this);
        this.onChecklistAttachmentFileDelete = this.onChecklistAttachmentFileDelete.bind(this);
        this.onTestSnapshotDownload = this.onTestSnapshotDownload.bind(this);
        this.onOtherFileDelete = this.onOtherFileDelete.bind(this);
        this.onTestSnapshotDelete = this.onTestSnapshotDelete.bind(this);
        this.handleTrialBalanceDownload = this.handleTrialBalanceDownload.bind(this);
        this.handleConsolidatedTrialBalanceDownload = this.handleConsolidatedTrialBalanceDownload.bind(this);
        this.handleTrialBalanceDelete = this.handleTrialBalanceDelete.bind(this);
        this.handleReportDownload = this.handleReportDownload.bind(this);
        this.handleReportDelete = this.handleReportDelete.bind(this);
        this.handleCancelDelete = this.handleCancelDelete.bind(this);
        this.onConfirmDelete = this.onConfirmDelete.bind(this);
        this.handleTrialBalancePurposeDropdownSelected = this.handleTrialBalancePurposeDropdownSelected.bind(this);
        this.handlePropertyTrialBalanceDelete = this.handlePropertyTrialBalanceDelete.bind(this);
        this.handlePropertyTrialBalanceDownload = this.handlePropertyTrialBalanceDownload.bind(this);
        this.handleMappingsDownload = this.handleMappingsDownload.bind(this);
        this.handleMappingsDelete = this.handleMappingsDelete.bind(this);
        this.handleAllTrialBalancesDelete = this.handleAllTrialBalancesDelete.bind(this);
        this.handleDismissErrorMessage = this.handleDismissErrorMessage.bind(this);
        this.handleDismissSuccessMessage = this.handleDismissSuccessMessage.bind(this);
        this.handlePctChanged = this.handlePctChanged.bind(this);
        this.downloadCurrentTestSnapshot = this.downloadCurrentTestSnapshot.bind(this);
        this.setDisableCurrentTestSnapshotButton = this.setDisableCurrentTestSnapshotButton.bind(this);
    }

    componentWillMount() {
        if (this.props.period) {
            this.setCanEdit(this.props.period);
            this.setCanReport(this.props.period);
            this.setCanUpload(this.props.period);
            this.setState({ activePeriod: Object.assign({}, this.props.period) });
        }
    }

    /**
     * Invoked immediately after mounting occurs
     * @returns {undefined}
     */
    componentDidMount() {
        // Check if state.period is null, if it is populate period
        if (!this.props.period) {
            this.props.periodActions.fetchPeriodById(this.props.periodId);
        }

        this.props.commonActions.clearErrorResponse();
        this.props.trialBalanceActions.getLastTrialBalanceByPeriod(this.props.periodId, "reit");
        this.props.trialBalanceActions.getLastConsolidatedTrialBalanceByPeriod(this.props.periodId);
        this.props.trialBalanceActions.getMappingsByPeriod(this.props.periodId);
        this.props.chartActions.fetchCharts(this.props.clientId);
        this.props.finalDeliverableReportActions.fetchUploadedReport(this.props.periodId);
        this.props.fileActions.fetchUploadedFiles(this.props.periodId);
        this.props.testSnapshotActions.fetchUploadedTestSnapshots(this.props.periodId);
        this.props.propertyActions.fetchPropertiesByPeriod(this.props.periodId);
        this.props.checklistActions.fetchChecklistFilesByPeriod(this.props.periodId);

        this.setDisableCurrentTestSnapshotButton();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.period) {
            this.setCanEdit(nextProps.period);
            this.setCanReport(nextProps.period);
            this.setCanUpload(nextProps.period);
            this.setState({ activePeriod: Object.assign({}, this.props.period) });
            this.setState({ tbMode: nextProps.period.trialBalanceModeID === 2 ? 2 : 1 });
        }

        // If a file is uploaded, files will be set to null and refetched here
        if (this.props.files != null && nextProps.files == null) {

            this.props.fileActions.fetchUploadedFiles(this.props.periodId);
        }

        if (this.props.checklistFiles != null && nextProps.checklistFiles == null) {

            this.props.checklistActions.fetchChecklistFilesByPeriod(this.props.periodId);
        }

        if (this.props.finalReport != null && nextProps.finalReport == null) {

            this.props.finalDeliverableReportActions.fetchUploadedReport(this.props.periodId);
        }

        if (this.props.lastTrialBalance != null && nextProps.lastTrialBalance == null) {

            this.props.trialBalanceActions.getLastTrialBalanceByPeriod(this.props.periodId, "reit");
            this.props.trialBalanceActions.getLastConsolidatedTrialBalanceByPeriod(this.props.periodId);
            this.props.trialBalanceActions.getMappingsByPeriod(this.props.periodId);
        }

        if (this.props.lastPropertyTrialBalance != null && nextProps.lastPropertyTrialBalance == null) {

            this.props.trialBalanceActions.getLastPropertyTrialBalanceByPeriod(this.props.periodId, this.propertyId);
            this.props.trialBalanceActions.getLastConsolidatedTrialBalanceByPeriod(this.props.periodId);
            this.props.trialBalanceActions.getMappingsByPeriod(this.props.periodId);
        }

        if (nextProps.finalReportUploadSuccess && this.props.finalReportUploadSuccess !== nextProps.finalReportUploadSuccess) {
            this.props.finalDeliverableReportActions.fetchUploadedReport(this.props.periodId);
        }

        if (nextProps.tbUploadSuccess && this.props.tbUploadSuccess !== nextProps.tbUploadSuccess) {

            this.props.trialBalanceActions.getLastTrialBalanceByPeriod(this.props.periodId, "reit");
            this.props.trialBalanceActions.getLastPropertyTrialBalanceByPeriod(this.props.periodId, this.state.propertyId);
            this.props.trialBalanceActions.getLastConsolidatedTrialBalanceByPeriod(this.props.periodId);
            this.props.trialBalanceActions.getMappingsByPeriod(this.props.periodId);
        }

        if (nextProps.properties != this.props.properties) {
            if (nextProps.properties === undefined || nextProps.properties === null || nextProps.properties.length === 0) {
                this.setState({ propertyId: 0, percentageOfOwnership: "100" },
                    () => { this.props.trialBalanceActions.getLastPropertyTrialBalanceByPeriod(this.props.periodId, this.state.propertyId); });
            } else {
                let firstPropertyId = nextProps.properties[0].propertyID;
                let firstPropertyPctOfOwnership = nextProps.properties[0].percentageOfOwnership;
                this.setState({ propertyId: firstPropertyId, percentageOfOwnership: firstPropertyPctOfOwnership.toString() },
                    () => { this.props.trialBalanceActions.getLastPropertyTrialBalanceByPeriod(this.props.periodId, this.state.propertyId); });
            }
            if (nextProps.properties && nextProps.properties.length) {
                if (nextProps.properties.length === 1 && nextProps.period && nextProps.period.trialBalanceModeID === 2) {
                    let propertyId = nextProps.properties[0].propertyID;
                    let propertyPerctentageOfOwnership = nextProps.properties[0].percentageOfOwnership;
                    this.setState({ propertyId: propertyId, percentageOfOwnership: propertyPerctentageOfOwnership.toString() });
                }
            }
        }
    }

    componentWillUnmount() {
        this.props.commonActions.clearErrorResponse();
        this.clearSuccess();
    }

    setDisableCurrentTestSnapshotButton() {
        servicesActions.fetchServicesByPeriodWithoutDispatch(this.props.periodId).then((services) => {
            if (actionHelpers.isErrorResponse(services)) {
                return;
            }

            if (Array.isArray(services) && services.some(x => x === serviceConstants.ASSET_TEST_SERVICE || x === serviceConstants.INCOME_TEST_SERVICE || x === serviceConstants.DISTRIBUTION_TEST_SERVICE)) {
                this.setState({ disableCurrentTestSnapshotButton: false });
            } else {
                this.setState({ disableCurrentTestSnapshotButton: true });
            }
        });
    }

    handleDismissErrorMessage() {
        this.props.commonActions.clearErrorResponse();
        this.setState({ errorResponse: null });
    }

    handleDismissSuccessMessage() {
        this.clearSuccess();
    }

    onOtherFileChange(e) {
        this.clearSuccess();
        this.setState({ otherFile: e.target.files[0] });
    }

    handleTbFileChange(e) {
        this.clearSuccess();
        this.setState({ tbFile: e.target.files[0] });
    }

    handlePropertyTbFileChange(e) {
        this.clearSuccess();
        this.setState({ propertyTbFile: e.target.files[0] });
    }

    handleReportFileChange(e) {
        this.clearSuccess();
        this.setState({ finalReportFile: e.target.files[0] });
    }

    onUploadOtherFile(e) {
        e.preventDefault();
        this.clearSuccess();
        this.props.commonActions.clearErrorResponse();
        this.setState({ errorResponse: null });

        const myFile = this.state.otherFile;
        if (myFile) {
            this.props.fileActions.uploadFile(this.props.periodId, myFile);
            this.setState({ otherFile: null });
        }
    }

    handleUploadTrialBalance(e) {
        e.preventDefault();
        this.clearSuccess();
        this.props.commonActions.clearErrorResponse();
        this.setState({ errorResponse: null });

        const myFile = this.state.tbFile;
        if (myFile) {
            const uploadId = uuidv4();
            this.props.trialBalanceActions.uploadTrialBalance(this.props.periodId, "adjusted", myFile, 1, null, 100.00, uploadId, this.props.clientId);
            this.setState({ tbFile: null, uploadId: uploadId });
        }

    }

    handleUploadPropertyTrialBalance(e) {
        e.preventDefault();
        this.clearSuccess();
        this.props.commonActions.clearErrorResponse();
        this.setState({ errorResponse: null });

        const myFile = this.state.propertyTbFile;
        if (myFile) {
            if (this.state.propertyId === null) {
                return;
            }
            if (this.state.propertyId > 0) {
                const uploadId = uuidv4();
                this.props.trialBalanceActions.uploadTrialBalance(this.props.periodId, "adjusted", myFile, 2, this.state.propertyId, this.state.percentageOfOwnership, uploadId, this.props.clientId);
                this.setState({ propertyTbFile: null, uploadId: uploadId });
            }

        }
    }

    handleUploadReport(e) {
        e.preventDefault();
        this.clearSuccess();
        this.props.commonActions.clearErrorResponse();
        this.setState({ errorResponse: null });

        const myFile = this.state.finalReportFile;
        if (myFile) {
            this.props.finalDeliverableReportActions.uploadFinalReport(this.props.periodId, myFile);
            this.setState({ finalReportFile: null });
        }
    }

    onChecklistAttachmentDownload(checklistFileAttachmentId, fileName) {
        this.clearSuccess();
        fileActions.downloadChecklistFile(checklistFileAttachmentId, fileName);
    }

    onOtherFileDownload(fileName) {
        this.clearSuccess();
        fileActions.downloadFile(this.props.periodId, fileName);
    }

    onTestSnapshotDownload(fileName) {
        this.clearSuccess();
        testSnapshotActions.downloadTestSnapshot(this.props.periodId, fileName);
    }

    handleTrialBalanceDownload() {
        this.clearSuccess();
        trialBalanceActions.downloadTrialBalance(this.props.lastTrialBalance.trialBalanceID);
    }

    handleConsolidatedTrialBalanceDownload() {
        this.clearSuccess();
        trialBalanceSummaryActions.downloadTrialBalanceSummary(this.props.periodId, false);
    }

    handlePropertyTrialBalanceDownload() {
        this.clearSuccess();
        trialBalanceActions.downloadTrialBalance(this.props.lastPropertyTrialBalance.trialBalanceID);
    }

    handleMappingsDownload() {
        this.clearSuccess();
        trialBalanceActions.downloadPeriodMappingsFile(this.props.periodId);
    }

    handleMappingsDelete() {
        this.clearSuccess();
        let self = this;
        this.setState({
            confirmAction: () => {
                this.props.commonActions.beginTask();
                return trialBalanceActions.deletePeriodMappings(self.props.periodId).then(() => {
                    this.props.commonActions.endTask();
                    return self.deleteAllTrialBalances();
                }).catch(error => self.props.commonActions.dispatchErrorAndEndTask(error));
            }
        });
    }

    handleAllTrialBalancesDelete() {
        this.clearSuccess();
        let self = this;
        this.setState({
            confirmAction: () => {
                this.props.commonActions.beginTask();
                return trialBalanceActions.deleteTrialBalance(self.props.periodId).then(() => {
                    this.props.commonActions.endTask();
                    this.props.trialBalanceActions.getLastTrialBalanceByPeriod(self.props.periodId, "reit");
                    this.props.trialBalanceActions.getLastPropertyTrialBalanceByPeriod(this.props.periodId, this.state.propertyId);
                    this.props.trialBalanceActions.getMappingsByPeriod(this.props.periodId);
                }).catch(error => self.props.commonActions.dispatchErrorAndEndTask(error));
            }
        });
    }

    deleteAllTrialBalances() {
        this.clearSuccess();
        let self = this;
        this.props.commonActions.beginTask();
        trialBalanceActions.deleteTrialBalance(self.props.periodId)
            .then(() => {
                this.props.commonActions.endTask();
                this.props.trialBalanceActions.getLastTrialBalanceByPeriod(self.props.periodId, "reit");
                this.props.trialBalanceActions.getLastPropertyTrialBalanceByPeriod(this.props.periodId, this.state.propertyId);
                this.props.trialBalanceActions.getMappingsByPeriod(this.props.periodId);
            }).catch(error => this.props.commonActions.dispatchErrorAndEndTask(error));
    }

    handleReportDownload() {
        this.clearSuccess();
        finalDeliverableReportActions.downloadFinalReport(this.props.periodId, encodeURIComponent(this.props.finalReport.fileName));
    }

    onOtherFileDelete(fileName) {
        this.clearSuccess();
        let self = this;
        this.setState({
            confirmAction: () => {
                this.props.commonActions.beginTask();
                return fileActions.deleteFile(self.props.periodId, fileName).then(() => {
                    this.props.commonActions.endTask();
                    return self.props.fileActions.fetchUploadedFiles(self.props.periodId);
                }).catch(error => self.props.commonActions.dispatchErrorAndEndTask(error));
            }
        });
    }

    onChecklistAttachmentFileDelete(checklistFileAttachmentId) {
        this.clearSuccess();
        let self = this;
        this.setState({
            confirmAction: () => {
                this.props.commonActions.beginTask();
                return this.props.checklistActions.deleteAttachment(
                    checklistFileAttachmentId, this.props.clientId,
                    null,
                    null).then(() => {
                        this.props.commonActions.endTask();
                        return self.props.checklistActions.fetchChecklistFilesByPeriod(self.props.periodId);
                    }).catch(error => self.props.commonActions.dispatchErrorAndEndTask(error));
            }
        });
    }

    onTestSnapshotDelete(fileName) {
        this.clearSuccess();
        let self = this;
        this.setState({
            confirmAction: () => {
                this.props.commonActions.beginTask();
                return testSnapshotActions.deleteTestSnapshot(self.props.periodId, fileName).then(() => {
                    this.props.commonActions.endTask();
                    return self.props.testSnapshotActions.fetchUploadedTestSnapshots(self.props.periodId);
                }).catch(error => self.props.commonActions.dispatchErrorAndEndTask(error));
            }
        });
    }

    handleTrialBalanceDelete() {
        this.clearSuccess();
        let self = this;
        this.setState({
            confirmAction: () => {
                this.props.commonActions.beginTask();
                return trialBalanceActions.deleteTrialBalance(self.props.periodId, "reit", null).then(() => {
                    this.props.commonActions.endTask();
                    return self.props.trialBalanceActions.getLastTrialBalanceByPeriod(self.props.periodId, "reit");
                }).catch(error => self.props.commonActions.dispatchErrorAndEndTask(error));
            }
        });
    }

    handlePropertyTrialBalanceDelete() {
        this.clearSuccess();
        let self = this;
        this.setState({
            confirmAction: () => {
                this.props.commonActions.beginTask();
                return trialBalanceActions.deleteTrialBalance(self.props.periodId, "property", this.state.propertyId).then(() => {
                    this.props.commonActions.endTask();
                    return this.props.trialBalanceActions.getLastPropertyTrialBalanceByPeriod(self.props.periodId, this.state.propertyId);
                }).catch(error => self.props.commonActions.dispatchErrorAndEndTask(error));
            }
        });
    }

    handleReportDelete() {
        this.clearSuccess();
        let self = this;
        this.setState({
            confirmAction: () => {
                this.props.commonActions.beginTask();
                return finalDeliverableReportActions.deleteFinalReport(self.props.periodId).then(() => {
                    this.props.commonActions.endTask();
                    return this.props.finalDeliverableReportActions.fetchUploadedReport(self.props.periodId);
                }).catch(error => self.props.commonActions.dispatchErrorAndEndTask(error));
            }
        });
    }

    handleCancelDelete() {
        this.setState({ confirmAction: null });
    }

    onConfirmDelete() {
        if (this.state.confirmAction) {
            this.state.confirmAction();
            this.setState({ confirmAction: null });
        }
    }

    clearSuccess() {
        this.props.trialBalanceActions.clearUploadTbSuccess();
        this.props.fileActions.clearUploadFileSuccess();
        this.props.finalDeliverableReportActions.clearUploadFinalReportSuccess();
    }

    setCanEdit(period) {
        const isEnabled = PeriodActions.isTestingEnabled(period);
        this.setState({ canEdit: isEnabled });
    }

    setCanReport(period) {
        const isEnabled = PeriodActions.isReportingEnabled(period);
        this.setState({ canReport: isEnabled });
    }

    setCanUpload(period) {
        const isEnabled = PeriodActions.isFileUploadEnabled(period);
        this.setState({ canUpload: isEnabled });
    }

    handleTrialBalancePurposeDropdownSelected(index) {
        const prop = this.props.properties[index];
        const { propertyID, percentageOfOwnership } = prop;

        const stateUpdate = {
            propertyId: propertyID,
            percentageOfOwnership: percentageOfOwnership.toString()
        };

        const stateCallback =
            () => this.props
                .trialBalanceActions
                .getLastPropertyTrialBalanceByPeriod(
                    this.props.periodId,
                    this.state.propertyId);

        this.setState(stateUpdate, stateCallback);
    }

    handlePctChanged(e) {
        const value = percentageOfOwnership(e.target.value);
        if (value === undefined) {
            return;
        }

        this.setState({ percentageOfOwnership: value.toString() });
    }

    downloadCurrentTestSnapshot() {
        let fileName = `${this.props.period.reportPeriodDescription.replace(/\s/g, '')}_${this.props.reit.reitName}_TestSnapshot.xlsx`;
        this.props.commonActions.beginTask();
        return testSnapshotActions.downloadCurrentTestSnapshot(this.props.periodId, fileName).then((blob) => {
            this.props.commonActions.endTask();
            return blob;
        });
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        const reitModalStyle = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '300px',
                height: '160px'
            }
        };

        return (
            <React.Fragment>
                <h1>File Management</h1>
                <FileNotifications
                    errorResponse={this.state.errorResponse || this.props.errorResponse}
                    tbUploadSuccess={this.props.tbUploadSuccess}
                    fileUploadSuccess={(this.props.fileUploadSuccess || this.props.finalReportUploadSuccess)}
                    handleDismissErrorMessage={this.handleDismissErrorMessage}
                    handleDismissSuccessMessage={this.handleDismissSuccessMessage} />
                <ReitFileManagementPage {...this.props} />
                <br />
                <h3>Period File Management</h3>
                <CoreItems
                    handleTbFileChange={this.handleTbFileChange}
                    handlePropertyTbFileChange={this.handlePropertyTbFileChange}
                    handleReportFileChange={this.handleReportFileChange}
                    tbFile={this.state.tbFile}
                    finalReportFile={this.state.finalReportFile}
                    handleUploadTrialBalance={this.handleUploadTrialBalance}
                    handleUploadReport={this.handleUploadReport}
                    handleTrialBalanceDownload={this.handleTrialBalanceDownload}
                    handleConsolidatedTrialBalanceDownload={this.handleConsolidatedTrialBalanceDownload}
                    handleReportDownload={this.handleReportDownload}
                    handleTrialBalanceDelete={this.handleTrialBalanceDelete}
                    handleReportDelete={this.handleReportDelete}
                    trialBalanceExists={(this.props.lastTrialBalance && this.props.lastTrialBalance.trialBalanceID && true) || false}
                    finalReportExists={(this.props.finalReport && this.props.finalReport.fileName && true) || false}
                    canEdit={this.state.canEdit}
                    canReport={this.state.canReport}
                    trialBalanceDateUploaded={(this.props.lastTrialBalance && this.props.lastTrialBalance.entryDate) || null}
                    finalReportDateUploaded={(this.props.finalReport && this.props.finalReport.lastModified) || null}
                    handleTrialBalancePurposeDropdownSelected={this.handleTrialBalancePurposeDropdownSelected}
                    propertyId={this.state.propertyId}
                    propertyTbFile={this.state.propertyTbFile}
                    handleUploadPropertyTrialBalance={this.handleUploadPropertyTrialBalance}
                    propertyTrialBalanceExists={(this.props.lastPropertyTrialBalance && this.props.lastPropertyTrialBalance.trialBalanceID && true) || false}
                    propertyTrialBalanceDateUploaded={(this.props.lastPropertyTrialBalance && this.props.lastPropertyTrialBalance.entryDate) || null}
                    handlePropertyTrialBalanceDelete={this.handlePropertyTrialBalanceDelete}
                    handlePropertyTrialBalanceDownload={this.handlePropertyTrialBalanceDownload}
                    handleMappingsDownload={this.handleMappingsDownload}
                    mappingsDateUploaded={(this.props.periodMappings && this.props.periodMappings[0] && this.props.periodMappings[0].created) || null}
                    mappingsExist={this.props.periodMappings && this.props.periodMappings.length > 0}
                    handleMappingsDelete={this.handleMappingsDelete}
                    handleAllTrialBalancesDelete={this.handleAllTrialBalancesDelete}
                    percentageOfOwnership={this.state.percentageOfOwnership}
                    handlePctChanged={this.handlePctChanged}
                    tbMode={this.state.tbMode}
                    lastConsolidatedTrialBalance={this.props.lastConsolidatedTrialBalance}
                    charts={this.props.chartOfAccounts.charts}
                    properties={this.props.properties}
                />
                <br />
                <OtherFileUpload onSubmit={this.onUploadOtherFile} onFileChange={this.onOtherFileChange} file={this.state.otherFile} canUpload={this.state.canUpload} />
                <FileList files={this.props.files} onDownload={this.onOtherFileDownload} onDelete={this.onOtherFileDelete} canUpload={this.state.canUpload} emptyMessage="No files have been uploaded." />
                <br />
                <div className="row">
                    <div className="col">
                        <h5>Closed Period Test Snapshots</h5>
                    </div>
                    <div className="col tright" style={{ marginBottom: '5px' }}>
                        {(!this.state.disableCurrentTestSnapshotButton && this.props.lastConsolidatedTrialBalance)
                            ? <MotifButton variant="primary-alt" className="right ml-1" type="button" onClick={this.downloadCurrentTestSnapshot}>Download Current Test Snapshot</MotifButton>
                            : <MotifButton variant="primary-alt" className="right ml-1" type="button" disabled={true}>Download Current Test Snapshot</MotifButton>
                        }
                    </div>
                </div>

                <div>
                    <FileList files={this.props.testSnapshots} onDownload={this.onTestSnapshotDownload} onDelete={this.onTestSnapshotDelete} canUpload={true} emptyMessage="Period has never been closed." />
                </div>

                <br />
                <div className="row">
                    <div className="col">
                        <h5>Checklist File Attachments</h5>
                    </div>
                </div>
                <div style={{ paddingBottom: "40px" }}>
                    <ChecklistAttachmentFileList files={this.props.checklistFiles} onDownload={this.onChecklistAttachmentDownload} onDelete={this.onChecklistAttachmentFileDelete} canDelete={this.state.canEdit} emptyMessage="No Checklist attachments found." />
                </div>

                <DeleteModal
                    showDeleteModal={this.state.confirmAction !== null}
                    confirmationMessage="Are you sure?"
                    size="sm"
                    handleDelete={this.onConfirmDelete}
                    toggleshowDeleteModal={this.handleCancelDelete}
                    closeTitle="close delete modal"
                    headerTitle="Delete File">
                </DeleteModal>
            </React.Fragment>
        );
    }

}

FileManagementPage.propTypes = {
    periodId: PropTypes.number.isRequired,
    period: PropTypes.object.isRequired,
    fileActions: PropTypes.object.isRequired,
    errorResponse: PropTypes.object,
    tbUploadSuccess: PropTypes.bool,
    fileUploadSuccess: PropTypes.bool,
    files: PropTypes.array,
    checklistFiles: PropTypes.array,
    testSnapshots: PropTypes.array,
    properties: PropTypes.array,
    lastTrialBalance: PropTypes.object,
    finalReport: PropTypes.object,
    finalReportUploadSuccess: PropTypes.bool,
    periodActions: PropTypes.object.isRequired,
    lastPropertyTrialBalance: PropTypes.object,
    lastConsolidatedTrialBalance: PropTypes.object,
    periodMappings: PropTypes.array,
    trialBalanceStatus: PropTypes.object,
    commonActions: PropTypes.object.isRequired,
    trialBalanceActions: PropTypes.object.isRequired,
    finalDeliverableReportActions: PropTypes.object.isRequired,
    testSnapshotActions: PropTypes.object.isRequired,
    propertyActions: PropTypes.object.isRequired
};

FileManagementPage.contextTypes = {
    router: PropTypes.object
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps Existing properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    return {
        periodId: Number.parseInt(ownProps.params.periodId),
        clientId: Number.parseInt(ownProps.params.clientId),
        errorResponse: state.errorResponse,
        tbUploadSuccess: state.tbUploadSuccess,
        fileUploadSuccess: state.fileUploadSuccess,
        files: state.files,
        reitFiles: state.reitFiles,
        checklistFiles: state.checklistFiles,
        testSnapshots: state.testSnapshots,
        lastTrialBalance: state.lastTrialBalance,
        finalReport: state.finalReport,
        finalReportUploadSuccess: state.finalReportUploadSuccess,
        period: state.period,
        properties: state.properties,
        lastPropertyTrialBalance: state.lastPropertyTrialBalance,
        lastConsolidatedTrialBalance: state.lastConsolidatedTrialBalance,
        periodMappings: state.periodMappings,
        trialBalanceStatus: state.trialBalanceStatus,
        pendingTasks: state.pendingTasks,
        reit: state.reit,
        chartOfAccounts: state.chartOfAccountsManager
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        fileActions: bindActionCreators(fileActions, dispatch),
        testSnapshotActions: bindActionCreators(testSnapshotActions, dispatch),
        trialBalanceActions: bindActionCreators(trialBalanceActions, dispatch),
        chartActions: bindActionCreators(chartActions, dispatch),
        finalDeliverableReportActions: bindActionCreators(finalDeliverableReportActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
        periodActions: bindActionCreators(PeriodActions, dispatch),
        propertyActions: bindActionCreators(propertyActions, dispatch),
        checklistActions: bindActionCreators(checklistActions, dispatch),
        processingCompleteNotified: () => dispatch({ type: actions.TB_PROCESSING_COMPLETE_NOTIFIED })
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FileManagementPage);