import { pendingTask, begin, end } from 'react-redux-spinner';
import * as actions from './actionTypes';
import { fetchFromReportApi as fetchReport } from './fetchFromApi';
import * as actionHelpers from '../scripts/actionHelpers';

/**
 * Fetch the variance detials for a period and it's predecessor. If successful this will dispatch the LOAD_CLIENT_ACCOUNT_DATA_SUCCESS
 * action, otherwise it will dispatch the LOAD_CLIENT_ACCOUNT_DATA_FAILURE action.
 * @param {number} periodId The ID of the REIT period.
 * @returns {funtion} A function that returns a Promise.
 */
export function fetchVarianceDetails(periodId) {
    return function(dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetchReport('/variance?compareUsingPeriodId=' + periodId).then(response => {
            return response.json();
        }).then(varianceDetails => {
            if (actionHelpers.isErrorResponse(varianceDetails)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CLIENT_ACCOUNT_DATA_FAILURE, varianceDetails);
            }

            dispatch({ type: actions.LOAD_CLIENT_ACCOUNT_DATA_SUCCESS, varianceDetails, [pendingTask]: end });
            }).catch(error => {
                actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_CLIENT_ACCOUNT_DATA_FAILURE, null, error);
        });
    };
}