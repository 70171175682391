import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
//import { Form } from 'semantic-ui-react';
import * as formHelpers from '../../scripts/formHelpers';
import { fetchFromTrialBalanceApi } from '../../actions/fetchFromApi';
import { download } from '../../scripts/fileHelpers';
import { MotifFormField, MotifLabel, MotifSearch, MotifButton } from '@ey-xd/motif-react';
import { addAttribute } from "../../common/Attribute";

const ChartAssignmentHeader = ({
    calendarYears,
    reits,
    handleCalendarYearSelection,
    handleReitSelection,
    handleSearchBox,
    searchBoxText,
    handleClearSearchText,
    clientId,
    chartAssignmentFormValues,
    rowsAffected
}) => {

    useEffect(() => {

        //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
        addAttribute("button[role = 'combobox']", "type", "button");
    });

    const calendarYearOptions = calendarYears.map(cy => {
        return { key: cy, value: cy, text: cy }
    });

    const reitOptions = reits.map(r => {
        return { key: r.reitID, value: r.reitID, text: r.reitShortName }
    });

    function downloadChartOfAssignments() {
        fetchFromTrialBalanceApi(`/chartAssignment/download?clientId=${clientId}&year=${chartAssignmentFormValues.calendarYear}`)
            .then(function (response) {
                return response.blob().then(function (blob) {
                    const contentDisposition =
                        response.headers.get("Content-Disposition");

                    const filename =
                        contentDisposition.split(';')[1].split('=')[1].trim();

                    download(blob, filename);
                });
            })
            .catch(e => console.error(e));
    }
    
    return (<div>
        <div style={{ minHeight: '24px' }}></div>
        <div className="row align-items-center">
            <div className="col-12 col-md-2">
                <Field
                    name="calendarYear"
                    label="Calendar Year"
                    type="select"
                    onChange={handleCalendarYearSelection}
                    options={calendarYearOptions}
                    component={formHelpers.SingleSelectComponent}
                    renderUntilValueSet={true}
                    renderUntilOptionsSet={true}
                    disabled={rowsAffected.length > 0} />
            </div>

            <div className="col-12 col-md-3">
                <Field
                    name="reit"
                    label="REIT"
                    type="select"
                    onChange={handleReitSelection}
                    options={reitOptions}
                    component={formHelpers.SingleSelectComponent}
                    renderUntilValueSet={true}
                    renderUntilOptionsSet={true}
                    disabled={rowsAffected.length > 0} />
            </div>

            <div className="col-12 col-md-1"></div>

            <div className="col">
                <MotifFormField>
                    <MotifLabel>Search Chart</MotifLabel>
                    <MotifSearch
                        type="text"
                        onChange={handleSearchBox}
                        value={searchBoxText}
                        searchButtonProps={{ tabIndex: "-1" }} />
                </MotifFormField>
            </div>

            <div>
                {calendarYearOptions && calendarYearOptions.length > 0 &&
                    <div className="mr-3 d-flex flex-row flex-row-reverse align-items-end">
                        <MotifButton variant="primary" type="button" className="motif-form-field" onClick={downloadChartOfAssignments}>Export Assignments</MotifButton>
                    </div>
                }
            </div>
        </div>
    </div>
    );
};

ChartAssignmentHeader.propTypes = {
    calendarYears: PropTypes.array,
    reits: PropTypes.array
}

export default reduxForm({ form: "chartAssignmentForm" })(ChartAssignmentHeader);