import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
  MotifButton,
  MotifIcon
} from '@ey-xd/motif-react';

import { addAttribute } from '../../common/Attribute';
import { alertIcError24px } from '@ey-xd/motif-react/assets/icons';
const DeleteChecklistDetailGridRowModal = ({
  showDeleteChecklistGridRowModal,
  toggleshowDeleteChecklistGridRowModal,
  deleteRow,
  modalName,
}) => {
  useEffect(() => {
    //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
    addAttribute("button[role = 'combobox']", 'type', 'button');
  });

  const HeaderWrapper = ({ children }) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
      }}
    >
      {children}
    </div>
  );

  const closeButtonProps = {
    title: `Close Delete ${modalName} modal`,
    ariaLabel: `Close Delete ${modalName} modal`,
    dataTestid: 'close',
  };
  const page = modalName == "Attachments" ? "Attachment" : "Comment";
  return (
    <React.Fragment>
      <MotifModal
        variant="alt"
        show={showDeleteChecklistGridRowModal}
        onClose={toggleshowDeleteChecklistGridRowModal}
        data-testid="deleteRowModal"
      >
        <MotifModalHeader
          style={{ display: '-webkit-box' }} data-testid="h3deleteRow"
          closeButtonProps={closeButtonProps}
        >
          <HeaderWrapper>
            <MotifIcon style={{ color: "#F95D54" }} src={alertIcError24px} />
            <span className="ml-2" >Delete {page}</span>
          </HeaderWrapper>
        </MotifModalHeader>
        <MotifModalBody>
          <span className="checklistActionMsg mb-2" data-testid="spanDeleteConfirmation">Are you sure you want to delete this {page.toLowerCase()}?</span>
        </MotifModalBody>
        <MotifModalFooter>
          <MotifButton variant="warn" className="right"
            data-testid="btnDelete"
            type="button" onClick={deleteRow}
          >Delete</MotifButton>
          <MotifButton variant="secondary" className="right"
            data-testid="btnCancel"
            type="button"
            onClick={toggleshowDeleteChecklistGridRowModal}>Cancel</MotifButton>
        </MotifModalFooter>
      </MotifModal>
    </React.Fragment>
  );
};

DeleteChecklistDetailGridRowModal.propTypes = {
  showDeleteChecklistGridRowModal: PropTypes.bool,
  toggleshowDeleteChecklistGridRowModal: PropTypes.bool,
  deleteRow: PropTypes.func,
  modalName: PropTypes.string
};

export default DeleteChecklistDetailGridRowModal;
