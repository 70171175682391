import PropTypes from "prop-types";
import React from "react";
import { Header, Label, Button, Input, Dropdown } from "semantic-ui-react";
import ReactModal from "react-modal";
import { Field, reduxForm, getFormValues } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import * as formValidators from "../../scripts/formValidators";
import { connect } from "react-redux";
import { MotifButton } from "@ey-xd/motif-react";

/**
 * CustomFdrTemplateContainer container component
 * @extends {React.Component}
 */
export class CreateFDRTemplateModal extends React.Component {
    /**
      * Creates a new CustomFdrTemplateContainer
      * @constructor
      * @param {Object} props The component properties
      * @param {Object} context The component context
      */
    constructor(props, context) {
        super(props, context);
    }

    componentDidMount() {
        if (this.props.mode === 'create') {
            this.props.dispatch(this.props.initialize(
                {
                    templateId: -1,
                    fdrTemplateName: ''
                },
                { options: { updateUnregisteredFields: true } }
            ));
        } else {
            const template = this.props.customFdrTemplateFormValues
                && this.props.customFdrTemplateFormValues.templateId
                && this.props.customFdrTemplateFormValues.templateId !== -1 ?
                this.props.customFdrTemplates.templates.find(t => t.fdrTemplateID == this.props.customFdrTemplateFormValues.templateId) : null;
            //If is saveas set the name with appending -Copy to the template name
            
            this.props.dispatch(this.props.initialize(
                {
                    templateId: template.fdrTemplateId,
                    fdrTemplateName: this.props.mode != 'saveas' ? template.fdrTemplateName : template.fdrTemplateName + '-Copy'
                },
                { options: { updateUnregisteredFields: true } }
            ));
        }
    }

    /**
* Render a React element
* @returns {Object} A reference to the component
*/
    render() {
        //const createEdit = this.props.mode === 'create' ? 'Create' : 'Edit';
        //First letter to UpperCase
        const createEdit = this.props.mode != 'saveas'? this.props.mode.charAt(0).toUpperCase() + this.props.mode.slice(1) : 'Save As';
        return (
            <ReactModal contentLabel={`${createEdit} Custom FDR Template`} isOpen={true} className="react-modal react-modal-small" overlayClassName="react-modal-overlay">
                <Header content={`${createEdit} Custom FDR Template`} />
                <form onSubmit={this.props.handleSubmit(this.props.mode != 'saveas'? this.props.onSubmit : this.props.onSaveAs)}>
                    <Field
                        name="fdrTemplateName"
                        type="text"
                        component={formHelpers.renderField}
                        label="Template Name"
                        maxLength={255}
                        isRequiredField={true}
                        onChange={this.props.clearDuplicateErrorMessage}
                        validate={[formValidators.required, formValidators.maxLength255]} />                   
                    <br />
                    {this.props.customFdrTemplates.duplicate                      
                        && 
                        <div className="text-danger">
                        This template name already exists.
                        </div>
                    }
                    <MotifButton variant="primary" className="right ml-2" type="submit"
                        disabled={this.props.invalid || this.props.submitting}>
                        Save
                    </MotifButton>
                    <MotifButton variant="secondary" className="right" type="button"
                        onClick={this.props.toggleShowCreateTemplateModal}>
                        Cancel
                    </MotifButton>
                </form>
            </ReactModal>
        );
    }
}

CreateFDRTemplateModal.propTypes = {
    handleSubmit: PropTypes.func,
    onSubmit: PropTypes.func,
    dispatch: PropTypes.func,
    initialize: PropTypes.func,
    toggleShowCreateTemplateModal: PropTypes.func,
    clearDuplicateErrorMessage: PropTypes.func,
    mode: PropTypes.string,
    invalid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool
};

//export default reduxForm({ form: "customChecklistTemplateModalForm" })(CreateFDRTemplateModal);
export default reduxForm({ form: "customFdrTemplateModalForm" })(CreateFDRTemplateModal);