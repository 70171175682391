import PropTypes from "prop-types";
import React from "react";
import { Field, reduxForm } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import * as formValidators from "../../scripts/formValidators";
import { MotifButton, MotifFormField, MotifCheckbox, MotifToggleSwitch, MotifLabel, MotifModal, MotifModalHeader, MotifModalBody, MotifModalFooter } from "@ey-xd/motif-react";

const AddClientAuthModal = ({
  showAddClientAuthModal,
  handleSubmit,
  toggleShowAddClientAuthModal,
  initialValues,
  addAuthMessageText,
  validateDomainWithRole,
  clientAuthFormValues,
  handleAddClientAuthModalValidateAction,
  validatingUser,
  myEyError,
  valid,
  invalid,
  values,
  receiveDailyNewsletter,
  handleDailyNewsletter,
  receiveWeeklyNewsletter,
  handleWeeklyNewsletter,
  receiveRealTimeNotification,
  handleRealTimeNotification,
  receiveRealTimeNotificationForNewPsqArqcSent,
  handleInternalReviewNotification,
  handleOnRoleTypeChange
}) => {
  let role = "";
  //const [receiveRealTimeNotification, setreceiveRealTimeNotification] = useState(true);
  if (clientAuthFormValues && clientAuthFormValues.role) {
    role = clientAuthFormValues.role;

  }

  const companyOptions = initialValues.companies && initialValues.companies.length > 1 && initialValues.companies.map((c) => {
    return {
      key: c.id,
      value: c.id,
      text: c.name,
    };
  });

  return (
    <form className="clientAuth">
      <MotifModal show={showAddClientAuthModal} onClose={toggleShowAddClientAuthModal} variant="alt">
        <MotifModalHeader>Add User Authorization</MotifModalHeader>
        <MotifModalBody>
          <label>
            <span>
              <Field
                name="role"
                component={formHelpers.renderField}
                type="radio"
                onChange={handleOnRoleTypeChange}
                value="1"
                label="Engagement Administrator"
                disabled={initialValues.userId || validatingUser}
              />
            </span>
            <br />
            <span>
              <Field
                name="role"
                component={formHelpers.renderField}
                type="radio"
                value="2"
                label="User"
                onChange={handleOnRoleTypeChange}
                labelLocationOnRight={true}
                disabled={initialValues.userId || validatingUser}
              />
            </span>
            <br />
            <span>
              <Field
                name="role"
                component={formHelpers.renderField}
                type="radio"
                value="4"
                label="External Checklist User"
                onChange={handleOnRoleTypeChange}
                labelLocationOnRight={true}
                disabled={initialValues.userId || validatingUser}
              />
            </span>
            <br />
          </label>

          <Field
            name="userId"
            component={formHelpers.renderField}
            type="email"
            label="User's email address"
            disabled={initialValues.userId || validatingUser}
            maxLength={255}
            validate={[
              formValidators.email,
              validateDomainWithRole,
              formValidators.required,
              formValidators.maxLength255,
            ]}
            isRequiredField={true}
          />
          {(addAuthMessageText && (
            <span className="text-danger">{addAuthMessageText}</span>
          )) ||
            null}
          <section className="clientAddAuthsSubscription">
            <div>
              <span className="clientAuths-Subscription-text" data-testid="receiveChecklistNewletter">
                Receive Checklist Newsletter
              </span>
              <div className="clientAuths-Subscription-checkbox">
                <MotifFormField>
                  <MotifCheckbox
                    className="clientAuths-checkbox-space"
                    data-testid="chkbxDaily"
                    type="checkbox"
                    name="dailyNewsletterAddAuth"
                    id="dailyNewsletterAddAuth"
                    disabled={(initialValues.userId !== null) || validatingUser}
                    checked={receiveDailyNewsletter}
                    onChange={handleDailyNewsletter}
                  >
                    Daily
                  </MotifCheckbox>
                </MotifFormField>
                <MotifFormField>
                  <MotifCheckbox
                    type="checkbox"
                    data-testid="chkbxWeekly"
                    className="clientAuths-checkbox-space"
                    name="weeklyNewsletterAddAuth"
                    disabled={(initialValues.userId !== null) || validatingUser}
                    id="weeklyNewsletterAddAuth"
                    checked={receiveWeeklyNewsletter}
                    onChange={handleWeeklyNewsletter}
                  >
                    Weekly
                  </MotifCheckbox>
                </MotifFormField>
              </div>
            </div>
            <div className="clientAuths-Subscription-tgl">
              <div className="realTimeText"><MotifLabel data-testId="lblReceiveRealtimeNotification" style={{ fontWeight: 'bold' }}> Receive Real Time Notification(s):</MotifLabel>
              </div>
            </div>
            <div className="row">
              <div className="col-md-9"> <MotifLabel data-testId="lblReopenChecklists"> 1. Reopen Submitted or Closed Checklist(s):</MotifLabel>
              </div>
              <div className="col-md-3" style={{ margin: 'auto' }}>
                <MotifFormField >
                  <MotifToggleSwitch
                    name="switchRealtimeNotification"
                    id="switchRealtimeNotification"
                    data-testId="chkbxRealtimeNotification"
                    onChange={handleRealTimeNotification}
                    disabled={(initialValues.userId !== null) || validatingUser}
                    checked={receiveRealTimeNotification} />

                </MotifFormField>
              </div>


            </div>
            {(role == "4") && (
              <div className="row">
                <div className="col-md-9"> <MotifLabel data-testId="lblNewPSQARQCSentInternalReview"> 2. New PSQ/ARQC Sent/Internal Review Notification:</MotifLabel>

                </div>
                <div className="col-md-3" style={{ margin: 'auto' }}>
                  <MotifFormField >
                    <MotifToggleSwitch
                      name="switchInternalNotification"
                      id="switchInternalNotification"
                      data-testId="chkbxNewPSQARQCSentInternalReview"
                      disabled={(initialValues.userId !== null) || validatingUser}
                      onChange={handleInternalReviewNotification}
                      checked={receiveRealTimeNotificationForNewPsqArqcSent}
                    />

                  </MotifFormField>
                </div>
              </div>
            )}
          </section>
          {initialValues.userId && (
            <div>
              <div className="mb-3 text-info" style={{ fontSize: "16px" }}>
                {(initialValues.status && "My EY user account found.") ||
                  "My EY user account not found."}
              </div>
              <Field
                name="first_name"
                component={formHelpers.renderField}
                type="text"
                label="First Name"
                maxLength={128}
                disabled={initialValues.status}
                isRequiredField={true}
                validate={[formValidators.required, formValidators.maxLength255]}
              />
              <Field
                name="last_name"
                component={formHelpers.renderField}
                type="text"
                label="Last Name"
                maxLength={128}
                disabled={initialValues.status}
                isRequiredField={true}
                validate={[formValidators.required, formValidators.maxLength255]}
              />

              {initialValues.companies && initialValues.companies.length > 1 && (
                <Field
                  name="companyId"
                  component={formHelpers.SingleSelectComponent}
                  label="Company"
                  options={companyOptions}
                  selectStyle={{ width: "100%", border: "1px solid #c4c4cd " }}
                />
              )}
              {(!initialValues.companies ||
                initialValues.companies.length <= 1) && (
                  <Field
                    name="company_name"
                    component={formHelpers.renderField}
                    type="text"
                    label="Company"
                    maxLength={255}
                    disabled={true}
                    isRequiredField={true}
                    validate={[
                      formValidators.required,
                      formValidators.maxLength255,
                    ]}
                  />
                )}

              <div className="mb-3 text-info" style={{ fontSize: "16px" }}>
                {(initialValues.status && (
                  <React.Fragment>
                    <p>
                      After submission, the user will be able to access REITSuite
                      immediately.
                    </p>
                    <p>Please inform the user and onboard accordingly.</p>
                  </React.Fragment>
                )) || (
                    <React.Fragment>
                      <p>
                        An email will be sent to the external user to complete
                        registration with My EY in order to access REITSuite.
                      </p>
                      <p>Please inform the user and onboard accordingly.</p>
                    </React.Fragment>
                  )}
              </div>
            </div>
          )}
        </MotifModalBody>
        <MotifModalFooter>
          {/* show Validate button if role is External Checklist User, and we haven't validated yet */}
          {role === "4" && !initialValues.userId && (
            <MotifButton
              variant="primary"
              className="right mt-auto mb-auto"
              type="button"
              onClick={() => handleAddClientAuthModalValidateAction(valid)}
              disabled={validatingUser}
              style={{ maxHeight: '3rem' }}
            >
              Validate
            </MotifButton>
          )}
          {(role !== "4" || initialValues.userId) && (
            <MotifButton
              variant="primary"
              className="right mt-auto mb-auto"
              type="button"
              data-testid="submit"
              onClick={() => handleSubmit(values)}
              style={{ maxHeight: '3rem' }}
            >
              Submit
            </MotifButton>
          )}
          <MotifButton
            variant="secondary"
            className="right mt-auto mb-auto"
            type="button"
            data-testid="cancel"
            onClick={toggleShowAddClientAuthModal}
            style={{ maxHeight: '3rem' }}
          >
            Cancel
          </MotifButton>
          {validatingUser && (
            <div className="mt-auto mb-auto ml-auto">Validating User. Please wait...</div>
          )}
          {myEyError && <div className="mt-auto mb-auto ml-3 text-danger">{myEyError}</div>}
        </MotifModalFooter>
      </MotifModal>
    </form >
  );
};

AddClientAuthModal.propTypes = {
  showAddClientAuthModal: PropTypes.bool,
  handleAddClientAuthModalAction: PropTypes.func,
  handleSubmit: PropTypes.func,
  toggleShowAddClientAuthModal: PropTypes.func,
  initialValues: PropTypes.object,
  addAuthMessageText: PropTypes.string,
  clientAuthFormValues: PropTypes.object,
  handleAddClientAuthModalValidateAction: PropTypes.func,
  validatingUser: PropTypes.bool,
  myEyError: PropTypes.string,
  valid: PropTypes.bool,
  invalid: PropTypes.bool,
  values: PropTypes.object,
  receiveDailyNewsletter: PropTypes.bool,
  handleDailyNewsletter: PropTypes.func,
  receiveWeeklyNewsletter: PropTypes.bool,
  handleWeeklyNewsletter: PropTypes.func,
  receiveRealTimeNotification: PropTypes.bool,
  handleRealTimeNotification: PropTypes.func,
  receiveRealTimeNotificationForNewPsqArqcSent: PropTypes.bool,
  handleInternalReviewNotification: PropTypes.func,
  handleOnRoleTypeChange: PropTypes.func
};

export default reduxForm({ form: "clientAuth", enableReinitialize: true })(
  AddClientAuthModal
);
