import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { Header, Label, Button, Dropdown, Input } from 'semantic-ui-react';
import * as formValidators from '../../scripts/formValidators';
import * as formHelpers from '../../scripts/formHelpers';
import ReactModal from 'react-modal';
import $ from 'jquery';
import { MotifButton, MotifModal, MotifModalHeader, MotifModalBody, MotifModalFooter } from '@ey-xd/motif-react';
import * as Constants from '../../constants/other';
const PsqCreateChecklistModal = ({
  handleSubmit,
  handleFieldChanged,
  handleClientEmailChanged,
  handleEyEmailChanged,
  handlePopulatePreviousAnswersChanged,
  showModal,
  isSendEmailEnabled,
  isCreateOnlyEnabled,
  setCreateAction,
  handleCancelAction,
  properties,
  error,
  handleDismissErrorMessage,
  clientEmailsRef,
  selectedPropertyId,
  selectedPropertyName,
  selectedPropertyType,
  checklist,
  customQuestionnaireTemplates,
  lookupTypes,
  clientEmail,
  createChecklistCustomErrorMessage
}) => {
  let propertyOptions = [];
  if (properties !== undefined) {
    propertyOptions = properties.map(property => {
      return { key: property.propertyID, text: property.propertyName, value: property.propertyID };
    });
  }
  let propertyTypeOptions = [];
  if(lookupTypes !== undefined ){
    propertyTypeOptions = lookupTypes.propertyTypes
    .filter(ppt => ppt.propertyTypeID != 0)
    .map(propertyType => {
      return { key: propertyType.propertyTypeID, value: propertyType.propertyTypeID, text: propertyType.propertyTypeDescription }
    });
  }

  let customTemplateOptions = [];
  if (customQuestionnaireTemplates) {
    customTemplateOptions = customQuestionnaireTemplates.map(item => {
      return { key: item.questionnaireTemplateID, text: item.questionnaireTemplateName, value: item.questionnaireTemplateID };
    })
  }

  customTemplateOptions.sort(function (a, b) { return a.key - b.key });

  let prePopulateOptions = Constants.prePopulateOptions; 
  const rollForwardQuestionComment = Constants.rollForwardQuestionComment; 
  const preventSubmitOnEnter = e => {
      if (e.key === 'Enter') {
          //Avoid preventing key press for buttons
          if (e.target && e.target.type !== 'button' && e.target.type !== 'submit') {
              e.preventDefault();
          }
      }
    };

   const handleCreateButtonClick = () => {
    setCreateAction('CREATE');
  };

  const handleCreateAndSendButtonClick = () => {
    setCreateAction('CREATEANDSEND');
  };

  const validateEmails = () => {
    let sendToFieldValue = $('#sendToField :input').val();

    if (sendToFieldValue !== '') {
      throw new SubmissionError({
        _error: "'" + sendToFieldValue + "' is not a valid email address.",
      });
    }

    return handleSubmit();
  };

  const modalStyle = {
    content: { 'max-height': '100vh', 'overflow-y': 'auto', left: '15%', width: '70%' },
  };
    let propertyData = properties.find(property => property.propertyID == selectedPropertyId);
    const HeaderWrapper = ({ children }) => (
      <div
          style={{
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'center'
          }}
      >
          {children}
      </div>
  );

  const closeButtonProps = {
      title: 'Close add new PSQ modal',
      ariaLabel: 'Close add new PSQ modal',
      dataTestid: 'close'
  };


  return (
    <MotifModal variant="alt" size="xl" show={showModal} onClose={handleCancelAction} data-testid="addPSQModal">
          <MotifModalHeader style={{ display: "-webkit-box" }} closeButtonProps={closeButtonProps} >
                <HeaderWrapper>
                    <span className="ml-2">Create a new PSQ</span>
                </HeaderWrapper>
            </MotifModalHeader>
     
         <MotifModalBody>
       <form
        onSubmit={handleSubmit(validateEmails)} id="createPSQ"
        onKeyPress={preventSubmitOnEnter}
      >
        {formHelpers.showSubmissionError(error, handleDismissErrorMessage)}
        <div className="field">
          <label htmlFor="propertyField">Select Property (Required)</label>
          <Dropdown
            fluid
            selection
            onChange={handleFieldChanged}
            name="propertyField"
            options={propertyOptions}
            multiple={false}
            placeholder="Select a property..."
          />
        </div>
        <div className="field">
          <label htmlFor="propertyField">Select Property Type (Required)</label>
          <Dropdown
            fluid
            selection
            onChange={handleFieldChanged}
            name="propertyTypeField"
            options={propertyTypeOptions}
            multiple={false}
            placeholder="Select a property type..."
          />
        </div>
        <div className="field">
          <label htmlFor="customTemplate">Select Template (Required)</label>
          <Dropdown
            fluid
            selection
            onChange={handleFieldChanged}
            name="customTemplateField"
            options={customTemplateOptions}
            multiple={false}
            className="createPSQTemplate"
            placeholder="Select a template..."
          />
        </div>

        <div className="field">
          <label htmlFor="customTemplate">Pre-Populate Answers? / Show Reference Answers?</label>
          <Dropdown
            fluid
            selection
            onChange={handleFieldChanged}
            name="populatePreviousAnswersField"
            options={prePopulateOptions}
            multiple={false}
            defaultValue={Constants.defaultPrepopulateAnswer}
          
          />
        </div>
      
        <div className="field">
          <label htmlFor="customTemplate">Roll Forward Question Comments? / Attachments?</label>
          <Dropdown
            fluid
            selection
            onChange={handleFieldChanged}
            name="rollForwardQuestionCommentsField"
            options={rollForwardQuestionComment}
            multiple={false}
            defaultValue={Constants.defaultRollFowardQuestionComment}
          
          />
        </div>


        <div className="field">
          <Field
            label="Send to (Multiple email addresses are supported if separated by a semi-colon)"
            component={formHelpers.renderReactMultiEmail}
            id="sendToField"
            name="sendToField"
            domRef={clientEmailsRef}
            handleChange={handleClientEmailChanged}
            emails= {clientEmail}
            removeEYEmail={true}
            validate={[
              formValidators.multipleEmails,
              formValidators.maxLength255,
            ]}
          />
        </div>
        {createChecklistCustomErrorMessage && createChecklistCustomErrorMessage.length > 0 &&
            <div className="text-danger">
                {createChecklistCustomErrorMessage}
            </div>
        }
        </form>
        </MotifModalBody>
         <MotifModalFooter>
              <MotifButton variant="primary" className="right" type="submit" form="createPSQ"
                  onClick={handleCreateButtonClick}
                  disabled={!isCreateOnlyEnabled}>
                  Create Only
              </MotifButton>
              <MotifButton variant="primary" className="right" type="submit" form="createPSQ"
                  onClick={handleCreateAndSendButtonClick}
                  disabled={!isSendEmailEnabled || !isCreateOnlyEnabled}>
                  Create &amp; Send
              </MotifButton>
              <MotifButton variant="secondary" className="right" type="button"
                  onClick={handleCancelAction} >
                  Cancel
            </MotifButton>
            </MotifModalFooter>
      
      </MotifModal>
  );
};

PsqCreateChecklistModal.propTypes = {
  showModal: PropTypes.bool,
  properties: PropTypes.array,
  handleSubmit: PropTypes.func,
  handleFieldChanged: PropTypes.func,
  handleClientEmailChanged: PropTypes.func,
  handleEyEmailChanged: PropTypes.func,
  handlePopulatePreviousAnswersChanged: PropTypes.func,
  isSendEmailEnabled: PropTypes.bool,
  isCreateOnlyEnabled: PropTypes.bool,
  setCreateAction: PropTypes.func,
  handleCancelAction: PropTypes.func,
  error: PropTypes.string,
  handleDismissErrorMessage: PropTypes.func,
  domRef: PropTypes.func,
  clientEmailsRef: PropTypes.func,
  selectedPropertyId: PropTypes.number,
  selectedPropertyName: PropTypes.string,
  selectedPropertyType: PropTypes.string,
  checklist: PropTypes.object,
  customQuestionnaireTemplates: PropTypes.array,
  clientEmail:PropTypes.array
};

export default reduxForm({
  form: 'psqcreatechecklistmodal',
  enableReinitialize: true,
})(PsqCreateChecklistModal);
