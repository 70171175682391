import React from 'react';
import PropTypes from 'prop-types';
import EditTrsRow from './EditTrsRow';

const EditTrsTable = ({ taxableReitSubsidiaries, showForm, handleEditButtonClick, handleDeleteButtonClick, canEdit }) => {
    return (
        <section className="table-responsive rtable">
            <table>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>$ Value</th>
                    <th>$ Debt</th>
                    <th>$ Equity</th>
                    <th />
                </tr>
                </thead>
                <tbody>
                    {taxableReitSubsidiaries.map(trs => <EditTrsRow
                        key={trs.taxableREITSubsidiaryID}
                        trs={trs}
                        showForm={showForm}
                        handleEditButtonClick={handleEditButtonClick}
                        handleDeleteButtonClick={handleDeleteButtonClick}
                        canEdit={canEdit} />)}
                </tbody>
            </table>
        </section>
    );
};

EditTrsTable.propTypes = {
    taxableReitSubsidiaries: PropTypes.arrayOf(PropTypes.shape({
        taxableREITSubsidiaryID: PropTypes.number,
        reportPeriodID: PropTypes.number,
        trsName: PropTypes.string,
        trsValue: PropTypes.number,
        trsDebt: PropTypes.number,
        trsEquity: PropTypes.number
    })),
    showForm: PropTypes.bool,
    handleEditButtonClick: PropTypes.func.isRequired,
    handleDeleteButtonClick: PropTypes.func.isRequired,
    canEdit: PropTypes.bool
};

export default EditTrsTable;