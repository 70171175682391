import React from 'react';
import { Link } from 'react-router';
import {
    MotifHeader,
    MotifIconButton,
    MotifIcon,
    MotifHeaderLogo
} from "@ey-xd/motif-react";
import { defaultLogo } from '@ey-xd/motif-react/assets/images';

const ExternalNavBar = () => {
  return (
      <MotifHeader
                  fixed
                  logo={
                      <MotifHeaderLogo >
                          <a href="/">
                              <MotifIcon src={defaultLogo} aria-label="EY Logo for REITSuite"/>
                              <span className="hide-for-accessibility">Home</span>
                          </a>
                      </MotifHeaderLogo>
                  }
                  appHeaderName="REITSuite"                 
              >
              </MotifHeader>
  );
};

export default ExternalNavBar;
