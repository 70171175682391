import React from 'react';

export default (props) => {
  const value = !props.data && props.node.childrenAfterGroup && props.node.childrenAfterGroup.length > 0 ? props.node.childrenAfterGroup[0].data[props.colDef.field] : null;
  const checklistId = !props.data && props.node.childrenAfterGroup && props.node.childrenAfterGroup.length > 0 ? props.node.childrenAfterGroup[0].data["checklistID"] : null;

  if (!props.data && props.node.childrenAfterGroup){
    const defaultCheckboxClass = "ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper";
    let checkboxClass = value ? defaultCheckboxClass + " ag-checked" : defaultCheckboxClass;
    
    return (
      <span>
        <div className={checkboxClass} onClick={()=>props.onCellCheckboxChange(checklistId, props.node)} role="presentation">
          <input class="ag-input-field-input ag-checkbox-input" type="checkbox" id="ag-cell-checkbox" aria-label="Press Space to toggle row selection" checked={value} tabindex="-1"/>
        </div>
      </span>
    );
  }

  return null;
};