import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Breadcrumbs from 'react-breadcrumbs';
import { MotifBreadcrumb, MotifBreadcrumbItem, MotifIcon, MotifButton, MotifDropdown, MotifDropdownItem } from '@ey-xd/motif-react';
import { navigationIcClose24px, navigationIcArrowDropDown24px } from '@ey-xd/motif-react/assets/icons';
import ReactModal from 'react-modal';
import Spinner from 'react-redux-spinner';
import { Button, Header, Icon, Image, Menu, Segment, Sidebar, Dropdown } from 'semantic-ui-react';
import Dimensions from 'react-dimensions';
import TabbedNavigationContainer from './shared/TabbedNavigationContainer';
import ErrorSummary from '../components/shared/ErrorSummary';
import * as commonActions from "../actions/commonActions";
import * as authActions from "../actions/authActions";
import * as notificationActions from "../actions/notificationActions";
import * as servicesActions from "../actions/servicesActions";
import * as trialBalanceActions from '../actions/trialBalanceActions';
import Notifications from "./notifications/Notifications";
import UploadQueueWatcher from "./tb/UploadQueueWatcher2";
import { BulkUploadModal } from '../components/tb/BulkUploadModal';
import { Button as BootstrapButton, Dropdown as BootstrapDropdown, ButtonGroup, DropdownButton } from 'react-bootstrap';
import ReitSuiteUsersMessage from '../components/shared/ReitSuiteUsersMessage';
import { withMsal } from "@azure/msal-react";
import Footer from '../components/Footer';

/**
 * LoginPage container component
 * @extends {React.Component}
 */
export class Main extends React.Component {
    /**
     * Creates a new LoginPage
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);

        this.state = {
            clientDropdownOpen: false
        }

        this.handleDismissErrorMessage = this.handleDismissErrorMessage.bind(this);
        this.handleDismissUsersMessage = this.handleDismissUsersMessage.bind(this);
        this.handleCloseNotifications = this.handleCloseNotifications.bind(this);
        this.handleClientManagementDropdownToggle = this.handleClientManagementDropdownToggle.bind(this);
        this.closeClientManagementDropdown = this.closeClientManagementDropdown.bind(this);
    }

    componentDidMount() {
        this.props.servicesActions.fetchNavigationServicesByPeriod(this.props.params.periodId);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.currentLocation != this.props.currentLocation) {
            this.props.commonActions.clearErrorResponse();
        }

        if (nextProps.params.periodId !== this.props.params.periodId) {
            this.props.servicesActions.fetchNavigationServicesByPeriod(nextProps.params.periodId);
        }

        if (nextProps.routes !== this.props.routes || (nextProps.client !== this.props.client) || (nextProps.period !== this.props.period) || (nextProps.reit !== this.props.reit)) {
            this.handleTabPageLocation(nextProps.routes);
        }

    }

    handleTabPageLocation(routes) {
        let tabTitle = '';
        let tabTitlesArray = [];
        let pathTitlesToReName = ['client'];
        routes.forEach((route, index) => {
            if (index == 0 || (index == routes.length - 1 && !route.path)) return;
            let initialPath = route.path.split('/')[0];
            let currentTitle = '';
            if (typeof route.getDisplayName === 'function') {
                currentTitle = route.getDisplayName();
            }
            if (route.name && !pathTitlesToReName.includes(initialPath)) {
                currentTitle = route.name;
            }
            tabTitlesArray.unshift(currentTitle);
        });
        tabTitlesArray.push('EY REITSuite');
        tabTitle = tabTitlesArray.join(' | ');
        window.document.title = tabTitle;
    }

    handleCloseNotifications(e) {
        e.preventDefault();
        this.props.notificationActions.toggleNotificationsDisplay();
    }

    handleDismissErrorMessage() {
        this.props.commonActions.clearErrorResponse();
    }

    handleDismissUsersMessage() {
        this.props.commonActions.dismissUsersMessage();
    }

    handleClientManagementDropdownToggle() {
        this.setState({ clientDropdownOpen: !this.state.clientDropdownOpen })
    }

    closeClientManagementDropdown() {
        this.setState({ clientDropdownOpen: false })
    }

    getBreadcrumbsAction() {
        if (Array.isArray(this.props.currentUserAuthorizations)) {
            const links = [];

            let newClientLink = <React.Fragment />;

            if (authActions.isSystemAdministrator(this.props.currentUserAuthorizations) && this.props.currentLocation && (this.props.currentLocation === "/" || this.props.currentLocation === "/clients")) {
                newClientLink = <MotifButton variant="primary" className="right" onClick={() => {this.context.router.push(`/client/edit`)}}>New Client</MotifButton>;
            } else if (this.props.client && this.props.client.id && (authActions.isSystemAdministrator(this.props.currentUserAuthorizations) || authActions.isEngagementAdministrator(this.props.currentUserAuthorizations, this.props.client.id)) && this.props.currentLocation && this.props.currentLocation.indexOf("/client/") >= 0 && this.props.currentLocation.indexOf("/edit") == -1) {
                links.push({
                    key: "client",
                    as: Link,
                    to: "/client/" + this.props.client.id + "/edit",
                    text: "Client Settings",
                    dataTestId: 'btnClientSettings'
                });
            }

            if (this.props.client
                && this.props.client.id
                && (authActions.isSystemAdministrator(this.props.currentUserAuthorizations)
                    || authActions.isEngagementAdministrator(this.props.currentUserAuthorizations, this.props.client.id)
                    || authActions.isUser(this.props.currentUserAuthorizations, this.props.client.id))
                && this.props.currentLocation &&
                this.props.currentLocation.indexOf("/client/") >= 0
                && this.props.currentLocation.indexOf("/checklistManagement") == -1
                && this.props.currentLocation.indexOf("/manageChecklist") == -1) {
                links.push({
                    key: "checklistManagement",
                    as: Link,
                    to: "/client/" + this.props.client.id + "/checklistManagement",
                    text: "Checklist Management",
                    dataTestId: 'btnChecklistManagement'
                });
            }

            if (this.props.client
                && this.props.client.id
                && !this.props.client.isAudit
                && (authActions.isSystemAdministrator(this.props.currentUserAuthorizations)
                || authActions.isEngagementAdministrator(this.props.currentUserAuthorizations, this.props.client.id)
                || authActions.isUser(this.props.currentUserAuthorizations, this.props.client.id))
                && this.props.currentLocation &&
                this.props.currentLocation.indexOf("/client/") >= 0
                && this.props.currentLocation.indexOf("/customPsqTemplate") == -1) {
                links.push({
                    key: "customPSQTemplate",
                    as: Link,
                    to: "/client/" + this.props.client.id + "/customPsqTemplate",
                    text: "Custom PSQ Template",
                    dataTestId: 'btnCustomPSQTemplate'
                });
            }

            if (this.props.client
                && this.props.client.id
                && !this.props.client.isAudit
                && (authActions.isSystemAdministrator(this.props.currentUserAuthorizations)
                || authActions.isEngagementAdministrator(this.props.currentUserAuthorizations, this.props.client.id)
                || authActions.isUser(this.props.currentUserAuthorizations, this.props.client.id))
                && this.props.currentLocation &&
                this.props.currentLocation.indexOf("/client/") >= 0
                && this.props.currentLocation.indexOf("/customFdrTemplate") == -1) {
                links.push({
                    key: "customFDRTemplate",
                    as: Link,
                    to: "/client/" + this.props.client.id + "/customFdrTemplate",
                    text: "Custom FDR Template",
                    dataTestId: 'btnCustomFDRTemplate'
                });
            }

            if (this.props.client
                && this.props.client.id
                && !this.props.client.isAudit
                && (authActions.isSystemAdministrator(this.props.currentUserAuthorizations)
                    || authActions.isEngagementAdministrator(this.props.currentUserAuthorizations, this.props.client.id))
                && this.props.currentLocation &&
                this.props.currentLocation.indexOf("/client/") >= 0
                && this.props.currentLocation.indexOf("/chartOfAccountsManager") == -1) {
                links.push({
                    key: "coaManager",
                    as: Link,
                    to: "/client/" + this.props.client.id + "/chartOfAccountsManager",
                    text: "Chart of Accounts Manager",
                    className: "btn btn-primary",
                    dataTestId: 'btnCoaManager'
                });
            }

            if (this.props.client
                && this.props.client.id
                && (authActions.isSystemAdministrator(this.props.currentUserAuthorizations)
                || authActions.isEngagementAdministrator(this.props.currentUserAuthorizations, this.props.client.id)
                || authActions.isUser(this.props.currentUserAuthorizations, this.props.client.id))
                && this.props.currentLocation &&
                this.props.currentLocation.indexOf("/client/") >= 0
                && this.props.currentLocation.indexOf("/bulkReportManagement") == -1) {
                links.push({
                    key: "bulkReportManagement",
                    as: Link,
                    to: "/client/" + this.props.client.id + "/bulkReportManagement",
                    text: "Bulk Report Management",
                    className: "btn btn-primary",
                    dataTestId:'btnBulkReportManagement'
                });
            }

            if (this.props.client
                && this.props.client.id
                && (authActions.isSystemAdministrator(this.props.currentUserAuthorizations)
                || authActions.isEngagementAdministrator(this.props.currentUserAuthorizations, this.props.client.id)
                || authActions.isUser(this.props.currentUserAuthorizations, this.props.client.id))
                && this.props.currentLocation &&
                this.props.currentLocation.indexOf("/client/") >= 0
                && this.props.currentLocation.indexOf("/bulkProcessManagement") == -1) {
                links.push({
                    key: "bulkProcessManagement",
                    as: Link,
                    to: "/client/" + this.props.client.id + "/bulkProcessManagement",
                    text: "Bulk Process Management",
                    className: "btn btn-primary",
                    dataTestId:'btnBulkProcessManagement'
                });
            }

            if (this.props.client
                && this.props.client.id
                && (authActions.isSystemAdministrator(this.props.currentUserAuthorizations)
                || authActions.isEngagementAdministrator(this.props.currentUserAuthorizations, this.props.client.id)
                || authActions.isUser(this.props.currentUserAuthorizations, this.props.client.id))
                && this.props.currentLocation &&
                this.props.currentLocation.indexOf("/client/") >= 0
                && this.props.currentLocation.indexOf("/bulkRollForward") == -1) {
                links.push({
                    key: "bulkRollForward",
                    as: Link,
                    to: "/client/" + this.props.client.id + "/bulkRollForward",
                    text: "Bulk Roll Forward",
                    className: "btn btn-primary",
                    dataTestId:'btnBulkRollForward'
                });
            }

            const dropDownLinks = (links.length > 0 ?
                <MotifDropdown
                    open={this.state.clientDropdownOpen}
                    placement="bottom-right"
                    id={'client-management'}
                    aria-labelledby={'client-management-trigger'}
                    handleClickOutside={this.closeClientManagementDropdown}
                    trigger={
                        <MotifButton
                            type="button"
                            variant="primary-alt"
                            onClick={this.handleClientManagementDropdownToggle}
                            aria-controls={'client-management'}
                            id={'client-management-trigger'}
                            aria-expanded={this.state.clientDropdownOpen}
                            aria-label="Client Management dropdown"
                        >
                            Client Management <MotifIcon src={navigationIcArrowDropDown24px} />
                        </MotifButton>
                    }
                >
                    {links.map((link, index) => <Link tabIndex='-1' style={{ color: '#2e2e38' }} to={link.to}><MotifDropdownItem data-testid={link.dataTestId}  onClick={this.closeClientManagementDropdown} key={link.key}>{link.text}</MotifDropdownItem></Link>)}
                </MotifDropdown> : <React.Fragment />);

            return <React.Fragment>
                {newClientLink}
                {dropDownLinks}
            </React.Fragment>;
        }

        return (<span />);
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        const loadingModalStyle = {
            content: {
                backgroundColor: 'transparent',
                border: 'none',
                color: 'white',
                textAlign: 'center',
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '60%',
                overlfow: 'none' // <-- This tells the modal to scrol
            }
        };

        MotifBreadcrumb.defaultProps = {
            triggerButtonProps: {
                title: 'Custom Trigger Button title',
                'aria-label': 'Custom Trigger Button aria-label'
            },
        };
        

        return (
            <div style={{ height: '100%', minHeight: this.props.containerHeight - 84, paddingBottom: '2px', marginTop: '-1px' }}>                
                <Sidebar.Pushable as={Segment}>
                    <Sidebar
                        as={Menu}
                        animation="overlay"
                        icon="labeled"
                        inverted
                        vertical
                        visible={this.props.showNotifications}
                        width="wide"
                        direction="right">
                        <div style={{ display: 'flex', justifyContent: "space-between" }}>
                            <div><h3>Notifications</h3></div>
                            <div style={{ display: 'flex' }}>
                                <MotifButton variant="text" className="notification-motif-text-button" onClick={this.handleCloseNotifications} title="Close sidebar">
                                    <div style={{ marginLeft: '14px' }} >
                                        <MotifIcon style={{ color: '#c4c4cd' }} src={navigationIcClose24px} />
                                    </div>
                                </MotifButton>
                            </div>
                        </div>
                        <Notifications userId={this.props.currentUserId} />
                    </Sidebar>

                    <Sidebar.Pusher>
                        <Segment basic>
                            <div className="container-fluid" style={{ minHeight: this.props.containerHeight - 84 }}>
                                <div style={{ minHeight: this.props.containerHeight - 60 }}>
                                    {this.props.currentLocation &&
                                        this.props.currentLocation.toLowerCase().indexOf("/psqrecord") < 0 &&
                                        this.props.currentLocation.toLowerCase().indexOf("/arqcrecord") < 0 &&
                                        this.props.currentLocation.toLowerCase().indexOf("/preview") < 0 &&
                                        <div className="row header breadcrumbs d-flex justify-content-between">
                                            <div className="col-md-10">
                                                <Breadcrumbs
                                                    routes={this.props.routes}
                                                    params={this.props.params}
                                                    client={this.props.client}
                                                    reit={this.props.reit}
                                                    period={this.props.period}
                                                    displayMissing={false}
                                                    separator=""
                                                    wrapperElement={MotifBreadcrumb}
                                                    itemElement={MotifBreadcrumbItem}                                              
                                                />
                                            </div>
                                            <div className="col-md-2 breadcrumbs-action">{this.getBreadcrumbsAction()}</div>
                                        </div>
                                    }
                                    {(this.props.showTabNav && this.props.currentLocation &&
                                        this.props.currentLocation.toLowerCase().indexOf("/psqrecord") < 0 &&
                                        this.props.currentLocation.toLowerCase().indexOf("/arqcrecord") < 0 &&
                                        <TabbedNavigationContainer clientId={this.props.client && this.props.client.id} reitId={this.props.reit && this.props.reit.reitid} periodId={this.props.period && this.props.period.reportPeriodID} currentLocation={this.props.currentLocation} />) || null}
                                <div style={{ minHeight : '24px'} }>
                                    {(this.props.errorResponse && !this.props.hideMainErrorSummary && <ErrorSummary errorResponse={this.props.errorResponse} handleDismiss={this.handleDismissErrorMessage} />) || null}
                                    {!this.props.dismissUsersMessage && <ReitSuiteUsersMessage handleDismiss={this.handleDismissUsersMessage} expireDate={"2022/02/28"} />}
                                </div>
                                    {this.props.children}
                                </div>
                                { this.props.currentLocation &&
                                  this.props.currentLocation.toLowerCase().indexOf("/template") < 0 &&
                                    <Footer>{this.props.children}</Footer>
                                }
                            </div>
                        </Segment>
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
                <BulkUploadModal
                    clearUploadTrialBalanceProgress={this.props.clearUploadTrialBalanceProgress}
                    addFilesToQueue={this.props.addFilesToQueue}
                    notifyRejectedFiles={this.props.notifyRejectedFiles}
                    fetchQueueCount={this.props.fetchQueueCount}
                    hideBulkUploadModal={this.props.hideBulkUploadModal}
                    uploadProgress={this.props.uploadProgress}
                    currentUserId={this.props.currentUserId}
                    processingQueueCount={this.props.processingQueueCount}
                    showBulkUpload={this.props.showBulkUpload}
                    client={this.props.client}
                />
                <ReactModal isOpen={this.props.pendingTasks > 0} contentLabel="" style={loadingModalStyle} overlayClassName="spinner-overlay">
                    <img src="/images/loading-spinner-black.gif" height="20px" /> ...loading...please wait...
                </ReactModal>
                <UploadQueueWatcher />
            </div>
        );
    }
}

Main.propTypes = {
    children: PropTypes.element,
    routes: PropTypes.array,
    params: PropTypes.object,
    client: PropTypes.object,
    reit: PropTypes.object,
    period: PropTypes.object,
    currentLocation: PropTypes.string,
    showTabNav: PropTypes.bool,
    hideMainErrorSummary: PropTypes.bool,
    pendingTasks: PropTypes.number,
    errorResponse: PropTypes.object,
    currentUserAuthorizations: PropTypes.array,
    containerHeight: PropTypes.number.isRequired,
    commonActions: PropTypes.object.isRequired,
    notificationActions: PropTypes.object.isRequired,
    servicesActions: PropTypes.object.isRequired,
    showNotifications: PropTypes.bool.isRequired
};

Main.contextTypes = {
    router: PropTypes.object
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties belonging to this component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    let result = {
        client: state.client,
        reit: state.reit,
        period: state.period,
        routes: ownProps.children.props.routes,
        params: ownProps.children.props.params,
        currentLocation: ownProps.children.props.location.pathname,
        showTabNav: shouldShowTabNav(ownProps.children.props),
        hideMainErrorSummary: shouldHideMainErrorSummary(ownProps.children.props),
        pendingTasks: state.pendingTasks,
        errorResponse: state.errorResponse,
        dismissUsersMessage: state.dismissUsersMessage,
        currentUserAuthorizations: state.currentUserAuthorizations,
        showNotifications: state.showNotifications,
        uploadProgress: state.uploadProgress,
        currentUserId: ownProps.userId,
        processingQueueCount: state.processingQueueCount,
        showBulkUpload: state.showBulkUploadModal,
    };
    return result;
}

/**
 * Determines whether or not the route specifies that we should show the tabbed navigation.
 * @param {any} props The component properties
 * @returns {bool} True if we should show tabbed navigation, otherwise false
 */
function shouldShowTabNav(props) {
    if (!props) {
        return false;
    }

    if (!props.route || !props.route.showTabNav) {
        if (props.children && props.children.props) {
            return shouldShowTabNav(props.children.props);
        } else {
            return false;
        }
    } else {
        return props.route.showTabNav;
    }
}

/** Determines whether to hide the main error summary
 * @param { any } props The component properties
 * @returns { bool } True if we should hide the main error summary, otherwise false
 */
function shouldHideMainErrorSummary(props) {
    if (!props) {
        return false;
    }

    if (!props.route || !props.route.hideMainErrorSummary) {
        if (props.children && props.children.props) {
            return shouldHideMainErrorSummary(props.children.props);
        } else {
            return false;
        }
    } else {
        return props.route.hideMainErrorSummary;
    }
}


/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        notificationActions: bindActionCreators(notificationActions, dispatch),
        servicesActions: bindActionCreators(servicesActions, dispatch),
        clearUploadTrialBalanceProgress: () => trialBalanceActions.clearUploadTrialBalanceProgress()(dispatch),
        addFilesToQueue: (files, userId, clientId, notifyStatus) => trialBalanceActions.addFilesToQueue(files, userId, clientId, notifyStatus)(dispatch),
        notifyRejectedFiles: (files, userId) => trialBalanceActions.notifyRejectedFiles(files, userId)(dispatch),
        fetchQueueCount: () => trialBalanceActions.fetchQueueCount()(dispatch),
        showBulkUploadModal: () => trialBalanceActions.showBulkUploadModal()(dispatch),
        hideBulkUploadModal: () => trialBalanceActions.hideBulkUploadModal()(dispatch)
    };
}

export default Dimensions({
    getHeight: function (element) {
        return window.innerHeight - 72;
    },
    getWidth: function (element) {
        let widthOffset = window.innerWidth < 680 ? 0 : 47;
        return window.innerWidth - widthOffset;
    }
})(connect(mapStateToProps, mapDispatchToProps)(withMsal(Main)));
