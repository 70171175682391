import PropTypes from "prop-types";
import React from "react";
import { Field, reduxForm } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import { MotifButton, MotifFormField, MotifCheckbox, MotifTooltip, MotifToggleSwitch, MotifLabel, MotifModal, MotifModalHeader, MotifModalBody, MotifModalFooter } from "@ey-xd/motif-react";

const EditClientAuthModal = ({
  showEditClientAuthModal,
  handleSubmit,
  toggleShowEditClientAuthModal,
  initialValues,
  clientAuthFormValues,
  receiveDailyNewsletter,
  handleDailyNewsletter,
  receiveWeeklyNewsletter,
  handleWeeklyNewsletter,
  receiveRealTimeNotification,
  handleRealTimeNotification,
  receiveRealTimeNotificationForNewPsqArqcSent,
  handleInternalReviewNotification
}) => {
  let email = "";
  if (initialValues && initialValues.userId) {
    email = initialValues.userId;
  }
  let initialRole = "";
  if (initialValues && initialValues.role) {
    initialRole = initialValues.role;
  }
  let newRole = "";
  if (clientAuthFormValues && clientAuthFormValues.role) {
    newRole = clientAuthFormValues.role;
  }
  let changedToInvalidRole = false;
  if (
    (initialRole === "4" && newRole !== "4") ||
    (initialRole !== "4" && newRole === "4")
  ) {
    changedToInvalidRole = true;
  }

  return (
    <MotifModal show={showEditClientAuthModal} onClose={toggleShowEditClientAuthModal} variant="alt" className="edit-client-auth-motif-modal">
      <MotifModalHeader>Edit User Authorization</MotifModalHeader>
      <form onSubmit={handleSubmit} className="clientAuth">
        <MotifModalBody>
          <Field
            name="userId"
            type="hidden"
            component={formHelpers.renderField}
            label=""
          />
          <Field
            name="resource"
            type="hidden"
            component={formHelpers.renderField}
            label=""
          />
          <Field
            name="id"
            type="hidden"
            component={formHelpers.renderField}
            label=""
          />
          <MotifTooltip
            placement="bottom-left"
            trigger={<h4 className="truncateEmail"> {email}</h4>}
          >
            {email}
          </MotifTooltip>
          <br />
          <br />
          <label>
            <Field
              name="role"
              component={formHelpers.renderField}
              type="radio"
              value="1"
              label="Engagement Administrator"
            />
          </label>
          <br />
          <label>
            <Field
              name="role"
              component={formHelpers.renderField}
              type="radio"
              value="2"
              label="User"
            />
          </label>
          <br />
          <label>
            <Field
              name="role"
              component={formHelpers.renderField}
              type="radio"
              value="4"
              label="External Checklist User"
            />
          </label>
          <br />
          {changedToInvalidRole && (
            <React.Fragment>
              <span className="text-danger">
                This email domain cannot change into this Role.
              </span>
              <br />
            </React.Fragment>
          )}
          <section className="clientAuthsSubscription">
            <div>
              <span
                className="clientAuths-Subscription-text"
                data-testid="receiveChecklistNewletter"
              >
                Receive Checklist Newsletter
              </span>
              <div className="clientAuths-Subscription-checkbox">
                <MotifFormField>
                  <MotifCheckbox
                    type="checkbox"
                    data-testid="chkbxDaily"
                    className="clientAuths-checkbox-space"
                    name="dailyNewsletter"
                    id="dailyNewsletter"
                    checked={receiveDailyNewsletter}
                    onChange={handleDailyNewsletter}
                  >
                    Daily
                  </MotifCheckbox>
                </MotifFormField>
                <MotifFormField>
                  <MotifCheckbox
                    type="checkbox"
                    data-testid="chkbxWeekly"
                    className="clientAuths-checkbox-space"
                    name="weeklyNewsletter"
                    id="weeklyNewsletter"
                    checked={receiveWeeklyNewsletter}
                    onChange={handleWeeklyNewsletter}
                  >
                    Weekly
                  </MotifCheckbox>
                </MotifFormField>
              </div>
            </div>
            <div>
              <div className="clientAuths-Subscription-tgl">
                <div className="realTimeText">
                  <MotifLabel
                    data-testId="lblReceiveRealtimeNotification"
                    style={{ fontWeight: "bold" }}
                  >
                    Receive Real Time Notification(s):
                  </MotifLabel>
                </div>
              </div>
              <div className="row">
                <div className="col-md-9">
                  <MotifLabel data-testId="lblReopenChecklists">
                    1. Reopen Submitted or Closed Checklist(s):
                  </MotifLabel>
                </div>
                <div className="col-md-3" style={{ margin: "auto" }}>
                  <MotifFormField>
                    <MotifToggleSwitch
                      name="switchRealtimeNotification"
                      id="switchRealtimeNotification"
                      data-testId="chkbxRealtimeNotification"
                      onChange={handleRealTimeNotification}
                      checked={receiveRealTimeNotification} />
                  </MotifFormField>
                </div>
              </div>
              {(newRole == "4") && (
                <div className="row">
                  <div className="col-md-9">
                    <MotifLabel data-testId="lblNewPSQARQCSentInternalReview">
                      2. New PSQ/ARQC Sent/Internal Review Notification:
                    </MotifLabel>
                  </div>
                  <div className="col-md-3" style={{ margin: "auto" }}>
                    <MotifFormField>
                      <MotifToggleSwitch
                        name="switchInternalNotification"
                        id="switchInternalNotification"
                        data-testId="chkbxNewPSQARQCSentInternalReview"
                        onChange={handleInternalReviewNotification}
                        checked={receiveRealTimeNotificationForNewPsqArqcSent}
                      />
                    </MotifFormField>
                  </div>
                </div>
              )}
            </div>
          </section>
        </MotifModalBody>
        <MotifModalFooter>
          <MotifButton
            variant="primary"
            className="right"
            type="submit"
            data-testid="submit"
            disabled={changedToInvalidRole}
          >
            Submit
          </MotifButton>
          <MotifButton
            variant="secondary"
            className="right"
            type="button"
            data-testid="cancel"
            onClick={toggleShowEditClientAuthModal}
          >
            Cancel
          </MotifButton>
        </MotifModalFooter>
      </form>
    </MotifModal>
  );
};

EditClientAuthModal.propTypes = {
  showEditClientAuthModal: PropTypes.bool,
  handleEditClientAuthModalAction: PropTypes.func,
  handleSubmit: PropTypes.func,
  toggleShowEditClientAuthModal: PropTypes.func,
  initialValues: PropTypes.object,
  clientAuthFormValues: PropTypes.object,
  receiveDailyNewsletter: PropTypes.bool,
  handleDailyNewsletter: PropTypes.func,
  receiveWeeklyNewsletter: PropTypes.bool,
  handleWeeklyNewsletter: PropTypes.func,
  receiveRealTimeNotification: PropTypes.bool,
  handleRealTimeNotification: PropTypes.func,
  receiveRealTimeNotificationForNewPsqArqcSent: PropTypes.bool,
  handleInternalReviewNotification: PropTypes.func
};

export default reduxForm({ form: "clientAuth", enableReinitialize: true })(
  EditClientAuthModal
);
