import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Icon, Progress, Button } from 'semantic-ui-react';
import ReactModal from "react-modal";
import { MotifButton, MotifTextLink, MotifIcon } from '@ey-xd/motif-react';
import * as Constants from '../../constants/other';
import { fileIcCloudUpload24px } from '@ey-xd/motif-react/assets/icons';
/**
 * Container component used for uploading trial balance files
 */
export class BulkUploadModal extends React.Component {
    /**
     * Creates a new TrialBalanceUploadPage
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);

        this.renderUploadProgress = this.renderUploadProgress.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.getQueueCount = this.getQueueCount.bind(this);

        this.state = {
            fileStatuses: []
        };
    }

    componentDidUpdate(prevProps) {        
        // If modal is shown
        if (this.props.showBulkUpload && !prevProps.showBulkUpload) {
            this.getQueueCount();
            this.timer = setInterval(this.getQueueCount, 3500);
        } else if (!this.props.showBulkUpload && prevProps.showBulkUpload) { // else if modal is hidden
            if (this.timer) {
                clearInterval(this.timer);
                this.timer = null;
            }
        }       
    }

    getQueueCount() {
        this.props.fetchQueueCount();
    }

    onDrop(acceptedFiles, rejectedFiles) {

        if (!this.props.client) {
            return;
        }

        const addedStatuses = [];
        this.props.clearUploadTrialBalanceProgress();

        if (Array.isArray(acceptedFiles)) {
            const acceptedFileStatuses =
                acceptedFiles.map(file => ({ file, status: 'PENDING' }));

            const notifyStatus = (file, status) => {
                acceptedFileStatuses
                    .filter(s => s.file === file)
                    .forEach(s => s.status = status);

                this.setState({ fileStatuses: [...this.state.fileStatuses] });
            }

            addedStatuses.push(...acceptedFileStatuses);
            this.props.addFilesToQueue(acceptedFiles, this.props.currentUserId, this.props.client.id, notifyStatus);
        }

        if (Array.isArray(rejectedFiles)) {
            const rejectedFileStatuses =
                rejectedFiles.map(file => ({ file: file.file, status: 'REJECTED' }));

            addedStatuses.push(...rejectedFileStatuses);
            this.props.notifyRejectedFiles(rejectedFiles, this.props.currentUserId);
        }

        this.setState({ fileStatuses: [...this.state.fileStatuses, ...addedStatuses] });
    }

    renderUploadProgress() {        
        const getClassNameAndMessage = (status, fileSize) => {
            
            if (status === 'PENDING') {
                return { className: 'row', message: 'Pending Upload' };
            }

            if (status === 'ERROR') {
                return {
                    className: 'row bg-danger text-white',
                    message: 'Upload Error'
                };
            }

            if (status === 'IN_PROGRESS') {
                return {
                    className: 'row bg-secondary text-white',
                    message: 'Upload In Progress'
                };
            }

            if (status === 'UPLOADED') {
                return {
                    className: 'row bg-success text-white',
                    message: 'File Uploaded. Check progress in notifications sidebar.'
                };
            }

            if (status === 'REJECTED') {
                return {
                    className: 'row bg-danger text-white',
                    message: fileSize > Constants.MAX_UPLOAD_FILE_SIZE_IN_BYTES 
                    ? 'The file size has exceeded max limit of ' + Constants.MAX_UPLOAD_FILE_SIZE_IN_MB +'MB.'
                    : 'Invalid file extension.'
                };
            }

            return { className: 'row bg-warning', message: 'Status Unknown' };
        };

        return this.state.fileStatuses.map((item, index) => 
            <div
                key={index}
                className={getClassNameAndMessage(item.status, item.file.size).className}>
                <div className="col-md-6">
                    {item.file.name}
                </div>
                <div className="col-md-6">
                    {getClassNameAndMessage(item.status, item.file.size).message}
                </div>
            </div>
        );
    }

    /**
        * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        const clear = () => {
            this.props.hideBulkUploadModal();

            // clear everything except PENDING and IN_PROGRESS
            this.setState({ fileStatuses: this.state.fileStatuses.filter(x => x.status === 'PENDING' || x.status === 'IN_PROGRESS') });
        };

        return (
            <ReactModal contentLabel="TB Bulk Upload" isOpen={this.props.showBulkUpload} onRequestClose={this.props.hideBulkUploadModal} className="react-modal react-modal" overlayClassName="react-modal-overlay-scrollable">
                <div style={{ textAlign: 'center' }}>
                    <h3 style={{ textAlign: 'left' }}>Client Trial Balance Upload</h3>
                    <Dropzone
                        tabIndex="-1"
                        multiple={true}
                        accept=".xlsx"
                        onDrop={this.onDrop}
                        maxSize={Constants.MAX_UPLOAD_FILE_SIZE_IN_BYTES}>
                        {({ getRootProps, getInputProps }) => (
                            <section className="container">
                                <div {...getRootProps({ className: 'dropzone' })} tabIndex="-1"
                                    style={{ width: '100%', textAlign: 'center', border: '1px dashed #666', padding: '50px' }}  >
                                    <div><MotifIcon src={fileIcCloudUpload24px} className="uploadIconSize" title="Upload File(s)" /></div>
                                    <input {...getInputProps()} />
                                    <div>Try dropping some files here, or click to <MotifTextLink href="javascript:void(0)" variant="alt">select files</MotifTextLink> to upload.</div>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                    <div className="react-modal-content-scrollable mt-3 mb-3">
                        {this.renderUploadProgress()}
                    </div>
                </div>                
                <MotifButton variant="primary" className="right" type="button"
                    onClick={clear}>
                    Close
                </MotifButton>
            </ReactModal>
        );
    }
}

BulkUploadModal.propTypes = {
    uploadProgress: PropTypes.array,
    fetchQueueCount: PropTypes.func.isRequired,
    clearUploadTrialBalanceProgress: PropTypes.func.isRequired,
    addFilesToQueue: PropTypes.func.isRequired,
    notifyRejectedFiles: PropTypes.func.isRequired,
    currentUserId: PropTypes.string.isRequired,
    processingQueueCount: PropTypes.number,
    showBulkUpload: PropTypes.bool.isRequired,
    hideBulkUploadModal: PropTypes.func.isRequired,
    client: PropTypes.object
};

export default BulkUploadModal;