import React from "react";
import PropTypes from "prop-types";
import { FormattedNumber } from "react-intl";
import { Button, Icon } from "semantic-ui-react";
import ShortDate from "../shared/ShortDate";
import { MotifIconButton, MotifIcon } from "@ey-xd/motif-react";
import { imageIcEdit24px, actionIcDelete24px } from '@ey-xd/motif-react/assets/icons';

//let NumberFormat = require("react-number-format");

const propertyListRow = ({ property, lookupTypes, handleOpenComponent, handleOpenDeleteProperty }) => {
    let propertyType = property.propertyTypeID;
    // switch (property.propertyTypeID) {
    // case "MixedUse":
    //     propertyType = "Mixed Use";
    //     break;
    // case "NotSelected":
    //     propertyType = "";
    //     break;
    // case "MultiFamily":
    //     propertyType = "Multi-Family";
    //     break;
    // }

    const propertyTypeObject = lookupTypes.propertyTypes.find(pt => pt.propertyTypeID === property.propertyTypeID);
    if(propertyTypeObject != undefined && propertyTypeObject != null){
        propertyType = propertyTypeObject.propertyTypeDescription === "Not Selected" ? "" : propertyTypeObject.propertyTypeDescription;
    }

    const onEditClick = () => {
        handleOpenComponent(property.propertyID);
    };

    const onDeleteClick = () => {
        handleOpenDeleteProperty(property.propertyID);
    };

    return (
        <tr>
            <td>{property.propertyName}</td>
            <td className="tcenter">{propertyType}</td>
            <td className="tcenter"><ShortDate value={property.purchaseDate} /></td>
            <td className="tcenter"><FormattedNumber value={property.percentageOfOwnership * 1000.00 / 100000.00} style="percent" minimumFractionDigits={4} maximumFractionDigits={10} /></td>
            <td className="tcenter" title={"Bulk upload tracking ID for " + property.propertyName}>{property.propertyTrackingID}</td>
            <td className="tcenter">
                <MotifIconButton aria-label="View/Edit this Property" type="button"
                    onClick={onEditClick}>
                    <MotifIcon src={imageIcEdit24px} title="View/Edit this Property" />
                </MotifIconButton>
                <MotifIconButton aria-label="Delete this Property" type="button"
                    onClick={onDeleteClick}>
                    <MotifIcon src={actionIcDelete24px} title="Delete this Property" />
                </MotifIconButton>
            </td>
        </tr>
    );
};

propertyListRow.propTypes = {
    property: PropTypes.object,
    handleOpenComponent: PropTypes.func.isRequired
};

export default propertyListRow;