import React from "react";
import PropTypes from "prop-types";
import { Editor, EditorTools, EditorDialogs, EditorUtils, EditorToolsSettings, ProseMirror } from '@progress/kendo-react-editor';
import {getMentionsPlugin } from '../../scripts/prosemirror-mentions';
import { buildSchema } from './CustomFdrSchema.js';

import { 
    baseKeymap, 
    newlineInCode, 
    createParagraphNear, 
    splitBlockKeepMarks,
    chainCommands,
    liftEmptyBlock
} from "prosemirror-commands";

import { InsertTagTool } from "./InsertTagTool";
import Tags from '../../constants/customFdrTags';
import { CustomFontSizeTool } from './CustomFontSizeTool.js';
import { CustomLineSpacingTool } from './CustomLineSpacingTool.js';

const {
    Bold, Italic, Underline, Strikethrough, Subscript, Superscript,
    ForeColor, BackColor, CleanFormatting,
    AlignLeft, AlignCenter, AlignRight, AlignJustify,
    Indent, Outdent, OrderedList, UnorderedList,
    Undo, Redo, FontSize, FontName, FormatBlock,
    Link, Unlink,
    InsertTable,
    SelectAll, ViewHtml,
    AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter,
    DeleteRow, DeleteColumn, DeleteTable,
    MergeCells, SplitCell, FindAndReplace
} = EditorTools;

const { FindAndReplaceDialog } = EditorDialogs;
const { textHighlight, setHtml, getHtml, pasteCleanup, sanitize, sanitizeClassAttr, sanitizeStyleAttr, removeAttribute } = EditorUtils;
const { Schema, EditorView, EditorState, keymap } = ProseMirror;

const paragraphPlaceHolder = '⠀';

// Check if object is an instance of class Node
const isNode = function(maybeNode) {
    return maybeNode instanceof ProseMirror.Node;
}

// Check if object is an instance of ReplaceStep
const isReplaceStep = function(maybeReplaceStep) {
    return typeof maybeReplaceStep === 'object' &&
        typeof maybeReplaceStep.from === 'number' &&
        typeof maybeReplaceStep.to === 'number';
}

const getLastCursorNode = function (transaction) {
    const cursor = transaction.curSelection.$cursor;

    const _nodes =
        cursor ?
            cursor
                .path
                .filter(isNode)
                .reverse() :
            [];
    
    if (_nodes.length > 0) {
        return _nodes[0];
    }
    else {
        return undefined;
    }
}

const extractTransactionContext = function (transaction) {
    const cursorPosition =
        transaction.curSelection &&
            transaction.curSelection.$cursor &&
            transaction.curSelection.$cursor.pos ?
            transaction.curSelection.$cursor.pos :
            undefined;

    const shouldRemoveParagraphPlaceholder =
        transaction.curSelection.$cursor &&
        transaction.curSelection.$cursor.parentOffset > 1;
    
    const lastNode = getLastCursorNode(transaction);
    let paragraphStartsWithPlaceHolder = false;

    if (lastNode) {
        if (lastNode.content &&
            lastNode.content.content &&
            lastNode.content.content.length > 0 &&
            typeof lastNode.content.content[0].text === 'string' &&
            lastNode.content.content[0].text[0] === paragraphPlaceHolder) {
            paragraphStartsWithPlaceHolder = true;
        }
    }

    const placeholderRemovalPosition =
        shouldRemoveParagraphPlaceholder && paragraphStartsWithPlaceHolder ?
            cursorPosition - 2 : 
            undefined;

    return {
        placeholderRemovalPosition
    };
}

const CustomFontName = (props) => {
    const customFontName = (
        <FontName
            {...props}
            data={[
                { text: 'Calibri', value: 'Calibri' },
                { text: 'Arial', value: 'Arial' },
                { text: 'Courier New', value: 'Courier New' },
                { text: 'Georgia', value: 'Georgia' },
                { text: 'Impact', value: 'Impact' },
                { text: 'Lucida Console', value: 'Lucida Console' },
                { text: 'Tahoma', value: 'Tahoma' },
                { text: 'Times New Roman', value: 'Times New Roman' },
                { text: 'Trebuchet MS', value: 'Trebuchet MS' },
                { text: 'Verdana', value: 'Verdana' }
            ]}
            title="Font Name"
        />
    );

    return customFontName;
};

// https://www.telerik.com/kendo-react-ui/components/inputs/colorpicker/custom/#toc-customizing-the-palette-popup
const paletteSettings = {
    palette: [
        "#FFFFFF", "#000000", "#EEECE1", "#1F497D", "#4F81BD", "#C0504D", "#9BBB59", "#8064A2", "#4BACC6", "#F79646",
        "#F2F2F2", "#808080", "#DDD9C4", "#C5D9F1", "#DCE6F1", "#F2DCDB", "#EBF1DE", "#E4DFEC", "#DAEEF3", "#FDE9D9",
        "#D9D9D9", "#595959", "#C4BD97", "#8DB4E2", "#B8CCE4", "#E6B8B7", "#D8E4BC", "#CCC0DA", "#B7DEE8", "#FCD5B4",
        "#BFBFBF", "#404040", "#948A54", "#538DD5", "#95B3D7", "#DA9694", "#C4D79B", "#B1A0C7", "#92CDDC", "#FABF8F",
        "#A6A6A6", "#262626", "#494529", "#16365C", "#366092", "#963634", "#76933C", "#60497A", "#31869B", "#E26B0A",
        "#808080", "#0D0D0D", "#1D1B10", "#0F243E", "#244062", "#632523", "#4F6228", "#403151", "#215967", "#974706",
        "#C00000", "#FF0000", "#FFC000", "#FFFF00", "#92D050", "#00B050", "#00B0F0", "#0070C0", "#002060", "#7030A0"
    ],
    columns: 10,
    tileSize: 20
};

// https://www.telerik.com/kendo-react-ui/components/inputs/api/ColorPickerProps/
const colorPickerForeProps = {
    // default settings
    ...EditorToolsSettings.foreColor.colorPickerProps,

    // custom settings
    paletteSettings
};

const ForeColorCustom = props => {
    return (
        <ForeColor
            {...props}
            colorPickerProps={colorPickerForeProps}
        />
    );
};

// https://www.telerik.com/kendo-react-ui/components/inputs/api/ColorPickerProps/
const colorPickerBackProps = {
    // default settings
    ...EditorToolsSettings.backColor.colorPickerProps,

    // custom settings
    paletteSettings
};

const BackColorCustom = props => {
    return (
        <BackColor
            {...props}
            colorPickerProps={colorPickerBackProps}
        />
    );
};

//Settings for Copy/Paste bullet points from Word
const pasteSettings = {
    convertMsLists: true,
    attributes: {
        class: sanitizeClassAttr,
        style: sanitizeStyleAttr,
        '*': removeAttribute
    }
};

class CustomFdrTemplateEditor extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            showDialog: false
        };

        this.view = null;
        this.editor = null;

        this.onMount = this.onMount.bind(this);
        this.onFindClose = this.onFindClose.bind(this);
    }

    onFindClose() {
        this.setState({ showDialog: false });
    }

    onMount(event) {
        const thisComponent = this;
        const iframeElement = event.target.iframe;

        // Because we limit width of content, we want to center the editor content section,
        // and give the surrounding area a light gray background with content background white
        iframeElement.parentNode.className += " d-flex justify-content-center bg-light";
        iframeElement.className += " bg-white";

        // Make the default font "Calibri" if unspecified
        const iframeDocument = event.dom.ownerDocument;
        iframeDocument.querySelector('.k-content').setAttribute("style", 'font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;');

        iframeDocument.querySelector('style').appendChild(document.createTextNode(`
            .prosemirror-suggestion { background: rgba(0, 0, 0, 0.05); border: 1px solid #999 }
            .suggestion-item-list { background: #fff; border: 1px solid #999 }
            .suggestion-item { padding: 5px }
            .suggestion-item-active { background: #08c; color: #fff }
            .ProseMirror table, .ProseMirror td, .ProseMirror th {
                border: 1px dashed #ddd !important
            }
        `));

        const state = event.viewProps.state;
        const mySchema = buildSchema(state.schema);

        const onEnterPressed = (state, dispatch, view) => {
            const setTransactionMetadata = function (tr) {
                tr.setMeta('EnterPressed', true);
                dispatch(tr);
            }

            return chainCommands(
                newlineInCode,
                createParagraphNear,
                liftEmptyBlock,
                splitBlockKeepMarks)(
                    state,
                    dispatch && setTransactionMetadata,
                    view);
        }

        baseKeymap.Enter = onEnterPressed

        // Find and Replace Dialog functionality (use CTRL-F to pop it up)
        const plugins = [
            ...state.plugins,

            // add a plugin which will highlight the matched text
            textHighlight(),

            // add a key binding Ctrl+F/Cmd+F which will open the `Find and Replace` Dialog.
            keymap({
                'Mod-f': () => {
                    this.setState({ showDialog: true });
                    return true;
                }
            })
        ];

        const getTagSuggestionsHTML = items => '<div class="suggestion-item-list">' +
            items.map(i => '<div class="suggestion-item">' + i.tag + '</div>').join('') +
            '</div>';

        const mentionPlugin = getMentionsPlugin({
            hashtagTrigger: "\\[",
            maxNoOfSuggestions: 8,
            delay: 150,
            getSuggestions: (type, text, done) => {
                setTimeout(() => {
                    if (type === 'mention') {
                        // Not using mentions
                        //done([{name: 'John Doe', id: '101', email: 'joe@gmail.com'}, {name: 'Joe Lewis', id: '102', email: 'lewis@gmail.com'}])
                    } else {
                        const filter = text ? Tags.filter(x => x.tag.toLowerCase().includes(text.toLowerCase())) : Tags;
                        done(filter);
                    }
                }, 0);
            },
            getSuggestionsHTML: (items, type) => {
                if (type === 'mention') {
                    //return getMentionSuggestionsHTML(items)
                } else if (type === 'tag') {
                    return getTagSuggestionsHTML(items)
                }
            }
        });

        plugins.unshift(mentionPlugin);

        const doc = EditorUtils.createDocument(mySchema, this.props.initialHtml);

        let view = new EditorView(
            { mount: event.dom },
            {
                ...event.viewProps,
                editable: () => !this.props.readOnly, // Optionally make it read-only
                handleDOMEvents: {
                    ...(event.viewProps.handleDOMEvents || {})
                },
                state: EditorState.create({ doc: doc, schema: mySchema, plugins }),
                dispatchTransaction: function (transaction) {
                    if (transaction.getMeta('EnterPressed')) {
                        transaction =
                            transaction.insertText(paragraphPlaceHolder);
                    }

                    const context = extractTransactionContext(transaction);
                    let { placeholderRemovalPosition } = context;

                    if (typeof placeholderRemovalPosition === 'number') {
                        transaction = transaction.replace(
                            placeholderRemovalPosition,
                            placeholderRemovalPosition + 1);
                    }

                    const state = view.state;

                    const marks = state.storedMarks ||
                        (state.selection.$to.parentOffset &&
                            state.selection.$from.marks());

                    const contentIsBeingInserted =
                        !transaction.getMeta('pointer') &&
                        transaction.steps.length &&
                        isReplaceStep(transaction.steps[0]);

                    if (marks && contentIsBeingInserted) {
                        transaction = transaction.ensureMarks(marks);
                    }

                    const newState = view.state.apply(transaction);
                    view.updateState(newState);

                    if (thisComponent.props.onEditorChanged) {
                        setTimeout(() => {
                            thisComponent.props.onEditorChanged();
                        });
                    }
                }
            }
        );

        this.view = view;
        return this.view;
    }

    render() {
        const tools = this.props.readOnly ? [] : [
            [Bold, Italic, Underline, Strikethrough],
            [Subscript, Superscript],
            ForeColorCustom, BackColorCustom, [CleanFormatting],
            [AlignLeft, AlignCenter, AlignRight, AlignJustify],
            [Indent, Outdent],
            [OrderedList, UnorderedList],
            CustomFontSizeTool, CustomFontName, FormatBlock, FindAndReplace,
            [SelectAll],
            [Undo, Redo],
            [Link, Unlink],
            [InsertTable],
            [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
            [DeleteRow, DeleteColumn, DeleteTable],
            [MergeCells, SplitCell], CustomLineSpacingTool,
            (props) => <InsertTagTool {...props} defaultValue="Insert Single Value" />, (props) => <InsertTagTool {...props} defaultValue="Insert Data Table" />
        ];

        return (
            <React.Fragment>
                <Editor
                    className="mt-4 mb-4"
                    style={{ width: '100%' }}
                    tools={tools}
                    defaultEditMode="iframe"
                    contentStyle={{ width: 660, height: 630 }}
                    defaultContent={this.props.initialHtml}
                    onMount={this.onMount}
                    ref={editor => { this.editor = editor; this.props.setEditorReferences(editor, setHtml, getHtml); }}
                    onChange={this.props.onEditorChanged}
                    onPasteHtml={event => pasteCleanup(sanitize(event.pastedHtml), pasteSettings)}
                />
                {this.state.showDialog && this.view &&
                    <FindAndReplaceDialog
                        view={this.view}
                        onClose={this.onFindClose}
                    />
                }
            </React.Fragment>
        );
    }
}

CustomFdrTemplateEditor.propTypes = {
    onEditorChanged: PropTypes.func,
    readOnly: PropTypes.bool,
    setEditorReferences: PropTypes.func,
    initialHtml: PropTypes.string
};

export default CustomFdrTemplateEditor;