import React, { useEffect } from "react";
import {Link} from 'react-router';
import { Form, Icon } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import { MotifButton } from "@ey-xd/motif-react";

const CustomChecklistTemplateHeader = ({
    clientId,
    currentRoute,
    currentUserIsSysAdmin,
    currentUserIsEngAdmin,
    currentUserIsEngagementUser
}) => {    
    return (
        <Form>
            <div className="row mt-2">
                {(currentUserIsSysAdmin || currentUserIsEngAdmin || currentUserIsEngagementUser) && <div className="col">
                    <Link tabIndex="-1" to={`${currentRoute}/template`} rel="noopener noreferrer" >                   
                        <MotifButton
                            variant="primary"
                            className="right ml-2"
                            type="button"
                            Title="Create New Template"
                            data-testId="btnCreateNewTemplate"
                        >
                            Create New Template
                        </MotifButton>
                    </Link>                
                </div>}
            </div>
        </Form>
    );
};

CustomChecklistTemplateHeader.propTypes = {
};

export default reduxForm({ form: "customChecklistTemplateForm" })(CustomChecklistTemplateHeader);