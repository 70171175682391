import React from 'react';
import PropTypes from 'prop-types';
import ReitListRow from './ReitListRow';
import { Button, Message, Icon } from 'semantic-ui-react';
import { MotifFormField, MotifSearch, MotifLabel, MotifToggleSwitch, MotifToast, MotifButton } from '@ey-xd/motif-react';

const ReitList = ({
  clientId,
  client,
  reits,
  getCurrentPeriodFunction,
  handleSearchChange,
  handleClearSearchText,
  reitSearchText,
  handleShowInactive,
  showInactive,
  canInactivateReits,
  handleToggleActiveFlag,
  handleDownloadIdList,
  handleDownloadTbTemplates,
  showSuccessMessage,
  handleDismissSuccessMessage,
  successMessageText,
  chartExistsForClient
}) => {
  let allOrActive = 'Active';
  if (showInactive) {
    allOrActive = 'All';
  }

  const clientIsAudit = client && client.isAudit;

  const GetCurrentQuarter = () => {
    let currentQuarter = 'Q';
    let today = new Date();

    switch (today.getMonth()) {
      case 0:
      case 1:
      case 2:
        currentQuarter += '1';
        break;

      case 3:
      case 4:
      case 5:
        currentQuarter += '2';
        break;

      case 6:
      case 7:
      case 8:
        currentQuarter += '3';
        break;

      case 9:
      case 10:
      case 11:
        currentQuarter += '4';
        break;

      default:
        currentQuarter += '1';
        break;
    }

    currentQuarter += ' ' + (today.getYear() + 1900);

    return currentQuarter;
  };

  const downloadIdList = () => handleDownloadIdList(client.id, showInactive, `ID List for ${client.name}.xlsx`);

  const downloadTbTemplates = () => handleDownloadTbTemplates(client.id, showInactive);

  return (
    <React.Fragment>
      {showSuccessMessage &&
        <MotifToast  className="mt-2 mb-2" variant="success" onClose={handleDismissSuccessMessage}>{successMessageText}</MotifToast>
      }
      <div className="row align-items-center">
        <div className="col-md-4">
          <h1>REITs</h1>
        </div>
        <div className="col-md-5 text-right">
          {canInactivateReits &&
            <React.Fragment>
              <MotifLabel
                htmlFor="switchInactive"
                className="text-right"
                style={{ display: 'inline-block', marginRight: '1rem', width: 'auto' }}>
                Display Inactive REITs:
            </MotifLabel>
            <MotifFormField style={{ display: 'inline-block', width: 'auto' }}>
              <MotifToggleSwitch
                  aria-label="Display Inactive REITs toogle"
                name="switchInactive"
                id="switchInactive"
                onChange={handleShowInactive}
                checked={!!showInactive} />
            </MotifFormField>
            </React.Fragment>
          }
        </div>
        <div className="col-md-3">
          <MotifFormField>
            <MotifLabel htmlFor="reitFilter">Search for REITs</MotifLabel>
            <MotifSearch
              type="text"
              name="reitFilter"
              onChange={handleSearchChange}
              value={reitSearchText}
              title="Type in a REIT name"
              searchButtonProps={{tabIndex:"-1"}}
            />
          </MotifFormField>
        </div>
      </div>
      {!clientIsAudit &&
              <div style={{ width: '100%', textAlign: 'left', marginTop: '15px' }}>
              <span>
                  <MotifButton variant="secondary" type="button"
                      onClick={downloadIdList}
                      disabled={(allOrActive === 'Active' && reits.length === 0)}>
                          Download {allOrActive} Tracking IDs
                   </MotifButton>
              </span>
              <span>
                  <MotifButton variant="secondary" className="ml-2" type="button"
                      onClick={downloadTbTemplates}
                      disabled={!chartExistsForClient}>
                          Download {allOrActive} TB Templates
                  </MotifButton>
              </span>
        </div>}

      <section className="row">
        <div className="col">
          <table className="table rtable mt-2">
            <thead>
              <tr>
                <th>REIT NAME</th>
                <th>CURRENT PERIOD</th>
                <th>STATUS</th>
                {(showInactive && <th>ACTIVE</th>) || null}
                <th>TRACKING ID</th>
              </tr>
            </thead>
            <tbody>
              {reits.map(reit => (
                <ReitListRow
                  key={reit.reitid}
                  clientId={clientId}
                  reit={reit}
                  getCurrentPeriodFunction={getCurrentPeriodFunction}
                  showInactive={showInactive}
                  handleToggleActiveFlag={handleToggleActiveFlag}
                />
              ))}
            </tbody>
          </table>
          {(reits && reits.length === 0 && reitSearchText && (
            <div>
              <h4>No REITs match your search text '{reitSearchText}'</h4>
            </div>
          )) ||
            null}
        </div>
      </section>
    </React.Fragment>
  );
};

ReitList.propTypes = {
  clientId: PropTypes.number.isRequired,
  client: PropTypes.object.isRequired,
  reits: PropTypes.array.isRequired,
  getCurrentPeriodFunction: PropTypes.func.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  handleClearSearchText: PropTypes.func.isRequired,
  reitSearchText: PropTypes.string,
  handleShowInactive: PropTypes.func.isRequired,
  showInactive: PropTypes.bool,
  canInactivateReits: PropTypes.bool,
  handleToggleActiveFlag: PropTypes.func.isRequired,
  handleDownloadIdList: PropTypes.func.isRequired,
  handleDownloadTbTemplates: PropTypes.func.isRequired,
  handleDismissSuccessMessage: PropTypes.func,
  showSuccessMessage: PropTypes.bool,
  successMessageText: PropTypes.string,
};

export default ReitList;
