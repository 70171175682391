import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from "semantic-ui-react";
import FormattedCurrency from "../shared/FormattedCurrency";
import { MotifIconButton, MotifIcon } from "@ey-xd/motif-react";
import { imageIcEdit24px, actionIcDelete24px } from '@ey-xd/motif-react/assets/icons';

const EditTrsRow = ({ trs, showForm, handleEditButtonClick, handleDeleteButtonClick, canEdit }) => {
    const onEditClick = () => {
        handleEditButtonClick(trs);
    };

    const onDeleteClick = () => {
        handleDeleteButtonClick(trs);
    };

    return (
        <tr>
            <td>{trs.trsName}</td>
            <td><FormattedCurrency value={trs.trsValue} /></td>
            <td><FormattedCurrency value={trs.trsDebt} /></td>
            <td><FormattedCurrency value={trs.trsEquity} /></td>
            <td className="tright">
                {canEdit &&
                    (<span>
                    <MotifIconButton aria-label="Edit this TRS" type="button"
                            onClick={onEditClick}>
                        <MotifIcon src={imageIcEdit24px} title="Edit this TRS" />
                        </MotifIconButton>
                    <MotifIconButton aria-label="Delete this TRS" type="button"
                            onClick={onDeleteClick}>
                        <MotifIcon src={actionIcDelete24px} title="Delete this TRS" />
                        </MotifIconButton>
                    </span>)
                    || (<span>
                        <MotifIconButton aria-label="Can't edit TRS - Testing Closed" type="button" disabled>                        
                            <MotifIcon src={imageIcEdit24px} aria-label="Can't edit TRS - Testing Closed" />
                        </MotifIconButton>
                        <MotifIconButton aria-label="Can't delete TRS - Testing Closed" type="button" disabled>                        
                            <MotifIcon src={actionIcDelete24px} aria-label="Can't delete TRS - Testing Closed" />
                        </MotifIconButton>
                    </span>)}
            </td>
        </tr>
    );
};

EditTrsRow.propTypes = {
    trs: PropTypes.shape({
        taxableREITSubsidiaryID: PropTypes.number,
        reportPeriodID: PropTypes.number,
        trsName: PropTypes.string,
        trsValue: PropTypes.number,
        trsDebt: PropTypes.number,
        trsEquity: PropTypes.number
    }),
    showForm: PropTypes.bool,
    handleEditButtonClick: PropTypes.func.isRequired,
    handleDeleteButtonClick: PropTypes.func.isRequired,
    canEdit: PropTypes.bool
};

export default EditTrsRow;