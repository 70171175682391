import React from 'react';
import PropTypes from 'prop-types';
import FileListItem from './FileListItem';

const FileList = ({ files, onDownload, onDelete, canUpload, emptyMessage }) => {
    return (
        <table>
            <thead>
                <tr>
                    <th className="col-md-8">File Name</th>
                    <th className="col-md-2">Date Uploaded</th>
                    <th className="col-md-2">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                {Array.isArray(files) && files.map(item => <FileListItem key={item.fileName} item={item} onDownload={onDownload} onDelete={onDelete} canUpload={canUpload} />)}
                {files && files.length < 1 && 
                    <tr>
                        <td>{emptyMessage}</td>
                        <td />
                        <td />
                    </tr>}
            </tbody>
        </table>
    );
};

FileList.propTypes = {
    files: PropTypes.array,
    onDownload: PropTypes.func,
    onDelete: PropTypes.func,
    canUpload: PropTypes.bool,
    emptyMessage: PropTypes.string
};

export default FileList;