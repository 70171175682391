import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from "semantic-ui-react";
import { MotifIconButton, MotifIcon } from "@ey-xd/motif-react";
import { imageIcEdit24px, actionIcDelete24px } from '@ey-xd/motif-react/assets/icons';

const CustomQuestionListRow = ({ customQuestionListRow, handleEditCustomQuestionListRow, toggleShowDeleteCustomQuestionModal }) => {
    let answerChoiceText = ""
    switch (customQuestionListRow.answerChoice) {
        case 0:
            answerChoiceText = "None";
            break;
        case 1:
            answerChoiceText = "Free text";
            break;
        case 2:
            answerChoiceText = "Yes, No, N/A";
            break;
    }

    return (
        <tr>
            <td>{customQuestionListRow.defaultUserDefinedQuestionID}</td>
            <td>{customQuestionListRow.questionText}</td>
            <td>{customQuestionListRow.helpText}</td>
            <td>{answerChoiceText}</td>
            <td className="text-nowrap tcenter" style={{ width: '8%' }}>                
                <MotifIconButton aria-label="Edit this Question" type="button"
                    onClick={() => handleEditCustomQuestionListRow(customQuestionListRow)}>
                    <MotifIcon src={imageIcEdit24px} title="Edit this Question" />
                </MotifIconButton>
                <MotifIconButton aria-label="Delete this Question" type="button"
                    onClick={() => toggleShowDeleteCustomQuestionModal(customQuestionListRow)}>
                    <MotifIcon src={actionIcDelete24px} title="Delete this Question" />
                </MotifIconButton>
            </td>
        </tr>
    );
};

CustomQuestionListRow.propTypes = {
    customQuestionListRow: PropTypes.object.isRequired
};

export default CustomQuestionListRow;