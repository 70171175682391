import React from "react";
import PropTypes from "prop-types";
import ReactModal from 'react-modal';
import { Header, Input, Button } from 'semantic-ui-react';
import { connect } from "react-redux";
import { getFormValues, getFormSyncErrors, getFormSubmitErrors, SubmissionError, reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import * as clientActions from "../../actions/clientActions";
import * as checklistActions from "../../actions/checklistActions";
import * as reitActions from "../../actions/reitActions";
import * as authActions from "../../actions/authActions";
import * as formatters from "../../scripts/formFormatters";
import * as formValidators from "../../scripts/formValidators";
import ScheduleChecklistHeader from "../../components/checklistDeliveryCalendar/ScheduleChecklistHeader";
import ScheduleChecklistTable from "../../components/checklistDeliveryCalendar/ScheduleChecklistTable";
import PSQCompletionRequirement from "../../components/checklistDeliveryCalendar/PSQCompletionRequirement";
import ChecklistDeliveryScheduleUploadModal from "../../components/checklistDeliveryCalendar/ChecklistDeliveryScheduleUploadModal";
import { MotifTabNavigation, MotifTabControl} from "@ey-xd/motif-react";
import { Link } from 'react-router';

/**
 * Schedule Checklist Container component
 * @extends {React.Component}
 */
class ScheduleChecklistContainer extends React.Component {
    /**
    * Creates a new ChartOfAccountsPage
    * @constructor
    * @param {Object} props The component properties
    * @param {Object} context The component context
    */
    constructor(props, context) {
        super(props, context);

        this.setTabBar1 = this.setTabBar1.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.setGridColumnState = this.setGridColumnState.bind(this);
        this.resetGridColumnState = this.resetGridColumnState.bind(this);
        this.handleGridColumnStateChange = this.handleGridColumnStateChange.bind(this); 
        this.handleGridChanges = this.handleGridChanges.bind(this);
        this.handleDownloadClick = this.handleDownloadClick.bind(this); 
        this.handleCalendarYearChange = this.handleCalendarYearChange.bind(this);
        this.toggleShowUploadChecklistDeliveryScheduleModal = this.toggleShowUploadChecklistDeliveryScheduleModal.bind(this);
        this.refreshScheduleChecklists = this.refreshScheduleChecklists.bind(this);        
        this.handlePSQCompletionRequirementChange = this.handlePSQCompletionRequirementChange.bind(this);

        this.state = {
            filteredScheduleChecklists: [],
            gridColumnState: [],
            showUploadChecklistDeliveryScheduleModal: false,            
        };

        const now = new Date();
        const currentYear = now.getFullYear();
        // Show default calendar year as first record
        if (currentYear == getDefaultCalendarYear()) {
            // From March 15th, 2022 to December 31st, 2022 the order of the calendar year is 2022, 2021
            this.calendarYears = [currentYear, currentYear - 1];
        }
        else
        {
            // From January 1st, 2022 to March 14th, 2022, the order of the calendar year is 2021, 2022
            this.calendarYears = [currentYear - 1, currentYear];
        }
        
    }

    /**
     * Invoked immediately after mounting occurs
     * @returns {undefined}
     */
    componentDidMount() {
        this.setGridColumnState();       
    }

    /**
     * Invoked immediately before unmounting occurs
     * @returns {undefined}
     */
    componentWillUnmount() {
        this.props.checklistActions.fetchChecklistDeliverySchedule();
    }

    /**
     * Component Will Receive Props   
     */
    componentWillReceiveProps(nextProps) {
        if ((nextProps.scheduleChecklistValues
            && nextProps.scheduleChecklistValues.calendarYear)
            && (nextProps.scheduleChecklistValues.calendarYear && !this.props.scheduleChecklistValues) ||
            (this.props.scheduleChecklistValues && (nextProps.scheduleChecklistValues.calendarYear !== this.props.scheduleChecklistValues.calendarYear))
        ) {            
            this.props.checklistActions
                .fetchChecklistDeliverySchedule(nextProps.clientId, nextProps.scheduleChecklistValues.calendarYear)
                .then(() => {
                    this.setState({ filteredScheduleChecklists: this.props.scheduleChecklists });
                });
        }
    }
        
    /**
     *  Set tab related state variables
     * @returns {undefined}
     */
    setTabBar1(currentIndex) {
        this.setState({ tabBar1: currentIndex });
    }

    /**
     * Set current tab index based on index 
     * @returns {undefined}
     */  
    handleClick(index) {
        this.setTabBar1(index);
    }   
    
    /**
     * Handle Grid changes
     * @returns {undefined}
     */
    handleGridChanges(gridRowData) {
        let rowData = [];
        rowData = gridRowData && gridRowData.length && gridRowData.map(x => {
            return {
                reitName: x.reitName,
                propertyName: x.propertyName,
                checklistType: x.checklistType,
                templateName: x.templateName,
                prePopulateAnswerOptionDescription: x.prePopulateAnswerOptionDescription,
                clientRecipients: x.clientRecipients,
                sendDateQ1: x.sendDateQ1,
                sendDateQ2: x.sendDateQ2,
                sendDateQ3: x.sendDateQ3,
                sendDateQ4: x.sendDateQ4
            }
        });

        this.setState({ gridCurrentState: rowData });
    }

   /**
   * Refresh Schedule Checklists
   * @returns {undefined}
   */
    refreshScheduleChecklists(clientId, calendarYear) {
        this.props.checklistActions.fetchChecklistDeliverySchedule(clientId, calendarYear)
            .then(() => {
                this.setState({ filteredScheduleChecklists: this.props.scheduleChecklists });
            });
    }

    /**
   * Set AG-Grid column state   
   * @returns {undefined}
   */
    setGridColumnState() {
        var columnState = localStorage.getItem('REITSuiteScheduleChecklistColumnState');
        if (columnState) {
            this.setState({ gridColumnState: JSON.parse(columnState) });
        }
        else {
            this.setState({ gridColumnState: [{ colId: '-1' }] });
        }
    }

    /**
    * Handle Grid column state chagne
    * @returns {undefined}
    */
    handleGridColumnStateChange(columnState) {
        this.setState({ gridColumnState: columnState });
    }

    /**
    * Handle Grid column state chagne
    * @returns {undefined}
    */
    handleCalendarYearChange(calendarYear) {
        this.props.checklistActions.fetchChecklistDeliverySchedule(
          this.props.clientId,
          calendarYear
        );
    }

    /**
    * Reset AG-Grid column state
    * @returns {undefined}
    */
    resetGridColumnState() {
        localStorage.removeItem('REITSuiteScheduleChecklistColumnState');
        this.setState({ gridColumnState: [] });
    }    

    handleDownloadClick()
    {
        this.props.checklistActions
        .exportChecklistDeliverySchedule(this.props.clientId,
            this.props.scheduleChecklistValues.calendarYear);
    }

    /**
    * Toggle upload Checklist Delivery Schedule modal
    * @returns {undefined}
    */
    toggleShowUploadChecklistDeliveryScheduleModal() {
        this.setState({
            showUploadChecklistDeliveryScheduleModal: !this.state.showUploadChecklistDeliveryScheduleModal
        });
    }

    /**
    * Update psq completion requirement status
    * @returns {undefined}
    */
    handlePSQCompletionRequirementChange(e) {
        if (e && e.target) {        
            this.props.clientActions
                .updatePSQCompletionRequirementStatus(this.props.clientId, e.target.checked)
                .then((response) => {
                    if (response && response.success) { 
                        this.props.clientActions.loadClient(this.props.clientId);
                   }
                });  
        }        
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() { 
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col">
                        <h1 className="pageheader">Checklist Management</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-9" >
                        <MotifTabNavigation variant="default">
                            <Link
                                to={`/client/${this.props.clientId}/checklistManagement`}
                                tabIndex="-1">
                                <MotifTabControl
                                    className="motif-active"
                                    autoFocus
                                    role="tab"
                                    data-testid="btnScheduleChecklist"
                                    onClick={() => this.handleClick(1)}>
                                    Schedule Checklist 
                                </MotifTabControl>
                            </Link>
                            <Link
                                to={`/client/${this.props.clientId}/manageChecklist`}
                                tabIndex="-1">
                                <MotifTabControl
                                    role="tab"
                                    data-testid="btnManageChecklist"
                                    onClick={() => this.handleClick(2)}>
                                    Manage Checklist
                                </MotifTabControl>
                            </Link>
                        </MotifTabNavigation>
                    </div>
                    <div className="col-md-3" style={{ borderBottom: '1px solid #c4c4cd', zIndex: 10, marginLeft:'-15px' }}>                        
                    {(authActions.isSystemAdministrator(this.props.currentUserAuthorizations) ||
                        authActions.isEngagementAdministrator(this.props.currentUserAuthorizations,this.props.clientId)) &&
                            <PSQCompletionRequirement
                                isPSQChecklistCompletionRequirementEnabled={this.props && this.props.client && this.props.client.isPsqCompletionRequirementEnabled || false }                            
                                handlePSQCompletionRequirementChange={this.handlePSQCompletionRequirementChange}
                            />                       
                    }
                    </div>
                </div>
                
                
                <ScheduleChecklistHeader
                    calendarYears={this.calendarYears}
                    isSystemOrEngagementAdmin={
                        authActions.isSystemAdministrator(
                            this.props.currentUserAuthorizations
                        ) ||
                        authActions.isEngagementAdministrator(
                            this.props.currentUserAuthorizations,
                            this.props.clientId
                        )

                    }
                    isEngagementUser={
                        authActions.isUser(this.props.currentUserAuthorizations, this.props.clientId)
                    }
                    handleDownloadClick={this.handleDownloadClick}
                    handleCalendarYearChange={this.handleCalendarYearChange}
                    toggleShowUploadChecklistDeliveryScheduleModal={this.toggleShowUploadChecklistDeliveryScheduleModal}
                />
                { (this.props.scheduleChecklists && this.props.scheduleChecklists.length === 0)
                    && <div><div style={{ minHeight: '20px' }}></div><div>No REIT or Property found for this client.</div></div>
                   }
                <ScheduleChecklistTable
                    formSyncErrors={this.props.formSyncErrors}
                    filteredScheduleChecklists={this.state.filteredScheduleChecklists}
                    gridColumnState={this.state.gridColumnState}
                    handleGridColumnStateChange={this.handleGridColumnStateChange}
                    clientId={this.props.clientId}
                    handleGridChanges={this.handleGridChanges}
                    isSystemOrEngagementAdmin={
                        authActions.isSystemAdministrator(
                            this.props.currentUserAuthorizations
                        ) ||
                        authActions.isEngagementAdministrator(
                            this.props.currentUserAuthorizations,
                            this.props.clientId
                        )
                    }
                />
                <ChecklistDeliveryScheduleUploadModal
                    showUploadChecklistDeliveryScheduleModal={this.state.showUploadChecklistDeliveryScheduleModal}
                    toggleShowUploadChecklistDeliveryScheduleModal={this.toggleShowUploadChecklistDeliveryScheduleModal}
                    scheduleChecklistValues={this.props.scheduleChecklistValues}
                    refreshScheduleChecklists={this.refreshScheduleChecklists}
                    clientId={this.props.clientId}
                />
            </React.Fragment>
        );
    }
}

/**
* Get Calendar year to be selected in DropDown by default
* @returns {selectedYear}
*/
function getDefaultCalendarYear() {
    // Cutoff Date is 15th March
    // from January 1st - March 14th, of every year, selected year is the previous calendar year
    // from March 15th - December 31st, of every year, selected year  is the current calendar year

    var defaultCutOffDate = "03-15";
    // Get cutoff dat from env variable
    var envCutOffDate = process.env.SCHEDULE_CHECKLIST_CALENDAR_YEAR_CUTOFF_DATE ?
                        process.env.SCHEDULE_CHECKLIST_CALENDAR_YEAR_CUTOFF_DATE : defaultCutOffDate;
    if (!envCutOffDate || envCutOffDate.split('-').length <= 1) {
        envCutOffDate = defaultCutOffDate;
    }
    var cutoffDateParts = envCutOffDate.split('-');

    let cutOffDate = new Date(parseInt(new Date().getFullYear()), parseInt(cutoffDateParts[0]) - 1, parseInt(cutoffDateParts[1]));    
    cutOffDate.setHours(0, 0, 0, 0);
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    let selectedYear = currentDate >= cutOffDate
        ? parseInt(new Date().getFullYear())
        : parseInt(new Date().getFullYear()) - 1;

    return selectedYear;
}
/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    let cid = Number.parseInt(ownProps.params.clientId);
    if (isNaN(cid)) {
        cid = 0;
    }
    
    return {
        clientId: cid,
        clientIsAudit: state.client && state.client.isAudit,   
        scheduleChecklistValues: getFormValues('scheduleChecklist')(state),
        currentUserAuthorizations: state.currentUserAuthorizations,
        initialValues: {
            calendarYear: getDefaultCalendarYear()
        },
        formSyncErrors: getFormSyncErrors('scheduleChecklist')(state),
        formSubmitErrors: getFormSubmitErrors('scheduleChecklist')(state),
        scheduleChecklists: state.checklistDeliverySchedules,
        client: state.client,
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        clientActions: bindActionCreators(clientActions, dispatch),
        checklistActions: bindActionCreators(checklistActions, dispatch),
        reitActions: bindActionCreators(reitActions, dispatch),
    };
}

ScheduleChecklistContainer.propTypes = {
    clientId: PropTypes.number,
    clientIsAudit: PropTypes.bool,
    scheduleChecklistValues: PropTypes.object,
    clientActions: PropTypes.object,
    checklistActions: PropTypes.object,
    currentUserAuthorizations: PropTypes.array,
    scheduleChecklists: PropTypes.array,
    initialize: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: "scheduleChecklist", enableReinitialize: true })(ScheduleChecklistContainer));