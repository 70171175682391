import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Header, Label, Button, Input, Dropdown } from "semantic-ui-react";
import ReactModal from "react-modal";
import { Field, reduxForm, getFormValues } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import * as formValidators from "../../scripts/formValidators";
import { connect } from "react-redux";
import {
    MotifButton, MotifFormField, MotifInput, MotifLabel, MotifSelect, MotifOption, MotifModal, MotifIcon,
    MotifModalBody, MotifModalFooter, MotifModalHeader} from "@ey-xd/motif-react";
import { addAttribute } from "../../common/Attribute";

const ApproveClientReopenRequestModal = ({ showApproveClientReopenRequestModal,
    handleSubmit, handleApproveClientReopenRequest, toggleShowApproveClientReopenRequestModal, gridCurrentState }) => {
  
    const HeaderWrapper = ({ children }) => (
        <div
            style={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'center'
            }}>
            {children}
        </div>
    );

    const closeButtonProps = {
        title: 'Close Approve Client Reopen Request modal',
        ariaLabel: 'Close Approve Client Reopen Request modal',
        dataTestid: 'close'
    };

    useEffect(() => {

        //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
        addAttribute("button[role = 'combobox']", "type", "button");
    });

    return (
        <MotifModal variant="alt" show={showApproveClientReopenRequestModal} onClose={() => toggleShowApproveClientReopenRequestModal()} size="xl" data-testid="ApproveClientReopenRequests">
            <MotifModalHeader style={{ display: "-webkit-box" }} closeButtonProps={closeButtonProps}>
                <HeaderWrapper>
                    <span className="ml-2">Approve Client Reopen Request(s)</span>
                </HeaderWrapper>
            </MotifModalHeader>

            <MotifModalBody>
                <form onSubmit={handleSubmit} className="auth">
                    <span className="checklistActionMsg">
                        You selected {gridCurrentState && new Set(gridCurrentState.filter(record => record.checklistSelection)
                            .map(record => record.checklistID + '_' + record.checklistType)).size} checklist(s).
                    </span>
                    <br />               
                </form>
            </MotifModalBody>
            
            <MotifModalFooter>
                <MotifButton type="button" onClick={() => handleApproveClientReopenRequest()} className="right" data-testid="Submit" >
                    Submit
                </MotifButton>
                <MotifButton
                    variant="secondary"
                    type="button"
                    onClick={() => toggleShowApproveClientReopenRequestModal()} className="right" data-testid="Cancel">
                    Cancel
                </MotifButton>
            </MotifModalFooter>
        </MotifModal>
    );
};

ApproveClientReopenRequestModal.propTypes = {
    showApproveClientReopenRequestModal: PropTypes.bool,
    handleSubmit: PropTypes.func,
    toggleShowApproveClientReopenRequestModal: PropTypes.func,
    initialValues: PropTypes.object
};

export default reduxForm({ form: "ApproveClientReopenRequest", enableReinitialize: true })(ApproveClientReopenRequestModal);