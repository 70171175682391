import React from "react";
import PropTypes from "prop-types";
import ClientListRowSlim from "./ClientListRowSlim";

const ClientListSlim = ({ clients, clientsAreLoaded }) => {
    return (
        <React.Fragment>
            <h1>Clients</h1>
            <div className="row">
                <div className="col">
                    <section className="table-responsive rtable">
                        <table>
                            <thead>
                                <tr>
                                    <th>Clients</th>
                                </tr>
                            </thead>
                            <tbody>
                                {clients.map(client => <ClientListRowSlim key={client.id} client={client} />)}
                            </tbody>
                        </table>
                        {(clients && clients.length === 0 && clientsAreLoaded && <div><h4>It looks as though you aren't assigned to any clients right now - please reach out to your EY contact.</h4></div>)
                            || null}
                    </section>
                </div>
            </div>
        </React.Fragment>
    );
};

ClientListSlim.propTypes = {
    clients: PropTypes.array.isRequired,
    clientsAreLoaded: PropTypes.bool
};

export default ClientListSlim;