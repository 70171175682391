import React, { useState, useEffect } from "react";
import { Link } from "react-router";
import PropTypes from "prop-types";
import MappingsListRow from "../../components/tb/MappingsListRow";
import AccountMappingsUploadModal from "../../components/tb/AccountMappingsUploadModal";
import MappingForm from "../../components/tb/MappingForm";
import ReactModal from "react-modal";
import FormattedPercent from "../shared/FormattedPercent";
import { fetchFromTrialBalanceApi } from '../../actions/fetchFromApi';
import { download, decodeURIComponentSafe } from '../../scripts/fileHelpers';
import { MotifIcon, MotifButton, MotifDropdown, MotifDropdownItem, MotifSelect, MotifOption, MotifLabel, MotifFormField } from '@ey-xd/motif-react';
import { navigationIcArrowDropDown24px } from '@ey-xd/motif-react/assets/icons';
import SyncREITTestingAttributesModal from "../chartOfAccountsManager/SyncREITTestingAttributesModal";
import { addAttribute } from "../../common/Attribute";

const MappingsList = ({ period, properties, property, formMapping, showForm, handleCloseForm, handleSubmit, formPurpose, handleEditButtonClick,
    showSuccess, canEdit, handleDismissErrorMessage, handleDismissSuccessMessage, hideSuccessMessage, showConfirmation, tbMappings, selectedTbName,
    trialBalancePurposes, handleTrialBalancePurposeDropdownSelected, handleDownloadAll, handleDeleteAll, confirmAction, handleCancelDelete, handleConfirmDelete,
    submittingMapping, tbMode, selectedTbPctOwnership, loading, chartName, selectedTbId, periodId, reitId, clientId, lookupTypes, showSyncREITTestingAttributesModal, toggleShowSyncREITTestingAttributesModal, handleSyncREITTestsingAttributeAction, syncREITTestingAttributesFormValues, syncInitialValue }) => {

    useEffect(() => {

        //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
        addAttribute("button[role = 'combobox']", "type", "button");
    });

    /**
    * Download Client Account Mappings template or zip package based on mapping type.
    * @param {string} mappingType Mapping type   
    * If mappingType is WITHOUT_PACKAGE then download template based on Report period Id, REIT Id and Property Id *
    * If mappingType is WITH_PACKAGE then download zip package based on Report period Id *
    */
    function downloadClientAccountMappings(mappingType) {

        if (mappingType === 'WITHOUT_PACKAGE') {
            let reitIdForTemplate = selectedTbId < 1 ? reitId : null;
            let propertyIdForTemplate = selectedTbId < 1 ? null : selectedTbId;

            fetchFromTrialBalanceApi(`/chartAssignment/downloadCAM?reportPeriodId=${periodId}&reitId=${reitIdForTemplate}&propertyId=${propertyIdForTemplate}`)
                .then(function (response) {
                    return response.blob().then(function (blob) {
                        const contentDisposition =
                            response.headers.get("Content-Disposition");

                        const filename =
                            contentDisposition.split(';')[1].split('=')[1].trim();

                        download(blob, filename);
                    });
                })
                .catch(e => console.error(e));
        }
        else if (mappingType === 'WITH_PACKAGE') {
           
            fetchFromTrialBalanceApi(`/chartAssignment/downloadCAMPackage?reportPeriodId=${periodId}`)
                .then(function (response) {
                    return response.blob().then(function (blob) {                       
                        const contentDisposition =
                            response.headers.get("Content-Disposition");

                        const filename =
                            contentDisposition.split(';')[1].split('=')[1].trim();
                        download(blob, decodeURIComponentSafe(filename, 1));
                    });
                })
                .catch(e => console.error(e));
        }
    }

    const [showUploadAccountMappingsModal, setShowUploadAccountMappingsModal] = useState(false);
    const [chartOptionsOpen, setChartOptionsOpen] = useState(false);

    const toggleShowUploadAccountMappingsModal = () =>{
        setShowUploadAccountMappingsModal(!showUploadAccountMappingsModal);
    }
    
    const mappingModalStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
            height: 'auto', // <-- This sets the height
            overflow: 'visible' // <-- This tells the modal to scroll
        }
    };

    const confirmModalStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '40%',
            height: 'auto'
        }
    };

    const loadingModalStyle = {
        content: {
            backgroundColor: 'transparent',
            border: 'none',
            color: 'white',
            textAlign: 'center',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '60%',
            overlfow: 'none' // <-- This tells the modal to scrol
        }
    };
    let submittingMessage = " ...loading...please wait...";

    let createChartLink = `/client/${clientId}/chartOfAccountsManager`;

    return (
        <React.Fragment>
            <div className="row">
                <div className="col d-flex">
                    <h1 className="flex-grow-1">Client Account Mappings {canEdit ? "" : "- Testing Closed"} </h1>
                    
                    {/* <div className="pr-2">
                        <button
                            className="btn btn-secondary right"
                            onClick={handleDownloadAll}
                            title={"Download all mappings for this REIT"} >Download All Mappings</button>
                    </div>
                    <div className="pr-2">
                        <button
                            className="btn btn-secondary right"
                            onClick={handleDeleteAll}
                            disabled={!canEdit}
                            title={"Delete all mappings for this REIT"} >Delete All Mappings</button>
                    </div> */}                   

                    <div className="pr-2 align-self-center">                       
                        <MotifDropdown
                            open={chartOptionsOpen}
                            placement="bottom-right"
                            id={'chart-dropdown'}
                            aria-labelledby={'client-management-trigger'}
                            handleClickOutside={() => setChartOptionsOpen(false)}
                            trigger={
                                <MotifButton
                                    disabled={chartName === ''}
                                    type="button"
                                    variant="primary-alt"
                                    onClick={() => setChartOptionsOpen(!chartOptionsOpen)}
                                    aria-controls={'chart-dropdown'}
                                    id={'chart-dropdown-trigger'}
                                    aria-expanded={chartOptionsOpen}
                                    aria-label="Download chart dropdown"
                                >
                                    Download Chart <MotifIcon src={navigationIcArrowDropDown24px} />
                                </MotifButton>
                            }
                        >
                            <MotifDropdownItem onClick={() => {setChartOptionsOpen(false); downloadClientAccountMappings('WITHOUT_PACKAGE');}}>
                                Download Selected Mapping Template Only
                            </MotifDropdownItem>
                            <MotifDropdownItem onClick={() => { setChartOptionsOpen(false); downloadClientAccountMappings('WITH_PACKAGE');}}>
                                Download Mapping Template Package
                            </MotifDropdownItem>
                        </MotifDropdown>
                    </div>
                    <div className="pr-2 align-self-center">
                        <MotifButton variant="secondary" className="right"
                            type="button"
                            onClick={toggleShowSyncREITTestingAttributesModal}
                            disabled={(!tbMappings || tbMappings.length === 0 || !canEdit)}>
                            Restore Default REIT Testing Attributes
                        </MotifButton>
                    </div>
                    <div className="pr-2 align-self-center">
                        <MotifButton
                            variant="primary" className="right" type="button"
                            onClick={toggleShowUploadAccountMappingsModal}
                            disabled={(!tbMappings || tbMappings.length === 0 || !canEdit)}>
                            Upload Mappings
                        </MotifButton>
                    </div>
                </div>
            </div>

            {
            (chartName !== null && chartName !== '') &&
            <div className="row">                
                <div className="col-md-9">                    
                    <b>Current Chart:</b> {chartName}                    
                </div> 
            </div>
            }
            
                      
            <div className="row d-flex align-items-center" style={{ marginTop: '15px' }}>
                <div className="col-md-2">
                    {tbMode !== 2 && 
                        <MotifFormField>
                            <MotifLabel>REIT/Property</MotifLabel>
                            <MotifSelect
                                role="dropdown"
                                aria-label="Select Reit Property account mappings"
                                value="0"
                                name="motif-select-name"
                                onChange={handleTrialBalancePurposeDropdownSelected}
                            >
                                {trialBalancePurposes()}
                            </MotifSelect>
                        </MotifFormField>
                        || <em>Consolidated TB Mode</em>}
                </div>
                <div className="col-md-6 mb-2">
                    Mappings for {selectedTbName}
                </div>
                
            </div>
            <section className="table-responsive rtable">
                {loading && <h5>Loading...</h5> ||
                    <React.Fragment>
                        {(!tbMappings || tbMappings.length === 0) && <h5>No mappings found. <Link to={createChartLink}>Create a new chart or update your existing chart.</Link></h5> ||
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{width: '10vw'}}>Client Account Number</th>
                                        <th style={{ width: '10vw' }}>Client Account Name</th>
                                        <th style={{ width: '5vw' }}>Category</th>
                                        <th style={{ width: '65vw' }}>REIT Testing Attribute Description</th>
                                        <th style={{ width: '15vw' }}>
                                            <div>Balance
                                                        {selectedTbId >= 1 && <span>&nbsp;(
                                                        <FormattedPercent percentage={selectedTbPctOwnership} minimumFractionDigits={4} maximumFractionDigits={10} />
                                                        ) </span>}
                                            </div>
                                        </th>  
                                        <th style={{ width: '5vw' }}>Date Modified</th>
                                        <th style={{ width: '5vw' }} />
                                    </tr>
                                </thead>
                                <tbody>
                                    {tbMappings.map(
                                        mapping => <MappingsListRow
                                            key={mapping.clientAccountMappingID}
                                            mapping={mapping} handleEditButtonClick={handleEditButtonClick} tbMode={tbMode} canEdit={canEdit} />)}
                                </tbody>
                            </table>
                        }
                    </React.Fragment>
                }
            </section>
            <AccountMappingsUploadModal
                showUploadAccountMappingsModal={showUploadAccountMappingsModal}
                toggleShowUploadAccountMappingsModal={toggleShowUploadAccountMappingsModal}
                //chartOfAccountsFormValues={this.props.chartOfAccountsFormValues}
                //handleChartSelection={this.handleChartSelection}
                clientId={clientId}
                periodId={period.reportPeriodID}
            />
            <ReactModal isOpen={confirmAction !== null} contentLabel="" onRequestClose={handleCancelDelete} style={confirmModalStyle} overlayClassName="react-modal-overlay">
                <div>
                    <h2>Delete All Mappings</h2>
                    <p>This action will delete all mappings and balances for this period only. You will need to start this period over by uploading a new set of mappings or a trial balance with mappings.</p>
                    <h3>Continue?</h3>
                    <div style={{ marginTop: '20px' }}>                       
                        <MotifButton variant="primary" className="right ml-2" type="button"
                            onClick={handleConfirmDelete}>
                            Yes
                        </MotifButton>
                        <MotifButton variant="secondary" className="right" type="button"
                            onClick={handleCancelDelete}>
                            No
                        </MotifButton>

                    </div>
                </div>
            </ReactModal>
            <ReactModal isOpen={showForm && !submittingMapping} onRequestClose={handleCloseForm} contentLabel="Edit Mapping" style={mappingModalStyle} overlayClassName="react-modal-overlay">
                <MappingForm onSubmit={handleSubmit} handleCloseForm={handleCloseForm} initialValues={formMapping} formPurpose={formPurpose} handleDismissErrorMessage={handleDismissErrorMessage} tbMode={tbMode} lookupTypes={lookupTypes}/>
            </ReactModal>
            <ReactModal isOpen={submittingMapping} contentLabel="" style={loadingModalStyle} overlayClassName="react-modal-overlay">
                <img src="/images/loading-spinner-black.gif" height="20px" /> {submittingMessage}
            </ReactModal>
            <SyncREITTestingAttributesModal
                showSyncREITTestingAttributesModal={showSyncREITTestingAttributesModal}
                onSubmit={handleSyncREITTestsingAttributeAction}
                toggleShowSyncREITTestingAttributesModal={toggleShowSyncREITTestingAttributesModal}
                syncREITTestingAttributesFormValues={syncREITTestingAttributesFormValues}
                initialValues={syncInitialValue}
                selectedTbName={selectedTbName ? selectedTbName.slice(0, selectedTbName.indexOf('-')) : ''}
                tbMode={tbMode}
            />
        </React.Fragment >
    );
};

MappingsList.propTypes = {
    tbMappings: PropTypes.array,
    formMapping: PropTypes.object,
    period: PropTypes.object,
    properties: PropTypes.array,
    property: PropTypes.object,
    showForm: PropTypes.bool,
    hideSuccessMessage: PropTypes.bool,
    submittingMapping: PropTypes.bool,
    showConfirmation: PropTypes.bool,
    showSuccess: PropTypes.bool,
    canEdit: PropTypes.bool,
    tbMode: PropTypes.number,
    formPurpose: PropTypes.string.isRequired,
    selectedTbName: PropTypes.string,
    confirmAction: PropTypes.func,
    trialBalancePurposes: PropTypes.func.isRequired,
    handleTrialBalancePurposeDropdownSelected: PropTypes.func.isRequired,
    handleCancelDelete: PropTypes.func.isRequired,
    handleConfirmDelete: PropTypes.func.isRequired,
    selectedTbPctOwnership: PropTypes.number,
    handleDismissErrorMessage: PropTypes.func.isRequired,
    handleDismissSuccessMessage: PropTypes.func.isRequired,
    handleDownloadAll: PropTypes.func.isRequired,
    handleDeleteAll: PropTypes.func.isRequired,
    handleEditButtonClick: PropTypes.func.isRequired,
    handleCloseForm: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    showSyncREITTestingAttributesModal: PropTypes.bool,
    toggleShowSyncREITTestingAttributesModal: PropTypes.func,
    handleSyncREITTestsingAttributeAction: PropTypes.func,
    syncREITTestingAttributesFormValues: PropTypes.object,
    chartName: PropTypes.string
};

export default MappingsList;