import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { pendingTask, begin, end } from "react-redux-spinner";
import {
  getFormValues,
  getFormSyncErrors,
  getFormSubmitErrors,
  SubmissionError,
  reduxForm,
} from 'redux-form';
import { bindActionCreators } from 'redux';
import * as clientActions from '../../actions/clientActions';
import * as checklistActions from '../../actions/checklistActions';
import * as commonActions from '../../actions/commonActions';
import * as reportActions from '../../actions/reportActions';
import { MotifTabNavigation, MotifTabControl } from '@ey-xd/motif-react';
import { Link } from 'react-router';
import ManageExternalChecklistHeader from '../../components/checklistDeliveryCalendar/ManageExternalChecklistHeader';
import PSQChecklistExternalTable from '../../components/checklistDeliveryCalendar/PSQChecklistExternalTable';
import DownloadChecklistsModal from '../../components/checklistDeliveryCalendar/DownloadChecklistsModal';
import RequestToReopenModal from '../../components/checklistDeliveryCalendar/RequestToReopenModal';
import * as actions from "../../actions/actionTypes";
import SubmitForReviewModal from "../../components/checklistDeliveryCalendar/SubmitForReviewModal";
import ClientSignoffModal from "../../components/checklistDeliveryCalendar/ClientSignoffModal";
import ClientUnsignoffModal from "../../components/checklistDeliveryCalendar/ClientUnsignoffModal";
import * as actionHelpers from '../../scripts/actionHelpers';
/**
 * PSQChecklistExternalContainer container component
 * @extends {React.Component}
 */
class PSQChecklistExternalContainer extends React.Component {
  /**
   * Creates a new Checklist Dashboard page
   * @constructor
   * @param {Object} props The component properties
   * @param {Object} context The component context
   */
  constructor(props, context) {
    super(props, context);

    this.setTabBar1 = this.setTabBar1.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClientChange = this.handleClientChange.bind(this);
    this.handleCalendarYearChange = this.handleCalendarYearChange.bind(this);
    this.handleGridChanges = this.handleGridChanges.bind(this);
    this.handleGridColumnStateChange =
      this.handleGridColumnStateChange.bind(this);
    this.toggleShowDownloadChecklistsModal =
      this.toggleShowDownloadChecklistsModal.bind(this);
    this.handleDownloadChecklists = this.handleDownloadChecklists.bind(this);
    this.toggleShowRequestToReopenModal = this.toggleShowRequestToReopenModal.bind(this);
    this.handleReopenChecklistRequest = this.handleReopenChecklistRequest.bind(this);
    this.toggleShowSubmitForEYReviewModal =
          this.toggleShowSubmitForEYReviewModal.bind(this);
    this.toggleShowClientSignoffModal =
          this.toggleShowClientSignoffModal.bind(this);
    this.toggleShowClientUnsignoffModal =
          this.toggleShowClientUnsignoffModal.bind(this);
    this.handleSubmitForReview = this.handleSubmitForReview.bind(this);
    this.handleClientSignoff = this.handleClientSignoff.bind(this);
    this.handleClientUnsignoff = this.handleClientUnsignoff.bind(this);
    this.state = {
      clients: [],
      checklistCalendarYears: [],
      selectedClient: undefined,
      selectedCalendarYear: 0,
      filteredExternalChecklists: [],
      gridCurrentState: [],
      selectedChecklists: [],
      downloadChecklists: {
        downloadType: '',
        showModal: false,
        modalTitle: '',
        modalContent: '',
      },
      gridColumnState: [],
      refreshGridOnCompletedAction: -1,
      showRequestToReopenModal: false,
      submitForEYReview: {
        showModal: false,
        modalTitle: "",
        modalContent: "",
        },
      clientSignoff: {
          showModal: false,
          modalTitle: "",
          modalContent: "",
      },
      clientUnsignoff: {
          showModal: false,
          modalTitle: "",
          modalContent: "",
      },
    };
  }

  /**
   * Invoked immediately after mounting occurs
   * @returns {undefined}
   */
  componentDidMount() {
    this.fetchAllActiveClients();
  }

  /**
   * Invoked immediately before unmounting occurs
   * @returns {undefined}
   */
  componentWillUnmount() {
    // On unmount, clear the client data from state to conserve memory
    this.props.clientActions.fetchAllActiveClients(true);
    // On unmount, clear the calendar data from state to conserve memory
    this.props.checklistActions.fetchChecklistCalendarYearsByClientAndType();
    // Calling this action with no argument will clear from redux-state
    this.props.checklistActions.fetchExternalChecklists();
    //this.props.checklistActions.fetchChecklistCalendarYears();

    this.setState({ filteredExternalChecklists: [] });
  }

  componentWillReceiveProps(nextProps) { }

  /**
   * Handle Grid column state chagne
   * @returns {undefined}
   */
  handleGridColumnStateChange(columnState) {
    this.setState({ gridColumnState: columnState });
  }

  handleGridChanges(gridRowData) {
    let rowData = [];
    rowData =
      gridRowData &&
      gridRowData.length &&
      gridRowData.map((x) => {
          return {
              checklistID: x.checklistID,
              checklistState: x.checklistState,
              checklistSelection: x.checklistSelection,
              checklistStateDescription: x.checklistStateDescription,
              checklistType: x.checklistType,
              propertyName: x.propertyName,
              propertyTypeDescription: x.propertyTypeDescription,
              quarter: x.quarter,
              quarterStatus: x.quarterStatus,
              reitid: x.reitid,
              reitName: x.reitName,
              reportPeriodID: x.reportPeriodID,
              isSignedOff: x.isSignedOff,
              signedOffStatusDescription: x.signedOffStatusDescription,
              userActionDateTime: x.userActionDateTime,
              clientUserEmailAddress: x.clientUserEmailAddress
          };
      });

    this.setState({ gridCurrentState: rowData });

    // Filtering the selected checklists
    const selectedChecklists =
      gridRowData &&
      gridRowData.length &&
      gridRowData.reduce((reduced, row) => {
        if (row.checklistSelection) {
          return reduced.concat({
            checklistID: row.checklistID,
            checklistState: row.checklistState,
            checklistSelection: row.checklistSelection,
            checklistStateDescription: row.checklistStateDescription,
            checklistType: row.checklistType,
            propertyName: row.propertyName,
            propertyTypeDescription: row.propertyTypeDescription,
            quarter: row.quarter,
            quarterStatus: row.quarterStatus,
            reitid: row.reitid,
            reitName: row.reitName,
            reportPeriodID: row.reportPeriodID,
            isSignedOff: row.isSignedOff,
            signedOffStatusDescription: row.signedOffStatusDescription,
            userActionDateTime: row.userActionDateTime,
            clientUserEmailAddress: row.clientUserEmailAddress,
            canExternalUserSubmitChecklistForEYReview: row.canExternalUserSubmitChecklistForEYReview
          });
        }
        return reduced;
      }, []);

    this.setState({ selectedChecklists: selectedChecklists });
  }

  //Set tab related state variables
  setTabBar1(currentIndex) {
    this.setState({ tabBar1: currentIndex });
  }

  //Set current tab index
  handleClick(index) {
    this.setTabBar1(index);
  }

  fetchAllActiveClients() {
    this.props.clientActions.fetchAllActiveClients().then(() => {
      if (
        this.props.externalChecklists.clients &&
        this.props.externalChecklists.clients.length > 0
      ) {
        this.setState({ clients: this.props.externalChecklists.clients });
        if (this.props.externalChecklists.clients.length === 1) {
          this.setState({
            selectedClient: this.props.externalChecklists.clients[0],
          });
          this.fetchCalenderYearsByClientAndChecklistType(
            this.state.selectedClient.id,
            'PSQ'
          );
        }
      }
    });
  }

  fetchCalenderYearsByClientAndChecklistType(clientId, checklistType) {
    this.props.checklistActions
      .fetchChecklistCalendarYearsByClientAndType(clientId, checklistType)
      .then(() => {
        // Setting the local state of the calendar year dropdown
        this.setState({
          checklistCalendarYears: this.props.externalChecklists.calendarYears,
        });
        // Populating an empty grid by clearing the redux-state if the calendar years array is empty 
        // Otherwise fetch external checklist for default calendar year
        if (
          this.props &&
          this.props.externalChecklists &&
          this.props.externalChecklists.calendarYears) {
          if (this.props.externalChecklists.calendarYears.length <= 0) {
            this.props.checklistActions.fetchExternalChecklists();
          } else {
            const preselectedCalendarYear = this.props.externalChecklists.calendarYears.includes(new Date().getFullYear())
              ? parseInt(new Date().getFullYear()).toString()
              : this.props.externalChecklists.calendarYears[0].toString();
            this.props.checklistActions.fetchExternalChecklists(clientId, preselectedCalendarYear, checklistType);
            this.setState({ selectedCalendarYear: preselectedCalendarYear });
          }
        }
      });
  }

  handleClientChange(client) {
    if (client !== '') {
      const selectedClient = this.props.externalChecklists.clients.filter(
        (c) => c.name === client
      )[0];
      this.setState({ selectedClient: selectedClient });
      this.setState({ checklistCalendarYears: [] });
      this.fetchCalenderYearsByClientAndChecklistType(selectedClient.id, 'PSQ');
    }
  }

  handleCalendarYearChange(calendarYear) {
    this.setState({ selectedCalendarYear: calendarYear });
    let clientId =
      (this.state.selectedClient && this.state.selectedClient.id) || -1;
    this.props.checklistActions.fetchExternalChecklists(
      clientId,
      calendarYear,
      'psq'
    );
  }

  toggleShowDownloadChecklistsModal(downloadType) {
    if (!this.state.downloadChecklists.showModal) {
      switch (downloadType) {
        case 'WITHOUT_ANSWERS': {
          const downloadChecklistsState = {
            downloadType: downloadType,
            showModal: !this.state.downloadChecklists.showModal,
            modalTitle: 'Download Checklist(s) without Answers or Comments',
            modalContent:
              'Are you sure you want to download the selected checklist(s) without answers or comments?',
          };
          this.setState({ downloadChecklists: downloadChecklistsState });
          break;
        }
        case 'WITH_ANSWERS_COMMENTS': {
          const downloadChecklistsState = {
            downloadType: downloadType,
            showModal: !this.state.downloadChecklists.showModal,
            modalTitle: 'Download Checklist(s) with Answers and Comments',
            modalContent:
              'Are you sure you want to download the selected checklist(s) with answers and comments?',
          };
          this.setState({ downloadChecklists: downloadChecklistsState });
          break;
        }
        case 'WITH_ANSWERS': {
          const downloadChecklistsState = {
            downloadType: downloadType,
            showModal: !this.state.downloadChecklists.showModal,
            modalTitle: 'Download Checklist(s) with Answers Only',
            modalContent:
              'Are you sure you want to download the selected checklist(s) with answers?',
          };
          this.setState({ downloadChecklists: downloadChecklistsState });
          break;
        }
        default:
          break;
      }
    } else {
      const downloadChecklistsState = {
        showModal: !this.state.downloadChecklists.showModal,
        modalTitle: '',
        modalContent: '',
        downloadType: '',
      };
      this.setState({ downloadChecklists: downloadChecklistsState });
    }
  }

  handleDownloadChecklists(downloadType) {
    // Getting a copy of the selected checklists from the local state
    const selectedChecklists = this.state.selectedChecklists.slice();

    // Creating a object array for all the selected checklists with corresponding request details
    const checklistRequestDetails = selectedChecklists.map((c) => ({
      reitNameAndPeriod: `reitName=${c.reitName},periodDesc=${c.quarter} ${this.state.selectedCalendarYear}`,
      periodId: c.reportPeriodID,
      id: c.checklistID,
      isExternal: true,
      filename: '',
      reitName: c.reitName,
      propertyName: c.propertyName,
      propertyTypeDescription: c.propertyTypeDescription,
      quarter: c.quarter,
    }));

    // Logic for file name
    checklistRequestDetails.forEach((item) => {
      // Setting a defualt file name based on the Report period, Year, RIET, Property name and Property type description
      const defaultFilename = `${item.quarter}${this.state.selectedCalendarYear}_${item.reitName}_${item.propertyName}_${item.propertyTypeDescription}_PSQ`;

      // Checking if a row object is already created with the standard file name
      const existingFiles = checklistRequestDetails
        .filter((c) => c.filename.includes(defaultFilename))
        .sort();

      if (existingFiles.length > 0) {
        //Splilling the file name based on "_"
        const fileNameSplitArray = existingFiles[
          existingFiles.length - 1
        ].filename
          .split('.')[0]
          .split('_');

        if (existingFiles.length > 1) {
          /* Case when there are more than one files with the same combination of Report period, Year, RIET, Property name and Property type description
             In such cases, incrementing the version at the end */
          const currentVersion = parseInt(fileNameSplitArray[fileNameSplitArray.length - 1]);
          fileNameSplitArray[fileNameSplitArray.length - 1] = (currentVersion + 1).toString();
          item.filename = `${fileNameSplitArray.join('_')}.docx`;
        } else {
          // Case when there is only one file with the existing combination, hence appending the version as 1 at the end
          item.filename = `${fileNameSplitArray.join('_')}_1.docx`;
        }
      } else {
        // Case when there is no file name with the combination
        item.filename = `${defaultFilename}.docx`;
      }
    });

    // Preparing the request body
    const request = {
      client: this.state.selectedClient.name,
      calendarYear: this.state.selectedCalendarYear,
      checklistRequestDetails: checklistRequestDetails,
    };

    this.props.commonActions.clearErrorResponse();

    // Calling the action to fetch data from the API
    this.props.reportActions
      .downloadChecklists(request, 'PSQ', downloadType)
      .then(() => {
        // Refreshing grid and toggling the modal to false
        this.refreshGrid();
        this.toggleShowDownloadChecklistsModal(downloadType);
      })
      .catch(() => {
        // Toggle the modal to fasle
        this.toggleShowDownloadChecklistsModal(downloadType);
      });
  }

  refreshGrid() {
    const clientId =
      (this.state.selectedClient && this.state.selectedClient.id) || -1;
    const calendarYear = this.state.selectedCalendarYear || -1;
    this.props.checklistActions
      .fetchExternalChecklists(clientId, calendarYear, 'psq')
      .then(() => {
        //Update refreshGridOnCompletedAction state variable to refresh the grid
        const randomNumber = parseInt(Math.random() * (99999999 - 1)) + 1;
        this.setState({ refreshGridOnCompletedAction: randomNumber });
      });
  }

  toggleShowRequestToReopenModal() {
    this.setState({ showRequestToReopenModal: !this.state.showRequestToReopenModal });
  }

  handleReopenChecklistRequest() {
    const checklistsToReopen = this.state.selectedChecklists
      .map(checklist => {
        return {
          checklistID: checklist.checklistID,
          checklistType: checklist.checklistType,
          reportPeriodID: checklist.reportPeriodID
        }
      });

    this.props.checklistActions
      .requestToReopenChecklists(checklistsToReopen)
      .then(() => {
        this.toggleShowRequestToReopenModal();
        this.refreshGrid();
      });
  }

  toggleShowSubmitForEYReviewModal() {
    if (!this.state.submitForEYReview.showModal) {
      const submitForEYReviewState = {
        showModal: !this.state.submitForEYReview.showModal,
        modalTitle: "Submit for EY Review",
        modalContent:
          "Are you sure you want to submit the selected checklist(s) for EY review?",
      };
      this.setState({ submitForEYReview: submitForEYReviewState });
    } else {
      const submitForEYReviewState = {
        showModal: !this.state.submitForEYReview.showModal,
        modalTitle: "",
        modalContent: "",
        downloadType: "",
      };
      this.setState({ submitForEYReview: submitForEYReviewState });
    }
  }

   toggleShowClientSignoffModal() {
      if (!this.state.clientSignoff.showModal) {
          const clientSignoffState = {
              showModal: !this.state.clientSignoff.showModal,
              modalTitle: "Client Sign Off",
              modalContent:
                  "Are you sure you want to sign off the selected checklist(s)?",
          };
          this.setState({ clientSignoff: clientSignoffState });
      } else {
          const clientSignoffState = {
              showModal: !this.state.clientSignoff.showModal,
              modalTitle: "",
              modalContent: "",
          };
          this.setState({ clientSignoff: clientSignoffState });
      }
   }

  toggleShowClientUnsignoffModal() {
     
      if (!this.state.clientUnsignoff.showModal) {
          const clientUnsignoffState = {
              showModal: !this.state.clientUnsignoff.showModal,
              modalTitle: "Client Unsign Off",
              modalContent:
                  "Are you sure you want to unsign off the selected checklist(s)?",
          };
          this.setState({ clientUnsignoff: clientUnsignoffState });
      } else {
          const clientUnsignoffState = {
              showModal: !this.state.clientUnsignoff.showModal,
              modalTitle: "",
              modalContent: "",
          };
          this.setState({ clientUnsignoff: clientUnsignoffState });
        }
    }    

  // Signoff/Unsignoff checklists
  async signoffAndUnsignoffChecklists(isSignedOff) {
      const selectedChecklists = Object.assign([], this.state.selectedChecklists);
      const splitedChecklists = this.spliceSelectedChecklists(
          selectedChecklists,
          20
      );
      this.props.dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

      await checklistActions
          .splitAndSignoffChecklist(splitedChecklists, "PSQ", isSignedOff)
          .then(() => {
              if (isSignedOff) {
                  this.toggleShowClientSignoffModal();
              }
              else {
                  this.toggleShowClientUnsignoffModal();
              }
              this.refreshGrid();
              this.props.dispatch({ type: actions.TASK_END, [pendingTask]: end });

          })
          .catch((error) => {
              if (isSignedOff) {
                  this.toggleShowClientSignoffModal();
              }
              else {
                  this.toggleShowClientUnsignoffModal();
              }
              this.refreshGrid();
              return actionHelpers.dispatchErrorAndEndTask(
                  this.props.dispatch,
                  actions.SIGNOFF_CHECKLIST_REQUESTS_FAILURE,
                  error,
              );
          });
  }

  // Handle Client Signoff 
  async handleClientSignoff() {
      await this.signoffAndUnsignoffChecklists(1);
  }

  // Handle Client Unsignoff 
  async handleClientUnsignoff() {
      await this.signoffAndUnsignoffChecklists(0);
  }

  async handleSubmitForReview() {
    const selectedChecklists = Object.assign([], this.state.selectedChecklists);
    const splitedChecklists = this.spliceSelectedChecklists(
      selectedChecklists,
      20
    );
    this.props.dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

    await checklistActions
      .splitAndsubmitForEYReview(splitedChecklists, "PSQ")
      .then(() => {
        this.toggleShowSubmitForEYReviewModal();
        this.refreshGrid();
        this.props.dispatch({ type: actions.TASK_END, [pendingTask]: end });

      })
      .catch((error) => {
        this.toggleShowSubmitForEYReviewModal();
        this.refreshGrid();
        return actionHelpers.dispatchErrorAndEndTask(
          this.props.dispatch,
          actions.SUBMITFOREYREVIEW_REQUESTS_FAILURE,
          error,
        );

      });
  }

  spliceSelectedChecklists(arr, size) {
    let res = [];
    while (arr.length > 0) {
      const chunk = arr.splice(0, size);
      res.push(chunk);
    }
    return res;
  }

  /**
   * Render a React element
   * @returns {Object} A reference to the component
   */
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col">
            <h1 className="pageheader" data-testid="checklistDashboard">
              Checklist Dashboard
            </h1>
          </div>
        </div>
        <MotifTabNavigation variant="default">
          <Link to={`/ext/psq`} tabIndex="-1">
            <MotifTabControl
              className="motif-active"
              autoFocus
              role="tab"
              data-testid="btnPSQ"
              onClick={() => this.handleClick(1)}
            >
              PSQ
            </MotifTabControl>
          </Link>
          <Link to={`/ext/arqc`} tabIndex="-1">
            <MotifTabControl
              role="tab"
              data-testid="btnARQC"
              onClick={() => this.handleClick(2)}
            >
              ARQC
            </MotifTabControl>
          </Link>
        </MotifTabNavigation>
        {this.state.clients.length > 0 && (
          <ManageExternalChecklistHeader
            clients={this.state.clients}
            selectedClient={this.state.selectedClient}
            handleClientChange={this.handleClientChange}
            calendarYears={this.state.checklistCalendarYears}
            handleCalendarYearChange={this.handleCalendarYearChange}
            selectedChecklists={this.state.selectedChecklists}
            toggleShowDownloadChecklistsModal={this.toggleShowDownloadChecklistsModal}
            toggleShowRequestToReopenModal={this.toggleShowRequestToReopenModal}
            toggleShowSubmitForEYReviewModal={this.toggleShowSubmitForEYReviewModal}
            toggleShowClientSignoffModal={this.toggleShowClientSignoffModal}
            toggleShowClientUnsignoffModal={this.toggleShowClientUnsignoffModal}
          ></ManageExternalChecklistHeader>
        )}
        {this.state.selectedClient &&
          this.state.selectedClient.name &&
          this.state.selectedClient.name.length > 0 &&
          (!this.props.externalChecklists ||
            !this.props.externalChecklists.calendarYears ||
            this.props.externalChecklists.calendarYears.length === 0) ? (
          <div>No checklists found for the selected client.</div>
        ) : (
          <div></div>
        )}
        <PSQChecklistExternalTable
          selectedClient={this.state.selectedClient}
          filteredExternalChecklists={this.props.filteredExternalChecklists}
          handleGridChanges={this.handleGridChanges}
          refreshGridOnCompletedAction={this.state.refreshGridOnCompletedAction}
          handleGridColumnStateChange={this.handleGridColumnStateChange}
        />
        <DownloadChecklistsModal
          showDownloadChecklistsModal={
            this.state.downloadChecklists.showModal
          }
          downloadType={this.state.downloadChecklists.downloadType}
          modalTitle={this.state.downloadChecklists.modalTitle}
          modalContent={this.state.downloadChecklists.modalContent}
          selectedChecklists={this.state.selectedChecklists}
          toggleShowDownloadChecklistsModal={
            this.toggleShowDownloadChecklistsModal
          }
          handleDownloadChecklists={this.handleDownloadChecklists}
        ></DownloadChecklistsModal>

        <RequestToReopenModal
          showRequestToReopenModal={this.state.showRequestToReopenModal}
          handleReopenChecklistRequest={this.handleReopenChecklistRequest}
          toggleShowRequestToReopenModal={this.toggleShowRequestToReopenModal}
          selectedChecklists={this.state.selectedChecklists}
        />
        <SubmitForReviewModal
          showSubmitForReviewModal={this.state.submitForEYReview.showModal}
          modalTitle={this.state.submitForEYReview.modalTitle}
          modalContent={this.state.submitForEYReview.modalContent}
          selectedChecklists={this.state.selectedChecklists}
          toggleShowSubmitForEYReviewModal={
            this.toggleShowSubmitForEYReviewModal
          }
          handleSubmitForReview={this.handleSubmitForReview}
          ></SubmitForReviewModal>
            <ClientSignoffModal
                showClientSignoffModal={this.state.clientSignoff.showModal}
                modalTitle={this.state.clientSignoff.modalTitle}
                modalContent={this.state.clientSignoff.modalContent}
                selectedChecklists={this.state.selectedChecklists}
                toggleShowClientSignoffModal={
                    this.toggleShowClientSignoffModal
                }
                handleClientSignoff={this.handleClientSignoff}
            ></ClientSignoffModal>
            <ClientUnsignoffModal
                showClientUnsignoffModal={this.state.clientUnsignoff.showModal}
                modalTitle={this.state.clientUnsignoff.modalTitle}
                modalContent={this.state.clientUnsignoff.modalContent}
                selectedChecklists={this.state.selectedChecklists}
                toggleShowClientUnsignoffModal={
                    this.toggleShowClientUnsignoffModal
                }
                handleClientUnsignoff={this.handleClientUnsignoff}
            ></ClientUnsignoffModal>
      </React.Fragment>
    );
  }
}

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
  return {
    externalChecklists: {
      clients: state.externalChecklistsClients,
      calendarYears: state.checklistCalendarYearsByType,
    },
    psqChecklistExternalValues: getFormValues('psqChecklistExternal')(state),
    filteredExternalChecklists: state.externalChecklists,
  };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
  return {
    clientActions: bindActionCreators(clientActions, dispatch),
    checklistActions: bindActionCreators(checklistActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    reportActions: bindActionCreators(reportActions, dispatch),
  };
}

PSQChecklistExternalContainer.propTypes = {
  initialValues: PropTypes.object,
  clientActions: PropTypes.object,
  checklistActions: PropTypes.object,
  currentUserAuthorizations: PropTypes.array,
  initialize: PropTypes.func,
  externalChecklists: PropTypes.object,
  psqChecklistExternalValues: PropTypes.object,
  filteredExternalChecklists: PropTypes.array,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({ form: 'psqChecklistExternal', enableReinitialize: true })(
    PSQChecklistExternalContainer
  )
);
