import PropTypes from "prop-types";
import React from "react";
import { Header, Icon } from "semantic-ui-react";
import { Field, reduxForm, SubmissionError } from 'redux-form';
import ReactModal from "react-modal";
import { ReactMultiEmail } from 'react-multi-email';
import * as formValidators from "../../scripts/formValidators";
import * as formHelpers from "../../scripts/formHelpers";
import $ from "jquery";
import { MotifButton } from "@ey-xd/motif-react";

const ForwardPsqForInternalReviewModal = ({ handleSubmit, handleCancel, showModal, submitting, domRef, handleInternalReviewEmailFieldChanged, error, handleDismissErrorMessage, loaded, emails }) => {

    const validateEmails = () => {
        let internalReviewEmailsValue = $("#internalReviewEmails :input").val();

        if (internalReviewEmailsValue !== "") {
            throw new SubmissionError({
                _error: "'" + internalReviewEmailsValue + "' is not a valid email address."
            });
        }

        return handleSubmit();
    };

    return (
        <ReactModal contentLabel="Submit Internally" isOpen={showModal} className="react-modal" overlayClassName="react-modal-overlay">
            <Header content="Submit Internally" />
            <form onSubmit={handleSubmit(validateEmails)}>
                {formHelpers.showSubmissionError(error, handleDismissErrorMessage)}                
                <div style={{ display: (loaded && "block") || "none" }}>
                    <div>
                        <Field
                            label="Send to (Multiple email addresses are supported if separated by a semi-colon)"
                            component={formHelpers.renderReactMultiEmail}
                            id="internalReviewEmails"
                            name="internalReviewEmails"
                            domRef={domRef}
                            handleChange={handleInternalReviewEmailFieldChanged}
                            emails={emails}
                            validate={[formValidators.multipleEmails, formValidators.maxLength255]} />
                    </div>
                    <div>                        
                        <MotifButton variant="primary" className="right internal-review-modal-button" type="submit"
                            disabled={submitting} >
                            Send
                        </MotifButton>
                        <MotifButton variant="secondary" className="right internal-review-modal-button" type="button"
                            onClick={handleCancel}
                            disabled={submitting} >
                            Cancel
                        </MotifButton>
                    </div>
                </div>
                <div style={{ display: (loaded && "none") || "block" }}>
                    <Icon loading name="spinner" /> loading...
                </div>
            </form>

        </ReactModal>
    );
};

ForwardPsqForInternalReviewModal.propTypes = {
    handleSubmit: PropTypes.func,
    handleCancel: PropTypes.func,
    showModal: PropTypes.bool,
    submitting: PropTypes.bool,
    initialValues: PropTypes.object,
    domRef: PropTypes.func,
    handleInternalReviewEmailFieldChanged: PropTypes.func,
    error: PropTypes.string,
    handleDismissErrorMessage: PropTypes.func,
    loaded: PropTypes.bool,
    emails: PropTypes.array
};

export default reduxForm({ form: 'forwardPsqForInternalReview', enableReinitialize: true })(ForwardPsqForInternalReviewModal);