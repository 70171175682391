import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
  MotifButton,
  MotifIcon
} from '@ey-xd/motif-react';
import { addAttribute } from '../../common/Attribute';
import { alertIcError24px } from '@ey-xd/motif-react/assets/icons';

const DeleteChecklistServiceProviderModal = ({
  showDeleteChecklistServiceProviderModal,
  toggleShowDeleteChecklistServiceProviderModal,
  deleteServiceProvider
}) => {
  useEffect(() => {
    //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
    addAttribute("button[role = 'combobox']", 'type', 'button');
  });

  const HeaderWrapper = ({ children }) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
      }}
    >
      {children}
    </div>
  );

  const closeButtonProps = {
    title: `Close Delete Service Provider modal`,
    ariaLabel: `Close Delete Service Provider modal`,
    dataTestid: 'close',
  };

  return (
    <React.Fragment>
      <MotifModal
        variant="alt"
        show={showDeleteChecklistServiceProviderModal}
        onClose={toggleShowDeleteChecklistServiceProviderModal}
        data-testid="deleteServiceProviderModal"
      >
        <MotifModalHeader
          style={{ display: '-webkit-box' }} data-testid="h3deleteServiceProvider"
          closeButtonProps={closeButtonProps}
        >
          <HeaderWrapper>
            <MotifIcon style={{ color: "#F95D54" }} src={alertIcError24px} />
            <span className="ml-2" >Delete Service Provider</span>
          </HeaderWrapper>
        </MotifModalHeader>
        <MotifModalBody>
          <span className="checklistActionMsg mb-2" data-testid="spanDeleteConfirmation">Are you sure you want to delete this Service Provider?</span>
        </MotifModalBody>
        <MotifModalFooter>
          <MotifButton variant="warn" className="right"
            data-testid="btnDelete"
            type="button" onClick={deleteServiceProvider}
          >Delete</MotifButton>
          <MotifButton variant="secondary" className="right"
            data-testid="btnCancel"
            type="button"
            onClick={toggleShowDeleteChecklistServiceProviderModal}>Cancel</MotifButton>
        </MotifModalFooter>
      </MotifModal>
    </React.Fragment>
  );
};

DeleteChecklistServiceProviderModal.propTypes = {
  showDeleteChecklistServiceProviderModal: PropTypes.bool,
  toggleShowDeleteChecklistServiceProviderModal: PropTypes.bool,
  deleteServiceProvider: PropTypes.func
};

export default DeleteChecklistServiceProviderModal;
