import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Icon, Header } from 'semantic-ui-react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as chartOfAccountActions from "../../actions/chartOfAccountsManagerActions";
import { MotifButton, MotifTextLink, MotifIcon, MotifModal, MotifModalHeader, MotifModalBody, MotifModalFooter } from '@ey-xd/motif-react';
import * as Constants from '../../constants/other';
import { fileIcCloudUpload24px } from '@ey-xd/motif-react/assets/icons';

/**
 * Container component used for uploading chart files
 */
export class ChartUploadModal extends React.Component {
    /**
     * Creates a new ChartUploadModal
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);

        this.renderUploadStatus = this.renderUploadStatus.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.state = {
            status: null,
            rejectedFiles: [],
            acceptedFiles: [],
            validationErrors: []
        };

        this.acceptedFileExtensions = '.xlsx';
    }

    handleClose() {
        this.setState({ status: null, rejectedFiles: [], acceptedFiles: [], validationErrors: [] });
        this.props.handleChartSelection(this.props.chartOfAccountsFormValues.chartID);
        this.props.toggleShowUploadChartModal();
    }

    onDrop(acceptedFiles, rejectedFiles) {
        if (Array.isArray(rejectedFiles) && rejectedFiles.length > 0) {
            this.setState({ status: 'Rejected', rejectedFiles, acceptedFiles: [], validationErrors: [] });
        } else if (Array.isArray(acceptedFiles)) {
            this.setState({ status: 'Uploading', rejectedFiles: [], acceptedFiles: acceptedFiles, validationErrors: [] });

            this.props.chartOfAccountActions.uploadChartofAccounts(this.props.clientId, this.props.chartOfAccountsFormValues.chartID, acceptedFiles[0])
                .then(response => {
                    if (response && response.errorResponse && response.errorResponse.errorCode) {
                        //SERVER ERROR, BAD REQUEST
                        this.setState({ status: null, rejectedFiles: [], acceptedFiles: [], validationErrors: [] });
                        this.props.toggleShowUploadChartModal();
                    }
                    else if (response && response.uploadChartValidationError && Array.isArray(response.uploadChartValidationError) && response.uploadChartValidationError.length > 0) {
                        this.setState({ status: 'Error', acceptedFiles: acceptedFiles, validationErrors: response.uploadChartValidationError });
                    } else {
                        this.setState({ status: 'Success', acceptedFiles: acceptedFiles, validationErrors: [] });
                    }
                });
        }
    }

    renderUploadStatus() {
        let msg;
        let rejectedDetailMsg;
        let colorClass;

        if (this.state.status == 'Uploading') {
            msg = 'Uploading file(s)';
        } else if (this.state.status == 'Success') {
            msg = 'File has successfully been processed.';
            if (this.state.acceptedFiles && this.state.acceptedFiles.length > 0) {
                var acceptedfileName = this.state.acceptedFiles[0].name;
                msg = acceptedfileName + ' has successfully been processed.';
            }
            colorClass = 'text-success';
        } else if (this.state.status == 'Rejected') {
            if (this.state.rejectedFiles && this.state.rejectedFiles.length > 1) {
                //Upload more than one file

                msg = 'Upload failed.';
                rejectedDetailMsg = 'Cannot upload more than one file.'
            }
            else {
                //Upload single non xlsx type file or Upload two xlsx type files

                msg = 'Upload failed.';
                rejectedDetailMsg = 'Cannot upload more than one file.';

                if (this.state.rejectedFiles && this.state.rejectedFiles.length == 1) {
                    let file = this.state.rejectedFiles[0].file;
                    let fileName = file.name;
                    if (fileName) {
                        let fileExtension = fileName.substr((fileName.lastIndexOf('.') + 1));
                        if (fileExtension.toUpperCase() != "XLSX") {
                            rejectedDetailMsg = 'The format of the file you submitted is not supported. The only supported format for a Chart of Accounts Template is XLSX.';
                        }
                        else if (file.size > Constants.MAX_UPLOAD_FILE_SIZE_IN_BYTES) {
                            rejectedDetailMsg = 'The file size has exceeded max limit of ' + Constants.MAX_UPLOAD_FILE_SIZE_IN_MB + 'MB.';
                        }
                        else {
                            rejectedDetailMsg = 'Cannot upload more than one file.';
                        }
                    }
                }
            }

            colorClass = 'text-danger';

        } else if (this.state.status == 'Error') {
            msg = 'Upload failed.'
            colorClass = 'text-danger';
        }

        return msg &&
            <React.Fragment>
                {this.state.status == 'Success' && <div style={{ textAlign: 'left' }}>
                    <span>
                        <Icon name="check circle" size="big" color='green' title="success" />
                    </span>
                    <span>
                        <Header size='small' className={colorClass} content={msg} />
                    </span>
                </div>
                }

                {this.state.status == 'Error' && <div style={{ textAlign: 'left' }}>
                    <div>
                        <span>
                            <Icon name="remove circle" size="big" color='red' title="error" />
                        </span>
                        <span>
                            <Header size='small' className={colorClass} content={msg} />
                        </span>
                    </div>
                    {this.state.validationErrors && this.state.validationErrors.length > 0 && this.state.validationErrors.map((e, index) =>
                        <div key={index} style={{ marginLeft: 37, wordWrap: "break-word" }} >
                            {e.error}
                        </div>
                    )}
                </div>
                }

                {this.state.status == 'Rejected' && <div style={{ textAlign: 'left' }}>
                    <div>
                        <span>
                            <Icon name="remove circle" size="big" color='red' title="error" />
                        </span>
                        <span>
                            <Header size='small' className={colorClass} content={msg} />
                        </span>
                    </div>
                    <div style={{ marginLeft: 37 }}> {rejectedDetailMsg} </div>
                </div>
                }

                {this.state.status == 'Uploading' && <div>
                    <Header size='small' className={colorClass} content={msg} />
                </div>
                }

            </React.Fragment>;
    }

    /**
        * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        return (
            <MotifModal show={this.props.showUploadChartModal} onClose={this.handleClose} variant="alt">
                <MotifModalHeader>
                    Upload Chart of Accounts
                </MotifModalHeader>
                <MotifModalBody>
                    <Dropzone
                        tabIndex="-1"
                        multiple={false}
                        accept='.xlsx'
                        onDrop={this.onDrop}
                        maxSize={Constants.MAX_UPLOAD_FILE_SIZE_IN_BYTES}>
                        {({ getRootProps, getInputProps }) => (
                            <section className="container">
                                <div {...getRootProps({ className: 'dropzone' })} tabIndex="-1"
                                    style={{ width: '100%', textAlign: 'center', border: '1px dashed #666', padding: '50px' }} >
                                    <div><MotifIcon src={fileIcCloudUpload24px} className="uploadIconSize" title="Upload File(s)" /></div>
                                    <input {...getInputProps()} />
                                    <div>Try dropping some file here, or click to <MotifTextLink href="javascript:void(0)" variant="alt">select files</MotifTextLink> to upload.</div>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                    <br />
                    {this.renderUploadStatus()}
                </MotifModalBody>
                <MotifModalFooter>
                    <MotifButton
                        onClick={this.handleClose}
                        variant="primary"
                        className="right"
                    >
                        Close
                    </MotifButton>
                </MotifModalFooter>
            </MotifModal>
        );
    }
}

ChartUploadModal.propTypes = {
    toggleShowUploadChartModal: PropTypes.func.isRequired,
    showUploadChartModal: PropTypes.bool.isRequired,
    authHeader: PropTypes.object,
    chartOfAccountsFormValues: PropTypes.object
};

/**
 * Maps items from state to properties of the component
* @param {Object} state The state
* @param {Object} ownProps The properties of the component
* @returns {Object} An object containing properties that the component can access
*/
function mapStateToProps(state, ownProps) {
    return {
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        chartOfAccountActions: bindActionCreators(chartOfAccountActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartUploadModal);