import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import * as formValidators from "../../scripts/formValidators";
import GroupingofReportExtract from "../../components/bulkReportManagement/GroupingofReportExtract";
import FDRReportTypeReportItemList from '../../components/bulkReportManagement/FDRReportTypeReportItemList';
import {
    MotifButton,
    MotifModal,
    MotifLabel, 
    MotifModalBody,
    MotifModalFooter,
    MotifModalHeader,
    MotifFormField,
    MotifCheckbox,
    MotifToggleSwitch,
    MotifErrorMessage,
    MotifSelect,
    MotifOption
} from "@ey-xd/motif-react";
import * as Constants from '../../constants/other';

const CreateAndRunReportPackageModal = ({ showCreateAndRunReportPackageModal,
    handleSubmit, toggleShowCreateAndRunReportPackageModal, handleCreateAndRunPackage, reportTypeSelectedCount,
    handleIncludeDiagnosticReportCheckboxChecked, includeDiagnosticReport,
    handleShowGroupingofExtract, showGroupingofExtract, handleAddLevelGroupBy, handleDeleteLevelGroupBy, groupingofReportExtractList, handleGroupBySelection,
    reportPeriods, reits, customFdrTemplates, availableReportItems, selectedTemplate, selectedItems, setItemSelection,
    setIncludePsqComments, setIncludeArqcComments, includePsqComments, includeArqcComments, handleTemplateSelect,handleFileSelection, invalid,
    submitting, groupByErrorMessage,setFdrSelection, resetCheckboxes,isAuditOnlyClient,
    handleAllSelection, checkedPeriods, checkedReits}) => {    
    const [checkedConsilidatedMappingCheckbox, setConsolidatedMappingCheckbox] = useState(false);
    const [checkedREITTrialBalanceCheckbox, setREITTrialBalanceCheckbox] = useState(false);
    const [checkedPropertyTrialBalanceCheckbox, setPropertyTrialBalanceCheckbox] = useState(false);
    const [checkedGeneralAndOtherFilesCheckbox, setGeneralAndOtherFilesCheckbox] = useState(false);
    const [checkedUserUploadedFinalReportCheckbox, setUserUploadedFinalReportCheckbox] = useState(false);
    
    const [checkedOneExcelPerREITPerReportPeriodCheckbox, setOneExcelPerREITPerReportPeriodCheckbox] = useState(false);
    const [checkedConsolidatedExcelforAllSelectedREITsCheckbox, setConsolidatedExcelforAllSelectedREITsCheckbox] = useState(false);

    const [checkedDownloadWorkDocForPSQChecklistWithAnswersOnlyCheckbox, setDownloadWorkDocForPSQChecklistWithAnswersOnlyCheckbox] = useState(false);
    const [checkedDownloadPDFForPSQChecklistWithAnswersOnlyCheckbox, setDownloadPDFForPSQChecklistWithAnswersOnlyCheckbox] = useState(false);
    const [checkedDownloadWorkDocForPSQChecklistWithAnswersAndCommentsCheckbox, setDownloadWorkDocForPSQChecklistWithAnswersAndCommentsCheckbox] = useState(false);
    const [checkedDownloadPDFForPSQChecklistWithAnswersAndCommentsCheckbox, setDownloadPDFForPSQChecklistWithAnswersAndCommentsCheckbox] = useState(false);
    const [checkedDownloadWorkDocForPSQChecklistWithoutAnswersOrCommentsCheckbox, setDownloadWorkDocForPSQChecklistWithoutAnswersOrCommentsCheckbox] = useState(false);
    const [checkedDownloadPDFForPSQChecklistWithoutAnswersOrCommentsCheckbox, setDownloadPDFForPSQChecklistWithoutAnswersOrCommentsCheckbox] = useState(false);
    const [checkedDownloadPSQChecklistAttachmentsCheckbox, setDownloadPSQChecklistAttachmentsCheckbox] = useState(false);

    const [checkedDownloadWorkDocForARQCChecklistWithAnswersOnlyCheckbox, setDownloadWorkDocForARQCChecklistWithAnswersOnlyCheckbox] = useState(false);
    const [checkedDownloadPDFForARQCChecklistWithAnswersOnlyCheckbox, setDownloadPDFForARQCChecklistWithAnswersOnlyCheckbox] = useState(false);
    const [checkedDownloadWorkDocForARQCChecklistWithAnswersAndCommentsCheckbox, setDownloadWorkDocForARQCChecklistWithAnswersAndCommentsCheckbox] = useState(false);
    const [checkedDownloadPDFForARQCChecklistWithAnswersAndCommentsCheckbox, setDownloadPDFForARQCChecklistWithAnswersAndCommentsCheckbox] = useState(false);
    const [checkedDownloadWorkDocForARQCChecklistWithoutAnswersOrCommentsCheckbox, setDownloadWorkDocForARQCChecklistWithoutAnswersOrCommentsCheckbox] = useState(false);
    const [checkedDownloadPDFForARQCChecklistWithoutAnswersOrCommentsCheckbox, setDownloadPDFForARQCChecklistWithoutAnswersOrCommentsCheckbox] = useState(false);
    const [checkedDownloadARQCChecklistAttachmentsCheckbox, setDownloadARQCChecklistAttachmentsCheckbox] = useState(false);

    const [checkedFdrTemplateWordDocCheckbox, setFdrTemplateWordDocCheckbox] = useState(false);
    const [checkedFDRTemplatePDFCheckbox, setFDRTemplatePDFCheckbox] = useState(false);

    const [checkedREITGeneralFilesCheckbox, setREITGeneralFilesCheckbox] = useState(false);

    const fileTypes = Constants.reportPackagesFileType;
    const reitOptions = [...reits].map(r => {
        return { key: r.reitid, value: r.reitid, text: r.reitShortName,
            isSelected: checkedReits.find(cp => cp.key == r.reitid)?.isSelected === undefined ? false : checkedReits.find(cp => cp.key == r.reitid)?.isSelected }
    }).sort((a, b) => (b.isSelected) - (a.isSelected));

    const periodsInSortedOrder = [...reportPeriods].sort((a, b) => new Date(b.endDate) - new Date(a.endDate));

    const uniquePeriodDescriptions = Array.from(new Set(periodsInSortedOrder.map(rp => rp.reportPeriodDescription)))
        .map(periodDesc => {
            return periodsInSortedOrder.find(rp => rp.reportPeriodDescription === periodDesc)
        })

    const reportPeriodOptions = [...uniquePeriodDescriptions].map(rp => {
        return { key: rp.reportPeriodID, value: rp.reportPeriodDescription, text: rp.reportPeriodDescription.substring(3, 8) + ' - ' + rp.reportPeriodDescription.substring(0, 2),
        isSelected: checkedPeriods.find(cp => cp.key == rp.reportPeriodID)?.isSelected === undefined ? false : checkedPeriods.find(cp => cp.key == rp.reportPeriodID)?.isSelected }
    }).sort((a, b) => (b.isSelected) - (a.isSelected));
      
    // Get report types count
    let reportTypesCount = 0;

    if(checkedConsilidatedMappingCheckbox)
        reportTypesCount++;
    if (checkedREITTrialBalanceCheckbox)
        reportTypesCount++;
    if (checkedPropertyTrialBalanceCheckbox)
        reportTypesCount++;
    if (checkedOneExcelPerREITPerReportPeriodCheckbox || checkedConsolidatedExcelforAllSelectedREITsCheckbox)
        reportTypesCount++;
    if (checkedDownloadWorkDocForPSQChecklistWithAnswersOnlyCheckbox || checkedDownloadPDFForPSQChecklistWithAnswersOnlyCheckbox
        || checkedDownloadWorkDocForPSQChecklistWithAnswersAndCommentsCheckbox || checkedDownloadPDFForPSQChecklistWithAnswersAndCommentsCheckbox
        || checkedDownloadWorkDocForPSQChecklistWithoutAnswersOrCommentsCheckbox || checkedDownloadPDFForPSQChecklistWithoutAnswersOrCommentsCheckbox
        || checkedDownloadPSQChecklistAttachmentsCheckbox)
        reportTypesCount++;
    if (checkedDownloadWorkDocForARQCChecklistWithAnswersOnlyCheckbox || checkedDownloadPDFForARQCChecklistWithAnswersOnlyCheckbox
        || checkedDownloadWorkDocForARQCChecklistWithAnswersAndCommentsCheckbox || checkedDownloadPDFForARQCChecklistWithAnswersAndCommentsCheckbox
        || checkedDownloadWorkDocForARQCChecklistWithoutAnswersOrCommentsCheckbox || checkedDownloadPDFForARQCChecklistWithoutAnswersOrCommentsCheckbox
        || checkedDownloadARQCChecklistAttachmentsCheckbox)
        reportTypesCount++;
    if (checkedFdrTemplateWordDocCheckbox || checkedFDRTemplatePDFCheckbox)
        reportTypesCount++;
    if (checkedGeneralAndOtherFilesCheckbox)
        reportTypesCount++;
    if (checkedREITGeneralFilesCheckbox)
        reportTypesCount++;
    if (checkedUserUploadedFinalReportCheckbox)
        reportTypesCount++;  

    let selectPeriodCount = 0;
    if(checkedPeriods.filter(cp => cp.isSelected).length > 0)
        selectPeriodCount++;

    let selectReitCount = 0;
    if(checkedReits.filter(cp => cp.isSelected).length > 0)
        selectReitCount++;

    useEffect(() => {
        setConsolidatedMappingCheckbox(false);
        setREITTrialBalanceCheckbox(false);
        setPropertyTrialBalanceCheckbox(false);
        setGeneralAndOtherFilesCheckbox(false);
        setREITGeneralFilesCheckbox(false);
        setUserUploadedFinalReportCheckbox(false);   
        setOneExcelPerREITPerReportPeriodCheckbox(false);
        setConsolidatedExcelforAllSelectedREITsCheckbox(false);
        setDownloadWorkDocForPSQChecklistWithAnswersOnlyCheckbox(false);
        setDownloadPDFForPSQChecklistWithAnswersOnlyCheckbox(false);
        setDownloadWorkDocForPSQChecklistWithAnswersAndCommentsCheckbox(false);
        setDownloadPDFForPSQChecklistWithAnswersAndCommentsCheckbox(false);
        setDownloadWorkDocForPSQChecklistWithoutAnswersOrCommentsCheckbox(false);
        setDownloadPDFForPSQChecklistWithoutAnswersOrCommentsCheckbox(false);
        setDownloadPSQChecklistAttachmentsCheckbox(false);
        setDownloadWorkDocForARQCChecklistWithAnswersOnlyCheckbox(false);
        setDownloadPDFForARQCChecklistWithAnswersOnlyCheckbox(false);
        setDownloadWorkDocForARQCChecklistWithAnswersAndCommentsCheckbox(false);
        setDownloadPDFForARQCChecklistWithAnswersAndCommentsCheckbox(false);
        setDownloadWorkDocForARQCChecklistWithoutAnswersOrCommentsCheckbox(false);
        setDownloadPDFForARQCChecklistWithoutAnswersOrCommentsCheckbox(false);
        setDownloadARQCChecklistAttachmentsCheckbox(false);
        setFdrTemplateWordDocCheckbox(false);
        setFDRTemplatePDFCheckbox(false);
    }, [resetCheckboxes])

        useEffect(() => {
            // Deselect PSQ download attachment checkbox if none of the checkboxes related to PSQ checklists are selected
            if (!(checkedDownloadWorkDocForPSQChecklistWithAnswersOnlyCheckbox
                || checkedDownloadPDFForPSQChecklistWithAnswersOnlyCheckbox
                || checkedDownloadWorkDocForPSQChecklistWithAnswersAndCommentsCheckbox
                || checkedDownloadPDFForPSQChecklistWithAnswersAndCommentsCheckbox
                || checkedDownloadWorkDocForPSQChecklistWithoutAnswersOrCommentsCheckbox
                || checkedDownloadPDFForPSQChecklistWithoutAnswersOrCommentsCheckbox)) {
                setDownloadPSQChecklistAttachmentsCheckbox(false);
            }   
        }, [checkedDownloadWorkDocForPSQChecklistWithAnswersOnlyCheckbox, checkedDownloadPDFForPSQChecklistWithAnswersOnlyCheckbox,
            checkedDownloadWorkDocForPSQChecklistWithAnswersAndCommentsCheckbox, checkedDownloadPDFForPSQChecklistWithAnswersAndCommentsCheckbox,
            checkedDownloadWorkDocForPSQChecklistWithoutAnswersOrCommentsCheckbox, checkedDownloadPDFForPSQChecklistWithoutAnswersOrCommentsCheckbox])
    


    useEffect(() => {
        // Deselect ARQC download attachment checkbox if none of the checkboxes related to PSQ checklists are selected
        if (!(checkedDownloadWorkDocForARQCChecklistWithAnswersOnlyCheckbox
            || checkedDownloadPDFForARQCChecklistWithAnswersOnlyCheckbox
            || checkedDownloadWorkDocForARQCChecklistWithAnswersAndCommentsCheckbox
            || checkedDownloadPDFForARQCChecklistWithAnswersAndCommentsCheckbox
            || checkedDownloadWorkDocForARQCChecklistWithoutAnswersOrCommentsCheckbox
            || checkedDownloadPDFForARQCChecklistWithoutAnswersOrCommentsCheckbox)) {
            setDownloadARQCChecklistAttachmentsCheckbox(false);
        }
    }, [checkedDownloadWorkDocForARQCChecklistWithAnswersOnlyCheckbox, checkedDownloadPDFForARQCChecklistWithAnswersOnlyCheckbox,
        checkedDownloadWorkDocForARQCChecklistWithAnswersAndCommentsCheckbox, checkedDownloadPDFForARQCChecklistWithAnswersAndCommentsCheckbox,
        checkedDownloadWorkDocForARQCChecklistWithoutAnswersOrCommentsCheckbox, checkedDownloadPDFForARQCChecklistWithoutAnswersOrCommentsCheckbox])

    const HeaderWrapper = ({ children }) => (
        <div
            style={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'center'
            }}>
            {children}
        </div>
    );

    const closeButtonProps = {
        title: 'Close Create and run report package modal',
        ariaLabel: 'Close Create and run report package modal',
        dataTestid: 'close'
    };

    return (
        <MotifModal variant="alt" show={showCreateAndRunReportPackageModal} onClose={() => toggleShowCreateAndRunReportPackageModal()} size="xl" data-testid="CreateRunReportPackage">
            <MotifModalHeader style={{ display: "-webkit-box" }} closeButtonProps={closeButtonProps}>
                <HeaderWrapper>
                    <span className="ml-2">Create and Run Report Package</span>
                </HeaderWrapper>
            </MotifModalHeader>

            <MotifModalBody>
                <form onSubmit={handleSubmit} id="createAndRunReportPackage">
                    <br />
                    {(reportPeriods && reits && reportPeriods.length > 0 && reits.length > 0) ?
                        <div>
                            <div className="row">
                                <div className="field col-md-12">
                                    <Field
                                        name="bulkReportPackageName"
                                        component={formHelpers.renderField}
                                        type="text"
                                        label="Report Package Name"
                                        maxLength={25}
                                        isRequiredField={true}
                                        data-testid="txtReportPackageName"
                                        validate={[
                                            formValidators.required,
                                            formValidators.minLength2,
                                            formValidators.maxLength255,
                                            formValidators.noWindowSpecialChars,
                                        ]}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="field col-md-3">
                                    <MotifFormField>
                                        <MotifLabel data-testid="lblReportPeriod">
                                            Report Period (Required)
                                        </MotifLabel>
                                        <MotifSelect
                                            data-testid="divReportPeriod"
                                            name="reportPeriod"
                                            placeholder="Select one or more"
                                            required={true}
                                            validate={[
                                                formValidators.required,
                                                formValidators.minLength2,
                                            ]}
                                            multiple={true}
                                            showSelectAllButton={true}
                                            onClick={(event) => {handleAllSelection(event, reportPeriodOptions, "period")}}
                                        >
                                            {reportPeriodOptions.map((co) => (
                                                <MotifOption
                                                    className="motif-option-text-space"
                                                    data-testvalue={co.text}
                                                    key={co.key}
                                                    value={co.value}>
                                                        {co.text}
                                                </MotifOption>
                                            ))}
                                        </MotifSelect>
                                    </MotifFormField>
                                </div>
                                <div className="field col-md-9">
                                <MotifFormField>
                                        <MotifLabel data-testid="lblReits">
                                            REIT(s) (Required)
                                        </MotifLabel>
                                        <MotifSelect
                                            data-testid="divReits"
                                            name="reit"
                                            placeholder="Select one or more"
                                            required={true}
                                            validate={[
                                                formValidators.required,
                                                formValidators.minLength2,
                                            ]}
                                            multiple={true}
                                            showSelectAllButton={true}
                                            onClick={(event) => {handleAllSelection(event, reitOptions, "reit")}}
                                        >
                                            {reitOptions.map((co) => (
                                                <MotifOption
                                                    className="motif-option-text-space"
                                                    data-testvalue={co.text}
                                                    key={co.key}
                                                    value={co.value}>
                                                        {co.text}
                                                </MotifOption>
                                            ))}
                                        </MotifSelect>
                                    </MotifFormField>
                                </div>
                            </div>

                            <div className="row">
                                <div className="field col-md-9 font-weight-bold">Please select report types for the package (Required)</div>
                            </div>

                            <div className="row">
                                <div style={{ width: '100%', paddingLeft: '14px', paddingRight: '13px' }}>
                                    <section className="table-responsive rtable pt-0">
                                        <table>
                                            <tbody>
                                                <tr className="create-and-run-package-file-types-table-header-no-childrens">
                                                    <td>
                                                        <div className="field col-md-12">
                                                            <MotifFormField>
                                                                <MotifCheckbox
                                                                    type="checkbox"
                                                                    data-testid="chkbxConsolitedMappings"
                                                                    className="clientAuths-checkbox-space font-weight-normal"
                                                                    name="consolidatedMapping"
                                                                    id="consolidatedMapping"
                                                                    checked={checkedConsilidatedMappingCheckbox}
                                                                    onChange={(event) => {
                                                                        setConsolidatedMappingCheckbox(event.target.checked);
                                                                        handleFileSelection(fileTypes.ConsolidatedMappings, event.target.checked);
                                                                    }}
                                                                    disabled={isAuditOnlyClient}
                                                                    >
                                                                    Consolidated Mappings
                                                                </MotifCheckbox>
                                                            </MotifFormField>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="create-and-run-package-file-types-table-header-no-childrens">
                                                    <td>
                                                        <div className="field col-md-12">
                                                            <MotifFormField>
                                                                <MotifCheckbox
                                                                    type="checkbox"
                                                                    data-testid="chkbxREITTrialBalance"
                                                                    className="clientAuths-checkbox-space font-weight-normal"
                                                                    name="reitTrialBalance"
                                                                    id="reitTrialBalance"
                                                                    checked={checkedREITTrialBalanceCheckbox}
                                                                    onChange={(event) => {
                                                                        setREITTrialBalanceCheckbox(event.target.checked);
                                                                        handleFileSelection(fileTypes.REITTrialBalance, event.target.checked);
                                                                    }}
                                                                    disabled={isAuditOnlyClient}>
                                                                    REIT Trial Balance
                                                                </MotifCheckbox>
                                                            </MotifFormField>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="create-and-run-package-file-types-table-header-no-childrens">
                                                    <td>
                                                        <div className="field col-md-12">
                                                            <MotifFormField>
                                                                <MotifCheckbox
                                                                    type="checkbox"
                                                                    data-testid="chkbxPropertyTrialBalance"
                                                                    className="clientAuths-checkbox-space font-weight-normal"
                                                                    name="ropertyTrialBalance"
                                                                    id="propertyTrialBalance"
                                                                    checked={checkedPropertyTrialBalanceCheckbox}
                                                                    onChange={(event) => {
                                                                        setPropertyTrialBalanceCheckbox(event.target.checked);
                                                                        handleFileSelection(fileTypes.PropertyTrialBalance, event.target.checked);
                                                                    }}
                                                                    disabled={isAuditOnlyClient}
                                                                >
                                                                    Property Trial Balance
                                                                </MotifCheckbox>
                                                            </MotifFormField>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="create-and-run-report-package-file-type-header-with-childrens">
                                                    <td>
                                                        <div className="field col-md-12">
                                                            <div className="font-weight-normal ml-2" >
                                                                <h6 data-testid="TestSnapshot">Test Snapshot</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="create-and-run-package-file-types-table-header-no-childrens">
                                                    <td>
                                                        <div className="field col-md-12">
                                                            <table className="create-and-run-report-package-file-type-detail">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <MotifFormField>
                                                                                <MotifCheckbox
                                                                                    type="checkbox"
                                                                                    data-testid="chkbxOneExcelPerREITPerReportPeriod"
                                                                                    className="clientAuths-checkbox-space font-weight-normal"
                                                                                    name="oneExcelPerREITPerReportPeriod"
                                                                                    id="oneExcelPerREITPerReportPeriod"
                                                                                    checked={checkedOneExcelPerREITPerReportPeriodCheckbox}
                                                                                    onChange={(event) => {
                                                                                        setOneExcelPerREITPerReportPeriodCheckbox(event.target.checked);
                                                                                        handleFileSelection(fileTypes.TestSnapshotFileperREIT, event.target.checked);
                                                                                    }}
                                                                                    disabled={isAuditOnlyClient}
                                                                                >
                                                                                    One excel per REIT per report period
                                                                                </MotifCheckbox>
                                                                            </MotifFormField>
                                                                        </td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <MotifFormField>
                                                                                <MotifCheckbox
                                                                                    type="checkbox"
                                                                                    data-testid="chkbxConsolidatedExcelforAllSelectedREITs"
                                                                                    className="clientAuths-checkbox-space font-weight-normal"
                                                                                    name="consolidatedExcelforAllSelectedREITs"
                                                                                    id="consolidatedExcelforAllSelectedREITs"
                                                                                    checked={checkedConsolidatedExcelforAllSelectedREITsCheckbox}
                                                                                    onChange={(event) => {
                                                                                        setConsolidatedExcelforAllSelectedREITsCheckbox(event.target.checked);
                                                                                        handleFileSelection(fileTypes.TestSnapshotSingleExcelforselectedREITs, event.target.checked);
                                                                                    }}
                                                                                    disabled={isAuditOnlyClient}
                                                                                >
                                                                                    Consolidated excel for all selected REITs
                                                                                </MotifCheckbox></MotifFormField>
                                                                        </td>
                                                                        <td></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="create-and-run-report-package-file-type-header-with-childrens">
                                                    <td>
                                                        <div className="field col-md-12">
                                                            <div className="font-weight-normal ml-2" >
                                                                <h6 data-testid="PSQChecklists">PSQ Checklists</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="create-and-run-package-file-types-table-header-no-childrens">
                                                    <td>
                                                        <div className="field col-md-12">
                                                            <table className="create-and-run-report-package-file-type-detail">
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ width: '83%' }}>
                                                                            <MotifLabel
                                                                                className="font-weight-normal ml-2"
                                                                                htmlFor="downloadWorkDocForPSQChecklistWithAnswersOnly"
                                                                                aria-label="PSQ Checklists With Answers Only"
                                                                                data-testId="lblPSQChecklistsWithAnswersOnly"
                                                                            >
                                                                                With Answers Only
                                                                            </MotifLabel>
                                                                        </td>
                                                                        <td style={{ width: '11%' }}>
                                                                            <MotifFormField>
                                                                                <MotifCheckbox
                                                                                    type="checkbox"
                                                                                    data-testid="chkbxWordDocAnswersOnly"
                                                                                    className="clientAuths-checkbox-space font-weight-normal"
                                                                                    name="downloadWorkDocForPSQChecklistWithAnswersOnly"
                                                                                    id="downloadWorkDocForPSQChecklistWithAnswersOnly"
                                                                                    checked={checkedDownloadWorkDocForPSQChecklistWithAnswersOnlyCheckbox}
                                                                                    onChange={(event) => {
                                                                                        setDownloadWorkDocForPSQChecklistWithAnswersOnlyCheckbox(event.target.checked);
                                                                                        handleFileSelection(fileTypes.PSQChecklistsWithAnswersOnlyMSWord, event.target.checked);
                                                                                    }}
                                                                                    disabled={isAuditOnlyClient}
                                                                                >
                                                                                    Word Doc
                                                                                </MotifCheckbox>
                                                                            </MotifFormField>
                                                                        </td>
                                                                        <td style={{ width: '6%' }}>
                                                                            <MotifFormField>
                                                                                <MotifCheckbox
                                                                                    type="checkbox"
                                                                                    data-testid="chkbxPDFAnswersOnly"
                                                                                    className="clientAuths-checkbox-space font-weight-normal"
                                                                                    name="downloadPDFForPSQChecklistWithAnswersOnly"
                                                                                    id="downloadPDFForPSQChecklistWithAnswersOnly"
                                                                                    checked={checkedDownloadPDFForPSQChecklistWithAnswersOnlyCheckbox}
                                                                                    onChange={(event) => {
                                                                                        setDownloadPDFForPSQChecklistWithAnswersOnlyCheckbox(event.target.checked);
                                                                                        handleFileSelection(fileTypes.PSQChecklistsWithAnswersOnlyPDF, event.target.checked);
                                                                                    }}
                                                                                    disabled={isAuditOnlyClient}
                                                                                >
                                                                                    PDF
                                                                                </MotifCheckbox>
                                                                            </MotifFormField>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ width: '83%' }}>
                                                                            <MotifLabel
                                                                                className="font-weight-normal ml-2"
                                                                                htmlFor="downloadWorkDocForPSQChecklistWithAnswersAndComments"
                                                                                aria-label="PSQ Checklists With Answers and Comments"
                                                                                data-testId="lblPSQChecklistsWithAnswersAndComments"
                                                                            >
                                                                                With Answers And Comments
                                                                            </MotifLabel>
                                                                        </td>
                                                                        <td style={{ width: '11%' }}>
                                                                            <MotifFormField>
                                                                                <MotifCheckbox
                                                                                    type="checkbox"
                                                                                    data-testid="chkbxWordDocWithAnswers"
                                                                                    className="clientAuths-checkbox-space font-weight-normal"
                                                                                    name="downloadWorkDocForPSQChecklistWithAnswersAndComments"
                                                                                    id="downloadWorkDocForPSQChecklistWithAnswersAndComments"
                                                                                    checked={checkedDownloadWorkDocForPSQChecklistWithAnswersAndCommentsCheckbox}
                                                                                    onChange={(event) => {
                                                                                        setDownloadWorkDocForPSQChecklistWithAnswersAndCommentsCheckbox(event.target.checked);
                                                                                        handleFileSelection(fileTypes.PSQChecklistsWithAnswersandCommentsMSWord, event.target.checked);
                                                                                    }}
                                                                                    disabled={isAuditOnlyClient}
                                                                                >
                                                                                    Word Doc
                                                                                </MotifCheckbox>
                                                                            </MotifFormField>
                                                                        </td>
                                                                        <td style={{ width: '6%' }}>
                                                                            <MotifFormField>
                                                                                <MotifCheckbox
                                                                                    type="checkbox"
                                                                                    data-testid="chkbxPDFAnswersWithAnswers"
                                                                                    className="clientAuths-checkbox-space font-weight-normal"
                                                                                    name="downloadPDFForPSQChecklistWithAnswersAndComments"
                                                                                    id="downloadPDFForPSQChecklistWithAnswersAndComments"
                                                                                    checked={checkedDownloadPDFForPSQChecklistWithAnswersAndCommentsCheckbox}
                                                                                    onChange={(event) => {
                                                                                        setDownloadPDFForPSQChecklistWithAnswersAndCommentsCheckbox(event.target.checked);
                                                                                        handleFileSelection(fileTypes.PSQChecklistsWithAnswersandCommentsPDF, event.target.checked);
                                                                                    }}
                                                                                    disabled={isAuditOnlyClient}
                                                                                >
                                                                                    PDF
                                                                                </MotifCheckbox>
                                                                            </MotifFormField>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ width: '83%' }}>
                                                                            <MotifLabel
                                                                                className="font-weight-normal ml-2"
                                                                                htmlFor="downloadWorkDocForPSQChecklistWithoutAnswersOrComments"
                                                                                aria-label="PSQ Checklists Without Answers or Comments"
                                                                                data-testId="lblPSQChecklistsWithoutAnswersOrComments"
                                                                            >
                                                                                Without Answers or Comments
                                                                            </MotifLabel>
                                                                        </td>
                                                                        <td style={{ width: '11%' }}>
                                                                            <MotifFormField>
                                                                                <MotifCheckbox
                                                                                    type="checkbox"
                                                                                    data-testid="chkbxWordDocWithoutAnswers"
                                                                                    className="clientAuths-checkbox-space font-weight-normal"
                                                                                    name="downloadWorkDocForPSQChecklistWithoutAnswersOrComments"
                                                                                    id="downloadWorkDocForPSQChecklistWithoutAnswersOrComments"
                                                                                    checked={checkedDownloadWorkDocForPSQChecklistWithoutAnswersOrCommentsCheckbox}
                                                                                    onChange={(event) => {
                                                                                        setDownloadWorkDocForPSQChecklistWithoutAnswersOrCommentsCheckbox(event.target.checked);
                                                                                        handleFileSelection(fileTypes.PSQChecklistsWithoutAnswersorCommentsMSWord, event.target.checked);
                                                                                    }}
                                                                                    disabled={isAuditOnlyClient}
                                                                                >
                                                                                    Word Doc
                                                                                </MotifCheckbox>
                                                                            </MotifFormField>
                                                                        </td>
                                                                        <td style={{ width: '6%' }}>
                                                                            <MotifFormField>
                                                                                <MotifCheckbox
                                                                                    type="checkbox"
                                                                                    data-testid="chkbxPDFAnswersWithoutAnswers"
                                                                                    className="clientAuths-checkbox-space font-weight-normal"
                                                                                    name="downloadPDFForPSQChecklistWithoutAnswersOrComments"
                                                                                    id="downloadPDFForPSQChecklistWithoutAnswersOrComments"
                                                                                    checked={checkedDownloadPDFForPSQChecklistWithoutAnswersOrCommentsCheckbox}
                                                                                    onChange={(event) => {
                                                                                        setDownloadPDFForPSQChecklistWithoutAnswersOrCommentsCheckbox(event.target.checked);
                                                                                        handleFileSelection(fileTypes.PSQChecklistsWithoutAnswersorCommentsPDF, event.target.checked);
                                                                                    }}
                                                                                    disabled={isAuditOnlyClient}
                                                                                >
                                                                                    PDF
                                                                                </MotifCheckbox>
                                                                            </MotifFormField>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ width: '83%' }}>
                                                                            <MotifLabel
                                                                                className="font-weight-normal ml-2"
                                                                                htmlFor="downloadPSQChecklistAttachments"
                                                                                aria-label="Download PSQ Checklist Attachments"
                                                                                data-testId="lblDownloadPSQChecklistAttachments"
                                                                            >
                                                                                Include Checklist Attachments as separate files
                                                                            </MotifLabel>
                                                                        </td>
                                                                        <td style={{ width: '17%' }} colSpan="2">
                                                                            <MotifFormField>
                                                                                <MotifCheckbox
                                                                                    type="checkbox"
                                                                                    data-testid="chkbxAllFiles"
                                                                                    className="clientAuths-checkbox-space font-weight-normal"
                                                                                    name="downloadPSQChecklistAttachments"
                                                                                    id="downloadPSQChecklistAttachments"
                                                                                    disabled={!(checkedDownloadWorkDocForPSQChecklistWithAnswersOnlyCheckbox
                                                                                        || checkedDownloadPDFForPSQChecklistWithAnswersOnlyCheckbox
                                                                                        || checkedDownloadWorkDocForPSQChecklistWithAnswersAndCommentsCheckbox
                                                                                        || checkedDownloadPDFForPSQChecklistWithAnswersAndCommentsCheckbox
                                                                                        || checkedDownloadWorkDocForPSQChecklistWithoutAnswersOrCommentsCheckbox
                                                                                        || checkedDownloadPDFForPSQChecklistWithoutAnswersOrCommentsCheckbox
                                                                                        ) || isAuditOnlyClient
                                                                                    }
                                                                                    checked={checkedDownloadPSQChecklistAttachmentsCheckbox}
                                                                                    onChange={(event) => {
                                                                                        setDownloadPSQChecklistAttachmentsCheckbox(event.target.checked);
                                                                                        handleFileSelection(fileTypes.PSQChecklistsIncludeChecklistAttachmentsasSeparateFiles, event.target.checked);
                                                                                    }}
                                                                                   
                                                                                >
                                                                                    All File Types
                                                                                </MotifCheckbox>
                                                                            </MotifFormField>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="create-and-run-report-package-file-type-header-with-childrens">
                                                    <td>
                                                        <div className="field col-md-12">
                                                            <div className="font-weight-normal ml-2" >
                                                                <h6 data-testid="ARQCChecklists">ARQC Checklists</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="create-and-run-package-file-types-table-header-no-childrens">
                                                    <td>
                                                        <div className="field col-md-12">
                                                            <table className="create-and-run-report-package-file-type-detail">
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ width: '83%' }}>
                                                                            <MotifLabel
                                                                                className="font-weight-normal ml-2"
                                                                                htmlFor="downloadWorkDocForARQCChecklistWithAnswersOnly"
                                                                                aria-label="ARQC Checklists With Answers Only"
                                                                                data-testId="lblARQCChecklistsWithAnswersOnly"
                                                                            >
                                                                                With Answers Only
                                                                            </MotifLabel>
                                                                        </td>
                                                                        <td style={{ width: '11%' }}>
                                                                            <MotifFormField>
                                                                                <MotifCheckbox
                                                                                    type="checkbox"
                                                                                    data-testid="chkbxWordDocAnswersOnly"
                                                                                    className="clientAuths-checkbox-space font-weight-normal"
                                                                                    name="downloadWorkDocForARQCChecklistWithAnswersOnly"
                                                                                    id="downloadWorkDocForARQCChecklistWithAnswersOnly"
                                                                                    checked={checkedDownloadWorkDocForARQCChecklistWithAnswersOnlyCheckbox}
                                                                                    onChange={(event) => {
                                                                                        setDownloadWorkDocForARQCChecklistWithAnswersOnlyCheckbox(event.target.checked);
                                                                                        handleFileSelection(fileTypes.ARQChecklistsWithAnswersOnlyMSWord, event.target.checked);
                                                                                    }}
                                                                                >
                                                                                    Word Doc
                                                                                </MotifCheckbox>
                                                                            </MotifFormField>
                                                                        </td>
                                                                        <td style={{ width: '6%' }}>
                                                                            <MotifFormField>
                                                                                <MotifCheckbox
                                                                                    type="checkbox"
                                                                                    data-testid="chkbxPDFAnswersOnly"
                                                                                    className="clientAuths-checkbox-space font-weight-normal"
                                                                                    name="downloadPDFForARQCChecklistWithAnswersOnly"
                                                                                    id="downloadPDFForARQCChecklistWithAnswersOnly"
                                                                                    checked={checkedDownloadPDFForARQCChecklistWithAnswersOnlyCheckbox}
                                                                                    onChange={(event) => {
                                                                                        setDownloadPDFForARQCChecklistWithAnswersOnlyCheckbox(event.target.checked);
                                                                                        handleFileSelection(fileTypes.ARQChecklistsWithAnswersOnlyPDF, event.target.checked);
                                                                                    }}
                                                                                >
                                                                                    PDF
                                                                                </MotifCheckbox>
                                                                            </MotifFormField>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ width: '83%' }}>
                                                                            <MotifLabel
                                                                                className="font-weight-normal ml-2"
                                                                                htmlFor="downloadWorkDocForARQCChecklistWithAnswersAndComments"
                                                                                aria-label="ARQC Checklists With Answers and Comments"
                                                                                data-testId="lblARQCChecklistsWithAnswersAndComments"
                                                                            >
                                                                                With Answers And Comments
                                                                            </MotifLabel>
                                                                        </td>
                                                                        <td style={{ width: '11%' }}>
                                                                            <MotifFormField>
                                                                                <MotifCheckbox
                                                                                    type="checkbox"
                                                                                    data-testid="chkbxWordDocWithAnswers"
                                                                                    className="clientAuths-checkbox-space font-weight-normal"
                                                                                    name="downloadWorkDocForARQCChecklistWithAnswersAndComments"
                                                                                    id="downloadWorkDocForARQCChecklistWithAnswersAndComments"
                                                                                    checked={checkedDownloadWorkDocForARQCChecklistWithAnswersAndCommentsCheckbox}
                                                                                    onChange={(event) => {
                                                                                        setDownloadWorkDocForARQCChecklistWithAnswersAndCommentsCheckbox(event.target.checked);
                                                                                        handleFileSelection(fileTypes.ARQChecklistsWithAnswersandCommentsMSWord, event.target.checked);
                                                                                    }}

                                                                                >
                                                                                    Word Doc
                                                                                </MotifCheckbox>
                                                                            </MotifFormField>
                                                                        </td>
                                                                        <td style={{ width: '6%' }}>
                                                                            <MotifFormField>
                                                                                <MotifCheckbox
                                                                                    type="checkbox"
                                                                                    data-testid="chkbxPDFAnswersWithAnswers"
                                                                                    className="clientAuths-checkbox-space font-weight-normal"
                                                                                    name="downloadPDFForARQCChecklistWithAnswersAndComments"
                                                                                    id="downloadPDFForARQCChecklistWithAnswersAndComments"
                                                                                    checked={checkedDownloadPDFForARQCChecklistWithAnswersAndCommentsCheckbox}
                                                                                    onChange={(event) => {
                                                                                        setDownloadPDFForARQCChecklistWithAnswersAndCommentsCheckbox(event.target.checked);
                                                                                        handleFileSelection(fileTypes.ARQChecklistsWithAnswersandCommentsPDF, event.target.checked);
                                                                                    }}
                                                                                >
                                                                                    PDF
                                                                                </MotifCheckbox>
                                                                            </MotifFormField>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ width: '83%' }}>
                                                                            <MotifLabel
                                                                                className="font-weight-normal ml-2"
                                                                                htmlFor="downloadWorkDocForARQCChecklistWithoutAnswersOrComments"
                                                                                aria-label="ARQC Checklists Without Answers or Comments"
                                                                                data-testId="lblARQCChecklistsWithoutAnswersOrComments"
                                                                            >
                                                                                Without Answers or Comments
                                                                            </MotifLabel>
                                                                        </td>
                                                                        <td style={{ width: '11%' }}>
                                                                            <MotifFormField>
                                                                                <MotifCheckbox
                                                                                    type="checkbox"
                                                                                    data-testid="chkbxWordDocWithoutAnswers"
                                                                                    className="clientAuths-checkbox-space font-weight-normal"
                                                                                    name="downloadWorkDocForARQCChecklistWithoutAnswersOrComments"
                                                                                    id="downloadWorkDocForARQCChecklistWithoutAnswersOrComments"
                                                                                    checked={checkedDownloadWorkDocForARQCChecklistWithoutAnswersOrCommentsCheckbox}
                                                                                    onChange={(event) => {
                                                                                        setDownloadWorkDocForARQCChecklistWithoutAnswersOrCommentsCheckbox(event.target.checked);
                                                                                        handleFileSelection(fileTypes.ARQChecklistsWithoutAnswersorCommentsMSWord, event.target.checked);
                                                                                    }}
                                                                                >
                                                                                    Word Doc
                                                                                </MotifCheckbox>
                                                                            </MotifFormField>
                                                                        </td>
                                                                        <td style={{ width: '6%' }}>
                                                                            <MotifFormField>
                                                                                <MotifCheckbox
                                                                                    type="checkbox"
                                                                                    data-testid="chkbxPDFAnswersWithoutAnswers"
                                                                                    className="clientAuths-checkbox-space font-weight-normal"
                                                                                    name="downloadPDFForARQCChecklistWithoutAnswersOrComments"
                                                                                    id="downloadPDFForARQCChecklistWithoutAnswersOrComments"
                                                                                    checked={checkedDownloadPDFForARQCChecklistWithoutAnswersOrCommentsCheckbox}
                                                                                    onChange={(event) => {
                                                                                        setDownloadPDFForARQCChecklistWithoutAnswersOrCommentsCheckbox(event.target.checked);
                                                                                        handleFileSelection(fileTypes.ARQChecklistsWithoutAnswersorCommentsPDF, event.target.checked);
                                                                                    }}
                                                                                >
                                                                                    PDF
                                                                                </MotifCheckbox>
                                                                            </MotifFormField>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ width: '83%' }}>
                                                                            <MotifLabel
                                                                                className="font-weight-normal ml-2"
                                                                                htmlFor="chkbxDownloadARQCChecklistAttachments"
                                                                                aria-label="Include Checklist Attachments as separate files"
                                                                                data-testId="lblARQCChecklistsWithoutAnswersOrComments"
                                                                            >
                                                                                Include Checklist Attachments as separate files
                                                                            </MotifLabel>
                                                                        </td>
                                                                        <td style={{ width: '17%' }} colSpan="2">
                                                                            <MotifFormField>
                                                                                <MotifCheckbox
                                                                                    type="checkbox"
                                                                                    data-testid="chkbxAllFiles"
                                                                                    className="clientAuths-checkbox-space font-weight-normal"
                                                                                    name="downloadARQCChecklistAttachments"
                                                                                    id="downloadARQCChecklistAttachments"
                                                                                    disabled={!(checkedDownloadWorkDocForARQCChecklistWithAnswersOnlyCheckbox
                                                                                        || checkedDownloadPDFForARQCChecklistWithAnswersOnlyCheckbox
                                                                                        || checkedDownloadWorkDocForARQCChecklistWithAnswersAndCommentsCheckbox
                                                                                        || checkedDownloadPDFForARQCChecklistWithAnswersAndCommentsCheckbox
                                                                                        || checkedDownloadWorkDocForARQCChecklistWithoutAnswersOrCommentsCheckbox
                                                                                        || checkedDownloadPDFForARQCChecklistWithoutAnswersOrCommentsCheckbox)
                                                                                    }
                                                                                    checked={checkedDownloadARQCChecklistAttachmentsCheckbox}
                                                                                    onChange={(event) => {
                                                                                        setDownloadARQCChecklistAttachmentsCheckbox(event.target.checked);
                                                                                        handleFileSelection(fileTypes.ARQChecklistsIncludeChecklistAttachmentsasSeparateFiles, event.target.checked);
                                                                                    }}
                                                                                >
                                                                                    All File Types
                                                                                </MotifCheckbox>
                                                                            </MotifFormField>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="create-and-run-report-package-file-type-header-with-childrens">
                                                    <td>
                                                        <div className="field col-md-12">
                                                            <div className="font-weight-normal ml-2" >
                                                                <h6 data-testid="FinalDeliverableReport">Final Deliverable Report</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="create-and-run-package-file-types-table-header-no-childrens">
                                                    <td>
                                                        <div className="col-md-12">
                                                            <table className="create-and-run-report-package-file-type-detail">
                                                                <tbody>
                                                                    <tr >
                                                                        <td style={{ width: '83%' }}>
                                                                            <div style={{ width: '100%' }}>
                                                                                <FDRReportTypeReportItemList
                                                                                    availableReportItems={availableReportItems}
                                                                                    selectedItems={selectedItems}
                                                                                    setItemSelection={setItemSelection}
                                                                                    includePsqComments={includePsqComments}
                                                                                    setIncludePsqComments={setIncludePsqComments}
                                                                                    includeArqcComments={includeArqcComments}
                                                                                    setIncludeArqcComments={setIncludeArqcComments}
                                                                                    customFdrTemplates={customFdrTemplates}
                                                                                    handleTemplateSelect={handleTemplateSelect}
                                                                                    selectedTemplate={selectedTemplate} />
                                                                            </div>
                                                                        </td>
                                                                        <td style={{ width: '11%', verticalAlign: 'top' }}>
                                                                            <MotifFormField>
                                                                                <MotifCheckbox
                                                                                    type="checkbox"
                                                                                    data-testid="chkbxWordDocFDRTemplate"
                                                                                    className="clientAuths-checkbox-space font-weight-normal"
                                                                                    name="fdrTemplateWordDoc"
                                                                                    id="fdrTemplateWordDoc"
                                                                                    checked={checkedFdrTemplateWordDocCheckbox}
                                                                                    onChange={(event) => {
                                                                                        setFdrTemplateWordDocCheckbox(event.target.checked);
                                                                                        setFdrSelection("word", event.target.checked);
                                                                                    }}
                                                                                >
                                                                                    Word Doc
                                                                                </MotifCheckbox>
                                                                            </MotifFormField>
                                                                        </td>
                                                                        <td style={{ width: '6%', verticalAlign: 'top' }}>
                                                                            <MotifFormField>
                                                                                <MotifCheckbox
                                                                                    type="checkbox"
                                                                                    data-testid="chkbxPDFFDRTemplate"
                                                                                    className="clientAuths-checkbox-space font-weight-normal"
                                                                                    name="fdrTemplatePDF"
                                                                                    id="fdrTemplatePDF"
                                                                                    checked={checkedFDRTemplatePDFCheckbox}
                                                                                    onChange={(event) => {
                                                                                        setFDRTemplatePDFCheckbox(event.target.checked);
                                                                                        setFdrSelection("pdf", event.target.checked);
                                                                                    }}
                                                                                >
                                                                                    PDF
                                                                                </MotifCheckbox>
                                                                            </MotifFormField>
                                                                        </td>
                                                                    </tr>
                                                                    <tr >
                                                                        <td colSpan="3" >
                                                                            <div style={{ minHeight: '50px' }}></div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="create-and-run-package-file-types-table-header-no-childrens">
                                                    <td>
                                                        <div className="field col-md-12">
                                                            <MotifFormField>
                                                                <MotifCheckbox
                                                                    type="checkbox"
                                                                    data-testid="chkbxAllGeneralFiles"
                                                                    className="clientAuths-checkbox-space font-weight-normal"
                                                                    name="generalandOtherFiles"
                                                                    id="generalandOtherFiles"
                                                                    checked={checkedGeneralAndOtherFilesCheckbox}
                                                                    onChange={(event) => {
                                                                        setGeneralAndOtherFilesCheckbox(event.target.checked);
                                                                        handleFileSelection(fileTypes.GeneralandOtherFiles, event.target.checked);
                                                                    }}
                                                                    disabled={isAuditOnlyClient}

                                                                >
                                                                    General and Other Files
                                                                </MotifCheckbox>
                                                            </MotifFormField>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="create-and-run-package-file-types-table-header-no-childrens">
                                                    <td>
                                                        <div className="field col-md-12">
                                                            <MotifFormField>
                                                                <MotifCheckbox
                                                                    type="checkbox"
                                                                    data-testid="chkbxREITGeneralFiles"
                                                                    className="clientAuths-checkbox-space font-weight-normal"
                                                                    name="reitGeneralFiles"
                                                                    id="reitGeneralFiles"
                                                                    checked={checkedREITGeneralFilesCheckbox}
                                                                    onChange={(event) => {
                                                                        setREITGeneralFilesCheckbox(event.target.checked);
                                                                        handleFileSelection(fileTypes.REITGeneralFiles, event.target.checked);
                                                                    }}
                                                                    disabled={isAuditOnlyClient}

                                                                >
                                                                    REIT General Files
                                                                </MotifCheckbox>
                                                            </MotifFormField>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="create-and-run-package-file-types-table-header-no-childrens">
                                                    <td>
                                                        <div className="field col-md-12">
                                                            <MotifFormField>
                                                                <MotifCheckbox
                                                                    type="checkbox"
                                                                    data-testid="chkbxUserUploadedReport"
                                                                    className="clientAuths-checkbox-space font-weight-normal"
                                                                    name="userUploadedFinalReport"
                                                                    id="userUploadedFinalReport"
                                                                    checked={checkedUserUploadedFinalReportCheckbox}
                                                                    onChange={(event) => {
                                                                        setUserUploadedFinalReportCheckbox(event.target.checked);
                                                                        handleFileSelection(fileTypes.UserUploadedFinalReport, event.target.checked);
                                                                    }}
                                                                    disabled={isAuditOnlyClient}
                                                                >
                                                                    User Uploaded Final Report
                                                                </MotifCheckbox>
                                                            </MotifFormField>
                                                        </div>
                                                    </td>
                                                </tr>                                                
                                            </tbody>
                                        </table>
                                    </section>
                                </div>
                            </div>

                            <div className="row">
                                <div className="field col-md-5 mt-4">                                   
                                    <MotifFormField>
                                        <MotifCheckbox
                                            type="checkbox"
                                            data-testid="chkbxIncludeDiagnosticReport"
                                            className="clientAuths-checkbox-space font-weight-normal"
                                            name="includeDiagnosticReportChkb"
                                            id="includeDiagnosticReportChkb"
                                            checked={includeDiagnosticReport}
                                            onChange={(event) => {
                                                handleIncludeDiagnosticReportCheckboxChecked(event.target.checked);
                                            }}
                                        >
                                            Include Diagnostic Report with the lead sheet
                                        </MotifCheckbox>
                                    </MotifFormField>                                    
                                </div>
                             </div>

                            <div className="row">
                                <div className="field col-md-12">
                                    <MotifLabel
                                        htmlFor="switchGroupingReportExtract"
                                        aria-label={`Display grouping of report extract toggle button ${showGroupingofExtract ? 'on' : 'off'}`}
                                        style={{ display: 'inline-block', width: 'auto', marginRight: '1rem' }}>
                                        Enable grouping the report extract:
                                    </MotifLabel>
                                    <MotifFormField style={{ display: 'inline-block', width: 'auto' }} aria-hidden="true">
                                        <MotifToggleSwitch
                                            name="switchGroupingReportExtract"
                                            id="switchGroupingReportExtract"
                                            onChange={handleShowGroupingofExtract}
                                            data-testid="tgEnableGroup"
                                            checked={!!showGroupingofExtract} />
                                    </MotifFormField>
                                </div>
                            </div>
                            <div className="row">
                                <div className="field col-md-12">
                                    <GroupingofReportExtract
                                        reportExtractGroupByList={groupingofReportExtractList}
                                        showGroupingofExtract={showGroupingofExtract}
                                        handleAddLevelGroupBy={handleAddLevelGroupBy}
                                        handleDeleteLevelGroupBy={handleDeleteLevelGroupBy}
                                        handleGroupBySelection={handleGroupBySelection}
                                    />
                                </div>
                            </div>
                            <br />
                        </div>
                        :
                        <div style={{ minHeight: '70px' }}>
                            No REITs/Report Periods found for this client.
                        </div>
                    }
                </form>
            </MotifModalBody>
            <MotifModalFooter>
                <MotifButton type="submit"  className="right" data-testid="btnCreateRun" disabled={invalid || submitting || groupByErrorMessage.length>0 || reportTypesCount==0 
                                                                                                  || selectPeriodCount == 0 || selectReitCount == 0}
                    form="createAndRunReportPackage">
                    Create and Run
                </MotifButton>
                <MotifButton
                    variant="secondary"
                    type="button"
                    onClick={() => toggleShowCreateAndRunReportPackageModal()} className="right" data-testid="btnCancel"
                    form="createAndRunReportPackage">
                    Cancel
                </MotifButton>
                <div style={{
                    paddingLeft: 'inherit',
                    alignSelf: 'center'
                }}>
                    {groupByErrorMessage && groupByErrorMessage.length > 0 &&
                        <MotifErrorMessage>{groupByErrorMessage}</MotifErrorMessage>
                    }
                </div>
              
            </MotifModalFooter>
        </MotifModal>
    );
};

CreateAndRunReportPackageModal.propTypes = {
    showCreateAndRunReportPackageModal: PropTypes.bool,
    includePsqComments: PropTypes.bool,
    includeArqcComments: PropTypes.bool,
    showGroupingofExtract: PropTypes.bool,    
    reportExtractGroupByList: PropTypes.array,
    reits: PropTypes.array,
    availableReportItems: PropTypes.array,
    selectedTemplate: PropTypes.object,
    selectedItems: PropTypes.object,
    handleSubmit: PropTypes.func,
    toggleShowCreateAndRunReportPackageModal: PropTypes.func,
    handleAddLevelGroupBy: PropTypes.func,
    handleDeleteLevelGroupBy: PropTypes.func,
    handleGroupBySelection: PropTypes.func,
    handleCreateAndRunPackage: PropTypes.func,
    handleReportTypeSelectionChanged: PropTypes.func,
    handleShowGroupingofExtract: PropTypes.func,
    setItemSelection: PropTypes.func,
    setIncludePsqComments: PropTypes.func,
    setIncludeArqcComments: PropTypes.func,
    handleTemplateSelect: PropTypes.func,
    initialValues: PropTypes.object,
    handleFileSelection: PropTypes.func,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
    groupByErrorMessage: PropTypes.string,
    setFdrSelection: PropTypes.func,
    resetCheckboxes: PropTypes.bool,
    isAuditOnlyClient: PropTypes.bool,
    handleAllSelection: PropTypes.func, 
    checkedPeriods: PropTypes.array,
    checkedReits: PropTypes.array
};

export default reduxForm({ form: "createAndRunReportPackage", enableReinitialize: true })(CreateAndRunReportPackageModal);