import React from 'react';

/**
 * LoginPage container component
 * @extends {React.Component}
 */
export class LoginPage extends React.Component {
    /**
     * Creates a new LoginPage
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);
        //oidcUserManager.signinRedirect({ data: { origin: window.location.href } });
    }

    componentDidMount() {
        // This login page isn't really necessary. Just redirect to home page for automatic login.
        this.props.router.push(`/`);
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        return null;
    }
}

export default LoginPage;