import PropTypes from "prop-types";
import React from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { Header, Label, Button, Dropdown, Input } from "semantic-ui-react";
import * as formValidators from "../../scripts/formValidators";
import * as formHelpers from "../../scripts/formHelpers";
import ReactModal from "react-modal";
import $ from "jquery";
import { MotifIcon, MotifButton, MotifDropdown, MotifDropdownItem } from '@ey-xd/motif-react';

const ChecklistSendModal = ({ handleSubmit, handleClientFieldChanged, handleEyFieldChanged, showModal, showSendModal, isSendCheckListEmailEnabled, error, 
    handleDismissErrorMessage, clientEmailsRef, checklist }) => {
    const validateEmails = () => {
        let sendToFieldValue = $("#sendToField :input").val();

        if (sendToFieldValue !== "") {
            throw new SubmissionError({
                _error: "'" + sendToFieldValue + "' is not a valid email address."
            });
        }

        let eyTeamMemberFieldValue = $("#eyTeamMemberField :input").val();

        if (eyTeamMemberFieldValue !== "") {
            throw new SubmissionError({
                _error: "'" + eyTeamMemberFieldValue + "' is not a valid email address."
            });
        }
        
        return handleSubmit();
    };
    
    return (
        <ReactModal contentLabel="Send checklist" isOpen={showModal} className="react-modal" overlayClassName="react-modal-overlay">
            <Header content="Send checklist"/>
            <form onSubmit={handleSubmit(validateEmails)}>
                {formHelpers.showSubmissionError(error, handleDismissErrorMessage)}
                <Field
                    label="Send to (Multiple email addresses are supported if separated by a semi-colon)"
                    component={formHelpers.renderReactMultiEmail}
                    id="sendToField"
                    name="sendToField"
                    domRef={clientEmailsRef}
                    handleChange={handleClientFieldChanged}
                    emails={checklist && checklist.clientEmailTo
                        ? [...new Set(checklist.clientEmailTo.toLowerCase().split(";"))]
                        : []}
                    validate={[formValidators.multipleEmails, formValidators.maxLength255]} />
                <Field
                    label="EY team member (Multiple email addresses are supported if separated by a semi-colon)"
                    component={formHelpers.renderReactMultiEmail}
                    id="eyTeamMemberField"
                    name="eyTeamMemberField"
                    handleChange={handleEyFieldChanged}
                    emails={checklist && checklist.accountantEmailTo
                        ? [...new Set(checklist.accountantEmailTo.toLowerCase().split(";"))]
                        : []}
                    validate={[formValidators.multipleEmails, formValidators.maxLength255]} />
                <br/>
                <MotifButton variant="primary" className="right ml-2" type="submit"
                    disabled={!isSendCheckListEmailEnabled}>
                    Send
                </MotifButton>
                <MotifButton variant="secondary" className="right" type="button"
                    onClick={showSendModal}>
                    Cancel
                </MotifButton>
            </form>
        </ReactModal>
    );
};

ChecklistSendModal.propTypes = {
    showModal: PropTypes.bool,
    handleSubmit: PropTypes.func,
    handleClientFieldChanged: PropTypes.func,
    handleEyFieldChanged: PropTypes.func,
    isSendCheckListEmailEnabled: PropTypes.bool,
    showSendModal: PropTypes.func,
    error: PropTypes.func,
    handleDismissErrorMessage: PropTypes.func,
    clientEmailsRef: PropTypes.func,
    checklist: PropTypes.object
};

export default reduxForm({ form: "checklistsendmodal", enableReinitialize: true })(ChecklistSendModal);