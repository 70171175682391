import { pendingTask, begin, end } from 'react-redux-spinner';
import * as actions from './actionTypes';
import { fetchFromTrialBalanceApi as fetch } from './fetchFromApi';
import * as actionHelpers from '../scripts/actionHelpers';

/**
 * Fetch the trial balance adjustments by period ID
 * @param {number} periodId The ID of the report period with trial balance that have adjusted items.
 * @returns {funtion} A function that returns a Promise.
 */
export function getTrialBalanceAdjustmentsByPeriodId(periodId) {
    return function (dispatch) {
      
        let url = '/trialBalanceAdjustments/period?periodId=' + periodId;
        
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetch(url).then(response => {
            return response.json();
        }).then(trialBalanceAdjustments => {
            if (actionHelpers.isErrorResponse(trialBalanceAdjustments)) {
                if (trialBalanceAdjustments.errorCode === 'RESOURCE_NOT_FOUND') {
                    trialBalanceAdjustments = null;  // Allow 404 and dispatch success with null property
                } else {
                    return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_TB_ADJUSTMENTS_FAILURE, trialBalanceAdjustments);
                }
            }
            
            dispatch({ type: actions.LOAD_TB_ADJUSTMENTS_SUCCESS, trialBalanceAdjustments, [pendingTask]: end });
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_TB_ADJUSTMENTS_FAILURE, null, error);
        });
    };
}

/**
 * Fetch the trial balance items by trial balance ID
 * @param {number} trialBalanceId The ID of the trial balance.
 * @returns {funtion} A function that returns a Promise.
 */
export function getTrialBalanceItemsByTrialBalanceId(trialBalanceId) {
    return function (dispatch) {
      
        let url = '/trialBalances/items/' + trialBalanceId;
        
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetch(url).then(response => {
            return response.json();
        }).then(trialBalanceItems => {
            if (actionHelpers.isErrorResponse(trialBalanceItems)) {
                if (trialBalanceItems.errorCode === 'RESOURCE_NOT_FOUND') {
                    trialBalanceItems = null;  // Allow 404 and dispatch success with null property
                } else {
                    return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_TB_ITEMS_FAILURE, trialBalanceItems);
                }
            }

            dispatch({ type: actions.LOAD_TB_ITEMS_SUCCESS, trialBalanceItems, [pendingTask]: end });
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_TB_ITEMS_FAILURE, null, error);
        });
    };
}

/**
 * Process the trial balance item adjustments.
 * @param {any} adjustments The item adjustments
 * @returns {function} A Promise.
 */
export function applyAdjustments(adjustments){
    return function (dispatch) {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetch('/trialBalanceAdjustments',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: 'PUT',
                body: JSON.stringify(adjustments)
            }).then(response => {
                dispatch({ type: actions.TASK_END, [pendingTask]: end });
                return;
        });
    };
}