/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-multi-comp */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
  MotifButton,
  MotifIcon,
} from '@ey-xd/motif-react';

import { addAttribute } from '../../common/Attribute';
import { alertIcError24px } from '@ey-xd/motif-react/assets/icons';

const DeleteTemplateQuestionModal = ({
  showDeleteTemplateQuestionModal,
  hasChildQuestions,
  handleCancelDeleteTemplateQuestion,
  handleDeleteTemplateQuestion,
}) => {
  useEffect(() => {
    //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
    addAttribute("button[role = 'combobox']", 'type', 'button');
  });

  const HeaderWrapper = ({ children }) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
      }}
    >
      {children}
    </div>
  );

  const closeButtonProps = {
    title: 'Close Delete Template Question modal',
    ariaLabel: 'Close Template Delete Question modal',
    dataTestid: 'close',
  };

  return (
    <React.Fragment>
      <MotifModal
        variant="alt"
        show={showDeleteTemplateQuestionModal}
        onClose={handleCancelDeleteTemplateQuestion}
        data-testid="DeleteTemplateQuestionModal"
      >
        <MotifModalHeader
          style={{ display: '-webkit-box' }}
          data-testid="h3DeleteTemplateQuestion"
          closeButtonProps={closeButtonProps}
        >
          <HeaderWrapper>
            <MotifIcon style={{ color: '#F95D54' }} src={alertIcError24px} />
            <span className="ml-2">Delete Question</span>
          </HeaderWrapper>
        </MotifModalHeader>
        <MotifModalBody>
          <span
            className="checklistActionMsg mb-2"
            data-testid="spanDeleteConfirmation"
          >
            Are you sure you want to delete this question?
          </span>
          <br />
          {!hasChildQuestions && (
            <>
              <span data-testid="spanNote">
                <b>Note:</b>
              </span>
              <ol>
                <li className="mb-0">
                  This will remove the ability to add this question to any
                  template.
                </li>
                <li className="mb-0">
                  This will also delete the question from other template(s)
                  that's currently using it.
                </li>
                <li>
                  This will not remove the question from an existing checklist
                  that's currently using it.
                </li>
              </ol>
            </>
          )}

          {hasChildQuestions && (
            <>
              <span data-testid="spanNote">
                <b>Note:</b>
              </span>
              <ol>
                <li className="mb-0">
                  This will delete the questions under this question.
                </li>
                <li className="mb-0">
                  This will remove the ability to add this question/child
                  questions to any template.
                </li>
                <li className="mb-0">
                  This will also delete the question from other template(s)
                  that's currently using it.
                </li>
                <li>
                  This will not remove the question from an existing checklist
                  that's currently using it.
                </li>
              </ol>
            </>
          )}
         
        </MotifModalBody>
        <MotifModalFooter>
            <MotifButton
              variant="warn"
              className="right"
              data-testid="btnDelete"
              type="button"
              onClick={handleDeleteTemplateQuestion}
            >
              Delete
            </MotifButton>
            <MotifButton
              variant="secondary"
              className="right"
              data-testid="btnCancel"
              type="button"
              onClick={handleCancelDeleteTemplateQuestion}
            >
              Cancel
            </MotifButton>
          </MotifModalFooter>
      </MotifModal>
    </React.Fragment>
  );
};

DeleteTemplateQuestionModal.propTypes = {
  showDeleteTemplateQuestionModal: PropTypes.bool,
  hasChildQuestions: PropTypes.bool,
  handleCancelDeleteTemplateQuestion: PropTypes.bool,
  handleDeleteTemplateQuestion: PropTypes.func,
};

export default DeleteTemplateQuestionModal;
