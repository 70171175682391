import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Breadcrumbs from 'react-breadcrumbs';
import ErrorSummary from '../components/shared/ErrorSummary';
import Spinner from 'react-redux-spinner';
import ReactModal from 'react-modal';
import * as commonActions from "../actions/commonActions";
import Dimensions from 'react-dimensions';
import { Menu, Segment, Sidebar } from 'semantic-ui-react';
import { MotifBreadcrumb, MotifBreadcrumbItem, MotifIcon, MotifButton } from '@ey-xd/motif-react';
import { navigationIcClose24px } from '@ey-xd/motif-react/assets/icons';
import Notifications from "./notifications/Notifications";
import * as notificationActions from "../actions/notificationActions";
import ExternalFooter from '../components/ExternalFooter';

/**
 * ExternalMain container component
 * @extends {React.Component}
 */
export class ExternalMain extends React.Component {
    /**
     * Creates a new ExternalMain
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);
        this.handleDismissErrorMessage = this.handleDismissErrorMessage.bind(this);
        this.handleCloseNotifications = this.handleCloseNotifications.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.currentLocation != this.props.currentLocation) {
            this.props.commonActions.clearErrorResponse();
        }
        if (nextProps.routes !== this.props.routes || (nextProps.client !== this.props.client) || (nextProps.period !== this.props.period) || (nextProps.reit !== this.props.reit)) {
            this.handleTabPageLocation(nextProps.routes, nextProps);
        }
    }

    handleTabPageLocation(routes, props) {
        let tabTitle = '';
        let tabTitlesArray = [];
        let pathTitlesToCreate = [];
        let pathsThatShiftArray = [];
        routes.forEach((route, index) => {
            if (index == 0 || (index == routes.length - 1 && !route.path)) return;
            let initialPath = route.path.split('/')[0];
            let secondaryPath = route.path.split('/')[1];
            let currentTitle = '';
            if (route.name && !pathTitlesToCreate.includes(initialPath)) {
                currentTitle = route.name;
            } else {
                currentTitle = this.getRouteTitleString(initialPath, secondaryPath, props);
            }
            if (pathsThatShiftArray.includes(initialPath)) tabTitlesArray.shift();
            if (currentTitle !== '' && currentTitle !== undefined) tabTitlesArray.unshift(currentTitle);
        });
        tabTitlesArray.push('EY REITSuite');
        tabTitle = tabTitlesArray.join(' | ');
        window.document.title = tabTitle;
    }

    getRouteTitleString(path, secondaryPath, props) {
        switch (path) {
            case 'reits':
                return `${props.client.legalName}`;
            case 'checklists':
                return `${props.reit.reitName}`;
            case 'arqc':
                return `${path.toUpperCase()}`;
            case 'psq':
                return `${path.toUpperCase()}`;
        }
    }

    handleDismissErrorMessage() {
        this.props.commonActions.clearErrorResponse();
    }

    handleCloseNotifications(e) {
        e.preventDefault();
        this.props.notificationActions.toggleNotificationsDisplay();
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        const loadingModalStyle = {
            content: {
                backgroundColor: 'transparent',
                border: 'none',
                color: 'white',
                textAlign: 'center',
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '60%',
                overflow: 'none' // <-- This tells the modal to scroll
            }
        };

        const suppressErrorSummary = this.props.errorResponse && this.props.errorResponse.errorCode && (this.props.errorResponse.errorCode === "RESOURCE_NOT_FOUND" || this.props.errorResponse.errorCode === "UNKNOWN" || this.props.errorResponse.errorCode === "LINK_EXPIRED");
        return (
            <div style={{ height: '100%', minHeight: this.props.containerHeight - 84, paddingBottom: '2px', marginTop: '-1px' }}>
                <Sidebar.Pushable as={Segment}>
                    <Sidebar
                        as={Menu}
                        animation="overlay"
                        icon="labeled"
                        inverted
                        vertical
                        visible={this.props.showNotifications}
                        width="wide"
                        direction="right">
                        <div style={{ display: 'flex', justifyContent: "space-between" }}>
                            <div><h3>Notifications</h3></div>
                            <div style={{ display: 'flex' }}>
                                <MotifButton variant="text" className="notification-motif-text-button" onClick={this.handleCloseNotifications} title="Close sidebar">
                                    <div style={{ marginLeft: '14px' }} >
                                        <MotifIcon style={{ color: '#c4c4cd' }} src={navigationIcClose24px} />
                                    </div>
                                </MotifButton>
                            </div>
                        </div>
                        <Notifications />
                    </Sidebar>

                    <Sidebar.Pusher>
                        <Segment basic>
                            <div className="container-fluid" style={{ minHeight: this.props.containerHeight - 84 }}>
                                <div style={{ minHeight: this.props.containerHeight - 60 }}>
                                {this.props.currentLocation &&
                                    this.props.currentLocation.toLowerCase().indexOf("/ext/arqc/arqcrecord") < 0 &&
                                    this.props.currentLocation.toLowerCase().indexOf("/ext/psq/psqrecord") < 0 &&
                                    <div className={this.props.isPermExternalApp ?
                                        "row header breadcrumbs d-flex justify-content-between" 
                                        : "row breadcrumbs d-flex justify-content-between"}>
                                    <div className="col-md-12">
                                        <Breadcrumbs
                                            routes={this.props.routes}
                                            params={this.props.params}
                                            client={this.props.client}
                                            reit={this.props.reit}
                                            period={this.props.period}
                                            displayMissing={false}
                                            separator=""
                                            wrapperElement={MotifBreadcrumb}
                                            itemElement={MotifBreadcrumbItem}
                                        />
                                    </div>
                                    </div>
                                }
                                <div style={{ minHeight: '24px' }}>
                                    {(this.props.errorResponse && !this.props.hideMainErrorSummary && !suppressErrorSummary && <ErrorSummary errorResponse={this.props.errorResponse} handleDismiss={this.handleDismissErrorMessage} />) || null}
                                </div>
                                {this.props.children}
                                </div>
                                <ExternalFooter></ExternalFooter>
                            </div>                           
                        </Segment>
                    </Sidebar.Pusher>
                </Sidebar.Pushable>               
                <ReactModal isOpen={this.props.pendingTasks > 0} contentLabel="" style={loadingModalStyle} overlayClassName="spinner-overlay">
                    <img src="/images/loading-spinner-black.gif" height="20px" /> ...loading...please wait...
                </ReactModal>
            </div>
        );
    }
}

ExternalMain.propTypes = {
    children: PropTypes.element,
    routes: PropTypes.array,
    params: PropTypes.object,
    client: PropTypes.object,
    reit: PropTypes.object,
    period: PropTypes.object,
    currentLocation: PropTypes.string,
    hideMainErrorSummary: PropTypes.bool,
    errorResponse: PropTypes.object,
    pendingTasks: PropTypes.number,
    containerHeight: PropTypes.number.isRequired,
    commonActions: PropTypes.object.isRequired,
    showNotifications: PropTypes.bool.isRequired,
    notificationActions: PropTypes.object.isRequired,
    isPermExternalApp: PropTypes.bool
};

/**
 * Determines whether the main error summary should be hidden
 * @param {Object} props The properties
 * @returns {boolean} True if the main error summary should be hidden, otherwise false
 */
function shouldHideMainErrorSummary(props) {
    if (!props) {
        return false;
    }

    if (!props.route || !props.route.hideMainErrorSummary) {
        if (props.children && props.children.props) {
            return shouldHideMainErrorSummary(props.children.props);
        } else {
            return false;
        }
    } else {
        return props.route.hideMainErrorSummary;
    }
}

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties belonging to this component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    const result = {
        client: state.client,
        reit: state.reit,
        period: state.period,
        routes: ownProps.children.props.routes,
        params: ownProps.children.props.params,
        currentLocation: ownProps.children.props.location.pathname,
        hideMainErrorSummary: shouldHideMainErrorSummary(ownProps.children.props),
        errorResponse: state.errorResponse,
        pendingTasks: state.pendingTasks,
        showNotifications: state.showNotifications,
        isPermExternalApp: ownProps.isPermExternalApp
    };
    return result;
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
        notificationActions: bindActionCreators(notificationActions, dispatch)
    };
}

export default Dimensions({
    getHeight: function (element) {
        return window.innerHeight - 72;
    },
    getWidth: function (element) {
        let widthOffset = window.innerWidth < 680 ? 0 : 47;
        return window.innerWidth - widthOffset;
    }
})(connect(mapStateToProps, mapDispatchToProps)(ExternalMain));