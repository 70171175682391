import PropTypes from "prop-types";
import React from "react";
import ScopedServicesRow from "./ScopedServicesRow";
import { MotifButton, MotifModal, MotifModalHeader, MotifModalBody, MotifModalFooter } from '@ey-xd/motif-react';

const ScopedServicesModal = ({ services, showServicesModal, handleSave, handleCancel, periodId, lookupTypes, handleServiceSelected, isBulkProcess }) => {
    return (
        <MotifModal show={showServicesModal} onClose={handleCancel} variant="alt">
            <MotifModalHeader>
                {isBulkProcess ? 'Update Services Scope' : 'Services'}
            </MotifModalHeader>
            <MotifModalBody>
                {
                    isBulkProcess ? (
                        <p>Please select services for selected REIT(s) and report period.<b> And note that any report period that is 'Complete' or 'Closed to Reporting' will be reopened to update the services scope.</b></p>
                    ):(
                        <p>Please select services for current quarter</p>
                    )
                }
                 <div>
                    <table><tbody>
                        {lookupTypes.services &&
                            lookupTypes.services
                                .filter(svc => svc.serviceID != 0)
                                .map(service =>
                                    <ScopedServicesRow
                                        key={service.serviceDescription}
                                        service={service.serviceDescription}
                                        selectedServices={services}
                                        handleServiceSelected={handleServiceSelected}
                                    />
                                )}
                    </tbody></table>
                </div>
            </MotifModalBody>
            <MotifModalFooter>
                <MotifButton variant="primary" className="right" type="button"
                    onClick={handleSave} data-key={periodId}>
                    Save</MotifButton>
                <MotifButton variant="secondary" className="right" type="button"
                    onClick={handleCancel}>
                    Cancel</MotifButton>
            </MotifModalFooter>
        </MotifModal>
    );
};

ScopedServicesModal.propTypes = {
    services: PropTypes.array.isRequired,
    showServicesModal: PropTypes.bool,
    handleSave: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    periodId: PropTypes.number.isRequired,
    handleServiceSelected: PropTypes.func.isRequired,
    isBulkProcess: PropTypes.bool
};

export default ScopedServicesModal;