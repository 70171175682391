import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as trialBalanceActions from '../../actions/trialBalanceActions';
import * as Constants from '../../constants/other';
import store from "../../store/configureStore";

/**
 * Container component used for uploading trial balance files
 */
export default class UploadQueueWatcher extends React.Component {
    /**
     * Creates a new TrialBalanceUploadPage
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);

        this.processLoop = this.processLoop.bind(this);
        this.processUploadQueue = this.processUploadQueue.bind(this);
    }

    processLoop() {
        this.processUploadQueue();
        const self = this;
        window.setTimeout(self.processLoop, 2000);
    }

    processUploadQueue() {
        const state = store.getState();
        const uploadProcesses = state.uploadProcesses;
        const uploadQueue = state.uploadQueue;

        let currentQueueSize = 0;
        let currentProcesses = uploadProcesses || 0;

        if (currentProcesses >= Constants.MAX_UPLOAD_PROCESSES) {
            return;
        }

        if (Array.isArray(uploadQueue)) {
            currentQueueSize = uploadQueue.length;
        }

        if (currentQueueSize === 0) {
            return;
        }

        let userId = state.authentication.currentUser;

        let item = uploadQueue[0];
        trialBalanceActions.uploadTrialBalanceWithProgress(item.file, item.fileName, item.uploadId, userId)(store.dispatch);
    }

    /**
        * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        this.processLoop();
        return null;
    }
}

UploadQueueWatcher.propTypes = {
    store: PropTypes.object
};