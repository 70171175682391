import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { MotifIconButton, MotifIcon } from "@ey-xd/motif-react";
import { imageIcEdit24px, actionIcDelete24px } from '@ey-xd/motif-react/assets/icons';


/**
 * Presentation component used for rendering a download link to the trial balance file.
 */
export default class RelatedPropertiesRow extends React.Component {
    /**
     * Creates a new TrialBalanceDownloadLink
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);

        this.onEditClick = this.onEditClick.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
    }

    onEditClick(e) {
        e.preventDefault();
        this.props.handleEditClick(this.props.property.id);
    }

    onDeleteClick(e) {
        e.preventDefault();
        this.props.handleDeleteClick(this.props.property.id);
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        return (
            <tr>
                <td>{this.props.property.name}</td>
                <td>{this.props.property.address}</td>
                <td>{this.props.property.type}</td>
                <td>                    
                    <MotifIconButton aria-label="Edit this Property" type="button"
                        onClick={this.onEditClick}>
                        <MotifIcon src={imageIcEdit24px} title="Edit this Property" />
                    </MotifIconButton>
                    <MotifIconButton aria-label="Delete this Property" type="button"
                        onClick={this.onDeleteClick}>
                        <MotifIcon src={actionIcDelete24px} title="Delete this Property" />
                    </MotifIconButton>

                </td>
            </tr>
        );
    }
}

RelatedPropertiesRow.propTypes = {
    property: PropTypes.object,
    handleEditClick: PropTypes.func.isRequired,
    handleDeleteClick: PropTypes.func.isRequired
};