import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Router, Route, IndexRoute, Switch, Redirect } from 'react-router';
import {
  appInsights,
  initUserData,
  setUserId,
  clearUserId,
} from '@ey/react-telemetry-library';
import withAppInsights from '@ey/react-telemetry-library';
import App from './App';
import ExternalApp from './ExternalApp';
import PermExternalApp from './PermExternalApp';
import AssetTestPage from '../containers/reits/AssetTestPage';
import NotFoundPage from './NotFoundPage';
import LoginPage from '../containers/LoginPage';
import ClientsPage from '../containers/clients/ClientsPage';
import EditClientPage from '../containers/clients/EditClientPage';
import ClientPage from '../containers/clients/ClientPage';
import ClientsExternalPage from '../containers/clients/ClientsExternalPage';
import ChecklistExternalContainer from '../containers/checklistDeliveryCalendar/ChecklistExternalContainer';
import PSQChecklistExternalContainer from '../containers/checklistDeliveryCalendar/PSQChecklistExternalContainer';
import ARQCChecklistExternalContainer from '../containers/checklistDeliveryCalendar/ARQCChecklistExternalContainer';
import ClientLoader from '../containers/clients/ClientLoader';
import ReitLoader from '../containers/reits/ReitLoader';
import ReitPage from '../containers/reits/ReitPage';
import ReportPeriodLoader from '../containers/reits/ReportPeriodLoader';
import TrialBalanceUploadPage from '../containers/tb/TrialBalanceUploadPage';
import ReportPage from '../containers/reports/ReportPage';
import EditTrsContainer from '../containers/reits/EditTrsContainer';
import EditSecuritiesSnapshotContainer from '../containers/reits/EditSecuritiesSnapshotContainer';
import PropertiesContainer from '../containers/reits/PropertiesContainer';
import IncomeTestPage from '../containers/reits/IncomeTestPage';
import ArqcChecklistPage from '../containers/reits/ARQCChecklistContainer';
import ArqcChecklistRecord from '../containers/reits/ArqcChecklistRecord';
import DistributionTestContainer from '../containers/reits/DistributionTestContainer';
import PsqChecklistPage from '../containers/reits/PSQChecklistContainer';
import PsqChecklistRecord from '../containers/reits/PsqChecklistRecord';
import ExternalArqcChecklistPage from '../containers/reits/ExternalArqcChecklistPage';
import ReitsExternalPage from '../containers/reits/ReitsExternalPage';
import ChecklistsExternalPage from '../containers/reits/ChecklistsExternalPage';
import ExternalPsqChecklistPage from '../containers/reits/ExternalPsqChecklistPage';
import CallbackPage from '../containers/CallbackPage';
import FileManagementPage from '../containers/fileManagement/FileManagementPage';
import ReitFileManagementPage from '../containers/fileManagement/ReitFileManagementPage';
import AdminAuthPage from '../containers/auth/AdminAuthPage';
import UnauthorizedUserContainer from '../containers/unauthorize/UnauthorizedUserContainer';
import ExternalUnauthorizedUserContainer from '../containers/unauthorize/ExternalUnauthorizedUserContainer';
// import ContactUsersPage from "../containers/contactUsers/ContactUsersPage";
import MappingsPage from '../containers/tb/MappingsPage';
import ScheduleChecklistContainer from '../containers/checklistDeliveryCalendar/ScheduleChecklistContainer';
import ManageChecklistContainer from '../containers/checklistDeliveryCalendar/ManageChecklistContainer';
import CustomChecklistTemplateContainer from '../containers/customChecklistQuestions/CustomChecklistTemplateContainer';
import CustomFdrTemplateContainer from '../containers/customFdrTemplate/CustomFdrTemplateContainer';
import ChartOfAccountsManagerContainer from '../containers/chartOfAccountsManager/ChartOfAccountsManagerContainer';
import ChartAssignmentContainer from '../containers/chartOfAccountsManager/ChartAssignmentContainer';
import BulkReportManagementContainer from '../containers/bulkReportManagement/BulkReportManagementContainer';
import DefaultFdrTemplatesContainer from '../containers/customFdrTemplate/DefaultFdrTemplatesContainer';
import PsqCustomChecklistTemplateContainer from '../containers/psqCustomChecklistTemplate/PsqCustomChecklistTemplateContainer';
import PsqChecklistTemplatePreview from '../containers/psqCustomChecklistTemplate/PsqChecklistTemplatePreview';
import BulkProcessManagementContainer from '../containers/bulkProcessManagement/BulkProcessManagementContainer';
import BulkRollForwardContainer from '../containers/bulkRollForward/BulkRollForwardContainer';
import LogoffPage from '../containers/LogoffPage';
import CustomQuestionsPage from '../containers/reits/CustomQuestionsPage';
import * as stringHelpers from '../scripts/stringHelpers';
import * as actions from '../actions/actionTypes';
import * as authenticationActions from '../actions/authenticationActions';
import store from '../store/configureStore';

import { MsalProvider, MsalAuthenticationTemplate } from '@azure/msal-react';
import {
  PublicClientApplication,
  EventType,
  InteractionType,
} from '@azure/msal-browser';
import { msalConfig, loginRequest } from '../auth/authConfig';
import { CustomNavigationClient } from '../auth/NavigationClient';

const pca = new PublicClientApplication(msalConfig);

/**
 * The Root component renders the Router and connects everything to the store
 * @extends {Component}
 */
class Root extends Component {
  /**
   * Creates a new Root
   * @constructor
   * @param {Object} props The component properties
   * @param {Object} context The component context
   */
  constructor(props, context) {
    super(props, context);

    this.lastPathname = '';
    this.userType = '';
    this.userEmail = '';

    const navigationClient = new CustomNavigationClient(props.history);
    pca.setNavigationClient(navigationClient);

    // pca.addEventCallback(message => {
    //     if (message.eventType === EventType.LOGIN_SUCCESS) {
    //         console.log('LOGIN_SUCCESS: ', message);
    //         const { payload } = message;
    //         // Get idToken(JWT) from the payload.
    //         store.dispatch({ type: actions.SET_ID_TOKEN, idToken: payload.idToken });

    //         props.authenticationActions.setCurrentUser(payload.idTokenClaims);
    //     }
    // });

    // window.msalInstanceRef = pca;

    function handleResponse(response, msalInstance) {
      console.log('RESPONSE:', response);
      // if (response !== null) {
      //     msalInstance.setActiveAccount(response.account);
      // } else {
      //     const currentAccounts = msalInstance.getAllAccounts();
      //     if (currentAccounts.length === 0) {
      //         // no accounts signed-in, attempt to sign a user in
      //         msalInstance.loginRedirect(loginRequest).then((res) => {
      //             if (res) {
      //                 console.log('Logged in: ', res);
      //                 msalInstance.setActiveAccount(res.account);
      //             }
      //         });

      //     } else if (currentAccounts.length > 0) {
      //         msalInstance.setActiveAccount(currentAccounts[0]);
      //         console.log('xx:', msalInstance.getActiveAccount())
      //     }
      // }

      return true;
    }

    pca.handleRedirectPromise().then((res) => handleResponse(res, pca));

    // Before use, you must provide the library REACT_APP_INSTRUMENTATIONKEY on file .env
    if (process.env.REACT_APP_INSTRUMENTATIONKEY !== 'local') {
      // Mandatory function for Usage Statistics
      initUserData();

      // Listen for page changes
      props.history.listen((location) => {
        const pathname = location.pathname;

        // Sometimes this history.listen event fires twice, so ensure we don't fire twice if path hasn't changed
        if (pathname !== this.lastPathname) {
          this.lastPathname = pathname;

          // Are we on a client page? (internal or external)
          const isClientInPath =
            pathname.includes('/client/') || pathname.includes('/ext/reits/');

          // Retry up to 5 times because Client api may have not responded fast enough after listen event fires
          let retryCount = 5;
          let trackPageViewTimeout;

          const attemptTrackPageView = () => {
            clearTimeout(trackPageViewTimeout);
            retryCount--;

            // Check if we are on a Client page, and optionally add clientName to metrics
            const splitPath = pathname.split('/');
            const clientIdInPath =
              this.props.authenticationScope == 'external'
                ? splitPath[3]
                : splitPath[2];
            const clientIdInProps =
              this.props.client && this.props.client.id
                ? this.props.client.id.toString()
                : null;

            if (
              ((!isClientInPath || clientIdInPath == clientIdInProps) &&
                this.userEmail) ||
              retryCount == 0
            ) {
              const appInsightData = {
                name: location.pathname,
                properties: {
                  'user-type': this.userType,
                  'user-guid': stringHelpers.stringToHash(this.userEmail),
                  'user-email': this.userEmail,
                  'app-id': 'EY REITSuite',
                },
              };

              // Optionally add client-name and engagement-id if on a Client page
              if (isClientInPath && clientIdInProps) {
                appInsightData.properties['client-name'] =
                  this.props.client.name;
                appInsightData.properties['engagement-id'] =
                  this.props.client.engagementNumber;
              }

              // Log page change to App Insights
              // (ignore "EY REITSuite")
              if (location.pathname !== 'EY REITSuite') {
                appInsights.trackPageView(appInsightData);
              }
            } else {
              // Retry
              trackPageViewTimeout = setTimeout(
                () => attemptTrackPageView(),
                1000,
              );
            }
          };

          // Trigger the function that will retry 5 times to ensure we have given enough time to fetch Client Info
          attemptTrackPageView();
        }
      });
    }

    this.getRoutes = this.getRoutes.bind(this);
    this.getClientName = this.getClientName.bind(this);
    this.getReitName = this.getReitName.bind(this);
    this.getPeriodName = this.getPeriodName.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentUser !== this.props.currentUser) {
      const userEmail = nextProps.currentUser;
      this.userEmail = userEmail;

      // Store the email domain in userType
      if (userEmail.includes('@')) {
        this.userType = userEmail.split('@')[1];
      }

      if (process.env.REACT_APP_INSTRUMENTATIONKEY !== 'local') {
        // Set the User ID (email address) of the authenticated user
        setUserId(userEmail);
      }
    }
  }

  getNewClientText() {
    return 'New Client';
  }

  getClientName() {
    return (
      (this.props.store.getState().client &&
        this.props.store.getState().client.name) ||
      '...'
    );
  }

  getReitName() {
    return (
      (this.props.store.getState().reit &&
        this.props.store.getState().reit.reitName) ||
      '...'
    );
  }

  getPeriodName() {
    return (
      (this.props.store.getState().period &&
        this.props.store.getState().period.reportPeriodDescription) ||
      '...'
    );
  }

  getRoutes() {
    return (
      <Route>
        <Route path="/external/" component={ExternalApp}>
          <Route
            path="arqc/:checklistId"
            component={ExternalArqcChecklistPage}
          />
          <Route path="psq/:checklistId" component={ExternalPsqChecklistPage} />
          <Route path="logoff" component={LogoffPage} />
        </Route>
        <Route path="/ext" component={PermExternalApp} name="Dashboard">
          <IndexRoute component={ChecklistExternalContainer} />
          <Route path="psq" name="PSQ">
            <IndexRoute component={PSQChecklistExternalContainer} />
            <Route
                path="psqRecord/:internalChecklistId"
                component={ExternalPsqChecklistPage}
                staticName={true}
                name="PSQ Record"
            />
            </Route>
            <Route path="arqc" name="ARQC">
            <IndexRoute component={ARQCChecklistExternalContainer} />
            <Route
                path="arqcRecord/:internalChecklistId"
                component={ExternalArqcChecklistPage}
                staticName={true}
                name="ARQC Record"
            />
        </Route>
        <Route
            path="unauthorized"
            component={ExternalUnauthorizedUserContainer}
            name="Access Denied"
        />
        </Route>
        <Route path="/auth" component={CallbackPage} />
        <Route path="/" component={App} name="Dashboard">
          <Route path="login" component={LoginPage} />
          <Route
            path="adminauth"
            component={AdminAuthPage}
            name="System Administrators"
          />
          <Route
            path="unauthorized"
            component={UnauthorizedUserContainer}
            name="Access Denied"
          />
          {/* <Route path="contactUsers" component={ContactUsersPage} /> */}
          <IndexRoute component={ClientsPage} />
          <Route path="clients" component={ClientsPage} />
          <Route
            path="client/edit"
            component={EditClientPage}
            staticName={true}
            getDisplayName={this.getNewClientText}
          />
          <Route
            path="client/:clientId"
            name="Client"
            component={ClientLoader}
            staticName={true}
            getDisplayName={this.getClientName}
          >
            <IndexRoute component={ClientPage} />
            <Route path="edit" component={EditClientPage} name="Client Settings" />
            <Route
              path="checklistManagement"
              component={ScheduleChecklistContainer}
              name="Checklist Management"
            />
            <Route
              path="manageChecklist"
              component={ManageChecklistContainer}
              name="Manage Checklist"
            />
            <Route path="customPsqTemplate" name="Custom PSQ Template">
              <IndexRoute component={CustomChecklistTemplateContainer} />
              <Route
                path="manageQuestions"
                component={CustomQuestionsPage}
                name="Manage Questions"
              />
              <Route
                path="template"
                component={PsqCustomChecklistTemplateContainer}
                name="Create Template"
              />
              <Route
                path="template/:templateId"
                component={PsqCustomChecklistTemplateContainer}
                name="Edit Template"
                staticName={true}
              />
              <Route
                path="template/:templateId/preview"
                component={PsqChecklistTemplatePreview}
                name="Template Preview"
                staticName={true}
              />
            </Route>
            <Route path="customFdrTemplate" name="Custom FDR Template">
              <IndexRoute component={CustomFdrTemplateContainer} />
              <Route
                path="assignReits"
                component={DefaultFdrTemplatesContainer}
                name="Assign Defaults"
              />
            </Route>
            
            <Route
              path="bulkReportManagement"
              component={BulkReportManagementContainer}
              name="Bulk Report Management">                
            </Route>
            <Route
                path="bulkProcessManagement"
                component={BulkProcessManagementContainer}
                name="Bulk Process Management">                
            </Route>
            <Route
                path="bulkRollForward"
                component={BulkRollForwardContainer}
                name="Bulk Roll Forward">                
            </Route>
            <Route
              path="chartOfAccountsManager"
              name="Chart of Accounts Manager"
            >
              <IndexRoute component={ChartOfAccountsManagerContainer} />
              <Route
                path="assignCharts"
                component={ChartAssignmentContainer}
                name="Chart Assignment"
              />
            </Route>
            <Route
              path="reit/:reitId"
              component={ReitLoader}
              staticName={true}
              getDisplayName={this.getReitName}
            >
              <IndexRoute component={ReitPage} />
              <Route
                path="files"
                component={ReitFileManagementPage}
                showTabNav={false}
                hideMainErrorSummary={true}
                staticName={true}
                name={'File Management'}
              />
              <Route
                path="period/:periodId"
                component={ReportPeriodLoader}
                showTabNav={true}
                staticName={true}
                getDisplayName={this.getPeriodName}
              >
                <Route
                  path="uploadtb/:tbType"
                  component={TrialBalanceUploadPage}
                  showTabNav={true}
                  hideMainErrorSummary={true}
                  staticName={true}
                  name="TB Upload"
                />
                <Route
                  path="report"
                  component={ReportPage}
                  showTabNav={true}
                  name="Final Report"
                />
                <Route
                  path="trs"
                  component={EditTrsContainer}
                  showTabNav={true}
                  name="TRS"
                />
                <Route
                  path="securitiesSnapshot"
                  component={EditSecuritiesSnapshotContainer}
                  name="Securities"
                />
                <Route
                  path="properties"
                  component={PropertiesContainer}
                  showTabNav={true}
                  name="Properties"
                />
                <Route
                  path="mappings"
                  component={MappingsPage}
                  showTabNav={true}
                  name="Client Account Mappings"
                />
                <Route
                  path="assettest"
                  component={AssetTestPage}
                  showTabNav={true}
                  name="Asset Test"
                />
                <Route
                  path="incometest"
                  component={IncomeTestPage}
                  showTabNav={true}
                  name="Income Test"
                />
                <Route
                    path="distributiontest"
                    component={DistributionTestContainer}
                    showTabNav={true}
                    name="Distribution Test"
                />
                <Route
                 path="arqc"
                 name="ARQC"
                 showTabNav={true}
                >
                    <IndexRoute component={ArqcChecklistPage} />
                    <Route
                     path="arqcRecord/:arqcChecklistId"
                     component={ArqcChecklistRecord}
                     showTabNav={false}
                     name="ARQC Record"
                    />
                </Route>              
                <Route
                 path="psq"
                 name="PSQ"
                 showTabNav={true}
                >
                    <IndexRoute component={PsqChecklistPage} />
                    <Route
                     path="psqRecord/:psqChecklistId"
                     component={PsqChecklistRecord}
                     showTabNav={false}
                     name="PSQ Record"
                />
                </Route>
                <Route
                  path="files"
                  component={FileManagementPage}
                  showTabNav={false}
                  hideMainErrorSummary={true}
                  staticName={true}
                  name="File Management"
                />
              </Route>
            </Route>
          </Route>
          <Route path="*" component={NotFoundPage} />
        </Route>
      </Route>
    );
  }

  render() {
    const { store, history } = this.props;

    return (
      <Provider store={store}>
        <MsalProvider instance={pca}>
          <Router history={history} routes={this.getRoutes()} />
        </MsalProvider>
      </Provider>
      // <Provider store={store} >
      //     <OidcProvider store={store} userManager={oidcUserManager}>
      //         <Router history={history} routes={this.getRoutes()} />
      //     </OidcProvider>
      // </Provider>
    );
  }
}

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state) {
  return {
    authenticationScope: state.authenticationScope,
    client: state.client,
    currentUser: state.authentication.currentUser,
  };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
  return {
    authenticationActions: bindActionCreators(authenticationActions, dispatch),
  };
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  client: PropTypes.object,
  user: PropTypes.object,
  authenticationScope: PropTypes.string,
  currentUser: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
