import React from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, ColumnGroup, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import Dimensions from 'react-dimensions';
import FormattedCurrency from "../shared/FormattedCurrency";
import { toPercentage } from "../../common/NumberFormatting";
import { MotifToggleSwitch, MotifButton, MotifTextLink } from '@ey-xd/motif-react';

// Fixed column widths for first 4 columns.
const CHART_WIDTH = 150;
const CAC_NUMBER_WIDTH = 150;
const CAC_NAME_WIDTH = 200;
const TEST_ATTR_WIDTH = 300;
const FIXED_WIDTHS = [CHART_WIDTH, CAC_NUMBER_WIDTH, CAC_NAME_WIDTH, TEST_ATTR_WIDTH];
const FIXED_COLUMN_COUNT = FIXED_WIDTHS.length;
const FIXED_COLUMNS_WIDTH = FIXED_WIDTHS.reduce((a,b) => a + b, 0);

/**
 * TrialBalanceSummaryTable container component
 * @extends {React.Component}
 */

 export class TrialBalanceSummaryTable extends React.Component {
    constructor(props, context) {
        super(props, context);
    }
    
    calculateWidths(columnNames, containerWidth)
    {
        const availableVariableWidth = containerWidth - FIXED_COLUMNS_WIDTH;

        let variableColumnWidth =
            availableVariableWidth / (columnNames.length - FIXED_COLUMN_COUNT);
        
        variableColumnWidth = Math.max(variableColumnWidth, 150);
        variableColumnWidth = Math.min(variableColumnWidth, 200) | 0;

        return columnNames
            .map((_, index) => FIXED_WIDTHS[index] || variableColumnWidth);
    }

    getColumns(rows, columnNames, propertyHeaders, widths, canEdit) {
        const { showTrialBalanceAdjustmentsModal } = this.props;

        const commonColumnGenerator = function (propertyKey, headerText, width, cellStyle = {}) {
            const header = <Cell>{headerText}</Cell>;

            const cellGetter = ({ rowIndex, ...props }) =>
                <Cell {...props} style={cellStyle}>{rows[rowIndex][propertyKey]}</Cell>;

            return (<Column
                fixed={true}
                width={width}
                header={header}
                cell={cellGetter} />);
        };


        const amountColumnGenerator = function (rowCellGetter, headerText, width, canEdit, subheaderText = null) {
            const headerStyle = {lineHeight : 1, overflowWrap : 'anywhere'};
            
            const header =
                <Cell className="tright">
                    <p style={headerStyle}>{headerText}</p>
                    <p style={headerStyle}>{subheaderText}</p>
                </Cell>;

            const cellGetter = function ({ rowIndex, ...props }) {
                const cell = rowCellGetter(rows[rowIndex]);
                const { trialBalanceItemID, amount, isAdjusted, isAdjustable } = cell;

                const roundedAmount =
                    Math.sign(amount) * Math.round(Math.abs(amount));

                let value;

                if (roundedAmount < 0) {
                    value = roundedAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    value = "($" + value.replace('-', '') + ")";
                }
                else {
                    value = "$" + roundedAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }

                if (isAdjusted) {
                    value = "*" + value;
                }

                const cellStyle = isAdjustable ? { cursor: 'pointer' } : { color: '#5d5d66' };

                if (isAdjusted) {
                    cellStyle.fontWeight = 'bold';
                }                

                //If adjustable and canEdit(Report period is not closed) flags are true
                if (isAdjustable && canEdit) {
                    return (<Cell {...props} className="tright" style={cellStyle}>                        
                        <MotifTextLink data-id={trialBalanceItemID}
                            data-propname={headerText}                            
                            onClick={showTrialBalanceAdjustmentsModal}>{value}</MotifTextLink>
                    </Cell>)
                }
                else {
                    return (<Cell {...props} className="tright table-secondary" style={cellStyle}>
                        <div>
                            {value}
                        </div>
                    </Cell>)
                }
            }

            return <Column fixed={false} width={width} header={header} cell={cellGetter} />;
        }

        let columnIndex = 0;

        const cols = [
            commonColumnGenerator('chartName', 'Chart', widths[columnIndex++]),
            commonColumnGenerator('clientAccountNumber', 'Client Account Number', widths[columnIndex++]),
            commonColumnGenerator('clientAccountName', 'Client Account Name', widths[columnIndex++]),
            commonColumnGenerator(
                'reitTestingAttributeDescription',
                'REIT Testing Attribute',
                widths[columnIndex++],
                { fontSize: '90%', lineHeight: 1 })
        ];

        const periodTotalHeader = <Cell className="tright">Period Total</Cell>;

        const periodTotalCellGetter =
            function ({ rowIndex, ...props }) {
                const value = rows[rowIndex].periodTotalCell.amount;

                return <Cell
                    {...props}
                    className="tright">
                    <FormattedCurrency
                        value={value}
                        currency="USD"
                        minimumFractionDigits={0}
                        maximumFractionDigits={0} />
                </Cell>;
            };

        const periodTotalColumn =
            <Column
                fixed={false}
                width={widths[columnIndex++]}
                header={periodTotalHeader}
                cell={periodTotalCellGetter} />

        cols.push(periodTotalColumn);

        if (columnNames[5] === 'REIT') {
            cols.push(amountColumnGenerator(row => row.reitCell, 'REIT', widths[columnIndex++], canEdit));
        }

        propertyHeaders.forEach((ph, index) => {
            cols.push(amountColumnGenerator(row => row.propertyCells[index], ph.propertyName, widths[columnIndex++], canEdit, toPercentage(ph.percentageOfOwnership)));
        });

        return cols;
    }

     calculateHeaderHeight(propNames) {
         const longestPropNameLength =
             Math.max(0, ...propNames.map(pn => pn.length));

         // 16 characters per line is the most we can expect in 150px width,
         // so divide by 15 to get required lines.
         const requiredLines = Math.ceil(longestPropNameLength / 15) + 4;

         return requiredLines * 15;
     }

    render() {
        if (this.props.containerWidth && this.props.trialBalanceSummary !== null) {
            const {trialBalanceSummary} = this.props;
            const rows = trialBalanceSummary.rows;
            
            const columnNames = [
                'Chart',
                'Client Account Number',
                'Client Account Name',
                'REIT Testing Attribute',
                'Period Total'];

            if (typeof trialBalanceSummary.reitTrialBalanceID === 'number') {
                columnNames.push('REIT');
            }
            
            columnNames.push(...trialBalanceSummary.propertyHeaders.map(ph => ph.propertyName));

            const widths =
                this.calculateWidths(columnNames, this.props.containerWidth);
            
            const tableWidth =
                Math.min(
                    widths.reduce((a, b) => a + b, 0),
                    this.props.containerWidth);
            
            
            
            return (
                <div>                   
                    <div className="tright" style={{paddingBottom : '1rem'}}>                        
                        <MotifButton variant="secondary" className="right mb-3" type="button"
                            title="Export to Excel"
                            onClick={this.props.handleDownloadTrialBalanceSummary}>
                            Export to Excel
                        </MotifButton>
                    </div>
                    <Table
                        rowHeight={60}
                        rowsCount={rows.length}
                        width={tableWidth}
                        groupHeaderHeight={50}
                        height={this.props.containerHeight}
                        headerHeight={this.calculateHeaderHeight(trialBalanceSummary.propertyHeaders.map(ph => ph.propertyName || ''))}
                        className="fdt-table">
                        <ColumnGroup fixed={true} header={<Cell className="fdt-column-group-header">Overview of Uploaded Trial Balances for {trialBalanceSummary.reitName} for {trialBalanceSummary.reportPeriodDescription}</Cell>} className="fdt-column-group">
                            {this.getColumns(rows, columnNames, trialBalanceSummary.propertyHeaders, widths, this.props.canEdit)}
                        </ColumnGroup>
                    </Table>

                </div>
            );
        }

        return (
            <table className="table-responsive rtable">
                <tbody>
                    <tr>
                        <th>Empty</th>
                    </tr>
                </tbody>
            </table>
        );
    }
}

TrialBalanceSummaryTable.propTypes = {
    trialBalanceSummary: PropTypes.object,
    handleHideZeroBalanceRows: PropTypes.func.isRequired,
    hideZeroBalanceRows: PropTypes.bool,
    handleDownloadTrialBalanceSummary: PropTypes.func.isRequired,
    showTrialBalanceAdjustmentsModal: PropTypes.func.isRequired,
    containerWidth: PropTypes.number.isRequired,
    containerHeight: PropTypes.number.isRequired,
    canEdit: PropTypes.number.isRequired,
    tbMode: PropTypes.number.isRequired
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps Existing properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    return {
    };
}

export default Dimensions({
    getHeight: function (element) {
        return window.innerHeight;
    },
    getWidth: function (element) {
        let widthOffset = window.innerWidth < 680 ? 0 : 47;
        return window.innerWidth - widthOffset;
    }
})(connect(mapStateToProps)(TrialBalanceSummaryTable));