import React from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

import { withMsal } from "@azure/msal-react";

/**
 * CallbackPage container component
 * @extends {React.Component}
 */
class CallbackPage extends React.Component {
    /**
     * Creates a new CallbackPage
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);
        this.state = {
            message: "Redirecting..."
        };
    }

    render() {
        return (
            <div className="m-3">
                <h5>{this.state.message}</h5>
            </div>
        );
    }
}

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties belonging to this component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    return {
        children: ownProps.children,
    };
}

CallbackPage.propTypes = {
    children: PropTypes.element,
};

CallbackPage.contextTypes = {
    router: PropTypes.object
};

export default connect(mapStateToProps)(withMsal(CallbackPage));