import React from "react";
import PropTypes from "prop-types";
import CustomQuestionListRow from "./CustomQuestionListRow";

const CustomQuestionList = ({ customQuestionList, handleEditCustomQuestionListRow, toggleShowDeleteCustomQuestionModal }) => {
    return (
        <React.Fragment>
            <div className="row mt-3">
                <div className="col">
                    <section className="table-responsive rtable">
                        <table>
                            <thead>
                                <tr>
                                    <th className="text-nowrap">Question ID</th>
                                    <th className="text-nowrap">Question Text</th>
                                    <th className="text-nowrap">Help Text</th>
                                    <th className="text-nowrap">Answer Choice</th>
                                    <th className="tcenter">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {customQuestionList.map(item => <CustomQuestionListRow key={item.defaultUserDefinedQuestionID}
                                    customQuestionListRow={item}
                                    handleEditCustomQuestionListRow={handleEditCustomQuestionListRow}
                                    toggleShowDeleteCustomQuestionModal={toggleShowDeleteCustomQuestionModal} />)}
                            </tbody>
                        </table>
                        {(customQuestionList && customQuestionList.length === 0 && <div><h4>No Custom Questions are currently available for this Client.</h4></div>)
                            || null}
                    </section>
                </div>
            </div>
        </React.Fragment>
    );
};

CustomQuestionList.propTypes = {
    customQuestionList: PropTypes.array.isRequired
};

export default CustomQuestionList;