import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as commonActions from "../../actions/commonActions";
import * as checklistActions from "../../actions/checklistActions";
import * as periodActions from "../../actions/periodActions";
import * as authActions from "../../actions/authActions";
import * as trsActions from "../../actions/trsActions";
import * as serviceActions from "../../actions/servicesActions";
import * as fileActions from '../../actions/fileActions';
import * as formValidators from "../../scripts/formValidators";
import ChecklistNavigation from "../../components/reits/ChecklistNavigation";
import ArqcChecklistSummary from "../../components/reits/ArqcChecklistSummary";
import ChecklistCounters from "../../components/reits/ChecklistCounters";
import { Segment, Button, Modal, Header, Icon, Grid } from 'semantic-ui-react';
import ChecklistCompleteModal from "../../components/reits/ChecklistCompleteModal";
import ArqcCreateChecklistModal from "../../components/reits/ArqcCreateChecklistModal";
import ArqcChecklistDetailsForm from "../../components/reits/ArqcChecklistDetailsForm";
import ChecklistPageBase from "./ChecklistPageBase";
import ReactModal from 'react-modal';
const uuidv4 = require('uuid/v4');
import * as reitActions from "../../actions/reitActions";
import * as reportActions from '../../actions/reportActions';
import * as customQuestionActions from "../../actions/customQuestionActions";
import { Button as BootstrapButton, Dropdown as BootstrapDropdown, ButtonGroup, DropdownButton } from 'react-bootstrap';
import { MotifIcon, MotifButton, MotifDropdown, MotifDropdownItem } from '@ey-xd/motif-react';
import { navigationIcArrowDropDown24px } from '@ey-xd/motif-react/assets/icons';
import { MotifToggleSwitch } from '@ey-xd/motif-react';
import * as customChecklistTemplateActions from '../../actions/customChecklistTemplateActions';
import DeleteChecklistDetailGridRowModal from '../../components/reits/DeleteChecklistDetailGridRowModal';
import ShowCommentsTable from "../../components/customChecklistTemplate/ShowCommentsTable";
import ShowAttachmentTable from '../../components/customChecklistTemplate/ShowAttachmentTable';
import ShowDiscrepanciesTable from '../../components/customChecklistTemplate/ShowDiscrepanciesTable';
import * as  checklistCountRequest from '../../constants/checklistNavigationTabs';
/**
 * ArqcChecklistRecord container component
 * @extends {React.Component}
 */
export class ArqcChecklistRecord extends ChecklistPageBase {
  /**
    * Creates a new ArqcChecklistRecord
    * @constructor
    * @param {Object} props The component properties
    * @param {Object} context The component context
    */
  constructor(props, context) {
    super(props, context);

    this.setActiveItem = this.setActiveItem.bind(this);
    this.isEngagementAdminOrSysAdmin = this.isEngagementAdminOrSysAdmin.bind(this);
    this.handleDismissErrorMessage = this.handleDismissErrorMessage.bind(this);
    this.populateMissingTaxableReitSubsidiaries = this.populateMissingTaxableReitSubsidiaries.bind(this);
    this.downloadChecklist = this.downloadChecklist.bind(this);
    this.state.showAllQuestions = true;
    this.state.showUnansweredQuestions = false;
    this.state.canEdit = true;
    this.state.tbMode = 1;
    this.state.checklistDropdownOpen = false;
    // This represents whether to set Checklist record as current record(based on Checklist Id provided in Route Parameter)
    this.state.setChecklistRecordAsCurrentRecord = true;

    this.state.selectedAttachmentId = null;
    this.state.selectedCommentId = null;
    this.state.showDeleteChecklistGridRowModal = false;
    this.state.gridColumnState = [];
    this.state.showDetails = true; // Will always be true as the checklist is displayed by default
    this.handleGridColumnStateChange = this.handleGridColumnStateChange.bind(this);
    this.handleGridChanges = this.handleGridChanges.bind(this);
    this.state.questionComments = [];
    this.state.attachmentDetails = [];
    this.state.discrepanciesDetail = [];
    this.state.deleteFor = null;
    this.handleGridRowDeleteById = this.handleGridRowDeleteById.bind(this);
    this.handleDeleteAttachment =
      this.handleDeleteAttachment.bind(this);
    this.toggleShowChecklistGridRowDelete = this.toggleShowChecklistGridRowDelete.bind(this);
    this.deleteAttachment = this.deleteAttachment.bind(this);
    this.deleteComment = this.deleteComment.bind(this);
  }

  /**
  * Download the checklist
  */
  downloadChecklist(withAnswer) {
    const filename = `${this.props.reit.reitName}_${this.props.period.reportPeriodDescription.replace(' ', '')}_ARQC_Checklist.docx`
    this.props.commonActions.clearErrorResponse();
    this.props.reportActions.downloadChecklist('reitName=' + this.props.reit.reitName + ',periodDesc=' + this.props.period.reportPeriodDescription, this.props.periodId, 'ARQC', this.props.arqcActiveChecklist.checklistID, withAnswer, filename);
  }

  /**
   * Invoked immediately after mounting occurs
   * @returns {undefined}
   */
  componentDidMount() {
    this.props.serviceActions.fetchServicesByPeriod(this.props.periodId);
    this.props.checklistActions.fetchArqcChecklistById(this.props.arqcChecklistId)
      .then(() => this.getChecklistCount()); // For performance optimization
    this.props.trsActions.fetchTrsByPeriod(this.props.periodId);
    this.props.reitActions.fetchReitLookupTypes();
    periodActions.fastFetchPeriodById(this.props.periodId).then((period) => {
      if (period && period.trialBalanceModeID === 2) {
        this.setState({ tbMode: 2 });
      }
    });

    // Set the period if it doesn't exist
    if (!this.props.period) {
      this.props.periodActions.fetchPeriodById(this.props.periodId);
    }
    else {
      this.setCanEdit(this.props.period);
    }
  }

  componentWillUnmount() {
    this.props.checklistActions.clearDeletedChecklist();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.arqcActiveChecklist !== nextProps.arqcActiveChecklist) {

      let checklist = nextProps.arqcActiveChecklist;
      this.setState({ checklist: checklist, initialValues: this.getArqcDetailsInitialValues(checklist, false) }, this.populateMissingTaxableReitSubsidiaries);

      // set disabled sections
      if (nextProps.arqcActiveChecklist.content !== undefined
        && nextProps.arqcActiveChecklist.content.length > 0) {
        this.disableSections(checklist);
      }

      if (this.state.activeItem == null
        && nextProps.arqcActiveChecklist.content !== undefined
        && nextProps.arqcActiveChecklist.content.length > 0) {
        for (let i = 0; i < nextProps.arqcActiveChecklist.content.length; i++) {
          if (this.state.disabledSections.find(x => x === nextProps.arqcActiveChecklist.content[i].id) === undefined) {
            this.setState({ activeItem: nextProps.arqcActiveChecklist.content[0] });
            break;
          }
        }
      }
    }

    if (this.props.taxableReitSubsidiaries !== nextProps.taxableReitSubsidiaries) {
      this.setState({ taxableReitSubsidiaries: nextProps.taxableReitSubsidiaries }, this.populateMissingTaxableReitSubsidiaries);
    }

    // Populate previous answers on active checklist
    if (this.props.previousArqcChecklist !== nextProps.previousArqcChecklist && this.props.arqcActiveChecklist) {
      this.props.checklistActions.setActiveChecklist(this.props.arqcActiveChecklist, nextProps.previousArqcChecklist);
    }

    if (nextProps.arqcActiveChecklist && (!this.props.arqcActiveChecklist || !('mappedPreviousAnswers' in nextProps.arqcActiveChecklist))) {
      if (this.props.arqcActiveChecklist !== nextProps.arqcActiveChecklist && this.props.previousArqcChecklist) {
        this.props.checklistActions.setActiveChecklist(nextProps.arqcActiveChecklist, this.props.previousArqcChecklist);
      }
    }

    // Set Can Edit based on period status
    if (nextProps.period !== this.props.period) {
      this.setCanEdit(nextProps.period);
    }

    // Refresh attachment details table post addition/deletion of attachments
    if (this.props.attachmentDetails !== nextProps.attachmentDetails) {
      if (this.props.arqcActiveChecklist && this.props.arqcActiveChecklist !== null && this.props.arqcActiveChecklist.content.length > 0) {
        const updatedAttachmentDetails = nextProps.attachmentDetails.map((attachment) => {
          const updatedQuestionText = this.getUpdatedQuestionTitleBasedOnChecklistSectionQuestionId({ ...this.props.arqcActiveChecklist }, attachment.questionID);

          return {
            ...attachment,
            questionText: updatedQuestionText === '' ? attachment.questionText : updatedQuestionText
          };
        });

        this.setState({ attachmentDetails: updatedAttachmentDetails });
      }
    }

    // Refresh comments details table post addition/deletion of attachments
    if (this.props.questionComments !== nextProps.questionComments) {
      if (this.props.arqcActiveChecklist && this.props.arqcActiveChecklist !== null && this.props.arqcActiveChecklist.content.length > 0) {
        const updatedCommentsDetails = nextProps.questionComments.map((comment) => {
          const updatedQuestionText = this.getUpdatedQuestionTitleBasedOnChecklistSectionQuestionId({ ...this.props.arqcActiveChecklist }, comment.checklistSectionQuestionID);

          return {
            ...comment,
            questionText: updatedQuestionText === '' ? comment.questionText : updatedQuestionText
          };
        });

        this.setState({ questionComments: updatedCommentsDetails });
      }
    }
  }

  setGridColumnState() {
    let localState = '';
    if (this.state.activeItem === 'Comments') {
      localState = 'REITSuiteShowCommentsColumnState';
    }
    else if (this.state.activeItem === 'Attachments') {
      localState = 'REITSuiteShowAttachmentColumnState';
    }
    else if (this.state.activeItem === 'Discrepancies') {
      localState = 'REITSuiteShowDiscrepanyColumnState';
    }
    var columnState = localStorage.getItem(localState);
    if (columnState) {
      this.setState({ gridColumnState: JSON.parse(columnState) });
    }
    else {
      this.setState({ gridColumnState: [{ colId: '-1' }] });
    }
  }

  /**
  * Reset AG-Grid column state
  * @returns {undefined}
  */
  resetGridColumnState() {
    let localState = '';
    if (this.state.activeItem === 'Comments') {
      localState = 'REITSuiteShowCommentsColumnState';
    }
    else if (this.state.activeItem === 'Attachments') {
      localState = 'REITSuiteShowAttachmentColumnState';
    }
    else if (this.state.activeItem === 'Discrepancies') {
      localState = 'REITSuiteShowDiscrepanyColumnState';
    }
    localStorage.removeItem(localState);
    this.setState({ gridColumnState: [] });
  }

  /**
  * Handle Grid column state chagne
  * @returns {undefined}
  */
  handleGridColumnStateChange(columnState) {
    this.setState({ gridColumnState: columnState });
  }

  handleGridChanges(gridRowData) {
    let rowData = [];

    switch (this.state.activeItem) {
      case 'Comments': {
        rowData = gridRowData && gridRowData.length && gridRowData.map(x => {
          return {
            snapshotSectionName: x.snapshotSectionName,
            questionText: x.questionText,
            commentText: x.commentText,
            commentedBy: x.commentedBy,
            dateCommented: x.dateCommented,
          }
        });
        break;
      }
      case 'Attachments': {
        rowData = gridRowData && gridRowData.length && gridRowData.map(x => {
          return {
            snapshotSectionName: x.snapshotSectionName,
            questionText: x.questionText,
            attachedFileName: x.attachedFileName,
            uploadedBy: x.uploadedBy,
            dateUploaded: x.dateUploaded,
          }
        });
        break;
      }
      case 'Discrepancies': {
        rowData = gridRowData && gridRowData.length && gridRowData.map(x => {
          return {
            sectionName: x.sectionName,
            questionText: x.questionText,
            discrepancyMessage: x.discrepancyMessage,
          }
        });
        break;
      }
    }

    this.setState({ gridCurrentState: rowData });
  }

  populateMissingTaxableReitSubsidiaries() {
    let trses = Object.assign([], this.state.taxableReitSubsidiaries);

    if (!Array.isArray(trses)) {
      trses = [];
    }

    let changed = false;

    if (this.state.initialValues && this.state.initialValues.propertyAmenities) {
      const propertyAmenities = this.state.initialValues.propertyAmenities;

      for (let i = 0; i < propertyAmenities.length; i++) {
        const item = propertyAmenities[i];
        if (item.taxableReitSubsidiary && !trses.some(x => x.name === item.taxableReitSubsidiary.label)) {
          trses.push({ name: item.taxableReitSubsidiary.label });
          changed = true;
        }
      }

      if (changed) {
        this.setState({ taxableReitSubsidiaries: trses });
      }
    }
  }

  handleDismissErrorMessage() {
    this.props.commonActions.clearFormErrors('arqccreatechecklistmodal');
  }

  setCanEdit(period) {
    const isEnabled = periodActions.isReportingEnabled(period);
    this.setState({ canEdit: isEnabled });
  }

  setActiveItem(e, { name }) {
    const item = this.props.arqcActiveChecklist.content.find(
      x => x.title === name,
    );

    if (item === undefined && name) {
      this.setState({ activeItem: name, isSubNavigation: true });
    }
    else if (this.state.disabledSections.find(x => x === item.id) === undefined) {
      this.setState({ activeItem: item, isSubNavigation: false });
    }


  }

  getChecklistCount() {
    this.getAttachmentDetails();
    this.getCommentsDetails();

    this.props.checklistActions.fetchChecklistDiscrepancies(this.props.clientId, this.props.arqcChecklistId);

    this.props.checklistActions
      .fetchChecklistNavCounts(
        this.props.clientId,
        this.props.arqcChecklistId,
        checklistCountRequest.ChecklistCountRequest
      )
      .then((data) => {
        this.setState({ counters: data });
      });
  }

  getAttachmentDetails() {
    this.props.checklistActions.fetchAttachmentDetail(this.props.clientId,
      this.props.arqcChecklistId, null)
      .then(() => {
        if (this.props.arqcActiveChecklist && this.props.arqcActiveChecklist !== null && this.props.arqcActiveChecklist.content.length > 0) {
          const updatedAttachmentDetails = this.props.attachmentDetails.map((attachment) => {
            const updatedQuestionText = this.getUpdatedQuestionTitleBasedOnChecklistSectionQuestionId({ ...this.props.arqcActiveChecklist }, attachment.questionID);

            return {
              ...attachment,
              questionText: updatedQuestionText === '' ? attachment.questionText : updatedQuestionText
            };
          });

          this.setState({ attachmentDetails: updatedAttachmentDetails });
        }
      });
  }

  getCommentsDetails() {
    this.props.checklistActions.fetchQuestionComments(this.props.clientId, this.props.arqcChecklistId)
      .then(() => {
        if (this.props.arqcActiveChecklist && this.props.arqcActiveChecklist !== null && this.props.arqcActiveChecklist.content.length > 0) {
          const updatedCommentsDetails = this.props.questionComments.map((comment) => {
            const updatedQuestionText = this.getUpdatedQuestionTitleBasedOnChecklistSectionQuestionId({ ...this.props.arqcActiveChecklist }, comment.checklistSectionQuestionID);

            return {
              ...comment,
              questionText: updatedQuestionText === '' ? comment.questionText : updatedQuestionText
            };
          });

          this.setState({ questionComments: updatedCommentsDetails });
        }
      });
  }

  handleGridRowDeleteById(id) {
    let activeTab = this.state.activeItem;
    if (this.state.activeItem === "Attachments") {
      this.setState({ selectedAttachmentId: id });
    } else if (this.state.activeItem === "Comments") {
      this.setState({ selectedCommentId: id });
    } else {
      activeTab = "Attachments";
      this.setState({ selectedAttachmentId: id });
    }
    this.setState({ deleteFor: activeTab });
    this.toggleShowChecklistGridRowDelete();
  }

  toggleShowChecklistGridRowDelete() {
    const showToggle = !this.state.showDeleteChecklistGridRowModal;
    this.setState({
      showDeleteChecklistGridRowModal: showToggle,
    });
  }

  deleteAttachment() {
    const clientId = this.props.clientId;
    this.props.checklistActions
      .deleteAttachment(
        this.state.selectedAttachmentId,
        clientId
      )
      .then((data) => {
        this.props.checklistActions.fetchAttachmentDetail(this.props.clientId,
          this.props.arqcChecklistId, null);

        this.props.checklistActions
          .fetchChecklistNavCounts(
            this.props.clientId,
            this.props.arqcChecklistId,
            checklistCountRequest.ChecklistCountRequest
          );

        this.toggleShowChecklistGridRowDelete();
      })
      .catch((error) => {
        this.toggleShowChecklistGridRowDelete();
      });
  }


  deleteComment() {
    const clientId = this.props.clientId;
    this.props.checklistActions
      .deleteComment(
        this.state.selectedCommentId,
        clientId
      )
      .then((data) => {
        this.props.checklistActions.fetchQuestionComments(this.props.clientId, this.props.arqcChecklistId, null);

        this.props.checklistActions
          .fetchChecklistNavCounts(
            this.props.clientId,
            this.props.arqcChecklistId,
            checklistCountRequest.ChecklistCountRequest
          );
        this.toggleShowChecklistGridRowDelete();
      })
      .catch((error) => {
        this.toggleShowChecklistGridRowDelete();
      });
  }


  getIsGridNoDataFound(name) {
    if ((name === "Comments"
      && this.props.questionComments
      && this.props.questionComments.length === 0)
      || (name === "Attachments"
        && this.props.attachmentDetails
        && this.props.attachmentDetails.length === 0)
      || (name === "Discrepancies"
        && this.props.discrepanciesDetail
        && this.props.discrepanciesDetail.length === 0)) {
      return true;
    }
    else { return false; }
  }

  isChecklistContentEnabled() {
    return this.props.arqcActiveChecklist !== null
      && this.props.arqcActiveChecklist.checklistStateID !== undefined
      && this.props.arqcActiveChecklist.checklistStateID.toLowerCase() !== "completed"
      && this.state.canEdit;
  }

  getDetails() {
    const isEnabled = this.isChecklistContentEnabled();

    if (this.state.showDetails) {
      return (
        <div>
          <div className="row">
            <div className="col-md-3 mb-2">
              <ChecklistNavigation
                disabledSections={this.state.disabledSections}
                checklist={this.state.checklist}
                activeItem={this.state.activeItem}
                handleSelectionChanged={this.setActiveItem}
                checklistFiles={this.props.attachmentDetails}
                navigationCount={this.props.checklistCounter}
                isExternal={false}
              />
            </div>
            <div className="col-md-9 mb-2">
              {!this.state.isSubNavigation && (
                <>
                  <ArqcChecklistDetailsForm
                    periodId={this.props.periodId}
                    onSubmit={this.handleArqcDetailsFormSubmit}
                    handleFormSubmit={this.handleSaveChecklist}
                    checklist={this.state.checklist}
                    isEnabled={isEnabled}
                    incomeDetails={this.props.incomeDetails}
                    activeItem={this.state.activeItem}
                    handleItemAnswered={this.handleItemAnswered}
                    handleComments={this.handleComments}
                    isExternal={false}
                    isArqc={true}
                    showAllQuestions={this.state.showAllQuestions}
                    showUnansweredQuestions={this.state.showUnansweredQuestions}
                    previousArqcChecklist={this.props.previousArqcChecklist}
                    taxableReitSubsidiaries={this.props.taxableReitSubsidiaries}
                    showConfirmationModal={this.showConfirmationModal}
                    initialValues={this.state.initialValues}
                    tbMode={this.state.tbMode}
                    canEdit={this.state.canEdit}
                    user={this.props.user}
                    checklistFiles={this.props.attachmentDetails}
                    questionComments={this.props.questionComments}
                    handleSaveComment={this.handleSaveComment}
                    handleDeleteComment={this.handleDeleteComment}
                    handleEditComment={this.handleEditComment}
                    handleDeleteAttachment={this.handleGridRowDeleteById}
                    handleDownloadChecklistFile={this.handleDownloadChecklistFile}
                  />
                </>
              )}
              {this.state.isSubNavigation && (
                <>
                  <h4 className="sectionTitle">{this.state.activeItem}</h4>

                  {this.getIsGridNoDataFound(this.state.activeItem) &&
                    <div>No {this.state.activeItem} found for this checklist.</div>
                  }

                  {this.state.activeItem === 'Comments' &&
                    <ShowCommentsTable
                      clientId={this.props.client.id}
                      questionComments={this.state.questionComments}
                      gridColumnState={this.state.gridColumnState}
                      handleGridColumnStateChange={this.handleGridColumnStateChange}
                      handleGridChanges={this.handleGridChanges}
                      handleRowDelete={this.handleGridRowDeleteById}
                      canEdit={this.state.canEdit}
                    />
                  }
                  {this.state.activeItem === 'Attachments' &&
                    <ShowAttachmentTable
                      attachmentDetail={this.state.attachmentDetails}
                      gridColumnState={this.state.gridColumnState}
                      handleGridColumnStateChange={this.handleGridColumnStateChange}
                      handleGridChanges={this.handleGridChanges}
                      handleDeleteAttachment={this.handleGridRowDeleteById}
                      handleDownloadChecklistFile={this.handleDownloadChecklistFile}
                      canEdit={this.state.canEdit}
                    />
                  }
                  {this.state.activeItem === 'Discrepancies' &&
                    <ShowDiscrepanciesTable
                      discrepanciesDetail={this.props.discrepanciesDetail}
                      gridColumnState={this.state.gridColumnState}
                      handleGridColumnStateChange={this.handleGridColumnStateChange}
                      handleGridChanges={this.handleGridChanges}
                    />
                  }
                </>
              )}

            </div>
          </div>
          <DeleteChecklistDetailGridRowModal
            showDeleteChecklistGridRowModal={
              this.state.showDeleteChecklistGridRowModal}
            toggleshowDeleteChecklistGridRowModal={
              this.toggleShowChecklistGridRowDelete}
            deleteRow={this.state.deleteFor === "Attachments" ? this.deleteAttachment : this.deleteComment}
            modalName={this.state.deleteFor}
          />
          <ChecklistCompleteModal handleModalAction={this.handleConfirmationAction} showModal={this.state.showConfirmationModal} />
        </div>
      );
    }

    return null;
  }

  isEngagementAdminOrSysAdmin() {
    let isEngAdminOrSysAdmin = false;
    let currentUserAuthorizations = this.props.currentUserAuthorizations;
    if (Array.isArray(currentUserAuthorizations)) {
      isEngAdminOrSysAdmin = authActions.isSystemAdministrator(currentUserAuthorizations) || authActions.isEngagementAdministrator(currentUserAuthorizations, this.props.client.id);
    }
    return isEngAdminOrSysAdmin;
  }

  /**
   * Render a React element
* @returns {Object} A reference to the component
      */
  render() {
    let isEngAdminOrSysAdmin = false;
    isEngAdminOrSysAdmin = this.isEngagementAdminOrSysAdmin();

    if (this.props.arqcActiveChecklist === null) {
      return (
        <div className="checklist">
          <h1 data-testid="h1PageHeading">{this.getArqcQuestionnaireTitle()}</h1>
          {!this.state.canEdit && <div>Reporting Closed</div>}
          <div style={{ marginTop: '15px', marginBottom: '25px' }}>
            <span>
              <MotifDropdown
                open={this.state.checklistDropdownOpen}
                placement="bottom-left"
                id={'checklist-dropdown'}
                aria-labelledby={'client-management-trigger'}
                data-testid="btnDownloadChecklist"
                handleClickOutside={() => this.setState({ checklistDropdownOpen: false })}
                trigger={
                  <MotifButton
                    disabled={!(this.props.arqcActiveChecklist)}
                    type="button"
                    variant="primary-alt"
                    onClick={() => this.setState({ checklistDropdownOpen: !this.state.checklistDropdownOpen })}
                    aria-controls={'checklist-dropdown'}
                    id={'checklist-dropdown-trigger'}
                    aria-expanded={this.state.checklistDropdownOpen}
                    aria-label="Download checklist dropdown"
                  >
                    Download Checklist <MotifIcon src={navigationIcArrowDropDown24px} />
                  </MotifButton>
                }
              >
                <MotifDropdownItem onClick={() => { this.setState({ checklistDropdownOpen: false }); this.downloadChecklist('WITH_ANSWERS'); }}>
                  With Answers Only
                </MotifDropdownItem>
                <MotifDropdownItem onClick={() => { this.setState({ checklistDropdownOpen: false }); this.downloadChecklist('WITH_ANSWERS_COMMENTS') }}>
                  With Answers And Comments
                </MotifDropdownItem>
                <MotifDropdownItem onClick={() => { this.setState({ checklistDropdownOpen: false }); this.downloadChecklist('WITHOUT_ANSWERS'); }}>
                  Without Answers or Comments
                </MotifDropdownItem>
              </MotifDropdown>
            </span>
          </div>
        </div>
      );
    }

    return (
      <div className="checklist">
        <div className="row" style={{ alignItems: "center" }}>
          <div className="col">
            <h1 data-testid="h1PageHeading">{this.getArqcQuestionnaireTitle()}</h1>
            {!this.state.canEdit && <div>Reporting Closed</div>}
          </div>
        </div>
        <div className="row mt-3 mb-3">
          <div className="col-md-4 checklistCounters">
            <ChecklistCounters
              previousChecklistFound={
                this.props.previousArqcChecklist &&
                this.props.previousArqcChecklist.content
              }
              isExternal={false}
              checklist={this.state.checklist}
              disabledSections={this.state.disabledSections}
            />
          </div>
          <div className="col-md-8">
            <div className="d-flex ml-auto justify-content-end">
              <span className="ml-3">
                <MotifDropdown
                  open={this.state.checklistDropdownOpen}
                  placement="bottom-left"
                  id={'checklist-dropdown'}
                  aria-labelledby={'client-management-trigger'}
                  data-testid="btnDownloadChecklist"
                  handleClickOutside={() => this.setState({ checklistDropdownOpen: false })}
                  trigger={
                    <MotifButton
                      disabled={!(this.props.arqcActiveChecklist)}
                      type="button"
                      variant="primary-alt"
                      onClick={() => this.setState({ checklistDropdownOpen: !this.state.checklistDropdownOpen })}
                      aria-controls={'checklist-dropdown'}
                      id={'checklist-dropdown-trigger'}
                      aria-expanded={this.state.checklistDropdownOpen}
                      aria-label="Download checklist dropdown"
                    >
                      Download Checklist <MotifIcon src={navigationIcArrowDropDown24px} />
                    </MotifButton>
                  }
                >
                  <MotifDropdownItem onClick={() => { this.setState({ checklistDropdownOpen: false }); this.downloadChecklist('WITH_ANSWERS'); }}>
                    With Answers Only
                  </MotifDropdownItem>
                  <MotifDropdownItem onClick={() => { this.setState({ checklistDropdownOpen: false }); this.downloadChecklist('WITH_ANSWERS_COMMENTS') }}>
                    With Answers And Comments
                  </MotifDropdownItem>
                  <MotifDropdownItem onClick={() => { this.setState({ checklistDropdownOpen: false }); this.downloadChecklist('WITHOUT_ANSWERS'); }}>
                    Without Answers or Comments
                  </MotifDropdownItem>
                </MotifDropdown>
              </span>

              <MotifButton variant="secondary" className="ml-2" type="button" data-testid="btnReviewComplete"
                onClick={this.showConfirmationModal}
                disabled={!this.isChecklistContentEnabled()}>
                Review Complete
              </MotifButton>
              <MotifButton variant="primary" className="ml-2" type="submit" data-testid="btnSave"
                onClick={this.handleSaveChecklist}
                disabled={!this.isChecklistContentEnabled()}>
                Save
              </MotifButton>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col d-flex mr-auto">
            <div className="d-flex flex-row" style={{ alignItems: 'center' }} data-testid="toggleDisplayUnansweredQuestionsOnly">
              <div className="mr-3">
                <span className="right font-weight-bold">Display Unanswered Questions Only:</span>
              </div>
              <div>
                <div className="switch-box">
                  <MotifToggleSwitch
                    aria-label="Display Unanswered Questions Only toggle"
                    name="showUnanswered"
                    id="showUnanswered"
                    onChange={this.handleShowUnansweredQuestions}
                    checked={this.state.showUnansweredQuestions}
                  />
                </div>
              </div>
            </div>

            {(this.state.showDetails && this.props.arqcActiveChecklist && this.props.previousArqcChecklist && Array.isArray(this.props.previousArqcChecklist.content) && this.props.previousArqcChecklist.content.length > 0 && (
              <div className="d-flex flex-row ml-4" style={{ alignItems: 'center' }} data-testid="toggleDisplayChangedAnswers">
                <div className="mr-3">
                  <span className="font-weight-bold">Display Changed Answers:</span>
                </div>
                <div>
                  <div className="switch-box">
                    <MotifToggleSwitch
                      aria-label="Display Changed Answers toggle"
                      name="showAll"
                      id="showAll"
                      onChange={this.handleShowAllQuestions}
                      checked={!this.state.showAllQuestions}
                    />
                  </div>
                </div>
              </div>)) || (<div />)}
          </div>
        </div>

        {this.getDetails()}
      </div>
    );
  }
}

ArqcChecklistRecord.propTypes = {
  client: PropTypes.object.isRequired,
  reit: PropTypes.object.isRequired,
  periodId: PropTypes.number.isRequired,
  arqcChecklists: PropTypes.array,
  arqcActiveChecklist: PropTypes.object,
  previousArqcChecklist: PropTypes.object,
  checklistActions: PropTypes.object,
  fileActions: PropTypes.object,
  propertyActions: PropTypes.object,
  reitActions: PropTypes.object,
  properties: PropTypes.array,
  incomeDetails: PropTypes.array,
  propertyDetails: PropTypes.object,
  propertyItsi: PropTypes.object,
  currentUserAuthorizations: PropTypes.array,
  periodActions: PropTypes.object.isRequired,
  deletedChecklist: PropTypes.object,
  canDelete: PropTypes.bool,
  canRevertState: PropTypes.bool,
  pendingTasks: PropTypes.number,
  user: PropTypes.string
};

/**
 * Maps items from state to properties of the component
* @param {Object} state The state
* @param {Object} ownProps The properties of the component
* @returns {Object} An object containing properties that the component can access
    */
function mapStateToProps(state, ownProps) {
  let clientId = Number.parseInt(ownProps.params.clientId);
  if (isNaN(clientId)) {
    clientId = 0;
  }
  return {
    user: state.authentication.currentUser,
    client: state.client,
    periodId: Number.parseInt(ownProps.params.periodId),
    arqcChecklistId: Number.parseInt(ownProps.params.arqcChecklistId),
    arqcChecklists: state.arqcChecklists,
    arqcActiveChecklist: state.arqcActiveChecklist,
    previousArqcChecklist: state.previousArqcChecklist,
    checklistFiles: state.checklistFiles,
    reit: state.reit,
    distributionDetails: state.distributionDetails,
    distributionTestSummary: state.distributionTestSummary,
    distributionTaxableIncome: state.distributionTaxableIncome,
    currentUserAuthorizations: state.currentUserAuthorizations,
    period: state.period,
    deletedChecklist: state.deletedChecklist,
    canDelete: (Array.isArray(state.currentUserAuthorizations) && (authActions.isSystemAdministrator(state.currentUserAuthorizations) || (state.client && authActions.isEngagementAdministrator(state.currentUserAuthorizations, state.client.id)))) && (state.period.reportPeriodStatusDescription !== "Closed to Reporting" && state.period.reportPeriodStatusDescription !== "Complete"),
    canRevertState: (state.period.reportPeriodStatusDescription !== "Closed to Reporting" && state.period.reportPeriodStatusDescription !== "Complete"),
    pendingTasks: state.pendingTasks,
    taxableReitSubsidiaries: state.taxableReitSubsidiaries,
    services: state.services,
    lookupTypes: state.lookupTypes,
    clientId: Number.parseInt(ownProps.params.clientId),
    questionComments: state.questionComments,
    attachmentDetails: state.attachmentDetail,
    discrepanciesDetail: state.discrepanciesDetail,
    checklistCounter: state.checklistsCounter
  };
}

/**
 * Binds actions to the dispatcher
* @param {Object} dispatch The action dispatcher
* @returns {Object} An object containing properties that the component can access
        */
function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonActions, dispatch),
    checklistActions: bindActionCreators(checklistActions, dispatch),
    periodActions: bindActionCreators(periodActions, dispatch),
    trsActions: bindActionCreators(trsActions, dispatch),
    reitActions: bindActionCreators(reitActions, dispatch),
    serviceActions: bindActionCreators(serviceActions, dispatch),
    reportActions: bindActionCreators(reportActions, dispatch),
    fileActions: bindActionCreators(fileActions, dispatch),
    customQuestionActions: bindActionCreators(customQuestionActions, dispatch),
    customChecklistTemplateActions: bindActionCreators(customChecklistTemplateActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ArqcChecklistRecord);