import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Icon } from 'semantic-ui-react';
import { getFormValues, change } from "redux-form";
import ReactModal from 'react-modal';
import EditClientForm from './EditClientForm';
import EditReitForm from '../reits/EditReitForm';
import * as dateHelpers from "../../scripts/dateHelpers";
import * as lookupTypeActions from "../../actions/lookupTypeActions";
import CreateEditReitModal from '../reits/createEditReitModal';


/**
 * The EditClientComponent class
 * @extends {React.Component}
 */
export class EditClientComponent extends React.Component {
    /**
     * Creates a new EditClientComponent
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);

        this.state = {
            initialValues: null,
            reitFormInitialValues: null
        };
    }

    componentDidMount() {
        this.props.lookupTypeActions.fetchPropertyTypes();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.client !== this.props.client) {
            if (nextProps.client) {
                let initialValues = Object.assign({}, nextProps.client);
                initialValues.isActive = initialValues.isActive.toString();

                this.setState({ initialValues: initialValues });
            }
            else {
                const initialValues = {
                    id: 0,
                    name: "",
                    gfisCode: "",
                    engagementNumber: "",
                    isActive: true.toString()
                };

                this.setState({ initialValues: initialValues });
            }
        }

        if (nextProps.selectedReit != this.props.selectedReit) {            
            const selectedProperty = {
                id: 0,
                propertyName: "",
                locationAddressStreet: "",
                locationAddressCity: "",
                locationAddressState: "",
                locationAddressZipCode: "",
                propertyTypeID: "0",
                psqPropertyTypes: [],
                propertyClassID: "0",
                rentableSquareFootage: null,
                tenantTypeId: "0",
                propertyParkingTypeID: "0",
                purchasePrice: null,
                PurchaseDate: null,
                managementCompany: "",
                managerName: "",
                managerPhone: "",
                managerEmail: "",
                accountantName: "",
                accountantEmail: "",
                accountantPhone: "",
                propertyAmenities: ""
            };
            const { id, ...otherProps } = selectedProperty;
            const newSelectedProperty = { propertyId: id, anotherProperty: 'NO', ...otherProps };

            if (nextProps.selectedReit) {
                let selectedReit = Object.assign({}, nextProps.selectedReit);
                // Handle issue of showing empty value when Field is having 0 value on UI
                if (!selectedReit.percentageThreshold) {
                    selectedReit.percentageThreshold = "0";
                }
                if (!selectedReit.balanceThreshold) {                    
                    selectedReit.balanceThreshold = "0";
                }                
                let reitFormInitialValues = Object.assign({}, selectedReit, newSelectedProperty);
                this.setState({ reitFormInitialValues: reitFormInitialValues });
            }
            else {                
                const selectedReit = {
                    reitid: 0,
                    reitName: "",
                    reitShortName: "",
                    reitTypeID: "",
                    balanceThreshold: "",
                    percentageThreshold: "",
                    numberOfSharesClasses: "",
                    preferredNumberOfShareHolders: "",
                    stateOrganized: "",
                    organizationTypeID: "",
                    periodQuarter: dateHelpers.getQuarter(),
                    periodYear: dateHelpers.getYear()
                };

                let reitFormInitialValues = Object.assign({}, selectedReit, newSelectedProperty);
                this.setState({ reitFormInitialValues: reitFormInitialValues });
            }
        }        
        //Here is where we have to change the functionality for the auto psq property type selection
        if (this.props.propertyValues && nextProps.propertyValues
            && nextProps.propertyValues.propertyTypeID !== this.props.propertyValues.propertyTypeID
            && !["Mixed Use"].includes(this.props.lookupTypes.propertyTypes.find(p => p.propertyTypeID == nextProps.propertyValues.propertyTypeID).propertyTypeDescription)
        ) {
            const newValue = this.props.lookupTypes.propertyTypes.find(p => p.propertyTypeID == nextProps.propertyValues.propertyTypeID).propertyTypeDescription;
            const newFormValue = newValue !== 'Not Selected' ? [newValue] : [];
            this.props.change("reit", "psqPropertyTypes", newFormValue);
        }
    }

    render() {
        const reitModalStyle = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '60%',
                height: '100%', // <-- This sets the height
                overlfow: 'scroll' // <-- This tells the modal to scrol
            }
        };

        return (
            <div className="edit-client-component">
                <EditClientForm reits={this.props.reits} onSubmit={this.props.handleClientSubmit} handleAddReitClick={this.props.handleAddReitClick}
                    handleEditReitClick={this.props.handleEditReitClick} handleDeleteReitClick={this.props.handleDeleteReitClick}
                    initialValues={this.state.initialValues} showSuccess={this.props.showSuccess}
                    showReitSuccess={this.props.showReitSuccess} clientFormPurpose={this.props.clientFormPurpose}
                    selectedReit={this.props.selectedReit} reitFormPurpose={this.props.reitFormPurpose}
                    auths={this.props.auths} handleSearchChange={this.props.handleSearchChange}
                    handleClearSearchText={this.props.handleClearSearchText} clientUserSearchText={this.props.clientUserSearchText}
                    handleEditButtonClick={this.props.handleEditButtonClick} handleDeleteButtonClick={this.props.handleDeleteButtonClick}
                    handleNewButtonClick={this.props.handleNewButtonClick} showAddClientAuthModal={this.props.showAddClientAuthModal}
                    handleAddClientAuthModalAction={this.props.handleAddClientAuthModalAction} auth={this.props.auth}
                    toggleShowAddClientAuthModal={this.props.toggleShowAddClientAuthModal} currentUserId={this.props.currentUserId}
                    showEditClientAuthModal={this.props.showEditClientAuthModal} handleEditClientAuthModalAction={this.props.handleEditClientAuthModalAction}
                    toggleShowEditClientAuthModal={this.props.toggleShowEditClientAuthModal} currentUserIsSysAdmin={this.props.currentUserIsSysAdmin}
                    addAuthMessageText={this.props.addAuthMessageText} handleDismissErrorMessage={this.props.handleDismissErrorMessage}
                    handleDismissSuccessMessage={this.props.handleDismissSuccessMessage} handleDismissReitSuccessMessage={this.props.handleDismissReitSuccessMessage}
                    handleToggleActiveFlag={this.props.handleToggleActiveFlag} isActive={this.props.isActive}
                    handleToggleAuditFlag={this.props.handleToggleAuditFlag} isAudit={this.props.isAudit}
                    numberOfEngagementAdmins={this.props.numberOfEngagementAdmins} handleEditClientCancel={this.props.handleEditClientCancel}
                    validateDomain={this.props.validateDomain} validateDomainWithRole={this.props.validateDomainWithRole}
                    clientAuthFormValues={this.props.clientAuthFormValues}
                    handleAddClientAuthModalValidateAction={this.props.handleAddClientAuthModalValidateAction}
                    validatingUser={this.props.validatingUser} myEyError={this.props.myEyError} handleDailyNewsletter={this.props.handleDailyNewsletter} receiveDailyNewsletter={this.props.receiveDailyNewsletter} 
                    handleWeeklyNewsletter={this.props.handleWeeklyNewsletter} receiveWeeklyNewsletter={this.props.receiveWeeklyNewsletter} 
                    handleRealTimeNotification={this.props.handleRealTimeNotification} receiveRealTimeNotification={this.props.receiveRealTimeNotification} 
                    receiveRealTimeNotificationForNewPsqArqcSent= {this.props.receiveRealTimeNotificationForNewPsqArqcSent}
                    handleInternalReviewNotification= {this.props.handleInternalReviewNotification} handleOnRoleTypeChange={this.props.handleOnRoleTypeChange}
                    />
                <CreateEditReitModal
                    isOpen={this.props.showReitForm}
                    handleModalClose={this.props.handleCloseReitForm}
                    reitFormPurpose={this.props.reitFormPurpose}
                    handleSubmit={this.props.handleReitSubmit}
                    initialValues={this.state.reitFormInitialValues}
                    handleDismissErrorMessage={this.props.handleDismissReitErrorMessage}
                    lookupTypes={this.props.lookupTypes}
                />
            </div>
        );
    }
}

EditClientComponent.propTypes = {
    showReitForm: PropTypes.bool.isRequired,
    handleCloseReitForm: PropTypes.func.isRequired,
    handleDismissReitErrorMessage: PropTypes.func.isRequired,
    client: PropTypes.object,
    reits: PropTypes.array,
    lookupTypes: PropTypes.object,
    selectedReit: PropTypes.object,
    selectedProperty: PropTypes.object,
    handleAddReitClick: PropTypes.func,
    handleEditReitClick: PropTypes.func,
    handleDeleteReitClick: PropTypes.func,
    handleReitSubmit: PropTypes.func,
    handleClientSubmit: PropTypes.func,
    showSuccess: PropTypes.bool,
    showReitSuccess: PropTypes.bool,
    clientFormPurpose: PropTypes.string,
    reitFormPurpose: PropTypes.string,
    auths: PropTypes.array,
    handleSearchChange: PropTypes.func,
    handleClearSearchText: PropTypes.func,
    clientUserSearchText: PropTypes.string,
    handleEditButtonClick: PropTypes.func,
    handleDeleteButtonClick: PropTypes.func,
    handleNewButtonClick: PropTypes.func,
    showAddClientAuthModal: PropTypes.bool,
    handleAddClientAuthModalAction: PropTypes.func,
    auth: PropTypes.object,
    toggleShowAddClientAuthModal: PropTypes.func,
    currentUserId: PropTypes.string,
    showEditClientAuthModal: PropTypes.bool,
    handleEditClientAuthModalAction: PropTypes.func,
    toggleShowEditClientAuthModal: PropTypes.func,
    currentUserIsSysAdmin: PropTypes.bool,
    addAuthMessageText: PropTypes.string,
    handleDismissErrorMessage: PropTypes.func,
    handleDismissSuccessMessage: PropTypes.func,
    handleDismissReitSuccessMessage: PropTypes.func,
    handleToggleActiveFlag: PropTypes.func,
    isActive: PropTypes.bool,
    handleToggleAuditFlag: PropTypes.func,
    isAudit: PropTypes.bool,
    numberOfEngagementAdmins: PropTypes.number,
    handleEditClientCancel: PropTypes.func,
    validateDomain: PropTypes.func,
    clientAuthFormValues: PropTypes.object,
    handleAddClientAuthModalValidateAction: PropTypes.func,
    validatingUser: PropTypes.bool,
    myEyError: PropTypes.string,
    handleDailyNewsletter:PropTypes.func,
    receiveDailyNewsletter:PropTypes.bool,
    handleWeeklyNewsletter:PropTypes.func,
    receiveWeeklyNewsletter:PropTypes.bool,
    handleRealTimeNotification:PropTypes.func,
    receiveRealTimeNotification:PropTypes.bool,
    receiveRealTimeNotificationForNewPsqArqcSent: PropTypes.bool,
    handleInternalReviewNotification: PropTypes.func,
    handleOnRoleTypeChange:PropTypes.func

};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    return {
        propertyValues: getFormValues('reit')(state),
        lookupTypes: state.lookupTypes
    };
}

/**
* Binds actions to the dispatcher
* @param {Object} dispatch The action dispatcher
* @returns {Object} An object containing properties that the component can access
*/
function mapDispatchToProps(dispatch) {
    return {
        change: bindActionCreators(change, dispatch),
        lookupTypeActions: bindActionCreators(lookupTypeActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditClientComponent);