import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";

/**
 * ChecklistExternalContainer container component
 * @extends {React.Component}
 */
class ChecklistExternalContainer extends React.Component {
    /**
    * Creates a new Checklist Dashboard page
    * @constructor
    * @param {Object} props The component properties
    * @param {Object} context The component context
    */
    constructor(props, context) {
        super(props, context);            

        this.state = {                     
        };
    }

    /**
     * Invoked immediately after mounting occurs
     * @returns {undefined}
     */
    componentDidMount() {
        //Redirect to psq tab on External Checklist UI
        this.context.router.push("/ext/psq");
    }
    
    /**
     * Invoked immediately before unmounting occurs
     * @returns {undefined}
     */
    componentWillUnmount() {        
    
    }

    componentWillReceiveProps(nextProps) {
   
    }
       
    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        return (
            <React.Fragment>
            </React.Fragment >
        );
    }
}

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    return {
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {   
    return {        
    };
}

ChecklistExternalContainer.propTypes = {     
};

ChecklistExternalContainer.contextTypes = {
    router: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: "checklistExternal", enableReinitialize: true })(ChecklistExternalContainer));