import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as reitActions from '../../actions/reitActions';

/**
 * ReitPage container component
 * @extends {React.Component}
 */
export class ReitLoader extends React.Component {
    /**
     * Invoked immediately after mounting occurs
     * @returns {undefined}
     */
    componentDidMount() {
        this.props.actions.fetchReit(this.props.reitId);
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        return (<div>{this.props.children}</div>);
    }
}

ReitLoader.propTypes = {
    children: PropTypes.object,
    reitId: PropTypes.string.isRequired,
    reit: PropTypes.object,
    actions: PropTypes.object
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    return {
        reitId: ownProps.params.reitId,
        reit: state.reit
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(reitActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReitLoader);