import React from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dimensions from 'react-dimensions';
import * as detailTestTableHelpers from '../../scripts/detailTestTableHelpers';

/**
 * IncomeDetailTestTable container component
 * @extends {React.Component}
 */
export class IncomeDetailTestTable extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        return (
            <div style={{ marginBottom: "20px", overflowX: 'auto' }}>
                <table className="table-responsive rtable detail-test-table" style={{ width: `${this.props.containerWidth}px`, tableLayout: 'fixed' }}>
                    <thead>
                        <tr>
                            <th>
                                {this.props.detail.test}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {detailTestTableHelpers.getInnerTables(this.props.detail, this.props.properties, this.props.tbMode, this.props.containerWidth, this.props.containerHeight, "INCOME")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

IncomeDetailTestTable.propTypes = {
    detail: PropTypes.object,
    properties: PropTypes.array,
    tbMode: PropTypes.number,
    containerWidth: PropTypes.number,
    containerHeight: PropTypes.number
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps Existing properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    return {
    };
}

export default Dimensions({
    getHeight: function (element) {
        return window.innerHeight;
    },
    getWidth: function (element) {
        let widthOffset = window.innerWidth < 680 ? 0 : 47;
        return window.innerWidth - widthOffset;
    }
})(connect(mapStateToProps)(IncomeDetailTestTable));