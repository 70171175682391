import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { MotifIconButton, MotifIcon } from "@ey-xd/motif-react";
import { imageIcEdit24px, actionIcDelete24px } from '@ey-xd/motif-react/assets/icons';

/**
 * Presentation component used for rendering a download link to the trial balance file.
 */
export default class RelatedReitsRow extends React.Component {
    /**
     * Creates a new TrialBalanceDownloadLink
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);

        this.onEditClick = this.onEditClick.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
    }
    
    onEditClick(e) {
        e.preventDefault();
        this.props.handleEditClick(this.props.reit);
    }

    onDeleteClick(e) {
        e.preventDefault();
        this.props.handleDeleteClick(this.props.reit.reitid);
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        let type = "Equity";
        if (this.props.reit.reitTypeID === 1) type = "Mortgage";
        return (
            <tr data-name={this.props.reit.reitName}>
                <td>{this.props.reit.reitName}</td>
                <td>{type}</td>
                <td>{this.props.reit.percentageThreshold}% / ${this.props.reit.balanceThreshold}</td>
                <td>{this.props.reit.ein}</td>
                <td>
                    <MotifIconButton aria-label="Edit this REIT" type="button"
                        onClick={this.onEditClick}>
                        <MotifIcon src={imageIcEdit24px} title="Edit this REIT" />
                    </MotifIconButton>
                </td>
            </tr>
        );
    }
}

RelatedReitsRow.propTypes = {
    reit: PropTypes.object,
    handleEditClick: PropTypes.func.isRequired,
    handleDeleteClick: PropTypes.func.isRequired,
    reitType: PropTypes.string
};