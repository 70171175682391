export const POLL_INTERVAL = process.env.POLL_INTERVAL;
export const NOTIFICATIONS_POLL_INTERVAL = process.env.NOTIFICATIONS_POLL_INTERVAL;
export const MAX_UPLOAD_PROCESSES = process.env.MAX_UPLOAD_PROCESSES;

export const FILE_PROPERTIES = [
    'lastModified',
    'lastModifiedDate',
    'name',
    'path',
    'preview',
    'size',
    'type',
    'webkitRelativePath'
];

export const RejectedEyDomain ="ey.com";
export const RejectedOtherEYDomain =".ey.com";
export const prePopulateOptions = [
    { key: 0, text: 'No / No', value: 2 },
    { key: 1, text: 'No / Yes', value: 0 },
    { key: 2, text: 'Yes / Yes', value: 1 }
];

export const deleteAvailableQuestionMessage = [
   "This will remove the ability to add this question to any template.",
   "This will also delete the question from other template(s) that's currently using it.",
   "This will not remove the question from an existing checklist that's currently using it."
];

export const deleteAvailableParentQuestionMessage = [
    "This will delete the questions under this question.",
    "This will remove the ability to add this question/child questions to any template.",
    "This will also delete the question from other template(s) that's currently using it.",
    "This will not remove the question from an existing checklist that's currently using it."
 ];

export const defaultPrepopulateAnswer=2;
export const invalidEmailCreateChecklistHeader="Information on Client Recipients";
export const invalidEmailAddUserHeader="Information on Checklist Recipients";


export const rollForwardQuestionComment = [
    { key: 1, text: 'No / No', value: 0 },
    { key: 2, text: 'No / Yes', value: 1 },
    { key: 3, text: 'Yes / No', value: 2 },
    { key: 4, text: 'Yes / Yes', value: 3 }

];

export const reportPeriodQuarter = [
    { key: "Q1", text: 'Q1', value: "Q1" },
    { key: "Q2", text: 'Q2', value: "Q2" },
    { key: "Q3", text: 'Q3', value: "Q3" },
    { key: "Q4", text: 'Q4', value: "Q4" }

];

export const reportPackagesGroupBy={
        1: 'REIT',
        2: 'REPORT_PERIOD',
        3: 'FILE_TYPE',
  
}

export const reportPackagesGroupByValues={
    1: 'REIT',
    2: 'Report Period',
    3: 'Report Type',

}

export const reportPackagesFileType={
    ConsolidatedMappings:1,
    REITTrialBalance:2,
    PropertyTrialBalance:3,
    TestSnapshotFileperREIT:4,
    TestSnapshotSingleExcelforselectedREITs:5,
    PSQChecklistsWithAnswersOnlyMSWord:6,
    PSQChecklistsWithAnswersOnlyPDF:7,
    PSQChecklistsWithAnswersandCommentsMSWord:8,
    PSQChecklistsWithAnswersandCommentsPDF:9,
    PSQChecklistsWithoutAnswersorCommentsMSWord:10,
    PSQChecklistsWithoutAnswersorCommentsPDF:11,
    PSQChecklistsIncludeChecklistAttachmentsasSeparateFiles:12,
    ARQChecklistsWithAnswersOnlyMSWord:13,
    ARQChecklistsWithAnswersOnlyPDF:14,
    ARQChecklistsWithAnswersandCommentsMSWord:15,
    ARQChecklistsWithAnswersandCommentsPDF:16,
    ARQChecklistsWithoutAnswersorCommentsMSWord:17,
    ARQChecklistsWithoutAnswersorCommentsPDF:18,
    ARQChecklistsIncludeChecklistAttachmentsasSeparateFiles:19,
    FinalDeliveryReportStandardTemplateMSWord:20,
    FinalDeliveryReportStandardTemplatePDF:21,
    FinalDeliveryReportCustomTemplateMSWord:22,
    FinalDeliveryReportCustomTemplatePDF:23,
    FinalDeliveryReportAssignedDefaultsMSWord:24,
    FinalDeliveryReportAssignedDefaultsPDF:25,
    GeneralandOtherFiles:26,
    UserUploadedFinalReport:27,
    LeadSheet: 28,
    REITGeneralFiles: 29
}


export const defaultRollFowardQuestionComment=0;
export const defaultRollFowardQuestionAttachment=0;

export const MAX_UPLOAD_FILE_SIZE_IN_BYTES = 104857600;
export const MAX_UPLOAD_FILE_SIZE_IN_MB = MAX_UPLOAD_FILE_SIZE_IN_BYTES/(1024*1024);

export const bulkProcessManagementMessages = {
toolTipMessage:"You have not selected any REIT(s) in order to perform this action.",

}

export const updateSignOffStatusAction = [
    { key: "closeTesting", text: 'Close Testing', value: "2", action: 'TestingClosed', type: 'close'},
    { key: "closeReporting", text: 'Close Reporting', value: "3", action: 'ReportingClosed',type:"close" },
    { key: "closePeriod", text: 'Close Period', value: "4" , action:'Complete',type:"close"},
    { key: "reopenPeriod", text: 'Reopen Period', value: "5", action: 'ReportingClosed',type:"reopen"},
    { key: "reopenToReporting", text: 'Reopen to Reporting', value: "6", action: 'TestingClosed',type:"reopen"},
    { key: "reopenToTesting", text: 'Reopen to Testing', value: "7", action:'InProgress',type:"reopen" },

];
export const defaultSelectedServiceScope = [
    "Asset Testing" 
];
export const emptySelectedServiceScope = [
    "None" 
];