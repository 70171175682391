import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../actions/distributionsTestActions";
import * as PeriodActions from "../../actions/periodActions";
import * as commonActions from "../../actions/commonActions";
import DistributionTestSummary from "../../components/reits/DistributionTestSummary";
import DistributionDetailTable from "../../components/reits/DistributionDetailTable";
import * as actionHelpers from "../../scripts/actionHelpers";
import * as formHelpers from "../../scripts/formHelpers";
import { SubmissionError } from "redux-form";
import * as reitActions from "../../actions/reitActions";

/**
 * DistributionTestContainer container component
 * @extends {React.Component}
 */
export class DistributionTestContainer extends React.Component {
    /**
      * Creates a new EditSecuritiesSnapshotContainer
      * @constructor
      * @param {Object} props The component properties
      * @param {Object} context The component context
      */
    constructor(props, context) {
        super(props, context);

        this.state = {
            showDetails: false,
            inputValue: 0,
            showForm: false,
            formPurpose: "",
            canEdit: true,
            selectedDistribution: {
                "distributionID": 0,
                "class": null,
                "distributionDate": null,
                "reportPeriodId": 0,
                "fairMarketValue": 0.00,
                "value": 0.00
            }
        };
        this.showDetails = this.showDetails.bind(this);
        this.setTaxableIncome = this.setTaxableIncome.bind(this);
        this.updateInputValue = this.updateInputValue.bind(this);
        this.closeForm = this.closeForm.bind(this);
        this.showForm = this.showForm.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.handleEditButtonClick = this.handleEditButtonClick.bind(this);
        this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
        this.handleDismissErrorMessage = this.handleDismissErrorMessage.bind(this);
    }

    /**
     * Invoked immediately after mounting occurs
     * @returns {undefined}
     */
    componentDidMount() {
        this.refresh();
    }

    /**
    * Invoked before a mounted component receives new props.
    * @param {Object} nextProps The properties that the component is receiving
    */
    componentWillReceiveProps(nextProps) {
        if (nextProps.period !== this.props.period) {
            this.setCanEdit(nextProps.period);
        }

       
        if (nextProps.distributionTaxableIncome) {
            this.setState({
                inputValue: nextProps.distributionTaxableIncome.distributionREITTaxableIncomeAmount                
            });    
        }
    }

    handleDismissErrorMessage() {
        this.props.commonActions.clearFormErrors('distribution');
    }

    refresh() {
        this.setState({ inputValue: 0 });
        this.props.actions.fetchDistributionTestSummary(this.props.periodId);
        this.props.actions.fetchDistributionDetail(this.props.periodId);
        this.props.actions.fetchDistributionTaxableIncome(this.props.periodId);
                
        // Check if state.period is null, if it is populate period
        if (!this.props.period) {
            this.props.periodActions.fetchPeriodById(this.props.periodId);
        }
        else {
            this.setCanEdit(this.props.period);
        }
    }

    setCanEdit(period) {
        const isEnabled = PeriodActions.isTestingEnabled(period);
        this.setState({ canEdit: isEnabled });
    }

    showDetails() {
        this.setState({ showDetails: !this.state.showDetails });
    }

    setTaxableIncome() {
        this.props.commonActions.clearErrorResponse();
        
        var dividend = actions.sumDistributionValues(this.props.distributionDetails);
        this.props.actions.saveDistributionTaxableIncome(this.props.periodId, this.state.inputValue, dividend);        
    }

    updateInputValue(event) {        
        this.setState({
            inputValue: event && event.target && event.target.value ? event.target.value : 0
        });
    }

    submitForm(values) {
        this.props.commonActions.clearErrorResponse();
        if (!values.class) {
            throw new SubmissionError({ name: "Class is required" });
        } else {
            switch(this.state.formPurpose) {
                case "EDIT":
                    {
                        this.props.commonActions.beginTask();
                        const distribution = {
                            "distributionID": values.distributionID,
                            "class": values.class,
                            "distributionDate": values.distributionDate,
                            "reportPeriodID": this.props.periodId,
                            "fairMarketValue": values.fairMarketValue,
                            "value": values.value
                        };
                        return actions.updateDistribution(distribution).then(data => {
                            if (actionHelpers.isErrorResponse(data)) {
                                this.props.commonActions.endTask();
                                formHelpers.generateSubmissionError(data);
                                return;
                            }

                            this.closeForm();
                            this.props.commonActions.endTask();
                            this.refresh();
                        }).catch(error => {
                            if (error instanceof SubmissionError) {
                                throw error;
                            }

                            this.props.commonActions.endTask();
                            formHelpers.generateSubmissionError();
                        });
                    }

                case "DELETE":
                    {
                        this.props.commonActions.beginTask();
                        const distribution = {
                            "distributionID": values.distributionID,
                            "class": values.class,
                            "distributionDate": values.distributionDate,
                            "reportPeriodID": this.props.periodId,
                            "fairMarketValue": values.fairMarketValue,
                            "value": values.value
                        };
                        return actions.deleteDistribution(distribution).then(data => {
                            if (actionHelpers.isErrorResponse(data)) {
                                this.props.commonActions.endTask();
                                formHelpers.generateSubmissionError(data);
                                return;
                            }

                            this.closeForm();
                            this.props.commonActions.endTask();
                            this.refresh();
                        }).catch(error => {
                            if (error instanceof SubmissionError) {
                                throw error;
                            }

                            this.props.commonActions.endTask();
                            formHelpers.generateSubmissionError();
                        });
                    }

                    default:
                    {
                        this.props.commonActions.beginTask();
                        const distribution = {
                                        "class": values.class,
                                        "distributionDate": values.distributionDate,
                                        "reportPeriodID": this.props.periodId,
                                        "fairMarketValue": values.fairMarketValue,
                                        "value": values.value
                        };
                        return actions.saveDistribution(this.props.periodId, distribution).then(data => {
                            
                            if (actionHelpers.isErrorResponse(data)) {
                                this.props.commonActions.endTask();
                                formHelpers.generateSubmissionError(data);
                                return;
                            }

                            this.props.reitActions.fetchReit(this.props.reitId);
                            this.closeForm();
                            this.props.commonActions.endTask();
                            this.refresh();
                        }).catch(error => {
                            
                            if (error instanceof SubmissionError) {
                                throw error;
                            }

                            this.props.commonActions.endTask();
                            formHelpers.generateSubmissionError();
                        });
                    }
            }
        }
        
    }

    showForm() {
        this.setState({ showForm: true, formPurpose: "", selectedDistribution: null });
    }

    closeForm() {
        this.setState({ showForm: false });
    }

    handleEditButtonClick(value) {
        this.setState({
            showForm: true, formPurpose: "EDIT", selectedDistribution: {
                "distributionID": value.distributionID,
                "class": value.class,
                "distributionDate": value.distributionDate,
                "reportPeriodID": value.reportPeriodID,
                "fairMarketValue": value.fairMarketValue.toString(),
                "value": value.value.toString()
            } });
    }

    handleDeleteButtonClick(value) {
        this.setState({
            showForm: true, formPurpose: "DELETE", selectedDistribution: {
                "distributionID": value.distributionID,
                "class": value.class,
                "distributionDate": value.distributionDate,
                "reportPeriodID": value.reportPeriodID,
                "fairMarketValue": value.fairMarketValue.toString(),
                "value": value.value.toString()
            }
        });
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {        
        return (
            <div>
                <DistributionTestSummary
                    distributionTestSummary={this.props.distributionTestSummary}
                    showDetails={this.state.showDetails}
                    handleShowDetails={this.showDetails}
                    distributionTaxableIncome={this.props.distributionTaxableIncome}
                    distributionTaxableIncomeValue={this.state.inputValue}
                    distributionDetails={this.props.distributionDetails}
                    totalValue={actions.sumDistributionValues(this.props.distributionDetails)}
                    setTaxableIncome={this.setTaxableIncome}
                    updateInputValue={this.updateInputValue}
                    showForm={this.state.showForm}
                    handleCloseForm={this.closeForm}
                    handleNewButtonClick={this.showForm}
                    handleSubmit={this.submitForm}
                    formPurpose={this.state.formPurpose}
                    selectedDistribution={this.state.selectedDistribution}
                    canEdit={this.state.canEdit}
                    handleDismissErrorMessage={this.handleDismissErrorMessage}
                />
                {this.state.showDetails
                    ? <DistributionDetailTable
                        distributionDetails={this.props.distributionDetails}
                        showForm={this.state.showForm}
                        handleEditButtonClick={this.handleEditButtonClick}
                        handleDeleteButtonClick={this.handleDeleteButtonClick}
                        canEdit={this.state.canEdit}
                    />
                    : null }
            </div>
        );
    }
}

DistributionTestContainer.propTypes = {
    reitId: PropTypes.number.isRequired,
    clientId: PropTypes.number.isRequired,
    periodId: PropTypes.number.isRequired,
    periodActions: PropTypes.object.isRequired,
    period: PropTypes.object.isRequired,
    reitActions: PropTypes.object,
    distributionTestSummary: PropTypes.shape({
        tests: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string,
            isPassing: PropTypes.bool,
            percent: PropTypes.number
        }))
    }),
    distributionDetails: PropTypes.array,
    distributionTaxableIncome: PropTypes.shape({
        reportPeriod: PropTypes.string,
        reportPeriodID: PropTypes.number,
        distributionREITTaxableIncomeAmount: PropTypes.number,
        distributionDividendsSum: PropTypes.number
    }),
    actions: PropTypes.object.isRequired,
    commonActions: PropTypes.object.isRequired
};

DistributionTestContainer.contextTypes = {
    router: PropTypes.object
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {    
    return {
        reitId: Number.parseInt(ownProps.params.reitId),
        clientId: Number.parseInt(ownProps.params.clientId),
        periodId: Number.parseInt(ownProps.params.periodId),
        distributionTestSummary: state.distributionTestSummary,
        distributionDetails: state.distributionDetails,
        distributionTaxableIncome: state.distributionTaxableIncome,
        period: state.period
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        periodActions: bindActionCreators(PeriodActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
        reitActions: bindActionCreators(reitActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DistributionTestContainer);