import PropTypes from "prop-types";
import React from "react";
import { Header, Label, Button, Input, Dropdown } from "semantic-ui-react";
import ReactModal from "react-modal";
import { Field, reduxForm } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import * as formValidators from "../../scripts/formValidators";
import { MotifButton } from '@ey-xd/motif-react';

const PeriodTbModeModal = ({ showModeModal, handleSubmit, handleCloseModeModal, initialValues }) => {
    let periodDescription = "Period";
    //if (initialValues && initialValues.description) {
    //    periodDescription = initialValues.description;
    //}

    return (
        <ReactModal isOpen={showModeModal} contentLabel="TB Mode Selection" className="react-modal react-modal-small" overlayClassName="react-modal-overlay">
            <h4 className="mb-3">{periodDescription} Trial Balance Mode</h4>
            <p>NOTE! Changing Trial Balance Mode will Remove All Trial Balances for the Period.</p>
            <form onSubmit={handleSubmit} className="clientAuth">
                <div className="field">
                    <label><Field name="trialBalanceMode" component={formHelpers.renderField} type="radio" value="1" label="Use REIT & Property TBs"/></label><br />
                    <label><Field name="trialBalanceMode" component={formHelpers.renderField} type="radio" value="2" label="Use Consolidated Single Asset TB"/></label>
                </div>               
                <MotifButton variant="primary" className="right ml-2" type="submit">
                    Submit
                </MotifButton>
                <MotifButton variant="secondary" className="right" type="button"
                    onClick={handleCloseModeModal}>
                    Cancel
                </MotifButton>
            </form>
        </ReactModal>
    );
};

PeriodTbModeModal.propTypes = {
    showModeModal: PropTypes.bool,
    handleCloseModeModal: PropTypes.func,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.object
};

export default reduxForm({ form: "clientAuth", enableReinitialize: true })(PeriodTbModeModal);