import React from 'react';
import PropTypes from 'prop-types';
import DistributionTestSummaryItem from './DistributionTestSummaryItem';
import { injectIntl } from 'react-intl';
import FormattedCurrencyInput from '../shared/FormattedCurrencyInput';
import { Button, Icon } from 'semantic-ui-react';
import ReactModal from 'react-modal';
import EditDistributionForm from './EditDistributionForm';
import { MotifButton, MotifToggleSwitch, MotifModal } from '@ey-xd/motif-react';

const DistributionTestSummary = ({
  distributionTestSummary,
  showDetails,
  handleShowDetails,
  distributionTaxableIncome,
  distributionTaxableIncomeValue,
  distributionDetails,
  totalValue,
  setTaxableIncome,
  updateInputValue,
  showForm,
  handleCloseForm,
  handleNewButtonClick,
  handleSubmit,
  formPurpose,
  selectedDistribution,
  canEdit,
  isChecklist,
  handleDismissErrorMessage,
}) => {
  const initialValues = {
    fairMarketValue: '0.00',
    value: '0.00',
  };

  if (!isChecklist) {
    isChecklist = false;
  }

  return (
    <div>
      <div className="rtable">
        <h1 className="pageheader">
          Distribution Test Summary {canEdit ? '' : '- Testing Closed'}
        </h1>
      </div>
      <div className="row container-fluid">
        <section className="inline col">
          <div className="row">
            <div className="testl col-md-6">
              {distributionTestSummary.tests.map(test => (
                <DistributionTestSummaryItem
                  key={test.title}
                  test={test}
                  totalItems={distributionTestSummary.tests.length}
                />
              ))}
            </div>
            <div className="testm col-md-6">
              <div className="field">
                <label className="llabel">Dividends (Summary from Inputs)</label>
                <FormattedCurrencyInput
                  amount={totalValue}
                  className="form-control"
                  name="distributionsSummary"
                  disabled={true}
                />
              </div>
              <div className="field">
                <label id="taxableIncomeInput" className="llabel">
                  REIT Taxable Income
              </label>
                <FormattedCurrencyInput
                  amount={distributionTaxableIncomeValue || 0}
                  className="form-control"
                  name="taxableIncome"
                  disabled={!canEdit}
                  handleOnChange={updateInputValue}
                  maxLength="21"                
                />
              </div>
              {(!isChecklist && (
                <div>                  
                    <MotifButton variant="primary" type="button"
                        onClick={setTaxableIncome}
                        disabled={!canEdit}>
                          Update Results
                    </MotifButton>
                </div>
              )) ||
                null}
            </div>
          </div>
        </section>
      </div>
      {(!isChecklist && (
        <div className="row" style={{ marginTop: '45px', marginBottom: '5px' }}>
          <div className="col-md-10">
            <span className="left mr-3">Display Distribution Details:</span>
            <div className="switch-box"
              style={{
                display: 'inline-block',
                position: 'relative',
                textAlign: 'left',
              }}>
              <MotifToggleSwitch
                aria-label="Display Distribution Details toogle"
                name="switchOff"
                id="switchOff"
                onChange={handleShowDetails}
                checked={showDetails}
              />
            </div>
          </div>

          <div className="col-md-2">
            {showDetails ? (              
                          <MotifButton variant="secondary" className="right" type="button"
                onClick={handleNewButtonClick}
                disabled={!canEdit}>
                              Add Distribution
              </MotifButton>
            ) : null}
          </div>
        </div>
      )) ||
        null}

      <MotifModal
        show={showForm}
        size="lg"
        variant="alt"
        area-label={formPurpose === "ADD" ? "Add Distribution" : (formPurpose === "EDIT" ? "Edit Distribution" : "Delete Distribution")}
        onClose={handleCloseForm}
      >
        <EditDistributionForm
          onSubmit={handleSubmit}
          handleCloseForm={handleCloseForm}
          initialValues={selectedDistribution}
          formPurpose={formPurpose}
          handleDismissErrorMessage={handleDismissErrorMessage}
        />
      </MotifModal>
    </div>
  );
};

DistributionTestSummary.propTypes = {
  distributionTestSummary: PropTypes.shape({
    tests: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        isPassing: PropTypes.bool,
        percent: PropTypes.number,
      }),
    ),
    newAccounts: PropTypes.number,
    deletedAccounts: PropTypes.number,
  }).isRequired,
  distributionDetails: PropTypes.array,
  distributionTaxableIncome: PropTypes.shape({
    reportPeriod: PropTypes.string,
    reportPeriodID: PropTypes.number,
    distributionREITTaxableIncomeAmount: PropTypes.number,
    distributionDividendsSum: PropTypes.number,
  }),
  showDetails: PropTypes.bool,
  handleShowDetails: PropTypes.func,
  totalValue: PropTypes.number,
  setTaxableIncome: PropTypes.func,
  updateInputValue: PropTypes.func,
  showForm: PropTypes.bool,
  handleCloseForm: PropTypes.func,
  handleNewButtonClick: PropTypes.func,
  handleSubmit: PropTypes.func,
  formPurpose: PropTypes.string,
  selectedDistribution: PropTypes.object,
  canEdit: PropTypes.bool,
  isChecklist: PropTypes.bool,
  handleDismissErrorMessage: PropTypes.func,
};

export default DistributionTestSummary;
