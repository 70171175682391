import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import { MotifTextLink, MotifCheckbox } from '@ey-xd/motif-react';

const ClientListRow = ({ rowIndex, client, showInactive, handleToggleActiveFlag, handlClientNavigation}) => {
    const toggleActiveFlag = (e) => {
        handleToggleActiveFlag(e, client.id, client.name, client.legalName);
    };

    return (
        <tr role="row" aria-rowindex={rowIndex}>
            <td role="gridcell" aria-colindex={1} aria-label="Press ENTER to open this client."><Link to={'/client/' + client.id} className="motif-text-link motif-text-link-alt">{client.name}</Link>{client.discrepancyCount > 0 && <h5 className="ml-1 badge badge-warning badgeStandard">{client.discrepancyCount}</h5> || null}</td>
            <td className="reits-col" role="gridcell" aria-colindex={2}>{client.reitCount}</td>
            <td role="gridcell" aria-colindex={3}>{client.q1Status}</td>
            <td role="gridcell" aria-colindex={4}>{client.q2Status}</td>
            <td role="gridcell" aria-colindex={5}>{client.q3Status}</td>
            <td role="gridcell" aria-colindex={6}>{client.q4Status}</td>
            {(showInactive && 
                <td role="gridcell" aria-colindex={7}>
                    <MotifCheckbox
                        id={`activate-client-${client.id}`}
                        name="activate-client"
                        aria-label="Activate Deactivate client checkbox"
                        checked={(client.isActive && "checked") || ""}
                        onChange={toggleActiveFlag}/>
                </td>) 
                || null
            }
            
        </tr>
    );
};

ClientListRow.propTypes = {
    rowIndex: PropTypes.number,
    client: PropTypes.object.isRequired,
    showInactive: PropTypes.bool,
    handleToggleActiveFlag: PropTypes.func
};

export default ClientListRow;