import React from "react";
import PropTypes from "prop-types";
import { MotifButton, MotifIcon, MotifModal, MotifModalHeader, MotifModalBody, MotifModalFooter } from '@ey-xd/motif-react';
import { alertIcError24px } from '@ey-xd/motif-react/assets/icons';

const DeleteChecklistModal = ({
    showDeleteChecklistModal,
    handleDeleteChecklist,
    toggleShowDeleteChecklistModal,
    gridCurrentState
}) => {
    const HeaderWrapper = ({ children }) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'center'
          }}
        >
          {children}
        </div>
          );

    const closeButtonProps = {
        title: 'Close delete checklists modal',
        ariaLabel: 'Close delete checklists modal',
        dataTestid: 'close'
    };

    return (
        <MotifModal variant="alt" show={showDeleteChecklistModal} onClose={toggleShowDeleteChecklistModal} data-testid="DeleteChecklists">
            <MotifModalHeader style={{ display: "-webkit-box" }} closeButtonProps= {closeButtonProps} >
                <HeaderWrapper>
                    <MotifIcon style={{ color: "#F95D54" }} src={alertIcError24px} />
                    <span className="ml-2">Delete Checklists</span>
                </HeaderWrapper>
            </MotifModalHeader>
            <MotifModalBody>
                <span className="checklistActionMsg">Are you sure you want to delete the selected checklist(s)?</span>
                <span className="checklistActionMsg">
                    You selected {gridCurrentState && new Set(
                        gridCurrentState.filter(record => record.checklistSelection)
                            .map(record => record.checklistID + '_' + record.checklistType)
                        ).size
                    } checklist(s).
                </span>
            </MotifModalBody>
            <MotifModalFooter>
                <MotifButton variant="warn" className="right"
                    data-testid="Delete"
                    type="button"
                    onClick={handleDeleteChecklist}>Delete</MotifButton>
                <MotifButton variant="secondary" className="right"
                    data-testid="Cancel"
                    type="button"
                    onClick={toggleShowDeleteChecklistModal}>Cancel</MotifButton>
            </MotifModalFooter>
        </MotifModal>
    );
};

DeleteChecklistModal.propTypes = {
    showDeleteChecklistModal: PropTypes.bool,
    handleDeleteChecklist: PropTypes.func,
    toggleShowDeleteChecklistModal: PropTypes.func,
    gridCurrentState: PropTypes.array
};

export default DeleteChecklistModal;