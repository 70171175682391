import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Form } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import { MotifButton, MotifIcon, MotifSelect, MotifOption, MotifLabel, MotifFormField } from '@ey-xd/motif-react';
import { actionIcInfo24px } from '@ey-xd/motif-react/assets/icons';
import { addAttribute } from "../../common/Attribute";

const ScheduleChecklistHeader = ({
    calendarYears,
    isSystemOrEngagementAdmin,
    isEngagementUser,
    handleDownloadClick,
    handleCalendarYearChange,
    toggleShowUploadChecklistDeliveryScheduleModal
}) => {

    useEffect(() => {

        //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
        addAttribute("button[role = 'combobox']", "type", "button");
    });

    const calendarYearsOptions = calendarYears.map(y => {
        return {
            key: y,
            text: y,
            value: y
        }
    });
    
    return <div>
        <div style={{ minHeight: '24px'}}></div>
        <form>
            {
                calendarYears && calendarYears.length > 0 &&
                <div className="row">
                    <div className="col-md-3">
                        <div className="row">
                            <div className="col-md-6 pl-3">
                                <Field
                                    name="calendarYear"
                                    label="Calendar Year"
                                    placeholder="Select Calendar Year..."
                                    options={calendarYearsOptions}
                                    testingData={{ label: "lblCalendarYear", div: "divCalendarYear" }}
                                    component={formHelpers.SingleSelectComponent} 
                                    onChange={handleCalendarYearChange}/>
                            </div>                            
                            <div className="col-md-6" />
                        </div>
                    </div>
                    <div className="col-md-2 pl-3">
                    </div>                   
                    {(isSystemOrEngagementAdmin || isEngagementUser) &&
                    //  Show Download Delivery Schedule button if current user is System admin, Engagement admin or Engagement user
                    //  Show Upload Checklist Delivery Schedule button if current user is System admin or Engagement admin 
                        <div className="col-md-7 d-flex justify-content-end">                       
                            <div style={{ width: 'fit-content' }}>
                            {isSystemOrEngagementAdmin && <MotifButton variant="primary right ml-2" type="button"
                                onClick={toggleShowUploadChecklistDeliveryScheduleModal}
                                data-testid="btnUploadChecklistDeliverySchedule">
                                Upload Delivery Schedule
                                </MotifButton>
                            }
                                <MotifButton variant="secondary right ml-2" type="button"
                                    onClick={handleDownloadClick}
                                    data-testid="btnDownloadChecklistDeliverySchedule">
                                    Download Delivery Schedule
                                </MotifButton>
                            </div>                       
                    </div>
                    }
                </div>
            }
        </form>
    </div>;
};

ScheduleChecklistHeader.propTypes = {
    calendarYears: PropTypes.array,
    handleDownloadClick:PropTypes.func,
    handleCalendarYearChange: PropTypes.func
};

export default reduxForm({ form: "scheduleChecklist" })(ScheduleChecklistHeader);