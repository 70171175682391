import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getFormValues, getFormSyncErrors, getFormSubmitErrors, SubmissionError, reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import * as clientActions from "../../actions/clientActions";
import * as checklistActions from "../../actions/checklistActions";
import * as authActions from "../../actions/authActions";
import * as customQuestionActions from "../../actions/customQuestionActions";
import * as customChecklistTemplateActions from '../../actions/customChecklistTemplateActions';
import { MotifButton, MotifTabNavigation, MotifTabControl, MotifToast } from "@ey-xd/motif-react";
import { Link } from 'react-router';
import { pendingTask, begin, end } from "react-redux-spinner";
import * as actions from "../../actions/actionTypes";
import CustomChecklistTemplateTable from "../../components/customChecklistTemplate/CustomChecklistTemplateTable";
import CustomChecklistTemplateHeader from "../../components/customChecklistTemplate/CustomChecklistTemplateHeader";
import DeleteCustomPSQTemplateModal from "../../components/customChecklistTemplate/DeleteCustomPSQTemplateModal";
import CustomPSQTemplateValidationMessageModal from "../../components/customChecklistTemplate/CustomPSQTemplateValidationMessageModal";
import CustomPSQTemplateLockUnlockMessageModal from "../../components/customChecklistTemplate/CustomPSQTemplateLockUnlockMessageModal";
import SystemAdminUnlockTemplateModal from "../../components/customChecklistTemplate/SystemAdminUnlockTemplateModal";
import CustomPSQTemplateDeleteTemplateValidationMessageModal from "../../components/customChecklistTemplate/CustomPSQTemplateDeleteTemplateValidationMessageModal";
import CustomPSQTemplatePreviewTemplateValidationMessageModal from "../../components/customChecklistTemplate/CustomPSQTemplatePreviewTemplateValidationMessageModal";

import * as actionHelpers from '../../scripts/actionHelpers';
import { isUserActiveOfEditedTemplate } from "../../common/SignalRFunction";

/**
 * CustomChecklistTemplateContainer container component
 * @extends {React.Component}
 */
class CustomChecklistTemplateContainer extends React.Component {
  /**
   * Creates a new ChartOfAccountsPage
   * @constructor
   * @param {Object} props The component properties
   * @param {Object} context The component context
   */
  constructor(props, context) {
    super(props, context);

    this.setGridColumnState = this.setGridColumnState.bind(this);
    this.resetGridColumnState = this.resetGridColumnState.bind(this);
    this.handleRedirectFromEditTemplate = this.handleRedirectFromEditTemplate.bind(this);
    this.handleGridColumnStateChange =
      this.handleGridColumnStateChange.bind(this);
    this.handleGridChanges = this.handleGridChanges.bind(this);
    this.toggleShowDeleteCustomPSQTemplateModal =
      this.toggleShowDeleteCustomPSQTemplateModal.bind(this);
    this.toggleShowCustomPSQTemplateValidationMessageModal =
      this.toggleShowCustomPSQTemplateValidationMessageModal.bind(this);
    this.handleDeleteCustomPSQTemplate =
      this.handleDeleteCustomPSQTemplate.bind(this);
    this.handleValidationMessage = this.handleValidationMessage.bind(this);
    this.toggleShowCustomPSQTemplateLockUnlockMessageModal =
      this.toggleShowCustomPSQTemplateLockUnlockMessageModal.bind(this);
    this.toggleShowSystemAdminUnlockTemplateModal =
      this.toggleShowSystemAdminUnlockTemplateModal.bind(this);
    this.handleSystemAdminUnlockTemplate =
      this.handleSystemAdminUnlockTemplate.bind(this);
    this.lockUnlockTemplate = this.lockUnlockTemplate.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleLock = this.handleLock.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.toggleCustomPSQTemplateDeleteTemplateValidationMessageModal =
      this.toggleCustomPSQTemplateDeleteTemplateValidationMessageModal.bind(
        this
      );
    this.handlePreview = this.handlePreview.bind(this);
    this.toggleCustomPSQTemplatePreviewTemplateValidationMessageModal = this.toggleCustomPSQTemplatePreviewTemplateValidationMessageModal.bind(this);
    this.executeIsUserActiveOfEditedTemplate = this.executeIsUserActiveOfEditedTemplate.bind(this);
    this.state = {
      saved: false,
      psqTemplates: [],
      gridColumnState: [],
      gridCurrentState: [],
      gridFilteredRowCurrentState: [],
      showDeleteCustomPSQTemplateModal: false,
      showCustomPSQTemplateValidationMessageModal: false,
      editModeValidationMessage: '',
      showCustomPSQTemplateLockUnlockMessageModal: false,
      lockUnlockValidationMessage: '',
      systemAdminUnlockTemplateMessage: '',
      showSystemAdminUnlockTemplateModal: false,
      selectedChecklistTemplate: {
        checklistTemplateId: -1,
        checklistTemplateName: '',
      },
      currentUserIsSysAdmin: this.props.currentUserIsSysAdmin,
      currentUserIsEngAdmin: this.props.currentUserIsEngAdmin,
      showCustomPSQTemplateDeleteTemplateValidationMessageModal: false,
      deleteCustomPSQTemplateValidationMessage: '',
      showCustomPSQTemplatePreviewTemplateValidationMessageModal: false,
      previewCustomPSQTemplateValidationMessage: '',
    };
  }

  /**
   * Invoked immediately after mounting occurs
   * @returns {undefined}
   */
  componentDidMount() {
    this.props.customChecklistTemplateActions.fetchPSQTemplates(
      this.props.clientId
    );
    this.setGridColumnState();
    this.handleRedirectFromEditTemplate();
  }

  /**
   * Invoked immediately before unmounting occurs
   * @returns {undefined}
   */
  componentWillUnmount() {
    // On unmount, clear the calendar data from state to conserve memory
    // Calling this action with no argument will clear from redux-state
    this.props.customChecklistTemplateActions.fetchPSQTemplates();
    this.setState({ psqTemplates: [] });
  }

  componentWillReceiveProps(nextProps) {}

  // Handle Redirect from edit template UI, 
  // Show validation message when user entered edit template url in browser, and that templated is already edited by other user, and other user is active
  handleRedirectFromEditTemplate() {
      if (this.props && this.props.location && this.props.location.query && this.props.location.query.beingEditedBy && this.props.location.query.showValidationMessage) {
          let showValidationMsg = this.props.location.query.showValidationMessage;
          let beingEditedBy = this.props.location.query.beingEditedBy;
          if (showValidationMsg) {
              this.setState({
                  lockUnlockValidationMessage: 'The given checklist template is currently being edited by ' + beingEditedBy + '. The request to edit this template is denied.',
              });

              this.toggleShowCustomPSQTemplateLockUnlockMessageModal();
          }
      }
  }

  // Check whether the user is active or not
  executeIsUserActiveOfEditedTemplate(clientId, checklistTemplateId) {
      return isUserActiveOfEditedTemplate(clientId, checklistTemplateId).then((response) => {
          return response;
      });
  }

  /**
   * Set AG-Grid column state
   * @returns {undefined}
   */
  setGridColumnState() {
    var columnState = localStorage.getItem('REITSuiteTemplateColumnState');
    if (columnState) {
      this.setState({ gridColumnState: JSON.parse(columnState) });
    } else {
      this.setState({ gridColumnState: [{ colId: '-1' }] });
    }
  }

  /**
   * Reset AG-Grid column state
   * @returns {undefined}
   */
  resetGridColumnState() {
    localStorage.removeItem('REITSuiteTemplateColumnState');
    this.setState({ gridColumnState: [] });
  }

  /**
   * Handle Grid column state chagne
   * @returns {undefined}
   */
  handleGridColumnStateChange(columnState) {
    this.setState({ gridColumnState: columnState });
  }

  handleGridChanges(gridRowData) {
    let rowData = [];
    rowData =
      gridRowData &&
      gridRowData.length &&
      gridRowData.map((x) => {
        return {
          questionnaireTemplateID: x.questionnaireTemplateID,
          questionnaireTemplateName: x.questionnaireTemplateName,
          questionnaireCount: x.questionnaireCount,
          lastUpdatedDate: x.lastUpdatedDate,
          lastUpdatedBy: x.lastUpdatedBy,
          isStandardTemplate: x.isStandardTemplate,
        };
      });

    this.setState({ gridCurrentState: rowData });
  }

  toggleShowCustomPSQTemplateValidationMessageModal() {
    this.setState({
      showCustomPSQTemplateValidationMessageModal:
        !this.state.showCustomPSQTemplateValidationMessageModal,
    });
  }

  toggleShowCustomPSQTemplateLockUnlockMessageModal() {
    this.setState({
      showCustomPSQTemplateLockUnlockMessageModal:
        !this.state.showCustomPSQTemplateLockUnlockMessageModal,
    });
  }

  toggleShowSystemAdminUnlockTemplateModal() {
    this.setState({
      showSystemAdminUnlockTemplateModal:
        !this.state.showSystemAdminUnlockTemplateModal,
    });
  }

  toggleShowDeleteCustomPSQTemplateModal() {
    this.setState({
      showDeleteCustomPSQTemplateModal:
        !this.state.showDeleteCustomPSQTemplateModal,
    });
  }

  /**
   * Handles Edit button click of Grid Row
   */
  handleEdit(checklistTemplateId, checklistTemplateName) {
    this.setState({
      selectedChecklistTemplate: {
        checklistTemplateId: checklistTemplateId,
        checklistTemplateName: checklistTemplateName,
      },
    });    

  // Check whether the user who edited the template is active
  let isUserActive = false;
  let currentUser = this.props.authentication.currentUser
      ? this.props.authentication.currentUser.toLowerCase()
      : '';

  if (currentUser) {
      // Call SignalR function to check the user is active or not 
      this.executeIsUserActiveOfEditedTemplate(this.props.clientId, checklistTemplateId).then((response) => {
            
          if (response) {
              isUserActive = response.isUserActive;
              let isDeleted = response.isDeleted;
              let beingEditedBy = response.beingEditedBy ? response.beingEditedBy : '';                
              let isTemplateEditedByOtherUser = currentUser != beingEditedBy.toLowerCase();
              // If checklist template is edited by someone other user than current user and other user is still active then show validation error message
              if (isTemplateEditedByOtherUser && isUserActive && !isDeleted) {
                  this.setState({
                      lockUnlockValidationMessage: 'The given checklist template is currently being edited by ' + beingEditedBy + '. The request to edit this template is denied.',
                  });

                  this.toggleShowCustomPSQTemplateLockUnlockMessageModal();
                  this.refreshGrid();                  
              }
              else {
                  const request = {
                      clientId: this.props.clientId,
                      templateId: checklistTemplateId,
                  };

                  this.props.customChecklistTemplateActions
                      .updateChecklistTemplateToEnableEditMode(request)
                      .then((response) => {
                          // Handle Enable edit mode api execution response
                          // Show validation message for failed response
                          if (
                              response &&
                              !response.success &&
                              response.errorMessage &&
                              response.errorMessage.length > 0
                          ) {
                              this.setState({
                                  lockUnlockValidationMessage: response.errorMessage,
                              });

                              this.toggleShowCustomPSQTemplateLockUnlockMessageModal();
                              this.refreshGrid();
                          }
                          // Success response
                          else if (response && response.success) {
                              this.setState({
                                  lockUnlockValidationMessage: ''
                              });
                              this.props.router.push(
                                  `/client/${this.props.clientId}/customPsqTemplate/template/${checklistTemplateId}`
                              );                            
                            }
                    });
              }
               
          }
          else {
              //Error response from SignalR
              //TODO
          }
        });
    }   
  }

  /**
   * Handles Unlock template by System Admin
   */
  handleSystemAdminUnlockTemplate(checklistTemplateId) {
    const request = {
      clientId: this.props.clientId,
      templateId: checklistTemplateId,
      lockTemplate: false,
    };

    // Unlock Template
    this.props.customChecklistTemplateActions
      .updateChecklistTemplateToLockOrUnlock(request)
      .then((response) => {
        // Handle UnLock api execution response
        // Show validation message for failed response
        if (
          response &&
          !response.success &&
          response.errorMessage &&
          response.errorMessage.length > 0
        ) {
          this.setState({
            systemAdminUnlockTemplateMessage: '',
          });
          // Close current modal popup
          this.toggleShowSystemAdminUnlockTemplateModal();

          this.setState({
            lockUnlockValidationMessage: response.errorMessage,
          });

          this.toggleShowCustomPSQTemplateLockUnlockMessageModal();
        } else {
          this.setState({
            lockUnlockValidationMessage: '',
            systemAdminUnlockTemplateMessage: '',
          });

          // Close current modal popup
          this.toggleShowSystemAdminUnlockTemplateModal();
        }
        this.refreshGrid();
      });
  }

  /**
   * Handles Lock/Unlock button click of Grid Row
   */
  handleLock(
    checklistTemplateId,
    checklistTemplateName,
    lockChecklistTemplate
  ) {
    this.setState({
      selectedChecklistTemplate: {
        checklistTemplateId: checklistTemplateId,
        checklistTemplateName: checklistTemplateName,
      },
    });

    // Check whether the user who has edited the template is active
    let isUserActive = false;
    let currentUser = this.props.authentication.currentUser
        ? this.props.authentication.currentUser.toLowerCase()
        : '';

    if (currentUser) {
        // Call SignalR function to check the user is active or not 
        this.executeIsUserActiveOfEditedTemplate(this.props.clientId, checklistTemplateId).then((response) => {            
            if (response) {
                isUserActive = response.isUserActive;
                let isDeleted = response.isDeleted;
                let beingEditedBy = response.beingEditedBy ? response.beingEditedBy : '';
                let isTemplateEditedByOtherUser = currentUser != beingEditedBy.toLowerCase();
                
                // If checklist template is edited by someone other user than current user and other user is still active then show validation error message
                if (isTemplateEditedByOtherUser && isUserActive && !isDeleted) {
                    if (lockChecklistTemplate) {
                        this.setState({
                            lockUnlockValidationMessage: 'The given checklist template is currently being edited by ' + beingEditedBy + '. The request to lock this template is denied.',
                        });
                    }
                    else {
                        this.setState({
                            lockUnlockValidationMessage: 'The given checklist template is currently being edited by ' + beingEditedBy + '. The request to unlock this template is denied.',
                        });
                    }

                    this.toggleShowCustomPSQTemplateLockUnlockMessageModal();
                    this.refreshGrid();
                                    
                }
                else {
                    // Checklist Template is not edited by other user 

                    // This scenario is for System admin trying to unlock template and that template is locked by some other user
                    //let currentUser = this.props.authentication.currentUser
                    //  ? this.props.authentication.currentUser.toLowerCase()
                    //  : '';
                    if (!lockChecklistTemplate && this.props.currentUserIsSysAdmin == true) {
                        const templateRequest = {
                            clientId: this.props.clientId,
                            templateId: checklistTemplateId,
                        };

                        this.props.customChecklistTemplateActions
                            .fetchChecklistTemplateByChecklistTemplateId(templateRequest)
                            .then((response) => {
                                if (response) {
                                    // If template is locked then get template details
                                    if (
                                        (!response.errorResponse || !response.errorResponse.errorCode) &&
                                        response.isLocked
                                    ) {
                                        let lockedBy = response.lockedBy
                                            ? response.lockedBy.toLowerCase()
                                            : '';
                                        let isEditMode = response.isEditMode;
                                        let beingEditedBy = response.beingEditedBy
                                            ? response.beingEditedBy.toLowerCase()
                                            : '';
                                        let isDeleted = response.isDeleted;
                                        // Check whether template is deleted
                                        if (isDeleted) {
                                            this.setState({
                                                lockUnlockValidationMessage:
                                                    'The given checklist template has been deleted.',
                                            });

                                            this.toggleShowCustomPSQTemplateLockUnlockMessageModal();
                                            this.refreshGrid();
                                        }

                                        // Check whether template is locked by other user then show confirmation popup for System admin
                                        else if (response.isLocked && currentUser != lockedBy) {
                                            this.setState({
                                                systemAdminUnlockTemplateMessage: `This template is currently locked by ${lockedBy}. Unlocking the template makes it available to other users for updates.`,
                                            });

                                            this.toggleShowSystemAdminUnlockTemplateModal();
                                            this.refreshGrid();
                                        }

                                        // At this point as all validation is done for system admin then good to unlock the template
                                        else {
                                            const request = {
                                                clientId: this.props.clientId,
                                                templateId: checklistTemplateId,
                                                lockTemplate: lockChecklistTemplate,
                                            };
                                            this.lockUnlockTemplate(request);
                                        }
                                    }
                                }
                            });
                    } else {
                        // Lock/Unlock template - Non System admin user
                        const request = {
                            clientId: this.props.clientId,
                            templateId: checklistTemplateId,
                            lockTemplate: lockChecklistTemplate,
                        };
                        this.lockUnlockTemplate(request);
                    }
                }
            }
            else {
                //Error response from SignalR
                //TODO
            }
        });
      }    
  }

  /**
   * Lock/Unlock Template
   */
  lockUnlockTemplate(request) {
    this.props.customChecklistTemplateActions
      .updateChecklistTemplateToLockOrUnlock(request)
      .then((response) => {
        // Handle Lock api execution response
        // Show validation message for failed response
        if (
          response &&
          !response.success &&
          response.errorMessage &&
          response.errorMessage.length > 0
        ) {
          this.setState({
            lockUnlockValidationMessage: response.errorMessage,
          });

          this.toggleShowCustomPSQTemplateLockUnlockMessageModal();
        } else {
          this.setState({
            lockUnlockValidationMessage: '',
          });
        }
        this.refreshGrid();
      });
  }

  /**
   * Handles Delete button click of Grid Row
   */
  handleDelete(checklistTemplateId, checklistTemplateName) {
    const templateRequest = {
      clientId: this.props.clientId,
      templateId: checklistTemplateId,
    };

    this.props.customChecklistTemplateActions
      .fetchChecklistTemplateByChecklistTemplateId(templateRequest)
      .then((response) => {
        if (response) {
          // If template is locked then get template details
          if (!response.errorResponse && !response.errorResponse?.errorCode) {
            const isLocked = response.isLocked;
            const lockedBy = response.lockedBy
              ? response.lockedBy.toLowerCase()
              : '';
            const isEditMode = response.isEditMode;
            const beingEditedBy = response.beingEditedBy
              ? response.beingEditedBy.toLowerCase()
              : '';
            const isDeleted = response.isDeleted;
            // Check whether template is already deleted by some other user
            if(isDeleted) {
              this.setState({
                showCustomPSQTemplateDeleteTemplateValidationMessageModal:
                  !this.state
                    .showCustomPSQTemplateDeleteTemplateValidationMessageModal,
                deleteCustomPSQTemplateValidationMessage: `The given checklist template has been deleted.`,
              });

              this.refreshGrid();
            }
            // Check whether template is locked by other user, then show confirmation popup
            else if (isLocked) {
              this.setState({
                showCustomPSQTemplateDeleteTemplateValidationMessageModal:
                  !this.state
                    .showCustomPSQTemplateDeleteTemplateValidationMessageModal,
                deleteCustomPSQTemplateValidationMessage: `The Checklist Template is already locked by ${lockedBy}. The request to delete this template is denied.`,
              });

              this.refreshGrid();
            }
            // Check whether template is edited by other user, then show confirmation popup
            else if (isEditMode) {
              this.setState({
                showCustomPSQTemplateDeleteTemplateValidationMessageModal:
                  !this.state
                    .showCustomPSQTemplateDeleteTemplateValidationMessageModal,
                deleteCustomPSQTemplateValidationMessage: `The Checklist Template is currently being edited by ${beingEditedBy}. The request to delete this template is denied.`,
              });

              this.refreshGrid();
            }
            // Display the delete confirmation popup
            else {
              this.setState({
                selectedChecklistTemplate: {
                  checklistTemplateId: checklistTemplateId,
                  checklistTemplateName: checklistTemplateName,
                },
              });
              this.toggleShowDeleteCustomPSQTemplateModal();
            }
          }
        }
      });
  }

  /**
   * Handles Delete button click on Delete Custom PSQ Modal Popup
   * Delete Custom PSQ Template
   */
  handleDeleteCustomPSQTemplate(checklistTemplateId) {
    this.props.checklistActions
      .deleteCustomPSQTemplate(this.props.clientId, checklistTemplateId)
      .then(() => {
        this.toggleShowDeleteCustomPSQTemplateModal();
        this.refreshGrid();
      });
  }

  /**
   * Handles hide event of Delete Template Validation Modal
   */
  toggleCustomPSQTemplateDeleteTemplateValidationMessageModal() {
    this.setState({
      showCustomPSQTemplateDeleteTemplateValidationMessageModal:
        !this.state.showCustomPSQTemplateDeleteTemplateValidationMessageModal,
      deleteCustomPSQTemplateValidationMessage: '',
    });
  }

  /**
   * Handles Template Preview
   */
  handlePreview(checklistTemplateId, targetUrl) {
    const templateRequest = {
      clientId: this.props.clientId,
      templateId: checklistTemplateId,
    };

    this.props.customChecklistTemplateActions
      .fetchChecklistTemplateByChecklistTemplateId(templateRequest)
      .then((response) => {
        if (response) {
          // If template is locked then get template details
          if (!response.errorResponse && !response.errorResponse?.errorCode) {
            const isLocked = response.isLocked;
            const lockedBy = response.lockedBy
              ? response.lockedBy.toLowerCase()
              : '';
            const isEditMode = response.isEditMode;
            const beingEditedBy = response.beingEditedBy
              ? response.beingEditedBy.toLowerCase()
              : '';
            const isDeleted = response.isDeleted;
            const templateClientId = response.clientID;
            // Check whether template is already deleted by some other user
            if(isDeleted) {
              this.setState({
                showCustomPSQTemplatePreviewTemplateValidationMessageModal:
                  !this.state
                    .showCustomPSQTemplatePreviewTemplateValidationMessageModal,
                previewCustomPSQTemplateValidationMessage: `The given checklist template has been deleted.`,
              });

              this.refreshGrid();
            }
           
         
            // Open the template preview in a new tab
            else {
              if (response.clientID != -1) {
                this.props.customChecklistTemplateActions.fetchCustomPsqTemplateContentById(this.props.clientId, checklistTemplateId)
                  .then(result => {
                    if (result.length > 0) {
                      window.open(targetUrl, '_blank');
                    }
                    else {
                      this.setState({
                        showCustomPSQTemplatePreviewTemplateValidationMessageModal:
                          !this.state
                            .showCustomPSQTemplatePreviewTemplateValidationMessageModal,
                        previewCustomPSQTemplateValidationMessage: `The selected checklist does not contain sections or questions. Please add a section or question.`,
                      });
                    }
                  });

              }
              else {
                window.open(targetUrl, '_blank');
              }
            }
          }
        }
      });
  }

   /**
   * Handles hide event of Preview Template Validation Modal
   */
  toggleCustomPSQTemplatePreviewTemplateValidationMessageModal() {
    this.setState({
      showCustomPSQTemplatePreviewTemplateValidationMessageModal:
        !this.state.showCustomPSQTemplatePreviewTemplateValidationMessageModal,
      previewCustomPSQTemplateValidationMessage: '',
    });
  }

  /**
   * Handles Validation message button click on Custom PSQ Validation Message Modal Popup
   * Close the popup
   */
  handleValidationMessage() {
    this.toggleShowCustomPSQTemplateValidationMessageModal();
  }

  /**
   * Refresh grid
   */
  refreshGrid() {
    this.props.customChecklistTemplateActions.fetchPSQTemplates(
      this.props.clientId
    );
  }

  /**
   * Render a React element
   * @returns {Object} A reference to the component
   */
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col">
            <h1 className="pageheader">Custom PSQ Templates</h1>
          </div>
        </div>
        <CustomChecklistTemplateHeader
          clientId={this.props.clientId}
          currentRoute={this.props.location.pathname}
          currentUserIsSysAdmin={authActions.isSystemAdministrator(this.props.currentUserAuthorizations)}
          currentUserIsEngAdmin={authActions.isEngagementAdministrator(this.props.currentUserAuthorizations, this.props.clientId)}
          currentUserIsEngagementUser={authActions.isUser(this.props.currentUserAuthorizations, this.props.clientId)}
        />
        {this.props.psqTemplates && this.props.psqTemplates.length === 0 && (
          <div>No PSQ Templates found for this client.</div>
        )}
        <CustomChecklistTemplateTable
          formSyncErrors={this.props.formSyncErrors}
          clientId={this.props.clientId}
          psqTemplates={this.props.psqTemplates}
          gridColumnState={this.state.gridColumnState}
          handleGridColumnStateChange={this.handleGridColumnStateChange}
          handleGridChanges={this.handleGridChanges}
          handleEdit={this.handleEdit}
          handleDelete={this.handleDelete}
          handleLock={this.handleLock}
          handlePreview={this.handlePreview}          
          currentUser={this.props.authentication.currentUser}
          currentUserIsSysAdmin={authActions.isSystemAdministrator(this.props.currentUserAuthorizations)}
          currentUserIsEngAdmin={authActions.isEngagementAdministrator(this.props.currentUserAuthorizations, this.props.clientId)}
          currentUserIsEngagementUser={authActions.isUser(this.props.currentUserAuthorizations, this.props.clientId)}
            />
          <DeleteCustomPSQTemplateModal
          showDeleteCustomPSQTemplateModal={
            this.state.showDeleteCustomPSQTemplateModal
          }
          handleDeleteCustomPSQTemplate={this.handleDeleteCustomPSQTemplate}
          toggleShowDeleteCustomPSQTemplateModal={
            this.toggleShowDeleteCustomPSQTemplateModal
          }
          checklistTemplate={this.state.selectedChecklistTemplate}
        />
        <CustomPSQTemplateValidationMessageModal
          showCustomPSQTemplateValidationMessageModal={
            this.state.showCustomPSQTemplateValidationMessageModal
          }
          handleValidationMessage={this.handleValidationMessage}
          toggleShowCustomPSQTemplateValidationMessageModal={
            this.toggleShowCustomPSQTemplateValidationMessageModal
          }
          editModeValidationMessage={this.state.editModeValidationMessage}
        />
        <CustomPSQTemplateLockUnlockMessageModal
          showCustomPSQTemplateLockUnlockMessageModal={
            this.state.showCustomPSQTemplateLockUnlockMessageModal
          }
          toggleShowCustomPSQTemplateLockUnlockMessageModal={
            this.toggleShowCustomPSQTemplateLockUnlockMessageModal
          }
          lockUnlockValidationMessage={this.state.lockUnlockValidationMessage}
        />
        <SystemAdminUnlockTemplateModal
          showSystemAdminUnlockTemplateModal={
            this.state.showSystemAdminUnlockTemplateModal
          }
          handleSystemAdminUnlockTemplate={this.handleSystemAdminUnlockTemplate}
          toggleShowSystemAdminUnlockTemplateModal={
            this.toggleShowSystemAdminUnlockTemplateModal
          }
          systemAdminUnlockTemplateMessage={
            this.state.systemAdminUnlockTemplateMessage
          }
          checklistTemplate={this.state.selectedChecklistTemplate}
        />
        <CustomPSQTemplateDeleteTemplateValidationMessageModal
          showCustomPSQTemplateDeleteTemplateValidationMessageModal={
            this.state.showCustomPSQTemplateDeleteTemplateValidationMessageModal
          }
          toggleCustomPSQTemplateDeleteTemplateValidationMessageModal={
            this.toggleCustomPSQTemplateDeleteTemplateValidationMessageModal
          }
          validationMessage={
            this.state.deleteCustomPSQTemplateValidationMessage
          }
        />
        <CustomPSQTemplatePreviewTemplateValidationMessageModal
          showCustomPSQTemplatePreviewTemplateValidationMessageModal={
            this.state.showCustomPSQTemplatePreviewTemplateValidationMessageModal
          }
          toggleCustomPSQTemplatePreviewTemplateValidationMessageModal={
            this.toggleCustomPSQTemplatePreviewTemplateValidationMessageModal
          }
          validationMessage={
            this.state.previewCustomPSQTemplateValidationMessage
          }
        />
      </React.Fragment>
    );
  }
}

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    let cid = Number.parseInt(ownProps.params.clientId);
    if (isNaN(cid)) {
        cid = 0;
    }
    return {
        clientId: cid,
        clientIsAudit: state.client && state.client.isAudit,
        psqTemplates: state.psqTemplates,
        currentUserAuthorizations: state.currentUserAuthorizations,
        currentUserIsSysAdmin: Array.isArray(state.currentUserAuthorizations) && (authActions.isSystemAdministrator(state.currentUserAuthorizations)),
        currentUserIsEngAdmin: Array.isArray(state.currentUserAuthorizations) && (authActions.isEngagementAdministrator(state.currentUserAuthorizations, cid)),
        authentication: state.authentication,
        formSyncErrors: getFormSyncErrors('customChecklistTemplate')(state),
        formSubmitErrors: getFormSubmitErrors('customChecklistTemplate')(state)
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        clientActions: bindActionCreators(clientActions, dispatch),
        checklistActions: bindActionCreators(checklistActions, dispatch),
        customQuestionActions: bindActionCreators(customQuestionActions, dispatch),
        customChecklistTemplateActions: bindActionCreators(customChecklistTemplateActions, dispatch)      
    };
}

CustomChecklistTemplateContainer.propTypes = {
    clientId: PropTypes.number,
    clientIsAudit: PropTypes.bool,
    psqTemplates: PropTypes.array,
    clientActions: PropTypes.object,
    customQuestionActions: PropTypes.object,
    checklistActions: PropTypes.object,
    customChecklistTemplateActions: PropTypes.object,
    currentUserAuthorizations: PropTypes.array,
    currentUserIsSysAdmin: PropTypes.bool.isRequired,
    currentUserIsEngAdmin: PropTypes.bool.isRequired,
    authentication: PropTypes.string,
    initialize: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: "customChecklistTemplate", enableReinitialize: true })(CustomChecklistTemplateContainer));