import { pendingTask, begin, end } from 'react-redux-spinner';
import * as actions from "./actionTypes";
import { fetchFromReitApi as fetch } from "./fetchFromApi";
import * as actionHelpers from '../scripts/actionHelpers';
import * as reitActions from './reitActions';

/**
 * Fetch all services scoped for the REIT period. If successful this will dispatch the LOAD_SERVICES_SUCCESS
 * action, otherwise it will dispatch the LOAD_SERVICES_FAILURE action.
 * @param {number} periodId The ID of the period that has the services.
 * @param {object} authHeader The (optional) authorization header.
 * @returns {funtion} A function that returns a Promise.
 */
export function fetchServicesByPeriod(periodId, authHeader) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        unloadServices();
        return fetchServicesByPeriodWithoutDispatch(periodId, authHeader).then(services => {
            if (actionHelpers.isErrorResponse(services)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_SERVICES_FAILURE, services);
            }

            return dispatch({ type: actions.LOAD_SERVICES_SUCCESS, services, [pendingTask]: end });
            }).catch(error => {
                actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_SERVICES_FAILURE, null, error);
        });
    };
}

/**
 * Dispatches the Unload Services action.
 * @returns {funtion} A function that returns a Promise.
 */
export function unloadServices(){
    return function (dispatch){
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return dispatch({ type: actions.UNLOAD_SERVICES, [pendingTask]: end });
    };
}

/**
 * For the tabbed navigation component, fetch all services scoped for the REIT period. If successful this will dispatch the LOAD_NAVIGATION_SERVICES_SUCCESS
 * action, otherwise it will dispatch the LOAD_NAVIGATION_SERVICES_FAILURE action.
 * @param {number} periodId The ID of the period that has the services.
 * @param {object} authHeader The (optional) authorization header.
 * @returns {funtion} A function that returns a Promise.
 */
export function fetchNavigationServicesByPeriod(periodId, authHeader) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        unloadNavigationServices();
        return fetchServicesByPeriodWithoutDispatch(periodId, authHeader).then(navigationServices => {
            if (actionHelpers.isErrorResponse(navigationServices)) {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_NAVIGATION_SERVICES_FAILURE, navigationServices);
            }

            return dispatch({ type: actions.LOAD_NAVIGATION_SERVICES_SUCCESS, navigationServices, [pendingTask]: end });
            }).catch(error => {
                actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_NAVIGATION_SERVICES_FAILURE, null, error);
        });
    };
}

/**
 * Dispatches the Unload Navigation Services action.
 * @returns {funtion} A function that returns a Promise.
 */
export function unloadNavigationServices(){
    return function (dispatch){
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return dispatch({ type: actions.UNLOAD_NAVIGATION_SERVICES, [pendingTask]: end });
    };
}

/**
 * Fetch all services scoped for the REIT period as an external user.If successful this will dispatch the LOAD_SERVICES_SUCCESS
 * action, otherwise it will dispatch the LOAD_SERVICES_FAILURE action.
 * @param {number} periodId The id of the period that owns the TRS.
 * @param {string} checklistId The id of the checklist.
 * @param {string} checklistType The checklist type.
 * @returns {funtion} A function that returns a Promise.
 */
export function fetchExternalServicesByPeriod(periodId, checklistId, checklistType) {
    return fetchServicesByPeriod(periodId, actionHelpers.buildAuthHeader(checklistId, checklistType));
}

/**
 * Fetch all services scoped for the REIT period and return the data from the response.
 * @param {number} periodId The ID of the period that has the services.
 * @param {object} authHeader The (optional) authorization header.
 * @returns {any} An array of services, or an error response if unsuccessful.
 */
export function fetchServicesByPeriodWithoutDispatch(periodId, authHeader) {
    if (!periodId) {
        periodId = -1;
    }
	return fetch(`/services?periodId=${periodId}`,
            {
                headers: (authHeader && new Headers({ 'Authorization': authHeader })) || null
            }).then(response => {
		return response.json();
	});
}

/**
 * Saves a services array to the REIT API database.
 * @param {number} reitId The ID of the REIT.
 * @param {number} periodId The ID of the period to fetch services for.
 * @param {any} services The services array
 * @returns {Promise} A Promise
 */
export function saveServices(reitId, periodId, services) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        const payload = services;
        return fetch(`/services/${periodId}`,
            {
                headers: {
                    'Accept':
                        "application/json, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    'Content-Type': "application/json"
                },
                method: "PUT",
                body: JSON.stringify(payload)
            }).then(response => {
                if (response.ok) {
                    return null;
                }

                return response.json();
            }).then(errorResponse => {
                if (actionHelpers.isErrorResponse(errorResponse)) {
                    return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.CREATE_SERVICES_FAILURE, errorResponse);
                }
                
                dispatch({ type: actions.CREATE_SERVICES_SUCCESS, [pendingTask]: end });
                
                if (reitId > 0) {                    
                    reitActions.fetchReit(reitId)(dispatch);
                }
            }).catch(error => {
                actionHelpers.dispatchErrorAndEndTask(dispatch, actions.CREATE_SERVICES_FAILURE, null, error);
        });
    };
}

