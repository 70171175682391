import { pendingTask, begin, end } from 'react-redux-spinner';
import * as actions from './actionTypes';
import { fetchFromReportApi as fetchReport, fetchFromTrialBalanceApi as fetchTb } from './fetchFromApi';
import { getTrialBalancesByPeriod as getTrialBalancesByPeriod } from './trialBalanceActions';
import * as actionHelpers from '../scripts/actionHelpers';
import { ApiError } from '../scripts/errors';
import * as reitActions from './reitActions';

/**
 * Retrieves the asset summary
 * @param {any} periodId The ID of the period.
 * @param {any} authHeader The Authorization header.  This will be generated if not supplied.
 * @returns {any} A Promise.
 * Used by fetchAssetSummary and fetchAssetDetailsForYear, both of which handle the dispatching
 */
export function fetchAssetSummaryWithoutDispatch(periodId, authHeader) {
    return getTrialBalancesByPeriod(periodId, 'adjusted', 'consolidated', null, authHeader).then(tb => {
        if (actionHelpers.isErrorResponse(tb)) {
            // Throw an ApiError with the error response so the methods handling the dispatching can
            // catch this error and dispatch it
            throw new ApiError(tb);
        }

        let trialBalanceId = (tb.length > 0 && tb[0].id || '-1');

        return fetchReport('/assetTest?periodId=' + periodId + '&trialBalanceId=' + trialBalanceId,
            {
                headers: (authHeader && new Headers({ 'Authorization': authHeader })) || null
            });
    }).then(response => {
        return response.json();
    }).then(assetSummary => {
        if (actionHelpers.isErrorResponse(assetSummary)) {
            // Throw an ApiError with the error response so the methods handling the dispatching can
            // catch this error and dispatch it
            throw new ApiError(assetSummary);
        }

        return assetSummary;
    });
}

/**
 * Retrieves the asset summary and adds it to state.
 * @param {any} periodId The ID of the period.
 * @param {any} authHeader The Authorization header.  This will be generated if not supplied.
 * @returns {any} A function that returns a Promise.
 */
export function fetchAssetSummary(periodId, authHeader) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        return fetchAssetSummaryWithoutDispatch(periodId, authHeader).then(assetSummary => {
            if (Array.isArray(assetSummary.details)) {
                dispatch({ type: actions.LOAD_ASSET_DETAIL_SUCCESS, assetDetails: assetSummary.details });
            }

            return dispatch({ type: actions.LOAD_ASSET_SUMMARY_SUCCESS, assetSummary, [pendingTask]: end });
        }).catch(error => {
            if (error instanceof ApiError && actionHelpers.isErrorResponse(error.data)) {
                return dispatch({ type: actions.LOAD_ASSET_SUMMARY_FAILURE, errorResponse: error.data, [pendingTask]: end });
            }

            return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_ASSET_SUMMARY_FAILURE, null, error);
        });
    };
}

/**
 * Retrieves the asset details for the year
 * @param {any} currentPeriodId The ID period
 * @param {any} checklistId The ID of the checklist
 * @param {any} checklistType The checklist type (PSQ or ARQC)
 * @returns {any} A function that returns a Promise
 */
export function fetchAssetDetailsForYear(currentPeriodId, checklistId, checklistType) {
    let authHeader = null;
    if (checklistId && checklistType) {
        authHeader = actionHelpers.buildAuthHeader(checklistId, checklistType);
    }

    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        return reitActions.fetchReitByPeriodId(currentPeriodId, checklistId, checklistType).then(reit => {
            if (actionHelpers.isErrorResponse(reit)) {
                return dispatch({ type: actions.LOAD_ASSET_DETAILS_FOR_YEAR_FAILURE, errorResponse: reit, [pendingTask]: end });
            }

            // We're only interested in quarterly periods right now
            const quarterlyPeriods = reit.periods.filter(x => x.reportPeriodTypeDescription.toLowerCase() === 'quarterly');
            // Sort the periods by date descenting
            const periods = quarterlyPeriods.sort((a, b) => new Date(b.endDate) - new Date(a.endDate));

            // Locate the index of the current period
            const idx = periods.findIndex(x => x.reportPeriodID === currentPeriodId);

            let year = 0;
            let promises = [];

            for (let i = idx; i < periods.length; i++) {
                let period = periods[i];
                let startDate = new Date(period.startDate);

                // If this period is the current period, get the year,
                // otherwise if the period is not the same year as the current period, exit the loop
                if (i === idx) {
                    year = startDate.getFullYear();
                } else if (year !== startDate.getFullYear()) {
                    break;
                }

                // This returns a promise
                let p = fetchAssetSummaryWithoutDispatch(period.reportPeriodID, authHeader).then(assetSummary => {

                    // If we received asset test details, return an object with the details and the quarter number
                    if (Array.isArray(assetSummary.details)) {
                        // Calculate the quarter number - remember January = 0
                        let quarter = Math.floor((startDate.getMonth() + 3) / 3);

                        return { quarter: quarter, assetDetails: assetSummary.details };
                    }
                });

                // Add the promise to the promises array
                promises.push(p);
            }

            // Wait for all promises in the promises array to resolve before dispatching success
            Promise.all(promises).then(values => {
                if (Array.isArray(values)) {
                    // Sort the values from lowest quarter to highest
                    values = values.sort((a, b) => {
                        if (a.quarter < b.quarter) {
                            return -1;
                        }

                        if (a.quarter > b.quarter) {
                            return 1;
                        }

                        return 0;
                    });
                }

                // Dispatch success with assetDetails
                return dispatch({ type: actions.LOAD_ASSET_DETAILS_FOR_YEAR_SUCCESS, assetDetails: values, [pendingTask]: end });
            });


        }).catch(error => {
            if (error instanceof ApiError && actionHelpers.isErrorResponse(error.data)) {
                return dispatch({ type: actions.LOAD_ASSET_DETAILS_FOR_YEAR_FAILURE, errorResponse: error.data, [pendingTask]: end });
            }

            return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_ASSET_DETAILS_FOR_YEAR_FAILURE, null, error);
        });
    };
}

/**
 * Fetch the external asset test summary belonging to the checklist. If successful this will dispatch the LOAD_ASSET_SUMMARY_SUCCESS
 * action, otherwise it will dispatch the LOAD_ASSET_SUMMARY_FAILURE action.
 * @param {number} periodId The id of the period.
 * @param {number} checklistId The id of the external checklist.
 * @param {string} checklistType The type of the external checklist.
 * @returns {function} A function that returns a Promise.
 */
export function fetchExternalAssetSummaryByChecklistId(periodId, checklistId, checklistType) {
    return fetchAssetSummary(periodId, actionHelpers.buildAuthHeader(checklistId, checklistType));
}