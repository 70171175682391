import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Button, Icon, Input } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import * as formValidators from "../../scripts/formValidators";
import { DateField, MoneyField, NumberField } from "../shared/formFields";
import { MotifButton, MotifModalHeader, MotifModalBody, MotifModalFooter, MotifIcon } from '@ey-xd/motif-react';
import { alertIcError24px } from '@ey-xd/motif-react/assets/icons';

const editSecuritiesSnapshotForm = ({ handleSubmit, handleCloseForm, initialValues, formPurpose, handleEditButtonClick, handleDeleteButtonClick, error, submitting, handleDismissErrorMessage }) => {

    const modalPurpose = formPurpose === "" ? 'Add New' : (formPurpose === "EDIT" ? 'Edit' : 'Delete');
    
    const closeButtonProps = {
        title: 'Close ' + modalPurpose + ' Snapshot Modal',
        ariaLabel: 'Close ' + modalPurpose + ' Snapshot Modal',
        dataTestid: 'close',
    };

    const HeaderWrapper = ({ children }) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'center',
          }}
        >
          {children}
        </div>
    );

    return (
        <form onSubmit={handleSubmit} className="securitiesSnapshot">
            <MotifModalHeader style={{ display: '-webkit-box' }} closeButtonProps={closeButtonProps}>
                <HeaderWrapper>
                    {formPurpose === "DELETE" && <MotifIcon style={{ color: '#F95D54' }} src={alertIcError24px} className="mr-2" />}
                    <span>{(formPurpose === "" && "Add New Snapshot") || formPurpose === "EDIT" && "Edit Snapshot" || "Delete Snapshot?"}</span>
                </HeaderWrapper>
            </MotifModalHeader>
            <MotifModalBody>
                <Field name="securitiesSnapshotID" type="hidden" component="input" label="" disabled={(formPurpose === "DELETE") ? "disabled" : ""} />
                <Field name="reportPeriodID" type="hidden" component="input" label="" disabled={(formPurpose === "DELETE") ? "disabled" : ""} />
                <div className="row mt-3">
                    <br />
                    {formHelpers.showSubmissionError(error, handleDismissErrorMessage)}
                    <div className="field col-md-6">
                        <Field
                            name="securityName"
                            type="text"
                            component={formHelpers.renderField}
                            label="Name"
                            disabled={(formPurpose === "DELETE") ? "disabled" : ""}
                            maxLength={255}
                            isRequiredField={true}
                            validate={[formValidators.required, formValidators.maxLength255, formValidators.noWindowSpecialChars]}
                            data-testid="txtSecurityName"
                        />
                    </div>
                    <div className="field col-md-6">
                        <Field
                            name="issuer"
                            type="text"
                            component={formHelpers.renderField}
                            label="Issuer"
                            disabled={(formPurpose === "DELETE") ? "disabled" : ""}
                            maxLength={255}
                            isRequiredField={true}
                            validate={[formValidators.required, formValidators.maxLength255]}
                            data-testid="txtIssuer"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="field col-md-6">
                        <Field
                            name="class"
                            type="text"
                            component={formHelpers.renderField}
                            label="Class"
                            disabled={(formPurpose === "DELETE") ? "disabled" : ""}
                            isRequiredField={true}
                            maxLength={255}
                            validate={[formValidators.required, formValidators.maxLength255]}
                            data-testid="txtClass"
                        />
                    </div>

                    <div className="field col-md-6">
                        <MoneyField
                            name="reitOwnedSharesValue"
                            placeholder="0.00"
                            label="Owned Shares Value"
                            disabled={(formPurpose === "DELETE") ? "disabled" : ""}
                            isRequiredField={true}
                            validate={(formPurpose === "DELETE") ? [formValidators.required, formValidators.number] : [formValidators.required, formValidators.number, formValidators.greaterThan0]}
                            data-testid="curOwnedSharesValue"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="field col-md-12">
                        <MoneyField
                            name="allSharesFromIssuerValue"
                            placeholder="0.00"
                            label="Value of all Shares from Issuer"
                            disabled={(formPurpose === "DELETE") ? "disabled" : ""}
                            isRequiredField={true}
                            validate={(formPurpose === "DELETE") ? [formValidators.required, formValidators.number] : [formValidators.required, formValidators.number, formValidators.greaterThan0]}
                            data-testid="curAllSharesFromIssuerValue"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="field col-md-12">
                        <NumberField
                            name="percentOfVote"
                            placeholder="0.00"
                            label="Percent of Vote"
                            allowDecimal={true}
                            disabled={(formPurpose === "DELETE") ? "disabled" : ""}
                            isRequiredField={true}
                            validate={[formValidators.required, formValidators.number]}
                            data-testid="numPercentOfVote"
                        />
                    </div>
                </div>
            </MotifModalBody>
            <MotifModalFooter>
                <MotifButton variant={formPurpose === "DELETE" ? "warn" : "primary"} type="submit"
                    value="Submit" disabled={submitting} 
                    data-testid={"btn" + (formPurpose === "" ? "Submit" : (formPurpose === "EDIT" ? "Save" : "Delete") )}>
                    {(formPurpose === "" && <React.Fragment>Submit</React.Fragment>) ||
                        formPurpose === "EDIT" && <React.Fragment>Save</React.Fragment> ||
                        <React.Fragment>Delete</React.Fragment>}
                </MotifButton>
                <MotifButton variant="secondary" type="button"
                    onClick={handleCloseForm} data-testid="btnCancel">Cancel</MotifButton>
            </MotifModalFooter>
        </form>
    );
};

editSecuritiesSnapshotForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleCloseForm: PropTypes.func.isRequired,
    handleEditButtonClick: PropTypes.func,
    handleDeleteButtonClick: PropTypes.func,
    handleDismissErrorMessage: PropTypes.func,
    formPurpose: PropTypes.string,
    initialValues: PropTypes.object,
    error: PropTypes.string,
    submitting: PropTypes.bool
};

export default reduxForm({ form: "securitiesSnapshot" })(editSecuritiesSnapshotForm);