import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Icon, Progress, Button, Header } from 'semantic-ui-react';
import ReactModal from "react-modal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as checklistActions from "../../actions/checklistActions";
import { MotifButton, MotifModal, MotifModalHeader, MotifModalBody, MotifModalFooter, MotifIcon, MotifTextLink } from '@ey-xd/motif-react';
import * as Constants from '../../constants/other';
import { fileIcCloudUpload24px } from '@ey-xd/motif-react/assets/icons';


/**
 * Container component used for uploading Checklist Delivery Schedules
 */
export class ChecklistDeliveryScheduleUploadModal extends React.Component {
    /**
     * Creates a new ChecklistDeliveryScheduleUploadModal
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);

        this.renderUploadStatus = this.renderUploadStatus.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.state = {
            status: null,
            rejectedFiles: [],
            acceptedFiles: [],
            validationErrors: [],
            closedReportPeriodExists: false,
            closedReportPeriodExistsMessage: ''
        };

        this.acceptedFileExtensions = '.xlsx';
    }

    /**
     * Handle Close popup
     */
    handleClose() {
        this.setState({ status: null, rejectedFiles: [], acceptedFiles: [], validationErrors: [] });
        this.props.refreshScheduleChecklists(this.props.clientId, this.props.scheduleChecklistValues.calendarYear);
        this.props.toggleShowUploadChecklistDeliveryScheduleModal();
    }

    /**
     * Hanle Drop event
     */
    onDrop(acceptedFiles, rejectedFiles) {
        if (Array.isArray(rejectedFiles) && rejectedFiles.length > 0) {
            this.setState({ status: 'Rejected', rejectedFiles, acceptedFiles: [], validationErrors: [] });
        } else if (Array.isArray(acceptedFiles)) {
            this.setState({ status: 'Uploading', rejectedFiles: [], acceptedFiles: acceptedFiles, validationErrors: [] });

            this.props.checklistActions.uploadChecklistDeliverySchedules(this.props.clientId, this.props.scheduleChecklistValues.calendarYear, acceptedFiles[0])
                .then(response => {
                    if (response && response.errorResponse && response.errorResponse.errorCode) {
                        //SERVER ERROR, BAD REQUEST
                        this.setState({ status: null, rejectedFiles: [], acceptedFiles: [], validationErrors: [] });
                        this.props.toggleShowUploadChecklistDeliveryScheduleModal();
                    }
                    else if (response &&
                        response.uploadChecklistDeliveryScheduleValidationError && Array.isArray(response.uploadChecklistDeliveryScheduleValidationError)
                        && response.uploadChecklistDeliveryScheduleValidationError.length > 0
                    ) {
                        this.setState({ status: 'Error', acceptedFiles: acceptedFiles, validationErrors: response.uploadChecklistDeliveryScheduleValidationError });
                    } else {
                        this.setState({ status: 'Success', acceptedFiles: acceptedFiles, validationErrors: [] });
                    }

                    //  Set isClosedReportPeriodExists flag and closed period message
                    if (response && response.isClosedReportPeriodExists && response.closedReportPeriodExistsMessage) {
                        this.setState({ closedReportPeriodExists: true, closedReportPeriodExistsMessage: response.closedReportPeriodExistsMessage });
                    }
                    else {
                        this.setState({ closedReportPeriodExists: false, closedReportPeriodExistsMessage: '' });
                    }

                });
        }
    }

    /**
     * Render Upload Status
     */
    renderUploadStatus() {
        let msg;
        let rejectedDetailMsg;
        let colorClass;

        if (this.state.status == 'Uploading') {
            msg = 'Uploading file(s)';
        } else if (this.state.status == 'Success') {
            msg = 'File has successfully been processed.';
            if (this.state.acceptedFiles && this.state.acceptedFiles.length > 0) {
                var acceptedfileName = this.state.acceptedFiles[0].name;
                msg = acceptedfileName + ' has successfully been processed.';
            }
            colorClass = 'text-success';
        } else if (this.state.status == 'Rejected') {
            if (this.state.rejectedFiles && this.state.rejectedFiles.length > 1) {
                //Upload more than one file

                msg = 'Upload failed';
                rejectedDetailMsg = 'Cannot upload more than one file.'
            }
            else {
                //Upload single non xlsx type file or Upload two xlsx type files

                msg = 'Upload failed';
                rejectedDetailMsg = 'Cannot upload more than one file.';

                if (this.state.rejectedFiles && this.state.rejectedFiles.length == 1) {
                    let file = this.state.rejectedFiles[0].file;
                    let fileName = file.name;
                    if (fileName) {
                        let fileExtension = fileName.substr((fileName.lastIndexOf('.') + 1));
                        if (fileExtension.toUpperCase() != "XLSX") {
                            rejectedDetailMsg = 'The format of the file you submitted is not supported. The only supported format for the Schedule Checklist Delivery template is XLSX.';
                        }
                        else if (file.size > Constants.MAX_UPLOAD_FILE_SIZE_IN_BYTES) {
                            rejectedDetailMsg = 'The file size has exceeded max limit of ' + Constants.MAX_UPLOAD_FILE_SIZE_IN_MB + 'MB.';
                        }
                        else {
                            rejectedDetailMsg = 'Cannot upload more than one file.';
                        }
                    }
                }
            }

            colorClass = 'text-danger';

        } else if (this.state.status == 'Error') {
            msg = 'Upload failed'
            colorClass = 'text-danger';
        }

        return msg &&
            <React.Fragment>
                {this.state.status == 'Success' && <div style={{ textAlign: 'left', marginBottom: '20px' }}>
                    <span>
                        <Icon name="check circle" size="big" color='green' title="success" />
                    </span>
                    <span>
                        <Header size='small' className={colorClass} content={msg} />
                    </span>
                </div>
                }

                {this.state.status == 'Error' && <div style={{ textAlign: 'left' }}>
                    <div>
                        <span>
                            <Icon name="remove circle" size="big" color='red' title="error" />
                        </span>
                        <span>
                            <Header size='small' className={colorClass} content={msg} />
                        </span>
                    </div>
                    {this.state.validationErrors && this.state.validationErrors.length > 0 && <div className="validation-error-content">
                        {this.state.validationErrors.map((e, index) =>
                            <div key={index} style={{ marginLeft: 37, wordWrap: "break-word" }} >
                                {e.error}
                            </div>
                        )}
                    </div>
                    }
                </div>
                }
                {this.state.closedReportPeriodExists && <div style={{ textAlign: 'left', marginLeft: '11px' }}>

                    <div>
                        <span>
                            <Header size='small' style={{ color: '#2E2E38' }} content="Note" />
                        </span>
                    </div>
                    <div className="validation-note-content">{this.state.closedReportPeriodExistsMessage}</div>
                </div>
                }

                {this.state.status == 'Rejected' && <div style={{ textAlign: 'left' }}>
                    <div>
                        <span>
                            <Icon name="remove circle" size="big" color='red' title="error" />
                        </span>
                        <span>
                            <Header size='small' className={colorClass} content={msg} />
                        </span>
                    </div>
                    <div style={{ marginLeft: 37 }}> {rejectedDetailMsg} </div>
                </div>
                }

                {this.state.status == 'Uploading' && <div>
                    <Header size='small' className={colorClass} content={msg} />
                </div>
                }

            </React.Fragment>;
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        const HeaderWrapper = ({ children }) => (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'start',
                    justifyContent: 'center'
                }}
            >
                {children}
            </div>
        );

        const closeButtonProps = {
            title: 'Close upload checklist delivery schedules modal',
            ariaLabel: 'Close upload checklist delivery schedules modal',
            dataTestid: 'close'
        };

        return (
            <MotifModal variant="alt" show={this.props.showUploadChecklistDeliveryScheduleModal} onClose={this.handleClose} data-testid="uploadScheduleDelivery">
                <MotifModalHeader style={{ display: "-webkit-box" }} closeButtonProps={closeButtonProps} >
                    <HeaderWrapper>
                        <span className="ml-2">Upload Checklist Delivery Schedules</span>
                    </HeaderWrapper>
                </MotifModalHeader>
                <MotifModalBody>
                    <Dropzone
                        tabIndex="-1"
                        multiple={false}
                        accept='.xlsx'
                        onDrop={this.onDrop}
                        maxSize={Constants.MAX_UPLOAD_FILE_SIZE_IN_BYTES}>
                        {({ getRootProps, getInputProps }) => (
                            <section className="container">
                                <div {...getRootProps({ className: 'dropzone' })} tabIndex="-1"
                                    style={{ width: '100%', textAlign: 'center', border: '1px dashed #666', padding: '50px' }} >
                                    <div><MotifIcon src={fileIcCloudUpload24px} className="uploadIconSize" title="Upload File(s)" /></div>
                                    <input {...getInputProps()} />
                                    <span className="checklistActionMsg">Try dropping some file here, or click to <MotifTextLink href="javascript:void(0)" variant="alt">select file</MotifTextLink> to upload.</span>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                    <br />
                    {this.renderUploadStatus()}
                </MotifModalBody>
                <MotifModalFooter>
                    <MotifButton onClick={this.handleClose} variant="secondary" className="right" >Close</MotifButton>
                </MotifModalFooter>
            </MotifModal>
        );
    }
}

ChecklistDeliveryScheduleUploadModal.propTypes = {
    toggleShowUploadChecklistDeliveryScheduleModal: PropTypes.func.isRequired,
    showUploadChecklistDeliveryScheduleModal: PropTypes.bool.isRequired,
    authHeader: PropTypes.object,
    scheduleChecklistValues: PropTypes.object
};

/**
 * Maps items from state to properties of the component
* @param {Object} state The state
* @param {Object} ownProps The properties of the component
* @returns {Object} An object containing properties that the component can access
*/
function mapStateToProps(state, ownProps) {
    return {
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        checklistActions: bindActionCreators(checklistActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistDeliveryScheduleUploadModal);