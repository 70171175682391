import React from "react";
import PropTypes from "prop-types";
import FormattedCurrency from "../shared/FormattedCurrency";
import ShortDate from "../shared/ShortDate";
import { Button, Icon } from "semantic-ui-react";
import { MotifIconButton, MotifIcon } from "@ey-xd/motif-react";
import { imageIcEdit24px, actionIcDelete24px } from '@ey-xd/motif-react/assets/icons';

const DistributionDetailRow = ({ item, showForm, handleEditButtonClick, handleDeleteButtonClick, canEdit, isChecklist }) => {   
    if (!isChecklist) {
        isChecklist = false;
    }

    const onEditButtonClick = () => {
        handleEditButtonClick(item);
    };

    const onDeleteButtonClick = () => {
        handleDeleteButtonClick(item);
    };

    return (
        <tr>
            <td>{item.class}</td>
            <td>
                <ShortDate value={item.distributionDate} />
            </td>
            <td>
                <FormattedCurrency value={item.fairMarketValue} currency="USD" minimumFractionDigits={0} maximumFractionDigits={0} />
            </td>
            <td>
                <FormattedCurrency value={item.value} currency="USD" minimumFractionDigits={0} maximumFractionDigits={0} />
            </td>
            {(!isChecklist && (
                <td className="tright">
                    {canEdit &&
                        (<span>                        
                        <MotifIconButton aria-label="Edit this Distribution" type="button"
                                onClick={onEditButtonClick}>
                            <MotifIcon src={imageIcEdit24px} title="Edit this Distribution" />
                            </MotifIconButton>
                        <MotifIconButton aria-label="Delete this Distribution" type="button"
                                onClick={onDeleteButtonClick}>
                            <MotifIcon src={actionIcDelete24px} title="Delete this Distribution" />
                            </MotifIconButton></span>)
                        || (<span>
                                <MotifIconButton aria-label="Can't edit Distribution - Testing Closed" type="button" disabled>
                                    <MotifIcon src={imageIcEdit24px} aria-label="Can't edit Distribution - Testing Closed" />
                                </MotifIconButton>
                                <MotifIconButton aria-label="Can't delete Distribution - Testing Closed" type="button" disabled>
                                    <MotifIcon src={actionIcDelete24px} aria-label="Can't delete Distribution - Testing Closed" />
                                </MotifIconButton>
                            </span>)}
                </td>
            )) || null}
        </tr>
    );
};

DistributionDetailRow.propTypes = {
    item: PropTypes.object.isRequired,
    showForm: PropTypes.bool,
    handleEditButtonClick: PropTypes.func,
    handleDeleteButtonClick: PropTypes.func,
    canEdit: PropTypes.bool,
    isChecklist: PropTypes.bool
};

export default DistributionDetailRow;