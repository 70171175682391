import { pendingTask, begin, end } from 'react-redux-spinner';

import * as actions from './actionTypes';
import { fetchFromV1MicrosoftGraphApi as fetch } from './fetchFromApi';
import * as actionHelpers from '../scripts/actionHelpers';

/** Microsoft Graph API V1 actions - Start  */

/**
 * Fetch the profile picture of the logged in user, If successful it will dispatch the LOAD_USER_PROFILE_PICTURE_SUCCESS action
 * action, otherwise it will dispatch the LOAD_USER_PROFILE_PICTURE_FAILURE action.
 * @returns {function} A function that returns a Promise.
 */
export const fetchUserProfilePictureV1 = () => {
  return (dispatch) => {
    dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
    return fetch(`/me/photo/$value`)
      .then((responseImage) => responseImage.blob())
      .then((blobResponse) => {
        let blobUrl = '';
        if (actionHelpers.isErrorResponse(blobResponse)) {
          return actionHelpers.dispatchErrorAndEndTask(
            dispatch,
            actions.LOAD_USER_PROFILE_PICTURE_FAILURE,
            ''
          );
        }

        if (blobResponse.type === 'image/jpeg') {
          const url = window.URL || window.webkitURL;
          blobUrl = url.createObjectURL(blobResponse);
        }

        dispatch({
          type: actions.LOAD_USER_PROFILE_PICTURE_SUCCESS,
          blobUrl,
          [pendingTask]: end,
        });
      })
      .catch((error) => {
        actionHelpers.dispatchErrorAndEndTask(
          dispatch,
          actions.LOAD_USER_PROFILE_PICTURE_FAILURE,
          '',
          error
        );
      });
  };
};

/** Microsoft Graph API V1 actions - End  */
