import PropTypes from "prop-types";
import React from "react";
import { Header, Label, Button, Input, Dropdown } from "semantic-ui-react";
import ReactModal from "react-modal";
import { Field, reduxForm } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import * as formValidators from "../../scripts/formValidators";
import {
    MotifButton, MotifModal, MotifIcon, 
    MotifModalBody,
    MotifModalFooter,
    MotifModalHeader } from "@ey-xd/motif-react";

const AddChecklistUserModal = ({ showAddChecklistUserModal,
    handleSubmit, toggleShowAddChecklistUserModal, emails, handleEmailChanged, gridCurrentState, handleAddChecklistUser }) => {

    const HeaderWrapper = ({ children }) => (
        <div
            style={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'center'
            }}>
            {children}
        </div>
    );

    const closeButtonProps = {
        title: 'Close Add checklist users modal',
        ariaLabel: 'Close Add checklist users modal',
        dataTestid: 'close'
    };

    return (
        <MotifModal variant="alt" show={showAddChecklistUserModal} onClose={() => toggleShowAddChecklistUserModal()} size="xl" data-testid="AddChecklistUsers">
            <MotifModalHeader style={{ display: "-webkit-box" }} closeButtonProps={closeButtonProps}>
                <HeaderWrapper>
                    <span className="ml-2">Add Checklist User(s)</span>
                </HeaderWrapper>
            </MotifModalHeader>

            <MotifModalBody>
                <form onSubmit={handleSubmit} className="auth">
                    <span className="checklistActionMsg">
                        You selected {gridCurrentState && new Set(gridCurrentState.filter(record => record.checklistSelection)
                                                            .map(record => record.checklistID + '_' + record.checklistType)).size} checklist(s).
                    </span>
                    <br />

                    <Field
                        label="Checklist Recipient(s)"
                        component={formHelpers.renderReactMultiEmail}
                        id="checklistUser"
                        name="checklistUser"
                        testingData={{ label: "lblChecklistRecipient", div: "divChecklistRecipient" }}
                        handleChange={handleEmailChanged}
                        removeEYEmail={true}
                        emails={emails && Array.isArray(emails) && emails.length > 0
                            ? emails
                            : []}
                        validate={[
                            formValidators.multipleEmails,
                            formValidators.maxLength255,
                        ]}
                        maxLength={255}
                    />
                    <br />                    
                </form>
            </MotifModalBody>
    <MotifModalFooter>
                <MotifButton type="button" onClick={() => handleAddChecklistUser()} className="right" data-testid="Submit"
                    disabled={!emails || emails.length <= 0} >
            Submit
        </MotifButton>
        <MotifButton
            variant="secondary"
            type="button"
                    onClick={() => toggleShowAddChecklistUserModal()} className="right" data-testid="Cancel">
             Cancel
        </MotifButton>
    </MotifModalFooter>
  </MotifModal>
    );
};

AddChecklistUserModal.propTypes = {
    showAddChecklistUserModal: PropTypes.bool,
    handleSubmit: PropTypes.func,
    toggleShowAddChecklistUserModal: PropTypes.func,
    initialValues: PropTypes.object
};

export default reduxForm({ form: "addChecklistUser", enableReinitialize: true })(AddChecklistUserModal);