import React from "react";
import { Menu } from 'semantic-ui-react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CustomQuestionList from "../../components/reits/CustomQuestionList";
import * as checklistActions from "../../actions/checklistActions";
import EditCustomQuestionModal from "../../components/reits/EditCustomQuestionModal";
import store from "../../store/configureStore";
import { reset } from "redux-form";
import * as commonActions from "../../actions/commonActions";
import DeleteCustomQuestionModal from "../../components/customChecklistQuestions/DeleteCustomQuestionModal";
import { MotifTabNavigation, MotifTabControl, MotifButton } from "@ey-xd/motif-react";
import { Link } from 'react-router';

/**
 * CustomQuestionsPage container component
 * @extends {React.Component}
 */
export class CustomQuestionsPage extends React.Component {
    /**
        * Creates a new CustomQuestionsPage
        * @constructor
        * @param {Object} props The component properties
        * @param {Object} context The component context
        */
    constructor(props, context) {
        super(props, context);

        this.state = {
            customQuestionList: [],
            showEditCustomQuestionModal: false,
            showDeleteCustomQuestionModal: false,
            initialValues: null,
            formPurpose: "NONE",
            tabBar1: 0,
            questionIdToDelete: 0
        };

        this.setTabBar1 = this.setTabBar1.bind(this);
        this.handleClick = this.handleClick.bind(this);

        this.handleEditCustomQuestionListRow = this.handleEditCustomQuestionListRow.bind(this);
        this.handleDeleteCustomQuestionListRow = this.handleDeleteCustomQuestionListRow.bind(this);
        this.handleOnClickCreateNewQuestion = this.handleOnClickCreateNewQuestion.bind(this);
        this.toggleShowEditCustomQuestionModal = this.toggleShowEditCustomQuestionModal.bind(this);
        this.handleEditCustomQuestionModalAction = this.handleEditCustomQuestionModalAction.bind(this);
        this.toggleShowDeleteCustomQuestionModal = this.toggleShowDeleteCustomQuestionModal.bind(this);
    }

    /**
     * Invoked immediately after mounting occurs
     * @returns {undefined}
     */
    componentDidMount() {
        this.props.actions.fetchCustomQuestionList(this.props.clientId);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.customQuestionList !== this.props.customQuestionList && nextProps.customQuestionList && this.props.customQuestionList) {
            const list = Object.assign([], nextProps.customQuestionList);
            this.setState({ customQuestionList: list });
        }
    }

    handleEditCustomQuestionListRow(customQuestionListRow) {
        this.setState({
            showEditCustomQuestionModal: !this.state.showEditCustomQuestionModal,
            formPurpose: "UPDATE",
            initialValues: customQuestionListRow
        });
    }

    handleDeleteCustomQuestionListRow() {
        this.toggleShowDeleteCustomQuestionModal();
        this.props.actions.deleteCustomQuestion(this.state.questionIdToDelete, this.state.customQuestionList);
        this.setState({ questionIdToDelete: 0 });
    }

    //Set tab related state variables
    setTabBar1(currentIndex) {
        this.setState({ tabBar1: currentIndex });
    }

    //Set current tab index
    handleClick(index) {
        this.setTabBar1(index);
    }

    toggleShowDeleteCustomQuestionModal(customQuestionListRow) {
        this.setState({ showDeleteCustomQuestionModal: !this.state.showDeleteCustomQuestionModal });
        if (customQuestionListRow) {
            this.setState({ questionIdToDelete: customQuestionListRow.customQuestionID });
        }
    }

    handleOnClickCreateNewQuestion() {
        this.setState({ showEditCustomQuestionModal: !this.state.showEditCustomQuestionModal, formPurpose: "CREATE" });
    }

    toggleShowEditCustomQuestionModal() {
        this.setState({
            initialValues: null,
            showEditCustomQuestionModal: !this.state.showEditCustomQuestionModal
        }, () => {
            if (!this.state.showEditCustomQuestionModal) {
                store.dispatch(reset('customquestionmodal'));
            }
        });
    }

    handleEditCustomQuestionModalAction(values) {
        let orders = this.state.customQuestionList.map((x) => x.questionOrder)
        let maxQuestionOrder = orders.length > 0 ? Math.max.apply(null, orders) : 1;
        let customQuestion = {
            "customQuestionID": values.customQuestionID,
            "defaultUserDefinedQuestionID": values.defaultUserDefinedQuestionID,
            "clientID": this.props.clientId,
            "questionOrder": maxQuestionOrder + 1,
            "questionText": values.questionText,
            "answerChoice": values.answerChoice,
            "helpText": values.helpText,
        };

        if (this.state.formPurpose === "CREATE") {
            this.props.actions.createCustomQuestion(customQuestion).then(data => {
                this.toggleShowEditCustomQuestionModal()
            })
        }

        if (this.state.formPurpose === "UPDATE") {
            this.props.actions.updateCustomQuestion(customQuestion).then(data => {
                this.toggleShowEditCustomQuestionModal()
            })
        }
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        return (
            <React.Fragment>
                <h1 className="mt-2">Custom PSQ Template</h1>
                <MotifTabNavigation variant="default">
                    <Link to={`/client/${this.props.clientId}/customPsqTemplate`}
                        tabIndex="-1">
                        <MotifTabControl 
                            role="tab"
                            onClick={() => this.handleClick(1)}>
                            Templates
                        </MotifTabControl>
                    </Link>
                    <Link to={`/client/${this.props.clientId}/customPsqTemplate/manageQuestions`} tabIndex="-1">
                        <MotifTabControl className="motif-active"
                            autoFocus
                            role="tab"
                            onClick={() => this.handleClick(2)}>
                            Questions
                        </MotifTabControl>
                    </Link>
                </MotifTabNavigation>
                <CustomQuestionList customQuestionList={this.state.customQuestionList}
                    clientId={this.props.clientId}
                    handleEditCustomQuestionListRow={this.handleEditCustomQuestionListRow}
                    toggleShowDeleteCustomQuestionModal={this.toggleShowDeleteCustomQuestionModal} />
                <div className="d-flex float-right mt-3">                    
                    <MotifButton variant="primary" type="submit"
                        onClick={this.handleOnClickCreateNewQuestion}>Create New Question</MotifButton>
                </div>
                <EditCustomQuestionModal
                    showEditCustomQuestionModal={this.state.showEditCustomQuestionModal}
                    onSubmit={this.handleEditCustomQuestionModalAction}
                    toggleShowEditCustomQuestionModal={this.toggleShowEditCustomQuestionModal}
                    initialValues={this.state.initialValues} />
                <DeleteCustomQuestionModal
                    showDeleteCustomQuestionModal={this.state.showDeleteCustomQuestionModal}
                    handleYes={this.handleDeleteCustomQuestionListRow}
                    toggleShowDeleteCustomQuestionModal={this.toggleShowDeleteCustomQuestionModal}
                />
            </React.Fragment>
        );
    }
}

CustomQuestionsPage.propTypes = {
    clientId: PropTypes.number,
    customQuestionList: PropTypes.array,
    actions: PropTypes.object,
    commonActions: PropTypes.object.isRequired
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    let cid = Number.parseInt(ownProps.params.clientId);
    if (isNaN(cid)) {
        cid = 0;
    }

    return {
        clientId: cid,
        customQuestionList: state.customQuestionnaire.customQuestionList
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(checklistActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomQuestionsPage);