import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Form } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import { MotifButton, MotifIcon, MotifSelect, MotifOption, MotifLabel, MotifFormField } from '@ey-xd/motif-react';
import { actionIcInfo24px } from '@ey-xd/motif-react/assets/icons';
import { addAttribute } from "../../common/Attribute";

const ChecklistDeliveryCalendarHeader = ({ 
    calendarYears,
    selectedPsqArqc,
    handleChangePsqArqc,
    handleReitSelection, 
    reitOptions, 
    selectedReit,
    clientIsAudit,
    checklistDeliveryCalendar,
    filteredCalendar,
    toggleShowResetDeliveryCalendarModal,    
    onSubmit,
    pristine,
    submitting,    
    formSyncErrors,
    formSubmitErrors,
    handleSubmit,
    handleReset,
    saved,
    hasWriteAccess
}) => {

    useEffect(() => {

        //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
        addAttribute("button[role = 'combobox']", "type", "button");
    });

    const calendarYearsOptions = calendarYears.map(y => {
        return {
            key: y,
            text: y,
            value: y
        }
    });

    const psqArqcOptions = [
        { key: 'ALL', value: 'ALL', text: 'ALL' },
        { key: 'PSQ', value: 'PSQ', text: 'PSQ' },
        { key: 'ARQC', value: 'ARQC', text: 'ARQC' },
    ];

    const preventSubmitOnEnter = (e) => {        
        if (e.key === 'Enter') {
            //Avoid preventing key press for buttons
            if (e.target && e.target.type !== 'button') {
                e.preventDefault();
            }
        }
    };

    const hasFormSyncErrors = Object.keys(formSyncErrors).length !== 0;

    useEffect(() => {

        //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
        addAttribute("button[role = 'combobox']", "type", "button");
    });

    return <div className = "mt-2" >
                <form>
                    <div className="d-flex flex-row justify-content-between">
                    <div className="d-flex">
                        <div className="mr-2" style={{width : '120px'}}>
                            <Field
                                name="calendarYear"
                                label="Calendar Year"
                                placeholder="Select Calendar Year..."
                                options={calendarYearsOptions}
                                testingData={{ label: "lblCalendarYear", div: "divCalendarYear"}}
                                component={formHelpers.SingleSelectComponent} />
                        </div>
                        <div className="mr-2" style={{width : '250px'}}>
                            {
                                reitOptions && reitOptions.length > 0 &&
                                <MotifFormField>
                                    <MotifLabel>REIT</MotifLabel>
                                    <MotifSelect
                                        name="reitFilter"
                                        value={selectedReit}
                                        onChange={handleReitSelection}
                                        disabled={clientIsAudit}
                                        filter
                                        multiple
                                    >
                                        {reitOptions.map(ro => (
                                            <MotifOption key={ro.value} value={ro.value}>{ro.text}</MotifOption>
                                        ))}
                                    </MotifSelect>
                                </MotifFormField>
                            }
                        </div>
                        <div style={{width : '120px'}}>
                            <MotifFormField>
                            <MotifLabel data-testid="lblPsqArqc">PSQ / ARQC</MotifLabel>
                                <MotifSelect
                                    name="psqArqc"
                                    value={selectedPsqArqc}
                                    onChange={handleChangePsqArqc}
                                    data-testid="divPsqArqc"
                                    disabled={clientIsAudit}
                                >
                                    {psqArqcOptions.map(pao => (
                                        <MotifOption data-testvalue={pao.text} key={pao.value} value={pao.value}>{pao.text}</MotifOption>
                                    ))}
                                </MotifSelect>
                            </MotifFormField>
                        </div>
                        </div>
                        <div className="d-flex justify-content-end">
                            {checklistDeliveryCalendar && hasWriteAccess &&
                                <div style={{width: 'fit-content'}}>
                                    <MotifButton variant="primary right ml-2" type="button"
                                        data-testid="hdrSave"
                                        onClick={()=> onSubmit()}
                                        disabled={pristine || submitting || hasFormSyncErrors}>
                                        Save
                                    </MotifButton>
                                    <MotifButton variant="secondary right ml-2" type="button"
                                        data-testid="hdrCancel"
                                        disabled={pristine || submitting} onClick={handleReset}>
                                        Cancel
                                    </MotifButton>
                                    <MotifButton variant="primary-alt right ml-2" type="button"
                                        data-testid="hdrResetToDefault"
                                        title="Reset the Delivery Calendar back to its default and clear all saved changes"
                                        disabled={submitting} onClick={toggleShowResetDeliveryCalendarModal}> Reset to Default
                                        <span style={{ marginRight: 10 }}></span>
                                        <MotifIcon src={actionIcInfo24px} style={{ marginRight: 4 }} />
                                    </MotifButton>
                                </div>
                            }
                            {saved && <strong className="text-success right ml-2">Saved</strong>}
                        </div>
                    </div>
                </form>
            </div>;
};

ChecklistDeliveryCalendarHeader.propTypes = {
    calendarYears: PropTypes.array,
    selectedClient: PropTypes.object,
    selectedCalendarYear: PropTypes.number
};

export default reduxForm({ form: "checklistDeliveryCalendar" })(ChecklistDeliveryCalendarHeader);