import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate } from 'react-intl';
import { Icon } from 'semantic-ui-react';
import { MotifFormField, MotifSearch, MotifLabel, MotifToggleSwitch, MotifButton, MotifTextLink, MotifIconButton, MotifIcon } from '@ey-xd/motif-react';
import { imageIcEdit24px, actionIcDelete24px, fileIcFileDownload24px } from '@ey-xd/motif-react/assets/icons';


/**
 * FileListItem component
 * @extends {React.Component}
 */
export default class ChecklistAttachmentFileListItem extends React.Component {
    /**
     * Creates a new TrialBalanceDownloadLink
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);

        this.onDownloadClick = this.onDownloadClick.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
    }

    /**
     * Fire the onDownloadClick function in props
     * @param {any} e The element that triggered this function
     */
    onDownloadClick(e) {
        e.preventDefault();
        this.props.onDownload(this.props.item.checklistSectionQuestionAttachmentID, this.props.item.attachedFileName);
    }

    onDeleteClick(e) {
        e.preventDefault();
        this.props.onDelete(this.props.item.checklistSectionQuestionAttachmentID);
    }
    
    parseChecklistType(checklistType)
    {
        if(checklistType && checklistType === 1)
        {
            return "ARQC"
        }
        else if(checklistType && checklistType === 2)
        {
            return "PSQ"
        }
        else
        {
            return "Unknown"
        }
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        //let fileName = decodeURI(this.props.item.fileName);
        let fileName = (this.props.item.attachedFileName);
        return (
            <tr>
                <td><MotifTextLink href='#' onClick={this.onDownloadClick} variant="alt">{fileName}</MotifTextLink></td>
                <td>{this.parseChecklistType(this.props.item.checklistTypeID)}</td>
                <td>{this.props.item.createdBy}</td>
                <td><FormattedDate value={this.props.item.createdDateTime} /></td>
                <td className="tright">                    
                    <MotifIconButton aria-label="Download Checklist Attachment" type="button"
                        onClick={this.onDownloadClick}>
                        <MotifIcon src={fileIcFileDownload24px} title="Download Checklist Attachment" />
                    </MotifIconButton>
                    {this.props.canDelete &&                        
                        <MotifIconButton aria-label="Delete Checklist Attachment" type="button"
                            onClick={this.onDeleteClick}>
                            <MotifIcon src={actionIcDelete24px} title="Delete Checklist Attachment" />
                        </MotifIconButton>
                        ||                        
                        <MotifIconButton aria-label="Can't delete Checklist Attachment" type="button"
                            disabled>
                            <MotifIcon src={actionIcDelete24px} />
                        </MotifIconButton>
                    }
                </td>
            </tr>
        );
    }
}


ChecklistAttachmentFileListItem.propTypes = {
    item: PropTypes.object.isRequired,
    onDownload: PropTypes.func,
    onDelete: PropTypes.func,
    canDelete: PropTypes.bool
};
