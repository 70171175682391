import React from "react";
import PropTypes from "prop-types";
import { MotifTabNavigation, MotifTabControl, MotifBadge } from "@ey-xd/motif-react";
import ChecklistCounters from "../../components/reits/ChecklistCounters";
import * as  menuNames from '../../constants/checklistNavigationTabs';

const checklistNavigation = ({ checklist, activeItem, disabledSections, handleSelectionChanged, checklistFiles, isExternal,navigationCount }) => {
    let checklistContent = checklist.content;
    if (checklistContent) {
        const isEnabledByParent = function (item, parentItem) {
            let isEnabled = false;
            if (item.enabledByParentAnswers === undefined || item.enabledByParentAnswers.length === 0) {
                isEnabled = true;
            } else {
                // determine whether the item is enabled by the parent item
                for (let i = 0; i < item.enabledByParentAnswers.length; i++) {
                    if ((parentItem.selectedAnswers === undefined || parentItem.selectedAnswers.length === 0) &&
                        parentItem.defaultAnswers.find(x => x === item.enabledByParentAnswers[i]) !== undefined) {
                        isEnabled = true;
                        break;
                    } else if (parentItem.selectedAnswers.find(x => x === item.enabledByParentAnswers[i]) !== undefined) {
                        isEnabled = true;
                        break;
                    }
                }
            }
            return isEnabled;
        }

        const specialReducerComments = function (parentItem) {
            return function (total, el) {
                if (isEnabledByParent(el, parentItem)) {
                    return total + (el.comments === undefined ? 0 : el.comments.length)
                        + (el.children === undefined ? 0 : el.children.reduce(specialReducerComments(el), 0))
                }
                else {
                    return 0;
                }
            }
        }

        const specialReducerAttachments = function (parentItem) {
            return function (total, el) {
                if (isEnabledByParent(el, parentItem)) {
                    return total + (checklistFiles === undefined ? 0 : checklistFiles.filter(f => f.questionID == el.id).length)
                        + (el.children === undefined ? 0 : el.children.reduce(specialReducerAttachments(el), 0))
                }
                else {
                    return 0;
                }
            }
        }

        const reducerComments = function (total, el) {
            return total + (el.comments === undefined ? 0 : el.comments.length)
                + (el.children === undefined ? 0 : el.children.reduce(specialReducerComments(el), 0))
        }

        const reducerAttachments = function (total, el) {
            return total + (checklistFiles === undefined ? 0 : checklistFiles.filter(f => f.questionID == el.id).length)
                + (el.children === undefined ? 0 : el.children.reduce(specialReducerAttachments(el), 0))
        }

        return (
            <MotifTabNavigation defaultActiveKey={0} id="checklistSectionNav" orientation="vertical" lineSide="right" className="w-auto" data-testid="sectionStatus">
                {
                    checklistContent.map((section, index) => {
                        const commentsCount = section.items.reduce(reducerComments, 0);
                        const attachmentsCount = section.items.reduce(reducerAttachments, 0);
                        // Create a new copy of checklist with current section content only
                        let checklistWithCurrentSection = JSON.parse(JSON.stringify(checklist));
                        checklistWithCurrentSection.content = checklistWithCurrentSection.content.slice(index, index+1);

                        return <MotifTabControl
                            className="d-flex align-items-center pt-2 pb-2"
                            style={(index % 2 === 0) ? { background: "#FFF", marginBottom: "1px" } : { background: "#FAFAFC", marginBottom: "1px" }}
                            tabIndex={0}
                            key={section.id}
                            disabled={disabledSections.find(x => x === section.id) !== undefined}
                            onClick={(e)=>handleSelectionChanged(e, {name:section.title})}>
                            <span className="sectionNavigation" data-testid="lblSectionName">{section.title}</span>

                            <ChecklistCounters
                                previousChecklistFound={[]}
                                isExternal={isExternal}
                                checklist={checklistWithCurrentSection}
                                disabledSections={disabledSections}
                                isSectionProgress={true}
                            />
                        </MotifTabControl>
                    })
                }
                <hr style={{ height: '2px', width: '100%', borderWidth: '0', backgroundColor: '#999' }} />
                {
                    menuNames.TabProperty.map((tabName, index) => {
                     let count=0;
                     if(navigationCount && navigationCount.length)
                     {
                        count=navigationCount.find(x=>x.counter==tabName.title).totalCount;
                     }
                     const dataTestId="lbl"+tabName.title;
                                
                        return <MotifTabControl
                            className="d-flex align-items-center pt-2 pb-2"
                            style={(index % 2 === 0) ? { background: "#FFF", marginBottom: "1px" } : { background: "#FAFAFC", marginBottom: "1px" }}
                            key={tabName.id}
                            name={tabName.title}
                            tabIndex={0}
                            active={activeItem.title === tabName.title}
                            onClick={(e) => handleSelectionChanged(e, { name: tabName.title })}>
                            <span className="sectionNavigation" data-testid= {`lbl${tabName.title}`}>{tabName.title}</span>
                            <div className="ml-auto">
                                <MotifBadge
                                    className={tabName.badgeClassName}
                                    value={count}
                                    data-testid={`badgeNoOf${tabName.title}`}/>
                            </div>
                            <div style={{height:"var(--progress-bar-circle-size)"}}></div>
                        </MotifTabControl>
                    })
                }
            </MotifTabNavigation>
        );
    } else {
        return (<div />);
    }
};

checklistNavigation.propTypes = {
    disabledSections: PropTypes.array.isRequired,
    checklist: PropTypes.object,
    activeItem: PropTypes.object,
    handleSelectionChanged: PropTypes.func.isRequired,
    checklistFiles: PropTypes.array,
    isExternal: PropTypes.bool
};

export default checklistNavigation;