import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
    MotifModal,
    MotifModalHeader,
    MotifModalBody,
    MotifModalFooter,
    MotifButton,
    MotifIcon
} from '@ey-xd/motif-react';

import { addAttribute } from '../../common/Attribute';
import { alertIcError24px } from '@ey-xd/motif-react/assets/icons';

const StandardChildQuestionsRearrangeWarningModal = ({
    showStandardChildQuestionsRearrangeWarningModal,
    handleContinueChildQuestionsRearrangeWarningModal,
    handleCancelStandardChildQuestionsRearrangeWarningModal,
}) => {
    useEffect(() => {
        //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
        addAttribute("button[role = 'combobox']", 'type', 'button');
    });

    const HeaderWrapper = ({ children }) => (
        <div
            style={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'center',
            }}
        >
            {children}
        </div>
    );

    const closeButtonProps = {
        title: 'Close Standard Child Questions Rearrange Warning Modal',
        ariaLabel: 'Close Standard Child Questions Rearrange Warning Modal',
        dataTestid: 'close',
    };

    return (
        <React.Fragment>
            <MotifModal
                variant="alt"
                show={showStandardChildQuestionsRearrangeWarningModal}
                onClose={handleCancelStandardChildQuestionsRearrangeWarningModal}
                data-testid="StandardChildQuestionsRearrangeWarningModal"
            >
                <MotifModalHeader
                    style={{ display: '-webkit-box' }}
                    data-testid="h3StandardChildQuestionsRearrangeWarningModalHeader"
                    closeButtonProps={closeButtonProps}
                >
                    <HeaderWrapper>
                        <MotifIcon style={{ color: '#F95D54' }} src={alertIcError24px} />
                        <span className="ml-2">Rearrange Standard Child Questions</span>
                    </HeaderWrapper>
                </MotifModalHeader>
                <MotifModalBody>
                    <p>
                        Are you sure you want to edit the standard child questions sort order?
                    </p>

                    <p>
                        <strong>NOTE:</strong> This change in the standard child questions sort order will convert the selected standard question to a custom question with the desired child questions sort order.
                    </p>
                </MotifModalBody>
                <MotifModalFooter>
                    <MotifButton
                        variant="warn"
                        className="right"
                        data-testid="btnContinue"
                        type="button"
                        onClick={handleContinueChildQuestionsRearrangeWarningModal}
                    >
                        Continue
                    </MotifButton>
                    <MotifButton
                        variant="secondary"
                        className="right"
                        data-testid="btnCancel"
                        type="button"
                        onClick={handleCancelStandardChildQuestionsRearrangeWarningModal}
                    >
                        Cancel
                    </MotifButton>
                </MotifModalFooter>
            </MotifModal>
        </React.Fragment>
    );
};

StandardChildQuestionsRearrangeWarningModal.propTypes = {
    showStandardChildQuestionsRearrangeWarningModal: PropTypes.bool,
    handleContinueChildQuestionsRearrangeWarningModal: PropTypes.func,
    handleCancelStandardChildQuestionsRearrangeWarningModal: PropTypes.func,
};

export default StandardChildQuestionsRearrangeWarningModal;