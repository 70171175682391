import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { indexOfWhile } from '../../scripts/arrayHelpers';

export class Tabs extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            activeTabIndex: this.props.defaultActiveTabIndex
        };
        this.handleTabClick = this.handleTabClick.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.lastClickedTabChecklistQuestionId !== this.props.lastClickedTabChecklistQuestionId) {
            if (this.props.siblingQuestionIds
                && Array.isArray(this.props.siblingQuestionIds)
                && indexOfWhile(this.props.siblingQuestionIds, nextProps.lastClickedTabChecklistQuestionId) >= 0) {
                this.setState({ activeTabIndex: this.props.defaultActiveTabIndex });
            }
        }
    }

    handleTabClick(tabIndex) {
        const shouldToggleOnSameTabClick = this.props.defaultActiveTabIndex === -1;

        this.setState({
            activeTabIndex: tabIndex === this.state.activeTabIndex && shouldToggleOnSameTabClick ? this.props.defaultActiveTabIndex : tabIndex
        });

        if (this.props.handleTabClick && typeof this.props.handleTabClick === 'function') {
            this.props.handleTabClick();
        }
    }

    // Encapsulate <Tabs/> component API as props for <Tab/> children
    renderChildrenWithTabsApiAsProps() {
        return this.props.children.map((child, index) => {
            return React.cloneElement(child, {
                onClick: this.handleTabClick,
                tabIndex: index,
                key: index,
                isActive: index === this.state.activeTabIndex
            });
        });
    }

    // Render current active tab content
    renderActiveTabContent() {
        const { children } = this.props;
        const { activeTabIndex } = this.state;
        if (children[activeTabIndex]) {
            return children[activeTabIndex].props.children;
        }
    }

    render() {
        var divClass = this.state.activeTabIndex == -1 ? "tabs mb-3" : "tabs"
        return (
            <div className={divClass}>
                <ul className="nav nav-tabs" role="tablist">
                    {this.renderChildrenWithTabsApiAsProps()}
                </ul>
                <div className="tab-content">
                    <div className="tab-pane fade show active">
                        {this.renderActiveTabContent()}
                    </div>
                </div>
            </div>
        );
    }
}

Tabs.propTypes = {
    defaultActiveTabIndex: PropTypes.number,
    handleTabClick: PropTypes.func,
    siblingQuestionIds: PropTypes.array
};

Tabs.defaultProps = {
    defaultActiveTabIndex: 0
};