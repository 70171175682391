import PropTypes from "prop-types";
import React from "react";
import { SubmissionError, reset } from "redux-form";
import * as checklistActions from "../../actions/checklistActions";
import * as fileActions from "../../actions/fileActions";
import * as formValidators from "../../scripts/formValidators";
import * as actionHelpers from "../../scripts/actionHelpers";
import * as formHelpers from "../../scripts/formHelpers";
import ChecklistSendModal from "../../components/reits/ChecklistSendModal";
import { formatCurrency, formatPercent } from "../../scripts/formFormatters";
import * as amenities from "../../constants/amenities";
import * as reitActions from "../../actions/reitActions";
import * as customQuestionActions from "../../actions/customQuestionActions";
import PropertiesContainer from "./PropertiesContainer";
import store from "../../store/configureStore";
import { SearchResults } from "semantic-ui-react";
import * as  checklistCountRequest from '../../constants/checklistNavigationTabs';
import * as Constants from '../../constants/other';
/**
 * ChecklistPageBase container component
 * @extends {React.Component}
 */
export class ChecklistPageBase extends React.Component {
    /**
      * Creates a new PsqChecklistPage
      * @constructor
      * @param {Object} props The component properties
      * @param {Object} context The component context
      */
    constructor(props, context) {
        super(props, context);

        this.state = {
            initialClientEmailTo: null,
            initialAccountantEmailTo: null,
            showDetails: false,
            showSendModal: false,
            activeItem: null,
            showConfirmationModal: false,
            showCreateModal: false,
            createData: null,
            checklist: null,
            disabledSections: [],
            isSendCheckListEmailEnabled: false,
            confirmDeleteAction: null,
            createAction: null,
            initialValues: null,
            taxableReitSubsidiaries: [],
            customQuestionnaireTemplates: [],
            createChecklistCustomErrorMessage: ''
        };

        this.handleShowDetails = this.handleShowDetails.bind(this);
        this.handleShowAllQuestions = this.handleShowAllQuestions.bind(this);
        this.handleItemAnswered = this.handleItemAnswered.bind(this);
        this.showConfirmationModal = this.showConfirmationModal.bind(this);
        this.handleSaveChecklist = this.handleSaveChecklist.bind(this);
        this.showCreateModal = this.showCreateModal.bind(this);
        this.hideCreateModal = this.hideCreateModal.bind(this);
        this.handleCancelAction = this.handleCancelAction.bind(this);
        this.handleCreateAction = this.handleCreateAction.bind(this);
        this.handleConfirmationAction = this.handleConfirmationAction.bind(this);
        this.setChecklistState = this.setChecklistState.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.showSendModal = this.showSendModal.bind(this);
        this.handleSendField = this.handleSendField.bind(this);
        this.handleEySendField = this.handleEySendField.bind(this);
        this.handleComments = this.handleComments.bind(this);
        this.showDeleteModal = this.showDeleteModal.bind(this);
        this.handleCancelDelete = this.handleCancelDelete.bind(this);
        this.onConfirmDelete = this.onConfirmDelete.bind(this);
        this.setCreateAction = this.setCreateAction.bind(this);
        this.handleArqcDetailsFormSubmit = this.handleArqcDetailsFormSubmit.bind(this);
        this.getArqcDetailsInitialValues = this.getArqcDetailsInitialValues.bind(this);

        this.handleSaveComment = this.handleSaveComment.bind(this);

        this.handleDeleteComment = this.handleDeleteComment.bind(this);
        this.handleDeleteCommentExternal = this.handleDeleteCommentExternal.bind(this);

        this.handleEditComment = this.handleEditComment.bind(this);
        this.handleEditCommentExternal = this.handleEditCommentExternal.bind(this);

        this.handleDownloadChecklistFile = this.handleDownloadChecklistFile.bind(this);
        this.handleDownloadChecklistFileExternal = this.handleDownloadChecklistFileExternal.bind(this);

        this.handleDeleteAttachment = this.handleDeleteAttachment.bind(this);
        this.handleDeleteAttachmentExternal = this.handleDeleteAttachmentExternal.bind(this);

        this.getPsqQuestionnaireTitle = this.getPsqQuestionnaireTitle.bind(this);
        this.getArqcQuestionnaireTitle = this.getArqcQuestionnaireTitle.bind(this);

        this.handleShowUnansweredQuestions = this.handleShowUnansweredQuestions.bind(this);
        this.getServiceQuestionJSON = this.getServiceQuestionJSON.bind(this);
    }

    handleDownloadChecklistFileExternal(checklistFileAttachmentId, fileName) {
        return this.handleDownloadChecklistFile(checklistFileAttachmentId, fileName, true);
    }

    handleDownloadChecklistFile(checklistFileAttachmentId, fileName, isExternal = false) {
        // Download checklist file attachment
        return fileActions.downloadChecklistFile(
            checklistFileAttachmentId,
            fileName,
            isExternal && this.props.checklistId ? actionHelpers.buildAuthHeader(this.props.checklistId, this.state.checklist.checklistTypeID) : null);
    }

    handleSaveComment(questionId, commentText, isExternal = false, isUnregisteredExternalUser = false) {

        const request = {
            checklistId: this.state.checklist.checklistID,
            checklistTypeId: this.state.checklist.checklistTypeID,
            questionId: questionId,
            comment: commentText,
            isExternalUser: isUnregisteredExternalUser
        }

        // Save comment and put returned comments back into checklist state
        return store
            .dispatch(
                checklistActions.saveChecklistComment(
                    request,
                    isExternal
                        ? actionHelpers.buildAuthHeader(
                            this.props.checklistId,
                            this.state.checklist.checklistTypeID
                        )
                        : null
                )
            )
            .then((comments) => {

                const checklistGuid =
                    isExternal && this.props.checklistId
                        ? this.props.checklistId
                        : null;

                const checklistType =
                    isExternal && this.props.checklistId
                        ? this.state.checklist.checklistTypeID
                        : null;

                this.props.checklistActions.fetchQuestionComments(
                    this.props.client.id,
                    this.state.checklist.checklistID,
                    checklistGuid,
                    checklistType
                );

                this.props.checklistActions.fetchChecklistNavCounts(
                    this.props.client.id,
                    this.state.checklist.checklistID,
                    checklistCountRequest.ChecklistCountRequest,
                    checklistGuid,
                    checklistType
                );
            });
    }


    handleEditCommentExternal(questionId, commentId, commentText) {
        return this.handleEditComment(questionId, commentId, commentText, true);
    }

    handleEditComment(questionId, commentId, commentText, isExternal = false) {
        // copy the checklist so we don't mutate state of prop
        const checklist = JSON.parse(JSON.stringify(this.state.checklist));

        // Recursively find question within items and children
        const findQuestion = (items) => {
            let foundQuestion = items.find(x => x.id == questionId);
            if (foundQuestion) {
                return foundQuestion;
            }

            for (let x = 0; x < items.length; x++) {
                const item = items[x];
                if (item.children && item.children.length > 0) {
                    foundQuestion = findQuestion(item.children);
                    if (foundQuestion) {
                        return foundQuestion;
                    }
                }
            }

            return null;
        };


        const question = findQuestion(checklist.content.find(x => x.id === this.state.activeItem.id).items);

        // Save comment and put returned comments back into checklist state
        return store.dispatch(checklistActions.editSavedChecklistComment(
            this.state.checklist.checklistTypeID,
            this.state.checklist.checklistID,
            questionId,
            commentText,
            commentId,
            isExternal ? actionHelpers.buildAuthHeader(this.props.checklistId, this.state.checklist.checklistTypeID) : null))
            .then(comments => {
                question.comments = comments;
                this.setState({ checklist: checklist });
            });
    }


    handleDeleteCommentExternal(questionId, commentId) {
        return this.handleDeleteComment(questionId, commentId, true);
    }

    handleDeleteComment(questionId, commentId, isExternal = false) {
        const checklistGuid =
            isExternal && this.props.checklistId
                ? this.props.checklistId
                : null;

        const checklistType =
            isExternal && this.props.checklistId
                ? this.state.checklist.checklistTypeID
                : null;


        return store.dispatch(checklistActions.deleteComment(commentId,
            this.props.client.id,
            checklistGuid,
            checklistType
        ))
            .then(comments => {
                this.props.checklistActions.fetchQuestionComments(
                    this.props.client.id,
                    this.state.checklist.checklistID,
                    checklistGuid,
                    checklistType
                );

                this.props.checklistActions.fetchChecklistNavCounts(
                    this.props.client.id,
                    this.state.checklist.checklistID,
                    checklistCountRequest.ChecklistCountRequest,
                    checklistGuid,
                    checklistType
                );
            });
    }

    handleDeleteAttachmentExternal(checklistAttachmentId) {
        return this.handleDeleteAttachment(
            checklistAttachmentId,
            true
        );
    }

    handleDeleteAttachment(checklistAttachmentId, isExternal = false) {
        return store.dispatch(checklistActions.deleteChecklistAttachment(
            checklistAttachmentId,
            this.state.checklist.checklistTypeID,
            this.state.checklist.checklistID,
            null,
            isExternal && this.props.checklistId ? actionHelpers.buildAuthHeader(this.props.checklistId, this.state.checklist.checklistTypeID) : null));
    }


    handleComments(e) {
        // copy the checklist so we don't mutate state of prop
        const checklist = JSON.parse(JSON.stringify(this.state.checklist));

        const comments = [e.target.value];
        checklist
            .content.find(x => x.id === this.state.activeItem.id).comments = comments;

        this.setState({ checklist: checklist });
    }

    handleShowDetails() {
        if (this.state.showDetails) {
            this.setState({ showAllQuestions: true });
        }
        this.setState({ showDetails: !this.state.showDetails });

        if (this.state.checklist !== undefined && this.state.checklist.content != undefined && this.state.checklist.content.length > 0) {
            this.setState({ activeItem: this.state.checklist.content[0] });
        }
    }

    // Used to be "Show All Questions" with a default of false, now it is
    // "Hide Unchanged Answers" with a default of true (i.e. showAllQuestions = true when Hide Unchanged Answers is toggled OFF)
    handleShowAllQuestions() {
        this.setState({ showAllQuestions: !this.state.showAllQuestions });
    }

    // Used to be "Show All Questions" with a default of false
    // When turned ON (true), it should show only Unanswered Questions
    handleShowUnansweredQuestions() {
        this.setState({ showUnansweredQuestions: !this.state.showUnansweredQuestions });
    }

    showConfirmationModal() {
        this.setState({ showConfirmationModal: !this.state.showConfirmationModal });
    }

    showCreateModal() {
        this.props.customQuestionActions.fetchCustomQuestionnaireTemplates(this.props.client.id, true);
        this.setState({
            showCreateModal: true,
            selectedPropertyId: 0,
            selectedPropertyName: "",
            selectedPropertyType: "",
            clientEmail: [],
            isCreateOnlyEnabled: false,
            createChecklistCustomErrorMessage: ''
        });
    }

    hideCreateModal() {
        this.setState({ showCreateModal: false });
        store.dispatch(reset('psqcreatechecklistmodal'));
        store.dispatch(reset('arqccreatechecklistmodal'));
    }

    handleItemAnswered(item, parentItem, e, data) {
        let value = null;
        if (data === undefined) {
            value = e.target.value;
        } else if (data.value !== undefined) {
            value = data.value;
        } else {
            value = data.target.value;
        }

        // copy the checklist so we don't mutate state of prop
        const checklist = JSON.parse(JSON.stringify(this.state.checklist));

        if (item.itemType.toLowerCase() === "arqcexhibitbmortgagetable") {
            let selectedAnswers = checklist
                .content.find(x => x.id === this.state.activeItem.id)
                .items.find(x => x.id === item.id).selectedAnswers;

            if (selectedAnswers === undefined || selectedAnswers === null) {
                selectedAnswers = ["", "", ""];
            }

            const lastCharOfName = e.target.name.substring(e.target.name.length - 1);
            switch (lastCharOfName) {
                case "0":
                    selectedAnswers = [value, selectedAnswers[1], selectedAnswers[2]];
                    break;
                case "1":
                    selectedAnswers = [selectedAnswers[0], value, selectedAnswers[2]];
                    break;
                case "2":
                    selectedAnswers = [selectedAnswers[0], selectedAnswers[1], value];
                    break;
            }

            checklist
                .content.find(x => x.id === this.state.activeItem.id)
                .items.find(x => x.id === item.id).selectedAnswers = selectedAnswers;
        }

        if (item.itemType.toLowerCase() === "arqcstockfmvtable") {

            if (checklist
                .content.find(x => x.id === this.state.activeItem.id)
                .items.find(x => x.id === item.id)
                .selectedAnswers.length === 0) {

                checklist
                    .content.find(x => x.id === this.state.activeItem.id)
                    .items.find(x => x.id === item.id)
                    .selectedAnswers = checklist
                        .content.find(x => x.id === this.state.activeItem.id)
                        .items.find(x => x.id === item.id)
                        .defaultAnswers;
            }

            if (e.field === "fmv") {
                value = formatCurrency(value);
                checklist
                    .content.find(x => x.id === this.state.activeItem.id)
                    .items.find(x => x.id === item.id)
                    .selectedAnswers.find(x => x.individualShareholder === e.individualShareholder)
                    .fmvOfStockOwned = value;
            } else if (e.field === "percent") {
                checklist
                    .content.find(x => x.id === this.state.activeItem.id)
                    .items.find(x => x.id === item.id)
                    .selectedAnswers.find(x => x.individualShareholder === e.individualShareholder)
                    .percentOfStockOwned = value;
            }
        } else if (item.itemType.toLowerCase() === "psqsection2propertymanager" || item.itemType.toLowerCase() === "psqsection2additionalservices") {
            let selectedAnswers = checklist
                .content.find(x => x.id === this.state.activeItem.id)
                .items.find(x => x.id === item.id).selectedAnswers;

            if (e.target.type === "text") {
                selectedAnswers[0] = value;

                checklist
                    .content.find(x => x.id === this.state.activeItem.id)
                    .items.find(x => x.id === item.id).selectedAnswers = selectedAnswers;
            } else if (e.target.type === "checkbox") {
                let doesExist = selectedAnswers.find(x => x === value);

                if (e.target.checked === true) {
                    if (!doesExist) {
                        checklist
                            .content.find(x => x.id === this.state.activeItem.id)
                            .items.find(x => x.id === item.id)
                            .selectedAnswers.push(value);
                    }
                } else if (e.target.checked === false) {
                    if (doesExist) {
                        for (let i = 0; i < selectedAnswers.length; i++) {
                            if (selectedAnswers[i] === value) {
                                checklist
                                    .content.find(x => x.id === this.state.activeItem.id)
                                    .items.find(x => x.id === item.id)
                                    .selectedAnswers.splice(i, 1);
                                break;
                            }
                        }
                    }
                }
            }
        } else if (item.itemType.toLowerCase() === "plaintext") {
            if (parentItem === undefined) {
                checklist
                    .content.find(x => x.id === this.state.activeItem.id)
                    .items.find(x => x.id === item.id).selectedAnswers = [value];
            } else {
                checklist
                    .content.find(x => x.id === this.state.activeItem.id)
                    .items.find(x => x.id === parentItem.id)
                    .children.find(x => x.id === item.id).selectedAnswers = [value];
            }
        } else if ((item.itemType.toLowerCase() === "menu") && item.isMultiSelect === false) {
            if (parentItem === undefined) {
                checklist
                    .content.find(x => x.id === this.state.activeItem.id)
                    .items.find(x => x.id === item.id).selectedAnswers = [value];
            } else {
                checklist
                    .content.find(x => x.id === this.state.activeItem.id)
                    .items.find(x => x.id === parentItem.id)
                    .children.find(x => x.id === item.id).selectedAnswers = [value];
            }
        } else if ((item.itemType.toLowerCase() === "menu") && item.isMultiSelect === true) {
            if (parentItem === undefined) {
                checklist
                    .content.find(x => x.id === this.state.activeItem.id)
                    .items.find(x => x.id === item.id).selectedAnswers = value;
            } else {
                checklist
                    .content.find(x => x.id === this.state.activeItem.id)
                    .items.find(x => x.id === parentItem.id)
                    .children.find(x => x.id === item.id).selectedAnswers = value;
            }
        } else if ((item.itemType.toLowerCase() === "button") && item.isMultiSelect === false) {
            if (parentItem === undefined) {
                checklist
                    .content.find(x => x.id === this.state.activeItem.id)
                    .items.find(x => x.id === item.id).selectedAnswers = [value];
            } else {
                checklist
                    .content.find(x => x.id === this.state.activeItem.id)
                    .items.find(x => x.id === parentItem.id)
                    .children.find(x => x.id === item.id).selectedAnswers = [value];
            }
        } else if ((item.itemType.toLowerCase() === "button") && item.isMultiSelect === true) {
            if (parentItem === undefined) {
                let selectedAnswers = checklist
                    .content.find(x => x.id === this.state.activeItem.id)
                    .items.find(x => x.id === item.id).selectedAnswers;
                let doesExist = selectedAnswers.find(x => x === value);

                if (e.target.checked === true) {
                    if (!doesExist) {
                        checklist
                            .content.find(x => x.id === this.state.activeItem.id)
                            .items.find(x => x.id === item.id)
                            .selectedAnswers.push(value);
                    }
                } else if (e.target.checked === false) {
                    if (doesExist) {
                        for (let i = selectedAnswers.length - 1; i >= 0; i--) {
                            if (selectedAnswers[i] === value) {
                                checklist
                                    .content.find(x => x.id === this.state.activeItem.id)
                                    .items.find(x => x.id === item.id)
                                    .selectedAnswers.splice(i, 1);
                                break;
                            }
                        }
                    }
                }
            } else {
                let selectedAnswers = checklist
                    .content.find(x => x.id === this.state.activeItem.id)
                    .items.find(x => x.id === parentItem.id)
                    .children.find(x => x.id === item.id).selectedAnswers;
                let doesExist = selectedAnswers.find(x => x === value);

                if (e.target.checked === true) {
                    if (!doesExist) {
                        checklist
                            .content.find(x => x.id === this.state.activeItem.id)
                            .items.find(x => x.id === parentItem.id)
                            .children.find(x => x.id === item.id)
                            .selectedAnswers.push(value);
                    }
                } else if (e.target.checked === false) {
                    if (doesExist) {
                        for (let i = selectedAnswers.length - 1; i >= 0; i--) {
                            if (selectedAnswers[i] === value) {
                                checklist
                                    .content.find(x => x.id === this.state.activeItem.id)
                                    .items.find(x => x.id === parentItem.id)
                                    .children.find(x => x.id === item.id)
                                    .selectedAnswers.splice(i, 1);
                                break;
                            }
                        }
                    }
                }
            }
        }

        this.setState({ checklist: checklist });
        this.disableSections(checklist);
    }

    disableSections(checklist) {
        let disabledSections = JSON.parse(JSON.stringify(this.state.disabledSections));
        if (checklist.content !== undefined && checklist.content.length !== undefined) {
            for (let s = 0; s < checklist.content.length; s++) {
                const section = checklist.content[s];
                if (section.items !== undefined && (section.type === undefined || section.type == null || section.type === "standardSection")) {
                    for (let i = 0; i < section.items.length; i++) {
                        const item = section.items[i];
                        disabledSections = this.checkItemDisableSection(item, disabledSections, checklist);
                        if (item.children !== undefined) {
                            for (let c = 0; c < item.children.length; c++) {
                                disabledSections = this.checkItemDisableSection(item.children[c], disabledSections, checklist);
                            }
                        }
                    }
                }

                if (section.requireService !== undefined) {
                    let disableSection = true;
                    for (let i = 0; i < this.props.services.length; i++) {
                        if (this.props.services[i].toLowerCase() === section.requireService.toLowerCase()) {
                            disableSection = false;
                        }
                    }

                    if (disableSection) {
                        disabledSections.push(section.id);
                    }
                }
            }

            this.setState({ disabledSections: disabledSections });
        }
    }

    checkItemDisableSection(item, disabledSections, checklist) {
        const defaultAnswers = item.defaultAnswers;
        const selectedAnswers = item.selectedAnswers;
        const controlSections = item.controlSections;
        if (controlSections !== undefined && controlSections !== null && controlSections.length > 0) {
            for (let i = 0; i < controlSections.length; i++) {
                const section = controlSections[i];
                const checklistSectionId = checklist?.content?.filter(c => c.checklistTemplateSectionID === section.sectionId)[0]?.id;
                const index = disabledSections.indexOf(checklistSectionId);
                if ((selectedAnswers === undefined || selectedAnswers === null || selectedAnswers.length === 0) &&
                    (defaultAnswers !== undefined && defaultAnswers !== null && defaultAnswers.length > 0)) {
                    // check default answers
                    for (let a = 0; a < section.answersEnable.length; a++) {
                        if (defaultAnswers.find(x => x === section.answersEnable[a]) !== undefined) {
                            // enable section
                            if (index >= 0) {
                                disabledSections.splice(index, 1);
                            }
                            break;
                        } else {
                            // disable section
                            if (index === undefined || index < 0) {
                                disabledSections.push(checklistSectionId);
                            }
                        }
                    }
                } else if (selectedAnswers !== undefined && selectedAnswers !== null && selectedAnswers.length > 0) {
                    // check selected answers
                    for (let a = 0; a < section.answersEnable.length; a++) {
                        if (selectedAnswers.find(x => x === section.answersEnable[a]) !== undefined) {
                            // enable section
                            if (index >= 0) {
                                disabledSections.splice(index, 1);
                            }
                            break;
                        } else {
                            // disable section
                            if (index === undefined || index < 0) {
                                disabledSections.push(checklistSectionId);
                            }
                        }
                    }
                } else {
                    // disable section
                    if (index === undefined || index < 0) {
                        disabledSections.push(checklistSectionId);
                    }
                }
            }
        }

        return disabledSections;
    }

    setCreateAction(action) {
        this.setState({ createAction: action });
    }

    handleCancelAction() {
        this.setState({ createData: null, isSendEmailEnabled: false, showDetails: false, selectedPropertyId: 0, selectedPropertyName: "", selectedPropertyType: "" }, this.hideCreateModal);
    }

    handleCreateAction() {
        let createAction = this.state.createAction;
        this.setState({ createAction: null });

        let createData = JSON.parse(JSON.stringify(this.state.createData));
        if (createData === null) { //Occurs on subsequent creations of ARQC, because we set createData to null below
            createData = {
                checklistTypeID: "ARQC",
                clientID: this.props.client.id,
                reitID: this.props.reit.reitid,
                reportPeriodID: this.props.periodId,
                propertyID: null,
                clientEmailTo: '',
                accountantEmailTo: '',
                propertyType: 'NotSelected',
                checklistStateID: "Created"
            };
        }

        createData.clientID = this.props.client.id;
        createData.reitID = this.props.reit.reitid;

        if (createAction === "CREATEANDSEND") {
            createData.sendNotification = true;
            if (!createData.clientID || !createData.reitID) {
                formHelpers.generateSubmissionError();
                return;
            }
        }

        this.props.checklistActions.createChecklist(createData).then(data => {
            // Handle custom error
            if (data && data.checklist && data.checklist.standardProcessExecutionResponse &&
                !data.checklist.standardProcessExecutionResponse.success) {
                this.setState({ createChecklistCustomErrorMessage: data.checklist.standardProcessExecutionResponse.errorMessage });
                store.dispatch(reset('psqcreatechecklistmodal'));
                store.dispatch(reset('arqccreatechecklistmodal'));
            }
            else {
                this.props.reitActions.fetchReit(this.props.reit.reitid);
                this.setState({ createData: null, showDetails: false }); // TRIPS UP SUBSEQUENT ARQC
                this.reloadSummaryData();
                this.hideCreateModal();
            }
        }).catch(error => {
            this.setState({ createData: null, showDetails: false }); // TRIPS UP SUBSEQUENT ARQC
            this.reloadSummaryData();
            this.hideCreateModal();
        });
    }

    handleConfirmationAction(e) {
        if ((!e) || (e.target.value === "CANCEL")) {
            this.showConfirmationModal();
            return;
        }

        const checklist = JSON.parse(JSON.stringify(this.state.checklist));
        checklist.checklistStateID = e.target.value;
        this.setState({ checklist: checklist });
        if (e.target.value === "PENDINGEYREVIEW") {
            checklist.sendNotification = true;
        }

        this.props.checklistActions.saveChecklist(checklist, true);
        this.showConfirmationModal();
    }

    saveChecklist(checklist, shouldRefresh) {
        checklist.clientID = this.props.client.id;
        checklist.reitID = this.props.reit.reitid;
        return this.props.checklistActions.saveChecklist(checklist, shouldRefresh);
    }

    revertChecklistState(checklist) {
        this.props.checklistActions.revertChecklistState(checklist);
    }

    saveExternalChecklist(checklist, checklistId, checklistType) {
        this.props.checklistActions.saveChecklist(checklist, false, actionHelpers.buildAuthHeader(checklistId, checklistType), true);
    }

    checkSendFields(checklist) {
        if (checklist.clientEmailTo && checklist.accountantEmailTo) {
            if (checklist.clientEmailTo.length > 0 && checklist.accountantEmailTo.length > 0) {
                let sendToEmailValidation = false;
                const sendToEmailsList = checklist.clientEmailTo.split(";");
                for (let email of sendToEmailsList) {
                    if (formValidators.email(email.trim()) === 'Invalid email address') {
                        sendToEmailValidation = true;
                    }
                }

                const eyEmailsList = checklist.accountantEmailTo.split(";");
                for (let email of eyEmailsList) {
                    if (formValidators.email(email.trim()) === 'Invalid email address') {
                        sendToEmailValidation = true;
                    }
                }

                if (sendToEmailValidation) {
                    this.setState({ isSendCheckListEmailEnabled: false });
                } else {
                    this.setState({ isSendCheckListEmailEnabled: true });
                }
            } else {
                this.setState({ isSendCheckListEmailEnabled: false });
            }
        } else {
            this.setState({ isSendCheckListEmailEnabled: false });
        }
    }

    handleEySendField(e) {
        const checklist = JSON.parse(JSON.stringify(this.state.checklist));

        if (Array.isArray(e)) {
            if (e.length > 0) {
                checklist.accountantEmailTo = e.join(";");
            } else {
                checklist.accountantEmailTo = "";
            }
        }

        this.checkSendFields(checklist);

        this.setState({ checklist: checklist });
    }

    handleSendField(e) {
        const checklist = JSON.parse(JSON.stringify(this.state.checklist));

        if (Array.isArray(e)) {
            if (e.length > 0) {
                checklist.clientEmailTo = e.join(";");
            } else {
                checklist.clientEmailTo = "";
            }
        }

        this.checkSendFields(checklist);

        this.setState({ checklist: checklist });
    }

    handleSubmit() {
        const checklist = JSON.parse(JSON.stringify(this.state.checklist));
        checklist.sendNotification = true;
        this.saveChecklist(checklist, true);

        this.setState({ isSendCheckListEmailEnabled: false });
        this.showSendModal();
    }

    handleArqcDetailsFormSubmit(values) {
        let propertyAmenities = [];
        const reduxFormValue = values.propertyAmenities;

        if (Array.isArray(reduxFormValue)) {
            reduxFormValue.map(item => {
                let propertyAmenity = {
                    serviceName: (item.propertyAmenityName && item.propertyAmenityName.value === "Other" && item.otherName) || item.propertyAmenityName.value,
                    e: item.isProvidedByEmployee === true ? true : false,
                    ik: item.isProvidedByIndependentContractor === true ? true : false,
                    c: item.isProvidedByCustomary === true ? true : false,
                    trs: item.isProvidedByTaxableReitSubsidiary === true ? true : false
                };

                if (propertyAmenity.trs && item.taxableReitSubsidiary) {
                    propertyAmenity.trsName = item.taxableReitSubsidiary.label;
                }

                propertyAmenities.push(propertyAmenity);
            });
        }

        const checklist = JSON.parse(JSON.stringify(this.state.checklist));

        if (checklist.content.find(x => x.type === "ArqcExhibitB") !== undefined) {
            checklist.content.find(x => x.type === "ArqcExhibitB").items[0].selectedAnswers = propertyAmenities;
        }

        this.setState({ checklist: checklist });
        this.disableSections(checklist);
    }

    getArqcDetailsInitialValues(checklist, isExternal) {
        store.dispatch(reset('checklist'));
        checklist = JSON.parse(JSON.stringify(checklist));

        let propertyAmenities = [];
        let propertyAmenityCollection = null;

        const exhibitBSection = checklist.content.find(x => x.type === "ArqcExhibitB");
        if (exhibitBSection && Array.isArray(exhibitBSection.items)) {
            const firstItem = exhibitBSection.items[0];
            if (firstItem) {
                propertyAmenityCollection = firstItem.selectedAnswers;
            }
        }

        if (Array.isArray(propertyAmenityCollection)) {
            propertyAmenityCollection.map(item => {
                let amenityOption = amenities.options.find(x => x.value === item.serviceName);
                let newAmenity = {
                    propertyAmenityName: amenityOption,
                    isProvidedByEmployee: item.e,
                    isProvidedByIndependentContractor: item.ik,
                    isProvidedByCustomary: item.c,
                    isProvidedByTaxableReitSubsidiary: item.trs
                };

                if (!amenityOption) {
                    newAmenity.propertyAmenityName = amenities.options.find(x => x.value === "Other");
                    newAmenity.otherName = item.serviceName;
                }

                if (newAmenity.isProvidedByTaxableReitSubsidiary && item.trsName) {
                    newAmenity.taxableReitSubsidiary = { label: item.trsName, value: item.trsName };
                }

                propertyAmenities.push(newAmenity);
            });
        }

        return {
            isExternal: isExternal.toString(),
            propertyAmenities: propertyAmenities
        };
    }

    handleSaveChecklist() {
        const checklist = JSON.parse(JSON.stringify(this.state.checklist));
        return this.saveChecklist(checklist, true).then(() => {
            // Reset newQuestionIds after saving checklist
            this.setState({ newQuestionIds: [], lastSavedQuestionId: null });
        });
    }

    handleRevertChecklistState() {
        const checklist = JSON.parse(JSON.stringify(this.state.checklist));
        this.revertChecklistState(checklist);
    }

    showSendModal() {
        if (this.state.showSendModal) {
            // revert the checklist state since this is cancel action
            const checklist = this.state.checklist;
            checklist.clientEmailTo = this.state.initialClientEmailTo;
            checklist.accountantEmailTo = this.state.initialAccountantEmailTo;
            this.setState({
                showSendModal: !this.state.showSendModal,
                initialClientEmailTo: null,
                initialAccountantEmailTo: null,
                selectedPropertyId: null,
                checklist: checklist
            });
        }
        else {
            // save the a copy of the email state so we can revert on cancel
            this.setState({
                showSendModal: !this.state.showSendModal,
                initialClientEmailTo: this.state.checklist.clientEmailTo,
                initialAccountantEmailTo: this.state.checklist.accountantEmailTo,
                selectedPropertyId: this.state.checklist.propertyID
            });
        }
    }

    setChecklistState(checklistState) {
        if (this.props.arqcChecklist !== null) {
            const checklist = JSON.parse(JSON.stringify(this.state.checklist));
            checklist.checklistStateID = checklistState;
            this.setState({ showConfirmationModal: false, showSubmitToEyModal: false });
            this.props.actions.saveChecklist(checklist);
        }
    }

    getCheckListItemStateName(state) {
        switch (state) {
            case "InProgress":
                return "In Progress";
            case "PendingEyReview":
                return "Pending EY Review";
            default:
                return state;
        }
    }

    showDeleteModal(checklist) {
        this.setState({
            confirmDeleteAction: () => {
                this.props.checklistActions.deleteChecklist(checklist.checklistTypeID, checklist.checklistID);
                if (checklist.checklistTypeID === "PSQ") {
                    this.setState({ psqProviderSummary: [] });
                }
            }
        });
    }

    handleCancelDelete() {
        this.setState({ confirmDeleteAction: null });
    }

    onConfirmDelete() {
        if (this.state.confirmDeleteAction) {
            this.setState({ showDetails: false, checklist: null });
            this.state.confirmDeleteAction();
            this.setState({ confirmDeleteAction: null });
        }
    }

    getSendModal() {
        if (this.state.showSendModal) {
            return (
                <ChecklistSendModal
                    onSubmit={this.handleSubmit}
                    handleClientFieldChanged={this.handleSendField}
                    handleEyFieldChanged={this.handleEySendField}
                    showModal={this.state.showSendModal}
                    showSendModal={this.showSendModal}
                    isSendCheckListEmailEnabled={this.state.isSendCheckListEmailEnabled}
                    clientEmailsRef={this.clientEmailsRef}
                    checklist={this.state.checklist} />
            );
        }

        return null;
    }

    getModalStyle() {
        return {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '300px',
                height: '160px'
            }
        };
    }

    getPsqQuestionnaireTitle() {
        const clientName = this.props.client ? this.props.client.name : "";
        const reportPeriod = this.props.period && Object.keys(this.props.period).length > 0 ? this.props.period : this.state.period;
        const reportPeriodDescription = reportPeriod ? reportPeriod.reportPeriodDescription : null;
        const yearAndPeriod = reportPeriodDescription ? reportPeriodDescription.slice(2) + " - " + reportPeriodDescription.slice(0, 2) : "";
        const propertyTypeID = this.props.psqActiveChecklist ?
            this.props.psqActiveChecklist.propertyType : "";
        const propertyTypes = this.props.lookupTypes && this.props.lookupTypes.propertyTypes ?
            this.props.lookupTypes.propertyTypes.filter(pt => pt.propertyTypeID == propertyTypeID) : null;
        const propertyTypeDescription = propertyTypes && propertyTypes.length > 0 ? propertyTypes[0].propertyTypeDescription : "";
        return clientName + " - PSQ - " + yearAndPeriod + " - " + propertyTypeDescription;
    }

    getArqcQuestionnaireTitle() {
        const clientName = this.props.client ? this.props.client.name : "";
        const reportPeriod = this.props.period && Object.keys(this.props.period).length > 0 ? this.props.period : this.state.period;
        const reportPeriodDescription = reportPeriod ? reportPeriod.reportPeriodDescription : null;
        const yearAndPeriod = reportPeriodDescription ? reportPeriodDescription.slice(2) + " - " + reportPeriodDescription.slice(0, 2) : "";
        const reitTypeID = this.props.arqcActiveChecklist ?
            this.props.arqcActiveChecklist.reitTypeID : "";
        const reitTypes = this.props.lookupTypes && this.props.lookupTypes.reitTypes ?
            this.props.lookupTypes.reitTypes.filter(pt => pt.reitTypeID == reitTypeID) : null;
        const reitTypeDescription = reitTypes && reitTypes.length > 0 ? reitTypes[0].reitTypeDescription : "";
        return clientName + " - ARQC - " + yearAndPeriod + " - " + reitTypeDescription;
    }

    isEYEmailAddress(email) {
        var splitArray = email.trim().split("@");
        if (
            splitArray[1] &&
            splitArray[1] != Constants.RejectedEyDomain &&
            !splitArray[1].includes(Constants.RejectedOtherEYDomain)
        ) {
            return false;
        } else {
            return true;
        }
    }

    getServiceQuestionJSON(addServiceProviderQuestionResponse, sectionSortOrder, questionSortOrder) {
        // Prepare Service Question JSON with newly created ChecklistSectionQuestionID & QuestionIDs
        return {
            id: addServiceProviderQuestionResponse[0].questionId,
            checklistSectionQuestionID: addServiceProviderQuestionResponse[0].checklistSectionQuestionId,
            questionSortOrder: questionSortOrder,
            title:
                sectionSortOrder + '.' +
                questionSortOrder +
                ' Please identify any Additional Service Provider, the services provided, the compensation arrangement, and its relationship to the REIT in the space below.',
            itemType: 'PsqSection2AdditionalServices',
            isMultiSelect: false,
            isEnabled: true,
            defaultAnswers: [],
            availableAnswers: [],
            selectedAnswers: [''],
            controlSections: [],
            systemRule: {
                name: 'SetFromPreviousPeriodAnswer',
                setAnswers: ['{selectedAnswers}'],
                isDisabledOnSystemAnswer: false,
            },
            children: [
                {
                    id: addServiceProviderQuestionResponse[1].questionId,
                    checklistSectionQuestionID: addServiceProviderQuestionResponse[1].checklistSectionQuestionId,
                    title:
                        sectionSortOrder + '.' +
                        questionSortOrder +
                        '.1 Does the IK own, directly or indirectly, more than a 35% interest (based on vote or value) in the REIT?',
                    itemType: 'button',
                    isMultiSelect: false,
                    isEnabled: true,
                    defaultAnswers: [],
                    availableAnswers: ['Yes', 'No'],
                    selectedAnswers: [],
                    controlSections: [],
                    systemRule: {
                        name: 'SetFromPreviousPeriodAnswer',
                        setAnswers: ['{selectedAnswers}'],
                        isDisabledOnSystemAnswer: false,
                    },
                    children: [],
                    enabledByParentAnswers: ['IK'],
                    ruleTag: [],
                },
                {
                    id: addServiceProviderQuestionResponse[2].questionId,
                    checklistSectionQuestionID: addServiceProviderQuestionResponse[2].checklistSectionQuestionId,
                    title:
                        sectionSortOrder + '.' +
                        questionSortOrder +
                        '.2 Do one or more persons who own 35% or more of the interests in the REIT (based on vote or value) also own directly or indirectly more than 35% of the interests in the IK (based on net profits or assets, if non-corporate)?',
                    itemType: 'button',
                    isMultiSelect: false,
                    isEnabled: true,
                    defaultAnswers: [],
                    availableAnswers: ['Yes', 'No'],
                    selectedAnswers: [],
                    controlSections: [],
                    systemRule: {
                        name: 'SetFromPreviousPeriodAnswer',
                        setAnswers: ['{selectedAnswers}'],
                        isDisabledOnSystemAnswer: false,
                    },
                    children: [],
                    enabledByParentAnswers: ['IK'],
                    ruleTag: [],
                },
                {
                    id: addServiceProviderQuestionResponse[3].questionId,
                    checklistSectionQuestionID: addServiceProviderQuestionResponse[3].checklistSectionQuestionId,
                    title:
                        sectionSortOrder + '.' +
                        questionSortOrder +
                        '.3 Does the REIT derive or receive any income from the IK, such as a share of the income or revenue derived from services, rents from leasing space to the contractor (at this property or any other property), dividends or other profits of the contractor, or any other form of income from the contractor?',
                    itemType: 'button',
                    isMultiSelect: false,
                    isEnabled: true,
                    defaultAnswers: [],
                    availableAnswers: ['Yes', 'No'],
                    selectedAnswers: [],
                    controlSections: [],
                    systemRule: {
                        name: 'SetFromPreviousPeriodAnswer',
                        setAnswers: ['{selectedAnswers}'],
                        isDisabledOnSystemAnswer: false,
                    },
                    children: [],
                    enabledByParentAnswers: ['IK'],
                    ruleTag: [],
                },
                {
                    id: addServiceProviderQuestionResponse[4].questionId,
                    checklistSectionQuestionID: addServiceProviderQuestionResponse[4].checklistSectionQuestionId,
                    title:
                        sectionSortOrder + '.' +
                        questionSortOrder +
                        ".4 Is the REIT's relationship with the IK an arms-length relationship?",
                    itemType: 'button',
                    isMultiSelect: false,
                    isEnabled: true,
                    defaultAnswers: [],
                    availableAnswers: ['Yes', 'No'],
                    selectedAnswers: [],
                    controlSections: [],
                    systemRule: {
                        name: 'SetFromPreviousPeriodAnswer',
                        setAnswers: ['{selectedAnswers}'],
                        isDisabledOnSystemAnswer: false,
                    },
                    children: [],
                    enabledByParentAnswers: ['IK'],
                    ruleTag: [],
                },
                {
                    id: addServiceProviderQuestionResponse[5].questionId,
                    checklistSectionQuestionID: addServiceProviderQuestionResponse[5].checklistSectionQuestionId,
                    title:
                        sectionSortOrder + '.' +
                        questionSortOrder +
                        '.5 Is the IK adequately compensated based on fair-value and arms-length dealing?',
                    itemType: 'button',
                    isMultiSelect: false,
                    isEnabled: true,
                    defaultAnswers: [],
                    availableAnswers: ['Yes', 'No'],
                    selectedAnswers: [],
                    controlSections: [],
                    systemRule: {
                        name: 'SetFromPreviousPeriodAnswer',
                        setAnswers: ['{selectedAnswers}'],
                        isDisabledOnSystemAnswer: false,
                    },
                    children: [],
                    enabledByParentAnswers: ['IK'],
                    ruleTag: [],
                },
                {
                    id: addServiceProviderQuestionResponse[6].questionId,
                    checklistSectionQuestionID: addServiceProviderQuestionResponse[6].checklistSectionQuestionId,
                    title:
                        sectionSortOrder + '.' +
                        questionSortOrder +
                        '.6 Does the REIT directly or indirectly bear the costs of any of the IK services?',
                    itemType: 'button',
                    isMultiSelect: false,
                    isEnabled: true,
                    defaultAnswers: [],
                    availableAnswers: ['Yes', 'No'],
                    selectedAnswers: [],
                    controlSections: [],
                    systemRule: {
                        name: 'SetFromPreviousPeriodAnswer',
                        setAnswers: ['{selectedAnswers}'],
                        isDisabledOnSystemAnswer: false,
                    },
                    children: [],
                    enabledByParentAnswers: ['IK'],
                    ruleTag: [],
                },
                {
                    id: addServiceProviderQuestionResponse[7].questionId,
                    checklistSectionQuestionID: addServiceProviderQuestionResponse[7].checklistSectionQuestionId,
                    title:
                        sectionSortOrder + '.' +
                        questionSortOrder +
                        '.7 Is the IK an employee of the REIT or is the REIT otherwise able to direct and control the manner in which the IK carries out its duties?',
                    itemType: 'button',
                    isMultiSelect: false,
                    isEnabled: true,
                    defaultAnswers: [],
                    availableAnswers: ['Yes', 'No'],
                    selectedAnswers: [],
                    controlSections: [],
                    systemRule: {
                        name: 'SetFromPreviousPeriodAnswer',
                        setAnswers: ['{selectedAnswers}'],
                        isDisabledOnSystemAnswer: false,
                    },
                    children: [],
                    enabledByParentAnswers: ['IK'],
                    ruleTag: [],
                },
                {
                    id: addServiceProviderQuestionResponse[8].questionId,
                    checklistSectionQuestionID: addServiceProviderQuestionResponse[8].checklistSectionQuestionId,
                    title:
                        sectionSortOrder + '.' +
                        questionSortOrder +
                        '.8 For the above questions, please add an explanation of any special circumstances or additional comments in the box below.',
                    itemType: 'plaintext',
                    isMultiSelect: false,
                    isEnabled: true,
                    defaultAnswers: [],
                    availableAnswers: [],
                    selectedAnswers: [],
                    controlSections: [],
                    systemRule: {
                        name: 'SetFromPreviousPeriodAnswer',
                        setAnswers: ['{selectedAnswers}'],
                        isDisabledOnSystemAnswer: false,
                    },
                    children: [],
                    enabledByParentAnswers: ['IK'],
                    ruleTag: [],
                },
            ],
            enabledByParentAnswers: [],
            ruleTag: [],
            serviceProvider: questionSortOrder,
        };
    }

    getUpdatedQuestionTitleBasedOnChecklistSectionQuestionId(activeChecklist, checklistSectionQuestionId) {
        let questionText = '';
        let isQuestionFound = false;
        for (let sectionIndex = 0; sectionIndex < activeChecklist.content.length; sectionIndex++) {
            if (activeChecklist.content[sectionIndex].items.length > 0) {
                for (let questionIndex = 0; questionIndex < activeChecklist.content[sectionIndex].items.length; questionIndex++) {
                    if (activeChecklist.content[sectionIndex].items[questionIndex].checklistSectionQuestionID === checklistSectionQuestionId) {
                        isQuestionFound = true;
                        questionText = activeChecklist.content[sectionIndex].items[questionIndex].title;
                    }

                    if (isQuestionFound) {
                        break;
                    }

                    if (activeChecklist.content[sectionIndex].items[questionIndex].children.length > 0 && !isQuestionFound) {
                        for (let childQuestionIndex = 0; childQuestionIndex < activeChecklist.content[sectionIndex].items[questionIndex].children.length; childQuestionIndex++) {
                            if (activeChecklist.content[sectionIndex].items[questionIndex].children[childQuestionIndex].checklistSectionQuestionID === checklistSectionQuestionId) {
                                isQuestionFound = true;
                                questionText = activeChecklist.content[sectionIndex].items[questionIndex].children[childQuestionIndex].title;
                            }

                            if (isQuestionFound) {
                                break;
                            }
                        }
                    }
                }
            }

            if (isQuestionFound) {
                break;
            }
        }

        return questionText;
    }

}

ChecklistPageBase.propTypes = {
    client: PropTypes.object.isRequired,
    reit: PropTypes.object.isRequired,
    periodId: PropTypes.number.isRequired,
    commonActions: PropTypes.object.isRequired,
    checklistActions: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    reitActions: PropTypes.object.isRequired,
    fileActions: PropTypes.object.isRequired,
    arqcChecklist: PropTypes.object,
    checklistId: PropTypes.number,
    services: PropTypes.array,
    serviceActions: PropTypes.func.isRequired,
    customQuestionActions: PropTypes.func.isRequired
};

export default ChecklistPageBase;