import React from "react";
import PropTypes from "prop-types";
import { Header, Button } from "semantic-ui-react";
import ReactModal from "react-modal";

const DeleteFdrTemplateModal = ({
    showDeleteTemplateModal,
    handleYes,
    toggleShowDeleteTemplateModal
}) => {
    return (
        <ReactModal contentLabel="Delete Custom FDR Template" isOpen={showDeleteTemplateModal} className="react-modal react-modal-small" overlayClassName="react-modal-overlay">
            <Header content="Delete Custom FDR Template" />
            <form>
                <h6>
                    Are you sure you want to delete this template?
                </h6>
                <br />
                <Button onClick={toggleShowDeleteTemplateModal} type="button" className="btn btn-secondary right">
                    No
                </Button>
                <Button onClick={handleYes} type="button" className="btn btn-primary right">
                    Yes
                </Button>
            </form>
        </ReactModal>
    );
};

DeleteFdrTemplateModal.propTypes = {
    showDeleteTemplateModal: PropTypes.bool,
    handleYes: PropTypes.func,
    toggleShowDeleteTemplateModal: PropTypes.func
};

export default DeleteFdrTemplateModal;