import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import * as formatters from "../../scripts/formFormatters";
import * as parsers from "../../scripts/formParsers";
import * as formHelpers from "../../scripts/formHelpers";

export const EinField = ({ ...props }) => {
    let properties = { ...props };
    if (!properties.format) {
        properties.format = formatters.formatEin;
    }

    if (!properties.parse) {
        properties.parse = parsers.phoneParser;
    }

    if (!properties.component) {
        properties.component = formHelpers.renderField;
    }

    if (!properties.placeholder) {
        properties.placeholder = "##-#######";
    }

    if (!properties.type) {
        properties.type = "text";
    }

    if (!properties.maxLength) {
        properties.maxLength = 10;
    }

    return (
        <Field {...properties} />
    );
};

export const DateField = ({ ...props }) => {
    let properties = { ...props };

    if (!properties.format) {
        properties.format = formatters.formatDate;
    }

    if (!properties.component) {
        properties.component = formHelpers.renderField;
    }

    if (!properties.placeholder) {
        properties.placeholder = "MM/DD/YYYY";
    }

    if (!properties.type) {
        properties.type = "text";
    }

    if (!properties.maxLength) {
        properties.maxLength = 10;
    }
        
    return (        
        <Field {...properties} />
    );
};

export const MoneyField = ({ ...props }) => {

    let properties = { ...props };
    if (!properties.format) {
        properties.format = formatters.formatCurrency;
    }

    if (!properties.parse) {
        properties.parse = parsers.numberParser;
    }

    if (!properties.component) {
        properties.component = formHelpers.renderField;
    }

    if (!properties.placeholder) {
        properties.placeholder = "$0.00";
    }

    if (!properties.type) {
        properties.type = "text";
    }

    if (!properties.maxLength) {
        properties.maxLength = 20;
    }
    
    return (
        <Field {...properties} />
    );
};

export const PhoneField = ({ ...props }) => {
    let properties = { ...props };
    if (!properties.format) {
        properties.format = formatters.formatPhone;
    }

    if (!properties.parse) {
        properties.parse = parsers.phoneParser;
    }

    if (!properties.component) {
        properties.component = formHelpers.renderField;
    }

    if (!properties.placeholder) {
        properties.placeholder = "(###) ###-####";
    }

    if (!properties.type) {
        properties.type = "tel";
    }

    if (!properties.maxLength) {
        properties.maxLength = 14;
    }

    return (
        <Field {...properties} />
    );
};

export const NumberField = ({ ...props }) => {
    let properties = { ...props };
    if (!properties.format) {
        if (properties.allowDecimal && properties.allowNegativeValues) {
            properties.format = formatters.formatNumberAllowNegativesAndDecimal;
        } else if (properties.allowDecimal) {
            properties.format = formatters.formatNumberAllowDecimal;
        } else if (properties.allowNegativeValues) {
            properties.format = formatters.formatNumberAllowNegatives;
        } else {
            properties.format = formatters.formatNumber;
        }
    }

    delete properties.allowDecimal;
    delete properties.allowNegativeValues;

    if (!properties.parse) {
        properties.parse = parsers.numberParser;
    }

    if (!properties.component) {
        properties.component = formHelpers.renderField;
    }
    
    if (!properties.type) {
        properties.type = "text";
    }

    if (!properties.maxLength) {
        properties.maxLength = 20;
    }

    return (
        <Field {...properties} />
    );
};

export const FileField = ({ ...props }) => {
    let properties = { ...props };

    if (!properties.type) {
        properties.type = "file";
    }

    let extensions = process.env.WHITELISTED_EXTENSIONS;
    if (!properties.accept && Array.isArray(extensions)) {
        properties.accept = extensions.join();
    }

    if (properties.allowMultiple) {
        delete properties.allowMultiple;

        return <input {...properties} multiple />;
    }

    return (
        <input {...properties} />
        );
};