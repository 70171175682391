import React from "react";
import PropTypes from "prop-types";
import DistributionDetailRow from "./DistributionDetailRow";

const DistributionDetailTable = ({ distributionDetails, showForm, handleEditButtonClick, handleDeleteButtonClick, canEdit, isChecklist }) => {
    if (!isChecklist) {
        isChecklist = false;
    }

    return (
        <div className="table-responsive mb-3">
            <table className="rtable">
                <thead>
                    <tr>
                        <th>Class</th>
                        <th>Date</th>
                        <th>Fair Market Value</th>
                        <th>Value</th>
                        {(!isChecklist && <th />) || null}
                    </tr>
                </thead>
                <tbody>
                    {distributionDetails.map(item => <DistributionDetailRow key={item.distributionID} item={item} showForm={showForm} handleEditButtonClick={handleEditButtonClick}
                        handleDeleteButtonClick={handleDeleteButtonClick} canEdit={canEdit} isChecklist={isChecklist} />)}
                </tbody>
            </table>
        </div>
    );
};

DistributionDetailTable.propTypes = {
    distributionDetails: PropTypes.array.isRequired,
    showForm: PropTypes.bool,
    handleEditButtonClick: PropTypes.func,
    handleDeleteButtonClick: PropTypes.func,
    canEdit: PropTypes.bool,
    isChecklist: PropTypes.bool
};

export default DistributionDetailTable;