import React from "react";
import PropTypes from "prop-types";
import { Header, Button } from "semantic-ui-react";
import ReactModal from "react-modal";
import { MotifButton } from "@ey-xd/motif-react";

const ChangeDefaultChartOfAccountsModal = ({
    showChangeDefaultChartModal,
    handleChangeDefaultChart,
    toggleShowChangeDefaultChartModal    
}) => {
    return (
        <ReactModal contentLabel="Set as Default for Client" isOpen={showChangeDefaultChartModal} className="react-modal react-modal-small" overlayClassName="react-modal-overlay">
            <Header content="Set as Default for Client" />
            <form>
                <h6>
                    Are you sure you want to change the default chart of accounts for the Client?
                </h6>
                <br />                
                <MotifButton variant="primary" className="right ml-2" type="button"
                    onClick={handleChangeDefaultChart}>
                    Yes
                </MotifButton>                
                <MotifButton variant="secondary" className="right" type="button"
                    onClick={toggleShowChangeDefaultChartModal}>
                    No
                </MotifButton>
                
            </form>
        </ReactModal>
    );
};

ChangeDefaultChartOfAccountsModal.propTypes = {
    showChangeDefaultChartModal: PropTypes.bool,
    handleChangeDefaultChart: PropTypes.func,
    toggleShowChangeDefaultChartModal: PropTypes.func
};

export default ChangeDefaultChartOfAccountsModal;