import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import { MotifTextLink, MotifCheckbox } from '@ey-xd/motif-react';

const ReitListRow = ({ clientId, reit, getCurrentPeriodFunction, showInactive, handleToggleActiveFlag }) => {
    const period = getCurrentPeriodFunction(reit);
    const toggleActiveFlag = (e) => {
        handleToggleActiveFlag(e, reit);
    };
    let link = '/client/' + clientId + '/reit/' + reit.reitid;

    return (
        <tr>
            <td><Link tabIndex='-1' to={link}><MotifTextLink variant="alt">{reit.reitName}</MotifTextLink></Link>{reit.discrepancyCount > 0 && <span className="ml-1 badge badge-warning badgeStandard">{reit.discrepancyCount}</span> || null}</td>
            <td>{period && period.reportPeriodDescription}</td>
            <td>{period && period.reportPeriodStatusDescription}</td>
            {(showInactive && 
                <td>
                    <MotifCheckbox
                        id={`activate-reit-${reit.reitid}`}
                        name="activate-reit"
                        aria-label="Activate Deactivate reit checkbox"
                        checked={((reit.isActive == null || reit.isActive) && "checked")}
                        onChange={toggleActiveFlag}/>
                </td>) 
                || null}            
            <td title={"Bulk upload tracking ID for " + reit.reitName}>{reit && reit.reitTrackingID}</td>
        </tr>
    );
};

ReitListRow.propTypes = {
    clientId: PropTypes.number,
    reit: PropTypes.object.isRequired,
    getCurrentPeriodFunction: PropTypes.func.isRequired,
    showInactive: PropTypes.bool,
    handleToggleActiveFlag: PropTypes.func.isRequired
};

export default ReitListRow;