import PropTypes from "prop-types";
import React from "react";
import { Link } from 'react-router';
import { Button, Icon } from "semantic-ui-react";
import { FormattedDate } from 'react-intl';
import { MotifIconButton, MotifIcon, MotifTextLink } from "@ey-xd/motif-react";
import { imageIcEdit24px, actionIcDelete24px, navigationIcRefresh24px, contentIcUndo24px, communicationIcEmail24px, actionIcCheckCircle24px, actionIcOpenInNew24px } from '@ey-xd/motif-react/assets/icons';

const ArqcChecklistSummaryItem = ({
    reit,
    selected,
    checklist,
    handleSelectedChecklistChanged,
    handleSendNotification,
    previousChecklist,
    handleRevertChecklistState,
    getCheckListItemStateName,
    isEngagementAdminOrSysAdmin,
    handleDelete,
    canDelete,
    isRevertStateVisible,
    getArqcChecklistLink }) => {
    handleSelectedChecklistChanged = handleSelectedChecklistChanged.bind(this, checklist, previousChecklist);
    handleSendNotification = handleSendNotification.bind(this, checklist);
    handleDelete = handleDelete.bind(this, checklist);
    let className = "";
    if (selected) {
        className = "selected";
    }

    let stateName = getCheckListItemStateName(checklist.checklistStateID);

    let revertChecklistStateDisabled = false;
    if (stateName == "Created") {
        revertChecklistStateDisabled = true;
    }

    return (
        <tr className={className}>
            <td style={{ textAlign: "center" }}>
                {(selected && <span>&nbsp;</span>) ||
                <MotifIconButton aria-label="Select this ARQC" type="button" 
                onClick={handleSelectedChecklistChanged}>
                        <MotifIcon src={actionIcCheckCircle24px} title="Select this ARQC" />
                    </MotifIconButton>
                }
            </td>
            <td>
                <div className="ag-cell" style={{height:"auto"}}>
                <Link tabIndex="-1" to={getArqcChecklistLink(checklist.checklistID)} target="_blank" rel="noopener noreferrer" >
                    <MotifTextLink variant="alt" href={getArqcChecklistLink(checklist.checklistID)}
                        target="_blank" rel="noopener noreferrer" >  <MotifIcon src={actionIcOpenInNew24px} /></MotifTextLink>
                </Link>
                </div>
            </td>
            <td>{reit.reitName}</td>
            <td>{checklist.sentToEmailAddress && [...new Set(checklist.sentToEmailAddress.toLowerCase().split(";"))].join(";")}</td>
            <td>{checklist.sentDate && <FormattedDate value={checklist.sentDate} /> || <span>&nbsp;</span>}</td>
            <td>{stateName === "Sent" ? "Email Sent" : stateName}</td>
            <td style={{ textAlign: "center" }}>{(selected && stateName === "Created" &&
                <MotifIconButton aria-label="Send this ARQC" type="button" 
                onClick={handleSendNotification}>
                <MotifIcon src={communicationIcEmail24px} title="Send this ARQC" />
                    </MotifIconButton>
            ) || <span>&nbsp;</span>}</td>
            {isRevertStateVisible ?
                <td style={{ textAlign: "center" }}>{(selected &&
                    <MotifIconButton aria-label="Revert ARQC State" type="button" disabled={revertChecklistStateDisabled}
                        onClick={handleRevertChecklistState}>
                    <MotifIcon src={contentIcUndo24px} title={revertChecklistStateDisabled ? "" : "Revert ARQC State"} />
                    </MotifIconButton>
                ) || <span>&nbsp;</span>}</td>
                : null}
            {(canDelete && <td style={{ textAlign: "center" }}>                
                <MotifIconButton aria-label="Delete this ARQC" type="button"
                    onClick={handleDelete}>
                    <MotifIcon src={actionIcDelete24px} title="Delete this ARQC" />
                </MotifIconButton>
            </td>) || null}
        </tr>
    );
};

ArqcChecklistSummaryItem.propTypes = {
    reit: PropTypes.object,
    selected: PropTypes.bool,
    checklist: PropTypes.object,
    handleSelectedChecklistChanged: PropTypes.func.isRequired,
    handleSendNotification: PropTypes.func.isRequired,
    previousChecklist: PropTypes.object,
    handleRevertChecklistState: PropTypes.func.isRequired,
    getCheckListItemStateName: PropTypes.func.isRequired,
    isEngagementAdminOrSysAdmin: PropTypes.bool,
    handleDelete: PropTypes.func.isRequired,
    canDelete: PropTypes.bool,
    isRevertStateVisible: PropTypes.bool,
    getArqcChecklistLink: PropTypes.func
};

const ArqcChecklistSummary = ({
    reit,
    activeChecklist,
    previousChecklist,
    checklists,
    handleSelectedChecklistChanged,
    handleSendNotification,
    handleRevertChecklistState,
    getCheckListItemStateName,
    isEngagementAdminOrSysAdmin,
    handleDelete,
    canDelete,
    canRevertState,
    getArqcChecklistLink
 }) => {

    if (checklists === undefined || checklists === null || checklists.length === 0) {
        return (<div>There are no checklists to display</div>);
    }

    let isRevertStateVisible = false;
    if (isEngagementAdminOrSysAdmin && canRevertState) {
        isRevertStateVisible = true;
    }

    return (
        <div>
            <div className="row">
                <div className="col">
                    <span className="pageheader">Annual REIT Qualification Checklist</span>
                </div>
            </div>
            <div className="row">
                <div className="col table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col" style={{ width: '1%' }}>Select</th>
                                <th scope="col" style={{ width: '3%' }}></th>
                                <th scope="col">REIT Name</th>
                                <th scope="col">Sent To</th>
                                <th scope="col">Date Sent</th>
                                <th scope="col">Status</th>
                                <th scope="col" style={{ textAlign: "center" }}>Send</th>
                                {isRevertStateVisible ? <th scope="col" style={{ textAlign: "center" }}>Revert State</th> : null}
                                {(canDelete && <th scope="col" style={{ textAlign: "center" }}>Delete</th>) || null}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                checklists.map(checklist =>
                                    <ArqcChecklistSummaryItem
                                        key={checklist.checklistID}
                                        reit={reit}
                                        selected={activeChecklist && checklist.checklistID === activeChecklist.checklistID}
                                        checklist={checklist}
                                        handleSendNotification={handleSendNotification}
                                        handleSelectedChecklistChanged={handleSelectedChecklistChanged}
                                        previousChecklist={previousChecklist}
                                        handleRevertChecklistState={handleRevertChecklistState}
                                        getCheckListItemStateName={getCheckListItemStateName}
                                        isEngagementAdminOrSysAdmin={isEngagementAdminOrSysAdmin}
                                        handleDelete={handleDelete}
                                        canDelete={canDelete}
                                        isRevertStateVisible={isRevertStateVisible}
                                        getArqcChecklistLink={getArqcChecklistLink} />)
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

ArqcChecklistSummary.propTypes = {
    reit: PropTypes.object,
    checklists: PropTypes.array,
    activeChecklist: PropTypes.object,
    selected: PropTypes.bool,
    handleSelectedChecklistChanged: PropTypes.func.isRequired,
    handleSendNotification: PropTypes.func.isRequired,
    previousChecklist: PropTypes.object,
    handleRevertChecklistState: PropTypes.func.isRequired,
    getCheckListItemStateName: PropTypes.func.isRequired,
    isEngagementAdminOrSysAdmin: PropTypes.bool,
    handleDelete: PropTypes.func.isRequired,
    canDelete: PropTypes.bool,
    canRevertState: PropTypes.bool,
    getArqcChecklistLink: PropTypes.func
};

export default ArqcChecklistSummary;