import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Icon, Progress, Button, Header } from 'semantic-ui-react';
import ReactModal from "react-modal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as checklistActions from "../../actions/checklistActions";
import { MotifButton, MotifModal, MotifModalHeader, MotifModalBody, MotifModalFooter,MotifIcon, MotifTextLink } from '@ey-xd/motif-react';
import * as Constants from "../../constants/other";
import { fileIcCloudUpload24px } from '@ey-xd/motif-react/assets/icons';

/**
 * Container component used for uploading trial balance files
 */
export class ChecklistFileUploadModal extends React.Component {
    /**
     * Creates a new ChecklistFileUploadModal
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);

        this.renderUploadStatus = this.renderUploadStatus.bind(this);
        this.onDrop = this.onDrop.bind(this);

        this.state = {
            status: null,
            rejectedFiles: []
        };

        this.acceptedFileExtensions = '.xlsx,.docx,.pdf';
        const extensions = process.env.WHITELISTED_EXTENSIONS;
        if (Array.isArray(extensions)) {
            this.acceptedFileExtensions = extensions.join();
        }
    }

    onDrop(acceptedFiles, rejectedFiles) {
        if (Array.isArray(rejectedFiles) && rejectedFiles.length > 0) {
            this.setState({ status: 'Rejected', rejectedFiles });
        } else if (Array.isArray(acceptedFiles)) {
            const activeChecklist = this.props.isArqc ? this.props.arqcActiveChecklist : this.props.psqActiveChecklist;
            if (activeChecklist) {
                this.setState({ status: 'Uploading', rejectedFiles: [] });

                this.props.checklistActions.uploadChecklistFiles(
                    acceptedFiles,
                    activeChecklist.checklistID,
                    this.props.isArqc ? 'ARQC' : 'PSQ',
                    this.props.questionId,
                    this.props.checklistId)
                    .then(responses => {
                        if (responses.every(r => r)) {
                            this.refreshChecklistFiles(activeChecklist.checklistID, this.props.isArqc ? 'ARQC' : 'PSQ', this.props.checklistId);
                            this.setState({ status: 'Success' });
                        } else {
                            this.setState({ status: 'Error' });
                        }
                    });
            }
        }
    }

    refreshChecklistFiles(checklistId, checklistType, checklistGuid) {
        this.props.checklistActions.fetchAttachmentDetail(this.props.client.id,
            checklistId, null, checklistGuid, checklistType);
        const request = {
            GetCountOfAttachments: true,
            GetCountOfDiscrepencies: true,
            GetCountOfComments: true,
        };

        this.props.checklistActions
            .fetchChecklistNavCounts(
                this.props.client.id,
                checklistId,
                request,
                checklistGuid,
                checklistType
            );
    }
    renderUploadStatus() {
        let msg;
        let colorClass;

        if (this.state.status == 'Uploading') {
            msg = 'Uploading file(s)';
        } else if (this.state.status == 'Success') {
            msg = 'File(s) Uploaded Successfully';
            colorClass = 'text-success';
        } else if (this.state.status == 'Rejected') {
            msg = 'The following file(s) were rejected:';
            colorClass = 'text-danger';
        } else if (this.state.status == 'Error') {
            msg = 'There was a problem uploading your file(s)';
            colorClass = 'text-danger';
        }

        return msg &&
            <div className='mb-2 mr-2 ml-2'>
                <Header size='small' className={colorClass} content={msg} />
                {this.state.rejectedFiles && this.state.rejectedFiles.length > 0 && this.state.rejectedFiles.map((f, index) =>
                    <div key={index} className='row bg-danger text-white mr-2 ml-2'>
                        <div className='col-md-6'>{f.file.name}</div>
                        <div className='col-md-6'>
                            {f.file.size > Constants.MAX_UPLOAD_FILE_SIZE_IN_BYTES
                                ? <span>The file size has exceeded max limit of {Constants.MAX_UPLOAD_FILE_SIZE_IN_MB}MB</span>
                                : <span>Invalid file extension</span>}
                        </div>
                    </div>
                )}
                {this.state.rejectedFiles && this.state.rejectedFiles.length > 0 &&
                    !this.state.rejectedFiles.every(f => f.file.size > Constants.MAX_UPLOAD_FILE_SIZE_IN_BYTES) &&
                    <div className='row mt-2'>
                        <strong className='col-md-12'>
                            The following file extensions are accepted: {this.acceptedFileExtensions.replace(/\./g, '').replace(/\,/g, ', ')}
                        </strong>
                    </div>}
            </div>;
    }

    handleCloseModal()
    {
        this.setState({ status: null, rejectedFiles: [] });
    }

    /**
        * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        const HeaderWrapper = ({ children }) => (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'start',
                    justifyContent: 'center'
                }}
            >
                {children}
            </div>
        );

        const closeButtonProps = {
            title: 'Close upload file(s) to checklist question modal',
            ariaLabel: 'Close upload file(s) to checklist question modal',
            dataTestid: 'close'
        };

        return (

            <MotifModal variant="alt" show={this.props.isChecklistFileUploadModalOpen} onClose={() => {
                this.props.hideChecklistFileUploadModal();
                this.handleCloseModal();
              }} data-testid="uploadChecklistFile">
                <MotifModalHeader style={{ display: "-webkit-box" }} closeButtonProps={closeButtonProps} >
                    <HeaderWrapper>
                        <span className="ml-2">Upload File(s) to Checklist Question</span>
                    </HeaderWrapper>
                </MotifModalHeader>

                <MotifModalBody>
                    {this.renderUploadStatus()}
                    <Dropzone
                        tabIndex="-1"
                        multiple={true}
                        accept={this.acceptedFileExtensions}
                        maxSize={Constants.MAX_UPLOAD_FILE_SIZE_IN_BYTES}
                        onDrop={this.onDrop}>
                        {({ getRootProps, getInputProps }) => (
                            <section className="container">
                                <div {...getRootProps({ className: 'dropzone' })} tabIndex="-1"
                                    style={{ width: '100%', textAlign: 'center', border: '1px dashed #666', padding: '50px' }} >
                                    <div><MotifIcon src={fileIcCloudUpload24px} className="uploadIconSize" title="Upload File(s)" /></div>
                                    <input {...getInputProps()} />
                                    <span className="checklistActionMsg">Try dropping some files here, or click to <MotifTextLink href="javascript:void(0)" variant="alt">select files</MotifTextLink> to upload.</span>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </MotifModalBody>
                <MotifModalFooter>
                    <MotifButton variant="secondary" className="right" type="button"
                        onClick={() => {
                            this.props.hideChecklistFileUploadModal();
                            this.handleCloseModal();
                          }} >
                        Close
                    </MotifButton>
                </MotifModalFooter>

            </MotifModal>
        );
    }
}

ChecklistFileUploadModal.propTypes = {
    checklistId: PropTypes.string, // Will only be passed in for external users (string of email identifier)
    questionId: PropTypes.number.isRequired,
    hideChecklistFileUploadModal: PropTypes.func.isRequired,
    authHeader: PropTypes.object,
    psqActiveChecklist: PropTypes.object,
    isArqc: PropTypes.bool.isRequired,
    isChecklistFileUploadModalOpen:PropTypes.bool
};

/**
 * Maps items from state to properties of the component
* @param {Object} state The state
* @param {Object} ownProps The properties of the component
* @returns {Object} An object containing properties that the component can access
*/
function mapStateToProps(state, ownProps) {
    return {
        psqActiveChecklist: state.psqActiveChecklist,
        arqcActiveChecklist: state.arqcActiveChecklist,
        client: state.client
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        checklistActions: bindActionCreators(checklistActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistFileUploadModal);