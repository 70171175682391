import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Header, Button } from "semantic-ui-react";
import ReactModal from "react-modal";
import { MotifButton, MotifModal, MotifModalHeader, MotifModalBody, MotifModalFooter } from '@ey-xd/motif-react';

const MaintainLoginModal = ({
    showMaintainTemplateModal,
    handleYes,
    handleNo,
    toggleShowMaintainTemplateModal
}) => {
    let modalContentInitialState = {
        title: "Session Information", 
        msg: `Your session will expire in ${Math.floor(parseInt(process.env.ACCESS_TOKEN_IDLE_MODAL_EXPIRE_MILLESECONDS) / 60000)} minutes.`,
        yesButton: "Keep Working",
        noButton: "Log Out"
    }
    const [modalContent, setModalContent] = useState(modalContentInitialState);
    const [modalExpired, setModalExpired] = useState(false);
    useEffect(() => {
        let modalTimeout = 0;
        if(showMaintainTemplateModal){
            modalTimeout = setTimeout(() => {
                setModalContent({ 
                    title: "Session Information", 
                    msg: "You have been logged out due to inactivity.",
                    yesButton: "Login Again",
                    noButton: "Log out"
                });
                setModalExpired(true);
            }, process.env.ACCESS_TOKEN_IDLE_MODAL_EXPIRE_MILLESECONDS);
        }
        return () => {
            clearTimeout(modalTimeout);
        }
    }, [showMaintainTemplateModal])

    const handleModalExpireAction = () =>{
        window.location.reload();
    }

    const HeaderWrapper = ({ children }) => (
        <div
            style={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'center'
            }}
        >
            {children}
        </div>
    );
    const closeButtonProps = {
        style:{visibility: 'hidden'}
    };

    return (
        <MotifModal variant="alt" size="sm" show={showMaintainTemplateModal} data-testid="maintainLoginModal">
        <MotifModalHeader style={{ display: "-webkit-box" }} closeButtonProps={closeButtonProps}>
            <HeaderWrapper>
                <span className="ml-2">{modalContent.title}</span>
            </HeaderWrapper>
        </MotifModalHeader>
       
            <MotifModalBody>
            <span className="checklistActionMsg">{modalContent.msg}</span>
            </MotifModalBody>
            <MotifModalFooter>
            <MotifButton variant="primary" className="right ml-2" type="button" data-testid="btnYes"
                onClick={() => handleYes(modalExpired)}>
                {modalContent.yesButton}
            </MotifButton>
            <MotifButton variant="secondary" className="right" type="button" data-testid="btnNo"
                onClick={handleNo}>
                {modalContent.noButton}
            </MotifButton>
            </MotifModalFooter>
        
    </MotifModal>      
    );
};

MaintainLoginModal.propTypes = {
    showMaintainTemplateModal: PropTypes.bool,
    handleYes: PropTypes.func,
    toggleShowMaintainTemplateModal: PropTypes.func
};

export default MaintainLoginModal;