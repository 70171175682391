import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, IndexLink } from 'react-router';
import { IntlProvider } from 'react-intl';
import NotificationSystem from 'react-notification-system';
import NavBar from './NavBar';
import Main from '../containers/Main';
import * as authActions from '../actions/authActions';
import * as bulkProcessManagementActions from '../actions/bulkProcessManagementActions';bulkRollForwardActions
import * as bulkRollForwardActions from '../actions/bulkRollForwardActions'; 
import * as notificationActions from '../actions/notificationActions';
import store from '../store/configureStore';
import * as authenticationActions from '../actions/authenticationActions';
import MaintainLoginModal from './MaintainLoginModal';
import * as actions from '../actions/actionTypes';
import { LicenseManager } from 'ag-grid-enterprise';
import axios from 'axios';
import * as signalR from '@microsoft/signalr';
import { MotifToast } from '@ey-xd/motif-react';

import {
    withMsal,
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from '@azure/msal-react';
import {
    InteractionStatus,
    InteractionRequiredAuthError,
} from '@azure/msal-browser';

/**
 * The App class.  This is the parent component for all routes.
 * @extends {React.Component}
 */
class App extends React.Component {
    /**
     * Creates a new App
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);

        this.connection = {};
        this.queueItems = {
            pendingQueueItems: [],
            completedQueueItems: [],
        };
        this.notificationSystem = React.createRef();
        this.processingDataNotification = null;
        this.lastPathname = '';
        this.establishedUser = false;
        this.fetchAuthorizations = false;

        this.updateProcessingDataNotification =
            this.updateProcessingDataNotification.bind(this);
        this.getConnectionInfo = this.getConnectionInfo.bind(this);
        this.executeJoinClient = this.executeJoinClient.bind(this);
        this.executeAddToGroup = this.executeAddToGroup.bind(this);
        this.executeRemoveFromGroup = this.executeRemoveFromGroup.bind(this);
        this.handleJoinClient = this.handleJoinClient.bind(this);
        this.handleCompletedQueueItem = this.handleCompletedQueueItem.bind(this);
        this.handleResendChecklistCompletedQueueItem =
            this.handleResendChecklistCompletedQueueItem.bind(this);
        this.handleCompletedQueueBatch = this.handleCompletedQueueBatch.bind(this);
        this.handleAddNewQueueItems = this.handleAddNewQueueItems.bind(this);

        this.handleCompletedBulkProcessForAllRequestsOfBatchGUID = this.handleCompletedBulkProcessForAllRequestsOfBatchGUID.bind(this);
        this.handleBulkProcessNotificationClose = this.handleBulkProcessNotificationClose.bind(this);

        this.handleCompletedBulkReportPackageStatus = this.handleCompletedBulkReportPackageStatus.bind(this);
        this.handleBulkReportPackageNotificationClose = this.handleBulkReportPackageNotificationClose.bind(this);
        this.handleBulkProcessStatusUpdate = this.handleBulkProcessStatusUpdate.bind(this);
        this.handleAddToGroup = this.handleAddToGroup.bind(this);
        this.handleRemoveFromGroup = this.handleRemoveFromGroup.bind(this);
        this.hideNotificationAndResetData =
            this.hideNotificationAndResetData.bind(this);
        this.toggleShowMaintainTemplateModal =
            this.toggleShowMaintainTemplateModal.bind(this);
        this.handleUserModalLogin = this.handleUserModalLogin.bind(this);
        this.handleUserLogOut = this.handleUserLogOut.bind(this);
        this.useIdToken = this.useIdToken.bind(this);

        this.notificationStyle = {
            NotificationItem: {
                // Override the notification item
                DefaultStyle: {
                    // Applied to every notification, regardless of the notification level
                    borderTop: null,
                    borderRadius: '10px',
                },

                info: {
                    // Applied only to the info notification item
                    backgroundColor: '#2E2E38',
                    color: '#FFFFFF',
                },
            },
            Title: {
                info: {
                    color: '#FFFFFF',
                },
            },
        };

        this.createdRouterListener = false;
        this.state = {
            showMaintainLoginModal: false,
            message: 'Authentication in progress...',
        };
    }

    componentDidMount() {
        this.useIdToken();

        //Set AG Grid license key
        LicenseManager.setLicenseKey(process.env.AG_GRID_UI_LICENSE);
    }

    componentDidUpdate() {
        // If user is logged in as external, but still on the "App" container, we need to route to external app.
        // This can happen if user manually navigates to an internal URL, then clicks that they are NOT an EY employee.
        if (this.props.authenticationScope === 'external') {
            this.props.router.push('/ext');
            return;
        }

        // Redirect unauthorized user to Access Denied Error page
        if (this.fetchAuthorizations && this.props.authenticationScope === 'internal'
            && this.props.currentUserAuthorizations && Array.isArray(this.props.currentUserAuthorizations)
            && this.props.currentUserAuthorizations.length > 0
            && !authActions.isSystemAdministrator(this.props.currentUserAuthorizations)) {

            // Though the user is on client page, the props.client or this.props.client.id could be null as the user
            // is not authorized to access that client,  
            // Also for authorized user, at this point the props.client details are not yet populated
            // hence get client id from url
            if (this.props.location.pathname.toLowerCase().indexOf("/client/") >= 0) {
                let clientPath = this.props.location.pathname.toLowerCase();
                clientPath = clientPath.replace('/client/', '')
                let clientId = -1;
                if (clientPath.indexOf("/") >= 0) {
                    clientId = parseInt(clientPath.substring(0, clientPath.indexOf("/") + 1));
                }
                else {
                    clientId = parseInt(clientPath);
                }
                if (clientId && clientId > 0) {
                    // Redirect unauthorized user to Access Denied Error page
                    if (!authActions.isEngagementAdministrator(this.props.currentUserAuthorizations, clientId)
                        && !authActions.isUser(this.props.currentUserAuthorizations, clientId)) {
                        this.props.router.push(`/unauthorized`);
                        return;
                    }
                }
                else {
                    this.props.router.push(`/unauthorized`);
                    return;
                }
            }
        }

        // if we have not already fetched the authorizations
        // and both idToken and currentUser are populated
        // then fetch authorizations
        if (
            !this.fetchAuthorizations &&
            this.props.idToken &&
            this.props.currentUser
        ) {
            this.fetchAuthorizations = true;

            // Fetch authorizations
            this.props.authActions
                .fetchAuthorizationsForCurrentUser(this.props.currentUser)
                .then((currentUserAuthorizations) => {
                    // If authorizations contain any External Permanent User role (role == 4)
                    if (currentUserAuthorizations.some((x) => x.role === 4)) {
                        this.props.authenticationActions.setAuthenticationScope('external');
                    } else {
                        this.props.authenticationActions.setAuthenticationScope('internal');
                    }

                    // If they have any authorizations, continue with redirect
                    // else, they do not have authorization to the App
                    if (currentUserAuthorizations.length > 0) {
                        // If authorizations found, clear message
                        this.setState({ message: null });
                    } else {
                        this.setState({
                            message:
                                'You are not authorized to use this application. Please contact your Administrator to obtain access.',
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.setState({ message: 'Failed to authorize. Please try again.' });
                });
        }
    }

    /**
     * Invoked before a mounted component receives new props.
     * @param {Object} nextProps The properties that the component is receiving
     */
    componentWillReceiveProps(nextProps) {
        if (!this.establishedUser && nextProps.currentUser) {
            this.establishedUser = true;

            this.timer = null;

            // Token renewal modal toggle timeout
            this.idTokenRefreshTimer = setTimeout(() => {
                this.setState({
                    showMaintainLoginModal: true,
                });
            }, process.env.ACCESS_TOKEN_IDLE_MODAL_POPUP_MILLESECONDS);

            if (!this.createdRouterListener) {
                this.createdRouterListener = true;

                this.props.router.listen((location) => {
                    const pathname = location.pathname;
                    if (pathname !== this.lastPathname) {
                        this.lastPathname = pathname;
                        const accounts = this.props.msalContext.instance.getAllAccounts();
                        if (accounts[0].idTokenClaims.exp) {
                            // Look for page changes here to trigger that user is still "active"
                            const exp = accounts[0].idTokenClaims.exp;

                            let expDate = new Date(0);
                            expDate.setUTCSeconds(exp);

                            let curDate = new Date();

                            const diff = expDate.getTime() - curDate.getTime();

                            //console.log(`ROUTE CHANGED: Token Exp: ${expDate}. Diff: ${diff}. ACCESS_TOKEN_SILENT_REFRESH_MILLESECONDS: ${process.env.ACCESS_TOKEN_SILENT_REFRESH_MILLESECONDS}`)
                            if (diff < process.env.ACCESS_TOKEN_SILENT_REFRESH_MILLESECONDS) {
                                this.useIdToken().then(() => {
                                    clearTimeout(this.idTokenRefreshTimer);
                                    this.idTokenRefreshTimer = setTimeout(() => {
                                        this.setState({
                                            showMaintainLoginModal: true,
                                        });
                                    }, process.env.ACCESS_TOKEN_IDLE_MODAL_POPUP_MILLESECONDS);
                                });
                            }
                        }
                    }
                });
            }

            notificationActions.pollNotifications(
                store.dispatch,
                nextProps.currentUser,
            );
        }

        const isOnClientPage =
            nextProps.location && nextProps.location.pathname.includes('/client/');
        const wasOnClientPage =
            this.props.location && this.props.location.pathname.includes('/client/');

        //SignalR
        const previousClientId = this.props.client ? this.props.client.id : null;
        if (
            isOnClientPage &&
            nextProps.client &&
            nextProps.client.id &&
            nextProps.client.id !== previousClientId
        ) {
            this.getConnectionInfo(nextProps.client.id)
                .then((info) => {
                    // make compatible with old and new SignalRConnectionInfo
                    info.accessToken = info.accessToken || info.accessKey;
                    info.url = info.url || info.endpoint;

                    //data.ready = true;
                    var options = {
                        accessTokenFactory: () => info.accessToken,
                    };

                    this.connection = new signalR.HubConnectionBuilder()
                        .withUrl(info.url, options)
                        .withAutomaticReconnect()
                        .configureLogging(signalR.LogLevel.Error)
                        .build();

                    //Handle client events
                    this.connection.on('joinClient', this.handleJoinClient);
                    this.connection.on(
                        'completedQueueItem',
                        this.handleCompletedQueueItem,
                    );
                    this.connection.on(
                        'completedResendChecklistQueueItem',
                        this.handleResendChecklistCompletedQueueItem,
                    );
                    this.connection.on(
                        'completedQueueBatch',
                        this.handleCompletedQueueBatch,
                    );
                    this.connection.on('completedBulkProcessForAllRequestsOfBatchGUID', this.handleCompletedBulkProcessForAllRequestsOfBatchGUID);
                    this.connection.on('completedBulkReportPackage', this.handleCompletedBulkReportPackageStatus);
                    this.connection.on('bulkProcessStatusUpdate', this.handleBulkProcessStatusUpdate);
                    this.connection.on('addNewQueueItems', this.handleAddNewQueueItems);
                    this.connection.on('addToGroup', this.handleAddToGroup);
                    this.connection.on('removeFromGroup', this.handleRemoveFromGroup);

                    // Handle SignalR disconnect
                    this.connection.onclose(() => {
                        this.hideNotificationAndResetData();
                    });

                    //Handle SignalR reconnect
                    this.connection.onreconnected((connectionId) => {
                        var reconnectConnectionId =
                            this.connection && this.connection.connectionId
                                ? this.connection.connectionId
                                : null;

                        this.executeJoinClient(connectionId);
                    });

                    //Start hub connection
                    this.connection
                        .start()
                        .then(() => {
                            var startConnectionId =
                                this.connection && this.connection.connectionId
                                    ? this.connection.connectionId
                                    : null;

                            this.executeJoinClient(startConnectionId);
                        })
                        .catch((e) => {
                            console.log('Error while establishing SignalR connection. ' + e);
                        });
                })
                .catch((e) => {
                    console.log('Error while establishing SignalR connection. ' + e);
                });
        } else if (!isOnClientPage && wasOnClientPage) {
            clearTimeout(this.processingDataNotificationTimeout);

            // Clear hub connection
            if (
                this.connection &&
                this.connection.state &&
                this.connection.state !== signalR.HubConnectionState.Disconnected
            ) {
                this.connection.stop();
            }
        } else {
            // alert('client is null, signalr disconnect');
        }
    }

    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }

        clearTimeout(this.processingDataNotificationTimeout);
        clearTimeout(this.idTokenRefreshTimer);
        // Clear hub connection
        if (
            this.connection &&
            this.connection.state &&
            this.connection.state !== signalR.HubConnectionState.Disconnected
        ) {
            this.connection.stop();
        }
    }

    async useIdToken() {
        const { instance } = this.props.msalContext;
        const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

        // If inProgress is not None, then we are probably in the middle of a redirect login. Wait until status is back to None before continuing.
        while (this.props.msalContext.inProgress !== InteractionStatus.None) {
            await sleep(100);
        }

        const accounts = instance.getAllAccounts();

        //console.log('useIdToken: accounts', accounts)
        if (accounts.length > 0) {
            const request = {
                scopes: ['user.read', 'openid', 'profile', 'offline_access'],
                account: accounts[0],
                forceRefresh: true,
                //state: window.location.href
            };
            //console.log('useIdToken: Calling acquireTokenSilent')
            const silentResponse = await instance
                .acquireTokenSilent(request)
                .then((response) => {
                    //console.log('useIdToken: acquireTokenSilent response: ', response);
                    return response;
                })
                .catch(async (error) => {
                    // acquireTokenSilent can fail for a number of reasons, fallback to interaction
                    if (error instanceof InteractionRequiredAuthError) {
                        return await instance.acquireTokenRedirect(request);
                    } else {
                        throw error;
                    }
                });

            //console.log('silentResponse', silentResponse);

            // Setting the ID_Token to the store
            await store.dispatch({
                type: actions.SET_ID_TOKEN,
                idToken: silentResponse.idToken,
            });

            // Setting the Access_Token to the store
            await store.dispatch({
                type: actions.SET_ACCESS_TOKEN,
                accessToken: silentResponse.accessToken,
            });

            await this.props.authenticationActions.setCurrentUser(
                silentResponse.idTokenClaims,
            );
        } else {
            const authRequest = {
                scopes: ['user.read', 'openid', 'profile', 'offline_access'],
                state: window.location.href,
            };

            instance.loginRedirect(authRequest);
        }
    }

    /*
     * SignalR methods
     */

    hideNotificationAndResetData() {
        try {
            if (this.processingDataNotification) {
                const notification = this.notificationSystem.current;
                notification.removeNotification(this.processingDataNotification);
            }
        } catch (err) {
            // do nothing
        } finally {
            this.processingDataNotification = null;

            // Reset items arrays
            this.queueItems.pendingQueueItems = [];
            this.queueItems.completedQueueItems = [];
        }
    }

    toggleShowMaintainTemplateModal() {
        this.setState({
            showMaintainLoginModal: !this.state.showMaintainLoginModal,
        });
    }

    handleUserModalLogin(modalExpired) {
        if (!modalExpired) {
            this.toggleShowMaintainTemplateModal();

            // Calling useIdToken, which will issue silent renew or redirect if needed
            this.useIdToken().then(() => {
                clearTimeout(this.idTokenRefreshTimer);
                this.idTokenRefreshTimer = setTimeout(() => {
                    this.setState({
                        showMaintainLoginModal: true,
                    });
                }, process.env.ACCESS_TOKEN_IDLE_MODAL_POPUP_MILLESECONDS);
            });
        } else {
            // We are expired. Reload entire page to login again.
            window.location.reload();
        }
    }

    handleUserLogOut() {
        this.props.router.push(`/external/logoff`);
    }

    // Execute SignalR negotiate method and get connection info
    getConnectionInfo(clientId) {
        // negotiate connection with clientId added to username
        // so that you can have individual connections to each client with multiple browser windows/tabs at the same time
        return axios
            .post(
                `${process.env.ROOT_REITSUITE_AZURE_FUNCTION_API_URL}/negotiate`,
                null,
                {
                    headers: {
                        'x-ms-signalr-userid': `${this.props.currentUserAuthorizations[0].userId}-${clientId}`,
                    },
                },
            )
            .then((resp) => resp.data);
    }

    // Join client
    executeJoinClient(hubConnectionId) {
        return axios
            .post(
                `${process.env.ROOT_REITSUITE_AZURE_FUNCTION_API_URL}/joinclient`,
                {
                    clientName: this.props.client.name,
                    clientId: this.props.client.id,
                    connectionId: hubConnectionId,
                    processType: 1,
                    userId: this.props.currentUserAuthorizations[0].userId,
                },
                {
                    headers: {},
                },
            )
            .then((resp) => resp);
    }

    // Add user id to group
    executeAddToGroup() {
        return axios
            .post(
                `${process.env.ROOT_REITSUITE_AZURE_FUNCTION_API_URL}/addToGroup`,
                {
                    clientName: this.props.client.name,
                    clientId: this.props.client.id,
                    userId: this.props.currentUserAuthorizations[0].userId,
                },
                {
                    headers: {
                        'x-ms-signalr-userid':
                            this.props.currentUserAuthorizations[0].userId,
                    },
                },
            )
            .then((resp) => resp)
            .catch((e) => {
                console.log('Error in adding user to SignalR group' + e);
            });
    }

    // Remove user id from group
    executeRemoveFromGroup(client) {
        return axios
            .post(
                `${process.env.ROOT_REITSUITE_AZURE_FUNCTION_API_URL}/removeFromGroup`,
                {
                    clientName: this.props.client.name,
                    clientId: this.props.client.id,
                    userId: this.props.currentUserAuthorizations[0].userId,
                },
                {
                    headers: {
                        'x-ms-signalr-userid':
                            this.props.currentUserAuthorizations[0].userId,
                    },
                },
            )
            .then((resp) => resp)
            .catch((e) => {
                console.log('Error in removing user from SignalR group' + e);
            });
    }

    // Handle client event of client has connected to hub
    handleJoinClient(message) {
        this.queueItems = message;

        this.updateProcessingDataNotification();
    }

    // Handle client event of queue item is completed
    handleCompletedQueueItem(message) {
        if (
            this.queueItems &&
            this.queueItems.pendingQueueItems &&
            message &&
            message.BatchQueueItemID
        ) {
            var removeIndex = this.queueItems.pendingQueueItems
                .map(function(item) {
                    return item.Id;
                })
                .indexOf(message.BatchQueueItemID);
            if (removeIndex != -1) {
                // remove queue item
                var removedItem = this.queueItems.pendingQueueItems.splice(
                    removeIndex,
                    1,
                );
                if (
                    removedItem &&
                    removedItem.length > 0 &&
                    this.queueItems.completedQueueItems
                ) {
                    let newQueueItem = removedItem[0];
                    newQueueItem.st = 'cq';
                    this.queueItems.completedQueueItems.push(newQueueItem);
                }
            }

            this.updateProcessingDataNotification();
        }
    }

    // Handle client event of Resend Checklist queue item is completed
    handleResendChecklistCompletedQueueItem(clientId) {
        // Update the state on redux to notify user to refresh manage checklist page
        this.props.authActions.refreshManageChecklistClient(clientId);
    }

    // Handle client event of queue batch is completed
    handleCompletedQueueBatch(message) { }

    // Handle client event of bulk process for all requests of batch guid is completed 
    handleCompletedBulkProcessForAllRequestsOfBatchGUID(message) {        

        const notification = this.notificationSystem.current;

        if (message && message.ClientID && this.props.client && message.ClientID === this.props.client.id && notification) {

            let toastVariant = 'info';
            let bulkProcessRequestStatusMsg = '';
            if(message.ActionType=="roll forwarding"){
                const rollForwarddetails=this.getRollForwardMessageForBottomCorner(message);
                toastVariant=rollForwarddetails.toastVariant;
                bulkProcessRequestStatusMsg=rollForwarddetails.bulkProcessRequestStatusMsg;
            }
            else{
            if (message.FailedRequestsCount === message.TotalRequestsCount) {
                // All requests are failed
                bulkProcessRequestStatusMsg = `Bulk updating the ${message.ActionType} is failed.`;
                toastVariant = 'error';
            }
            else if (message.CompletedRequestsCount === message.TotalRequestsCount) {
                // All requests are completed successfully
                bulkProcessRequestStatusMsg = `Bulk updating the ${message.ActionType} is processed successfully.`;
                toastVariant = 'success';
            }
            else if (message.FailedRequestsCount > 0 && message.NotApplicableRequestsCount > 0) {
                // Alteast 1 request of failed type and not applicable type
                bulkProcessRequestStatusMsg = `Bulk updating the ${message.ActionType} is completed. ${message.NotApplicableRequestsCount} REITs are not applicable for the selected action. Failed to update ${message.FailedRequestsCount} REITs.`;
                toastVariant = 'info';
            }
            else if (message.FailedRequestsCount > 0 && message.NotApplicableRequestsCount == 0) {
                // Alteast 1 request of failed type
                bulkProcessRequestStatusMsg = `Bulk updating the ${message.ActionType} is completed. Failed to update ${message.FailedRequestsCount} REITs.`;
                toastVariant = 'info';
            }
            else if (message.FailedRequestsCount == 0 && message.NotApplicableRequestsCount > 0) {
                // Alteast 1 request of not applicable type
                bulkProcessRequestStatusMsg = `Bulk updating the ${message.ActionType} is completed. ${message.NotApplicableRequestsCount} REITs are not applicable for the selected action.`;
                toastVariant = 'info';
            }
        }
            var bulkProcessRequestStatusNotification = notification.addNotification({
                message: (
                    <MotifToast position="right"
                        variant={toastVariant}
                        onClose={() => this.handleBulkProcessNotificationClose(bulkProcessRequestStatusNotification)}
                    >
                        <div>
                            <div className="row"><div className="mb-2 ml-2 font-weight-bold">Bulk Process Request Status</div></div>
                            <div className="row"><div className="ml-3 mr-2">
                                {bulkProcessRequestStatusMsg}
                            </div></div>
                        </div>
                    </MotifToast>
                ),
                level: 'info',
                position: 'br',
                dismissible: 'none',
                autoDismiss: 5
            });
        }
    }

    getRollForwardMessageForBottomCorner(message) {
        let toastVariant = 'info';
        let bulkProcessRequestStatusMsg = '';
        if (message.FailedRequestsCount === message.TotalRequestsCount) {
            // All requests are failed
            bulkProcessRequestStatusMsg = `Bulk roll forwarding REITs is failed.`;
            toastVariant = 'error';
        }
        else if (message.CompletedRequestsCount === message.TotalRequestsCount) {
            // All requests are completed successfully
            bulkProcessRequestStatusMsg = `Bulk roll forwarding REITs is processed successfully.`;
            toastVariant = 'success';
        }
        else if (message.FailedRequestsCount > 0 && message.NotApplicableRequestsCount > 0) {
            // Alteast 1 request of failed type and not applicable type
            bulkProcessRequestStatusMsg = `Bulk roll forwarding REITs is completed. ${message.NotApplicableRequestsCount} REITs are not applicable for the selected action. Failed to roll forward ${message.FailedRequestsCount} REITs.`;
            toastVariant = 'info';
        }
        else if (message.FailedRequestsCount > 0 && message.NotApplicableRequestsCount == 0) {
            // Alteast 1 request of failed type
            bulkProcessRequestStatusMsg = `Bulk roll forwarding REITs is completed. Failed to roll forward ${message.FailedRequestsCount} REITs.`;
            toastVariant = 'info';
        }
        else if (message.FailedRequestsCount == 0 && message.NotApplicableRequestsCount > 0) {
            // Alteast 1 request of not applicable type
            bulkProcessRequestStatusMsg = `Bulk roll forwarding REITs is completed. ${message.NotApplicableRequestsCount} REITs are not applicable for the selected action.`;
            toastVariant = 'info';
        }

        const rollForwardMessage = {
            toastVariant: toastVariant,
            bulkProcessRequestStatusMsg: bulkProcessRequestStatusMsg
        }

        return rollForwardMessage;

    }


    // Handle close event of bulk process notification
    handleBulkProcessNotificationClose(bulkProcessRequestStatusNotification) {
        const notification = this.notificationSystem.current;
        if (bulkProcessRequestStatusNotification && notification) {
            notification.removeNotification(bulkProcessRequestStatusNotification)
        }
    }

    // Handle client event of bulk report package process completed
    handleCompletedBulkReportPackageStatus(message) {
        // Send message to Redux, so the table data can be updated with new status
        store.dispatch({
            type: actions.RECEIVE_BULK_REPORT_PACKAGE_MESSAGE,
            message: message 
        });

        const notification = this.notificationSystem.current;

        const isCompletedOrFailed = message && message.BulkReportStatusDescription &&
            (message.BulkReportStatusDescription.toLowerCase() == 'completed' || message.BulkReportStatusDescription.toLowerCase() == 'failed');

        if (message && message.ClientID && this.props.client && message.ClientID === this.props.client.id
            && message.BulkReportStatusDescription && message.BulkReportStatusDescription.length > 0 && notification && isCompletedOrFailed) {

            let packageStatusMsg = message.BulkReportStatusDescription.toLowerCase() == 'completed'
                ? `"${message.BulkReportPackageName}" is complete, and the extract is ready to download.`
                : `"${message.BulkReportPackageName}" is failed.`;

            if (packageStatusMsg.length > 0) {
                var bulkReportPackageStatusNotification = notification.addNotification({
                    message: (
                        <MotifToast position="right"
                            variant={message.BulkReportStatusDescription.toLowerCase() == 'completed' ? 'success' : 'error'}
                            onClose={() => this.handleBulkReportPackageNotificationClose(bulkReportPackageStatusNotification)}
                        >
                            <div>
                                <div className="row"><div className="mb-2 ml-2 font-weight-bold">Bulk Report Package Status</div></div>
                                <div className="row"><div className="ml-3 mr-2">
                                    <span style={{ wordBreak: 'break-word' }}>  {packageStatusMsg} </span>
                                </div></div>
                            </div>
                        </MotifToast>
                    ),
                    level: 'info',
                    position: 'br',
                    dismissible: 'none',
                    autoDismiss: 5
                });
            }
        }
    }

    handleBulkProcessStatusUpdate(message) {
        if (message.BulkProcessManagementCurrentStatusData && message.BulkProcessManagementCurrentStatusData !== null) {
            // Send message to Redux, so the bulk process management table data can be updated with new status
            this.props.bulkProcessManagementActions.dispatchBulkProcessData(message.BulkProcessManagementCurrentStatusData, actions.RECEIVE_BULK_PROCESS_STATUS_UPDATE_MESSAGE);
            this.props.bulkRollForwardActions.dispatchBulkRollForwardData(message.BulkProcessManagementCurrentStatusData, actions.RECEIVE_BULK_ROLL_FORWARD_STATUS_CONCURRENT_UPDATE_MESSAGE);
        }

        if (message.BulkRollForwardCurrentStatusData && message.BulkRollForwardCurrentStatusData !== null) {
            // Send message to Redux, so the bulk roll forward table data can be updated with new status
            this.props.bulkRollForwardActions.dispatchBulkRollForwardData(message.BulkRollForwardCurrentStatusData, actions.RECEIVE_BULK_ROLL_FORWARD_STATUS_UPDATE_MESSAGE);
            this.props.bulkProcessManagementActions.dispatchBulkProcessData(message.BulkRollForwardCurrentStatusData, actions.RECEIVE_BULK_PROCESS_STATUS_CONCURRENT_UPDATE_MESSAGE);
        }
    }



    // Handle close event of bulk report package notification
    handleBulkReportPackageNotificationClose(bulkReportPackageStatusNotification) {
        const notification = this.notificationSystem.current;
        if (bulkReportPackageStatusNotification && notification) {
            notification.removeNotification(bulkReportPackageStatusNotification)
        }
    }

    // Handle client event of adding new queue item
    handleAddNewQueueItems(message) {
        if (message) {
            let newItem = {};
            for (let item of message) {
                let itemFound = this.queueItems.pendingQueueItems.some(
                    (el) => el.ID === item.BatchQueueItemID,
                );

                if (!itemFound) {
                    newItem = {};
                    newItem.Id = item.BatchQueueItemID;
                    newItem.BatchId = item.BatchQueueID;
                    newItem.ProcessTypeId = item.ProcessTypeID;
                    newItem.st = 'aq';

                    this.queueItems.pendingQueueItems.push(newItem);
                }
            }

            this.updateProcessingDataNotification();
        }
    }

    handleAddToGroup(message) { }

    handleRemoveFromGroup(message) { }

    updateProcessingDataNotification() {
        clearTimeout(this.processingDataNotificationTimeout);

        // Calculate percentage
        const numOfPending = this.queueItems.pendingQueueItems.length;
        const numOfCompleted = this.queueItems.completedQueueItems.length;
        let percent =
            numOfCompleted === 0
                ? 0
                : Math.round((numOfCompleted / (numOfCompleted + numOfPending)) * 100);
        percent = percent > 100 ? 100 : percent;

        const notification = this.notificationSystem.current;
        const autoDismiss = percent == 100;

        if (this.notificationSystem) {
            if (this.processingDataNotification) {
                notification.editNotification(this.processingDataNotification, {
                    message: (
                        <div className="progress">
                            <div
                                className="progress-bar progress-bar-striped progress-bar-animated"
                                style={{
                                    width: `${percent}%`,
                                    backgroundColor: '#FFE600',
                                    color: '#050505',
                                }}
                            >
                                {percent}%
                            </div>
                        </div>
                    ),
                });
            } else if (percent !== 100) {
                // Do not even show notification if it is 100%
                const processingDataNotification = notification.addNotification({
                    title: 'Processing Changes',
                    message: (
                        <div className="progress">
                            <div
                                className="progress-bar progress-bar-striped progress-bar-animated"
                                style={{
                                    width: `${percent}%`,
                                    backgroundColor: '#FFE600',
                                    color: '#050505',
                                }}
                            >
                                {percent}%
                            </div>
                        </div>
                    ),
                    level: 'info',
                    position: 'br',
                    dismissible: 'none',
                    autoDismiss: 0,
                });
                this.processingDataNotification = processingDataNotification;
            }

            if (autoDismiss) {
                this.processingDataNotificationTimeout = setTimeout(() => {
                    this.hideNotificationAndResetData();
                }, 3000);
            }
        }
    }

    render() {
        let userId = this.props.currentUser;

        return (
            <IntlProvider locale="en">
                {this.state.message ? (
                    <p>{this.state.message}</p>
                ) : (
                    <React.Fragment>
                        {this.props.idToken ? (
                            <AuthenticatedTemplate>
                                <NotificationSystem
                                    ref={this.notificationSystem}
                                    style={this.notificationStyle}
                                />
                                <div className="container-fluid no-padding">
                                    <MaintainLoginModal
                                        showMaintainTemplateModal={
                                            this.state.showMaintainLoginModal
                                        }
                                        handleYes={this.handleUserModalLogin}
                                        handleNo={this.handleUserLogOut}
                                        toggleShowMaintainTemplateModal={
                                            this.toggleShowMaintainTemplateModal
                                        }
                                    />
                                    <NavBar>{this.props.children}</NavBar>
                                    <Main userId={userId}>{this.props.children}</Main>
                                </div>
                            </AuthenticatedTemplate>
                        ) : (
                            <p>Authentication in progress...</p>
                        )}
                        <UnauthenticatedTemplate>
                            Authentication in progress...
                        </UnauthenticatedTemplate>
                    </React.Fragment>
                )}
            </IntlProvider>
        );
    }
}

App.propTypes = {
    children: PropTypes.element,
    authActions: PropTypes.object,
    bulkProcessManagementActions: PropTypes.object,
    bulkRollForwardActions: PropTypes.object,
    authenticationActions: PropTypes.object,
    authenticationScope: PropTypes.string,
    currentUserAuthorizations: PropTypes.array,
    client: PropTypes.object,
    idToken: PropTypes.string,
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    return {
        children: ownProps.children,
        authenticationScope: state.authenticationScope,
        currentUserAuthorizations: state.currentUserAuthorizations,
        client: state.client,
        currentUser: state.authentication.currentUser,
        idToken: state.authentication.idToken,
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        authActions: bindActionCreators(authActions, dispatch),
        bulkProcessManagementActions: bindActionCreators(bulkProcessManagementActions, dispatch),
        bulkRollForwardActions: bindActionCreators(bulkRollForwardActions, dispatch),
        authenticationActions: bindActionCreators(authenticationActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withMsal(App));
