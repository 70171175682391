/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-multi-comp */
/* eslint-disable no-unused-vars */

import PropTypes, { number } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import * as formHelpers from '../../scripts/formHelpers';
import * as formValidators from '../../scripts/formValidators';
import { connect } from 'react-redux';
import {
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
  MotifButton,
  MotifLabel,
  MotifFileUploader,
  MotifFileUploaderItem,
  MotifFormField,
  MotifIcon,
  MotifErrorMessage,
  MotifIconButton,
  MotifModalFooter
} from '@ey-xd/motif-react';

import { addAttribute } from '../../common/Attribute';
import { changeAttribute } from '../../common/FileUploadAttribute'
import PicklistItemSection from './PicklistItemSection';
import { actionIcDescription24px, actionIcDelete24px, fileIcFileDownload24px } from '@ey-xd/motif-react/assets/icons';
import * as Constants from '../../constants/other';
import DeleteModal from "../../components/shared/DeleteModal";
import IntentToEditLockExtensionModal from './IntentToEditLockExtensionModal';

import * as customQuestionActions from '../../actions/customQuestionActions';

const AddEditCustomQuestionModal = ({
  showAddEditCustomQuestionModal,
  handleSubmit,
  toggleShowAddEditCustomQuestionModal,
  toggleShowAddEditCustomQuestionModalAndReleaseIntentLock,
  formPurpose,
  isChildQuestion,
  picklistItemText,
  currentPicklistItems,
  showChildQuestionAlways,
  questionTypeID,
  parentQuestionTypeID,
  parentQuestionPicklistItems,
  valid,
  dirty,
  change,
  questionTypes,
  handleChangePicklistItem,
  isQuestionFormDirty,
  initialValues,
  sectionID,
  sectionName,
  isLocked,
  fileAttachmentList,
  removeFile,
  handleDrop,
  existingAttachmentData,
  handleDeleteExistingFile,
  handleDownloadAttachmentClick,
  startIntentLockTimer,
  timeoutIdProp,
  intentLockExpirationTime,
  customQuestionActions,
  currentQuestionIdinWorks,
  checklistTemplateID,
  clientID,
}) => {
  let timeoutId = 0;

  const intentExtensionModalSettingsDefaultObj = {
    showModal: false,
    timerDuration: 0
  };
  const [intentExtensionModalSettings, setIntentExtensionModalSettings] = useState(intentExtensionModalSettingsDefaultObj);
  const [reinitializeTimer, setReinitializeTimer] = useState(false);

  if (startIntentLockTimer && intentLockExpirationTime) {
    if (!intentExtensionModalSettings.showModal) {
      if (!timeoutIdProp || timeoutIdProp === 0) {
        const currentLocalDateTime = new Date();
        const expirationDateTime = new Date(intentLockExpirationTime + 'Z');
        const timeLeftForExpirationinMilliSeconds = Math.abs(expirationDateTime.getTime() - currentLocalDateTime.getTime());
        const timeForTimeout = timeLeftForExpirationinMilliSeconds - (2.5 * 60 * 1000); // Subtracting the time left with 2.5 minutes to perform an API call plus display the timer warning modal
        timeoutId = setTimeout(() => {
          getLockStatusForQuestion(currentQuestionIdinWorks, checklistTemplateID, clientID);
        }, timeForTimeout);
        change('timeoutId', timeoutId);
      }
    }
  }

  useEffect(() => {
    if (reinitializeTimer) {
      // Prepare request to acquire extension for intent lock
      let request = {
        questionID: currentQuestionIdinWorks,
        checklistTemplateID: checklistTemplateID,
        clientID: clientID
      }

      // Acquire Intent lock extension
      customQuestionActions.acquireIntentLockForCustomQuestion(request)
        .then((response) => {
          if (response && response.isIntentLockUpdateSuccessful) {
            const currentLocalDateTime = new Date();
            const expirationDateTime = new Date(response.lockExpirationDateTime + 'Z');
            const timeLeftForExpirationinMilliSeconds = Math.abs(expirationDateTime.getTime() - currentLocalDateTime.getTime());
            const timeForTimeout = timeLeftForExpirationinMilliSeconds - (2.5 * 60 * 1000); // Subtracting the time left with 2.5 minutes to perform an API call plus display the timer warning modal
            timeoutId = setTimeout(() => {
              getLockStatusForQuestion(currentQuestionIdinWorks, checklistTemplateID, clientID);
            }, timeForTimeout);
            setReinitializeTimer(false);
            change('timeoutId', timeoutId);
          }
        })
        .catch(() => {
          clearLocalStateAndToggleQuestionModal();
        });
    }
  }, [reinitializeTimer]);

  const getLockStatusForQuestion = (questionID, checklistTemplateID, clientID) => {
    customQuestionActions.getLockStatusForQuestion(questionID, checklistTemplateID, clientID)
      .then((response) => {
        if (response && response.isQuestionAvailableForEdit && response.isIntentExtensionAvailable) {
          const settingsObj = {
            showModal: true,
            timerDuration: 120
          }
          setIntentExtensionModalSettings(settingsObj);
        }
      })
      .catch(() => {
        clearLocalStateAndToggleQuestionModal();
      });
  }

  useEffect(() => {
    //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
    addAttribute("button[role = 'combobox']", 'type', 'button');
    changeAttribute("button[class = 'dropzone-container']", 'type', 'button');
  });

  let formAction =
    formPurpose == 'CREATE' ? 'Add' : formPurpose == 'UPDATE' ? 'Edit' : '';
  formAction = formAction + (isChildQuestion ? ' Child' : '');
  const [showPicklistItemSection, setShowPicklistItemSection] = useState(false);
  const [val, setVal] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedQuestionAttachmentIDToDelete, setSelectedQuestionAttachmentIDToDelete] = useState(null);
  const [selectedUploadAttachmentToDelete, setSelectedUploadAttachmentToDelete] = useState(null);
  const isParentPicklistQuestion =
    parentQuestionTypeID == 3 || parentQuestionTypeID == 4;

  useEffect(() => {
    // Set the showPicklistItemSection on first load
    handleQuestionTypeChange(questionTypeID);
  }, [questionTypeID]);

  // Set the "showChildQuestionAlways" as yes only when parent question is not picklist and form is in create mode
  if (formPurpose == 'CREATE' && !isParentPicklistQuestion) {
    change('showChildQuestionAlways', 'yes');
  }

  function handleQuestionTypeChange(questionTypeID) {
    if (questionTypeID == 3 || questionTypeID == 4) {
      setShowPicklistItemSection(true);
    } else {
      setShowPicklistItemSection(false);
    }
  }

  function addPicklistItem() {
    if (currentPicklistItems) {
      handleChangePicklistItem([
        ...currentPicklistItems,
        { picklistItemName: picklistItemText.trim() },
      ]);
    } else {
      handleChangePicklistItem([{ picklistItemName: picklistItemText }]);
    }
    change('picklistItemText', '');
  }

  function removePicklistItem(key, event) {
    handleChangePicklistItem(
      currentPicklistItems.filter((item) => item.picklistItemName != key)
    );
  }

  const clearLocalStateAndToggleQuestionModal = () => {
    const settingsObj = {
      showModal: false,
      timerDuration: 0
    }
    setIntentExtensionModalSettings(settingsObj);
    clearTimeout(timeoutIdProp);
    toggleShowAddEditCustomQuestionModal();
  }

  const clearLocalStateAndToggleQuestionModalAndReleaseIntentLock = () => {
    const settingsObj = {
      showModal: false,
      timerDuration: 0
    }
    setIntentExtensionModalSettings(settingsObj);
    clearTimeout(timeoutIdProp);
    toggleShowAddEditCustomQuestionModalAndReleaseIntentLock();
  }

  function onDownloadClick(e, fileName, attachmentGuid) {
    e.preventDefault();
    handleDownloadAttachmentClick(attachmentGuid, fileName);
  }

  // Handle Delete button on delete attachment modal
  function handleConfirmDelete() {
    selectedQuestionAttachmentIDToDelete && handleDeleteExistingFile(selectedQuestionAttachmentIDToDelete);
    selectedUploadAttachmentToDelete && removeFile(selectedUploadAttachmentToDelete);
    setShowDeleteModal(false);
    setSelectedQuestionAttachmentIDToDelete(null);
    setSelectedUploadAttachmentToDelete(null);
  }

  // Handle Cancel button on delete attachment modal
  function handleCancelDelete() {
    setSelectedQuestionAttachmentIDToDelete(null);
    setSelectedUploadAttachmentToDelete(null);
    setShowDeleteModal(false);
  }

  // Handle Delete icon click on existing attachments list
  function handleDeleteExistingAttachment(questionAttachmentID) {
    setShowDeleteModal(true);
    setSelectedQuestionAttachmentIDToDelete(questionAttachmentID);
  }

  // Handle Delete icon click on newly uploaded attachments list
  function handleRemoveUploadedAttachment(attachment) {
    setShowDeleteModal(true);
    setSelectedUploadAttachmentToDelete(attachment);
  }

  function getFileErrorMessage(message) {
    if (message && message.toLowerCase().includes("file is larger than")) {
      message = 'The file size has exceeded max limit of ' + Constants.MAX_UPLOAD_FILE_SIZE_IN_MB + 'MB.'
    }
    return message;
  }

  const handleAcceptIntentExtension = () => {
    const settingsObj = {
      showModal: false,
      timerDuration: 0
    }
    setIntentExtensionModalSettings(settingsObj);
    clearTimeout(timeoutIdProp);
    setReinitializeTimer(true);
  }

  const HeaderWrapper = ({ children }) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
      }}
    >
      {children}
    </div>
  );

  const closeButtonProps = {
    title: 'Close Add Question Modal',
    ariaLabel: 'Close Add Question Modal',
    dataTestid: 'close',
  };

  const updateVal = (val) => {
    setVal(val);
  };

  /**
   * Renders each item in the queue as a MotifChip
   *
   * @param {*} queue - current list of files to be uploaded
   * @param {*} setQueue - function that updates the queue
   */
  const renderQueue = (queue) =>
    queue.map(file => (
      <div key={file.path}>
        <MotifFileUploaderItem className="addEditQuestionAttachmentList"
          fileIcon={<MotifIcon src={actionIcDescription24px} />}
          showProgress={false}
          percentUploaded={100}
          fileName={file.name}
          fileSize={file.size}
          title={file.name}
          error={file.error && true}
          icons={{ close: <MotifIcon src={actionIcDelete24px} /> }}
          removable={false}
          customButton={
            <MotifIconButton aria-label="delete attachment" type="button"
              onClick={(e) => handleRemoveUploadedAttachment(file)} data-testId="delete">
              <MotifIcon title='Delete this attachment' src={actionIcDelete24px} />
            </MotifIconButton>
          }
        />
        {file.error && <MotifErrorMessage>{getFileErrorMessage(file.error)}</MotifErrorMessage>}
      </div>
    ));

  return (
    <React.Fragment>
      <MotifModal
        variant="alt"
        show={showAddEditCustomQuestionModal}
        size="xl"
        style={{ minWidth: '50vw' }}
        onClose={clearLocalStateAndToggleQuestionModalAndReleaseIntentLock}
        data-testid="AddEditCustomQuestionModal"
      >
        <MotifModalHeader
          style={{ display: '-webkit-box' }}
          closeButtonProps={closeButtonProps}
        >
          <HeaderWrapper>
            <span className="ml-2">{formAction} Question</span>
          </HeaderWrapper>
        </MotifModalHeader>
        <MotifModalBody>
          <form onSubmit={handleSubmit} id="addEditQuestion">
            <Field
              name="sectionID"
              type="hidden"
              component={formHelpers.renderField}
            />
            <Field
              name="questionID"
              type="hidden"
              component={formHelpers.renderField}
            />
            <Field
              name="isLocked"
              type="hidden"
              component={formHelpers.renderField}
            />
            <Field
              name="timeoutId"
              type="hidden"
              component={formHelpers.renderField}
            />
            <div className="row">
              {sectionID !== 0 && sectionName !== '' && (
                <div className="field col-md-12">
                  <Field
                    name="sectionName"
                    type="textarea"
                    component={formHelpers.renderField}
                    label="Section Name"
                    maxLength={255}
                    disabled={true}
                    data-testid="txtSectionName"
                    // inputClassName="disabled-textarea"
                    inputClassName="pt-1 pb-0"
                  />
                </div>
              )}
            </div>
            <div className="row">
              <div className="field col-md-12">
                <Field
                  name="questionText"
                  type="textarea"
                  component={formHelpers.renderField}
                  label="Question Text"
                  isRequiredField={true}
                  maxLength={4000}
                  validate={[
                    formValidators.required,
                    formValidators.maxLength4000,
                  ]}
                  data-testid="txtQuestionText"
                  onChange={(e) => updateVal(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="field col-md-12">
                <Field
                  name="questionHelpText"
                  type="textarea"
                  component={formHelpers.renderField}
                  label="Help Text"
                  maxLength={12000}
                  validate={[formValidators.maxLength12000]}
                  data-testid="txtHelpText"
                  onChange={(e) => updateVal(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="field col-md-12">
                <Field
                  name="questionTag"
                  type="text"
                  component={formHelpers.renderField}
                  label="Question Tag"
                  maxLength={255}
                  validate={[formValidators.maxLength255]}
                  data-testid="txtQuestionTag"
                  onChange={(e) => updateVal(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="field col-md-6">
                <Field
                  name="questionTypeID"
                  label="Type (Required)"
                  placeholder="Select a Type"
                  component={formHelpers.SingleSelectComponent}
                  options={questionTypes}
                  optionValue={(o) => o.questionTypeID}
                  optionText={(o) => o.questionTypeDescription}
                  validate={[formValidators.requiredSelect]}
                  onChange={handleQuestionTypeChange}
                  testingData={{
                    label: 'lblQuestionType',
                    div: 'divQuestionType',
                  }}
                  disabled={
                    formPurpose === 'UPDATE' &&
                    !isChildQuestion &&
                    (questionTypeID === 3 || questionTypeID === 4)
                  }
                />
              </div>
            </div>
            {showPicklistItemSection && (
              <PicklistItemSection
                picklistItems={currentPicklistItems}
                addPicklistItem={addPicklistItem}
                removePicklistItem={removePicklistItem}
                shouldDisableAddPicklistItem={
                  !(picklistItemText && picklistItemText.length > 0) ||
                  (currentPicklistItems &&
                    currentPicklistItems.some(
                      (item) => item.picklistItemName == picklistItemText.trim()
                    ))
                }
              />
            )}
            <hr />
            <MotifLabel className="font-weight-bold">
              Attachments
            </MotifLabel>
            {existingAttachmentData && existingAttachmentData.map((attachmentData) => {
              return (
                (
                  <>
                    {attachmentData.isDeleted == false &&
                      <div className="row">
                        <div className='col-md-10 checklistActionMsg attachmentsListSpace attachmentName'>{attachmentData.attachmentName}</div>
                        <div className='col-md-2' style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                          <MotifIconButton aria-label="delete attachment" type="button"
                            onClick={(e) => handleDeleteExistingAttachment(attachmentData.questionAttachmentID)} data-testId="delete">
                            <MotifIcon title='Delete this attachment' src={actionIcDelete24px}
                            />
                          </MotifIconButton>
                          <MotifIconButton aria-label="Download Attachment" type="button"
                            onClick={(e) =>
                              onDownloadClick(e, attachmentData.attachmentName, attachmentData.attachmentGuid)}>
                            <MotifIcon src={fileIcFileDownload24px} title="Download Attachment" />

                          </MotifIconButton>
                        </div>
                      </div>
                    }
                  </>

                )

              );
            })}

            <MotifFormField className="mt-4">
              <MotifFileUploader
                onDrop={event => handleDrop(event)}
                label="Drop files here or click to upload"
                accept=".doc,.docx,.pdf,.xls,.xlsx,.xml,.xlsm,.xlsb,.xltx,.xlt,.xlam,.xla,.xps,.csv"
                maxSize={Constants.MAX_UPLOAD_FILE_SIZE_IN_BYTES}
                data-test-id="attachmentFileUpload"
                tabIndex="-1"
              />
              {renderQueue(fileAttachmentList)}
            </MotifFormField>

            {isChildQuestion && (
              <div className="mb-3">
                <hr />
                <div className="row">
                  <div className="field col-md-12 mt-2">
                    {isParentPicklistQuestion ? (
                      <div className="d-flex">
                        <MotifLabel className="col-md-6 pl-0">
                          Do you want to always display Child Question?
                        </MotifLabel>
                        <div className="col-md-2 pl-0">
                          <Field
                            name="showChildQuestionAlways"
                            component={formHelpers.renderField}
                            type="radio"
                            value="yes"
                            label="Yes"
                            date-testid="yes"
                          />
                        </div>
                        <div className="col-md-2 pl-0">
                          <Field
                            name="showChildQuestionAlways"
                            component={formHelpers.renderField}
                            type="radio"
                            value="no"
                            label="No"
                            data-testid="no"
                          />
                        </div>
                      </div>
                    ) : (
                      <MotifLabel className="mb-4">
                        Child Question will be always displayed.
                      </MotifLabel>
                    )}
                  </div>
                </div>
                {showChildQuestionAlways == 'no' && (
                  <div className="row">
                    <div className="field col-md-8">
                      <Field
                        name="parentPicklistItems"
                        label="Display Child Question when the answer to the Parent Question is"
                        placeholder="Select a Picklist Item"
                        component={formHelpers.MultipleSelect}
                        options={parentQuestionPicklistItems}
                        optionValue={(o) => o.picklistItemName}
                        optionText={(o) => o.picklistItemName}
                        validate={[formValidators.requiredMultiSelect]}
                        testingData={{
                          label: 'lblParentPicklistItem',
                          div: 'divParentPicklistItem',
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
            <div
              className="row"
              style={
                !showPicklistItemSection && !isChildQuestion
                  ? { marginTop: '8rem' }
                  : { marginTop: '0rem' }
              }
            >
              {(isLocked ||
                ((questionTypeID === 3 || questionTypeID === 4) &&
                  !isChildQuestion)) && ( // Section to display warning messages
                  <div className="row">
                    <div className="col-md-12 ml-4">
                      {isLocked &&
                        !(
                          formPurpose === 'UPDATE' &&
                          !isChildQuestion &&
                          (questionTypeID === 3 || questionTypeID === 4)
                        ) && ( // Warning message for editing a standard parent question
                          <p style={{ width: '100%' }}>
                            <strong>Note:</strong>{' '}
                            <ul className="add-edit-question-modal-note-warning-message">
                              <li>
                                This is a standard question. Editing this question
                                will create a copy of the question and saved as a
                                non-standard question.
                              </li>
                            </ul>
                          </p>
                        )}
                      {isLocked &&
                        formPurpose === 'UPDATE' &&
                        !isChildQuestion &&
                        (questionTypeID === 3 || questionTypeID === 4) && ( // Warning message for editing a standard parent picklist question
                          <p style={{ width: '100%' }}>
                            <strong>Note:</strong>{' '}
                            <ul className="add-edit-question-modal-note-warning-message">
                              <li>
                                This is a standard question. Editing this question
                                will create a copy of the question and saved as a
                                non-standard question.
                              </li>
                              <li>
                               <div>Removing a picklist items may have an impact on the
                                display rule(s) of the child question(s) (if any).</div> 
                                <div> Please update the display rule of individual child
                                questions as required.</div> 
                              </li>
                            </ul>
                          </p>
                        )}
                      {!isLocked &&
                        formPurpose === 'UPDATE' &&
                        !isChildQuestion &&
                        (questionTypeID === 3 || questionTypeID === 4) && ( // Warning message for editing a custom parent picklist question
                          <p style={{ width: '100%' }}>
                            <strong>Note:</strong>{' '}
                            <ul className="add-edit-question-modal-note-warning-message">
                              <li>
                                <div>
                                Removing picklist item(s) may have an impact on the
                                display rule of the child question(s) (if any).
                                </div>
                                <div>
                                Please update the display rule of individual child
                                question as required.</div>
                              </li>
                            </ul>
                          </p>
                        )}
                    </div>
                  </div>
                )}
            
            </div>
          </form>
        </MotifModalBody>
        <MotifModalFooter>
          <MotifButton
            variant="primary"
            form="addEditQuestion"
            disabled={
              !valid ||
              (formPurpose == 'UPDATE' &&
                !dirty &&
                !isQuestionFormDirty) ||
              (showPicklistItemSection &&
                (!currentPicklistItems ||
                  currentPicklistItems.length <= 0)) ||
              (isParentPicklistQuestion &&
                (!showChildQuestionAlways ||
                  showChildQuestionAlways === ''))
            }
            type="submit"
            data-testid="save"
          >
            Save
          </MotifButton>
          <MotifButton
            variant="secondary"
            type="button"
            onClick={clearLocalStateAndToggleQuestionModalAndReleaseIntentLock}
            data-testid="cancel"
          >
            Cancel
          </MotifButton></MotifModalFooter>
      </MotifModal>

      <DeleteModal
        showDeleteModal={showDeleteModal}
        confirmationMessage="Are you sure you want to delete this file on the question?"
        size="md"
        handleDelete={handleConfirmDelete}
        toggleshowDeleteModal={handleCancelDelete}
        closeTitle="close system admin delete modal"
        headerTitle="Delete File on the Question">
      </DeleteModal>

      {intentExtensionModalSettings.showModal && (
        <IntentToEditLockExtensionModal
          showIntentToEditLockExtensionModal={intentExtensionModalSettings.showModal && showAddEditCustomQuestionModal}
          handleCancelIntentExtent={clearLocalStateAndToggleQuestionModal}
          handleAcceptIntentExtension={handleAcceptIntentExtension}
          timerDuration={intentExtensionModalSettings.timerDuration}
        />
      )}
    </React.Fragment>
  );
};

AddEditCustomQuestionModal.propTypes = {
  showAddEditCustomQuestionModal: PropTypes.bool,
  handleSubmit: PropTypes.func,
  toggleShowAddEditCustomQuestionModal: PropTypes.func,
  toggleShowAddEditCustomQuestionModalAndReleaseIntentLock: PropTypes.func,
  formPurpose: PropTypes.string,
  isChildQuestion: PropTypes.bool,
  picklistItemText: PropTypes.string,
  showChildQuestionAlways: PropTypes.string,
  questionTypeID: PropTypes.number,
  valid: PropTypes.bool,
  dirty: PropTypes.bool,
  change: PropTypes.func,
  currentPicklistItems: PropTypes.array,
  parentQuestionTypeID: PropTypes.number,
  parentQuestionPicklistItems: PropTypes.array,
  questionTypes: PropTypes.array,
  handleChangePicklistItem: PropTypes.func,
  isQuestionFormDirty: PropTypes.bool,
  initialValues: PropTypes.object,
  sectionID: PropTypes.number,
  sectionName: PropTypes.string,
  fileAttachmentList: PropTypes.array,
  removeFile: PropTypes.func,
  handleDrop: PropTypes.func,
  existingAttachmentData: PropTypes.array,
  handleDeleteExistingFile: PropTypes.func,
  handleDownloadAttachmentClick: PropTypes.func,
  startIntentLockTimer: PropTypes.bool,
  timeoutIdProp: PropTypes.number,
  intentLockExpirationTime: PropTypes.any,
  currentQuestionIdinWorks: number,
  checklistTemplateID: number,
  clientID: number,
};

let AddEditCustomQuestionForm = reduxForm({
  form: 'customQuestionForm',
  enableReinitialize: true,
})(AddEditCustomQuestionModal);
const selector = formValueSelector('customQuestionForm');

const mapStateToProps = (state, props) => {
  return {
    picklistItemText: selector(state, 'picklistItemText'),
    showChildQuestionAlways: selector(state, 'showChildQuestionAlways'),
    questionTypeID: selector(state, 'questionTypeID'),
    sectionID: selector(state, 'sectionID'),
    sectionName: selector(state, 'sectionName'),
    isLocked: selector(state, 'isLocked'),
    timeoutIdProp: selector(state, 'timeoutId'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    change: bindActionCreators(change, dispatch),
    customQuestionActions: bindActionCreators(customQuestionActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEditCustomQuestionForm);
