import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import RelatedPropertiesRow from './RelatedPropertiesRow';

const RelatedProperties = ({ handleAddClick, handleEditClick, handleDeleteClick, properties }) => {
    return (
        <section className="related-reits">
            <div className="row">
                <div className="col-md-12">
                    <h2>Related Properties <Button content="Add Property" icon="add" labelPosition="right" onClick={handleAddClick} /></h2>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <table className="reits">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Address</th>
                                <th>Type</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(properties) && properties.map(p => <RelatedPropertiesRow key={p.id} property={p} handleEditClick={handleEditClick} handleDeleteClick={handleDeleteClick} />)}
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    );
};

RelatedProperties.propTypes = {
    handleAddClick: PropTypes.func.isRequired,
    handleEditClick: PropTypes.func.isRequired,
    handleDeleteClick: PropTypes.func.isRequired,
    properties: PropTypes.array.isRequired
};

export default RelatedProperties;