import React from 'react';
import { MotifTextLink, MotifIcon, MotifButton } from '@ey-xd/motif-react';
import { Link } from 'react-router';
import { actionIcOpenInNew24px } from '@ey-xd/motif-react/assets/icons';

export default (props) => {
  const isStandardTemplate = props.data ? props.data.isStandardTemplate : false;
  const templateId =
    props.data && props.data.checklistTemplateID
      ? props.data.checklistTemplateID
      : null;
  const isEditMode = props.data ? props.data.isEditMode : false;

  const reitId = 1;
  const reportPeriodID = -1;
  const targetUrl = `/client/${props.clientId}/customPsqTemplate/template/${templateId}/preview`;
  //TODO - This will be implemented after DB migration
  return (
    <div>
      {
        <div>
          <MotifTextLink
            variant="alt"
            href="#"
            rel="noopener noreferrer"
            onClick={() => props.handlePreview(templateId, targetUrl)}
          >
            {' '}
            <MotifIcon src={actionIcOpenInNew24px} />
          </MotifTextLink>
        </div>
      }
    </div>
  );
};
