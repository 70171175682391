import React from "react";
import PropTypes from "prop-types";

/**
 * The SelectedReportItems component.
 */
export default class SelectedReportItems extends React.Component {
    /**
     * Get the display name of the report item by type.
     * @param {any} key The report item type.
     * @returns {string} The display name of the report item.
     */
    getItemName(key) {
        const item = this.props.items.find(x => x.type === key);
        return item ? item.displayName : key;
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        return (
            <div>
                {Array.isArray(this.props.selectedItems) &&
                    this.props.selectedItems.map(
                        item => <div key={item} className="selected-report-item">{this.getItemName(item)}</div>)}
            </div>
        );
    }
}

SelectedReportItems.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        displayName: PropTypes.string,
        type: PropTypes.string
    })),
    selectedItems: PropTypes.arrayOf(PropTypes.string)
};