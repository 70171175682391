import React from 'react';
import PropTypes from 'prop-types';
import ChecklistAttachmentFileListItem from './ChecklistAttachmentFileListItem';

const ChecklistAttachmentFileList = ({ files, onDownload, onDelete, canDelete, emptyMessage }) => {
    return (
        <table>
            <thead>
                <tr>
                    <th className="w-50">File Name</th>
                    <th className="w-10">Checklist Type</th>
                    <th className="w-20">Uploaded By</th>
                    <th className="w-10">Date Uploaded</th>
                    <th className="w-10">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                {Array.isArray(files) && files.map(item => <ChecklistAttachmentFileListItem key={item.fileName} item={item} onDownload={onDownload} onDelete={onDelete} canDelete={canDelete} />)}
                {files && files.length < 1 && 
                    <tr>
                        <td>{emptyMessage}</td>
                        <td />
                        <td />
                        <td />
                        <td />
                    </tr>}
            </tbody>
        </table>
    );
};

ChecklistAttachmentFileList.propTypes = {
    files: PropTypes.array,
    onDownload: PropTypes.func,
    onDelete: PropTypes.func,
    canDelete: PropTypes.bool,
    emptyMessage: PropTypes.string
};

export default ChecklistAttachmentFileList;