import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Form } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import { MotifButton, MotifIcon, MotifIconButton, MotifSelect, MotifOption, MotifLabel, MotifFormField, MotifDropdown, MotifDropdownItem, MotifTooltip, MotifToast } from '@ey-xd/motif-react';
import { actionIcInfo24px, navigationIcArrowDropDown24px } from '@ey-xd/motif-react/assets/icons';
import { addAttribute } from "../../common/Attribute";

const ChecklistHeader = ({
    formSyncErrors,
    isSystemOrEngagementAdmin,
    isEngagementUser,
    selectedPsqArqc,
    clientIsAudit,
    handleChangePsqArqc,
    resetGridColumnState,
    toggleShowAddChecklistUserModal,
    toggleShowDeleteChecklistUserModal,
    toggleShowDeleteChecklistModal,
    toggleShowResendChecklistModal,
    toggleShowSignoffChecklistModal,
    toggleShowReopenForEYReviewChecklistModal,
    toggleShowApproveClientReopenRequestModal,
    toggleShowDenyClientReopenRequestModal,
    handleExportChecklistSummary,
    gridCurrentState,
    gridFilteredRowCurrentState,
    selectedRecipients,
    toggleShowDownloadChecklistsModal,
    handleDownloadChecklist,
    showCreateModal,
    canEdit=true
}) => {

    const [actionOptionsOpen, setActionOptionsOpen] = useState(false);
    const [hideInfoMessage, setHideInfoMessage] = useState(false);
    useEffect(() => {

        //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
        addAttribute("button[role = 'combobox']", "type", "button");
    });

    const hasFormSyncErrors = Object.keys(formSyncErrors).length !== 0;

    const quarterStatusList = ['reporting closed', 'complete'];

    let resendChecklistActionStatus = true;
    let resendChecklistTooltipMessage = '';
    // Check if there is any recipient selected for resend
    if (!selectedRecipients || !selectedRecipients.length > 0) {
        resendChecklistActionStatus = false;
        resendChecklistTooltipMessage = "You have not selected any recipients in order to perform this action.";
    }
    // Check if there is any checklist (for selected recipients) falls into closed report period
    else if (gridCurrentState && selectedRecipients && selectedRecipients.length > 0) {
        let selectedRecords = gridCurrentState.filter(x => selectedRecipients.some(sr => sr.checklistID === x.checklistID && sr.checklistType === x.checklistType));
        if (selectedRecords && selectedRecords.some(record => quarterStatusList.includes(record.quarterStatus.toLowerCase()))) {
            resendChecklistActionStatus = false;
            resendChecklistTooltipMessage = "You have selected a recipient within a Closed period.";
        }
    }

    // If checklist status is either in completed or pendingeyreview status then checklist should not be allowed to add/delete recipients.
    const checklistStatusList = ['completed', 'pendingeyreview'];

    let addUserActionStatus = true;
    let addUserValidationMessage = [];
    let isChecklistSelected = gridCurrentState && gridCurrentState.some(record => record.checklistSelection === true);
    if (isChecklistSelected) {
        //Get the selected checkist records
        let selectedRecords = gridCurrentState.filter(x => x.checklistSelection === true);

        //If any Report period of selected checklist records having Reporting Closed/Complete status then disable the Add User action
        if (selectedRecords.some(record => quarterStatusList.includes(record.quarterStatus.toLowerCase()))) {
            addUserActionStatus = false;
            addUserValidationMessage.push('Period of the selected checklist(s) is either Closed For Reporting or Completed.');
        }
    }
    else {
        addUserActionStatus = false;
        addUserValidationMessage.push('You have not selected any checklist(s) in order to perform this action.');
    }

    let deleteUserActionStatus = true;
    let deleteUserValidationMessage = [];
    if (!selectedRecipients || selectedRecipients.length === 0) {
        deleteUserActionStatus = false;
        deleteUserValidationMessage.push('You have not selected any user(s) in order to perform this action.');
    }
    else {
        //If any seleced checklist record having Completed/Pending EY Review status, then disable the Delete User action
        if (selectedRecipients.some(record => checklistStatusList.includes(record.checklistState.toLowerCase()))) {
            deleteUserActionStatus = false;
            deleteUserValidationMessage.push('Selected checklist(s) is either Pending for EY Review or Completed.');
        }
        //If any Report period of selected checklist records having Reporting Closed/Complete status then disable Delete User action
        if (selectedRecipients.some(record => quarterStatusList.includes(record.quarterStatus.toLowerCase()))) {
            deleteUserActionStatus = false;
            deleteUserValidationMessage.push('Period of the selected checklist(s) is either Closed For Reporting or Completed.');
        }
    }

    let deleteChecklistsActionStatus = true;
    let deleteChecklistValidationMessage = [];
    if (isChecklistSelected) {
        let selectedRecords = gridCurrentState.filter(x => x.checklistSelection === true);

        if (selectedRecords && selectedRecords.some(record => quarterStatusList.includes(record.quarterStatus.toLowerCase()))) {
            deleteChecklistsActionStatus = false;
            deleteChecklistValidationMessage.push('Period of the selected checklist(s) is either Closed For Reporting or Completed.');
        }
    } else {
        deleteChecklistsActionStatus = false;
        deleteChecklistValidationMessage.push('You have not selected any checklist(s) in order to perform this action.');
    }

    // If checklist status is either is with status 'created','sent', 'inprogress', 'pendingeyreview' status then only enable Sign-Off Action status.
    let signoffRequiredChecklistStatusList = ['created', 'sent', 'inprogress', 'pendingeyreview'];

    let signoffChecklistActionStatus = true;
    let reopenforEYReviewChecklistActionStatus = true;
    let signoffChecklistValidationMessage = [];
    let reopenforEYReviewChecklistValidationMessage = [];

    if (isChecklistSelected) {
        //Get the selected checkist records
        let selectedRecords = gridCurrentState.filter(x => x.checklistSelection === true);

        //  If any of selected checklist is not completed then disable the Reopen for EY Review Checklist Action  
        if (selectedRecords.some(record => record.checklistState.toLowerCase() !== 'completed')) {
            reopenforEYReviewChecklistActionStatus = false;
            reopenforEYReviewChecklistValidationMessage.push('Selected checklist(s) is not Signed Off.');
        }

        //If all checklists are with these statuses 'created','sent', 'inprogress', 'pendingeyreview' then only Enable the Sign-Off checklist Action
        if (selectedRecords.some(record => !signoffRequiredChecklistStatusList.includes(record.checklistState.toLowerCase()))) {
            signoffChecklistActionStatus = false;
            signoffChecklistValidationMessage.push('Selected checklist(s) is Signed Off.');
        }

        //If any Report period of selected checklist records having Reporting Closed/Complete status then disable both the actions Sign-off/Reopen for EY Review Checklist action
        if (selectedRecords.some(record => quarterStatusList.includes(record.quarterStatus.toLowerCase()))) {
            signoffChecklistActionStatus = false;
            signoffChecklistValidationMessage.push('Period of the selected checklist(s) is either Closed For Reporting or Completed.');

            reopenforEYReviewChecklistActionStatus = false;
            reopenforEYReviewChecklistValidationMessage.push('Period of the selected checklist(s) is either Closed For Reporting or Completed.');
        }
    }
    else {
        signoffChecklistActionStatus = false;
        reopenforEYReviewChecklistActionStatus = false;

        signoffChecklistValidationMessage.push('You have not selected any checklist(s) in order to perform this action.');
        reopenforEYReviewChecklistValidationMessage.push('You have not selected any checklist(s) in order to perform this action.');
    }

    let downloadChecklistActionStatus = true;
    let downloadChecklistValidationMessage = [];
    if (!isChecklistSelected) {
        downloadChecklistActionStatus = false;
        downloadChecklistValidationMessage.push('You have not selected any checklist(s) in order to perform this action.');
    }

    let approveDenyClientReopenRequestActionStatus = true;
    let approveDenyClientReopenRequestValidationMessage = [];
    if (isChecklistSelected) {
        let selectedRecords = gridCurrentState.filter(x => x.checklistSelection === true);
        //  If there are no pending open requests for selected checklists then disable the Approve/Deny Reopen Request Action
        if (selectedRecords.some(record => !record.reopenRequestStatusDescription || record.reopenRequestStatusDescription.toLowerCase() !== 'pending')) {
            approveDenyClientReopenRequestActionStatus = false;
            approveDenyClientReopenRequestValidationMessage.push('Selected checklist(s) is not in Pending Reopen Request status.');
        }

        //  If any Report period of selected checklist records having Reporting Closed/Complete status then disable both the actions Approve/Deny Client Reopen Request action
        if (selectedRecords && selectedRecords.some(record => quarterStatusList.includes(record.quarterStatus.toLowerCase()))) {
            approveDenyClientReopenRequestActionStatus = false;
            approveDenyClientReopenRequestValidationMessage.push('Period of the selected checklist(s) is either Closed For Reporting or Completed.');
        }

    } else {
        approveDenyClientReopenRequestActionStatus = false;
        approveDenyClientReopenRequestValidationMessage.push('You have not selected any checklist(s) in order to perform this action.');
    }

    let reopenRequestCount = gridFilteredRowCurrentState && gridFilteredRowCurrentState.length > 0
        && gridFilteredRowCurrentState.filter(record => record.reopenRequestStatusDescription && record.reopenRequestStatusDescription.toLowerCase() === 'pending').length
        || 0;

    let exportChecklistSummaryActionStatus = true;
    let exportChecklistSummaryValidationMessage = [];
    if (!isChecklistSelected) {
        exportChecklistSummaryActionStatus = false;
        exportChecklistSummaryValidationMessage.push('You have not selected any checklist(s) in order to perform this action.');
    }

    return <div className="mt-2" >
        <form>
            <div className="row">
                <div className="col-md-10 pl-3">
                </div>
                <div className="col-md-2 mt-1 d-flex justify-content-end">
                    {(isSystemOrEngagementAdmin || isEngagementUser) &&
                        //  Show Create New button if current user is System admin, Engagement admin or Engagement user
                        <div>
                            <MotifButton className="right ml-2"
                                data-testid="btnCreate"
                                variant="primary" type="button"
                                disabled={!canEdit}
                                onClick={showCreateModal}>
                                Create New
                            </MotifButton>
                        {gridCurrentState != null && Array.isArray(gridCurrentState) && gridCurrentState.length > 0 &&
                            // Show Action dropdown only if current user is either System admin or Engagement admin)
                            <MotifDropdown
                                open={actionOptionsOpen}
                                placement="bottom-right"
                                id={'action-dropdown'}
                                className="right"
                                aria-labelledby={'manage-checklist-trigger'}
                                handleClickOutside={() => setActionOptionsOpen(false)}
                                trigger={
                                    <MotifButton
                                        type="button"
                                        variant="primary-alt"
                                        onClick={() => setActionOptionsOpen(!actionOptionsOpen)}
                                        aria-controls={'action-dropdown'}
                                        id={'action-dropdown-trigger'}
                                        aria-expanded={actionOptionsOpen}
                                        aria-label="Action dropdown"
                                        data-testid="actions"
                                    >
                                        Actions <MotifIcon src={navigationIcArrowDropDown24px} />
                                    </MotifButton>
                                }
                            >
                                {addUserActionStatus &&
                                    <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowAddChecklistUserModal(); }} type="button"
                                        disabled={!addUserActionStatus}
                                        data-testid="addUsers">
                                        Add User(s)
                                    </MotifDropdownItem>
                                    ||
                                    <MotifTooltip
                                        trigger={
                                            <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowAddChecklistUserModal(); }} type="button"
                                                disabled={!addUserActionStatus} data-testid="addUsers"
                                            >
                                                Add User(s)
                                                <MotifIconButton className="ml-2 mr-5" aria-label="Add Users" type="button">
                                                    <MotifIcon src={actionIcInfo24px} />
                                                </MotifIconButton>
                                            </MotifDropdownItem>}
                                    >
                                        <ul style={{ margin: '0px' }}>{(addUserValidationMessage.map(msg => <li className="checklistActionToolTipContent">{msg}</li>))}</ul>
                                    </MotifTooltip>
                                }

                                {deleteUserActionStatus &&
                                    <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowDeleteChecklistUserModal(); }} type="button"
                                        disabled={!deleteUserActionStatus}
                                        data-testid="deleteUsers"   >
                                        Delete User(s)
                                    </MotifDropdownItem>
                                    ||
                                    <MotifTooltip
                                        trigger={
                                            <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowDeleteChecklistUserModal(); }} type="button"
                                                disabled={!deleteUserActionStatus} data-testid="deleteUsers">
                                                Delete User(s)
                                                <MotifIconButton className="ml-2" aria-label="Delete Users" type="button">
                                                    <MotifIcon src={actionIcInfo24px} />
                                                </MotifIconButton>
                                            </MotifDropdownItem>}
                                    >
                                        <ul style={{ margin: '0px' }}>{(deleteUserValidationMessage.map(msg => <li className="checklistActionToolTipContent">{msg}</li>))}</ul>
                                    </MotifTooltip>
                                }

                                {(deleteChecklistsActionStatus && (
                                    <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowDeleteChecklistModal(); }}
                                        type="button" disabled={!gridCurrentState || !gridCurrentState.some(record => record.checklistSelection === true)}
                                        Title="Delete Checklist(s)" data-testid="deleteChecklists">
                                        Delete Checklist(s)
                                    </MotifDropdownItem>)) || (
                                        <MotifTooltip
                                            trigger={
                                                <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowDeleteChecklistModal(); }} type="button"
                                                    disabled={!deleteChecklistsActionStatus} data-testid="deleteChecklists"
                                                >
                                                    Delete Checklist(s)
                                                    <MotifIconButton className="ml-2" aria-label="Delete Checklists" type="button">
                                                        <MotifIcon src={actionIcInfo24px} />
                                                    </MotifIconButton>
                                                </MotifDropdownItem>}
                                        >
                                            <ul style={{ margin: '0px' }}>{(deleteChecklistValidationMessage.map(msg => <li className="checklistActionToolTipContent">{msg}</li>))}</ul>
                                        </MotifTooltip>
                                    )}

                                {isSystemOrEngagementAdmin ? (approveDenyClientReopenRequestActionStatus &&
                                    <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowApproveClientReopenRequestModal(); }} type="button"
                                        disabled={false} data-testid="approveClientReopenRequest">
                                        Approve Client Reopen Request(s)
                                    </MotifDropdownItem>
                                    ||
                                    <MotifTooltip
                                        trigger={
                                            <MotifDropdownItem type="button"
                                                disabled={true} data-testid="approveClientReopenRequest">
                                                Approve Client Reopen Request(s)
                                                <MotifIconButton className="ml-2" aria-label="Approve Client Reopen Request(s)" type="button">
                                                    <MotifIcon src={actionIcInfo24px} />
                                                </MotifIconButton>
                                            </MotifDropdownItem>}
                                    >
                                        <ul style={{ margin: '0px' }}>{(approveDenyClientReopenRequestValidationMessage.map(msg => <li className="checklistActionToolTipContent">{msg}</li>))}</ul>
                                </MotifTooltip>) : <React.Fragment />
                                }

                                {isSystemOrEngagementAdmin ? (approveDenyClientReopenRequestActionStatus &&
                                    <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowDenyClientReopenRequestModal(); }} type="button"
                                        disabled={false} data-testid="denyClientReopenRequest">
                                        Deny Client Reopen Request(s)
                                    </MotifDropdownItem>
                                    ||
                                    <MotifTooltip
                                        trigger={
                                            <MotifDropdownItem type="button"
                                                disabled={true} data-testid="denyClientReopenRequest">
                                                Deny Client Reopen Request(s)
                                                <MotifIconButton className="ml-2" aria-label="Deny Client Reopen Request(s)" type="button">
                                                    <MotifIcon src={actionIcInfo24px} />
                                                </MotifIconButton>
                                            </MotifDropdownItem>}
                                    >
                                        <ul style={{ margin: '0px' }}>{(approveDenyClientReopenRequestValidationMessage.map(msg => <li className="checklistActionToolTipContent">{msg}</li>))}</ul>
                                    </MotifTooltip>) : <React.Fragment />
                                }

                                {(resendChecklistActionStatus && (<MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowResendChecklistModal(); }} type="button"
                                    disabled={!resendChecklistActionStatus} data-testid="resendChecklists">
                                    Resend Checklist(s)
                                </MotifDropdownItem>)) || (
                                        <MotifTooltip
                                            trigger={
                                                <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowResendChecklistModal(); }} type="button"
                                                    disabled={!resendChecklistActionStatus} data-testid="resendChecklists">
                                                    Resend Checklist(s)
                                                    <MotifIconButton className="ml-2" aria-label="Tooltip" type="button">
                                                        <MotifIcon src={actionIcInfo24px} />
                                                    </MotifIconButton>
                                                </MotifDropdownItem>}
                                        >
                                            <ul style={{ margin: '0px' }}><li className="checklistActionToolTipContent">{resendChecklistTooltipMessage}</li></ul>
                                        </MotifTooltip>
                                    )}

                                {isSystemOrEngagementAdmin ? (reopenforEYReviewChecklistActionStatus &&
                                    <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowReopenForEYReviewChecklistModal(); }} type="button"
                                        data-testid="reopenforEYReviewChecklists"   >
                                        Reopen for EY Review
                                    </MotifDropdownItem>
                                    ||
                                    <MotifTooltip
                                        trigger={
                                            <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowReopenForEYReviewChecklistModal(); }} type="button"
                                                disabled={true} data-testid="reopenforEYReviewChecklists">
                                                Reopen for EY Review
                                                <MotifIconButton className="ml-2" aria-label="Reopen for EY Review" type="button">
                                                    <MotifIcon src={actionIcInfo24px} />
                                                </MotifIconButton>
                                            </MotifDropdownItem>}
                                    >
                                        <ul style={{ margin: '0px' }}>{(reopenforEYReviewChecklistValidationMessage.map(msg => <li className="checklistActionToolTipContent">{msg}</li>))}</ul>
                                    </MotifTooltip>) : <React.Fragment />
                                }

                                {(isSystemOrEngagementAdmin || isEngagementUser) ? (signoffChecklistActionStatus &&
                                    <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowSignoffChecklistModal(); }} type="button"
                                        data-testid="signOffChecklists"   >
                                        Sign-Off Checklist(s)
                                    </MotifDropdownItem>
                                    ||
                                    <MotifTooltip
                                        trigger={
                                            <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowSignoffChecklistModal(); }} type="button"
                                                disabled={true} data-testid="signOffChecklists">
                                                Sign-Off Checklist(s)
                                                <MotifIconButton className="ml-2" aria-label="Sign-Off Checklists" type="button">
                                                    <MotifIcon src={actionIcInfo24px} />
                                                </MotifIconButton>
                                            </MotifDropdownItem>}
                                    >
                                        <ul style={{ margin: '0px' }}>{(signoffChecklistValidationMessage.map(msg => <li className="checklistActionToolTipContent">{msg}</li>))}</ul>
                                    </MotifTooltip>) : <React.Fragment />
                                }

                                {exportChecklistSummaryActionStatus &&
                                    <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); handleExportChecklistSummary(); }} type="button"
                                        data-testid="exportChecklists"   >
                                        Export Checklist Summary
                                    </MotifDropdownItem>
                                    ||
                                    <MotifTooltip
                                        trigger={
                                            <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); handleExportChecklistSummary(); }} type="button"
                                                disabled={true} data-testid="exportChecklists">
                                                Export Checklist Summary
                                                <MotifIconButton className="ml-2" aria-label="Export Checklist Summary" type="button">
                                                    <MotifIcon src={actionIcInfo24px} />
                                                </MotifIconButton>
                                            </MotifDropdownItem>}
                                    >
                                        <ul style={{ margin: '0px' }}>{(exportChecklistSummaryValidationMessage.map(msg => <li className="checklistActionToolTipContent">{msg}</li>))}</ul>
                                    </MotifTooltip>
                                }
                                {downloadChecklistActionStatus &&
                                    <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowDownloadChecklistsModal('WITH_ANSWERS'); }} type="button"
                                        data-testid="downloadChecklistWithAnswersOnly"   >
                                        Download - With Answers Only
                                    </MotifDropdownItem>
                                    ||
                                    <MotifTooltip
                                        trigger={
                                            <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowDownloadChecklistsModal('WITH_ANSWERS'); }} type="button"
                                                disabled={true} data-testid="downloadChecklistWithAnswersOnly">
                                                Download - With Answers Only
                                                <MotifIconButton className="ml-2" aria-label="Download - With Answers Only" type="button">
                                                    <MotifIcon src={actionIcInfo24px} />
                                                </MotifIconButton>
                                            </MotifDropdownItem>}
                                    >
                                        <ul style={{ margin: '0px' }}>{(downloadChecklistValidationMessage.map(msg => <li className="checklistActionToolTipContent">{msg}</li>))}</ul>
                                    </MotifTooltip>
                                }
                                {downloadChecklistActionStatus &&
                                    <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowDownloadChecklistsModal('WITH_ANSWERS_COMMENTS'); }} type="button"
                                        data-testid="downloadChecklistWithAnswersAndComments"   >
                                        Download - With Answers And Comments
                                    </MotifDropdownItem>
                                    ||
                                    <MotifTooltip
                                        trigger={
                                            <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowDownloadChecklistsModal('WITH_ANSWERS_COMMENTS'); }} type="button"
                                                disabled={true} data-testid="downloadChecklistWithAnswersAndComments">
                                                Download - With Answers And Comments
                                                <MotifIconButton className="ml-2" aria-label="Download - With Answers And Comments" type="button">
                                                    <MotifIcon src={actionIcInfo24px} />
                                                </MotifIconButton>
                                            </MotifDropdownItem>}
                                    >
                                        <ul style={{ margin: '0px' }}>{(downloadChecklistValidationMessage.map(msg => <li className="checklistActionToolTipContent">{msg}</li>))}</ul>
                                    </MotifTooltip>
                                }
                                {downloadChecklistActionStatus &&
                                    <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowDownloadChecklistsModal('WITHOUT_ANSWERS'); }} type="button"
                                        data-testid="downloadChecklistWithoutAnswersorComments"   >
                                        Download - Without Answers or Comments
                                    </MotifDropdownItem>
                                    ||
                                    <MotifTooltip
                                        trigger={
                                            <MotifDropdownItem onClick={() => { setActionOptionsOpen(false); toggleShowDownloadChecklistsModal('WITHOUT_ANSWERS'); }} type="button"
                                                disabled={true} data-testid="downloadChecklistWithoutAnswersorComments">
                                                Download - Without Answers or Comments
                                                <MotifIconButton className="ml-2" aria-label="Download - With Answers or Comments" type="button">
                                                    <MotifIcon src={actionIcInfo24px} />
                                                </MotifIconButton>
                                            </MotifDropdownItem>}
                                    >
                                        <ul style={{ margin: '0px' }}>{(downloadChecklistValidationMessage.map(msg => <li className="checklistActionToolTipContent">{msg}</li>))}</ul>
                                    </MotifTooltip>
                                }
                            </MotifDropdown>
                        }
                        </div>
                    }
                </div>
            </div>
        </form>
    </div>;
};

ChecklistHeader.propTypes = {
    selectedClient: PropTypes.object,
    toggleShowDownloadChecklistsModal: PropTypes.bool,
    canEdit:PropTypes.bool,
};

export default reduxForm({ form: "Checklist" })(ChecklistHeader);