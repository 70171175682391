import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as commonActions from '../../actions/commonActions';
import * as customChecklistTemplateActions from '../../actions/customChecklistTemplateActions';
import * as checklistActions from '../../actions/checklistActions';
import * as propertyActions from '../../actions/propertyActions';
import * as periodActions from '../../actions/periodActions';
import * as authActions from '../../actions/authActions';
import * as reitActions from '../../actions/reitActions';
import * as reportActions from '../../actions/reportActions';
import * as lookupTypeActions from "../../actions/lookupTypeActions";
import * as fileActions from '../../actions/fileActions';
import * as formValidators from '../../scripts/formValidators';
import ChecklistNavigation from '../../components/reits/ChecklistNavigation';
import EditChecklistSection from '../../components/reits/EditChecklistSection';
import PsqChecklistSummary from '../../components/reits/PsqChecklistSummary';
import ChecklistCounters from '../../components/reits/ChecklistCounters';
import { Segment, Button, Modal, Header, Icon, Grid } from 'semantic-ui-react';
import ChecklistCompleteModal from '../../components/reits/ChecklistCompleteModal';
import PsqCreateChecklistModal from '../../components/reits/PsqCreateChecklistModal';
import ChecklistPageBase from '../reits/ChecklistPageBase';
import PsqProviderSummary from '../../components/reits/PsqProviderSummary';
import ReactModal from 'react-modal';
import * as customQuestionActions from "../../actions/customQuestionActions";
import { Button as BootstrapButton, Dropdown as BootstrapDropdown, ButtonGroup, DropdownButton } from 'react-bootstrap';
import { MotifToggleSwitch, MotifIcon, MotifButton, MotifDropdown, MotifDropdownItem } from '@ey-xd/motif-react';
import { navigationIcArrowDropDown24px } from '@ey-xd/motif-react/assets/icons';
import * as  menuCount from '../../constants/checklistNavigationTabs';
/**
 * PsqChecklistTemplatePreview container component
 * @extends {ChecklistPageBase}
 */
export class PsqChecklistTemplatePreview extends ChecklistPageBase {
  /**
   * Creates a new PsqChecklistTemplatePreview
   * @constructor
   * @param {Object} props The component properties
   * @param {Object} context The component context
   */
  constructor(props, context) {
    super(props, context);

    this.state.psqProviderSummary = [];
    this.state.showAllQuestions = true;
    this.state.showUnansweredQuestions = false;
    this.state.canEdit = true;
    this.state.newQuestionIds = [];
    this.state.navigationCount=[];
    this.state.isSubNavigation = false;
    this.state.firstDynamicQuestionIdForServiceProvider = -200000;  // For Template Preview, for new Service Provider, use dynamic ids from -200000
    this.setActiveItem = this.setActiveItem.bind(this);   
    this.isEngagementAdminOrSysAdmin = this.isEngagementAdminOrSysAdmin.bind(
      this,
    );
    this.handleAddPsqSection2Row = this.handleAddPsqSection2Row.bind(this);
    this.handleRemovePsqSection2Row = this.handleRemovePsqSection2Row.bind(
      this,
    );
    this.handleDownloadAttachmentClick = this.handleDownloadAttachmentClick.bind(this);
    this.state.isDisabledTab = true;
  }

  /**
   * Invoked immediately after mounting occurs
   * @returns {undefined}
   */
  componentDidMount() {
    // Check if the template opened in preview mode is Standard template (i.e. TemplateID less than 0), if yes use the -1 clientId
    const clientId = Number.parseInt(this.props.templateId) < 0 ? -1 : this.props.clientId;

    this.props.customChecklistTemplateActions.fetchCustomPsqTemplateContentById(this.props.clientId, this.props.templateId);
    
    // Using this API call to get template name as this does not have any access restrictions
    const templateRequest = {
      clientId: this.props.clientId,
      templateId: this.props.templateId,
    };

    this.props.customChecklistTemplateActions
      .fetchChecklistTemplateByChecklistTemplateId(templateRequest);
  }

  componentWillUnmount() {
    this.props.customChecklistTemplateActions.fetchCustomPsqTemplateById();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.psqActiveChecklist !== nextProps.psqActiveChecklist) {
      let checklist = {content: nextProps.psqActiveChecklist};

      this.setState({ checklist: checklist });

      // set disabled sections
      if (
        nextProps.psqActiveChecklist !== undefined &&
        nextProps.psqActiveChecklist !== null &&
        nextProps.psqActiveChecklist.length > 0
      ) {
        this.disableSections(checklist);
      }

      if (
        this.state.activeItem == null &&
        nextProps.psqActiveChecklist !== undefined &&
        nextProps.psqActiveChecklist !== null &&
        nextProps.psqActiveChecklist.length > 0
      ) {
        for (let i = 0; i < nextProps.psqActiveChecklist.length; i++) {
          if (
            this.state.disabledSections.find(
              x => x === nextProps.psqActiveChecklist[i].id,
            ) === undefined
          ) {
            this.setState({
              activeItem: nextProps.psqActiveChecklist[0],
            });
            break;
          }
        }
      }

      if (nextProps.psqActiveChecklist) {
          if (this.state.showDetails) {
            this.setState({ showAllQuestions: true, showUnansweredQuestions: false });
          }
          this.setState({ showDetails: !this.state.showDetails });

          if (this.state.checklist !== undefined && this.state.checklist !== null && this.state.checklist.content != undefined && this.state.checklist.content.length > 0) {
              this.setState({ activeItem: this.state.checklist.content[0] });
          }
      }
    }
  }

  setActiveItem(e, { name }) {
    const item = this.props.psqActiveChecklist.find(
      x => x.title === name,
    );
    if (this.state.disabledSections.find(x => x === item.id) === undefined) {
      this.setState({ activeItem: item, isSubNavigation: false });
    }

    if (item === undefined && name) {
      this.setState({ activeItem: name, isSubNavigation: true });
    }
  }

  handleDownloadAttachmentClick(attachmentGuid, attachmentName) {
    return fileActions.downloadCustomQuestionAttachment(this.props.clientId, attachmentGuid, encodeURIComponent(attachmentName), null);

  }

  handleAddPsqSection2Row() {
    const checklist = JSON.parse(JSON.stringify(this.state.checklist));
    const psqSection2 = checklist.content.find(x => x.type === 'PsqSection2');
    let items = psqSection2.items;

    if (items != null) {
      let lastItem = items[items.length - 1];
      let lastId = lastItem.id;
      if (
        lastItem.children !== undefined &&
        lastItem.children !== null &&
        lastItem.children.length > 0
      ) {
        for (let i = 0; i < lastItem.children.length; i++) {
          if (lastItem.children[i].id > lastId) {
            lastId = lastItem.children[i].id;
          }
        }
      }

      lastId = lastId > 0 ? this.state.firstDynamicQuestionIdForServiceProvider-1 : lastId;

      const nextItemNumber = lastItem.questionSortOrder + 1;
      checklist.content
        .find(x => x.type === 'PsqSection2')
        .items.push({
          id: lastId + 1,
          questionSortOrder: nextItemNumber,
          title:
            psqSection2.sectionSortOrder + '.' +
            nextItemNumber +
            ' Please identify any Additional Service Provider, the services provided, the compensation arrangement, and its relationship to the REIT in the space below.',
          itemType: 'PsqSection2AdditionalServices',
          isMultiSelect: false,
          isEnabled: true,
          defaultAnswers: [],
          availableAnswers: [],
          selectedAnswers: [''],
          controlSections: [],
          systemRule: {
            name: 'SetFromPreviousPeriodAnswer',
            setAnswers: ['{selectedAnswers}'],
            isDisabledOnSystemAnswer: false,
          },
          children: [
            {
              id: lastId + 2,
              title:
                psqSection2.sectionSortOrder + '.' +
                nextItemNumber +
                '.1 Does the IK own, directly or indirectly, more than a 35% interest (based on vote or value) in the REIT?',
              itemType: 'button',
              isMultiSelect: false,
              isEnabled: true,
              defaultAnswers: [],
              availableAnswers: ['Yes', 'No'],
              selectedAnswers: [],
              controlSections: [],
              systemRule: {
                name: 'SetFromPreviousPeriodAnswer',
                setAnswers: ['{selectedAnswers}'],
                isDisabledOnSystemAnswer: false,
              },
              children: [],
              enabledByParentAnswers: ['IK'],
              ruleTag: [],
            },
            {
              id: lastId + 3,
              title:
                psqSection2.sectionSortOrder + '.' +
                nextItemNumber +
                '.2 Do one or more persons who own 35% or more of the interests in the REIT (based on vote or value) also own directly or indirectly more than 35% of the interests in the IK (based on net profits or assets, if non-corporate)?',
              itemType: 'button',
              isMultiSelect: false,
              isEnabled: true,
              defaultAnswers: [],
              availableAnswers: ['Yes', 'No'],
              selectedAnswers: [],
              controlSections: [],
              systemRule: {
                name: 'SetFromPreviousPeriodAnswer',
                setAnswers: ['{selectedAnswers}'],
                isDisabledOnSystemAnswer: false,
              },
              children: [],
              enabledByParentAnswers: ['IK'],
              ruleTag: [],
            },
            {
              id: lastId + 4,
              title:
                psqSection2.sectionSortOrder + '.' +
                nextItemNumber +
                '.3 Does the REIT derive or receive any income from the IK, such as a share of the income or revenue derived from services, rents from leasing space to the contractor (at this property or any other property), dividends or other profits of the contractor, or any other form of income from the contractor?',
              itemType: 'button',
              isMultiSelect: false,
              isEnabled: true,
              defaultAnswers: [],
              availableAnswers: ['Yes', 'No'],
              selectedAnswers: [],
              controlSections: [],
              systemRule: {
                name: 'SetFromPreviousPeriodAnswer',
                setAnswers: ['{selectedAnswers}'],
                isDisabledOnSystemAnswer: false,
              },
              children: [],
              enabledByParentAnswers: ['IK'],
              ruleTag: [],
            },
            {
              id: lastId + 5,
              title:
                psqSection2.sectionSortOrder + '.' +
                nextItemNumber +
                ".4 Is the REIT's relationship with the IK an arms-length relationship?",
              itemType: 'button',
              isMultiSelect: false,
              isEnabled: true,
              defaultAnswers: [],
              availableAnswers: ['Yes', 'No'],
              selectedAnswers: [],
              controlSections: [],
              systemRule: {
                name: 'SetFromPreviousPeriodAnswer',
                setAnswers: ['{selectedAnswers}'],
                isDisabledOnSystemAnswer: false,
              },
              children: [],
              enabledByParentAnswers: ['IK'],
              ruleTag: [],
            },
            {
              id: lastId + 6,
              title:
                psqSection2.sectionSortOrder + '.' +
                nextItemNumber +
                '.5 Is the IK adequately compensated based on fair-value and arms-length dealing?',
              itemType: 'button',
              isMultiSelect: false,
              isEnabled: true,
              defaultAnswers: [],
              availableAnswers: ['Yes', 'No'],
              selectedAnswers: [],
              controlSections: [],
              systemRule: {
                name: 'SetFromPreviousPeriodAnswer',
                setAnswers: ['{selectedAnswers}'],
                isDisabledOnSystemAnswer: false,
              },
              children: [],
              enabledByParentAnswers: ['IK'],
              ruleTag: [],
            },
            {
              id: lastId + 7,
              title:
                psqSection2.sectionSortOrder + '.' +
                nextItemNumber +
                '.6 Does the REIT directly or indirectly bear the costs of any of the IK services?',
              itemType: 'button',
              isMultiSelect: false,
              isEnabled: true,
              defaultAnswers: [],
              availableAnswers: ['Yes', 'No'],
              selectedAnswers: [],
              controlSections: [],
              systemRule: {
                name: 'SetFromPreviousPeriodAnswer',
                setAnswers: ['{selectedAnswers}'],
                isDisabledOnSystemAnswer: false,
              },
              children: [],
              enabledByParentAnswers: ['IK'],
              ruleTag: [],
            },
            {
              id: lastId + 8,
              title:
                psqSection2.sectionSortOrder + '.' +
                nextItemNumber +
                '.7 Is the IK an employee of the REIT or is the REIT otherwise able to direct and control the manner in which the IK carries out its duties?',
              itemType: 'button',
              isMultiSelect: false,
              isEnabled: true,
              defaultAnswers: [],
              availableAnswers: ['Yes', 'No'],
              selectedAnswers: [],
              controlSections: [],
              systemRule: {
                name: 'SetFromPreviousPeriodAnswer',
                setAnswers: ['{selectedAnswers}'],
                isDisabledOnSystemAnswer: false,
              },
              children: [],
              enabledByParentAnswers: ['IK'],
              ruleTag: [],
            },
            {
              id: lastId + 9,
              title:
                psqSection2.sectionSortOrder + '.' +
                nextItemNumber +
                '.8 For the above questions, please add an explanation of any special circumstances or additional comments in the box below.',
              itemType: 'plaintext',
              isMultiSelect: false,
              isEnabled: true,
              defaultAnswers: [],
              availableAnswers: [],
              selectedAnswers: [],
              controlSections: [],
              systemRule: {
                name: 'SetFromPreviousPeriodAnswer',
                setAnswers: ['{selectedAnswers}'],
                isDisabledOnSystemAnswer: false,
              },
              children: [],
              enabledByParentAnswers: ['IK'],
              ruleTag: [],
            },
          ],
          enabledByParentAnswers: [],
          ruleTag: [],
          serviceProvider: nextItemNumber,
        });

      this.setState({ checklist: checklist });

      // Append all these new question ids into state
      // The range will generate an array of 9 ids (lastId +1 to lastId +9)
      const range = (start, end) => Array.from({ length: ((end + 1) - start) }, (v, k) => k + start);
      this.setState({ newQuestionIds: [...this.state.newQuestionIds, ...range(lastId + 1, lastId + 9)] });

      this.disableSections(checklist);
    }
  }

  handleRemovePsqSection2Row(item) {
    const checklist = JSON.parse(JSON.stringify(this.state.checklist));
    let section = checklist.content.find(x => x.type === 'PsqSection2');
    for (let i = 1; i < section.items.length; i++) {
      if (section.items[i].id === item.id) {
        let questionIdsToRemove = [item.id];
        questionIdsToRemove = [...questionIdsToRemove, ...item.children.map(x => x.id)];

        // Remove these question ids from state
        this.setState({ newQuestionIds: this.state.newQuestionIds.filter(x => !questionIdsToRemove.includes(x)) });

        checklist.content
          .find(x => x.type === 'PsqSection2')
          .items.splice(i, 1);
        break;
      }
    }
    section = this.reArrangeSortOrder(section);
    this.setState({ checklist: checklist });
    this.disableSections(checklist);
  }
  
  reArrangeSortOrder(section) {
    for (let j = 0; j < section.items.length; j++) {
      const newSortOrder = j + 1;
      section.items[j].questionSortOrder = newSortOrder; // assign new question sort order
      //Prepare new question sort order to display with question text
      const sortOrder = section.sectionSortOrder + "." + newSortOrder;

      //Split question title to seprate question number and text
      const [oldQuestionSortOrder, questionText] =
        section.items[j].title.split(/\s+(.*)/);

      if (
        section.items[j].itemType == "PsqSection2AdditionalServices" &&
        sortOrder != oldQuestionSortOrder
      ) {
        //Concatenate new sort order with question text
        section.items[j].title = sortOrder + " " + questionText;
        let StartIndex = 1;
        //Rearrange child question number
        section.items[j].children.forEach((element) => {
          const [childOrder, childQuestionText] =
            element.title.split(/\s+(.*)/);
          element.title =
            sortOrder + "." + StartIndex + " " + childQuestionText;
          StartIndex = StartIndex + 1;
        });
      }
    }
    return section;
  }

  getDetails() {
    const isEnabled =
      this.props.psqActiveChecklist !== null &&
      this.state.canEdit;

    if (this.state.showDetails && this.state.checklist !== null) {
      return (
        <div>
          <div className="row">
            <div className="col-md-3 mb-2">
              <ChecklistNavigation
                disabledSections={this.state.disabledSections}
                checklist={this.state.checklist}
                activeItem={this.state.activeItem}
                handleSelectionChanged={this.setActiveItem}
                checklistFiles={this.props.checklistFiles}
                isExternal={false}
              />
            </div>
            <div className="col-md-9 mb-2">
              {!this.state.isSubNavigation && (
                <>
                  {this.state.checklist.content.map((section) => (
                    <EditChecklistSection
                      isEnabled={isEnabled}
                      key={section.id}
                      section={section}
                      isVisible={section.title === this.state.activeItem.title}
                      handleItemAnswered={this.handleItemAnswered}
                      showAllQuestions={this.state.showAllQuestions}
                      showUnansweredQuestions={this.state.showUnansweredQuestions}
                      isExternal={false}
                      handleDownloadAttachmentClick={this.handleDownloadAttachmentClick}
                      isArqc={false}
                      previousChecklistFound={
                        this.props.previousPsqChecklist &&
                        this.props.previousPsqChecklist.content
                      }
                      prePopulateAnswerOptionID={
                        this.state.checklist.prePopulateAnswerOptionID
                      }
                      lastServiceProviderQuestionId={this.state.firstDynamicQuestionIdForServiceProvider + (98*9)}  // Allow only 100 Service Providers
                      handleComments={this.handleComments}
                      handleAddPsqSection2Row={this.handleAddPsqSection2Row}
                      handleRemovePsqSection2Row={
                        this.handleRemovePsqSection2Row
                      }
                      canEdit={this.state.canEdit}
                      user={this.props.user}
                      checklistFiles={this.props.checklistFiles}
                      handleSaveComment={this.handleSaveComment}
                      handleDeleteComment={this.handleDeleteComment}
                      handleEditComment={this.handleEditComment}
                      handleDeleteAttachment={this.handleDeleteAttachment}
                      handleDownloadChecklistFile={
                        this.handleDownloadChecklistFile
                      }
                      isDisabledTab={this.state.isDisabledTab}
                    />
                  ))}
                </>
              )}

              {this.state.isSubNavigation && (
                <>
                  <h4 className="sectionTitle">{this.state.activeItem}</h4>
                  <div>No {this.state.activeItem.toLowerCase()} found for this checklist.</div>
                </>
              )}
            </div>
          </div>
          <ChecklistCompleteModal
            handleModalAction={this.handleConfirmationAction}
            showModal={this.state.showConfirmationModal}
          />
        </div>
      );
    }

    return null;
  }
      
  isEngagementAdminOrSysAdmin() {
    let isEngAdminOrSysAdmin = false;
    let currentUserAuthorizations = this.props.currentUserAuthorizations;
    if (Array.isArray(currentUserAuthorizations)) {
      isEngAdminOrSysAdmin =
        authActions.isSystemAdministrator(currentUserAuthorizations) ||
        authActions.isEngagementAdministrator(currentUserAuthorizations, this.props.client.id);
    }

    return isEngAdminOrSysAdmin;
  }

  getQuestionnaireTitle() {
    return this.props.customPsqTemplateDetails ? this.props.customPsqTemplateDetails.checklistTemplateName : "";
  }

  /**
   * Render a React element
   * @returns {Object} A reference to the component
   */
  render() {

    let isEngAdminOrSysAdmin = false;
    isEngAdminOrSysAdmin = this.isEngagementAdminOrSysAdmin();

    if (this.props.psqActiveChecklist === null) {
      return (
        <div className="checklist">
          <h1 data-testid="h1PageHeading">{this.getQuestionnaireTitle()}</h1>
          {!this.state.canEdit && <div>Reporting Closed</div>}

          <div style={{ marginTop: '15px' }}>
            <span>
              <MotifDropdown
                open={this.state.checklistDropdownOpen}
                placement="bottom-left"
                id={'checklist-dropdown'}
                aria-labelledby={'checklist-management-trigger'}
                data-testid="btnDownloadChecklist"
                handleClickOutside={() => this.setState({ checklistDropdownOpen: false })}
                trigger={
                  <MotifButton
                    disabled={!(this.props.psqActiveChecklist && this.props.psqChecklists !== undefined && this.props.psqChecklists.length > 0)}
                    type="button"
                    variant="primary-alt"
                    onClick={() => this.setState({ checklistDropdownOpen: !this.state.checklistDropdownOpen })}
                    aria-controls={'checklist-dropdown'}
                    id={'checklist-dropdown-trigger'}
                    aria-expanded={this.state.checklistDropdownOpen}
                    aria-label="Download checklist dropdown"
                  >
                    Download Checklist <MotifIcon src={navigationIcArrowDropDown24px} />
                  </MotifButton>
                }
              >
                <MotifDropdownItem onClick={() => { this.setState({ checklistDropdownOpen: false }); this.downloadChecklist('WITH_ANSWERS'); }}>
                  With Answers Only
                </MotifDropdownItem>
                <MotifDropdownItem onClick={() => { this.setState({ checklistDropdownOpen: false }); this.downloadChecklist('WITH_ANSWERS_COMMENTS') }}>
                  With Answers And Comments
                </MotifDropdownItem>
                <MotifDropdownItem onClick={() => { this.setState({ checklistDropdownOpen: false }); this.downloadChecklist('WITHOUT_ANSWERS'); }}>
                  Without Answers or Comments
                </MotifDropdownItem>
              </MotifDropdown>
            </span>
          </div>
        </div>
      );
    }

    return (
      <div className="checklist">
        <div className="row" style={{alignItems:"center"}}>
          <div className="col">
            <h1 data-testid="h1PageHeading">{this.getQuestionnaireTitle()}</h1>
            {!this.state.canEdit && <div>Reporting Closed</div>}
          </div>
        </div>
        <div className="row mt-3 mb-3">
          <div className="col-md-4 checklistCounters">
            <ChecklistCounters
                previousChecklistFound={
                  this.props.previousPsqChecklist &&
                  this.props.previousPsqChecklist.content
                }
                isExternal={false}
                checklist={this.state.checklist}
                disabledSections={this.state.disabledSections}
            />
          </div>
          <div className="col-md-8">
            <div className="d-flex ml-auto justify-content-end">
              <span className="ml-3">
                <MotifDropdown
                  open={this.state.checklistDropdownOpen}
                  placement="bottom-left"
                  id={'checklist-dropdown'}
                  aria-labelledby={'checklist-management-trigger'}
                  data-testid="btnDownloadChecklist"
                  handleClickOutside={() => this.setState({ checklistDropdownOpen: false })}
                  trigger={
                    <MotifButton
                      disabled={!(this.props.psqActiveChecklist && this.props.psqChecklists !== undefined && this.props.psqChecklists.length > 0)}
                      type="button"
                      variant="primary-alt"
                      onClick={() => this.setState({ checklistDropdownOpen: !this.state.checklistDropdownOpen })}
                      aria-controls={'checklist-dropdown'}
                      id={'checklist-dropdown-trigger'}
                      aria-expanded={this.state.checklistDropdownOpen}
                      aria-label="Download checklist dropdown"
                    >
                      Download Checklist <MotifIcon src={navigationIcArrowDropDown24px} />
                    </MotifButton>
                  }
                >
                  <MotifDropdownItem onClick={() => { this.setState({ checklistDropdownOpen: false }); this.downloadChecklist('WITH_ANSWERS'); }}>
                    With Answers Only
                  </MotifDropdownItem>
                  <MotifDropdownItem onClick={() => { this.setState({ checklistDropdownOpen: false }); this.downloadChecklist('WITH_ANSWERS_COMMENTS') }}>
                    With Answers And Comments
                  </MotifDropdownItem>
                  <MotifDropdownItem onClick={() => { this.setState({ checklistDropdownOpen: false }); this.downloadChecklist('WITHOUT_ANSWERS'); }}>
                    Without Answers or Comments
                  </MotifDropdownItem>
                </MotifDropdown>
              </span>

              <MotifButton variant="secondary" className="ml-2" type="button" data-testid="btnReviewComplete"
                onClick={this.showConfirmationModal}
                disabled={true}>
                Review Complete
              </MotifButton>
              <MotifButton variant="primary" className="ml-2" type="button" data-testid="btnSave"
                onClick={this.handleSaveChecklist}
                disabled={true}>
                Save
              </MotifButton>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col d-flex mr-auto checklistPreview">
            <div className="d-flex flex-row" style={{ alignItems: 'center' }} data-testid="toggleDisplayUnansweredQuestionsOnly">
              <div className="mr-3">
                <span className="right font-weight-bold">Display Unanswered Questions Only:</span>
              </div>
              <div>
                <div className="switch-box">
                  <MotifToggleSwitch
                    aria-label="Display Unanswered Questions Only toggle"
                    name="showUnanswered"
                    id="showUnanswered"
                    onChange={this.handleShowUnansweredQuestions}
                    checked={this.state.showUnansweredQuestions}
                  />
                </div>
              </div>
            </div>

            {(this.state.showDetails &&
              this.props.psqActiveChecklist &&
              this.props.previousPsqChecklist &&
              Array.isArray(this.props.previousPsqChecklist.content) &&
              this.props.previousPsqChecklist.content.length > 0 && (
                <div className="d-flex flex-row ml-4" style={{ alignItems: 'center' }} data-testid="toggleDisplayChangedAnswers">
                  <div className="mr-3">
                    <span className="right font-weight-bold">Display Changed Answers:</span>
                  </div>
                  <div>
                    <div className="switch-box">
                      <MotifToggleSwitch
                        aria-label="Display Changed Answers toggle"
                        name="showAll"
                        id="showAll"
                        onChange={this.handleShowAllQuestions}
                        checked={!this.state.showAllQuestions}
                      />
                    </div>
                  </div>
                </div>
              )) || <div />}
          </div>
        </div>

        {this.getDetails()}     
      </div>
    );
  }
}

PsqChecklistTemplatePreview.propTypes = {
  client: PropTypes.object.isRequired,
  reit: PropTypes.object.isRequired,
  periodId: PropTypes.number.isRequired,
  psqChecklists: PropTypes.array,
  psqActiveChecklist: PropTypes.object,
  previousPsqChecklist: PropTypes.object,
  checklistActions: PropTypes.object,
  reitActions: PropTypes.object,
  propertyActions: PropTypes.object,
  fileActions: PropTypes.object,
  properties: PropTypes.array,
  periodActions: PropTypes.object.isRequired,
  currentUserAuthorizations: PropTypes.array,
  deletedChecklist: PropTypes.object,
  canDelete: PropTypes.bool,
  canRevertState: PropTypes.bool,
  pendingTasks: PropTypes.number,
  reportActions: PropTypes.object,
  lookupTypes: PropTypes.object,
  user: PropTypes.string,
  customPsqTemplateDetails: PropTypes.object
};

/**
 * Maps items from state to properties of the component
* @param {Object} state The state
* @param {Object} ownProps The properties of the component
* @returns {Object} An object containing properties that the component can access
             */
function mapStateToProps(state, ownProps) {
  return {
    user: state.authentication.currentUser,
    client: state.client,
    clientId: Number.parseInt(ownProps.params.clientId),
    templateId: Number.parseInt(ownProps.params.templateId),
    customPsqTemplateDetails: state.customPsqTemplateDetails,
    psqChecklists: state.psqChecklists,
    psqActiveChecklist: state.customPsqTemplateContent,
    previousPsqChecklist: state.previousPsqChecklist,
    checklistFiles: state.checklistFiles,
    reit: state.reit,
    properties: state.properties,
    period: state.period,
    currentUserAuthorizations: state.currentUserAuthorizations,
    deletedChecklist: state.deletedChecklist,
    canDelete:
      Array.isArray(state.currentUserAuthorizations) &&
      (authActions.isSystemAdministrator(state.currentUserAuthorizations) ||
        (state.client &&
          authActions.isEngagementAdministrator(
            state.currentUserAuthorizations,
            state.client.id,
          ))) &&
      (state.period.reportPeriodStatusDescription !== 'Closed to Reporting' &&
        state.period.reportPeriodStatusDescription !== 'Complete'),
    canRevertState:
      state.period.reportPeriodStatusDescription !== 'Closed to Reporting' &&
      state.period.reportPeriodStatusDescription !== 'Complete',
    pendingTasks: state.pendingTasks,
    customQuestionnaireTemplates: state.customQuestionnaire.templates,
    lookupTypes: state.lookupTypes
  };
}

/**
 * Binds actions to the dispatcher
* @param {Object} dispatch The action dispatcher
* @returns {Object} An object containing properties that the component can access
               */
function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonActions, dispatch),
    customChecklistTemplateActions: bindActionCreators(customChecklistTemplateActions, dispatch),
    checklistActions: bindActionCreators(checklistActions, dispatch),
    fileActions: bindActionCreators(fileActions, dispatch),
    propertyActions: bindActionCreators(propertyActions, dispatch),
    reitActions: bindActionCreators(reitActions, dispatch),
    periodActions: bindActionCreators(periodActions, dispatch),
    reportActions: bindActionCreators(reportActions, dispatch),
    customQuestionActions: bindActionCreators(customQuestionActions, dispatch),
    lookupTypeActions: bindActionCreators(lookupTypeActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PsqChecklistTemplatePreview);
