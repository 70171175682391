import React from 'react';
import PropTypes, { number } from "prop-types";
import { connect } from "react-redux";
import { reduxForm, getFormValues } from 'redux-form';
import { Menu } from 'semantic-ui-react';
import * as actions from '../../actions/chartOfAccountsManagerActions';
import * as reitActions from '../../actions/reitActions';
import { bindActionCreators } from 'redux';
import ChartAssignmentHeader from '../../components/chartOfAccountsManager/ChartAssignmentHeader';
import ChartAssignmentAllReitsTable from '../../components/chartOfAccountsManager/ChartAssignmentAllReitsTable';
import ChartAssignmentPropertyTable from '../../components/chartOfAccountsManager/ChartAssignmentPropertyTable';
import { MotifTabNavigation, MotifTabControl } from '@ey-xd/motif-react';
import { Link } from 'react-router';

/**
 * ChartAssignmentContainer container component
 * @extends {React.Component}
 */
class ChartAssignmentContainer extends React.Component {
    /**
    * Creates a new ChartAssignmentContainer
    * @constructor
    * @param {Object} props The component properties
    * @param {Object} context The component context
    */
    constructor(props, context) {
        super(props, context);

        this.handleCalendarYearSelection = this.handleCalendarYearSelection.bind(this);
        this.handleReitSelection = this.handleReitSelection.bind(this);
        this.handleReitUpdate = this.handleReitUpdate.bind(this);
        this.handlePropertyUpdate = this.handlePropertyUpdate.bind(this);
        this.handleSearchBox = this.handleSearchBox.bind(this);
        this.handleClearSearchText = this.handleClearSearchText.bind(this);
        this.setRowsAffected = this.setRowsAffected.bind(this);
        this.setTabBar1 = this.setTabBar1.bind(this);
        this.handleClick = this.handleClick.bind(this);

        this.state = {
            calendarYears: [],
            reits: [],
            searchBoxText: "",
            reitsMappings: [],
            reitPropertyMappings: [],
            reitsGridArray: [],
            reitPropertiesGridArray: [],
            defaultChart: {},
            tabBar1: 0,
            rowsAffected: []
        };

        // Default option for all calendar years to show chart assignment for all REITs
        this.allReitsOption = {
            reitID: -1,
            reitShortName: "All REITs"
        };
    }

    /**
     * Invoked immediately after mounting occurs
     * @returns {undefined}
     */
    componentDidMount() {
        // Fetch the charts for the dropdown
        this.props.actions.fetchCharts(this.props.clientId).then(() => {
            // Attempt to find a default chart and select its ID in the dropdown
            if (this.props.chartOfAccounts && this.props.chartOfAccounts.charts && this.props.chartOfAccounts.charts.find(c => c.isDefault)) {
                this.setState({
                    defaultChart: this.props.chartOfAccounts.charts.find(c => c.isDefault)
                });
            }
        });

        // Fetch calendar years for current client
        this.props.actions.fetchClientCalendarYears(this.props.clientId).then(fetchedCalendarYears => {
            let defaultCalendarYear = -1;

            if (fetchedCalendarYears && fetchedCalendarYears.length > 0) {
                defaultCalendarYear = fetchedCalendarYears[0];
                this.setState({
                    calendarYears: fetchedCalendarYears,
                });

                // Fetch Reits that has report periods in the selected calendar year
                this.fetchCalendarYearReits(defaultCalendarYear);
                this.fetchCalendarYearReitsGridArray(defaultCalendarYear);
            }

            // Send the initial values for calendar year and reit dropdowns
            this.props.dispatch(this.props.initialize({ calendarYear: defaultCalendarYear, reit: -1 }));
        });

    }

    /**
     * Invoked before our mounted React component receives new props
     * @returns {undefined}
     */
    componentWillReceiveProps(nextProps) {
    }

    setRowsAffected(values) {
        this.setState({ rowsAffected: values });
    }

    //Set tab related state variables
    setTabBar1(currentIndex) {
        this.setState({ tabBar1: currentIndex });
    }

    //Set current tab index
    handleClick(index) {
        this.setTabBar1(index);
    }

    handleCalendarYearSelection(calendarYear) {
        this.fetchCalendarYearReits(calendarYear);
    }

    fetchCalendarYearReits(calendarYear) {
        // Fetch Reits that has report periods in the selected calendar year
        this.props.reitActions.fetchCalendarYearReits(calendarYear, this.props.clientId).then(fetchedCalendarYearReits => {
            if (fetchedCalendarYearReits && fetchedCalendarYearReits.length > 0) {
                this.setState({
                    reits: [this.allReitsOption, ...fetchedCalendarYearReits]
                });
                // Check if the current selected REIT is present for selected calendar year, if not then set REIT dropdown to 'All REITs' option
                if (this.props.chartAssignmentFormValues && this.props.chartAssignmentFormValues.reit) {
                    const isReitPresent = this.state.reits.findIndex(r => r.reitID === this.props.chartAssignmentFormValues.reit) !== -1;
                    if (!isReitPresent && this.state.reits.length > 0) {
                        this.props.dispatch(this.props.change('reit', -1));
                        this.fetchCalendarYearReitsGridArray(calendarYear);
                    } else if (isReitPresent && this.props.chartAssignmentFormValues.reit !== -1) {
                        this.props.actions.fetchReitPropertiesById(this.props.chartAssignmentFormValues.reit, calendarYear)
                            .then((response) => {
                                this.setState({
                                    reitPropertyMappings: response.propertiesMappings,
                                    reitPropertiesGridArray: response.gridArray
                                });
                            });
                    } else if (this.props.chartAssignmentFormValues.reit === -1){
                        this.fetchCalendarYearReitsGridArray(calendarYear);
                    }
                }
            }
        });
    }

    fetchCalendarYearReitsGridArray(calendarYear) {
        this.props.actions.fetchCalendarYearReitsData(this.props.clientId, calendarYear).then((response) => {
            this.setState({
                reitsMappings: response.reitsMappings,
                reitsGridArray: response.gridArray
            });
        });
    }

    handleReitSelection(reit) {
        let calendarYear = this.props.chartAssignmentFormValues.calendarYear
        if (reit === -1) {
            this.fetchCalendarYearReitsGridArray(calendarYear);
        } else {
            this.props.actions.fetchReitPropertiesById(reit, calendarYear)
                .then((response) => {
                    this.setState({
                        reitPropertyMappings: response.propertiesMappings,
                        reitPropertiesGridArray: response.gridArray
                    });
                });
        }
    }

    handleReitUpdate(mappingsToUpdate) {
        this.setState({
            reitsMappings: [],
            reitsGridArray: []
        });
        this.props.actions.updateReitsMappings(mappingsToUpdate).then((response) => {
            this.fetchCalendarYearReitsGridArray(this.props.chartAssignmentFormValues.calendarYear);
        });
    }

    handleSearchBox(e) {
        this.setState({
            searchBoxText: e.target.value
        })
    }

    handleClearSearchText() {
        this.setState({
            searchBoxText: ""
        })
    }

    handlePropertyUpdate(mappingsToUpdate) {
        this.setState({
            reitPropertyMappings: [],
            reitPropertiesGridArray: []
        });
        this.props.actions.updateReitPropertiesMappings(mappingsToUpdate).then((response) => {
            this.handleReitSelection(this.props.chartAssignmentFormValues.reit);
        });
        // this.setState({
        //     reitsMappings: [],
        //     reitsGridArray: []
        // });
        // this.props.actions.updateReitsMappings(mappingsToUpdate).then((response)=>{
        //     this.fetchCalendarYearReitsGridArray(this.props.chartAssignmentFormValues.calendarYear);
        // });
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */

    render() {
        return (
            <React.Fragment>
                <h1>Chart of Accounts Manager</h1>
                <MotifTabNavigation variant="default">
                    <Link to={`/client/${this.props.clientId}/chartOfAccountsManager`} tabIndex="-1">
                        <MotifTabControl
                            role="tab"
                            onClick={() => this.handleClick(1)}>
                            Chart of Accounts
                        </MotifTabControl>
                    </Link>
                    <Link to={`/client/${this.props.clientId}/chartOfAccountsManager/assignCharts`} tabIndex="-1">
                        <MotifTabControl
                            role="tab"
                            autoFocus
                            className="motif-active" onClick={() => this.handleClick(2)} >
                            Chart Assignments
                        </MotifTabControl>
                    </Link>
                </MotifTabNavigation>

                {
                    // If no chart exists for current client, just show below message.
                    !(this.props.chartOfAccounts.charts && this.props.chartOfAccounts.charts.length > 0) &&
                    <div className="row mt-2">
                        <div className="col">No charts found. To start making chart assignments, create a chart via the Chart of Accounts screen.</div>
                    </div>
                }
                {
                    (this.props.chartOfAccounts.charts && this.props.chartOfAccounts.charts.length > 0) &&
                    <React.Fragment>
                        <ChartAssignmentHeader
                            calendarYears={this.state.calendarYears}
                            reits={this.state.reits}
                            handleSearchBox={this.handleSearchBox}
                            handleClearSearchText={this.handleClearSearchText}
                            searchBoxText={this.state.searchBoxText}
                            handleCalendarYearSelection={this.handleCalendarYearSelection}
                            handleReitSelection={this.handleReitSelection}
                            clientId={this.props.clientId}
                            chartAssignmentFormValues={this.props.chartAssignmentFormValues}
                            rowsAffected={this.state.rowsAffected}
                        />
                        <div className="row">
                            <div className="col-12 pt-2">
                                {this.props.chartAssignmentFormValues && this.props.chartAssignmentFormValues.reit && this.props.chartAssignmentFormValues.reit == -1 && this.props.chartAssignmentFormValues.calendarYear && (this.state.reitsGridArray.length > 0) && this.state.defaultChart.chartName &&
                                    <ChartAssignmentAllReitsTable
                                        reitsArray={this.state.reitsGridArray}
                                        reitsMappings={this.state.reitsMappings}
                                        searchBoxText={this.state.searchBoxText}
                                        chartOfAccounts={this.props.chartOfAccounts}
                                        defaultChart={this.state.defaultChart}
                                        handleReitUpdate={this.handleReitUpdate}
                                        setRowsAffected={this.setRowsAffected}
                                        rowsAffected={this.state.rowsAffected}
                                    />
                                }

                                {this.props.chartAssignmentFormValues && this.props.chartAssignmentFormValues.reit && this.props.chartAssignmentFormValues.reit != -1 && this.props.chartAssignmentFormValues.calendarYear && (this.state.reitPropertiesGridArray.length > 0) && this.state.defaultChart.chartName &&
                                    <ChartAssignmentPropertyTable
                                        propertiesArray={this.state.reitPropertiesGridArray}
                                        reitsMappings={this.state.reitsMappings}
                                        searchBoxText={this.state.searchBoxText}
                                        chartOfAccounts={this.props.chartOfAccounts}
                                        defaultChart={this.state.defaultChart}
                                        handlePropertyUpdate={this.handlePropertyUpdate}
                                        setRowsAffected={this.setRowsAffected}
                                        rowsAffected={this.state.rowsAffected}
                                    />
                                }
                            </div>
                        </div>
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    return {
        clientId: Number.parseInt(ownProps.params.clientId),
        chartOfAccounts: state.chartOfAccountsManager,
        chartAssignmentFormValues: getFormValues('chartAssignmentForm')(state),
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        reitActions: bindActionCreators(reitActions, dispatch)
    };
}

ChartAssignmentContainer.propTypes = {
    clientId: PropTypes.number,
    calendarYears: PropTypes.array,
    reits: PropTypes.array,
    chartOfAccounts: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: "chartAssignmentForm", enableReinitialize: true })(ChartAssignmentContainer));