import React from 'react';
import { MotifTextLink, MotifCheckbox, MotifIcon } from '@ey-xd/motif-react';
import { Link } from 'react-router';
import { actionIcOpenInNew24px } from '@ey-xd/motif-react/assets/icons';

export default (props) => {
    const checklistType = props.data
        && props.data["checklistType"] != null
        ? props.data["checklistType"].toLowerCase() : null;

    const internalChecklistId = props.data
        && props.data["checklistID"] != null
        ? props.data["checklistID"] : null;

    return (<div>
        {checklistType && internalChecklistId && (checklistType == 'psq' || checklistType == 'arqc') &&
            <div>
            <Link tabIndex="-1" to={`/ext/${checklistType}/${checklistType}Record/${internalChecklistId}`} target="_blank" rel="noopener noreferrer" >
                <MotifTextLink variant="alt" href={`/ext/${checklistType}/${checklistType}Record/${internalChecklistId}`}
                        target="_blank" rel="noopener noreferrer" >
                        <MotifIcon src={actionIcOpenInNew24px} />
                    </MotifTextLink>
                </Link>
            </div>
        }
    </div>);
};