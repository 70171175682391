import PropTypes from "prop-types";
import React from "react";
import { MotifButton, MotifModal, MotifModalHeader, MotifModalBody, MotifModalFooter } from '@ey-xd/motif-react';

const ClosePeriodModal = ({ showClosePeriodModal, handleClosePeriodClick, handleCancel, period, toggleIsAcknowledged, isAcknowledged, pendingTasks, isEngAdminOrSysAdmin }) => {
    let closeQuestion = `Close ${period.reportPeriodDescription} for testing?`;
    let closeStatement = "Reporting and file services will remain accessible.";

    switch (period.reportPeriodStatusDescription) {
        case "Closed to Testing":
            closeQuestion = `Close ${period.reportPeriodDescription} for reporting?`;
            closeStatement = "Reporting and checklist functionality will be closed. File management will remain accessible.";
            break;
        case "Closed to Reporting":
            closeQuestion = `Complete ${period.reportPeriodDescription}?`;
            closeStatement = "Final report will be accessible. Only file downloads will be allowed.";
            break;
    }

    const onYesClick = () => {
        handleClosePeriodClick(period.reportPeriodID, period.reportPeriodStatusDescription);
    };

    return (
        <div>
            {
                pendingTasks === 0 &&
                <MotifModal show={showClosePeriodModal} onClose={handleCancel} variant="alt">
                    <MotifModalHeader>Close Period</MotifModalHeader>
                    <MotifModalBody className="pb-0">
                    <h3>{closeQuestion}</h3>
                        <blockquote className="mb-0">
                            <div>
                                {closeStatement}
                            </div>
                            <br />
                            {(!isEngAdminOrSysAdmin && (<div>
                                <h6>Reversable only by Engagement or System Administrator. <input type="checkbox" id="acknowledgeCheckbox" onChange={toggleIsAcknowledged} /> OK</h6>
                            </div>)) || null}
                        </blockquote>
                    </MotifModalBody>
                    <MotifModalFooter>
                    <MotifButton variant="primary" className="right" type="button"
                            id="closeButton"
                            onClick={onYesClick}
                            data-key={period.reportPeriodID}
                            disabled={!isAcknowledged && !isEngAdminOrSysAdmin}>
                            Yes
                        </MotifButton>
                        <MotifButton variant="secondary" className="right" type="button"
                            onClick={handleCancel}>
                            Cancel
                        </MotifButton>
                    </MotifModalFooter>
                </MotifModal>  || null
            }
        </div>
    );
};

ClosePeriodModal.propTypes = {
    handleClosePeriod: PropTypes.func,
    showClosePeriodModal: PropTypes.bool,
    handleClosePeriodClick: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    period: PropTypes.object.isRequired,
    toggleIsAcknowledged: PropTypes.func.isRequired,
    isAcknowledged: PropTypes.bool,
    pendingTasks: PropTypes.number,
    isEngAdminOrSysAdmin: PropTypes.bool
};

export default ClosePeriodModal;