import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ClientList from "../../components/clients/ClientList";
import * as clientActions from "../../actions/clientActions";
import * as reitActions from "../../actions/reitActions";
import * as authActions from "../../actions/authActions";
import ReactivateClientModal from "../../components/clients/ReactivateClientModal";

/**
 * ClientsPage container component
 * @extends {React.Component}
 */
export class ClientsPage extends React.Component {
    /**
        * Creates a new ClientsPage
        * @constructor
        * @param {Object} props The component properties
        * @param {Object} context The component context
        */
    constructor(props, context) {
        super(props, context);

        this.state = {
            clientSearchText: "", filteredClients: [], showInactive: false,
            showReactivateClientModal: false, clientData: {}, clientsAreLoaded: false,
            currentClientCheckboxHtmlId: ''
        };
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleClearSearchText = this.handleClearSearchText.bind(this);
        this.reloadClients = this.reloadClients.bind(this);
        this.handleShowInactive = this.handleShowInactive.bind(this);
        this.handleToggleActiveFlag = this.handleToggleActiveFlag.bind(this);
        this.handleReactivateClient = this.handleReactivateClient.bind(this);
        this.toggleShowReactivateClientModal = this.toggleShowReactivateClientModal.bind(this);
    }

    /**
     * Invoked immediately after mounting occurs
     * @returns {undefined}
     */
    componentDidMount() {
        this.props.actions.loadAllClients();
        this.setFilter();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.clients !== this.props.clients && nextProps.clients && this.props.clients) {
            const list = Object.assign([], nextProps.clients);
            //this.setState({ filteredClients: list });
            this.setFilter(list);
            this.setState({ clientsAreLoaded: true });
        }
    }

    componentWillUnmount() {
        this.setState({ showInactive: false });
    }

    reloadClients(reloadClients) {
        if (reloadClients) {
            this.props.actions.loadAllClients();
        }
    }

    handleSearchChange(e) {
        this.setState({ clientSearchText: e.target.value }, this.setFilter);
    }

    handleClearSearchText(e) {
        this.setState({ clientSearchText: "" }, this.setFilter);
    }

    handleShowInactive(e) {
        this.setState({ showInactive: !this.state.showInactive }, this.setFilter);
    }

    handleToggleActiveFlag(e, clientId, clientName, clientLegalName) {
        const clientData = { "id": clientId, "name": clientName, "legalName": clientLegalName };
        if (e.target.checked) {
            this.setState({ clientData: clientData, currentClientCheckboxHtmlId: e.target.id }, this.toggleShowReactivateClientModal());

            return;
        }

        this.props.actions.toggleActiveFlagAndReloadClients(clientId);
    }

    setFilter(clients) {
        let includeInactive = false;
        let filteredClients = clients || this.props.clients;

        if (this.props.isSysAdmin) {
            includeInactive = this.state.showInactive;
        }

        if (this.state.clientSearchText === "") {
            filteredClients = filteredClients.filter((client) => includeInactive || client.isActive);
        } else {
            filteredClients = filteredClients.filter((client) => client.name.toLowerCase().includes(this.state.clientSearchText.toLowerCase()) && (includeInactive || client.isActive));
        }

        this.setState({ filteredClients: filteredClients });
    }

    handleReactivateClient(clientId) {
        this.props.actions.toggleActiveFlagAndReloadClients(clientId);
        this.toggleShowReactivateClientModal(null, true); // Passing reActivateClient = true to distinguish that this method is called to reactivate the client
    }

    toggleShowReactivateClientModal(periodId, reActivateClient) {
        if (this.state.currentClientCheckboxHtmlId !== '' && !reActivateClient) { // Client that is being currently activated/deactivated
            document.getElementById(this.state.currentClientCheckboxHtmlId).checked = false; // Resetting the checked attribute to 'False' if the user decides not to activate the Client
            this.setState({ currentClientCheckboxHtmlId: '' }); // Resetting the state of currentClientCheckboxHtmlId once the user decides not to activate the Client
        } else if (reActivateClient) {
            this.setState({ currentClientCheckboxHtmlId: '' }); // Resetting the state of currentClientCheckboxHtmlId once the user has rectivated the client
        }

        this.setState({ showReactivateClientModal: !this.state.showReactivateClientModal });
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        return (
            <React.Fragment>
                <ClientList clients={this.state.filteredClients}
                    handleSearchChange={this.handleSearchChange}
                    handleClearSearchText={this.handleClearSearchText}
                    clientSearchText={this.state.clientSearchText}
                    handleShowInactive={this.handleShowInactive}
                    showInactive={this.state.showInactive}
                    isSysAdmin={this.props.isSysAdmin}
                    handleToggleActiveFlag={this.handleToggleActiveFlag}
                    clientsAreLoaded={this.state.clientsAreLoaded}
                />
                <ReactivateClientModal showReactivateClientModal={this.state.showReactivateClientModal} toggleShowReactivateClientModal={this.toggleShowReactivateClientModal} handleReactivateClient={this.handleReactivateClient} handleCancel={this.toggleShowReactivateClientModal} clientData={this.state.clientData} />
            </React.Fragment>
        );
    }
}

ClientsPage.propTypes = {
    clients: PropTypes.array.isRequired,
    actions: PropTypes.object,
    currentUserAuthorizations: PropTypes.array,
    isSysAdmin: PropTypes.bool
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state) {
    const clients = state.clients;
    return {
        clients: clients,
        currentUserAuthorizations: state.currentUserAuthorizations,
        isSysAdmin: Array.isArray(state.currentUserAuthorizations) && authActions.isSystemAdministrator(state.currentUserAuthorizations)
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(clientActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientsPage);