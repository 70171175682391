import PropTypes from "prop-types";
import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { render } from 'react-dom';
import { Form, Icon, Popup, Dropdown } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import * as formValidators from "../../scripts/formValidators";
import { MotifPagination, MotifPaginationSelect, MotifPaginationSelectItem } from "@ey-xd/motif-react";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import ExternalChecklistCheckboxCellRenderer from './customRenderers/ExternalChecklistCheckboxCellRenderer.js';
import ExternalChecklistCheckboxHeaderRenderer from './customRenderers/ExternalChecklistCheckboxHeaderRenderer.js';
import ReopentoRequestStatusValueGetter from './customValueGetters/ReopentoRequestStatusValueGetter.js';
import DateComparator from './customComparators/DateComparator.js';
import CustomHeaderTemplate from './customHeaders/CustomHeaderTemplate.js';
import ExternalChecklistLinkCellRenderer from './customRenderers/ExternalChecklistLinkCellRenderer';

const ARQCChecklistExternalTable = ({
    formSyncErrors,
    formSubmitErrors,
    hasWriteAccess,
    filteredExternalChecklists,
    clientId,
    selectedClient,
    gridColumnState,
    handleGridColumnStateChange,
    handleGridChanges,
    refreshGridOnCompletedAction
}) => {
    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);

    const [rowData, setRowData] = useState([]);
    const rowDataRef = useRef({});
    rowDataRef.current = rowData;

    const onHeaderCheckboxChange = (isHeaderCheckboxSelected) => {
        let updatedData = rowDataRef.current;
        if (gridRef && gridRef.current && gridRef.current.api) {

            // Select checkbox for filtered rows only
            gridRef.current.api.forEachNodeAfterFilter((rowNode) => {
                // Check if the parent rowNode's key matches with grid's rowdata and update checkbox selection for those matching
                if (rowNode.level === 0) {
                    updatedData = rowDataRef.current.map(newUd => {
                        if (newUd.checklistID === rowNode.data.checklistID) {
                            newUd.checklistSelection = !isHeaderCheckboxSelected;
                        }
                        return newUd;
                    });
                }
            });
            setRowData(updatedData);
            gridRef.current.api.redrawRows();
            gridRef.current.api.refreshHeader();
        }
    }

    const onCellCheckboxChange = (checklistId, rowNode) => {
        let updatedData = rowDataRef.current.map(newUd => {

            if (newUd.checklistID == checklistId) {
                newUd.checklistSelection = !newUd.checklistSelection;
            }
            return newUd;
        });

        setRowData(updatedData);
        gridRef.current.api.redrawRows(rowNode);
        gridRef.current.api.refreshHeader();
    }

    //Default column settings
    const defaultColDef = useMemo(() => {
        return {
            flex: 1,
            minWidth: 65,
            filter: true
        };
    }, []);

    const dateOptions = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    };

    const getColumnDefs = () => {
        return [
            {
                field: 'checklistSelection', headerName: '', suppressMenu: true, maxWidth: 65,
                headerComponent: ExternalChecklistCheckboxHeaderRenderer,
                headerComponentParams: { onHeaderCheckboxChange: onHeaderCheckboxChange },
                cellRendererParams: { onCellCheckboxChange: onCellCheckboxChange },
                cellRenderer: ExternalChecklistCheckboxCellRenderer
            },
            {
                field: 'checklistLink', headerName: '', suppressMenu: true, maxWidth: 54,
                cellRenderer: ExternalChecklistLinkCellRenderer, cellRendererParams: { clientId: clientId },
                headerComponentParams: { template: CustomHeaderTemplate('checklistLink') }
            },
            {
                field: 'quarter', headerName: 'Period', sortable: true, showRowGroup: false, rowGroup: false, minWidth: 103,
                valueGetter: params => {
                    return params.node && params.node.data
                        ? params.node.data['quarter'] : '';
                },
                filterValueGetter: params => { return params.data && params.data.quarter; },
                headerComponentParams: { template: CustomHeaderTemplate('period') }
            },
            {
                field: 'reitName', headerName: 'REIT', sortable: true, showRowGroup: false, hide: false, rowGroup: false, minWidth: 253,
                valueGetter: params => {
                    return params.node && params.node.data
                        ? params.node.data['reitName'] : '';
                },
                filterValueGetter: params => { return params.data && params.data.reitName; },
                headerComponentParams: { template: CustomHeaderTemplate('reit') }
            },
            {
                field: 'reitTypeDescription', headerName: 'REIT Type', sortable: true, minWidth: 223,
                valueGetter: params => {
                    return params.node && params.node.data
                        ? params.node.data['reitTypeDescription'] : '';
                },
                filterValueGetter: params => { return params.data && params.data.reitTypeDescription; },
                headerComponentParams: { template: CustomHeaderTemplate('type') }
            },
            {
                field: 'checklistStateDescription', headerName: 'Status', sortable: true, showRowGroup: false, hide: false, rowGroup: false, minWidth: 233,
                valueGetter: params => {
                    return params.node && params.node.data
                        ? params.node.data['checklistStateDescription'] : '';
                },
                filterValueGetter: params => { return params.data && params.data.checklistStateDescription; },
                headerComponentParams: { template: CustomHeaderTemplate('status') }
            },
            {
                field: 'lastUpdatedDate', headerName: 'Status Date', sortable: true, minWidth: 203,
                valueGetter: params => {
                    return params.node && params.node.data
                        ? params.node.data["lastUpdatedDate"] ? (new Date(params.node.data["lastUpdatedDate"] + 'Z')).toLocaleString('en-US', dateOptions) : ''
                        : null;
                },
                filterValueGetter: params => { return params.data && (new Date(params.data.lastUpdatedDate + 'Z')).toLocaleString('en-US', dateOptions); },
                comparator: DateComparator,
                headerComponentParams: { template: CustomHeaderTemplate('statusDate') }
            },
            {
                field: 'reopenRequestStatusDescription', headerName: 'Reopen Request', sortable: true, minWidth: 203,
                valueGetter: params => ReopentoRequestStatusValueGetter(params),
                cellRenderer: params => {
                    return params.data && params.data.reopenRequestStatusDescription
                        ? params.data.reopenRequestStatusDescription.toLowerCase() === 'pending' ? 'Pending EY Action' : params.data.reopenRequestStatusDescription
                        : 'N/A';
                },
                filterValueGetter: params => {
                    return params.data && params.data.reopenRequestStatusDescription
                        ? params.data.reopenRequestStatusDescription.toLowerCase() === 'pending' ? 'Pending EY Action' : params.data.reopenRequestStatusDescription
                        : 'N/A';
                },
                headerComponentParams: { template: CustomHeaderTemplate('reopenRequestStatusDescription') }
            },
            {
                field: 'requestDateTime', headerName: 'Reopen Request Date', sortable: true, minWidth: 203,
                valueGetter: params => {
                    return params.node && params.node.data
                        ? params.node.data["requestDateTime"] ? (new Date(params.node.data["requestDateTime"] + 'Z')).toLocaleString('en-US', dateOptions) : ''
                        : null;
                },
                filterValueGetter: params => {
                    return params.data
                        ? params.data["requestDateTime"] ? (new Date(params.data.requestDateTime + 'Z')).toLocaleString('en-US', dateOptions) : 'N/A'
                        : null;
                },
                cellRenderer: params => {
                    return params.data && params.data.requestDateTime ? (new Date(params.data.requestDateTime + 'Z')).toLocaleString('en-US', dateOptions) : 'N/A';
                },
                comparator: DateComparator,
                headerComponentParams: { template: CustomHeaderTemplate('requestDateTime') }
            },
            {
                field: 'clientUserEmailAddress', headerName: 'Client Signedoff By', sortable: true, minWidth: 172,
                valueGetter: params => {
                    return params.node && params.node.data && params.node.data.isSignedOff
                        ? params.node.data.clientUserEmailAddress : '';
                },
                cellRenderer: params => {
                    return params.data && params.data.isSignedOff ? params.data.clientUserEmailAddress : '';
                },
                filterValueGetter: params => {
                    return params.data && params.data.isSignedOff ? params.data.clientUserEmailAddress : '';
                },

                headerComponentParams: { template: CustomHeaderTemplate('clientUserEmailAddress') }
            },
            {
                field: 'userActionDateTime', headerName: 'Client Signedoff Date', sortable: true, minWidth: 200,
                valueGetter: params => {
                    return params.node && params.node.data && params.node.data.isSignedOff
                        ? params.node.data.userActionDateTime ? (new Date(params.node.data.userActionDateTime + 'Z')).toLocaleString('en-US', dateOptions) : ''
                        : null;
                },
                filterValueGetter: params => {
                    return params.data && params.data.isSignedOff
                        ? params.data.userActionDateTime ? (new Date(params.data.userActionDateTime + 'Z')).toLocaleString('en-US', dateOptions) : 'N/A'
                        : null;
                },
                cellRenderer: params => {
                    return params.data && params.data.isSignedOff && params.data.userActionDateTime ? (new Date(params.data.userActionDateTime + 'Z')).toLocaleString('en-US', dateOptions) : '';
                },
                comparator: DateComparator,
                headerComponentParams: { template: CustomHeaderTemplate('userActionDateTime') }
            }
        ];
    };

    const [columnDefs, setColumnDefs] = useState(getColumnDefs());
    //const getRowId = useCallback((params) => params.data.recipients + '_' + params.data.recipientEmail, []);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(100);

    //Grid Global Listner
    const gridGlobalListner = function (type, event) {
        if (type.indexOf("columnVisible") >= 0) {
            handleGridColumnsChanged(event);
        }
    }

    //Handle Grid Ready event
    const handleGridReady = (event) => {
        //Remove event for column state change
        event.api.removeEventListener(gridGlobalListner);

        //Add event for column state change
        event.api.addGlobalListener(gridGlobalListner);

        //Apply column state
        var columnState = localStorage.getItem('REITSuiteExternalARQCChecklistColumnState');
        if (columnState) {
            gridRef.current.columnApi.applyColumnState({
                state: JSON.parse(columnState),
                applyOrder: true,
            });
        }

        // Set the page size and total pages on the first load
        // Setting it 100 to make sure the default number of items on load is always set to 100
        handleItemsPerPageChange(100);
    }

    // Handle OnFilterChanged event
    const handleFilterChanged = (event) => {
        if (gridRef && gridRef.current && gridRef.current.api) {
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);
            if (currentPage > calcTotalPages) {
                if (calcTotalPages == 0) {
                    setTimeout(function () { setCurrentPage(calcTotalPages); }, 0)
                }
                else {
                    setCurrentPage(calcTotalPages);
                }
            }
            const gridCurrentPage = gridRef.current.api.paginationGetCurrentPage() + 1; // Adding 1 since it returns zero based index
            if (currentPage < (gridCurrentPage) && calcTotalPages != 0) {
                setCurrentPage(gridCurrentPage);
            }
        }
    }

    //Handle First Data Renderered event
    const handleFirstDataRendered = (event) => {
        gridRef.current.columnApi.autoSizeAllColumns();
    }

    //Handle Grid Columns changed event
    const handleGridColumnsChanged = (event) => {

        var currentColumnState = gridRef.current.columnApi.getColumnState();
        var localStorageColumnState = localStorage.getItem('REITSuiteExternalARQCChecklistColumnState');

        // If the column state details are not exists in local storage then save the changed Column state data
        if (currentColumnState && currentColumnState.filter(col => col.hide == true)?.length > 0 && !localStorageColumnState) {
            saveUserPreferenceDetails();
        }
        else if (localStorageColumnState) {
            // If the column state details are exists in local storage then save the changed Column state data only if difference is found
            var columnsStateArray = JSON.parse(localStorageColumnState);
            if (columnsStateArray && columnsStateArray.length > 0
                && currentColumnState && currentColumnState.length > 0
                && columnsStateArray.filter(col => col.hide == true)?.length != currentColumnState.filter(col => col.hide == true)?.length) {
                //If column state mismatch found then only save column state data to avoid saving same state
                saveUserPreferenceDetails();
            }
        }

        //Trigger Grid Column state change (of parent) to sync column state data
        handleGridColumnStateChange(currentColumnState);

        gridRef && gridRef.current && gridRef.current.columnApi && gridRef.current.columnApi.autoSizeAllColumns();
    }

    //Save User Preference details (Column state)
    const saveUserPreferenceDetails = useCallback(() => {

        var columnState = gridRef.current.columnApi.getColumnState();
        if (columnState) {
            localStorage.setItem('REITSuiteExternalARQCChecklistColumnState', JSON.stringify(columnState));
        }
    }, []);

    //Handle Grid Items per Page change event
    const handleItemsPerPageChange = (val) => {

        gridRef.current.api.paginationGoToFirstPage();
        gridRef.current.api.paginationSetPageSize(Number(val));
        setCurrentPage(1);
        setItemsPerPage(val);
        // Get total pages from Grid's default pagination control and apply it to custom pagination control
        if (gridRef && gridRef.current && gridRef.current.api) {
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);
            //If total page is zero then set the current page as zero
            if (calcTotalPages == 0) {
                setCurrentPage(calcTotalPages);
            }
        }
    };

    //Handle Grid Page change event
    const handlePageChange = (val) => {
        setCurrentPage(val);
        gridRef.current.api.paginationGoToPage(val - 1);
    };

    useEffect(() => {
        handleGridChanges(rowData, gridRef && gridRef.current ? gridRef.current.api : null);

    }, [rowData]);

    useEffect(() => {

        // Refresh Grid         
        const gridApi = gridRef && gridRef.current && gridRef.current.api;
        if (gridApi) {
            gridApi.refreshCells();
            gridApi.deselectAll();
        }

    }, [refreshGridOnCompletedAction]);

    // Handle Row data updated event 
    const handleRowDataUpdated = () => {
        if (gridRef && gridRef.current && gridRef.current.columnApi) {
            gridRef.current.columnApi.autoSizeAllColumns();
        }
    }

    useEffect(() => {

        setRowData(filteredExternalChecklists);

        if (gridRef && gridRef.current && gridRef.current.api) {
            gridRef.current.api.refreshHeader();
            // Get total pages from Grid's default pagination control and apply it to custom pagination control
            let calcTotalPages = gridRef.current.api.paginationGetTotalPages();
            setTotalPages(calcTotalPages);
        }

        const gridApi = gridRef?.current?.api;
        gridApi && gridApi.onFilterChanged();
    }, [filteredExternalChecklists]);


    const handleSelectionChanged = (event) => { }

    return <div className="row mb-3">
        {filteredExternalChecklists && filteredExternalChecklists.length > 0 &&
            <div className="col mt-3">
                <div className="ag-theme-alpine" style={{ height: 450 }}>
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        accentedSort={true}
                        rowSelection="multiple"
                        suppressRowClickSelection={true}
                        ref={gridRef}
                        pagination={true}
                        suppressPaginationPanel={true}
                        paginationPageSize="100"
                        groupDisplayType={'custom'}
                        groupSelectsChildren={true}
                        groupDefaultExpanded={0}
                        onFirstDataRendered={handleFirstDataRendered}
                        onSelectionChanged={handleSelectionChanged}
                        onGridReady={handleGridReady}
                        onFilterChanged={handleFilterChanged}
                        onRowDataUpdated={handleRowDataUpdated}
                        data-testid="externalChecklistGrid"
                    >
                    </AgGridReact>
                </div>
                <div className="mt-3 mr-4 right">
                    <MotifPagination currentPage={currentPage} onPageChange={handlePageChange} min={1} max={totalPages}>
                        <MotifPaginationSelect>
                            <MotifPaginationSelectItem onClick={() => handleItemsPerPageChange(50)}>
                                Show 50
                            </MotifPaginationSelectItem>
                            <MotifPaginationSelectItem selected onClick={() => handleItemsPerPageChange(100)}>
                                Show 100
                            </MotifPaginationSelectItem>
                            <MotifPaginationSelectItem onClick={() => handleItemsPerPageChange(150)}>
                                Show 150
                            </MotifPaginationSelectItem>
                            <MotifPaginationSelectItem onClick={() => handleItemsPerPageChange(200)}>
                                Show 200
                            </MotifPaginationSelectItem>
                        </MotifPaginationSelect>
                    </MotifPagination>
                    <div style={{ minHeight: '100px' }}></div>
                </div>
            </div>
        }
    </div>;
};

ARQCChecklistExternalTable.propTypes = {

};

export default reduxForm({ form: "arqcChecklistExternal" })(ARQCChecklistExternalTable);