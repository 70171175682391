import React from 'react';
import PropTypes from 'prop-types';
import { Header } from 'semantic-ui-react';
import ReactModal from 'react-modal';
import { MotifButton } from '@ey-xd/motif-react';

const DeleteCustomQuestionModal = ({
  showDeleteCustomQuestionModal,
  handleYes,
  toggleShowDeleteCustomQuestionModal,
}) => {
  return (
    <ReactModal
      contentLabel="Delete Custom Question"
      isOpen={showDeleteCustomQuestionModal}
      className="react-modal react-modal-small"
      overlayClassName="react-modal-overlay"
    >
      <Header content="Delete Custom Question" />
      <form>
        <h6>Are you sure you want to delete this custom question?</h6>
        <br />
        <MotifButton
          variant="primary"
          className="right ml-2"
          type="button"
          onClick={handleYes}
        >
          Yes
        </MotifButton>
        <MotifButton
          variant="secondary"
          className="right"
          type="button"
          onClick={toggleShowDeleteCustomQuestionModal}
        >
          No
        </MotifButton>
      </form>
    </ReactModal>
  );
};

DeleteCustomQuestionModal.propTypes = {
  showDeleteCustomQuestionModal: PropTypes.bool,
  handleYes: PropTypes.func,
  toggleShowDeleteCustomQuestionModal: PropTypes.func,
};

export default DeleteCustomQuestionModal;
