import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from "../../actions/incomeTestActions";
import * as propertyActions from "../../actions/propertyActions";
import * as varianceActions from "../../actions/varianceActions";
import * as periodActions from "../../actions/periodActions";
import TestSummary from "../../components/reits/TestSummary";
import IncomeDetails from "../../components/tb/IncomeDetails";

/**
 * IncomeTestPage container component
 * @extends {React.Component}
 */
export class IncomeTestPage extends React.Component {
    /**
      * Creates a new AssetTestPage
      * @constructor
      * @param {Object} props The component properties
      * @param {Object} context The component context
      */
    constructor(props, context) {
        super(props, context);

        this.state = { showDetails: false, tbMode: 1 };
        this.showDetails = this.showDetails.bind(this);

    }

    /**
     * Invoked immediately after mounting occurs
     * @returns {undefined}
     */
    componentDidMount() {
        this.props.actions.fetchIncomeTestSummary(this.props.periodId);
        this.props.actions.fetchPropertyDetails(this.props.periodId);
        this.props.actions.fetchPropertyItsi(this.props.periodId);
        this.props.varianceActions.fetchVarianceDetails(this.props.periodId);
        this.props.propertyActions.fetchPropertiesByPeriod(this.props.periodId);
        periodActions.fastFetchPeriodById(this.props.periodId).then((period) => {
            if(period && period.trialBalanceModeID === 2) {
                this.setState({ tbMode: 2 });
            }
        });
    }

    showDetails() {
        this.setState({ showDetails: !this.state.showDetails });
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        if (this.props.incomeTestSummary.tests && this.props.incomeTestSummary.tests.length === 0) {
            return (
                <div>
                    <p>Please upload trial balance for the period to view results.</p>
                </div>
            );
        }
        return (
            <div>
                <TestSummary testSummary={this.props.incomeTestSummary} testHeader="Income Test Summary" showDetails={this.state.showDetails} handleShowDetails={this.showDetails} varianceDetails={this.props.varianceDetails} />
                {this.state.showDetails ? <IncomeDetails incomeDetails={this.props.incomeDetails} propertyDetails={this.props.propertyDetails} propertyItsi={this.props.propertyItsi} properties={this.props.properties} tbMode={this.state.tbMode} /> : null}
            </div>
        );
    }
}

const clientAccountDataShape = PropTypes.shape({
    balance: PropTypes.number,
    clientAccountDescription: PropTypes.string,
    clientAccountNumber: PropTypes.string,
    internalAccountCode: PropTypes.string,
    internalAccountDescription: PropTypes.string,
    type: PropTypes.number
});

IncomeTestPage.propTypes = {
    reitId: PropTypes.number.isRequired,
    periodId: PropTypes.number.isRequired,
    incomeTestSummary: PropTypes.shape({
        tests: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string,
            isPassing: PropTypes.bool,
            percent: PropTypes.number
        }))
    }),
    incomeDetails: PropTypes.array,
    propertyDetails: PropTypes.object,
    propertyItsi: PropTypes.object,
    varianceDetails: PropTypes.shape({
        addedAccounts: PropTypes.arrayOf(clientAccountDataShape),
        modifiedAccounts: PropTypes.arrayOf(PropTypes.shape({
            after: clientAccountDataShape,
            balanceDelta: PropTypes.number,
            balanceDeltaPercentage: PropTypes.number,
            before: clientAccountDataShape
        }).isRequired),
        removedAccounts: PropTypes.arrayOf(clientAccountDataShape).isRequired
    }),
    actions: PropTypes.object,
    varianceActions: PropTypes.object,
    properties: PropTypes.array,
    propertyActions: PropTypes.object.isRequired
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    return {
        reitId: Number.parseInt(ownProps.params.reitId),
        periodId: Number.parseInt(ownProps.params.periodId),
        incomeTestSummary: state.incomeTestSummary,
        incomeDetails: state.incomeDetails,
        varianceDetails: state.varianceDetails,
        propertyDetails: state.propertyDetails,
        propertyItsi: state.propertyItsi,
        properties: state.properties
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        propertyActions: bindActionCreators(propertyActions, dispatch),
        varianceActions: bindActionCreators(varianceActions, dispatch)        
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(IncomeTestPage);