import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SubmissionError } from 'redux-form';
import * as trsActions from '../../actions/trsActions';
import * as PeriodActions from "../../actions/periodActions";
import * as commonActions from "../../actions/commonActions";
import EditTrsComponent from '../../components/reits/EditTrsComponent';
import * as errorCodes from '../../constants/errorCodes';
import * as actionHelpers from "../../scripts/actionHelpers";
import * as formHelpers from "../../scripts/formHelpers";
import * as reitActions from "../../actions/reitActions";

/**
 * EditTrsContainer container component
 * @extends {React.Component}
 */
export class EditTrsContainer extends React.Component {
    /**
     * Creates a new EditTrsContainer
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);

        this.state = {
            showForm: false,
            formPurpose: "",
            canEdit: true,
            selectedTrs: {
                "taxableREITSubsidiaryID": 0,
                "trsDebt": 0.00,
                "trsEquity": 0.00,
                "trsName": "",
                "reportPeriodID": 0,
                "trsValue": 0.00
            } };
        this.closeForm = this.closeForm.bind(this);
        this.showForm = this.showForm.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.handleEditButtonClick = this.handleEditButtonClick.bind(this);
        this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
        this.handleDismissErrorMessage = this.handleDismissErrorMessage.bind(this);
    }

    /**
     * Invoked immediately after mounting occurs
     * @returns {undefined}
     */
    componentDidMount() {
        this.refresh();
    }

    /**
     * Invoked before a mounted component receives new props.
     * @param {Object} nextProps The properties that the component is receiving
     */
    componentWillReceiveProps(nextProps) {
        if (nextProps.period !== this.props.period) {
            this.setCanEdit(nextProps.period);
        }
    }

    handleDismissErrorMessage() {
        this.props.commonActions.clearFormErrors('trs');
    }

    refresh() {
        // Check if state.period is null, if it is populate period
        if (!this.props.period) {
            this.props.periodActions.fetchPeriodById(this.props.periodId);
        }
        else {
            this.setCanEdit(this.props.period);
        }

        return this.props.actions.fetchTrsByPeriod(this.props.periodId);
    }

    setCanEdit(period) {
        const isEnabled = PeriodActions.isTestingEnabled(period);
        this.setState({ canEdit: isEnabled });
    }

    submitForm(values) {
        if (!values.trsName) {
            throw new SubmissionError({ name: "Name is required" });
        } else {
            switch (this.state.formPurpose) {
                case "EDIT":
                    {
                        this.props.commonActions.beginTask();
                        const trs = {
                            "taxableREITSubsidiaryID": values.taxableREITSubsidiaryID,
                            "trsDebt": values.trsDebt,
                            "trsEquity": values.trsEquity,
                            "trsName": values.trsName,
                            "reportPeriodID": this.props.periodId,
                            "trsValue": values.trsValue
                        };
                        return trsActions.updateTrs(trs).then(data => {
                            if (actionHelpers.isErrorResponse(data)) {
                                this.props.commonActions.endTask();
                                return formHelpers.generateSubmissionError(data);
                            }

                            this.closeForm();
                            this.props.commonActions.endTask();
                            this.refresh();
                        }).catch(error => {
                            if (error instanceof SubmissionError) {
                                this.props.commonActions.endTask();
                                throw error;
                            }

                            this.props.commonActions.endTask();
                            formHelpers.generateSubmissionError();
                        });
                    }
                case "DELETE":
                    {
                        this.props.commonActions.beginTask();
                        const trs = {
                            "taxableREITSubsidiaryID": values.taxableREITSubsidiaryID,
                            "trsDebt": values.trsDebt,
                            "trsEquity": values.trsEquity,
                            "trsName": values.trsName,
                            "reportPeriodID": this.props.periodId,
                            "trsValue": values.trsValue
                        };
                        return trsActions.deleteTrs(trs).then(data => {
                            if (actionHelpers.isErrorResponse(data)) {
                                this.props.commonActions.endTask();
                                return formHelpers.generateSubmissionError(data);
                            }

                            this.closeForm();
                            this.props.commonActions.endTask();
                            this.refresh();
                        }).catch(error => {
                            if (error instanceof SubmissionError) {
                                this.props.commonActions.endTask();
                                throw error;
                            }

                            this.props.commonActions.endTask();
                            formHelpers.generateSubmissionError();
                        });
                    }
                default:
                    {
                        this.props.commonActions.beginTask();
                        const trs = {
                            "trsDebt": values.trsDebt,
                            "trsEquity": values.trsEquity,
                            "trsName": values.trsName,
                            "reportPeriodID": this.props.periodId,
                            "trsValue": values.trsValue
                        };

                        const self = this;
                        return trsActions.createTrs(trs).then(data => {
                            if (actionHelpers.isErrorResponse(data)) {
                                this.props.commonActions.endTask();
                                return formHelpers.generateSubmissionError(data);
                            }

                            this.props.reitActions.fetchReit(this.props.reitId);
                            this.closeForm();
                            this.props.commonActions.endTask();
                            this.refresh();
                        }).catch(error => {
                            if (error instanceof SubmissionError) {
                                this.props.commonActions.endTask();
                                throw error;
                            }

                            this.props.commonActions.endTask();
                            formHelpers.generateSubmissionError();
                        });
                    }
            }
        }
    }

    showForm(e) {
        this.setState({ showForm: true, formPurpose: "", selectedTrs: null });
    }

    closeForm() {
        this.setState({ showForm: false });
    }

    handleEditButtonClick(value) {
        this.setState({
            showForm: true, formPurpose: "EDIT", selectedTrs: {
                "taxableREITSubsidiaryID": value.taxableREITSubsidiaryID,
                "trsDebt": value.trsDebt ? value.trsDebt : "0",
                "trsEquity": value.trsEquity ? value.trsEquity : "0",
                "trsName": value.trsName,
                "reportPeriodID": value.reportPeriodID,
                "trsValue": value.trsValue ? value.trsValue : "0"
            }
        });
    }

    handleDeleteButtonClick(value) {
        this.setState({
            showForm: true, formPurpose: "DELETE", selectedTrs: {
                "taxableREITSubsidiaryID": value.taxableREITSubsidiaryID,
                "trsDebt": value.trsDebt ? value.trsDebt : "0",
                "trsEquity": value.trsEquity ? value.trsEquity : "0",
                "trsName": value.trsName,
                "reportPeriodID": value.reportPeriodID,
                "trsValue": value.trsValue ? value.trsValue : "0"
            }
        });
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        return (
            <EditTrsComponent
                taxableReitSubsidiaries={this.props.taxableReitSubsidiaries}
                showForm={this.state.showForm}
                handleCloseForm={this.closeForm}
                handleNewButtonClick={this.showForm}
                handleSubmit={this.submitForm}
                formPurpose={this.state.formPurpose}
                selectedTrs={this.state.selectedTrs}
                handleEditButtonClick={this.handleEditButtonClick}
                handleDeleteButtonClick={this.handleDeleteButtonClick}
                canEdit={this.state.canEdit}
                handleDismissErrorMessage={this.handleDismissErrorMessage}/>
        );
    }
}

EditTrsContainer.propTypes = {
    reitId: PropTypes.number.isRequired,
    clientId: PropTypes.number.isRequired,
    periodId: PropTypes.number.isRequired,
    actions: PropTypes.object.isRequired,
    periodActions: PropTypes.object.isRequired,
    reitActions: PropTypes.object,
    period: PropTypes.object.isRequired,
    taxableReitSubsidiaries: PropTypes.arrayOf(PropTypes.shape({
        taxableREITSubsidiaryID: PropTypes.number,
        reportPeriodID: PropTypes.number,
        trsName: PropTypes.string,
        trsValue: PropTypes.number,
        trsDebt: PropTypes.number,
        trsEquity: PropTypes.number
    })),
    commonActions: PropTypes.object.isRequired
};

EditTrsContainer.contextTypes = {
    router: PropTypes.object
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    return {
        reitId: Number.parseInt(ownProps.params.reitId),
        clientId: Number.parseInt(ownProps.params.clientId),
        periodId: Number.parseInt(ownProps.params.periodId),
        taxableReitSubsidiaries: state.taxableReitSubsidiaries,
        period: state.period
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(trsActions, dispatch),
        periodActions: bindActionCreators(PeriodActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
        reitActions: bindActionCreators(reitActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditTrsContainer);