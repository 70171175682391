import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Icon, Progress, Button, Header } from 'semantic-ui-react';
import ReactModal from "react-modal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Tabs } from '../shared/Tabs';
import { Tab } from '../shared/Tab';
import { relativeDate, getDateTime } from "../../scripts/dateHelpers";
import * as checklistActions from "../../actions/checklistActions";
import ChecklistFileUploadModal from './ChecklistFileUploadModal';
import * as formValidators from "../../scripts/formValidators";
import * as actions from '../../actions/actionTypes';
import { MotifButton, MotifIconButton, MotifIcon } from "@ey-xd/motif-react";
import { contentIcContentCopy24px, contentIcContentPaste24px, imageIcEdit24px, actionIcDelete24px, fileIcFileDownload24px, actionIcInfo24px } from '@ey-xd/motif-react/assets/icons';
import DeleteChecklistDetailGridRowModal from '../../components/reits/DeleteChecklistDetailGridRowModal';
/**
 * Component used for previous answers, comments, and file attachments for a particular question
 */
export class ChecklistQuestionTabs extends React.Component {
    /**
     * Creates a new ChecklistQuestionTabs component
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);

        this.state = {
            comments: [],
            attachments: []
        };
        this.setLastClickedQuestionId = this.setLastClickedQuestionId.bind(this);
    }

    setLastClickedQuestionId(questionId) {
        this.props.dispatch({ type: actions.LAST_CLICKED_TAB_CHECKLIST_QUESTION_ID, questionId });
    }

    render() {
        const {
            handleEditComment,
            handleSaveComment,
            handleDeleteComment,
            handleDeleteAttachment,
            handleDownloadChecklistFile,
            previousAnswers,
            previousChecklistFound,
            isArqc,
            isExternal,
            item,
            checklistFiles,
            checklistId,
            user,
            canEdit,
            externalEmail,
            siblingQuestionIds,
            hidePreviousAnswers,
            authenticationScope,
            autoOpenFileTab,
            prePopulateAnswerOptionID,
            isDisabledTab,
            questionComments
        } = this.props;

        const {
            comments,
            attachments,
            showDeleteChecklistGridRowModal=false,
        } = this.state;

        let userId = user;
        if (userId) {
            userId = userId.toLowerCase();
        }
   

        /* If previous checklist exists and question is not answered then show blank */
        const prevAnswer = (
            (Array.isArray(previousAnswers) && previousAnswers.filter(Boolean).join(", ")) ||
            (previousAnswers !== undefined && previousAnswers !== null && Array.isArray(previousAnswers) === false && previousAnswers.length > 0 && previousAnswers) ||
            (previousChecklistFound && " ") ||
            (!isArqc && "Prior PSQ for Property Not Available") || (isArqc && "Prior ARQC Not Available")
        );

        /* Keep the previous answers tab as blank when prePopulateAnswerOptionID is selected as Questions unaswered (i.e. 2) */
        const prevAnswerTab = hidePreviousAnswers ?
            <React.Fragment /> :
            <Tab iconName="history" iconSize="small" hoverText="Previous Answer" text="">
                <div className="mt-2">
                    {prePopulateAnswerOptionID === 2 ? " " : prevAnswer}
                </div>
            </Tab>;
       const filteredComments= Array.isArray(questionComments) ? questionComments.filter(f => f.checklistSectionQuestionID == item.checklistSectionQuestionID) : [];

        const commentsCount = filteredComments ? filteredComments.length : 0;

        // Init comments for this questions
        if (!comments[`id_${item.checklistSectionQuestionID}`]) {
            comments[`id_${item.checklistSectionQuestionID}`] = {};
        }

        const itemComments = comments[`id_${item.checklistSectionQuestionID}`];

        // Init attachments for this question
        if (!attachments[`id_${item.checklistSectionQuestionID}`]) {
            attachments[`id_${item.checklistSectionQuestionID}`] = {};
        }

        const itemAttachments = attachments[`id_${item.checklistSectionQuestionID}`];

        // Filter checklistFiles to only those related to this questionId
        const filteredChecklistFiles = Array.isArray(checklistFiles) ? checklistFiles.filter(f => f.questionID == item.checklistSectionQuestionID) : [];

        const attachmentsCount = filteredChecklistFiles ? filteredChecklistFiles.length : 0;

        const handleCloseChecklistFileUploadModal = () => {
            itemAttachments.isChecklistFileUploadModalOpen = false;
            this.setState({ attachments: Object.assign({}, attachments) });
        };        
        const handleOpenChecklistFileUploadModal = () => {
            itemAttachments.isChecklistFileUploadModalOpen = true;
            this.setState({ attachments: Object.assign({}, attachments) });
        };

     
        const handleConfirmDeleteComment = () => {
            handleDeleteComment(item.checklistSectionQuestionID, itemComments.deleteCommentId, isExternal)
                .then(() => {
                    toggleShowChecklistGridRowDelete();
                }).catch((error) => {
                    this.toggleShowChecklistGridRowDelete();
                  });
        };

        const handleCancelDeleteAttachment = () => {
            itemAttachments.isDeleteAttachmentModalOpen = false;
            this.setState({ attachments: Object.assign({}, attachments) });
        };
        const handleConfirmDeleteAttachment = () => {
            handleDeleteAttachment(itemAttachments.deleteAttachmentId, isExternal)
                .then(() => {
                    itemAttachments.isDeleteAttachmentModalOpen = false;
                    this.setState({ attachments: Object.assign({}, attachments) });
                });
        };

        const  toggleShowChecklistGridRowDelete = ()=> {
            const showToggle = !this.state.showDeleteChecklistGridRowModal;
            this.setState({
            showDeleteChecklistGridRowModal: showToggle,
            });
        }

        const deleteModalStyle = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '350px',
                height: '180px'
            }
        };

      

        const deleteAttachmentModal = itemAttachments.isDeleteAttachmentModalOpen ? <ReactModal
            isOpen={true}
            contentLabel="Delete Attachment"
            className="react-modal"
            style={deleteModalStyle}
            overlayClassName="react-modal-overlay"
        >
            <h3>Delete Attachment</h3>
            <h5>Are you sure?</h5>
            <div style={{ marginTop: '20px' }}>
                <MotifButton variant="primary" className="right ml-2" type="button"
                    onClick={() => handleConfirmDeleteAttachment(itemAttachments.deleteAttachmentId)}>
                    Yes
                </MotifButton>
                <MotifButton variant="secondary" className="right" type="button"
                    onClick={handleCancelDeleteAttachment}>
                    No
                </MotifButton>
            </div>
        </ReactModal> : <React.Fragment />;

        const uploadFileModal = 
            <ChecklistFileUploadModal
                checklistId={checklistId}
                questionId={item.checklistSectionQuestionID}
                isArqc={isArqc}
                hideChecklistFileUploadModal={handleCloseChecklistFileUploadModal}
                isChecklistFileUploadModalOpen={itemAttachments.isChecklistFileUploadModalOpen}
            />
         ;

        const tabs = (
            <React.Fragment>
                {deleteAttachmentModal}
                {uploadFileModal}
                {/* Set default active tab "Previous Answer (0)". If autoOpenFileTab, set to 2 (Attachment). */}
                <Tabs
                    defaultActiveTabIndex={autoOpenFileTab ? 2 : 0}
                    handleTabClick={() => this.setLastClickedQuestionId(item.id)}
                    lastClickedTabChecklistQuestionId={this.props.lastClickedTabChecklistQuestionId}
                    siblingQuestionIds={siblingQuestionIds}>
                    {prevAnswerTab}
                    <Tab iconName="comments" isDisabledTab={isDisabledTab}  iconSize="small" hoverText="Comments" text={`${commentsCount > 0 ? `(${commentsCount})` : ''}`}>
                        {Array.isArray(filteredComments) && filteredComments.map((comment, index) => {
                            if (!itemComments[comment.checklistSectionQuestionCommentID]) {
                                itemComments[comment.checklistSectionQuestionCommentID] = {};
                            }

                            return <div
                                className="comment-section"
                                key={index}
                                onMouseOver={() => {
                                    itemComments[comment.checklistSectionQuestionCommentID].displayCommentIcon = true;
                                    this.setState({ comments: Object.assign({}, comments) });
                                }}
                                onMouseLeave={() => {
                                    itemComments[comment.checklistSectionQuestionCommentID].displayCommentIcon = false;
                                    this.setState({ comments: Object.assign({}, comments) });
                                }}>
                                {itemComments[comment.checklistSectionQuestionCommentID] && !itemComments[comment.checklistSectionQuestionCommentID].editMode ?
                                    <div className="comment-text">
                                        <div className="info text-break">
                                            <div className="mb-2 d-flex justify-content-between">
                                                <div>
                                                    <strong className="mr-2">{comment.isExternalUser ? "External User" : comment.commentedBy}</strong>
                                                    <small>
                                                        commented <span title={getDateTime(comment.dateCommented,true)}>{relativeDate(comment.dateCommented,true)}</span>
                                                    </small>
                                                </div>
                                                <div className="p-0">
                                                    {
                                                        itemComments[comment.checklistSectionQuestionCommentID] &&
                                                        itemComments[comment.checklistSectionQuestionCommentID].displayCommentIcon &&
                                                        canEdit &&
                                                        <MotifIconButton aria-label="Delete this Comment" type="button" data-testid="deleteIcon"
                                                        onClick={() => {
                                                          
                                                            itemComments.deleteCommentId = comment.checklistSectionQuestionCommentID;
                                                            toggleShowChecklistGridRowDelete();
                                                        }}
                                                        >
                                                            <MotifIcon src={actionIcDelete24px} title="Delete this Comment" />
                                                        </MotifIconButton>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {comment.commentText}
                                    </div>
                                    : <div className="write-comment m-2">
                                        <textarea
                                            defaultValue={comment.content}
                                            onChange={(e) => {
                                                itemComments[comment.checklistSectionQuestionCommentID].textarea = e.target;
                                                this.setState({ comments: Object.assign({}, comments) });
                                            }}>
                                        </textarea>
                                        <div className="d-flex flex-row-reverse">
                                            <MotifButton variant="primary" className="ml-2" type="button"
                                                disabled={!itemComments[comment.checklistSectionQuestionCommentID] || !itemComments[comment.checklistSectionQuestionCommentID].textarea || itemComments[comment.checklistSectionQuestionCommentID].textarea.value.trim() === ''}
                                                onClick={() =>
                                                    handleEditComment(item.id, comment.checklistSectionQuestionCommentID, itemComments[comment.checklistSectionQuestionCommentID].textarea.value, isExternal)
                                                        .then(() => { itemComments[comment.checklistSectionQuestionCommentID].textarea.value = ''; this.setState({ comments: Object.assign({}, comments) }); })}>
                                                Update
                                            </MotifButton>
                                            <MotifButton variant="secondary" type="button"
                                                onClick={() => {
                                                    comments[`id_${item.id}`][comment.checklistSectionQuestionCommentID].editMode = false;
                                                    this.setState({ comments: Object.assign({}, comments) });
                                                }}>
                                                Cancel
                                            </MotifButton>
                                        </div>
                                    </div>
                                }
                            </div>;
                        })}
                        {canEdit &&
                            <div className="mt-1">
                                <div className="write-comment m-2">
                                    <textarea
                                        placeholder="Write your comment here"
                                        data-testid="txtComment"
                                        maxLength={4000}
                                        validate={[formValidators.maxLength4000]}
                                        onChange={(e) => { itemComments.textarea = e.target; this.setState({ comments: Object.assign({}, comments) }); }} />
                                    <div className="d-flex flex-row-reverse">
                                        <MotifButton variant="primary" type="button"
                                            disabled={!itemComments || !itemComments.textarea || itemComments.textarea.value.trim() === '' || isDisabledTab}
                                            data-testid="btnSaveComment"
                                            onClick={() =>
                                                handleSaveComment(item.checklistSectionQuestionID, itemComments.textarea.value)
                                                    .then(() => { itemComments.textarea.value = ''; this.setState({ comments: Object.assign({}, comments) }); })}>
                                            Save Comment
                                        </MotifButton>
                                    </div>
                                </div>
                            </div>
                        }
                    </Tab>
                 
                    <Tab iconName="attach" isDisabledTab={isDisabledTab}  iconSize="small" hoverText="Attachments" text={`${attachmentsCount > 0 ? `(${attachmentsCount})` : ''}`}>
                        <div className="row mt-1">
                            <div className="col">
                                {filteredChecklistFiles.length > 0 &&
                                    <React.Fragment>
                                        <div className="row d-flex justify-content-center">
                                            <MotifButton variant="secondary" type="button"
                                                disabled={!canEdit}
                                                onClick={handleOpenChecklistFileUploadModal}>
                                                Add Attachment
                                            </MotifButton>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table-sm mt-1 mb-2">
                                                <thead>
                                                    <tr>
                                                        <th>File Name</th>
                                                        <th>Uploaded By</th>
                                                        <th>Date Uploaded</th>  
                                                        <th />
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredChecklistFiles.map((f, index) =>
                                                        <tr key={index}>
                                                            <td>{f.fileName}</td>
                                                            <td>{f.uploadedBy}</td>
                                                            <td><span title={getDateTime(f.dateUploaded, true)}>{relativeDate(f.dateUploaded, true)}</span></td>
                                                          
                                                            <td className="text-right" style={{ width: '15%' }}>
                                                                <MotifIconButton aria-label="Download this Attachment" type="button"
                                                                    onClick={(e) => { e.preventDefault(); handleDownloadChecklistFile(f.checklistFileAttachmentID, f.fileName); }} >
                                                                    <MotifIcon src={fileIcFileDownload24px} title="Download this Attachment" />
                                                                </MotifIconButton>
                                                                {canEdit &&
                                                                    <MotifIconButton aria-label="Delete this Attachment" type="button"
                                                                    onClick={(e) => { e.preventDefault(); handleDeleteAttachment(f.checklistFileAttachmentID); }} >
                                                                        <MotifIcon src={actionIcDelete24px} title="Delete this Attachment" />
                                                                    </MotifIconButton>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </React.Fragment>
                                }
                                {!filteredChecklistFiles || filteredChecklistFiles.length == 0 &&
                                    <div className="text-center mb-2">
                                        <div className="mt-2">No attachments have been added</div>
                                        <div className="row d-flex justify-content-center">
                                            <MotifButton
                                                variant="secondary" type="button"
                                                disabled={!canEdit || isDisabledTab}
                                                onClick={handleOpenChecklistFileUploadModal}>
                                                Add Attachment
                                            </MotifButton>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </Tab>
                </Tabs>

                <DeleteChecklistDetailGridRowModal
                showDeleteChecklistGridRowModal={
                this.state.showDeleteChecklistGridRowModal
                }
                toggleshowDeleteChecklistGridRowModal={
                toggleShowChecklistGridRowDelete
                }
                deleteRow={handleConfirmDeleteComment}
                modalName={"Comments"}
          />
            </React.Fragment>
        );

        return tabs;
    }
}

/**
 * Maps items from state to properties of the component
* @param {Object} state The state
* @param {Object} ownProps The properties of the component
* @returns {Object} An object containing properties that the component can access
*/
function mapStateToProps(state, ownProps) {
    return {
        lastClickedTabChecklistQuestionId: state.lastClickedTabChecklistQuestionId,
        authenticationScope: state.authenticationScope
    };
}

ChecklistQuestionTabs.propTypes = {
    isExternal: PropTypes.bool,
    isArqc: PropTypes.bool,
    previousAnswers: PropTypes.array,
    previousChecklistFound: PropTypes.array,
    item: PropTypes.object,
    handleSaveComment: PropTypes.func,
    handleDeleteComment: PropTypes.func,
    handleEditComment: PropTypes.func,
    handleDeleteAttachment: PropTypes.func,
    handleDownloadChecklistFile: PropTypes.func,
    user: PropTypes.string,
    canEdit: PropTypes.bool,
    externalEmail: PropTypes.string,
    checklistId: PropTypes.number,
    checklistFiles: PropTypes.array,
    siblingQuestionIds: PropTypes.array,
    lastClickedTabChecklistQuestionId: PropTypes.number,
    dispatch: PropTypes.func,
    hidePreviousAnswers: PropTypes.bool,
    authenticationScope: PropTypes.string,
    prePopulateAnswerOptionID: PropTypes.number,
    isDisabledTab: PropTypes.bool,
    questionComments:PropTypes.array
};

export default connect(mapStateToProps)(ChecklistQuestionTabs);