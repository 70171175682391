import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ChecklistList from "../../components/reits/ChecklistList";
import * as checklistActions from "../../actions/checklistActions";
import * as reitActions from "../../actions/reitActions";

/**
 * ChecklistsExternalPage container component
 * @extends {React.Component}
 */
export class ChecklistsExternalPage extends React.Component {
    /**
        * Creates a new ChecklistsExternalPage
        * @constructor
        * @param {Object} props The component properties
        * @param {Object} context The component context
        */
    constructor(props, context) {
        super(props, context);
        this.handleRevertChecklistState = this.handleRevertChecklistState.bind(this);
        this.state = { checklistList: [] };
    }

    /**
     * Invoked immediately after mounting occurs
     * @returns {undefined}
     */
    componentDidMount() {
        this.props.actions.fetchChecklistListForExternalUser(this.props.reitId);
        this.props.reitActions.fetchReit(this.props.reitId);    
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.checklistList !== this.props.checklistList && nextProps.checklistList && this.props.checklistList) {
            const list = Object.assign([], nextProps.checklistList);
            this.setState({ checklistList: list });
        }
    }

    handleRevertChecklistState(checklistRow) {
        const checklist = {
            checklistTypeID: 'ARQC',
            checklistID: checklistRow.arqcCheckListId,
            checklistStateID: checklistRow.status
        };

        this.props.actions.revertChecklistStateExternal(checklist, this.props.reitId);
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        return (
            <React.Fragment>
                <h1>Report Periods</h1>
                <ChecklistList checklistList={this.state.checklistList} clientId={this.props.clientId} handleRevertChecklistState={this.handleRevertChecklistState} />
            </React.Fragment>
        );
    }
}

ChecklistsExternalPage.propTypes = {
    clientId: PropTypes.string.isRequired,
    actions: PropTypes.object
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    return {
        clientId: ownProps.params.clientId,
        reitId: ownProps.params.reitId,
        checklistList: state.checklistList,
        reitName: state.reit.reitName
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(checklistActions, dispatch),
        reitActions: bindActionCreators(reitActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistsExternalPage);