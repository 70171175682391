import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import { render } from "react-dom";
import { browserHistory } from "react-router";
import { AppContainer } from "react-hot-loader";
import ReactModal from "react-modal";
import Root from "./components/Root";
import store from "./store/configureStore";

//require('./favicon.ico'); // Tell webpack to load favicon.ico
import '@progress/kendo-theme-bootstrap/dist/all.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'; // Yep, that's right. You can import SASS/CSS files too! Webpack will run the associated loader and plug this into the page.
import './css/styles.css';
import './css/tables.css';
import './css/rsMenu.css';
import '@ey-xd/motif-react/index.css';
import { syncHistoryWithStore } from 'react-router-redux';

// Create an enhanced history that syncs navigation events with the store
const history = syncHistoryWithStore(browserHistory, store);

/* eslint-enable no-console */

ReactModal.setAppElement('div#app');

render(
    <AppContainer>
        <Root store={store} history={history} />
    </AppContainer>,
    document.getElementById("app")
);

if (module.hot) {
    module.hot.accept("./components/Root",
        () => {
            const NewRoot = require("./components/Root").default;
            render(
                <AppContainer>
                    <NewRoot store={store} history={history} />
                </AppContainer>,
                document.getElementById("app")
            );
        });
}
