/* eslint react/no-danger: 0 */  // --> OFF
import React from "react";
import PropTypes from "prop-types";
import { Form, Button, Segment } from "semantic-ui-react";
import EditChecklistItem from "./EditChecklistItem";
import EditChecklistComments from "./EditChecklistComments";
import PropertyAmenitiesTableContainer from "../../containers/shared/PropertyAmenitiesTableContainer";
import ChecklistQuestionTabs from "./ChecklistQuestionTabs";
import { MotifButton, MotifIconButton, MotifIcon } from "@ey-xd/motif-react";

const getHtml = (title) => {
    return { __html: title };
};

const renderPsqSection2InitialItem = (
    section,
    isEnabled,
    handleItemAnswered,
    showAllQuestions,
    isExternal,
    isArqc,
    previousChecklistFound,
    canEdit,
    user,
    checklistFiles,
    externalEmail,
    handleSaveComment,
    handleDeleteComment,
    handleEditComment,
    handleDeleteAttachment,
    handleDownloadChecklistFile,
    checklistId,
    prePopulateAnswerOptionID,
    showUnansweredQuestions,
    isDisabledTab,
    questionComments
) => {
    if (section.items.length > 0) {
        const item = section.items[0];
        return (
            <EditChecklistItem key={item.id}
                isEnabled={isEnabled} item={item} handleItemAnswered={handleItemAnswered}
                showAllQuestions={showAllQuestions} isExternal={isExternal} isArqc={isArqc}
                previousChecklistFound={previousChecklistFound}
                checklistFiles={checklistFiles}
                checklistId={checklistId}
                canEdit={canEdit}
                user={user}
                externalEmail={externalEmail}
                handleSaveComment={handleSaveComment}
                handleDeleteComment={handleDeleteComment}
                handleEditComment={handleEditComment}
                handleDeleteAttachment={handleDeleteAttachment}
                handleDownloadChecklistFile={handleDownloadChecklistFile}
                prePopulateAnswerOptionID={prePopulateAnswerOptionID}
                showUnansweredQuestions={showUnansweredQuestions} 
                isDisabledTab={isDisabledTab}
                questionComments={questionComments}
                />
        );
    }

    return null;
};

const editChecklistSection = ({
    section,
    isVisible,
    handleItemAnswered,
    isEnabled,
    showAllQuestions,
    isExternal,
    isArqc,
    previousChecklistFound,
    prePopulateAnswerOptionID,
    lastServiceProviderQuestionId,
    handleComments,
    taxableReitSubsidiaries,
    handleAddPsqSection2Row,
    handleRemovePsqSection2Row,
    tbMode,
    periodId,
    shouldSubmitReduxFormOnChange,
    handleSubmitReduxForm,
    checklistId,
    canEdit,
    user,
    checklistFiles,
    handleSaveComment,
    handleDeleteComment,
    handleEditComment,
    handleDeleteAttachment,
    handleDownloadChecklistFile,
    externalEmail,
    showUnansweredQuestions,
    isDisabledTab,
    questionComments,
    handleDownloadAttachmentClick
}) => {
    if (isVisible) {
        if (section !== undefined && section.items !== undefined && (section.type === undefined || section.type === null || section.type === "standardSection" || section.type === "ArqcExhibitBMortgage")) {
            let dynamicQuestionDisplayOrder = 0; //Initializing dynamic question order to 0
            return (
                <Segment className="mt-0 checklistPreview">
                    <h4 className="sectionTitle" data-testid="sectionTitle">{section.title}</h4>
                    {section.items.map(
                        (item, idx, array) => {
                        return <EditChecklistItem key={item.id} periodId={periodId} isSectionVisible={isVisible} checklistId={checklistId}
                            isEnabled={isEnabled} item={item} nextItem={array.length > idx ? array[idx + 1] : null} handleItemAnswered={handleItemAnswered}
                            showAllQuestions={showAllQuestions} isExternal={isExternal} isArqc={isArqc} previousChecklistFound={previousChecklistFound}
                            tbMode={tbMode} canEdit={canEdit} checklistFiles={checklistFiles} user={user} externalEmail={externalEmail}
                            handleSaveComment={handleSaveComment} handleDeleteComment={handleDeleteComment} handleEditComment={handleEditComment}
                            handleDeleteAttachment={handleDeleteAttachment} handleDownloadChecklistFile={handleDownloadChecklistFile}
                            prePopulateAnswerOptionID={prePopulateAnswerOptionID} showUnansweredQuestions={showUnansweredQuestions} isDisabledTab={isDisabledTab}  questionComments={questionComments} handleDownloadAttachmentClick={handleDownloadAttachmentClick} />
                            })}
                        <EditChecklistComments section={section} handleComments={handleComments} isExternal={isExternal}  />
                </Segment>
            );
        } else if (section !== undefined && section.type === "ArqcExhibitB") {
            return (
                <Segment className="mt-0 checklistPreview">
                    <h4 className="sectionTitle" data-testid="sectionTitle">{section.title}</h4>
                    <div className="fields">
                        <PropertyAmenitiesTableContainer readOnly={!isEnabled} handleSubmit={handleSubmitReduxForm} shouldSubmitReduxFormOnChange={shouldSubmitReduxFormOnChange} formName="checklist" taxableReitSubsidiaries={taxableReitSubsidiaries} />
                        <div className="mt-3">
                            <ChecklistQuestionTabs
                                handleSaveComment={handleSaveComment}
                                handleDeleteComment={handleDeleteComment}
                                handleEditComment={handleEditComment}
                                handleDeleteAttachment={handleDeleteAttachment}
                                handleDownloadChecklistFile={handleDownloadChecklistFile}
                                item={section.items[0]}
                                checklistFiles={checklistFiles}
                                checklistId={checklistId}
                                user={user}
                                externalEmail={externalEmail}
                                canEdit={canEdit}
                                previousAnswers={section.items[0].previousAnswers}
                                previousChecklistFound={previousChecklistFound}
                                isArqc={isArqc}
                                isExternal={isExternal}
                                hidePreviousAnswers={true}
                                prePopulateAnswerOptionID={prePopulateAnswerOptionID}
                                isDisabledTab={isDisabledTab}
                                questionComments={questionComments}
                            />
                        </div>
                    </div>
                    <EditChecklistComments section={section} handleComments={handleComments} isExternal={isExternal} />
                </Segment>
            );
        } else if (section !== undefined && section.items !== undefined && (section.type === undefined || section.type === null || section.type === "PsqSection2")) {
            let items = [];
            
            for (let i = 1; i < section.items.length; i++) {
                items.push(section.items[i]);
            }

            const shouldDisableNewRow = items.find(q => q.id === lastServiceProviderQuestionId) ? true: false;
            const allowRemovePsqSection2Row = items.length > 1;

            return (
                <Segment className="mt-0 checklistPreview">
                    <h4 className="sectionTitle" data-testid="sectionTitle">{section.title}</h4>
                    <div>
                        {renderPsqSection2InitialItem(section, isEnabled, handleItemAnswered, showAllQuestions, isExternal, isArqc, previousChecklistFound, canEdit, user, checklistFiles, externalEmail, handleSaveComment, handleDeleteComment, handleEditComment, handleDeleteAttachment, handleDownloadChecklistFile, checklistId, prePopulateAnswerOptionID, showUnansweredQuestions, isDisabledTab, questionComments)}
                    </div>
                    <br />
                    <div>
                        <Form.Group>
                            <div dangerouslySetInnerHTML={getHtml(items[0].title)} />
                            <table>
                                <thead>
                                    <tr>
                                        <th>Service Providers</th>
                                        <th>E</th>
                                        <th>IK</th>
                                        <th>TRS</th>
                                        <th>Delete</th>
                                    </tr>
                                </thead>
                                {items.map(
                                    (item, idx, array) => <EditChecklistItem key={item.id} periodId={periodId} isSectionVisible={isVisible}
                                        isEnabled={isEnabled} item={item} nextItem={array.length > idx ? array[idx + 1] : null} handleItemAnswered={handleItemAnswered}
                                        showAllQuestions={showAllQuestions} isExternal={isExternal} isArqc={isArqc} previousChecklistFound={previousChecklistFound}
                                        handleRemovePsqSection2Row={handleRemovePsqSection2Row} allowRemovePsqSection2Row={allowRemovePsqSection2Row}
                                        canEdit={canEdit} checklistFiles={checklistFiles} user={user} externalEmail={externalEmail} checklistId={checklistId}
                                        handleSaveComment={handleSaveComment} handleDeleteComment={handleDeleteComment} handleEditComment={handleEditComment}
                                        handleDeleteAttachment={handleDeleteAttachment} handleDownloadChecklistFile={handleDownloadChecklistFile}
                                        prePopulateAnswerOptionID={prePopulateAnswerOptionID} showUnansweredQuestions={showUnansweredQuestions}  isDisabledTab={isDisabledTab} questionComments={questionComments}/>)}
                            </table>
                            {(isEnabled &&
                                <MotifButton variant="secondary" type="button" disabled={shouldDisableNewRow}
                                    onClick={handleAddPsqSection2Row}>
                                    New Row
                                </MotifButton>
                            ) || null}
                        </Form.Group>
                    </div>
                    <br />
                    <EditChecklistComments section={section} handleComments={handleComments} isExternal={isExternal} />
                </Segment>
            );
        }
    }

    return null;
};

editChecklistSection.propTypes = {
    section: PropTypes.object,
    isVisible: PropTypes.bool,
    handleItemAnswered: PropTypes.func,
    isEnabled: PropTypes.bool,
    showAllQuestions: PropTypes.bool,
    isExternal: PropTypes.bool,
    previousChecklistFound: PropTypes.array,
    handleComments: PropTypes.func,
    handleArqcExhibitBItemAnswered: PropTypes.func,
    handleAddArqcExhibitBRow: PropTypes.func,
    handleRemoveArqcExhibitBRow: PropTypes.func,
    handleAddPsqSection2Row: PropTypes.func,
    handleRemovePsqSection2Row: PropTypes.func,
    tbMode: PropTypes.number,
    isArqc: PropTypes.bool,
    taxableReitSubsidiaries: PropTypes.array,
    shouldSubmitReduxFormOnChange: PropTypes.bool,
    handleSubmitReduxForm: PropTypes.func,
    periodId: PropTypes.number,
    checklistId: PropTypes.string,
    prePopulateAnswerOptionID: PropTypes.number,
    user: PropTypes.string,
    showUnansweredQuestions: PropTypes.bool,
    isDisabledTab:PropTypes.bool,
    handleDownloadAttachmentClick: PropTypes.func
    
};

export default editChecklistSection;
