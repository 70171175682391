import PropTypes from "prop-types";
import React from "react";
import { Header, Label, Button, Input, Dropdown } from "semantic-ui-react";
import ReactModal from "react-modal";
import { Field, reduxForm } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import { MotifButton } from '@ey-xd/motif-react';

const SyncREITTestingAttributesModal = ({
    showSyncREITTestingAttributesModal, handleSubmit, toggleShowSyncREITTestingAttributesModal, syncREITTestingAttributesFormValues, initialValues, selectedTbName, tbMode
}) => {

    var applyToREITorProperties = "Apply only to " + selectedTbName;
    
    return (
        <ReactModal contentLabel="Restore Default REIT Testing Attributes" isOpen={showSyncREITTestingAttributesModal} className="react-modal react-modal-small" overlayClassName="react-modal-overlay">
            <Header content="Restore Default REIT Testing Attributes" />
            <form className="synchREITTestingAttribute">
                { tbMode && tbMode !== 2 && <br />}                
                <span>
                    { tbMode && tbMode !== 2  &&
                        <Field name="synchType" component={formHelpers.renderField} type="radio" value="1" label={applyToREITorProperties} labelLocationOnRight={true} />
                    }
                </span>
                <br />
                <span><Field name="synchType" component={formHelpers.renderField} type="radio" value="2" label="Apply to REIT and all Properties" labelLocationOnRight={true} /></span><br />
                
                <br/><br/>
                <div className="mb-3" >
                    By clicking confirm, you agree to push these changes.
                </div>

                <div className="mt-3 text-right">
                    <MotifButton variant="primary" className="right ml-2" type="button"
                        onClick={() => handleSubmit()}
                        disabled={!syncREITTestingAttributesFormValues}>
                        Confirm
                    </MotifButton>
                    <MotifButton variant="secondary" className="right" type="button"
                        onClick={toggleShowSyncREITTestingAttributesModal}>
                        Cancel
                    </MotifButton>
                    
                    </div>
            </form>
        </ReactModal>
    );
};

SyncREITTestingAttributesModal.propTypes = {
    showSyncREITTestingAttributesModal: PropTypes.bool,
    handleSubmit: PropTypes.func,
    toggleShowSyncREITTestingAttributesModal: PropTypes.func,
    initialValues: PropTypes.object,
    tbMode: PropTypes.number,
    syncREITTestingAttributesFormValues: PropTypes.object
};

export default reduxForm({ form: "synchREITTestingAttribute", enableReinitialize: true })(SyncREITTestingAttributesModal);