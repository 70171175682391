import React from "react";
import PropTypes from "prop-types";
import * as formHelpers from "../../scripts/formHelpers";
import * as formValidators from "../../scripts/formValidators";
import { Button, Icon, Input } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import { DateField, MoneyField, NumberField } from "../shared/formFields";
import { MotifButton, MotifModalHeader, MotifModalBody, MotifModalFooter, MotifIcon } from '@ey-xd/motif-react';
import { alertIcError24px } from '@ey-xd/motif-react/assets/icons';

const validate = values => {
    
    // Validates business rules, not field validation
    const errors = {};
    
    if (values.value > 0 && values.fairMarketValue > 0) {
        errors.value = "0 if FMV is used.";
        errors.fairMarketValue = "0 if Value is used.";
    }
    
    if (values.value === 0 && values.fairMarketValue === 0) {
        errors.value = "Enter Value or FMV";
        errors.fairMarketValue = "Enter Value or FMV";
    }
    
    return errors;
};

const editDistributionForm = ({ handleSubmit, handleCloseForm, initialValues, formPurpose, error, submitting, handleDismissErrorMessage }) => {

    const modalPurpose = formPurpose === "" ? 'Add' : (formPurpose === "EDIT" ? 'Edit' : 'Delete');

    const closeButtonProps = {
        title: 'Close ' + modalPurpose + ' Distribution Modal',
        ariaLabel: 'Close ' + modalPurpose + ' Distribution Modal',
        dataTestid: 'close',
    };

    const HeaderWrapper = ({ children }) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'center',
          }}
        >
          {children}
        </div>
    );

    return (
        <React.Fragment>
            <MotifModalHeader style={{ display: '-webkit-box' }} closeButtonProps={closeButtonProps}>
                <HeaderWrapper>
                    {formPurpose === "DELETE" && <MotifIcon style={{ color: '#F95D54' }} src={alertIcError24px} className="mr-2" />}
                    <span>{formPurpose === "" ? "Add Distribution" : (formPurpose === "EDIT" ? "Edit Distribution" : "Delete Distribution?")}</span>
                </HeaderWrapper>
            </MotifModalHeader>
            <MotifModalBody>
                <form onSubmit={handleSubmit} className="distribution" id="addEditDeleteDistributionForm">
                    <Field
                        name="distributionID"
                        type="hidden"
                        component={formHelpers.renderField}
                        label=""
                        disabled={(formPurpose === "DELETE") ? "disabled" : ""} />
                    <Field name="reportPeriodID" type="hidden" component={formHelpers.renderField}  disabled={(formPurpose === "DELETE") ? "disabled" : ""} />
                    <div className="row">
                        <br />
                        {formHelpers.showSubmissionError(error, handleDismissErrorMessage)}
                        <div className="field col-md-6">
                            <Field
                                name="class"
                                type="text"
                                label="Class"
                                component={formHelpers.renderField}
                                isRequiredField={true}
                                disabled={(formPurpose === "DELETE") ? "disabled" : ""}
                                maxLength={255}
                                validate={[formValidators.required, formValidators.maxLength255, formValidators.noWindowSpecialChars]}
                                />
                        </div>
                        <div className="field col-md-6">
                            <DateField
                                name="distributionDate"
                                label="Date"
                                isRequiredField={true}
                                disabled={(formPurpose === "DELETE") ? "disabled" : ""}
                                validate={[formValidators.required, formValidators.isValidDate]}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="field col-md-6">
                            <NumberField
                                name="fairMarketValue"
                                label="Fair Market Value"
                                placeholder="0"
                                allowDecimal={false}
                                isRequiredField={true}
                                disabled={(formPurpose === "DELETE") ? "disabled" : ""}
                                validate={[formValidators.required, formValidators.number]} />
                        </div>

                        <div className="field col-md-6">
                            <NumberField
                                name="value"
                                label="Value"
                                placeholder="0"
                                allowDecimal={false}
                                isRequiredField={true}
                                disabled={(formPurpose === "DELETE") ? "disabled" : ""}
                                validate={[formValidators.required, formValidators.number]} />
                        </div>
                    </div>
                </form>
            </MotifModalBody>
            <MotifModalFooter>
                <MotifButton variant={formPurpose === "DELETE" ? "warn" : "primary"} type="submit" value="Submit" form="addEditDeleteDistributionForm">
                    {(formPurpose === "" && <React.Fragment>Submit</React.Fragment>)
                        || formPurpose === "EDIT" && <React.Fragment>Save</React.Fragment>
                        || <React.Fragment>Delete</React.Fragment>}
                </MotifButton>
                <MotifButton variant="secondary" type="button" onClick={handleCloseForm} form="addEditDeleteDistributionForm">
                    Cancel
                </MotifButton>
            </MotifModalFooter>
        </React.Fragment>
    );
};

editDistributionForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleCloseForm: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    formPurpose: PropTypes.string,
    error: PropTypes.string,
    submitting: PropTypes.bool,
    handleDismissErrorMessage: PropTypes.func
};

export default reduxForm({ form: "distribution", validate})(editDistributionForm);