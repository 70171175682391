import React from "react";
import PropTypes from "prop-types";
import ClientListRow from "./ClientListRow";
import { MotifFormField, MotifSearch, MotifLabel, MotifToggleSwitch } from '@ey-xd/motif-react';
import { Search, Icon, Input, Button } from "semantic-ui-react";

const ClientList = ({ clients, handleSearchChange, handleClearSearchText, clientSearchText, handleShowInactive, showInactive,
    isSysAdmin, handleToggleActiveFlag, clientsAreLoaded }) => {
    return (
        <React.Fragment>
            <div className="row align-items-center">
                <div className="col-md-4">
                    <h1>Clients</h1>
                </div>
                <div className="col-md-5 text-right">
                    {isSysAdmin &&
                        <React.Fragment>
                            <MotifLabel
                                htmlFor="switchInactive" 
                                aria-label={`Display Inactive Clients toggle button ${showInactive ? 'on' : 'off'}`}
                                style={{ display: 'inline-block', width: 'auto', marginRight: '1rem' }}>
                                Display Inactive Clients:
                            </MotifLabel>
                            <MotifFormField style={{ display: 'inline-block', width: 'auto' }} aria-hidden="true">
                                <MotifToggleSwitch
                                    name="switchInactive"
                                    id="switchInactive"
                                    onChange={handleShowInactive} 
                                    checked={!!showInactive} />
                            </MotifFormField>
                        </React.Fragment>
                    }
                </div>
                <div className="col-md-3">
                    <MotifFormField>
                        <MotifLabel htmlFor="clientFilter">Search for Clients</MotifLabel>
                        <MotifSearch
                            type="text"
                            onChange={handleSearchChange}
                            value={clientSearchText}
                            name="clientFilter"
                            title="Type in a client name"
                            aria-label="Search for Clients"
                            searchButtonProps={{ tabIndex: "-1" }} />
                    </MotifFormField>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <section className="table-responsive rtable">
                        <table>
                            <thead>
                                <tr role="row" aria-rowindex={1}>
                                    <th role="columnheader" aria-sort="none" aria-colindex={1}>Clients</th>
                                    <th role="columnheader" aria-sort="none" aria-colindex={2}>REITS</th>
                                    <th role="columnheader" aria-sort="none" aria-colindex={3}>Q1</th>
                                    <th role="columnheader" aria-sort="none" aria-colindex={4}>Q2</th>
                                    <th role="columnheader" aria-sort="none" aria-colindex={5}>Q3</th>
                                    <th role="columnheader" aria-sort="none" aria-colindex={6}>Q4</th>
                                    {(showInactive && <th role="columnheader" aria-sort="none" aria-colindex={7}>Active</th>) || null}
                                </tr>
                            </thead>
                            <tbody>
                                {clients.map((client, index) => <ClientListRow rowIndex={index + 2} key={client.id} client={client} showInactive={showInactive} handleToggleActiveFlag={handleToggleActiveFlag}/>)}

                            </tbody>
                        </table>
                        {(clients && clients.length === 0 && clientSearchText === "" && clientsAreLoaded && <div><h4>It looks as though you aren't assigned to any clients right now - please contact your manager.</h4></div>) || ((clients && clients.length === 0 && clientSearchText !== "" && clientsAreLoaded) && <div><h4>No clients match your search text '{clientSearchText}'</h4></div>) || null}
                    </section>
                </div>
            </div>
        </React.Fragment>
    );
};

ClientList.propTypes = {
    clients: PropTypes.array.isRequired,
    handleSearchChange: PropTypes.func,
    handleClearSearchText: PropTypes.func,
    clientSearchText: PropTypes.string,
    handleShowInactive: PropTypes.func,
    showInactive: PropTypes.bool,
    isSysAdmin: PropTypes.bool,
    handleToggleActiveFlag: PropTypes.func,
    clientsAreLoaded: PropTypes.bool
};

export default ClientList;