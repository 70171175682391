import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, GridColumn, GridNoRecords, GridToolbar } from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { process, filterBy } from '@progress/kendo-data-query';
import { Button } from "semantic-ui-react";
import { Input } from "@progress/kendo-react-inputs";
import DuplicateAccountsEditModal from "../chartOfAccountsManager/DuplicateAccountsEditModal";
import { MotifButton } from '@ey-xd/motif-react';

let globalLookupTypes = {};
let globalCategoryOptions = [];
let globalAttributeOptions = [];

const LoadingPanel = () => {
    return <div className="k-loading-mask">
        <span className="k-loading-text"></span>
        <div className="k-loading-image">
        </div>
        <div className="k-loading-color"></div>
    </div>
}

let DropDownCell = props => {
    let optionsData = props.optionsData || [];

    const [data, setData] = React.useState(optionsData.slice());

    const filterData = (filter) => {
        const data = optionsData.slice();
        return filterBy(data, filter);
    };

    // handle filter change
    const filterChange = (event) => {
        setData(filterData(event.filter));
    };

    const {
        dataItem
    } = props;
    const field = props.field || '';
    const dataValue = dataItem[field] === null ? '' : dataItem[field];

    //Since we are using only text the actual ID is being updated on itemChange
    const [optionValue, setOptionValue] = React.useState({ label: dataValue, value: dataValue });

    // handle dropdown change
    const handleChange = (event) => {
        // Update the dropdown value
        setOptionValue(event.target.value);

        // Update the form data
        if (event == null) {
            event = new Event('ClearValue')
        }
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: props.field,
                syntheticEvent: event.syntheticEvent,
                value: event.target.value
            });
        }
    };
    return <td>
        {dataItem.inEdit ? <DropDownList
            data={data}
            value={optionValue}
            textField="label"
            dataItemKey="value"
            filterable={props.isFilterable}
            onFilterChange={filterChange}
            onChange={handleChange}
            className={dataItem.isDirty[field] ? "dropDownListChanged" : ""}
        /> : dataValue}
    </td>;
};
DropDownCell = React.memo(DropDownCell);

let InputCell = props => {
    const {
        dataItem
    } = props;
    const field = props.field || '';
    const dataValue = dataItem[field] === null ? '' : dataItem[field];

    //This triggers the itemChange
    const handleChange = e => {
        props.onChange({
            dataIndex: 0,
            dataItem: dataItem,
            field: field,
            syntheticEvent: e.syntheticEvent,
            value: e.target.value
        });
    };

    return <td>
        {dataItem.inEdit ? <Input style={{
            width: "100%",
            marginTop: "5px",
            background: dataItem.isDirty[field] ? "rgb(255 242 127)" : ""
        }} onChange={handleChange} value={dataValue} type={"text"} maxLength={field === "clientAccountCode" ? 50 : 255} /> : dataValue}
    </td>;
};
InputCell = React.memo(InputCell);

let CategoryCell = (props) => <DropDownCell {...props} lookupTypes={globalLookupTypes} dropDownType={"Category"} optionsData={globalCategoryOptions} isFilterable={false} />;
CategoryCell = React.memo(CategoryCell);
let AttributeCell = (props) => <DropDownCell {...props} lookupTypes={globalLookupTypes} dropDownType={"Attribute"} optionsData={globalAttributeOptions} isFilterable={true} />;
AttributeCell = React.memo(AttributeCell);

const ChartOfAccountsTable = ({
    mappings,
    searchBoxText,
    isEditModeEnabled,
    lookupTypes,
    handleOnEditToggle,
    handleAccountUpdate
}) => {
    let pagerSettings = {
        buttonCount: 4,
        info: true,
        type: "numeric",
        pageSizes: [100, 250, 500],
        previousNext: true,
    };
    const [dataState, setDataState] = useState({
        skip: 0,
        take: 100,
        sort: [
            {
                field: 'clientAccountCode',
                dir: 'asc'
            }
        ]
    });
    const [dataResult, setDataResult] = useState(process(mappings, dataState));
    const [rowsAffected, setRowsAffected] = useState([]);
    const [dataResultOnEditing, setDataResultOnEditing] = useState([]);
    const [showDuplicateAccountsEditModal, setShowDuplicateAccountsEditModal] = useState(false);
    const [clientAccountCodeList, setClientAccountCodeList] = useState([]);
    const [editLoading, setEditLoading] = useState(false);

    useEffect(() => {
        handleSearchBox(searchBoxText);
    }, [searchBoxText])

    useEffect(() => {
        const tempGlobalCategoryOptions = lookupTypes.clientAccountCategories.map(type => {
            return { label: type.clientAccountCategoryDescription, value: type.clientAccountCategoryDescription, id: type.clientAccountCategoryID }
        });
        tempGlobalCategoryOptions.unshift({ label: '', value: '', id: '' });
        globalCategoryOptions = tempGlobalCategoryOptions;

        globalAttributeOptions = lookupTypes.reitTestingAttributes.map(type => {
            return { label: type.reitTestingAttributeDescription, value: type.reitTestingAttributeDescription, id: type.reitTestingAttributeID }
        });
    }, [lookupTypes])

    useEffect(() => {
        if (isEditModeEnabled) {
            setEditLoading(true);
        }
        //If edit mode is being toggled we use the dataResult to keep the elements sorted
        let mappingsEditEnabled = mappings.map(mapping => {
                return {
                    ...mapping,
                    inEdit: isEditModeEnabled,
                    isDirty: {
                        clientAccountCode: false,
                        clientAccountDescription: false,
                        clientAccountCategoryDescription: false,
                        reitTestingAttributeDescription: false
                    },
                    originalState: mapping
                }
            });

        setDataResultOnEditing(mappingsEditEnabled);
        setDataResult(process(mappingsEditEnabled, dataState));
        setDataState(dataState);

    }, [isEditModeEnabled])

    const dataStateChange = event => {
        setDataResult(process(isEditModeEnabled ? dataResultOnEditing : mappings, event.dataState));
        setDataState(event.dataState);
    };

    const handleSearchBox = (textToFilter) => {

        let filterConfig = {
            logic: "or",
            filters: [
                { field: "clientAccountCode", operator: "contains", value: textToFilter },
                { field: "clientAccountDescription", operator: "contains", value: textToFilter },
                { field: "clientAccountCategoryDescription", operator: "contains", value: textToFilter },
                { field: "reitTestingAttributeDescription", operator: "contains", value: textToFilter },
                { field: "lastUpdatedDateTime", operator: "contains", value: textToFilter }
            ]
        };
        
        //This ternary operator alternates bewteen the mappings that we dont want to change for revert functionality and the actual edit data
        setDataResult(process(isEditModeEnabled ? dataResultOnEditing : mappings, { ...dataState, filter: filterConfig }));
        setDataState({ ...dataState, filter: filterConfig, skip : 0});
    }

    const itemChange = (event) => {
        const inEditID = event.dataItem.chartOfAccountsID;
        const field = event.field || "";

        let value = (field == "clientAccountCategoryDescription" || field == "reitTestingAttributeDescription") ? event.value.value : event.value;
        const editedItem = dataResultOnEditing.find(mapping => mapping.chartOfAccountsID === inEditID);

        editedItem[field] = (field == "clientAccountCategoryDescription" || field == "reitTestingAttributeDescription") ? event.value.value : event.value;

        if (value == editedItem.originalState[field]) {
            editedItem.isDirty = { ...editedItem.isDirty, [field]: false };
        } else {
            editedItem.isDirty = { ...editedItem.isDirty, [field]: true };
        }

        if (field == "clientAccountCategoryDescription" || field == "reitTestingAttributeDescription") {
            editedItem[field == "clientAccountCategoryDescription" ? "clientAccountCategoryID" : "defaultREITTestingAttributeID"] = event.value.id;
        }

        let affectedRowsCurrentCounter = dataResultOnEditing.filter(account =>
            account.isDirty.clientAccountCode === true ||
            account.isDirty.clientAccountDescription === true ||
            account.isDirty.clientAccountCategoryDescription === true ||
            account.isDirty.reitTestingAttributeDescription === true);
            
        setRowsAffected(affectedRowsCurrentCounter)
        setDataResultOnEditing(dataResultOnEditing);
        setDataResult(process(dataResultOnEditing, dataState));
    };

    const closeDuplicateAccountsEditModal = () => {
        setShowDuplicateAccountsEditModal(false);
        setClientAccountCodeList([]);
    }

    const handleSaveUpdates = () => {
        let foundDuplicates = false;
        let duplicateCodes = [];
        rowsAffected.forEach(account => {
            let foundOnMappings = mappings.filter(mapping => mapping.chartOfAccountsID != account.chartOfAccountsID && mapping.clientAccountCode == account.clientAccountCode);
            let foundOnRowsAffected = rowsAffected.filter(affectedAccount => affectedAccount.chartOfAccountsID != account.chartOfAccountsID && affectedAccount.clientAccountCode == account.clientAccountCode);
            if (foundOnMappings.length > 0 || foundOnRowsAffected.length > 0) {
                foundDuplicates = true;
                if (!duplicateCodes.includes(account.clientAccountCode)) {
                    duplicateCodes.push(account.clientAccountCode);
                }
            }
        });
        if (foundDuplicates) {
            setShowDuplicateAccountsEditModal(true);
            setClientAccountCodeList(duplicateCodes);
            return false;
        }
        setRowsAffected([]);
        setDataResult([]);
        handleAccountUpdate(rowsAffected);
    }

    const rowRender = (trElement, props) => {
        const style = {};

        if (rowsAffected.indexOf(props.dataItem) >= 0) {
            style.backgroundColor = "#fffacc";
        }

        const trProps = {
            style,
        };

        return React.cloneElement(
            trElement,
            { ...trProps },
            trElement.props.children
        );
    };

    return (
        <div className="row">
            <div className="col-12">
                {
                    isEditModeEnabled &&
                    <div style={{ position: "absolute", top: "360px", right: "15px", zIndex: "1", display: "inline" }}>                        
                        <MotifButton onClick={handleSaveUpdates} variant="primary" className="right ml-2 mr-3" disabled={(rowsAffected.length == 0 ||
                            rowsAffected.filter(code => code.clientAccountCode.trim() === '' || code.clientAccountDescription.trim() === '').length > 0)}>Save</MotifButton>
                        <MotifButton onClick={() => {
                            handleOnEditToggle();
                            setRowsAffected([]);
                            setDataResult([]);
                        }} variant="secondary" className="right ml-2">Cancel</MotifButton>
                        <p className="right mr-2 mt-2">{rowsAffected.length} record{rowsAffected.length === 0 || rowsAffected.length > 1 ? 's' : ''}  changed</p>
                    </div>
                }
                <Grid
                    style={{
                        height: "400px",
                    }}
                    sortable={!isEditModeEnabled}
                    pageable={pagerSettings}
                    data={dataResult}
                    {...dataState}
                    onDataStateChange={dataStateChange}
                    scrollable={"scrollable"}
                    fixedScroll={true}
                    resizable={true}
                    editField="inEdit"
                    onItemChange={itemChange}
                    dataItemKey={"chartOfAccountsID"}
                    rowRender={rowRender}
                >
                    <GridNoRecords>
                        {mappings.length == 0 ?
                            "Click on Chart Management > Click Download Chart of Accounts > Update the downloaded file with the chart's details > Click Upload Chart of Accounts"
                            : `No mappings match your search text '${searchBoxText}'`
                        }
                    </GridNoRecords>
                    {/* <GridColumn field="chartId" /> */}
                    <GridColumn field="clientAccountCode" title="Client Account Number" cell={InputCell} className="gridRowFixedHeight" />
                    <GridColumn field="clientAccountDescription" title="Client Account Name" cell={InputCell} className="gridRowFixedHeight" />
                    <GridColumn field="clientAccountCategoryDescription" title="Category" cell={CategoryCell} className="gridRowFixedHeight" />
                    <GridColumn field="reitTestingAttributeDescription" title="Default REIT Testing Attribute" cell={AttributeCell} className="gridRowFixedHeight" />
                    <GridColumn field="lastUpdatedDateTime" title="Date Modified" editable={false} className="gridRowFixedHeight" />
                </Grid>
            </div>
            <DuplicateAccountsEditModal
                showDuplicateAccountsEditModal={showDuplicateAccountsEditModal}
                closeDuplicateAccountsEditModal={closeDuplicateAccountsEditModal}
                clientAccountCodeList={clientAccountCodeList}
            />
        </div>
    );
};

ChartOfAccountsTable.propTypes = {
    chart: PropTypes.object,
};

export default React.memo(ChartOfAccountsTable);