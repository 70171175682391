import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import * as formHelpers from "../../scripts/formHelpers";
import * as formValidators from "../../scripts/formValidators";

const MappingFormAllReits = ({ handleSubmit, handleCloseForm, initialValues, eyStandardAccounts, error, submitting, handleDismissErrorMessage }) => {
    let formTitle = "Add Client Account Mapping to All REITs and All Open Periods";
    let selectionHeader = "Map to:";

    return (
        <form onSubmit={handleSubmit} className="mapping">
            {formHelpers.showSubmissionError(error, handleDismissErrorMessage)}
            <h3>{formTitle}</h3>
            <div className="row col-md-12 field">
                <div className="field col-md-6">
                    <br />
                    <label htmlFor="name">Client Account Number: </label>
                    <Field
                        name="clientAccountCode"
                        component={formHelpers.renderField}
                        type="text"
                        placeholder="Client Account Number"
                        maxLength={50}
                        validate={[formValidators.required, formValidators.maxLength50]}
                    />
                </div>
                <div className="field col-md-6">
                    <br />
                    <label htmlFor="name">Client Account Name: </label>
                    <Field
                        name="clientAccountDescription"
                        component={formHelpers.renderField}
                        type="text"
                        placeholder="Client Account Name"
                        maxLength={50}
                        validate={[formValidators.required, formValidators.maxLength50]}
                    />
                </div>
            </div>
            <div className="row col-md-12 field">
                <div className="field col-md-12">
                    <br />
                    <label htmlFor="name">{selectionHeader}</label>
                    <br />
                    <Field name="internalAccountCode" component={formHelpers.renderReactSelect} options={eyStandardAccounts} validate={[formValidators.requiredSelect]} />
                </div>

            </div>
            <div className="row action">
                <br />
                <div className="modal-footer col-md-12 container">
                    <button type="submit" className="btn btn-primary" disabled={submitting}>Save</button>
                    <button className="btn btn-secondary right" onClick={handleCloseForm}>Cancel</button>
                </div>
            </div>
        </form>
    );
};

MappingFormAllReits.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleCloseForm: PropTypes.func.isRequired,
    trs: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        periodId: PropTypes.number,
        name: PropTypes.string,
        debt: PropTypes.number,
        equity: PropTypes.number
    })),
    initialValues: PropTypes.object,
    error: PropTypes.string,
    submitting: PropTypes.bool,
    eyStandardAccounts: PropTypes.array,
    handleDismissErrorMessage: PropTypes.func.isRequired
};

export default reduxForm({ form: "mapping", enableReinitialize: true })(MappingFormAllReits);