import PropTypes from "prop-types";
import React from "react";
import ReactModal from "react-modal";
import { Button, Header } from "semantic-ui-react";
import { MotifButton } from '@ey-xd/motif-react';

const YesNoModal = ({ showModal, handleYesAction, handleNoAction, identifier, title, question }) => {
       
    const onYesClicked = () => {
        handleYesAction(identifier);
    };

    const onNoClicked = () => {
        handleNoAction(identifier);
    };

    return (
        <div>
            <ReactModal isOpen={showModal} contentLabel={title} className="react-modal" overlayClassName="react-modal-overlay">
                <div>
                    <Header content={title} />
                    <h3>{question}</h3>
                    <MotifButton variant="primary" className="right ml-2" type="button"
                        id="openButton"
                        onClick={onYesClicked}
                        data-key={identifier}>
                        Yes
                    </MotifButton>
                    <MotifButton variant="secondary" className="right" type="button"
                        onClick={onNoClicked}>
                        No
                    </MotifButton>
                </div>
            </ReactModal>
        </div>
    );
};

YesNoModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    handleYesAction: PropTypes.func.isRequired,
    handleNoAction: PropTypes.func.isRequired,
    identifier: PropTypes.number,
    title: PropTypes.string,
    question: PropTypes.string,
    pendingTasks: PropTypes.number
};

export default YesNoModal;