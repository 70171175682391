import React, { useEffect } from "react";
import PropTypes, { object } from "prop-types";
import { connect } from "react-redux";
import { getFormValues, getFormSyncErrors, getFormSubmitErrors, SubmissionError, reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import * as clientActions from "../../actions/clientActions";
import * as checklistActions from "../../actions/checklistActions";
import * as reportActions from '../../actions/reportActions';
import * as periodActions from "../../actions/periodActions";
import * as authActions from "../../actions/authActions";
import * as customQuestionActions from "../../actions/customQuestionActions";
import { MotifButton, MotifTabNavigation, MotifTabControl, MotifToast } from "@ey-xd/motif-react";
import { Link } from 'react-router';
import { pendingTask, begin, end } from "react-redux-spinner";
import * as actions from "../../actions/actionTypes";
import ChecklistHeader from "../../components/reits/ChecklistHeader";
import PSQChecklistTable from "../../components/reits/PSQChecklistTable";
import AddChecklistUserModal from "../../components/checklistDeliveryCalendar/AddChecklistUserModal";
import DeleteChecklistUserModal from "../../components/checklistDeliveryCalendar/DeleteChecklistUserModal";
import DeleteChecklistModal from "../../components/checklistDeliveryCalendar/DeleteChecklistModal";
import ResendChecklistModal from "../../components/checklistDeliveryCalendar/ResendChecklistModal";
import SignoffChecklistModal from "../../components/checklistDeliveryCalendar/SignoffChecklistModal";
import ReopenForEYReviewChecklistModal from "../../components/checklistDeliveryCalendar/ReopenForEYReviewChecklistModal";
import ApproveClientReopenRequestModal from "../../components/checklistDeliveryCalendar/ApproveClientReopenRequestModal";
import DenyClientReopenRequestModal from "../../components/checklistDeliveryCalendar/DenyClientReopenRequestModal";
import DownloadChecklistsModal from '../../components/checklistDeliveryCalendar/DownloadChecklistsModal';
import * as actionHelpers from '../../scripts/actionHelpers';
import ChecklistPageBase from './ChecklistPageBase';
import PsqCreateChecklistModal from '../../components/reits/PsqCreateChecklistModal';
import * as propertyActions from '../../actions/propertyActions';
import * as lookupTypeActions from "../../actions/lookupTypeActions";
import * as commonActions from '../../actions/commonActions';
import * as reitActions from '../../actions/reitActions';
import * as formValidators from '../../scripts/formValidators';
import * as Constants from '../../constants/other';
import InvalidEmailModal from '../../components/reits/InvalidEmailModal';
/**
 * PSQChecklistContainer container component
 * @extends {React.Component}
 */
class PSQChecklistContainer extends ChecklistPageBase {
    /**
    * Creates a new PSQ Checklists page
    * @constructor
    * @param {Object} props The component properties
    * @param {Object} context The component context
    */
    constructor(props, context) {
        super(props, context);

        this.updateInitialValues = this.updateInitialValues.bind(this);
        this.setGridColumnState = this.setGridColumnState.bind(this);
        this.resetGridColumnState = this.resetGridColumnState.bind(this);
        this.handleGridColumnStateChange = this.handleGridColumnStateChange.bind(this);
        this.toggleShowAddChecklistUserModal = this.toggleShowAddChecklistUserModal.bind(this);
        this.handleAddChecklistUser = this.handleAddChecklistUser.bind(this);
        this.toggleShowDeleteChecklistUserModal = this.toggleShowDeleteChecklistUserModal.bind(this);
        this.handleDeleteChecklistUser = this.handleDeleteChecklistUser.bind(this);
        this.toggleShowDeleteChecklistModal = this.toggleShowDeleteChecklistModal.bind(this);
        this.handleDeleteChecklist = this.handleDeleteChecklist.bind(this);
        this.toggleShowResendChecklistModal = this.toggleShowResendChecklistModal.bind(this);
        this.handleResendChecklist = this.handleResendChecklist.bind(this);
        this.toggleShowSignoffChecklistModal = this.toggleShowSignoffChecklistModal.bind(this);
        this.toggleShowReopenForEYReviewChecklistModal = this.toggleShowReopenForEYReviewChecklistModal.bind(this);
        this.toggleShowApproveClientReopenRequestModal = this.toggleShowApproveClientReopenRequestModal.bind(this);
        this.toggleShowDenyClientReopenRequestModal = this.toggleShowDenyClientReopenRequestModal.bind(this);
        this.handleSignoffChecklist = this.handleSignoffChecklist.bind(this);
        this.handleReopenForEYReviewChecklist = this.handleReopenForEYReviewChecklist.bind(this);
        this.handleApproveClientReopenRequest = this.handleApproveClientReopenRequest.bind(this);
        this.handleDenyClientReopenRequest = this.handleDenyClientReopenRequest.bind(this);
        this.handleExportChecklistSummary = this.handleExportChecklistSummary.bind(this);
        this.handleGridChanges = this.handleGridChanges.bind(this);
        this.handleGridFilteredRowChanges = this.handleGridFilteredRowChanges.bind(this);
        this.handleRecipientChanges = this.handleRecipientChanges.bind(this);
        this.handleEmailChanged = this.handleEmailChanged.bind(this);
        this.handleSignoffChecklistStateChange = this.handleSignoffChecklistStateChange.bind(this);
        this.refreshGrid = this.refreshGrid.bind(this);
        this.handleRefreshManageChecklistClient = this.handleRefreshManageChecklistClient.bind(this);
        this.dismissRefreshManageChecklistMessage = this.dismissRefreshManageChecklistMessage.bind(this);
        this.handleCreateField = this.handleCreateField.bind(this);
        this.handleClientEmailChanged = this.handleClientEmailChanged.bind(this);
        this.handlePopulatePreviousAnswersChanged = this.handlePopulatePreviousAnswersChanged.bind(
            this,
        );
        this.handleDismissErrorMessage = this.handleDismissErrorMessage.bind(this);
        this.clientEmailsRef = this.clientEmailsRef.bind(this);
        this.toggleShowInvalidEmailModal = this.toggleShowInvalidEmailModal.bind(this);
        this.deleteInvalidEmail = this.deleteInvalidEmail.bind(this);
        this.handleDownloadChecklists = this.handleDownloadChecklists.bind(this);
        this.toggleShowDownloadChecklistsModal = this.toggleShowDownloadChecklistsModal.bind(this);
        this.state = {
            saved: false,
            psqArqc: 'PSQ',
            filteredManageChecklists: [],
            gridColumnState: [],
            showAddChecklistUserModal: false,
            showDeleteChecklistUserModal: false,
            showDeleteChecklistModal: false,
            showResendChecklistModal: false,
            showSignoffChecklistModal: false,
            showReopenForEYReviewChecklistModal: false,
            showApproveClientReopenRequestModal: false,
            showDenyClientReopenRequestModal: false,
            gridCurrentState: [],
            gridFilteredRowCurrentState: [],
            selectedRecipients: [],
            checklistUsers: [],
            selectedSignoffChecklistState: -1,
            refreshGridChecklistData: {
                refreshGridOnCompletedAction: -1,
                checklists: []
            },
            isSendEmailEnabled: false,
            isCreateOnlyEnabled: false,
            createData: {
                checklistTypeID: 'PSQ',
                clientID: this.props.client.id,
                reitId: this.props.reit.reitid,
                reportPeriodID: this.props.periodId,
                propertyID: null,
                clientEmailTo: '',
                propertyType: null,
                checklistStateID: 'Created',
                clientContactEmails: '',
                clientEmails: [],
                selectedPropertyId: 0,
                selectedPropertyName: '',
                selectedPropertyType: '',
                reportPeriodSummary: {},
                questionnaireTemplateId: null,
                prePopulatedId: Constants.defaultPrepopulateAnswer,
                hasRollForwardQuestionComment: Constants.defaultRollFowardQuestionComment,
                hasRollForwardQuestionAttachment: Constants.defaultRollFowardQuestionAttachment,
            },
            selectedPropertyId: 0,
            selectedPropertyName: '',
            selectedPropertyType: '',
            showInvalidEmailModal: false,
            clientEmail: [],
            downloadChecklists: {
                downloadType: '',
                showModal: false,
                modalTitle: '',
                modalContent: '',
            },
            canEdit: true,
            invalidHeader:Constants.invalidEmailCreateChecklistHeader
        };
    }

    /**
     * Invoked immediately after mounting occurs
     * @returns {undefined}
     */
    componentDidMount() {
        this.props.propertyActions.fetchPropertiesByPeriod(this.props.periodId);
        this.props.lookupTypeActions.fetchPropertyTypes();
        this.props.checklistActions.fetchManageChecklists(this.props.clientId, null, this.props.periodId, 'PSQ');
        checklistActions
            .getReportPeriodSummary(this.props.periodId)
            .then(request => {
                return request.json();
            })
            .then(json => {
                if (json) {
                    this.setState({ reportPeriodSummary: json });
                }
            });
            this.props.checklistActions.resetRefreshManageChecklistClient(); // Reseting the Client Id to 0 on page load to hide Motif "Refresh" Toast Message

        this.setGridColumnState();
    }

    /**
     * Invoked immediately before unmounting occurs
     * @returns {undefined}
     */
    componentWillUnmount() {
        // On unmount, clear the calendar data from state to conserve memory
        // Calling this action with no argument will clear from redux-state
        this.props.checklistActions.fetchManageChecklists();
        this.props.checklistActions.resetRefreshManageChecklistClient(); // Reseting the Client Id to 0 on page unmount to hide Motif "Refresh" Toast Message on subsequest redirected view

        this.setState({ filteredManageChecklists: [], selectedRecipients: [] });
    }

    componentWillReceiveProps(nextProps) {
        // Set the period if it doesn't exist
        if (!this.props.period) {
            this.props.periodActions.fetchPeriodById(this.props.periodId);
        }
        else {
            this.setCanEdit(this.props.period);
        }

    }

    updateInitialValues() {
        const initialValues = {
            clientId: this.props.clientId
        };

        this.props.dispatch(this.props.initialize(
            initialValues,
            { options: { updateUnregisteredFields: true } }
        ));
    }

    setCanEdit(period) {
        const isEnabled = periodActions.isReportingEnabled(period);
        this.setState({ canEdit: isEnabled });
    }

    toggleShowAddChecklistUserModal() {
        this.setState({
            showAddChecklistUserModal: !this.state.showAddChecklistUserModal,
            checklistUsers: []
        });
    }

    refreshGrid(selectedChecklists = []) {
        // Reset the refresh manage checklist message for client
        this.props.checklistActions.resetRefreshManageChecklistClient();

        this.props.checklistActions.fetchManageChecklists(this.props.clientId, null, this.props.periodId, 'PSQ')
            .then(() => {

                this.updateInitialValues();

                //Update refreshGridChecklistData state variable to refresh the grid
                const randomNumber = parseInt(Math.random() * (99999999 - 1)) + 1;

                if (selectedChecklists && Array.isArray(selectedChecklists) && selectedChecklists.length > 0) {
                    this.setState({
                        refreshGridChecklistData: {
                            refreshGridOnCompletedAction: randomNumber,
                            checklists: selectedChecklists
                        }
                    });
                }
                else {
                    this.setState({
                        refreshGridChecklistData: {
                            refreshGridOnCompletedAction: randomNumber,
                            checklists: []
                        }
                    });
                }
            });
    }

    handleGridFilteredRowChanges(gridFilteredRowData) {
        this.setState({ gridFilteredRowCurrentState: gridFilteredRowData });
    }

    handleGridChanges(gridRowData) {
        let rowData = [];
        rowData = gridRowData && gridRowData.length && gridRowData.map(x => {
            return {
                checklistID: x.checklistID,
                checklistSelection: x.checklistSelection,
                checklistState: x.checklistState,
                checklistType: x.checklistType,
                quarterStatus: x.quarterStatus,
                checklistStateDescription: x.checklistStateDescription,
                reopenRequestStatusDescription: x.reopenRequestStatusDescription,
                requestDateTime: x.requestDateTime,
                requesterEmailAddress: x.requesterEmailAddress,
                isSignedOff: x.isSignedOff,
                signedOffStatusDescription: x.signedOffStatusDescription,
                userActionDateTime: x.userActionDateTime,
                clientUserEmailAddress: x.clientUserEmailAddress,
                quarter: x.quarter,
                reitName: x.reitName,
                reportPeriodID: x.reportPeriodID,
                propertyType: x.propertyType,
                propertyTypeDescription: x.propertyTypeDescription,
                propertyName: x.propertyName
            }
        });

        this.setState({ gridCurrentState: rowData });
    }

    handleRecipientChanges(gridApi) {

        let selectedRecipients = [];
        if (gridApi) {
            let selectedNodes = gridApi.getSelectedNodes()
                ? gridApi.getSelectedNodes().filter(rowNode => rowNode.data && rowNode.data.recipientEmail && rowNode.data.recipientEmail.length > 0)
                : [];
            selectedRecipients = selectedNodes && selectedNodes.length && selectedNodes.map(x => {
                return {
                    checklistID: x.data.checklistID,
                    checklistSelection: x.data.checklistSelection,
                    checklistType: x.data.checklistType,
                    checklistState: x.data.checklistState,
                    quarterStatus: x.data.quarterStatus,
                    expirationDate: x.data.expirationDate,
                    recipientEmail: x.data.recipientEmail,
                    recipients: x.data.recipients,
                    senderEmail: x.data.senderEmail,
                    sentDate: x.data.sentDate,
                    quarter: x.data.quarter,
                    reitName: x.data.reitName,
                    propertyType: x.data.propertyType,
                    propertyTypeDescription: x.data.propertyTypeDescription,
                    propertyName: x.data.propertyName
                }
            });
        }
        this.setState({ selectedRecipients: selectedRecipients });
    }

    handleEmailChanged(e) {
        let checklistEmailData = [];
        let uniqueArray = [];
        let isShowInvalidModal  = false;
        if (Array.isArray(e)) {
            if (e.length > 0) {
                 uniqueArray = [...new Set(e.join(";").toLowerCase().split(";"))];
                 for (let i = 0; i < uniqueArray.length; i++) {
                    if (this.isEYEmailAddress(uniqueArray[i])) {
                        isShowInvalidModal = true;
                    } else {
                        checklistEmailData.push(uniqueArray[i]);
                    }
                }

            
            }
        }
        if (isShowInvalidModal) {
            this.setState({ invalidHeader: Constants.invalidEmailAddUserHeader });
            this.toggleShowInvalidEmailModal();
        }
        this.setState({ checklistUsers: checklistEmailData });
    }

    handleAddChecklistUser() {
        let emailDetails = this.state.checklistUsers && Array.isArray(this.state.checklistUsers) && this.state.checklistUsers.length > 0
            ? this.state.checklistUsers : [];

        let selectedChecklists = [];

        if (this.state.gridCurrentState && Array.isArray(this.state.gridCurrentState) && this.state.gridCurrentState.length > 0
            && emailDetails) {
            selectedChecklists = this.state.gridCurrentState.reduce(function (filtered, option) {
                if (option.checklistSelection) {
                    var checklistItem = {
                        checklistID: option.checklistID,
                        checklistType: option && option.checklistType ? option.checklistType.toLowerCase() : ''
                    }
                    if (filtered.findIndex(x => x.checklistID === checklistItem.checklistID && x.checklistType === checklistItem.checklistType) == -1) {
                        filtered.push(checklistItem);
                    }
                }
                return filtered;
            }, []);
        }

        let requestData = {
            clientID: this.props.clientId,
            internalEmails: [],
            externalEmails: emailDetails,
            checklists: selectedChecklists
        };

        this.props.checklistActions
            .addChecklistUsers(this.props.clientId, requestData)
            .then(() => {
                this.toggleShowAddChecklistUserModal();
                this.setState({
                    checklistUsers: []
                });

                this.refreshGrid(selectedChecklists);
            });
    }

    toggleShowDeleteChecklistUserModal() {
        this.setState({
            showDeleteChecklistUserModal: !this.state.showDeleteChecklistUserModal
        });
    }

    handleDeleteChecklistUser() {

        let recipientsList = (this.state.selectedRecipients && Array.isArray(this.state.selectedRecipients) && this.state.selectedRecipients.length > 0)
            ? this.state.selectedRecipients.map(x => {
                return {
                    checklistID: x.checklistID,
                    checklistType: x.checklistType,
                    emails: x.recipientEmail.toLowerCase()
                }
            })
            : [];

        var helper = {};
        var uniqueRecipientsChecklists = (recipientsList && Array.isArray(recipientsList)) ? recipientsList.reduce(function (r, o) {
            var key = o.checklistID + '_' + o.checklistType;

            if (!helper[key]) {
                helper[key] = {
                    checklistID: o.checklistID,
                    checklistType: o.checklistType,
                    emails: [o.emails.toLowerCase()]
                }
                r.push(helper[key]);
            } else {
                helper[key].emails.push(o.emails);
            }

            return r;
        }, []) : [];


        let requestData = {
            clientID: this.props.clientId,
            checklists: uniqueRecipientsChecklists
        };

        this.props.checklistActions
            .deleteChecklistUsers(this.props.clientId, requestData)
            .then(() => {
                this.toggleShowDeleteChecklistUserModal();
                this.refreshGrid(uniqueRecipientsChecklists);
            });
    }

    toggleShowDeleteChecklistModal() {
        this.setState({
            showDeleteChecklistModal: !this.state.showDeleteChecklistModal
        });
    }

    handleDeleteChecklist(values) {
        let checklistsToDelete = this.state.gridCurrentState.filter(gridData => gridData.checklistSelection)
            .map(gridData => {
                return {
                    checklistID: gridData.checklistID,
                    type: gridData.checklistType
                }
            });

        // Remove duplicate checklists
        checklistsToDelete = checklistsToDelete.filter((value, index, self) =>
            self.findIndex((self) => self.checklistID === value.checklistID && self.type === value.type) === index);

        const requestData = {
            "clientId": this.props.clientId,
            "deleteChecklistRequestModels": checklistsToDelete
        };

        this.props.checklistActions
            .deleteChecklists(requestData)
            .then(() => {
                this.toggleShowDeleteChecklistModal();
                this.refreshGrid();
            });
    }

    toggleShowResendChecklistModal() {
        this.setState({
            showResendChecklistModal: !this.state.showResendChecklistModal
        });
    }

    toggleShowInvalidEmailModal() {
        const showToggle = !this.state.showInvalidEmailModal;
        this.setState({
            showInvalidEmailModal: showToggle,
        });
    }

    deleteInvalidEmail() {
        this.toggleShowInvalidEmailModal();

    }
    handleResendChecklist(values) {        
        const checklistsToResend = this.state.selectedRecipients
            .map(recipient => {                
                return {
                    checklistID: recipient.checklistID,
                    checklistType: recipient.checklistType,
                    recipientEmail: recipient.recipientEmail,
                    reitName: recipient.reitName,
                    propertyType: recipient.propertyTypeDescription,
                    propertyName: recipient.propertyName,
                    periodDescription: this.props.period.reportPeriodDescription
                }
            });

        const requestData = {
            "clientId": this.props.clientId,
            "resendChecklistRequestModels": checklistsToResend
        };

        this.props.checklistActions
            .resendChecklists(requestData)
            .then(() => {
                this.toggleShowResendChecklistModal();
                this.refreshGrid(this.getSelectedChecklists());
            });
    }

    toggleShowSignoffChecklistModal() {
        this.setState({
            showSignoffChecklistModal: !this.state.showSignoffChecklistModal
        });
    }

    toggleShowReopenForEYReviewChecklistModal() {
        this.setState({
            showReopenForEYReviewChecklistModal: !this.state.showReopenForEYReviewChecklistModal
        });
    }

    toggleShowApproveClientReopenRequestModal() {
        this.setState({
            showApproveClientReopenRequestModal: !this.state.showApproveClientReopenRequestModal
        });
    }

    toggleShowDenyClientReopenRequestModal() {
        this.setState({
            showDenyClientReopenRequestModal: !this.state.showDenyClientReopenRequestModal
        });
    }

    handleSignoffChecklistStateChange(checklistState) {
        this.setState({
            selectedSignoffChecklistState: checklistState
        });
    }

    async handleReopenForEYReviewChecklist() {

        let selectedChecklists = [];
        if (this.state.gridCurrentState && Array.isArray(this.state.gridCurrentState) && this.state.gridCurrentState.length > 0) {
            selectedChecklists = this.state.gridCurrentState.reduce(function (filtered, option) {
                if (option.checklistSelection) {
                    var checklistItem = {
                        checklistID: option.checklistID,
                        checklistType: option && option.checklistType ? option.checklistType.toLowerCase() : ''
                    }
                    if (filtered.findIndex(x => x.checklistID === checklistItem.checklistID && x.checklistType === checklistItem.checklistType) == -1) {
                        filtered.push(checklistItem);
                    }
                }
                return filtered;
            }, []);
        }

        let splitedChecklists = this.spliceSelectedChecklists(
            selectedChecklists.slice(),
            50
        );

        this.props.dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        await checklistActions
            .updateChecklistsState(this.props.clientId, splitedChecklists, 'PENDINGEYREVIEW')
            .then(() => {
                this.toggleShowReopenForEYReviewChecklistModal();
                this.refreshGrid(selectedChecklists);
                this.props.dispatch({ type: actions.TASK_END, [pendingTask]: end });
            })
            .catch((error) => {
                this.toggleShowReopenForEYReviewChecklistModal();
                this.refreshGrid(selectedChecklists);
                return actionHelpers.dispatchErrorAndEndTask(
                    this.props.dispatch,
                    actions.UPDATE_CHECKLISTS_STATE_FAILURE,
                    error,
                );
            });
    }

    async handleSignoffChecklist() {

        let selectedChecklists = [];
        if (this.state.gridCurrentState && Array.isArray(this.state.gridCurrentState) && this.state.gridCurrentState.length > 0) {
            selectedChecklists = this.state.gridCurrentState.reduce(function (filtered, option) {
                if (option.checklistSelection) {
                    var checklistItem = {
                        checklistID: option.checklistID,
                        checklistType: option && option.checklistType ? option.checklistType.toLowerCase() : ''
                    }
                    if (filtered.findIndex(x => x.checklistID === checklistItem.checklistID && x.checklistType === checklistItem.checklistType) == -1) {
                        filtered.push(checklistItem);
                    }
                }
                return filtered;
            }, []);
        }

        let splitedChecklists = this.spliceSelectedChecklists(
            selectedChecklists.slice(),
            50
        );

        this.props.dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        await checklistActions
            .updateChecklistsState(this.props.clientId, splitedChecklists, 'COMPLETED')
            .then(() => {
                this.toggleShowSignoffChecklistModal();
                this.refreshGrid(selectedChecklists);
                this.props.dispatch({ type: actions.TASK_END, [pendingTask]: end });
            })
            .catch((error) => {
                this.toggleShowSignoffChecklistModal();
                this.refreshGrid(selectedChecklists);
                return actionHelpers.dispatchErrorAndEndTask(
                    this.props.dispatch,
                    actions.UPDATE_CHECKLISTS_STATE_FAILURE,
                    error,
                );
            });
    }

    async handleApproveClientReopenRequest() {

        let selectedChecklists = [];
        if (this.state.gridCurrentState && Array.isArray(this.state.gridCurrentState) && this.state.gridCurrentState.length > 0) {
            selectedChecklists = this.state.gridCurrentState.reduce(function (filtered, option) {
                if (option.checklistSelection) {
                    var checklistItem = {
                        checklistID: option.checklistID,
                        checklistType: option && option.checklistType ? option.checklistType.toLowerCase() : '',
                        requesterEmailAddress: option.requesterEmailAddress
                    }
                    if (filtered.findIndex(x => x.checklistID === checklistItem.checklistID && x.checklistType === checklistItem.checklistType) == -1) {
                        filtered.push(checklistItem);
                    }
                }
                return filtered;
            }, []);
        }

        let splitedChecklists = this.splitChecklistsPerRequester(
            selectedChecklists.slice(),
            50
        );

        this.props.dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        await checklistActions
            .approveDenyClientRequest(this.props.clientId, splitedChecklists, 'APPROVED')
            .then(() => {
                this.toggleShowApproveClientReopenRequestModal();
                this.refreshGrid(selectedChecklists);
                this.props.dispatch({ type: actions.TASK_END, [pendingTask]: end });

            })
            .catch((error) => {
                this.toggleShowApproveClientReopenRequestModal();
                this.refreshGrid(selectedChecklists);
                return actionHelpers.dispatchErrorAndEndTask(
                    this.props.dispatch,
                    actions.APPROVE_DENY_CHECKLISTS_REQUEST_FAILURE,
                    error,
                );
            });
    }

    spliceSelectedChecklists(arr, size) {
        let res = [];
        while (arr.length > 0) {
            const chunk = arr.splice(0, size);
            res.push(chunk);
        }
        return res;
    }

    splitChecklistsPerRequester(checklists, batchSize) {
        let groups = {}, result = [];
        let key = 'requesterEmailAddress';
        checklists.forEach(function (checklist) {
            if (!(checklist[key] in groups)) {
                groups[checklist[key]] = [];
            }
            groups[checklist[key]].push(checklist);
        });

        for (let property in groups) {
            let group = groups[property];
            if (group.length > batchSize) {
                result.push(...this.spliceSelectedChecklists(group, batchSize));
            }
            else {
                result.push(group);
            }
        }

        return result;
    }

    getSelectedChecklists() {
        let res = [];
        if (this.state.gridCurrentState && Array.isArray(this.state.gridCurrentState) && this.state.gridCurrentState.length > 0) {
            res = this.state.gridCurrentState.reduce(function (filtered, option) {
                if (option.checklistSelection) {
                    var checklistItem = {
                        checklistID: option.checklistID,
                        checklistType: option && option.checklistType ? option.checklistType.toLowerCase() : ''
                    }
                    if (filtered.findIndex(x => x.checklistID === checklistItem.checklistID && x.checklistType === checklistItem.checklistType) == -1) {
                        filtered.push(checklistItem);
                    }
                }
                return filtered;
            }, []);
        }
        return res;
    }

    async handleDenyClientReopenRequest() {
        let selectedChecklists = [];
        if (this.state.gridCurrentState && Array.isArray(this.state.gridCurrentState) && this.state.gridCurrentState.length > 0) {
            selectedChecklists = this.state.gridCurrentState.reduce(function (filtered, option) {
                if (option.checklistSelection) {
                    var checklistItem = {
                        checklistID: option.checklistID,
                        checklistType: option && option.checklistType ? option.checklistType.toLowerCase() : '',
                        requesterEmailAddress: option.requesterEmailAddress
                    }
                    if (filtered.findIndex(x => x.checklistID === checklistItem.checklistID && x.checklistType === checklistItem.checklistType) == -1) {
                        filtered.push(checklistItem);
                    }
                }
                return filtered;
            }, []);
        }

        let splitedChecklists = this.splitChecklistsPerRequester(
            selectedChecklists.slice(),
            50
        );

        this.props.dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        await checklistActions
            .approveDenyClientRequest(this.props.clientId, splitedChecklists, 'DENIED')
            .then(() => {
                this.toggleShowDenyClientReopenRequestModal();
                this.refreshGrid(selectedChecklists);
                this.props.dispatch({ type: actions.TASK_END, [pendingTask]: end });

            })
            .catch((error) => {
                this.toggleShowDenyClientReopenRequestModal();
                this.refreshGrid(selectedChecklists);
                return actionHelpers.dispatchErrorAndEndTask(
                    this.props.dispatch,
                    actions.APPROVE_DENY_CHECKLISTS_REQUEST_FAILURE,
                    error,
                );
            });
    }

    handleExportChecklistSummary(values) {
        let checklistsToExport = this.state.gridCurrentState.filter(gridData => gridData.checklistSelection)
            .map(gridData => {
                return {
                    checklistID: gridData.checklistID,
                    checklistType: gridData.checklistType
                }
            });
        let checklistData = Object.assign({}, checklistsToExport);

        // Remove duplicate checklists
        checklistData = checklistsToExport.filter((value, index, self) =>
            self.findIndex((self) => self.checklistID === value.checklistID && self.type === value.type) === index);

        const requestData = {
            "clientId": this.props.clientId,
            "calendarYear": new Date(this.props.period.endDate).getFullYear(),
            "exportChecklistRequestModels": checklistData
        };

        this.props.checklistActions
            .exportChecklists(requestData);
    }

    handleCreateField(e, data) {
        let createData = {
            checklistTypeID: 'PSQ',
            clientID: this.props.client.id,
            reitID: this.props.reit.reitid,
            reportPeriodID: this.props.periodId,
            propertyID: null,
            clientEmailTo: '',
            propertyType: null,
            checklistStateID: 'Created',
            questionnaireTemplateId: null,
            prePopulatedId: Constants.defaultPrepopulateAnswer,
            hasRollForwardQuestionComment: Constants.defaultRollFowardQuestionComment,
            hasRollForwardQuestionAttachment: Constants.defaultRollFowardQuestionAttachment

        };
        if (
            this.state !== undefined &&
            this.state.createData !== undefined &&
            this.state.createData !== null
        ) {
            createData = this.state.createData;
        }

        let fieldName = null;
        let value = null;
        if (data === undefined) {
            fieldName = e.target.name;
            value = e.target.value;
        } else {
            fieldName = data.name;
            value = data.value;
        }

        if (fieldName === undefined) {
            fieldName = e.target.name;
        }

        let propertyName = '';
        let propertyType = '';

        switch (fieldName) {
            case 'propertyField':
                createData.propertyID = value;
                for (let option of data.options) {
                    if (option.key === value) {
                        propertyName = option.text;
                        break;
                    }
                }

                this.setState({
                    selectedPropertyId: value,
                    selectedPropertyName: propertyName,
                });
                break;
            case 'propertyTypeField':
                createData.propertyType = value;
                for (let option of data.options) {
                    if (option.key === value) {
                        propertyType = option.text;
                        break;
                    }
                }
                this.setState({ selectedPropertyType: propertyType });
                break;
            case 'populatePreviousAnswersField':
                createData.prePopulatedId = value;
                break;
            case 'customTemplateField':
                createData.questionnaireTemplateId = value;
                break;
                case 'rollForwardQuestionCommentsField':

                createData.hasRollForwardQuestionComment = Constants.defaultRollFowardQuestionComment;
                createData.hasRollForwardQuestionAttachment = Constants.defaultRollFowardQuestionAttachment;
                if (value == "2" || value == "3") {
                    createData.hasRollForwardQuestionComment = 1;
                }
                if (value == "1" || value == "3") {
                    createData.hasRollForwardQuestionAttachment = 1;
                }
                    break;
        }

        createData.clientID = this.props.client.id;
        createData.reitID = this.props.reit.reitid;
        createData.reportPeriodID = this.props.periodId;

        // Validate dropdown values
        if (createData.propertyID && createData.propertyType && createData.questionnaireTemplateId) {
            this.setState({ isCreateOnlyEnabled: true });
        }

        this.setState({ createData: createData });
    }

    clientEmailsRef(ref) {
        let clientEmails = '';
        if (
            this.state.reportPeriodSummary !== undefined &&
            this.state.reportPeriodSummary !== null
        ) {
            let clientContactEmail = this.state.reportPeriodSummary
                .clientContactEmail;
            if (
                clientContactEmail !== undefined &&
                clientContactEmail !== null &&
                !this.isEYEmailAddress(clientContactEmail) &&
                clientContactEmail !== '' &&
                !clientEmails.includes(clientContactEmail.toLowerCase().trim())
            ) {
                clientEmails = clientContactEmail.toLowerCase().trim() + ';';
            }

            for (let property of this.state.reportPeriodSummary.periodProperties) {
                let email = property.managerEmail;
                if (
                    email !== undefined &&
                    email !== null &&
                    email !== '' &&
                    !this.isEYEmailAddress(email) &&
                    property.propertyID === this.state.selectedPropertyId &&
                    !clientEmails.includes(email.toLowerCase().trim())
                ) {
                    clientEmails += email.toLowerCase().trim() + ';';
                }
                if (property.accountantEmail && !this.isEYEmailAddress(property.accountantEmail)) {
                    email = property.accountantEmail.toLowerCase().trim();
                }
                if (
                    email !== undefined &&
                    email !== null &&
                    email !== '' &&
                    property.propertyID === this.state.selectedPropertyId &&
                    !clientEmails.includes(email.toLowerCase().trim())
                ) {
                    clientEmails += email.toLowerCase().trim() + ';'
                }
            }

            if (ref) {
                ref.findEmailAddress(clientEmails, false);
            }

            //This functionality is to update clientEmailTo field of createData state variable
            let createData = {
                checklistTypeID: 'PSQ',
                clientID: this.props.client.id,
                reitID: this.props.reit.reitid,
                reportPeriodID: this.props.periodId,
                propertyID: null,
                clientEmailTo: '',
                propertyType: null,
                checklistStateID: 'Created',
                prePopulatedId: Constants.defaultPrepopulateAnswer,
                hasRollForwardQuestionComment: Constants.defaultRollFowardQuestionComment,
                hasRollForwardQuestionAttachment: Constants.defaultRollFowardQuestionAttachment
                
            };
            if (
                this.state !== undefined &&
                this.state.createData !== undefined &&
                this.state.createData !== null
            ) {
                createData = this.state.createData;
                createData.clientEmailTo = clientEmails;
                this.validateEmail(createData);
                this.setState({ createData: createData });
            }
        }
    }

    handleDismissErrorMessage() {
        this.props.commonActions.clearFormErrors('psqcreatechecklistmodal');
    }
    handleClientEmailChanged(e) {
        let createData = {
            checklistTypeID: 'PSQ',
            clientID: this.props.client.id,
            reitID: this.props.reit.reitid,
            reportPeriodID: this.props.periodId,
            propertyID: null,
            clientEmailTo: '',
            propertyType: null,
            checklistStateID: 'Created',
            prePopulatedId: Constants.defaultPrepopulateAnswer,
            hasRollForwardQuestionComment: Constants.defaultRollFowardQuestionComment,
            hasRollForwardQuestionAttachment: Constants.defaultRollFowardQuestionAttachment
        };
        if (
            this.state !== undefined &&
            this.state.createData !== undefined &&
            this.state.createData !== null
        ) {
            createData = this.state.createData;
        }

        let isShowInvalidModal = false;
        let uniqueArray = [];
        let splicedArray = [];
        if (Array.isArray(e)) {
            if (e.length > 0) {
                uniqueArray = [
                    ...new Set(e.join(";").toLowerCase().split(";")),
                ];

                for (let i = 0; i < uniqueArray.length; i++) {
                    if (this.isEYEmailAddress(uniqueArray[i])) {
                        isShowInvalidModal = true;
                    } else {
                        splicedArray.push(uniqueArray[i]);
                    }
                }

                createData.clientEmailTo = splicedArray.join(";");
            } else {
                createData.clientEmailTo = "";
            }
        }

        if (isShowInvalidModal) {
            this.setState({ invalidHeader: Constants.invalidEmailCreateChecklistHeader });
            this.toggleShowInvalidEmailModal();
        }
        this.validateEmail(createData);
        this.setState({ createData: createData });
        this.setState({ clientEmail: uniqueArray });

    }

    validateEmail(createData) {
        // Validate that both send to email and accountant email have text and are valid email addresses
        if (
            createData.clientEmailTo.length > 0
        ) {
            // Validate Send To Multiple Email Addresses
            let sendToEmailValidation = false;
            let isRejectedDomain = false;

            // Get Array of Email Addresses
            const sendToEmailsList = createData.clientEmailTo.split(";");
            for (let email of sendToEmailsList) {
                if (formValidators.email(email.trim()) === 'Invalid email address') {
                    sendToEmailValidation = true;
                }

            }

            // Validate both Send To and Accountant Email Addresses
            if (sendToEmailValidation && isRejectedDomain) {
                this.setState({ isSendEmailEnabled: false });
            } else {
                this.setState({ isSendEmailEnabled: true });
            }
        } else {
            this.setState({ isSendEmailEnabled: false });
        }
    }

    /**
    * Set AG-Grid column state   
    * @returns {undefined}
    */
    setGridColumnState() {
        var columnState = localStorage.getItem('REITSuitePSQChecklistColumnState');
        if (columnState) {
            this.setState({ gridColumnState: JSON.parse(columnState) });
        }
        else {
            this.setState({ gridColumnState: [{ colId: '-1' }] });
        }
    }


    reloadSummaryData() {
        this.setState({ isSendEmailEnabled: false, isCreateOnlyEnabled: false });
        this.props.checklistActions.fetchManageChecklists(this.props.clientId, null, this.props.periodId, 'PSQ');
    }

    /**
    * Reset AG-Grid column state
    * @returns {undefined}
    */
    resetGridColumnState() {
        localStorage.removeItem('REITSuitePSQChecklistColumnState');
        this.setState({ gridColumnState: [] });
    }

    /**
    * Handle Grid column state chagne
    * @returns {undefined}
    */
    handleGridColumnStateChange(columnState) {
        this.setState({ gridColumnState: columnState });
    }

    handleRefreshManageChecklistClient() {
        this.refreshGrid();
    }

    dismissRefreshManageChecklistMessage() {
        this.props.checklistActions.resetRefreshManageChecklistClient();
    }

    handlePopulatePreviousAnswersChanged(e) {
        let createData = {
            checklistTypeID: 'PSQ',
            clientID: this.props.client.id,
            reitID: this.props.reit.reitid,
            reportPeriodID: this.props.periodId,
            propertyID: null,
            clientEmailTo: '',
            propertyType: null,
            checklistStateID: 'Created',
            questionnaireTemplateId: null,
            prePopulatedId: Constants.defaultPrepopulateAnswer,
            hasRollForwardQuestionComment: Constants.defaultRollFowardQuestionComment,
            hasRollForwardQuestionAttachment: Constants.defaultRollFowardQuestionAttachment
        };

        if (
            this.state !== undefined &&
            this.state.createData !== undefined &&
            this.state.createData !== null
        ) {
            createData = this.state.createData;
        }

        createData.prePopulatedId = e.target.checked;

        this.validateEmail(createData);

        this.setState({ createData: createData });
    }

    toggleShowDownloadChecklistsModal(downloadType) {
        if (this.state.downloadChecklists.showModal === false) {
            switch (downloadType) {
                case 'WITHOUT_ANSWERS': {
                    const downloadChecklistsState = {
                        downloadType: downloadType,
                        showModal: !this.state.downloadChecklists.showModal,
                        modalTitle: 'Download Checklist(s) without Answers or Comments',
                        modalContent:
                            'Are you sure you want to download the selected checklist(s) without answers or comments?',
                    };
                    this.setState({ downloadChecklists: downloadChecklistsState });
                    break;
                }
                case 'WITH_ANSWERS_COMMENTS': {
                    const downloadChecklistsState = {
                        downloadType: downloadType,
                        showModal: !this.state.downloadChecklists.showModal,
                        modalTitle: 'Download Checklist(s) with Answers and Comments',
                        modalContent:
                            'Are you sure you want to download the selected checklist(s) with answers and comments?',
                    };
                    this.setState({ downloadChecklists: downloadChecklistsState });
                    break;
                }
                case 'WITH_ANSWERS': {
                    const downloadChecklistsState = {
                        downloadType: downloadType,
                        showModal: !this.state.downloadChecklists.showModal,
                        modalTitle: 'Download Checklist(s) with Answers Only',
                        modalContent:
                            'Are you sure you want to download the selected checklist(s) with answers?',
                    };
                    this.setState({ downloadChecklists: downloadChecklistsState });
                    break;
                }
                default:
                    break;
            }
        } else {
            const downloadChecklistsState = {
                showModal: !this.state.downloadChecklists.showModal,
                modalTitle: '',
                modalContent: '',
                downloadType: '',
            };
            this.setState({ downloadChecklists: downloadChecklistsState });
        }
    }

    //  Handle download checklist
    handleDownloadChecklists(downloadType) {
        // Getting a copy of the selected checklists from the local state
        const selectedChecklists = this.state.gridCurrentState.filter(gridData => gridData.checklistSelection)
            .map(gridData => {
                return {
                    checklistID: gridData.checklistID,
                    checklistType: gridData.checklistType,
                    quarter: gridData.quarter,
                    reitName: gridData.reitName,
                    reportPeriodID: gridData.reportPeriodID,
                    propertyName: gridData.propertyName,
                    propertyTypeDescription: gridData.propertyTypeDescription
                }
            });

        //Get unique checklists
        let uniqueselectedChecklists = Object.assign({}, selectedChecklists);
        uniqueselectedChecklists = selectedChecklists.filter((value, index, self) =>
            self.findIndex((self) => self.checklistID === value.checklistID) === index);

        // Creating a object array for all the selected checklists with corresponding request details
        const checklistRequestDetails = uniqueselectedChecklists.map((c) => ({
            reitNameAndPeriod: `reitName=${c.reitName},periodDesc=${this.props.period.reportPeriodDescription}`,
            periodId: c.reportPeriodID,
            id: c.checklistID,
            isExternal: true,
            filename: '',
            reitName: c.reitName,
            propertyName: c.propertyName,
            propertyTypeDescription: c.propertyTypeDescription,
            quarter: c.quarter,
        }));

        // Logic for file name
        checklistRequestDetails.forEach((item) => {
            // Setting a defualt file name based on the Report period, Year, RIET, Property name and Property type description
            const defaultFilename = `${this.props.period.reportPeriodDescription.replace(' ', '')}_${item.reitName}_${item.propertyName}_${item.propertyTypeDescription}_PSQ`;

            // Checking if a row object is already created with the standard file name
            const existingFiles = checklistRequestDetails
                .filter((c) => c.filename.includes(defaultFilename))
                .sort();

            if (existingFiles.length > 0) {
                //Splilling the file name based on "_"
                const fileNameSplitArray = existingFiles[
                    existingFiles.length - 1
                ].filename
                    .split('.')[0]
                    .split('_');

                if (existingFiles.length > 1) {
                    /* Case when there are more than one files with the same combination of Report period, Year, RIET, Property name and Property type description
                       In such cases, incrementing the version at the end */
                    const currentVersion = parseInt(fileNameSplitArray[fileNameSplitArray.length - 1]);
                    fileNameSplitArray[fileNameSplitArray.length - 1] = (currentVersion + 1).toString();
                    item.filename = `${fileNameSplitArray.join('_')}.docx`;
                } else {
                    // Case when there is only one file with the existing combination, hence appending the version as 1 at the end
                    item.filename = `${fileNameSplitArray.join('_')}_1.docx`;
                }
            } else {
                // Case when there is no file name with the combination
                item.filename = `${defaultFilename}.docx`;
            }
        });

        // Preparing the request body
        const request = {
            client: null,
            calendarYear: new Date(this.props.period.endDate).getFullYear(),
            checklistRequestDetails: checklistRequestDetails,
            clientId: this.props.clientId
        };

        // Calling the action to fetch data from the API
        this.props.reportActions
            .downloadChecklists(request, 'PSQ', downloadType)
            .then(() => {
                // Refreshing grid and toggling the modal to false
                this.refreshGrid(this.getSelectedChecklists());
                this.toggleShowDownloadChecklistsModal(downloadType);
            })
            .catch(() => {
                // Toggle the modal to fasle
                this.toggleShowDownloadChecklistsModal(downloadType);
            });
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col">
                        <h1 className="pageheader">
                            Property Service Questionnaire
                        </h1>
                    </div>
                </div>                
                <ChecklistHeader
                    formSyncErrors={this.props.formSyncErrors}
                    isSystemOrEngagementAdmin={
                        (authActions.isSystemAdministrator(this.props.currentUserAuthorizations)
                            || authActions.isEngagementAdministrator(this.props.currentUserAuthorizations, this.props.clientId))
                    }
                    isEngagementUser={
                        authActions.isUser(this.props.currentUserAuthorizations, this.props.clientId)
                    }
                    selectedPsqArqc={this.state.psqArqc}
                    clientIsAudit={this.props.clientIsAudit}
                    resetGridColumnState={this.resetGridColumnState}
                    gridCurrentState={this.state.gridCurrentState}
                    gridFilteredRowCurrentState={this.state.gridFilteredRowCurrentState}
                    selectedRecipients={this.state.selectedRecipients}
                    toggleShowAddChecklistUserModal={
                        this.toggleShowAddChecklistUserModal
                    }
                    toggleShowDeleteChecklistUserModal={
                        this.toggleShowDeleteChecklistUserModal
                    }
                    toggleShowDeleteChecklistModal={
                        this.toggleShowDeleteChecklistModal
                    }
                    toggleShowResendChecklistModal={
                        this.toggleShowResendChecklistModal
                    }
                    toggleShowSignoffChecklistModal={
                        this.toggleShowSignoffChecklistModal
                    }
                    toggleShowReopenForEYReviewChecklistModal={
                        this.toggleShowReopenForEYReviewChecklistModal
                    }
                    toggleShowApproveClientReopenRequestModal={
                        this.toggleShowApproveClientReopenRequestModal
                    }
                    toggleShowDenyClientReopenRequestModal={
                        this.toggleShowDenyClientReopenRequestModal
                    }
                    handleExportChecklistSummary={this.handleExportChecklistSummary}
                    toggleShowDownloadChecklistsModal={this.toggleShowDownloadChecklistsModal}
                    handleDownloadChecklist={this.handleDownloadChecklist}
                    showCreateModal={this.showCreateModal}
                    canEdit={this.state.canEdit}
                />

                {this.props.manageChecklists && this.props.manageChecklists.length === 0 &&
                    <div>No PSQs found for this period.</div>}

                {(this.props.refreshManageChecklistClientId === this.props.clientId) &&
                    <MotifToast variant="info" onClose={this.dismissRefreshManageChecklistMessage}>
                        Contents on the checklist table below has been changed, please <MotifButton variant="text-alt" onClick={this.handleRefreshManageChecklistClient}>refresh</MotifButton> the table.
                    </MotifToast>}
                <PSQChecklistTable
                    filteredManageChecklists={this.props.manageChecklists}
                    gridColumnState={this.state.gridColumnState}
                    handleGridColumnStateChange={this.handleGridColumnStateChange}
                    clientId={this.props.clientId}
                    handleGridChanges={this.handleGridChanges}
                    handleGridFilteredRowChanges={this.handleGridFilteredRowChanges}
                    handleRecipientChanges={this.handleRecipientChanges}
                    refreshGridChecklistData={this.state.refreshGridChecklistData}
                />
                <DownloadChecklistsModal
                    showDownloadChecklistsModal={
                        this.state.downloadChecklists.showModal
                    }
                    downloadType={this.state.downloadChecklists.downloadType}
                    modalTitle={this.state.downloadChecklists.modalTitle}
                    modalContent={this.state.downloadChecklists.modalContent}
                    selectedChecklists={this.getSelectedChecklists()}
                    toggleShowDownloadChecklistsModal={
                        this.toggleShowDownloadChecklistsModal
                    }
                    handleDownloadChecklists={this.handleDownloadChecklists}
                ></DownloadChecklistsModal>
                <AddChecklistUserModal
                    showAddChecklistUserModal={
                        this.state.showAddChecklistUserModal
                    }
                    onSubmit={this.handleAddChecklistUser}
                    handleAddChecklistUser={this.handleAddChecklistUser}
                    toggleShowAddChecklistUserModal={
                        this.toggleShowAddChecklistUserModal
                    }
                    handleEmailChanged={this.handleEmailChanged}
                    emails={this.state.checklistUsers}
                    gridCurrentState={this.state.gridCurrentState}
                />
                <DeleteChecklistUserModal
                    showDeleteChecklistUserModal={
                        this.state.showDeleteChecklistUserModal
                    }
                    handleDeleteChecklistUser={this.handleDeleteChecklistUser}
                    toggleShowDeleteChecklistUserModal={
                        this.toggleShowDeleteChecklistUserModal
                    }
                />
                <ReopenForEYReviewChecklistModal
                    showReopenForEYReviewChecklistModal={
                        this.state.showReopenForEYReviewChecklistModal
                    }
                    gridCurrentState={this.state.gridCurrentState}
                    handleReopenForEYReviewChecklist={this.handleReopenForEYReviewChecklist}
                    toggleShowReopenForEYReviewChecklistModal={
                        this.toggleShowReopenForEYReviewChecklistModal
                    }
                />
                <SignoffChecklistModal
                    showSignoffChecklistModal={
                        this.state.showSignoffChecklistModal
                    }
                    gridCurrentState={this.state.gridCurrentState}
                    handleSignoffChecklist={this.handleSignoffChecklist}
                    toggleShowSignoffChecklistModal={
                        this.toggleShowSignoffChecklistModal
                    }
                />
                <ApproveClientReopenRequestModal
                    showApproveClientReopenRequestModal={
                        this.state.showApproveClientReopenRequestModal
                    }
                    gridCurrentState={this.state.gridCurrentState}
                    handleApproveClientReopenRequest={this.handleApproveClientReopenRequest}
                    toggleShowApproveClientReopenRequestModal={
                        this.toggleShowApproveClientReopenRequestModal
                    }
                />
                <DenyClientReopenRequestModal
                    showDenyClientReopenRequestModal={
                        this.state.showDenyClientReopenRequestModal
                    }
                    gridCurrentState={this.state.gridCurrentState}
                    handleDenyClientReopenRequest={this.handleDenyClientReopenRequest}
                    toggleShowDenyClientReopenRequestModal={
                        this.toggleShowDenyClientReopenRequestModal
                    }
                />
                <DeleteChecklistModal
                    showDeleteChecklistModal={
                        this.state.showDeleteChecklistModal
                    }
                    handleDeleteChecklist={this.handleDeleteChecklist}
                    toggleShowDeleteChecklistModal={
                        this.toggleShowDeleteChecklistModal
                    }
                    gridCurrentState={this.state.gridCurrentState}
                />
                <ResendChecklistModal
                    showResendChecklistModal={
                        this.state.showResendChecklistModal
                    }
                    handleResendChecklist={this.handleResendChecklist}
                    toggleShowResendChecklistModal={
                        this.toggleShowResendChecklistModal
                    }
                    selectedRecipients={this.state.selectedRecipients}
                />



                <div>
                    <PsqCreateChecklistModal
                        properties={this.props.properties}
                        handleFieldChanged={this.handleCreateField}
                        handleClientEmailChanged={this.handleClientEmailChanged}
                        handlePopulatePreviousAnswersChanged={
                            this.handlePopulatePreviousAnswersChanged
                        }
                        onSubmit={this.handleCreateAction}
                        isSendEmailEnabled={this.state.isSendEmailEnabled}
                        isCreateOnlyEnabled={this.state.isCreateOnlyEnabled}
                        showModal={this.state.showCreateModal}
                        createChecklistCustomErrorMessage={this.state.createChecklistCustomErrorMessage}
                        setCreateAction={this.setCreateAction}
                        handleCancelAction={this.handleCancelAction}
                        handleDismissErrorMessage={this.handleDismissErrorMessage}
                        clientEmailsRef={this.clientEmailsRef}
                        eyTeamMemberField={this.state.eyTeamMemberField}
                        selectedPropertyId={this.state.selectedPropertyId}
                        selectedPropertyName={this.state.selectedPropertyName}
                        selectedPropertyType={this.state.selectedPropertyType}
                        checklist={this.state.checklist}
                        customQuestionnaireTemplates={this.props.customQuestionnaireTemplates}
                        lookupTypes={this.props.lookupTypes}
                        clientEmail={this.state.clientEmail}
                    />

                </div>

                <InvalidEmailModal
                    showInvalidEmailModal={
                        this.state.showInvalidEmailModal
                    }
                    toggleShowInvalidEmailModal={
                        this.toggleShowInvalidEmailModal
                    }
                    deleteInvalidEmail={this.deleteInvalidEmail}
                    headerText={this.state.invalidHeader}
                />

            </React.Fragment >
        );
    }
}

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    let cid = Number.parseInt(ownProps.params.clientId);
    if (isNaN(cid)) {
        cid = 0;
    }

    return {
        clientId: cid,
        clientIsAudit: state.client && state.client.isAudit,
        periodId: Number.parseInt(ownProps.params.periodId),
        period: state.period,
        manageChecklists: state.manageChecklists,
        manageChecklistValues: getFormValues('psqChecklist')(state),
        initialValues: {
        },
        currentUserAuthorizations: state.currentUserAuthorizations,
        formSyncErrors: getFormSyncErrors('psqChecklist')(state),
        formSubmitErrors: getFormSubmitErrors('psqChecklist')(state),
        refreshManageChecklistClientId: state.refreshManageChecklistClient,
        lookupTypes: state.lookupTypes,
        properties: state.properties,
        client: state.client,
        reit: state.reit,
        customQuestionnaireTemplates: state.customQuestionnaire.templates,
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        clientActions: bindActionCreators(clientActions, dispatch),
        checklistActions: bindActionCreators(checklistActions, dispatch),
        reportActions: bindActionCreators(reportActions, dispatch),
        periodActions: bindActionCreators(periodActions, dispatch),
        customQuestionActions: bindActionCreators(customQuestionActions, dispatch),
        propertyActions: bindActionCreators(propertyActions, dispatch),
        lookupTypeActions: bindActionCreators(lookupTypeActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
        reitActions: bindActionCreators(reitActions, dispatch),
    };
}

PSQChecklistContainer.propTypes = {

    clientId: PropTypes.number,
    periodId: PropTypes.number.isRequired,
    clientIsAudit: PropTypes.bool,
    manageChecklists: PropTypes.array,
    manageChecklistValues: PropTypes.object,
    initialValues: PropTypes.object,
    clientActions: PropTypes.object,
    periodActions: PropTypes.object.isRequired,
    checklistActions: PropTypes.object,
    reportActions: PropTypes.object,
    currentUserAuthorizations: PropTypes.array,
    initialize: PropTypes.func,
    refreshManageChecklistClientId: PropTypes.number
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: "psqChecklist", enableReinitialize: true })(PSQChecklistContainer));