import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

/**
 * Presentation component used for rendering a download link to the trial balance file.
 */
export default class TrialBalanceDownloadLink extends React.Component {
    /**
     * Creates a new TrialBalanceDownloadLink
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);

        this.onDownloadClick = this.onDownloadClick.bind(this);
    }

    /**
     * Fire the onDownloadClick function in props
     * @param {any} e The element that triggered this function
     */
    onDownloadClick(e) {
        e.preventDefault();
        this.props.onDownloadClick(this.props.trialBalance.id, this.props.trialBalance.fileName);
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        return (<Link to={'#'} onClick={this.onDownloadClick}>Download Trial Balance for the Period</Link>);
    }
}

TrialBalanceDownloadLink.propTypes = {
    trialBalance: PropTypes.shape({
        id: PropTypes.number.isRequired,
        fileName: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired
    }),
    onDownloadClick: PropTypes.func.isRequired
};