import React from 'react';

export default (props) => {
  const value = props.data ? props.data[props.colDef.field] : null;
  const reitId = props.data ? props.data.reitid : null;
  const isReitAvailableForSelection = props.data ? props.data.isReitAvailableForSelection : null;
  const isReitActive = props.data ? props.data.isReitActive : null;

  // Disable checkbox for selection if the reit is inactive
  if (props.data && !isReitActive) {
    return null;
  }

  // Display checkbox if the reit is available for selection
  if (props.data && isReitAvailableForSelection) {
    const defaultCheckboxClass = "ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper";
    let checkboxClass = value ? defaultCheckboxClass + " ag-checked" : defaultCheckboxClass;

    return (
      <span>
        <div className={checkboxClass} onClick={() => props.onCellCheckboxChange(reitId, props.node)} role="presentation">
          <input class="ag-input-field-input ag-checkbox-input" type="checkbox" id="ag-cell-checkbox" aria-label="Press Space to toggle row selection" checked={value} tabindex="-1" />
        </div>
      </span>
    );
  }

  // Display loading spinner if the reit is not available for selection
  if (props.data && !isReitAvailableForSelection) {
    return (
      <span>
        <img src="/images/loading-spinner-black.gif" height="20px" />
      </span>
    );
  }

  return null;
};