import React from 'react';
import PropTypes from 'prop-types';
import RelatedReitsRow from './RelatedReitsRow';
import { MotifButton, MotifRadio, MotifToast } from '@ey-xd/motif-react';

const RelatedReits = ({ handleAddReitClick, handleEditClick, handleDeleteClick, reits, canAddReit }) => {
    return (
        <section className="related-reits">
            <div className="row">
                <div className="col-md-12">
                    <h2>Related REITs
                        <MotifButton variant="secondary" className="right" type="button"
                            onClick={handleAddReitClick}
                            disabled={!canAddReit}>
                            Add REIT
                        </MotifButton>
                    </h2>
                </div>
                {!canAddReit && <div className="col-md-12" style={{ textAlign: 'right', fontStyle: 'italic' }}>The Add REIT button has been disabled because there are unsaved changes to the client</div>}
            </div>
            <div className="row">
                <div className="col-md-12">
                    <table className="reits">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Trial Balance Threshold</th>
                                <th>EIN</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(reits) && reits.map(r => <RelatedReitsRow key={r.reitid} reit={r} handleEditClick={handleEditClick} handleDeleteClick={handleDeleteClick} />)}
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    );
};

RelatedReits.propTypes = {
    handleAddReitClick: PropTypes.func.isRequired,
    handleEditClick: PropTypes.func.isRequired,
    handleDeleteClick: PropTypes.func.isRequired,
    reits: PropTypes.array.isRequired,
    canAddReit: PropTypes.bool
};

export default RelatedReits;