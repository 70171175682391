import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AdminAuthList from "../../components/auth/AdminAuthList";
import * as authActions from "../../actions/authActions";
import * as commonActions from "../../actions/commonActions";
import ReactModal from 'react-modal';
import * as authorizationDomainValidator from "../../scripts/authorizationDomainValidator";
import store from "../../store/configureStore";
import { reset } from "redux-form";
import { MotifButton, MotifIconButton, MotifIcon } from '@ey-xd/motif-react';
import DeleteModal from "../../components/shared/DeleteModal";
/**
 * AdminAuthPage container component
 * @extends {React.Component}
 */
export class AdminAuthPage extends React.Component {
    /**
        * Creates a new AdminAuthPage
        * @constructor
        * @param {Object} props The component properties
        * @param {Object} context The component context
        */
    constructor(props, context) {
        super(props, context);

        this.state = {
            adminUserSearchText: "",
            filteredAdminAuths: [],
            showAddSysAdminModal: false,
            auth: {
                "userId": null, "resource": authActions.resource.System, "role": authActions.role.Administrator, "resourceId": null, "id": null
            },
            confirmAction: null,
            currentUserId: this.props.currentUserId,
            addAuthMessageText: null,
            allowedDomains: ""
        };
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleClearSearchText = this.handleClearSearchText.bind(this);
        this.reloadAdminAuths = this.reloadAdminAuths.bind(this);
        this.handleEditButtonClick = this.handleEditButtonClick.bind(this);
        this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
        this.handleNewButtonClick = this.handleNewButtonClick.bind(this);
        this.handleAddSysAdminModalAction = this.handleAddSysAdminModalAction.bind(this);
        this.toggleShowAddSysAdminModal = this.toggleShowAddSysAdminModal.bind(this);
        this.onConfirmDelete = this.onConfirmDelete.bind(this);
        this.handleCancelDelete = this.handleCancelDelete.bind(this);
        this.validateDomain = this.validateDomain.bind(this);
    }

    /**
     * Invoked immediately after mounting occurs
     * @returns {undefined}
     */
    componentDidMount() {
        this.props.actions.fetchAuthorizations(null, 1, null, 1);
        this.setFilter();
        authActions.fetchAllowedAuthorizationDomains().then((allowedDomains) => { this.setState({ allowedDomains: allowedDomains }); });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.authorizations !== this.props.authorizations && nextProps.authorizations && this.props.authorizations) {
            const list = Object.assign([], nextProps.authorizations);
            this.setState({ filteredAdminAuths: list });
        }
    }

    reloadAdminAuths(reloadAdminAuths) {
        if (reloadAdminAuths) {
            this.props.actions.fetchAuthorizations(null, 1, null, 1);
        }
    }

    handleSearchChange(e) {
        this.setState({ adminUserSearchText: e.target.value }, this.setFilter);
    }

    handleClearSearchText(e) {
        this.setState({ adminUserSearchText: "" }, this.setFilter);
    }

    setFilter() {
        if (this.state.adminUserSearchText === "") {
            this.setState({ filteredAdminAuths: Object.assign([], this.props.authorizations) });
        } else {
            this.setState({ filteredAdminAuths: this.props.authorizations.filter((auth) => auth.userId.toLowerCase().includes(this.state.adminUserSearchText.toLowerCase())) });
        }
    }

    handleNewButtonClick(value) {
        this.setState({ showAddSysAdminModal: !this.state.showAddSysAdminModal });
    }

    handleEditButtonClick() {
        //Not used in SysAdmin
    }

    handleDeleteButtonClick(auth) {
        let id = auth.id;

        this.setState({
            confirmAction: () =>this.props.authActions.removeAuthorization(id).then(() => {
                this.reloadAdminAuths(true);
                this.setState({
                    auth: {
                        "userId": null, "resource": authActions.resource.System, "role": authActions.role.Administrator, "resourceId": null, "id": null
                    }
                });
            })
        });
    }

    toggleShowAddSysAdminModal() {
        this.setState({ addAuthMessageText: null, showAddSysAdminModal: !this.state.showAddSysAdminModal }, () => {
            if (!this.state.showAddSysAdminModal) {
                store.dispatch(reset('auth'));
            }
        });
    }

    handleAddSysAdminModalAction(values) {
        if (!values || !values.userId || values.userId.trim() === "") {
            this.setState({ addAuthMessageText: "Required" });
            return;
        }

        for (let auth of this.props.authorizations) {
            if (values.userId.toLowerCase() === auth.userId.toLowerCase()) {
                this.setState({ addAuthMessageText: `'${values.userId}' is already an authorized SysAdmin.` });
                return;
            }
        }

        this.setState({ auth: values }, () => {
            this.props.commonActions.beginTask();

            authActions.createAuthorization(this.state.auth).then(() => {
                this.reloadAdminAuths(true);
                this.setState({
                    auth: {
                        "userId": null, "resource": authActions.resource.System, "role": authActions.role.Administrator, "resourceId": null, "id": null
                    }
                }, () => {
                    this.toggleShowAddSysAdminModal();
                    this.props.commonActions.endTask();
                });
            });
        });
    }

    onConfirmDelete() {
        if (this.state.confirmAction) {
            this.state.confirmAction();
            this.setState({ confirmAction: null });
        }
    }

    handleCancelDelete() {
        this.setState({ confirmAction: null });
    }

    validateDomain(value) {
        return authorizationDomainValidator.allowedDomain(value, this.state.allowedDomains);
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        const authModalStyle = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '300px',
                height: '160px'
            }
        };

        let isSysAdmin = false;
        if (Array.isArray(this.props.currentUserAuthorizations)) {
            isSysAdmin = authActions.isSystemAdministrator(this.props.currentUserAuthorizations);
        }
        
        // Non sys admins are unauthorized to access system admin page
        if (!isSysAdmin) {            
            this.props.router.push(`/unauthorized`);
            return (<span />);
        }

        return isSysAdmin ? (
            <div>
                <AdminAuthList auths={this.state.filteredAdminAuths} handleSearchChange={this.handleSearchChange} handleClearSearchText={this.handleClearSearchText} adminUserSearchText={this.state.adminUserSearchText} handleEditButtonClick={this.handleEditButtonClick} handleDeleteButtonClick={this.handleDeleteButtonClick} handleNewButtonClick={this.handleNewButtonClick} showAddSysAdminModal={this.state.showAddSysAdminModal} handleAddSysAdminModalAction={this.handleAddSysAdminModalAction} auth={this.state.auth} toggleShowAddSysAdminModal={this.toggleShowAddSysAdminModal} currentUserId={this.state.currentUserId} addAuthMessageText={this.state.addAuthMessageText} validateDomain={this.validateDomain} />
                <DeleteModal showDeleteModal={this.state.confirmAction !== null} confirmationMessage="Are you sure?" size="sm" handleDelete={this.onConfirmDelete} toggleshowDeleteModal={this.handleCancelDelete} closeTitle="close system admin delete modal" headerTitle="Delete System Administrator"></DeleteModal>
            </div>
        ) : <span />;
    }
}

AdminAuthPage.propTypes = {
    authorizations: PropTypes.array,
    currentUserAuthorizations: PropTypes.array,
    actions: PropTypes.object.isRequired,
    commonActions: PropTypes.object.isRequired,
    currentUserId: PropTypes.string.isRequired
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state) {
    const authorizations = state.authorizations;
    const currentUserAuthorizations = state.currentUserAuthorizations;

    return {
        authorizations: authorizations,
        currentUserAuthorizations: currentUserAuthorizations,
        currentUserId: state.authentication.currentUser
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(authActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
        authActions: bindActionCreators(authActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminAuthPage);