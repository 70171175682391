import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import { MotifTextLink, MotifButton, MotifBadge } from '@ey-xd/motif-react';
import { Button } from "semantic-ui-react";
import * as testSnapshotActions from "../../actions/testSnapshotActions";
import DownloadLink from "../tb/TrialBalanceDownloadLink";
import ReactModal from 'react-modal';

const ReportPeriodRow = ({ period, reitId, reit, clientId, trialBalances, downloadFunction, handleActivePeriodIdChanged, toggleShowClosePeriodModal,
    hasIncompleteChecklists, toggleShowOpenPeriodModal, isEngAdminOrSysAdmin, tbMode, toggleShowModeModal, hideTbModeLink, hideTestSnapshotLink,
    handleDownloadCurrentTestSnapshot, handleDownloadTbTemplates, clientIsAudit, handleDownloadMappingTemplatePackage, chartExistsForClient }) => {

    const rawTrialBalance = trialBalances.find(x => x.type && x.type.toLowerCase() === "raw");
    const adjTrialBalance = trialBalances.find(x => x.type && x.type.toLowerCase() === "adjusted");
    const consolidatedTrialBalance = trialBalances.find(x => x.trialBalancePurposeID && x.trialBalancePurposeID === 3);

    const handleToggleShowClosePeriodModal = () => {
        return toggleShowClosePeriodModal(period.reportPeriodID);
    };

    const handleToggleShowOpenPeriodModal = () => {
        return toggleShowOpenPeriodModal(period.reportPeriodID);
    };

    const onHandleActivePeriodIdChanged = () => {
        return handleActivePeriodIdChanged(period.reportPeriodID);
    };

    const downloadTbTemplates = (e) => {
        e.preventDefault()
        return handleDownloadTbTemplates(clientId, period.reportPeriodID);
    };

    const downloadCurrentTestSnapshot = (e) => {
        e.preventDefault();
        let fileName = `${period.reportPeriodDescription.replace(/\s/g, '')}_${reit.reitName}_TestSnapshot.xlsx`;
        //return handleDownloadCurrentTestSnapshot("testSnapshot.xlsx")
        return handleDownloadCurrentTestSnapshot(fileName);
    };

    const downloadMappingTemplatePackage = (e) => {
        e.preventDefault()
        return handleDownloadMappingTemplatePackage(period.reportPeriodID);
    };

    let closeButtonText = "Close Testing";
    let openButtonText = "";
    let isClosed = false;
    let isReportingClosed = false;
    switch (period.reportPeriodStatusDescription) {
        case "Closed to Testing":
            closeButtonText = "Close Reporting";
            openButtonText = "Reopen to Testing";
            break;
        case "Closed to Reporting":
            closeButtonText = "Close Period";
            openButtonText = "Reopen to Reporting";
            isReportingClosed = true;
            break;
        case "Complete":
            closeButtonText = "Closed";
            openButtonText = "Reopen Period";
            isReportingClosed = true;
            isClosed = true;
            break;
    }

    let tbModeText = "REIT & Property TB Mode";
    if (period.trialBalanceModeID === 2) {
        tbModeText = "Consolidated TB Mode";
    }
    if (period.trialBalanceModeID === 0) {
        tbModeText = "TB Mode not set.";
    }

    let tbModeClick = () => {
        toggleShowModeModal(period.reportPeriodID);
    };

    const periodLink = clientIsAudit ? `/client/${clientId}/reit/${reitId}/period/${period.reportPeriodID}/arqc` : `/client/${clientId}/reit/${reitId}/period/${period.reportPeriodID}/uploadtb/adjusted`;
    return (
        <tr className="data-row">
            <td>
                <Link tabIndex="-1" to={periodLink}><MotifTextLink variant="alt">{period.reportPeriodDescription}</MotifTextLink></Link>
                {period.discrepancyCount > 0 && <h5 className="ml-1 badge badge-warning badgeStandard">{period.discrepancyCount}</h5> || null}
            </td>
            <td>
                {!clientIsAudit ?
                    <Link tabIndex="-1" to={`/client/${clientId}/reit/${reitId}/period/${period.reportPeriodID}/uploadtb/adjusted`}><MotifTextLink variant="alt">Upload Trial Balance</MotifTextLink></Link>
                    : <MotifTextLink disabled variant="alt">Upload Trial Balance</MotifTextLink>}

                {consolidatedTrialBalance && !clientIsAudit
                    ? <React.Fragment>
                        <br /><Link tabIndex="-1" to={`/client/${clientId}/reit/${reitId}/period/${period.reportPeriodID}/files`}><MotifTextLink variant="alt">Download Trial Balance</MotifTextLink></Link>
                    </React.Fragment>
                    : <React.Fragment><br /><MotifTextLink disabled variant="alt">Download Trial Balance</MotifTextLink></React.Fragment>}
                <br />
                {(!hideTestSnapshotLink && !clientIsAudit && consolidatedTrialBalance)
                    ? <MotifTextLink href='#' onClick={downloadCurrentTestSnapshot} variant="alt">Download Test Snapshot</MotifTextLink>
                    : <MotifTextLink disabled variant="alt">Download Test Snapshot</MotifTextLink>}
                <br />
                {(!clientIsAudit && chartExistsForClient) ?
                    <MotifTextLink href='#' onClick={downloadTbTemplates} variant="alt">Download TB Templates</MotifTextLink>
                    : <MotifTextLink disabled variant="alt">Download TB Templates</MotifTextLink>}
                <br />
                {(!clientIsAudit && chartExistsForClient) ?
                    <MotifTextLink href='#' onClick={downloadMappingTemplatePackage} variant="alt">Download Mapping Template Package</MotifTextLink>
                    : <MotifTextLink disabled variant="alt">Download Mapping Template Package</MotifTextLink>}

                <React.Fragment>
                    <br /><Link tabIndex="-1" to={`/client/${clientId}/reit/${reitId}/period/${period.reportPeriodID}/report`}><MotifTextLink variant="alt">Final Report</MotifTextLink></Link>
                </React.Fragment>
            </td>
            <td>
                <Link tabIndex="-1" to={`/client/${clientId}/reit/${reitId}/period/${period.reportPeriodID}/properties`}><MotifTextLink variant="alt">Properties</MotifTextLink></Link>
                <br />
                {!clientIsAudit ?
                    <Link tabIndex="-1" to={`/client/${clientId}/reit/${reitId}/period/${period.reportPeriodID}/securitiesSnapshot`}><MotifTextLink variant="alt">Securities</MotifTextLink></Link>
                    : <MotifTextLink disabled variant="alt">Securities</MotifTextLink>}
                <br />
                {Array.isArray(period.services) && period.services.includes("DistributionTesting") && !clientIsAudit ?
                    <Link tabIndex="-1" to={`/client/${clientId}/reit/${reitId}/period/${period.reportPeriodID}/distributionTest`} disabled={true}><MotifTextLink variant="alt">Distributions</MotifTextLink></Link>
                    : <MotifTextLink disabled variant="alt">Distributions</MotifTextLink>
                }
                <br />
                {!clientIsAudit ?
                    <Link tabIndex="-1" to={`/client/${clientId}/reit/${reitId}/period/${period.reportPeriodID}/trs`}><MotifTextLink variant="alt">Taxable REIT Subsidiaries</MotifTextLink></Link>
                    : <MotifTextLink disabled variant="alt">Taxable REIT Subsidiaries</MotifTextLink>}
                <br />
                {!clientIsAudit ?
                    <Link tabIndex="-1" to={`/client/${clientId}/reit/${reitId}/period/${period.reportPeriodID}/files`}><MotifTextLink variant="alt">File Management</MotifTextLink></Link>
                    : <MotifTextLink disabled variant="alt">File Management</MotifTextLink>}
                <br />
                {!clientIsAudit ?
                    <Link tabIndex="-1" to={`/client/${clientId}/reit/${reitId}/period/${period.reportPeriodID}/mappings`}><MotifTextLink variant="alt">Client Account Mappings</MotifTextLink></Link>
                    : <MotifTextLink disabled variant="alt">Client Account Mappings</MotifTextLink>}
                {!hideTbModeLink && !clientIsAudit && <div>
                    {period.reportPeriodStatusDescription === "In Progress" || period.reportPeriodStatusDescription === "Not Started"
                        ?
                        <React.Fragment><MotifTextLink href="#" onClick={tbModeClick} variant="alt">Trial Balance Mode</MotifTextLink></React.Fragment>
                        : <React.Fragment><MotifTextLink disabled variant="alt" title={period.reportPeriodStatusDescription}>Trial Balance Mode</MotifTextLink></React.Fragment>
                    }
                </div> || null}

            </td>
            <td align="center">
                <div style={{ float: 'none', display: 'inline' }}>
                    <MotifButton variant="secondary" type="button"
                        onClick={onHandleActivePeriodIdChanged}
                        disabled={isReportingClosed || clientIsAudit}>
                        Services Scope
                    </MotifButton></div>
            </td>
            <td className="td-center">
                <span>
                    <MotifBadge variant={period.reportPeriodStatusDescription === "Complete" ? "success" : "alt"} value={period.reportPeriodStatusDescription} />
                    {hasIncompleteChecklists &&
                        <div>
                            <MotifBadge variant="error" value={"Incomplete ARQC or PSQ"} />        
                        </div>
                    }
                    <div>{tbModeText}</div>
                </span>
            </td>
            <td align="center">
                <div style={{ float: 'none', display: 'inline' }}>
                    <div className="close-period-button">
                        {!isClosed &&
                            <MotifButton variant="secondary"
                                type="button"
                                onClick={handleToggleShowClosePeriodModal}
                                disabled={hasIncompleteChecklists
                                    && closeButtonText === "Close Reporting"}>
                                {closeButtonText}
                            </MotifButton>
                            || null}
                    </div>
                    {(isEngAdminOrSysAdmin && openButtonText !== "" && (<div className="close-period-button">
                        {openButtonText !== "" &&
                            <MotifButton variant="secondary"
                                type="button"
                                onClick={handleToggleShowOpenPeriodModal} >
                                {openButtonText}
                            </MotifButton>
                            || null}
                    </div>)) || null}
                </div>
            </td>
        </tr>
    );
};

ReportPeriodRow.propTypes = {
    clientId: PropTypes.number.isRequired,
    clientIsAudit: PropTypes.bool,
    reitId: PropTypes.number.isRequired,
    reit: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
    }),
    period: PropTypes.shape({
        reportPeriodID: PropTypes.number.isRequired
    }),
    trialBalances: PropTypes.array,
    downloadFunction: PropTypes.func.isRequired,
    handleActivePeriodIdChanged: PropTypes.func.isRequired,
    handleDownloadTbTemplates: PropTypes.func.isRequired,
    handleDownloadMappingTemplatePackage: PropTypes.func.isRequired,
    toggleShowClosePeriodModal: PropTypes.func.isRequired,
    hasIncompleteChecklists: PropTypes.bool,
    toggleShowOpenPeriodModal: PropTypes.func.isRequired,
    isEngAdminOrSysAdmin: PropTypes.bool,
    tbMode: PropTypes.number,
    toggleShowModeModal: PropTypes.func.isRequired,
    hideTbModeLink: PropTypes.bool,
    hideTestSnapshotLink: PropTypes.bool,
    handleDownloadCurrentTestSnapshot: PropTypes.func
};

export default ReportPeriodRow;