import { pendingTask, begin, end } from 'react-redux-spinner';
import * as actions from './actionTypes';
import { fetchFromReitApi as fetchFromReit, fetchFromTrialBalanceApi as fetchFromTrialBalance } from './fetchFromApi';
import * as actionHelpers from '../scripts/actionHelpers';


/**
 * Fetch all the property enums
 * @returns {Promise} A Promise
 */
export function fetchPropertyTypes() {
    /*
        This is an example by now im just assuming that the returned object is like the next example
        {
            "PropertyTypes": [{"PropertyTypeID": 1, "PropertyTypeDescription": "Office"}...],
            "PSQPropertyTypes": [{"PSQPropertyTypeID": 1, "PSQPropertyTypeDescription": "Office"}...],
        }
    */
    
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetchFromReit(`/properties/lookupData`,
            {
                method: "GET" 
            }).then(response => {
                return response.json();
            })
            .then(fetchedTypes => {
                dispatch({
                    type: actions.LOAD_ALL_PROPERTY_TYPES_SUCCESS,
                    fetchedTypes,
                    [pendingTask]: end,
                });
        });
    }
}

/**
 * Fetch all the property enums for External user
 * @returns {Promise} A Promise
 */
 export function fetchExternalPropertyTypes(checklistId, checklistType) {
    /*
        This is an example by now im just assuming that the returned object is like the next example
        {
            "PropertyTypes": [{"PropertyTypeID": 1, "PropertyTypeDescription": "Office"}...],
            "PSQPropertyTypes": [{"PSQPropertyTypeID": 1, "PSQPropertyTypeDescription": "Office"}...],
        }
    */
    
    return function (dispatch) {
        const authHeader = actionHelpers.buildAuthHeader(checklistId, checklistType);
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetchFromReit(`/properties/lookupData`,
            {
                method: "GET",
                headers: authHeader && new Headers({ 'Authorization': authHeader }) 
            }).then(response => {
                return response.json();
            })
            .then(fetchedTypes => {
                dispatch({
                    type: actions.LOAD_ALL_PROPERTY_TYPES_SUCCESS,
                    fetchedTypes,
                    [pendingTask]: end,
                });
        });
    }
}

/**
 * Fetch all the chart of account lookup tables data
 * @returns {Promise} A Promise
 */
 export function fetchChartOfAccountTypes() {

    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetchFromTrialBalance(`/chartofaccounts/lookupData`,
            {
                method: "GET" 
            }).then(response => {
                return response.json();
            })
            .then(fetchedTypes => {
                dispatch({
                    type: actions.LOAD_ALL_CHARTOFACCOUNT_TYPES_SUCCESS,
                    fetchedTypes,
                    [pendingTask]: end,
                });
        });
    }
}