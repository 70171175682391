import { pendingTask, begin, end } from 'react-redux-spinner';
import * as actions from './actionTypes';
import { fetchFromTrialBalanceApi as fetch } from './fetchFromApi';
import { download } from '../scripts/fileHelpers';
import * as actionHelpers from '../scripts/actionHelpers';

/**
 * Fetch the trial balance summary by period
 * @param {number} periodId The id of the period that owns the TRS.
 * @param {boolean} hideZeroBalanceRows True if zero-balance rows should be hidden, otherwise false
 * @param {boolean} useColumns True if columns should be used, otherwise false
 * @param {boolean} useRows True if rows should be used, otherwise false
 * @param {number} trialBalanceMode The trial balance mode
 * @returns {funtion} A function that returns a Promise.
 */
export function getTrialBalanceSummaryByPeriod(periodId, hideZeroBalanceRows, useColumns, useRows, trialBalanceMode) {
    return function (dispatch) {
        if (hideZeroBalanceRows === undefined) {
            hideZeroBalanceRows = false;
        }

        let url = '/trialBalanceSummary?periodId=' + periodId;
        url += '&hideZeroBalanceRows=' + hideZeroBalanceRows;
        if (useColumns !== undefined) {
            url += '&useColumns=' + useColumns;
        }
        if (useRows !== undefined) {
            url += '&useRows=' + useRows;
        }
        if (trialBalanceMode) {
            url += '&trialBalanceMode=' + trialBalanceMode;
        } else { url += '&trialBalanceMode=1'; }

        url += '&ShowDetailCode=false';

        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetch(url).then(response => {
            return response.json();
        }).then(trialBalanceSummary => {
            if (actionHelpers.isErrorResponse(trialBalanceSummary)) {
                if (trialBalanceSummary.errorCode === 'RESOURCE_NOT_FOUND') {
                    trialBalanceSummary = null;  // Allow 404 and dispatch success with null property
                } else {
                    return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_TB_SUMMARY_FAILURE, trialBalanceSummary);
                }
            }

            dispatch({ type: actions.LOAD_TB_SUMMARY_SUCCESS, trialBalanceSummary, [pendingTask]: end });
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(dispatch, actions.LOAD_TB_SUMMARY_FAILURE, null, error);
        });
    };
}

/**
 * Download the trial balance blob
 * @param {number} periodId The id of the period that owns the trial balance
 * @param {boolean} hideZeroBalanceRows True if zero-balance rows should be hidden, otherwise false
 * @param {number} trialBalanceMode The trial balance mode
 * @returns {Promise} A promise that downloads the trial balance
 */
export function downloadTrialBalanceSummary(periodId, hideZeroBalanceRows, trialBalanceMode) {
    let url = '/trialBalanceSummary/export?periodId=' + periodId;
    url += '&hideZeroBalanceRows=' + hideZeroBalanceRows;
    if (trialBalanceMode) {
        url += '&trialBalanceMode=' + trialBalanceMode;
    } else { url += '&trialBalanceMode=1'; }

    url += '&ShowDetailCode=true';

    return fetch(url)
        .then(function (response) {
            return response.blob().then(function (blob) {
                const contentDisposition = response.headers.get("Content-Disposition");
                const fileName = contentDisposition.split(';')[1].split('=')[1].trim();
                download(blob, fileName);
            });
        });
}