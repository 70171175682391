import { pendingTask, begin, end } from 'react-redux-spinner';
import * as actions from "./actionTypes";

let authenticationTimeoutInterval = null;

/**
 * Repeatedly update the authentication timeout.  The timeout is polled every 1000 (1 second).
 * @param {any} dispatch The dispatch.
 * @param {any} expiration The expiration timeout
 * @returns {void} None
 */
export function pollAuthenticationTimeout(dispatch, expiration) {
    authenticationTimeoutInterval = setInterval(function () { updateTimeout(dispatch, expiration); }, 1000);
}

/**
 * Get notifications for a user.
 * @param {any} dispatch The dispatch.
 * @param {any} expiration The expiration for the user token.
 * @returns {void} None.
 */
export function updateTimeout(dispatch, expiration) {
    const now = parseInt(Date.now() / 1000);
    const diff = expiration - now;

    const remainingTimeMilliseconds = (diff - 300) * 1000;
    const remainingTime = millisToMinutesAndSeconds(remainingTimeMilliseconds);
    dispatch({ type: actions.AUTHENTICATION_TIMER_UPDATED, remainingTime: remainingTime });

    if (diff < 300) {
        this.context.router.push("/");
    }
}

/**
 *  Stop polling for authentication timeout
 * @returns {void} None
 */
export function stopPollAuthenticationTimeout() {
    if (authenticationTimeoutInterval !== undefined && authenticationTimeoutInterval !== null) {
        clearInterval(authenticationTimeoutInterval);
        authenticationTimeoutInterval = null;
    }
}

/**
 * Sets the authentication scope, whether internal or external
 * @param {any} scope internal or external.
 * @returns {any} A function that returns a Promise.
 */
export function setAuthenticationScope(scope) {
    return function (dispatch) {
        dispatch({ type: actions.SET_AUTHENTICATION_SCOPE, authenticationScope: scope });
    }
}

/**
 * Sets the current user id based on email or upn
 * @param {any} idTokenClaims
 * @returns {any} A function that returns a Promise.
 */
export function setCurrentUser(idTokenClaims) {
    return function (dispatch) {
        let userId = '';

        if (idTokenClaims) {
            userId = idTokenClaims.email;

            // get email from user principal name if no email claim
            if (userId === undefined || userId === null || userId === "") {
                userId = idTokenClaims.upn;
            }
        }

        dispatch({ type: actions.SET_CURRENT_USER, currentUser: userId });
    }
}

/**
 * Convert milliseconds to minutes and seconds
 * @param {number} millis Number in milliseconds
 * @returns {string} Minutes and Seconds
 */
function millisToMinutesAndSeconds(millis) {
    const minutes = Math.floor(millis / 60000);
    const seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
}