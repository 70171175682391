import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Header, Label, Button, Input, Dropdown } from "semantic-ui-react";
import ReactModal from "react-modal";
import { Field, reduxForm, getFormValues } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import * as formValidators from "../../scripts/formValidators";
import { connect } from "react-redux";
import {
    MotifButton, MotifFormField, MotifInput, MotifLabel, MotifSelect, MotifOption, MotifModal, MotifIcon,
    MotifModalBody, MotifModalFooter, MotifModalHeader} from "@ey-xd/motif-react";
import { addAttribute } from "../../common/Attribute";

const CustomPSQTemplateValidationMessageModal = ({ showCustomPSQTemplateValidationMessageModal,
    handleSubmit, handleValidationMessage, toggleShowCustomPSQTemplateValidationMessageModal, editModeValidationMessage }) => {
    
    const HeaderWrapper = ({ children }) => (
        <div
            style={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'center'
            }}>
            {children}
        </div>
    );

    const closeButtonProps = {
        title: 'Close Validation message modal',
        ariaLabel: 'Close Validation message modal',
        dataTestid: 'close'
    };

    useEffect(() => {

        //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
        addAttribute("button[role = 'combobox']", "type", "button");
    });

    return (
        <MotifModal variant="alt" show={showCustomPSQTemplateValidationMessageModal} onClose={() => toggleShowCustomPSQTemplateValidationMessageModal()} size="xl" data-testid="customPSQTemplateValidationMessage">
            <MotifModalHeader style={{ display: "-webkit-box" }} closeButtonProps={closeButtonProps}>
                <HeaderWrapper>
                    <span className="ml-2">Edit Template</span>
                </HeaderWrapper>
            </MotifModalHeader>

            <MotifModalBody>
                <form onSubmit={handleSubmit} className="auth">
                    <span className="checklistActionMsg">
                        {editModeValidationMessage}
                    </span>
                    <br />               
                </form>
            </MotifModalBody>
            
            <MotifModalFooter>
                <MotifButton type="button" onClick={() => toggleShowCustomPSQTemplateValidationMessageModal()} className="right" data-testid="Submit" >
                    OK
                </MotifButton>              
            </MotifModalFooter>
        </MotifModal>
    );
};

CustomPSQTemplateValidationMessageModal.propTypes = {
    showCustomPSQTemplateValidationMessageModal: PropTypes.bool,
    handleSubmit: PropTypes.func,
    toggleShowCustomPSQTemplateValidationMessageModal: PropTypes.func,
    initialValues: PropTypes.object
};

export default reduxForm({ form: "customPSQTemplateValidationMessage", enableReinitialize: true })(CustomPSQTemplateValidationMessageModal);