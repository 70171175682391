import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { MotifTextLink } from '@ey-xd/motif-react';

const ClientListRowSlim = ({ client }) => {
    return (
        <tr>
            <td><Link tabIndex='-1' to={'/ext/reits/' + client.id}><MotifTextLink variant="alt">{client.name}</MotifTextLink></Link></td>
        </tr>
    );
};

ClientListRowSlim.propTypes = {
    client: PropTypes.object.isRequired
};

export default ClientListRowSlim;