import PropTypes from "prop-types";
import React from "react";
import { Button, Header } from "semantic-ui-react";
import ReactModal from "react-modal";
import { MotifButton } from "@ey-xd/motif-react";

const ChecklistReadyModal = ({ handleModalAction, showModal }) => {
    return (
        <ReactModal contentLabel="Submit checklist for EY review." isOpen={showModal} className="react-modal" overlayClassName="react-modal-overlay">
            <Header content="Submit checklist for EY review."/>
            <p>Are you sure your checklist is ready? You will not be able to update the checklist once it is submitted for EY review. Click "Yes" to submit to EY, or "No" to return to editing.</p>            
            
            <MotifButton variant="primary" className="right ml-2" type="button"
                onClick={handleModalAction} value="PENDINGEYREVIEW">
                Yes
            </MotifButton>
            <MotifButton variant="secondary" className="right" type="button"
                onClick={handleModalAction} value="INPROGRESS">
                No
            </MotifButton>
        </ReactModal>
    );
};

ChecklistReadyModal.propTypes = {
    showModal: PropTypes.bool,
    handleModalAction: PropTypes.func.isRequired
};

export default ChecklistReadyModal;