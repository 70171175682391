import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Icon, Progress, Button, Header } from 'semantic-ui-react';
import ReactModal from "react-modal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as trialBalanceActions from "../../actions/trialBalanceActions";
import { MotifButton, MotifTextLink, MotifIcon } from '@ey-xd/motif-react';
import * as Constants from '../../constants/other';
import { fileIcCloudUpload24px } from '@ey-xd/motif-react/assets/icons';

const UploadStatusComponent = ({rejectedFiles, acceptedFiles, status, validationErrors}) => {
    return ( 
        <React.Fragment>
            {
                status == 'Success' && acceptedFiles.map((file, index) => {
                    var acceptedfileNames = file.name;
                    let msg = acceptedfileNames + ' has been added to the queue for processing.';
                    
                    return <div key={index} style={{ textAlign: 'left' }}>
                        <span>
                            <Icon name="check circle" size="big" color='green' title="success" />
                        </span>
                        <span>
                            <Header size='small' className='text-success' content={msg} />
                        </span>
                    </div>
                })
            }
            {
                status == 'Error' && 
                    <div style={{ textAlign: 'left' }}>
                        <div>
                            <span>
                                <Icon name="remove circle" size="big" color='red' title="error" />
                            </span>
                            <span>
                            <Header size='small' className='text-danger' content='Upload failed.' />
                            </span>
                        </div>
                        {validationErrors.map((e, index) =>
                            <div key={index} style={{ marginLeft: 37 }} >
                                {e.error}
                            </div>
                        )}
                    </div>
            }
            {
                status == 'Rejected' && 
                <div className='mb-2'>
                    <div className='text-left mb-2'>
                        <span>
                            <Icon name="remove circle" size="big" color='red' title="error" />
                        </span>
                        <span>
                            <Header size='small' className='text-danger' content='Upload failed.' />
                        </span>
                    </div>
                    {rejectedFiles && rejectedFiles.length > 0 && rejectedFiles.map((f, index) =>
                        <div key={index} className='row bg-danger text-white mr-3 ml-3'>
                            <div className='col-md-6'>{f.file.name}</div>
                            <div className='col-md-6'>
                            {f.file.size > Constants.MAX_UPLOAD_FILE_SIZE_IN_BYTES 
                                ? <span>The file size has exceeded max limit of {Constants.MAX_UPLOAD_FILE_SIZE_IN_MB}MB</span>
                                : <span>Invalid file extension (Supports only XLSX format)</span>}
                            </div>
                        </div>
                    )}
                </div>
            }
            {
                status == 'Uploading' && <div>
                    <Header size='small' className='' content='Uploading file(s)' />
                </div>
            }
        </React.Fragment> 
    )
}

/**
 * Container component used for uploading chart files
 */
export class AccountMappingsUploadModal extends React.Component {
    /**
     * Creates a new AccountMappingsUploadModal
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);

        this.onDrop = this.onDrop.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.state = {
            status: null,
            rejectedFiles: [],
            acceptedFiles: [],
            validationErrors: []
        };

        this.acceptedFileExtensions = '.xlsx';
    }
        
    handleClose() {
        this.setState({ status: null, rejectedFiles: [], acceptedFiles: [], validationErrors: [] });
        //this.props.handleChartSelection(this.props.chartOfAccountsFormValues.chartID);
        this.props.toggleShowUploadAccountMappingsModal();
    }

    onDrop(acceptedFiles, rejectedFiles) {
        if (Array.isArray(rejectedFiles) && rejectedFiles.length > 0) {
            this.setState({ status: 'Rejected', rejectedFiles, acceptedFiles: [], validationErrors: [] });
        } else if (Array.isArray(acceptedFiles)) {
            this.setState({ status: 'Uploading', rejectedFiles: [], acceptedFiles: acceptedFiles, validationErrors: [] });
            this.props.trialBalanceActions.uploadAccountMappings(this.props.clientId, this.props.periodId, acceptedFiles)
                .then(response => {
                    if (response && response.error) {
                        //SERVER ERROR, BAD REQUEST
                        this.setState({ status: null, rejectedFiles: [], acceptedFiles: [], validationErrors: [] });
                        this.props.toggleShowUploadAccountMappingsModal();
                    }
                    else if (response && response.uploadChartValidationError && Array.isArray(response.uploadChartValidationError) && response.uploadChartValidationError.length > 0) {
                        this.setState({ status: 'Error', acceptedFiles: acceptedFiles, validationErrors: response.uploadChartValidationError });
                    } else {
                        this.setState({ status: 'Success', acceptedFiles: acceptedFiles, validationErrors: [] });
                    }
                });
        }
    }

    /**
        * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        return (
            <ReactModal
                isOpen={this.props.showUploadAccountMappingsModal}
                onRequestClose={this.handleClose}
                className="react-modal react-modal"
                overlayClassName="react-modal-overlay">
                <div style={{ marginTop: '15px', marginBottom: '15px', width: '100%', textAlign: 'center' }}>
                    <Header content="Upload Account Mappings" />

                    <Dropzone
                        tabIndex="-1"
                        multiple={true}
                        accept={this.acceptedFileExtensions}
                        onDrop={this.onDrop}
                        maxSize={Constants.MAX_UPLOAD_FILE_SIZE_IN_BYTES}>
                        {({ getRootProps, getInputProps }) => (
                            <section className="container">
                                <div {...getRootProps({ className: 'dropzone' })} tabIndex="-1"
                                    style={{ width: '100%', textAlign: 'center', border: '1px dashed #666', padding: '50px' }} >
                                    <div><MotifIcon src={fileIcCloudUpload24px} className="uploadIconSize" title="Upload File(s)" /></div>
                                    <input {...getInputProps()} />
                                    <div>Try dropping some files here, or click to <MotifTextLink href="javascript:void(0)" variant="alt">select files</MotifTextLink> to upload.</div>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                    <br />
                    {
                        this.state.status != null && 
                            <UploadStatusComponent 
                                acceptedFiles={this.state.acceptedFiles}
                                rejectedFiles={this.state.rejectedFiles}
                                status={this.state.status}
                                validationErrors={this.state.validationErrors}
                            />
                    }
                </div>
                <MotifButton variant="primary" className="right"
                    type="button"
                    onClick={this.handleClose}>
                    Close
                </MotifButton>

            </ReactModal>
        );
    }
}

AccountMappingsUploadModal.propTypes = {
    toggleShowUploadAccountMappingsModal: PropTypes.func.isRequired,
    showUploadAccountMappingsModal: PropTypes.bool.isRequired,
    authHeader: PropTypes.object,
    //chartOfAccountsFormValues: PropTypes.object
};

/**
 * Maps items from state to properties of the component
* @param {Object} state The state
* @param {Object} ownProps The properties of the component
* @returns {Object} An object containing properties that the component can access
*/
function mapStateToProps(state, ownProps) {
    return {
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        trialBalanceActions: bindActionCreators(trialBalanceActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountMappingsUploadModal);