import React from "react";
import PropTypes from "prop-types";
import AuthListRow from "./AuthListRow";
import AddSysAdminModal from "./AddSysAdminModal";
import { Search, Icon, Input, Button } from "semantic-ui-react";
import { MotifFormField, MotifLabel, MotifSearch, MotifButton } from "@ey-xd/motif-react";

const AdminAuthList = ({ auths, handleSearchChange, handleClearSearchText, adminUserSearchText, handleEditButtonClick, handleDeleteButtonClick,
    handleNewButtonClick, showAddSysAdminModal, handleAddSysAdminModalAction, auth, toggleShowAddSysAdminModal, currentUserId, addAuthMessageText, validateDomain }) => {
    return (
        <React.Fragment>
            <div className="row d-flex">
                <div className="col-md-8">
                    <h1>Manage System Administrators</h1>
                </div>
                <div className="col-md-4">
                    <MotifButton variant="primary" data-testId="addUser" className="right" onClick={handleNewButtonClick}>Add User</MotifButton>
                </div>
            </div>
            <div className="row d-flex justify-content-end">
                <div className="col-md-8" />
                <div className="col-md-4">
                    <MotifFormField>
                        <MotifLabel data-testId="lblSearch">Search for User</MotifLabel>
                        <MotifSearch
                            data-testId="txtSearch" 
                            type="text"
                            onChange={handleSearchChange}
                            value={adminUserSearchText}
                            title="Type in a user name"
                            aria-label="Search for User"
                            searchButtonProps={{tabIndex:"-1","data-testId":"btnSearch"}} />
                    </MotifFormField>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <section className="table-responsive rtable">
                        <table data-testId="tblMngSysAdmin" className="tbl-manage-sys-admin">
                            <thead>
                                <tr role="row" aria-rowindex={1}>
                                    <th role="columnheader" aria-sort="none" aria-colindex={1}>User</th>
                                    <th role="columnheader" aria-sort="none" aria-colindex={2}>Role</th>
                                    <th role="columnheader" aria-sort="none" aria-colindex={3} />
                                    <th role="columnheader" aria-sort="none" aria-colindex={4} />
                                </tr>
                            </thead>
                            <tbody>
                                {auths.map((auth, index) => <AuthListRow rowIndex={index+2} key={auth.id} auth={auth} handleEditButtonClick={handleEditButtonClick}
                                    handleDeleteButtonClick={handleDeleteButtonClick} currentUserId={currentUserId} authResource="system" />)}
                            </tbody>
                        </table>
                    </section>
                </div>
            </div>
            <AddSysAdminModal showAddSysAdminModal={showAddSysAdminModal} onSubmit={handleAddSysAdminModalAction} toggleShowAddSysAdminModal={toggleShowAddSysAdminModal} initialValues={auth} addAuthMessageText={addAuthMessageText} validateDomain={validateDomain} />
        </React.Fragment>
    );
};

AdminAuthList.propTypes = {
    auths: PropTypes.array.isRequired,
    handleSearchChange: PropTypes.func,
    handleClearSearchText: PropTypes.func,
    adminUserSearchText: PropTypes.string,
    handleEditButtonClick: PropTypes.func,
    handleDeleteButtonClick: PropTypes.func,
    handleNewButtonClick: PropTypes.func,
    showAddSysAdminModal: PropTypes.bool,
    handleAddSysAdminModalAction: PropTypes.func,
    auth: PropTypes.object,
    toggleShowAddSysAdminModal: PropTypes.func,
    currentUserId: PropTypes.string,
    addAuthMessageText: PropTypes.string,
    validateDomain: PropTypes.func
};

export default AdminAuthList;