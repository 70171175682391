import PropTypes from "prop-types";
import React from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { Header, Label, Button, Dropdown, Input } from "semantic-ui-react";
import * as formValidators from "../../scripts/formValidators";
import * as formHelpers from "../../scripts/formHelpers";
import ReactModal from "react-modal";
import $ from "jquery";
import { MotifButton, MotifModal, MotifModalHeader, MotifModalBody, MotifModalFooter } from '@ey-xd/motif-react';
import * as Constants from '../../constants/other';
const ArqcCreateChecklistModal = ({ handleSubmit, handleClientFieldChanged, handlePopulatePreviousAnswersChanged, isSendEmailEnabled, isPreviousAnswersPopulated, showModal, setCreateAction, handleCancelAction, error, handleDismissErrorMessage, clientEmailsRef, checklist, clientEmail, handleRollForwardComment, createChecklistCustomErrorMessage }) => {
    let prePopulateOptions = Constants.prePopulateOptions;
    const rollForwardQuestionComment = Constants.rollForwardQuestionComment; 
    const preventSubmitOnEnter = (e) => {
        if (e.key === 'Enter') {
            //Avoid preventing key press for buttons
            if (e.target && e.target.type !== 'button' && e.target.type !== 'submit') {
                e.preventDefault();
            }
        }
    };

    const handleCreateButtonClick = (e) => {
        setCreateAction("CREATE");
    };

    const handleCreateAndSendButtonClick = () => {
        setCreateAction("CREATEANDSEND");
    };

    const validateEmails = () => {
        let sendToFieldValue = $("#sendToField :input").val();

        if (sendToFieldValue !== "") {
            throw new SubmissionError({
                _error: "'" + sendToFieldValue + "' is not a valid email address."
            });
        }
        return handleSubmit();
    };

    const HeaderWrapper = ({ children }) => (
        <div
            style={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'center'
            }}
        >
            {children}
        </div>
    );
  
    const closeButtonProps = {
        title: 'Close add new ARQC modal',
        ariaLabel: 'Close add new ARQC modal',
        dataTestid: 'close'
    };

    return (
            <MotifModal variant="alt"  show={showModal} onClose={handleCancelAction} data-testid="addARQCModal">
          <MotifModalHeader style={{ display: "-webkit-box" }} closeButtonProps={closeButtonProps} >
                <HeaderWrapper>
                    <span className="ml-2">Create a new ARQC</span>
                </HeaderWrapper>
            </MotifModalHeader>
         
            <MotifModalBody>
            <form onSubmit={handleSubmit(validateEmails)} onKeyPress={preventSubmitOnEnter} id="createARQC">
                {formHelpers.showSubmissionError(error, handleDismissErrorMessage)}
                <div className="field">
                <label htmlFor="customTemplate">Pre-Populate Answers? / Show Reference Answers?</label>
                <Dropdown
                    fluid
                    selection
                    onChange={handlePopulatePreviousAnswersChanged}
                    name="populatePreviousAnswersField"
                    options={prePopulateOptions}
                    multiple={false}
                    defaultValue={Constants.defaultPrepopulateAnswer}/>
              </div>

              <div className="field">
                <label htmlFor="customTemplate">Roll Forward Question Comments? / Attachments?</label>
                <Dropdown
                    fluid
                    selection
                    onChange={handleRollForwardComment}
                    name="rollForwardQuestionCommentsField"
                    options={rollForwardQuestionComment}
                    multiple={false}
                    defaultValue={Constants.defaultRollFowardQuestionComment}/>
              </div>

                <div className="field">
                    <Field
                        label="Send to (Multiple email addresses are supported if separated by a semi-colon)"
                        component={formHelpers.renderReactMultiEmail}
                        id="sendToField"
                        name="sendToField"
                        type="text"
                        domRef={clientEmailsRef}
                        handleChange={handleClientFieldChanged}
                        emails= {clientEmail}
                        removeEYEmail={true}
                        validate={[formValidators.multipleEmails, formValidators.maxLength255]} />
                </div>
                {createChecklistCustomErrorMessage && createChecklistCustomErrorMessage.length > 0 && 
                    <div className="text-danger">
                        {createChecklistCustomErrorMessage}
                    </div>
                }
                 </form>
                </MotifModalBody>
                <MotifModalFooter> 
                <MotifButton variant="primary" className="right" type="submit" form="createARQC"
                    onClick={handleCreateButtonClick}>
                    Create Only
                </MotifButton>               
                <MotifButton variant="primary" className="right" type="submit" form="createARQC"
                    onClick={handleCreateAndSendButtonClick}
                    disabled={!isSendEmailEnabled} >
                    Create &amp; Send
                </MotifButton>                
                <MotifButton variant="secondary" className="right" type="submit"
                    onClick={handleCancelAction}>
                    Cancel
                </MotifButton>
                </MotifModalFooter>
          
            </MotifModal>
    );
};

ArqcCreateChecklistModal.propTypes = {
    showModal: PropTypes.bool,
    handleSubmit: PropTypes.func,
    handleClientFieldChanged: PropTypes.func,
    handlePopulatePreviousAnswersChanged: PropTypes.func,
    handleEyFieldChanged: PropTypes.func,
    isSendEmailEnabled: PropTypes.bool,
    isPreviousAnswersPopulated: PropTypes.bool,
    setCreateAction: PropTypes.func,
    handleCancelAction: PropTypes.func,
    error: PropTypes.string,
    handleDismissErrorMessage: PropTypes.func,
    clientEmailsRef: PropTypes.func,
    checklist: PropTypes.object,
    clientEmail:PropTypes.array,
    handleRollForwardComment:PropTypes.func
};

export default reduxForm({ form: "arqccreatechecklistmodal", enableReinitialize: true })(ArqcCreateChecklistModal);