import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Form, Icon, Popup, Dropdown } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import * as formHelpers from "../../scripts/formHelpers";
import * as formValidators from "../../scripts/formValidators";
import { MotifButton, MotifIconButton, MotifIcon } from "@ey-xd/motif-react";
import { contentIcContentCopy24px, contentIcContentPaste24px, imageIcEdit24px, actionIcDelete24px, fileIcFileDownload24px, actionIcInfo24px } from '@ey-xd/motif-react/assets/icons';
import { addAttribute } from "../../common/Attribute";

const AssignReitsTable = ({
    defaultFdrTemplates,
    onSubmit,
    pristine,
    submitting,
    formSyncErrors,
    formSubmitErrors,
    handleSubmit,
    handleReset,
    saved,
    handleCopyField,
    handlePasteField,
    copiedFieldName,
    hasWriteAccess,
    customQuestionnaireTemplates,
    customTemplateOptions
}) => {

    useEffect(() => {

        //Add Attribute(type=button) to all button elements having attribute(role = 'combobox')
        addAttribute("button[role = 'combobox']", "type", "button");
    });

    const preventSubmitOnEnter = (e) => {
        if (e.key === 'Enter') {
            //Avoid preventing key press for buttons
            if (e.target && e.target.type !== 'button') {
                e.preventDefault();
            }
        }
    };

    const glowStyle = {
        boxShadow: "0 0 5px rgba(81, 203, 238, 1)",
        border: "1px solid rgba(81, 203, 238, 1)"
    };

    const hasFormSyncErrors = Object.keys(formSyncErrors).length !== 0;
    const hasFormSubmitErrors = Object.keys(formSubmitErrors).length !== 0;

    return <div>
        <div style={{ minHeight: '24px' }}></div>
        <div className="row mb-3">
            <div className="col">
            {defaultFdrTemplates && defaultFdrTemplates.length > 0 &&
                <Form onSubmit={handleSubmit(onSubmit)} onKeyPress={preventSubmitOnEnter}>
                    
                    {hasWriteAccess &&
                        <React.Fragment>                    
                            <MotifButton variant="primary" className="right ml-2" type="submit"
                                disabled={pristine || submitting || hasFormSyncErrors}>
                                Save Default FDR Templates
                            </MotifButton>                    
                            <MotifButton variant="secondary" className="right ml-2" type="button"
                                disabled={pristine || submitting} onClick={handleReset}>
                                Reset
                            </MotifButton>
                        </React.Fragment>}
                {saved && <strong className="text-success right ml-2">Saved</strong>}
                {copiedFieldName && <div>
                    <span> <MotifIcon className="mr-2" src={actionIcInfo24px} /> </span>
                    <span className="copyInfoMessage">Press Escape to display Copy buttons
                    </span>
                </div> || <div>&nbsp;</div>}
                {<div style={{ height: '10px'}}> </div> }
                {(hasFormSyncErrors || hasFormSubmitErrors) && <div><strong className="text-danger">There are invalid form entries. Please correct.</strong></div>}
                    <div className="overflow-visible w-100 mb-3">
                        <table className="table mt-3">
                            <thead>
                                <tr>
                                    <th>REIT</th>
                                    {[1, 2, 3, 4].map(q => {
                                        return <th key={`th_q${q}`}>
                                            <div className="d-flex justify-content-between">
                                                Q{q}
                                                {hasWriteAccess && <div className="d-flex align-content-center ml-1">
                                                    {!copiedFieldName && <Popup
                                                        content='Copy entire column'
                                                        trigger={
                                                            <MotifIconButton aria-label="Copy entire column" type="button"
                                                                onClick={() => handleCopyField(`col_q${q}`)} >
                                                                <MotifIcon src={contentIcContentCopy24px} title="Copy entire column" />
                                                            </MotifIconButton>
                                                        }
                                                    />
                                                    }
                                                    {copiedFieldName && copiedFieldName.includes('_') &&
                                                        <Popup
                                                        content='Paste across entire column'
                                                        trigger={
                                                            <MotifIconButton className="ml-1" aria-label="Paste across entire column" type="button"
                                                                onClick={() => handlePasteField(null, `q${q}`)}
                                                            disabled={!copiedFieldName || (copiedFieldName && !copiedFieldName.includes('_'))}
                                                            >
                                                                <MotifIcon src={contentIcContentPaste24px} title="Paste across entire column" />
                                                            </MotifIconButton>
                                                        }
                                                    />
                                                    }
                                                </div>}
                                            </div>
                                        </th>;
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {defaultFdrTemplates.map(dft => {
                                    return <tr key={`tr_${dft.reitId}`} className={dft.disabledReason ? 'table-secondary text-muted' : null}>
                                        <td>
                                            <div className="d-flex justify-content-between">
                                                {hasWriteAccess && !dft.disabledReason && <div className="d-flex align-content-center ml-1">
                                                    {!copiedFieldName && 
                                                        <Popup
                                                        content='Copy entire row'
                                                        trigger={
                                                            <MotifIconButton aria-label="Copy entire row" type="button"
                                                                onClick={() => handleCopyField(dft.reitId.toString())} 
                                                            >
                                                                <MotifIcon src={contentIcContentCopy24px} title="Copy entire row" />
                                                            </MotifIconButton>
                                                        }
                                                    />
                                                    }
                                                    {copiedFieldName && !copiedFieldName.includes('col_') &&
                                                        <Popup
                                                        content='Paste across entire row'
                                                        trigger={
                                                            <MotifIconButton aria-label="Paste across entire row" type="button"
                                                                onClick={() => handlePasteField(dft.reitId.toString())}
                                                            disabled={!copiedFieldName || (copiedFieldName && copiedFieldName.includes('col_'))}
                                                            >
                                                                <MotifIcon src={contentIcContentPaste24px} title="Paste across entire row" />
                                                            </MotifIconButton>
                                                        }
                                                    />}
                                                </div>}
                                                <div className="flex-fill">
                                                    {dft.reitName}
                                                </div>
                                            </div>
                                        </td>
                                        {[1, 2, 3, 4].map(q => {
                                            const wrapperStyle =
                                                copiedFieldName ===
                                                    `q${q}_${dft.reitId}` ||
                                                copiedFieldName === `col_q${q}` ||
                                                copiedFieldName == dft.reitId
                                                    ? glowStyle
                                                    : {};
                                            
                                            return <td key={`td_${dft.reitId}_${q}`}>
                                                <div className="d-flex justify-content-between">
                                                    <div className="flex-fill">
                                                        <div style={{...wrapperStyle, minWidth: '200px'}}>
                                                        <Field
                                                            component={formHelpers.SingleSelectComponent}
                                                            name={`q${q}_${dft.reitId}`}
                                                            options={customTemplateOptions}
                                                            disabled={!hasWriteAccess}
                                                            selection
                                                            multiple={false}
                                                            placeholder="Standard Template"
                                                            renderUntilValueSet={true}
                                                            renderUntilOptionsSet={true}
                                                            />
                                                        </div>
                                                    </div>
                                                    {hasWriteAccess && !dft.disabledReason && <div className="d-flex align-content-center ml-1">
                                                        {!copiedFieldName &&
                                                            <Popup
                                                            content='Copy value'
                                                            trigger={
                                                                    <MotifIconButton aria-label="Copy value" type="button"
                                                                        onClick={() => handleCopyField(`q${q}_${dft.reitId}`)}
                                                                    >
                                                                        <MotifIcon src={contentIcContentCopy24px} title="Copy value" />
                                                                    </MotifIconButton>
                                                            }
                                                        />
                                                        }
                                                        {copiedFieldName &&
                                                            copiedFieldName.includes('_') &&
                                                            !copiedFieldName.includes('col_') &&
                                                            <Popup
                                                            content='Paste value'
                                                            trigger={
                                                                    <MotifIconButton aria-label="Paste value" type="button"
                                                                            onClick={() => handlePasteField(`q${q}_${dft.reitId}`)}
                                                                    disabled={!copiedFieldName || (copiedFieldName && copiedFieldName.includes(`q${q}_${dft.reitId}`)) || (copiedFieldName && !copiedFieldName.includes('_')) || (copiedFieldName && copiedFieldName.includes('col_'))}
                                                                        >
                                                                        <MotifIcon src={contentIcContentPaste24px} title="Paste value" />
                                                                    </MotifIconButton>
                                                            }
                                                        />
                                                        }
                                                    </div>}
                                                </div>
                                            </td>;
                                        })}
                                    </tr>
                                }
                                )}
                            </tbody>
                        </table>
                    </div>
                    {(hasFormSyncErrors || hasFormSubmitErrors) && <div><strong className="text-danger">There are invalid form entries. Please correct.</strong></div>}
                    {hasWriteAccess &&
                        <React.Fragment> 
                            <MotifButton variant="primary" className="right ml-2" type="submit"
                                disabled={pristine || submitting || hasFormSyncErrors}>
                                Save Default FDR Templates
                            </MotifButton>
                            <MotifButton variant="secondary" className="right ml-2" type="button"
                                disabled={pristine || submitting} onClick={handleReset}>
                                Reset
                            </MotifButton>
                        </React.Fragment>}
                    {saved && <strong className="text-success right ml-2">Saved</strong>}
                </Form>}

            {defaultFdrTemplates && defaultFdrTemplates.length === 0 &&
                <div>Data not available. Ensure there is at least one REIT created for this Client.</div>}
        </div>
        </div>
    </div>;
};

AssignReitsTable.propTypes = {
    checklistDeliveryCalendar: PropTypes.array,
    customQuestionnaireTemplates: PropTypes.array
};

export default reduxForm({ form: "defaultFdrTemplates" })(AssignReitsTable);