import React from 'react';
import PropTypes from 'prop-types';

import {
    MotifIconButton,
    MotifIcon,
    MotifDropdownPortal,
    MotifDropdownItem,
    MotifAvatar
} from "@ey-xd/motif-react";
import { actionIcExitToApp24px } from '@ey-xd/motif-react/assets/icons';

const UserAvatarComponent = ({
    isUserDropdownOpen,
    handleToggleUserDropdownWhenClickedOutside,
    handleToggleUserDropdown,
    userName,
    userEmail,
    userRole,
    userProfilePictureUrl,
    navToLogoff
}) => {
    return (
        <>
            <MotifDropdownPortal
                open={isUserDropdownOpen}
                placement="bottom-left"
                aria-labelledby="User Avatar Dropdown"
                handleClickOutside={handleToggleUserDropdownWhenClickedOutside}
                trigger={
                    <MotifIconButton
                        type="button"
                        onClick={handleToggleUserDropdown}
                        id="userDropDownTrigger"
                        aria-label="User Options"
                    >
                        <MotifAvatar
                            userName={userName}
                            description={`${userName} ${userEmail}`}
                            status={null}
                            size="small"
                            src={userProfilePictureUrl}
                        />
                    </MotifIconButton>
                }
            >
                <MotifDropdownItem
                    className='dark-user-avatar-dropdown-item'
                    style={{
                        height: '80px',
                        pointerEvents: 'none',
                    }}
                    onClick={() => { }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}
                    >
                        <div style={{ flex: '1', margin: 'auto' }}>
                            <MotifAvatar
                                userName={userName}
                                description={`${userName} ${userEmail}`}
                                status={null}
                                size="large"
                                src={userProfilePictureUrl}
                            />
                        </div>
                        <div className='ml-3' style={{ flex: '1.5' }}>
                            <h6>{userName}</h6>
                            <div>{userEmail}</div>
                            <div>{userRole}</div>
                        </div>
                    </div>
                </MotifDropdownItem>
                <MotifDropdownItem
                    className='dark-user-avatar-dropdown-item'
                    style={{
                        height: '40px'
                    }}
                    onClick={navToLogoff}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            width: '100%',
                        }}
                    >
                        <div
                            className="mr-1"
                            style={{ flex: '1', textAlign: 'end' }}
                        >
                           <h6>
                                Logoff
                            </h6>
                        </div>
                        <div className="ml-1" style={{ flex: '1' }}>
                            <MotifIcon
                                src={actionIcExitToApp24px}
                                title="Logoff"
                                className='logoff-icon'
                            />
                        </div>
                    </div>
                </MotifDropdownItem>
            </MotifDropdownPortal>
        </>
    );
};

UserAvatarComponent.propTypes = {
    isUserDropdownOpen: PropTypes.bool,
    handleToggleUserDropdownWhenClickedOutside: PropTypes.func,
    handleToggleUserDropdown: PropTypes.func,
    userName: PropTypes.string,
    userEmail: PropTypes.string,
    userRole: PropTypes.string,
    userProfilePictureUrl: PropTypes.string,
    navToLogoff: PropTypes.func
};

export default UserAvatarComponent;