import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SubmissionError } from "redux-form";
import * as securitiesActions from "../../actions/securitiesSnapshotActions";
import * as reitActions from "../../actions/reitActions";
import * as PeriodActions from "../../actions/periodActions";
import * as commonActions from "../../actions/commonActions";
import EditSecuritiesSnapshotComponent from "../../components/reits/EditSecuritiesSnapshotComponent";
import * as actionHelpers from "../../scripts/actionHelpers";
import * as formHelpers from "../../scripts/formHelpers";

/**
 * EditSecuritiesSnapshotContainer container component
 * @extends {React.Component}
 */
export class EditSecuritiesSnapshotContainer extends React.Component {

    /**
     * Creates a new EditSecuritiesSnapshotContainer
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);

        this.state = {
            showForm: false,
            formPurpose: "",
            canEdit: true,
            selectedSecuritiesSnapshot: {
                "allSharesFromIssuerValue": 0,
                "class": "",
                "issuer": "",
                "securityName": "",
                "reitOwnedSharesValue": 0,
                "percentOfVote": 0,
                "reportPeriodID": 0,
            }
        };
        this.closeForm = this.closeForm.bind(this);
        this.showForm = this.showForm.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.handleEditButtonClick = this.handleEditButtonClick.bind(this);
        this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
        this.handleDismissErrorMessage = this.handleDismissErrorMessage.bind(this);
    }

    /**
     * Invoked immediately after mounting occurs
     * @returns {undefined}
     */
    componentDidMount() {
        this.refresh();
    }

     /**
     * Invoked before a mounted component receives new props.
     * @param {Object} nextProps The properties that the component is receiving
     */
    componentWillReceiveProps(nextProps) {
        if (nextProps.period !== this.props.period) {
            this.setCanEdit(nextProps.period);
        }
    }

    handleDismissErrorMessage() {
        this.props.commonActions.clearFormErrors('securitiesSnapshot');
    }


    refresh() {
        // Check if state.period is null, if it is populate period
        if (!this.props.period) {
            this.props.periodActions.fetchPeriodById(this.props.periodId);
        }
        else {
            this.setCanEdit(this.props.period);
        }

        
        return this.props.securitiesActions.fetchSecuritiesSnapshotByPeriod(this.props.periodId);
    }

    submitForm(values) {
        if (!values.class) {
            throw new SubmissionError({ name: "Class is required" });
        } else {
            switch (this.state.formPurpose) {
                case "EDIT":
                {
                    this.props.commonActions.beginTask();
                    const securitiesSnapshot = {
                        "securitiesSnapshotID": values.securitiesSnapshotID,
                        "allSharesFromIssuerValue": values.allSharesFromIssuerValue,
                        "class": values.class,
                        "issuer": values.issuer,
                        "securityName": values.securityName,
                        "reitOwnedSharesValue": values.reitOwnedSharesValue,
                        "percentOfVote": values.percentOfVote,
                        "reportPeriodID": this.props.reportPeriodID
                    }; 

                    return securitiesActions.updateSecuritiesSnapshot(securitiesSnapshot).then(data => {
                        if (actionHelpers.isErrorResponse(data)) {
                            this.props.commonActions.endTask();
                            formHelpers.generateSubmissionError(data);
                            return;
                        }

                        this.closeForm();
                        this.props.commonActions.endTask();
                        this.refresh();
                    }).catch(error => {
                        if (error instanceof SubmissionError) {
                            throw error;
                        }

                        this.props.commonActions.endTask();
                        formHelpers.generateSubmissionError();
                    });
                }
                case "DELETE":
                {
                    this.props.commonActions.beginTask();
                    const securitiesSnapshot = {
                        "securitiesSnapshotID": values.securitiesSnapshotID,
                        "allSharesFromIssuerValue": values.allSharesFromIssuerValue,
                        "class": values.class,
                        "issuer": values.issuer,
                        "securityName": values.securityName,
                        "reitOwnedSharesValue": values.reitOwnedSharesValue,
                        "percentOfVote": values.percentOfVote,
                        "reportPeriodID": this.props.reportPeriodID
                    };

                    return securitiesActions.deleteSecuritiesSnapshot(securitiesSnapshot).then(data => {
                        if (actionHelpers.isErrorResponse(data)) {
                            this.props.commonActions.endTask();
                            formHelpers.generateSubmissionError(data);
                            return;
                        }

                        this.closeForm();
                        this.props.commonActions.endTask();
                        this.refresh();
                    }).catch(error => {
                        if (error instanceof SubmissionError) {
                            throw error;
                        }

                        this.props.commonActions.endTask();
                        formHelpers.generateSubmissionError();
                    });
                }
                default:
                {
                    this.props.commonActions.beginTask();
                    const securitiesSnapshot = {
                        "allSharesFromIssuerValue": values.allSharesFromIssuerValue,
                        "class": values.class,
                        "issuer": values.issuer,
                        "securityName": values.securityName,
                        "reitOwnedSharesValue": values.reitOwnedSharesValue,
                        "percentOfVote": values.percentOfVote,
                        "reportPeriodID": this.props.periodId
                    };

                    return securitiesActions.saveSecuritiesSnapshot(securitiesSnapshot).then(data => {
                        if (actionHelpers.isErrorResponse(data)) {
                            this.props.commonActions.endTask();
                            formHelpers.generateSubmissionError(data);
                            return;
                        }

                        this.props.reitActions.fetchReit(this.props.reitId);
                        this.closeForm();
                        this.props.commonActions.endTask();
                        this.refresh();
                    }).catch(error => {
                        if (error instanceof SubmissionError) {
                            throw error;
                        }

                        this.props.commonActions.endTask();
                        formHelpers.generateSubmissionError();
                    });
                }
            }
        }
    }

    showForm() {
        this.setState({ showForm: true, formPurpose: "", selectedSecuritiesSnapshot: null });
    }

    closeForm() {
        this.setState({ showForm: false });
    }

    handleEditButtonClick(value) {
        this.setState({
            showForm: true,
            formPurpose: "EDIT",
            selectedSecuritiesSnapshot: {
                "securitiesSnapshotID": value.securitiesSnapshotID,
                "allSharesFromIssuerValue": value.allSharesFromIssuerValue ? value.allSharesFromIssuerValue : "0",
                "class": value.class,
                "issuer": value.issuer,
                "securityName": value.securityName,
                "reitOwnedSharesValue": value.reitOwnedSharesValue ? value.reitOwnedSharesValue : "0",
                "percentOfVote": value.percentOfVote ? value.percentOfVote : "0",
                "reportPeriodID": value.reportPeriodID
            }
        });
    }

    handleDeleteButtonClick(value) {
        this.setState({
            showForm: true,
            formPurpose: "DELETE",
            selectedSecuritiesSnapshot: {
                "securitiesSnapshotID": value.securitiesSnapshotID,
                "allSharesFromIssuerValue": value.allSharesFromIssuerValue ? value.allSharesFromIssuerValue : "0",
                "class": value.class,
                "issuer": value.issuer,
                "securityName": value.securityName,
                "reitOwnedSharesValue": value.reitOwnedSharesValue ? value.reitOwnedSharesValue : "0",
                "percentOfVote": value.percentOfVote ? value.percentOfVote : "0",
                "reportPeriodID": value.reportPeriodID
            }
        });
    }

    setCanEdit(period) {
        const isEnabled = PeriodActions.isTestingEnabled(period);
        this.setState({ canEdit: isEnabled});
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        return (
            <EditSecuritiesSnapshotComponent
                securitiesSnapshots={this.props.securitiesSnapshots}
                showForm={this.state.showForm}
                handleCloseForm={this.closeForm}
                handleNewButtonClick={this.showForm}
                handleSubmit={this.submitForm}
                formPurpose={this.state.formPurpose}
                selectedSecuritiesSnapshot={this.state.selectedSecuritiesSnapshot}
                handleEditButtonClick={this.handleEditButtonClick}
                handleDeleteButtonClick={this.handleDeleteButtonClick}
                canEdit={this.state.canEdit}
                handleDismissErrorMessage={this.handleDismissErrorMessage}
            />
        );
    }
}

EditSecuritiesSnapshotContainer.propTypes = {
    clientId: PropTypes.number.isRequired,
    reitId: PropTypes.number.isRequired,
    period: PropTypes.object.isRequired,
    periodId: PropTypes.number.isRequired,
    periodStatus: PropTypes.string,
    securitiesActions: PropTypes.object.isRequired,
    reitActions: PropTypes.object,
     periodActions: PropTypes.object.isRequired,
    securitiesSnapshots: PropTypes.arrayOf(PropTypes.shape({
        allSharesFromIssuerValue: PropTypes.number,
        class: PropTypes.string,
        securitiesSnapshotID: PropTypes.number,
        issuer: PropTypes.string,
        securityName: PropTypes.string,
        reitOwnedSharesValue: PropTypes.number,
        percentOfVote: PropTypes.number,
        reportPeriodID: PropTypes.number,
    })),
    commonActions: PropTypes.object.isRequired
};

EditSecuritiesSnapshotContainer.contextTypes = {
    router: PropTypes.object
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    return {
        clientId: Number.parseInt(ownProps.params.clientId),
        reitId: Number.parseInt(ownProps.params.reitId),
        periodId: Number.parseInt(ownProps.params.periodId),
        securitiesSnapshots: state.securitiesSnapshots,
        period: state.period
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        securitiesActions: bindActionCreators(securitiesActions, dispatch),
        periodActions: bindActionCreators(PeriodActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch),
        reitActions: bindActionCreators(reitActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditSecuritiesSnapshotContainer);