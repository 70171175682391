import React from 'react';
import { MotifBadge } from '@ey-xd/motif-react';

export default (props) => {  
    const status = props.data ? props.data.bulkReportStatusDescription : '';  
    var packageDisplayStatus = status;

    var badgeVariant = 'success';
    switch (status.toLowerCase().split('-')[0].trim()) {
        case "completed":
            badgeVariant ='success';
            break;
        case "unexpected error":
            badgeVariant = 'error';
            // In case of 'unexpected error' status, show status as 'Failed'
            packageDisplayStatus = 'Failed';
            break;
        case "in progress":
            badgeVariant = 'info';
            break;
        case "queued":
            badgeVariant = 'alt';
            break;
        case "unknown or hung":
            badgeVariant = 'alt';
            // In case of 'unknown or hung' status, show status as 'Preparing'
            packageDisplayStatus = 'Preparing';
            break;
        default: badgeVariant = 'alt';
            break;
    }

    return (             
        <div className="bulk-report-management-status-column" >            
            <div className="justify-content-center align-items-center mt-2" >                   
                <MotifBadge className="bulk-report-management-badge" variant={badgeVariant} value={packageDisplayStatus} />
            </div>  
        </div>
        );
};