import { pendingTask, begin, end } from 'react-redux-spinner';
import * as actions from './actionTypes';
import { fetchFromReportApi as fetch } from './fetchFromApi';
import { fetchFromReitApi as fetchFromReit } from './fetchFromApi';
import * as actionHelpers from '../scripts/actionHelpers';
import { download } from '../scripts/fileHelpers';

/**
 * Create or Edit a Custom FDR Template. Pass -1 for fdrtemplateid for Create.
 * If failure, it will dispatch the LOAD_CUSTOM_FDR_TEMPLATES_FAILURE action.
 * @param {number} clientId The id of the client.
 * @param {number} fdrtemplateid The id of the fdr template.
 * @param {string} templateName The name of the template.
 * @param {bool} isdeleted Whether the template is deleted.
 * @returns {function} A function that returns a Promise.
 */
export function createOrEditFDRTemplate(
    clientId,
    fdrtemplateid,
    fdrtemplateName,
    isdeleted,
    fdrtemplateContent,
    currentFdrTemplates = []
) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        const apiMethod = fdrtemplateid === -1 ? 'POST' : 'PUT';
        return fetch(`/fdrtemplates`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: apiMethod,
            body: JSON.stringify({
                clientId,
                fdrtemplateid,
                fdrtemplateName,
                isdeleted,
                fdrtemplateContent
            })
        }).then(response => {
            return response.text();
        }).then(response => {
            const jsonResponse = response ? JSON.parse(response) : {};
            //Duplicate Record check
            if (jsonResponse && jsonResponse.errorCode == "DUPLICATE_RECORD_ERROR") {
                return dispatch({ type: actions.SET_CUSTOM_FDR_TEMPLATE_DUPLICATE, response: jsonResponse, [pendingTask]: end });
            }
            else if (actionHelpers.isErrorResponse(jsonResponse)) {
                return actionHelpers.dispatchErrorAndEndTask(
                    dispatch,
                    actions.CREATE_CUSTOM_FDR_TEMPLATE_FAILURE,
                    jsonResponse,
                );
            }
            if(apiMethod === 'PUT'){
                //Replace the FDR template name
                if (fdrtemplateName){
                    var updatedFdrTemplateList = currentFdrTemplates.templates.map(template => {
                        if (template.fdrTemplateID == fdrtemplateid) {
                            return { ...template, fdrTemplateName: fdrtemplateName }
                        }
                        return template;
                    });
                    dispatch({
                        type: actions.UPDATE_CUSTOM_FDR_TEMPLATES_SUCCESS,
                        updatedFdrTemplateList,
                        [pendingTask]: end,
                    });
                }else{
                    dispatch({ type: actions.TASK_END, [pendingTask]: end });
                }
            }else{
                dispatch({ type: actions.TASK_END, [pendingTask]: end });
            }
            return jsonResponse;
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(
                dispatch,
                actions.CREATE_CUSTOM_FDR_TEMPLATE_FAILURE,
                null,
                error,
            );
        });
    };
}

/**
 * Save a current FDR Template to create a new one.
 * If failure, it will dispatch the LOAD_CUSTOM_FDR_TEMPLATES_FAILURE action.
 * @param {number} clientId The id of the client.
 * @param {number} fdrtemplateid The id of the fdr template.
 * @param {string} templateName The name of the template.
 * @param {bool} isdeleted Whether the template is deleted.
 * @returns {function} A function that returns a Promise.
 */
export function SaveAsNewFDRTemplate(
    clientId,
    fdrtemplateid,
    fdrtemplateName,
    fdrtemplateContent,
) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetch(`/fdrtemplates/${clientId}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                fdrtemplateid,
                fdrtemplateName,
                fdrtemplateContent
            })
        }).then(response => {
            return response.text();
        }).then(response => {
            const jsonResponse = response ? JSON.parse(response) : {};
            //Duplicate Record check
            if (jsonResponse && jsonResponse.errorCode == "DUPLICATE_RECORD_ERROR") {
                return dispatch({ type: actions.SET_CUSTOM_FDR_TEMPLATE_DUPLICATE, response: jsonResponse, [pendingTask]: end });
            }
            else if (actionHelpers.isErrorResponse(jsonResponse)) {
                return actionHelpers.dispatchErrorAndEndTask(
                    dispatch,
                    actions.CREATE_CUSTOM_FDR_TEMPLATE_FAILURE,
                    jsonResponse,
                );
            }
            dispatch({ type: actions.TASK_END, [pendingTask]: end });
            return jsonResponse;
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(
                dispatch,
                actions.CREATE_CUSTOM_FDR_TEMPLATE_FAILURE,
                null,
                error,
            );
        });
    };
}


/**
 * Clear duplicate record error message.
 */
export function clearDuplicateMessage() {
    return function (dispatch) {
        dispatch({ type: actions.CLEAR_CUSTOM_FDR_TEMPLATE_DUPLICATE });
    };
}

/**
 * Fetch all the custom fdr templates for a client. If successful this will dispatch the LOAD_CUSTOM_FDR_TEMPLATES_SUCCESS
 * action, otherwise it will dispatch the LOAD_CUSTOM_FDR_TEMPLATES_FAILURE action.
 * @param {number} clientId The id of the client.
 * @returns {function} A function that returns a Promise.
 */
export function fetchCustomFdrTemplates(
    clientId,
    excludeEmpty = false
) {
    return function (dispatch) {
        if (!clientId) {
            return dispatch({
                type: actions.LOAD_CUSTOM_FDR_TEMPLATES_SUCCESS,
                customFdrTemplates: []
            });
        }
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        return fetch(`/fdrtemplates/templates/${clientId}`)
            .then(response => {
                return response.json();
            })
            .then(customFdrTemplates => {
                if (actionHelpers.isErrorResponse(customFdrTemplates)) {
                    return actionHelpers.dispatchErrorAndEndTask(
                        dispatch,
                        actions.LOAD_CUSTOM_FDR_TEMPLATES_FAILURE,
                        customFdrTemplates,
                    );
                }
                dispatch({
                    type: actions.LOAD_CUSTOM_FDR_TEMPLATES_SUCCESS,
                    customFdrTemplates,
                    [pendingTask]: end,
                });
            })
            .catch(error => {
                actionHelpers.dispatchErrorAndEndTask(
                    dispatch,
                    actions.LOAD_CUSTOM_FDR_TEMPLATES_FAILURE,
                    null,
                    error,
                );
            });
    };
}

/**
 * Fetch custom fdr template by templateId. If successful this will dispatch the LOAD_CUSTOM_FDR_TEMPLATES_SUCCESS
 * action, otherwise it will dispatch the LOAD_CUSTOM_FDR_TEMPLATES_FAILURE action.
 * @param {number} templateId The id of the template.
 * @returns {function} A function that returns a Promise.
 */
export function fetchCustomFdrTemplateById(
    templateId
) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetch(`/fdrtemplates/${templateId}`)
            .then(response => {
                return response.json();
            })
            .then(customFdrTemplate => {
                if (actionHelpers.isErrorResponse(customFdrTemplate)) {
                    actionHelpers.dispatchErrorAndEndTask(
                        dispatch,
                        actions.LOAD_CUSTOM_FDR_TEMPLATES_FAILURE,
                        null,
                    );
                }
                dispatch({ type: actions.TASK_END, [pendingTask]: end });
                return customFdrTemplate;
            })
            .catch(error => {
                actionHelpers.dispatchErrorAndEndTask(
                    dispatch,
                    actions.LOAD_CUSTOM_FDR_TEMPLATES_FAILURE,
                    null,
                    error,
                );
            });
    };
}


/**
 * Save default fdr templates.
 * If failure, it will dispatch the SAVE_DEFAULT_FDR_TEMPLATES_FAILURE action.
 * @param {object} fdrtemplates list of default FDR templates.
 * @returns {function} A function that returns a Promise.
 */
export function saveDefaultFdrTemplates(
    fdrtemplates
) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        const apiMethod = 'PUT';
        return fetchFromReit(`/reits/savedefaulttemplates`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: apiMethod,
            body: JSON.stringify(
                fdrtemplates
            )
        }).then(response => {
            return response.text();
        }).then(response => {
            const jsonResponse = response ? JSON.parse(response) : {};
            if (actionHelpers.isErrorResponse(jsonResponse)) {
                return actionHelpers.dispatchErrorAndEndTask(
                    dispatch,
                    actions.SAVE_DEFAULT_FDR_TEMPLATES_FAILURE,
                    jsonResponse,
                );
            }

            dispatch({ type: actions.TASK_END, [pendingTask]: end });
            return jsonResponse;
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(
                dispatch,
                actions.SAVE_DEFAULT_FDR_TEMPLATES_FAILURE,
                null,
                error,
            );
        });
    };
}

/**
 * Get default fdr template set for reit period.
 * If failure, it will dispatch the GET_DEFAULT_FDR_TEMPLATE_PERIOD_FAILED action.
 * @param {int} clientId the clientId.
 * @param {int} reitId the reitId.
 * @param {string} periodQuarter the period selected.
 * @returns {function} A function that returns a Promise.
 */
export function getDefaultFdrTemplateForPeriod(
    clientId,
    reitId,
    periodQuarter
) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        const apiMethod = 'GET';
        return fetchFromReit(`/reits/getReitPeriodDefaultFDRTemplate/${reitId}/${periodQuarter}/${clientId}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: apiMethod
        }).then(response => {
            return response.json();
        }).then(jsonResponse => {
            if (actionHelpers.isErrorResponse(jsonResponse)) {
                return actionHelpers.dispatchErrorAndEndTask(
                    dispatch,
                    actions.SAVE_DEFAULT_FDR_TEMPLATES_FAILURE,
                    jsonResponse,
                );
            }
            dispatch({ type: actions.TASK_END, [pendingTask]: end });
            return jsonResponse;
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(
                dispatch,
                actions.SAVE_DEFAULT_FDR_TEMPLATES_FAILURE,
                null,
                error,
            );
        });
    };
}

/**
 * Fetch custom fdr template by templateId. If successful this will dispatch the LOAD_CUSTOM_FDR_TEMPLATES_SUCCESS
 * action, otherwise it will dispatch the LOAD_CUSTOM_FDR_TEMPLATES_FAILURE action.
 * @param {number} clientId The id of the client.
 * @returns {function} A function that returns a Promise.
 */
export function fetchDefaultFdrTemplates(
    clientId
) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetchFromReit(`/reits/defaultFdrTemplates/${clientId}`)
            .then(response => {
                return response.json();
            })
            .then(defaultFdrTemplates => {
                if (actionHelpers.isErrorResponse(defaultFdrTemplates)) {
                    actionHelpers.dispatchErrorAndEndTask(
                        dispatch,
                        actions.LOAD_DEFAULT_FDR_TEMPLATES_FAILURE,
                        defaultFdrTemplates,
                    );
                }

                dispatch({
                    type: actions.LOAD_DEFAULT_FDR_TEMPLATES_SUCCESS,
                    defaultFdrTemplates,
                    [pendingTask]: end,
                });
                return defaultFdrTemplates;
            })
            .catch(error => {
                actionHelpers.dispatchErrorAndEndTask(
                    dispatch,
                    actions.LOAD_DEFAULT_FDR_TEMPLATES_FAILURE,
                    null,
                    error,
                );
            });
    };
}

/**
 * Delete custom fdr template by templateId. If successful this will dispatch the LOAD_CUSTOM_FDR_TEMPLATES_SUCCESS
 * action, otherwise it will dispatch the LOAD_CUSTOM_FDR_TEMPLATES_FAILURE action.
 * @param {number} templateId The id of the trmplate.
 * @returns {function} A function that returns a Promise.
 */
export function deleteFdrTemplateById(
    fdrTemplateId
) {
    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });
        return fetch(`/fdrtemplates/` + fdrTemplateId, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: "DELETE"
        }).then(response => {
            return response.text();
        }).then(response => {
            const jsonResponse = response ? JSON.parse(response) : {};
            //Duplicate Record check
            // if (jsonResponse && jsonResponse.errorCode == "DUPLICATE_RECORD_ERROR") {
            //     return dispatch({ type: actions.SET_CUSTOM_FDR_TEMPLATE_DUPLICATE, response: jsonResponse, [pendingTask]: end });
            // }
            if (actionHelpers.isErrorResponse(jsonResponse)) {
                return actionHelpers.dispatchErrorAndEndTask(
                    dispatch,
                    actions.DELETE_TEMPLATE_FAILURE,
                    jsonResponse,
                );
            }
            dispatch({
                type: actions.DELETE_TEMPLATE_SUCCESS,
                fdrTemplateId,
                [pendingTask]: end,
            });
        }).catch(error => {
            actionHelpers.dispatchErrorAndEndTask(
                dispatch,
                actions.DELETE_TEMPLATE_FAILURE,
                null,
                error,
            );
        });
    };
}

/**
 * Download the trial balance blob
 * @param {object} template The template object with id and template name
 * @returns {Promise} A promise that downloads a file
 */
export function downloadFdrTemplateDocument(selectedTemplate, periodId, reportItems, excludedPsqs, includePsqComments, includeArqcComments, templateName) {
    const payload = {
        reportItems,
        excludedPsqs,
        includePsqComments,
        includeArqcComments
    };

    return function (dispatch) {
        dispatch({ type: actions.TASK_BEGIN, [pendingTask]: begin });

        return fetch('/fdrtemplates/template/' + selectedTemplate + '/' + periodId, {
            headers: {
                'Accept': 'application/json, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(payload)
        })
            .then(function (response) {
                if (!response.ok) {
                    throw new Error('Unexpected error');
                }

                return response.blob().then(function (blob) {
                    const contentDisposition = response.headers.get("Content-Disposition");
                    const filename = contentDisposition.split(';')[1].split('=')[1].trim();
                    dispatch({ type: actions.TASK_END, [pendingTask]: end });
                    download(blob, filename);
                });
            }).catch(error => {
                return actionHelpers.dispatchErrorAndEndTask(dispatch, actions.TASK_END, null, error);
            });
    }
}