import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import * as notificationActions from '../actions/notificationActions';
import * as microsoftGraphApiActions from '../actions/microsoftGraphApiActions';
import Menu, { Item as MenuItem } from 'rc-menu';
import { Icon } from 'semantic-ui-react';
import {
    MotifHeader,
    MotifIconButton,
    MotifIcon,
    MotifHeaderLogo
} from "@ey-xd/motif-react";
import { actionIcExitToApp24px, socialIcNotificationsNone24px } from '@ey-xd/motif-react/assets/icons';
import { defaultLogo } from '@ey-xd/motif-react/assets/images';
import { withMsal } from "@azure/msal-react";

import UserAvatarComponent from './shared/navbar/userAvatarComponent';

/**
 * NavBar container component
 * @extends {React.Component}
 */
export class NavBar extends React.Component {
    /**
     * Creates a new NavBar
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);

        this.state = { userId: "", isUserDropdownOpen: false };
    }

    componentDidMount() {
        // Microsoft Graph API call to fetch the profile picture of the logged in user
        this.props.microsoftGraphApiActions.fetchUserProfilePictureV1();
    }

    render() {
        let self = this;
        let user = '';
        const userId = this.props.authentication.currentUser;

        let name = this.props.msalContext.accounts[0].idTokenClaims.name;
        if (name === undefined || name === null || name === "") {
            name = "";
            let firstName = this.props.msalContext.accounts[0].idTokenClaims.given_name;
            if (firstName === undefined || firstName === null) {
                firstName = "";
            }
            let lastName = this.props.msalContext.accounts[0].idTokenClaims.family_name;
            if (lastName === undefined || lastName === null) {
                lastName = "";
            }
            if (firstName !== "") {
                name += firstName;
            }
            if (lastName != "") {
                if (name !== "") {
                    name += " ";
                }
                name += lastName;
            }
        }

        if (name !== "") {
            user += name;
        }
        else {
            user += userId;
        }

        /**
         * Handles a navigation click
         * @param {Object} info The information
         */
        function handleClick(info) {
            switch (info.key) {
                case "Logoff":
                    navToLogoff();
                    break;
            }
        }

        function handleNotificationToggleDisplay() {
            self.props.notificationActions.toggleNotificationsDisplay();
        }

        const rsMenu = (
            <Menu onClick={handleClick} mode="horizontal" openAnimation="slide-up">
                <MenuItem key="Logoff" className="inTopMenu" title="Logoff" tabIndex="1"><Icon name="sign out" size="large" /></MenuItem>
            </Menu>);

        const navToLogoff = () => {
            notificationActions.stopNotificationPolling();
            this.context.router.push(`/external/logoff`);
        };

        const handleToggleUserDropdown = () => {
            this.setState({ isUserDropdownOpen: !this.state.isUserDropdownOpen });
        };

        const handleToggleUserDropdownWhenClickedOutside = () => {
            if (this.state.isUserDropdownOpen === true) {
                this.setState({ isUserDropdownOpen: false });
            }
        };

        return (
            <MotifHeader
                fixed
                logo={
                    <MotifHeaderLogo >
                        <a href="/">
                            <MotifIcon src={defaultLogo} aria-label="EY Logo for REITSuite"/>
                            <span className="hide-for-accessibility">Home</span>
                        </a>
                    </MotifHeaderLogo>
                }
                appHeaderName="REITSuite"
                iconsContainer={
                    <div style={{ width: '100%' }} >
                        <span style={{ float: 'right' }}>
                            <span>
                                <MotifIconButton aria-label="Notifications" type="button"
                                    className={this.props.showNotifications ? "motif-icon-active" : ""}
                                    onClick={handleNotificationToggleDisplay} >
                                    <div style={{ position: 'relative' }}>
                                        <div style={{ width: '100%', height: '100%' }}>
                                            <MotifIcon src={socialIcNotificationsNone24px} title="Notifications" />
                                        </div>
                                        <div >
                                            {(Array.isArray(this.props.notifications) && this.props.notifications.length > 0 &&
                                                <Label color="red" circular floating >{this.props.notifications.length}</Label>) || null}
                                        </div>
                                    </div>
                                </MotifIconButton>
                            </span>
                            <span style={{ float: 'right' }}>
                                <UserAvatarComponent
                                    isUserDropdownOpen={this.state.isUserDropdownOpen}
                                    handleToggleUserDropdownWhenClickedOutside={handleToggleUserDropdownWhenClickedOutside}
                                    handleToggleUserDropdown={handleToggleUserDropdown}
                                    userName={name}
                                    userEmail={userId}
                                    userRole="(External Checklist User)"
                                    userProfilePictureUrl={this.props.userProfilePictureUrl}
                                    navToLogoff={navToLogoff}>
                                </UserAvatarComponent>
                            </span >
                        </span>
                    </div>
                }
            >
            </MotifHeader>
        );
    }
}

NavBar.propTypes = {
    user: PropTypes.object,
    children: PropTypes.element,
    showNotifications: PropTypes.bool.isRequired,
    notificationActions: PropTypes.object,
    microsoftGraphApiActions: PropTypes.object,
    authentication: PropTypes.object,
    userProfilePictureUrl: PropTypes.string
};

NavBar.contextTypes = {
    router: PropTypes.object
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties belonging to this component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    let result = {
        children: ownProps.children,
        showNotifications: state.showNotifications,
        authentication: state.authentication,
        userProfilePictureUrl: state.userProfilePicture
    };

    return result;
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        notificationActions: bindActionCreators(notificationActions, dispatch),
        microsoftGraphApiActions: bindActionCreators(microsoftGraphApiActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withMsal(NavBar));
