import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactModal from 'react-modal';
import * as fileActions from '../../actions/fileActions';
import * as commonActions from "../../actions/commonActions";
import OtherFileUpload from '../../components/fileManagement/OtherFileUpload';
import FileList from '../../components/fileManagement/FileList';
import { MotifButton } from '@ey-xd/motif-react';
import DeleteModal from "../../components/shared/DeleteModal";

/**
 * Container component used for uploading trial balance files
 */
export class FileManagementPage extends React.Component {
    /**
     * Creates a new TrialBalanceUploadPage
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);

        this.state = {
            otherFile: null,
            confirmAction: null,
            errorResponse: null
        };
        this.onOtherFileChange = this.onOtherFileChange.bind(this);
        this.onUploadOtherFile = this.onUploadOtherFile.bind(this);
        this.onOtherFileDownload = this.onOtherFileDownload.bind(this);
        this.onOtherFileDelete = this.onOtherFileDelete.bind(this);
        this.handleCancelDelete = this.handleCancelDelete.bind(this);
        this.onConfirmDelete = this.onConfirmDelete.bind(this);
        this.handleDismissErrorMessage = this.handleDismissErrorMessage.bind(this);
        this.handleDismissSuccessMessage = this.handleDismissSuccessMessage.bind(this);
    }

    /**
     * Invoked immediately after mounting occurs
     * @returns {undefined}
     */
    componentDidMount() {
        this.props.commonActions.clearErrorResponse();
        this.props.fileActions.fetchUploadedReitFiles(this.props.reitId);
    }

    componentWillReceiveProps(nextProps) {
        // If a file is uploaded, files will be set to null and refetched here
        if (this.props.files != null && nextProps.files == null) {
            this.props.fileActions.fetchUploadedReitFiles(this.props.reitId);
        }
    }

    componentWillUnmount() {
        this.props.commonActions.clearErrorResponse();
        this.clearSuccess();
    }

    handleDismissErrorMessage() {
        this.props.commonActions.clearErrorResponse();
        this.setState({ errorResponse: null });
    }

    handleDismissSuccessMessage() {
        this.clearSuccess();
    }

    onOtherFileChange(e) {
        this.clearSuccess();
        this.setState({ otherFile: e.target.files[0] });
    }

    onUploadOtherFile(e) {
        e.preventDefault();
        this.clearSuccess();
        this.props.commonActions.clearErrorResponse();
        this.setState({ errorResponse: null });

        const myFile = this.state.otherFile;
        if (myFile) {
            this.props.fileActions.uploadReitFile(this.props.reitId, myFile);
            this.setState({ otherFile: null });
        }
    }

    onOtherFileDownload(fileName) {
        this.clearSuccess();
        fileActions.downloadReitFile(this.props.reitId, fileName);
    }

    onOtherFileDelete(fileName) {
        this.clearSuccess();
        let self = this;
        this.setState({
            confirmAction: () => {
                this.props.commonActions.beginTask();
                return fileActions.deleteReitFile(self.props.reitId, fileName).then(() => {
                    this.props.commonActions.endTask();
                    return self.props.fileActions.fetchUploadedReitFiles(self.props.reitId);
                }).catch(error => self.props.commonActions.dispatchErrorAndEndTask(error));
            }
        });
    }

    handleCancelDelete() {
        this.setState({ confirmAction: null });
    }

    onConfirmDelete() {
        if (this.state.confirmAction) {
            this.state.confirmAction();
            this.setState({ confirmAction: null });
        }
    }

    clearSuccess() {
        this.props.fileActions.clearUploadFileSuccess();
    }

    /**
     * Render a React element
     * @returns {Object} A reference to the component
     */
    render() {
        const reitModalStyle = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '300px',
                height: '160px'
            }
        };

        return (
            <div>
                <OtherFileUpload onSubmit={this.onUploadOtherFile} onFileChange={this.onOtherFileChange} file={this.state.otherFile} canUpload={true} title="REIT General Files" />
                <FileList files={this.props.files} onDownload={this.onOtherFileDownload} onDelete={this.onOtherFileDelete} canUpload={true} emptyMessage="No files have been uploaded." />
                <DeleteModal
                    showDeleteModal={this.state.confirmAction !== null}
                    confirmationMessage="Are you sure?"
                    size="sm"
                    handleDelete={this.onConfirmDelete}
                    toggleshowDeleteModal={this.handleCancelDelete}
                    closeTitle="close delete modal"
                    headerTitle="Delete File">
                </DeleteModal>
            </div>

        );
    }

}

FileManagementPage.propTypes = {
    reitId: PropTypes.number.isRequired,
    fileActions: PropTypes.object.isRequired,
    errorResponse: PropTypes.object,
    fileUploadSuccess: PropTypes.bool,
    files: PropTypes.array,
    commonActions: PropTypes.object.isRequired,
};

FileManagementPage.contextTypes = {
    router: PropTypes.object
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps Existing properties of the component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    return {
        reitId: Number.parseInt(ownProps.params.reitId),
        errorResponse: state.errorResponse,
        fileUploadSuccess: state.fileUploadSuccess,
        files: state.reitFiles,
        pendingTasks: state.pendingTasks
    };
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        fileActions: bindActionCreators(fileActions, dispatch),
        commonActions: bindActionCreators(commonActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FileManagementPage);