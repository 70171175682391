import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import * as authActions from '../actions/authActions';
import * as notificationActions from '../actions/notificationActions';
import * as trialBalanceActions from '../actions/trialBalanceActions';
import * as microsoftGraphApiActions from '../actions/microsoftGraphApiActions';
import Menu, { Item as MenuItem } from 'rc-menu';
import { Label } from 'semantic-ui-react';
import animate from 'css-animation';
import {
    MotifHeader,
    MotifIconButton,
    MotifIcon,
    MotifHeaderLogo
} from "@ey-xd/motif-react";
import { fileIcFileUpload24px, actionIcDescription24px, socialIcNotificationsNone24px, actionIcExitToApp24px, actionIcSupervisorAccount24px } from '@ey-xd/motif-react/assets/icons';
import { defaultLogo } from '@ey-xd/motif-react/assets/images';
import { withMsal } from "@azure/msal-react";

import UserAvatarComponent from './shared/navbar/userAvatarComponent';

/**
 * NavBar container component
 * @extends {React.Component}
 */
export class NavBar extends React.Component {
    /**
     * Creates a new NavBar
     * @constructor
     * @param {Object} props The component properties
     * @param {Object} context The component context
     */
    constructor(props, context) {
        super(props, context);
        this.state = { userId: "", activeMotifIconId: -1, isUserDropdownOpen: false };
    }

    componentDidMount() {
        // Microsoft Graph API call to fetch the profile picture of the logged in user
        this.props.microsoftGraphApiActions.fetchUserProfilePictureV1();
    }

    render() {
        let showReitFilesNavLink = this.props.children.props.location.pathname && this.props.children.props.location.pathname.toLowerCase().indexOf("/reit/") > 0;
        let showPeriodFilesNavLink = this.props.children.props.location.pathname && this.props.children.props.location.pathname.toLowerCase().indexOf("/period/") > 0;
        let showtbUploadNavLink = this.props.children.props.location.pathname && this.props.children.props.location.pathname.toLowerCase().indexOf("client/") > 0;

        let pfmActive = this.props.children.props.location.pathname && this.props.children.props.location.pathname.toLowerCase().indexOf("/period/") > 0 && this.props.children.props.location.pathname.toLowerCase().indexOf("/files") > 0;
        let manageSystemAdminActive = this.props.children.props.location.pathname && this.props.children.props.location.pathname.toLowerCase().indexOf("adminauth") > 0;
        let logoffActive = this.props.children.props.location.pathname && this.props.children.props.location.pathname.toLowerCase().indexOf("/logoff/") > 0;
        let showHeaderIcons = this.props.children.props.location.pathname && this.props.children.props.location.pathname.toLowerCase().indexOf("/psqrecord") < 0 &&
            this.props.children.props.location.pathname.toLowerCase().indexOf("/arqcrecord") < 0 &&
            this.props.children.props.location.pathname.toLowerCase().indexOf("/preview") < 0;

        //If current url is of Period File Management then set the state activeMotifIconId to 2
        if (pfmActive) {
            if (this.state.activeMotifIconId !== 2) {
                this.state.activeMotifIconId = 2;
            }
        }
        //If current url is of Manage System Administrator then set the state activeMotifIconId to 4
        else if (manageSystemAdminActive) {
            if (this.state.activeMotifIconId !== 4) {
                this.state.activeMotifIconId = 4;
            }
        }
        //If current url is of Logoff then set the state activeMotifIconId to 5
        else if (logoffActive) {
            if (this.state.activeMotifIconId !== 5) {
                this.state.activeMotifIconId = 5;
            }
        }
        //else if (this.state.activeMotifIconId == 1 || this.state.activeMotifIconId == 3) {
        //    //Don't update activeMotifIconId to 5
        //}
        else {
            this.state.activeMotifIconId = -1;
        }

        let clientId = -1;
        if (this.props.client && this.props.client.id) {
            clientId = this.props.client.id;
        }

        let isSysAdmin = false;
        let isEngAdmin = false;
        let isEngUser = false;
        if (Array.isArray(this.props.currentUserAuthorizations)) {
            isSysAdmin = authActions.isSystemAdministrator(this.props.currentUserAuthorizations);
            isEngAdmin = authActions.isEngagementAdministrator(this.props.currentUserAuthorizations, clientId);
            isEngUser = authActions.isUser(this.props.currentUserAuthorizations, clientId);
        }

        let user = '';
        const userId = this.props.authentication.currentUser;

        let name = this.props.msalContext.accounts[0].idTokenClaims.name;
        if (name === undefined || name === null || name === "") {
            name = "";
            let firstName = this.props.msalContext.accounts[0].idTokenClaims.given_name;
            if (firstName === undefined || firstName === null) {
                firstName = "";
            }
            let lastName = this.props.msalContext.accounts[0].idTokenClaims.family_name;
            if (lastName === undefined || lastName === null) {
                lastName = "";
            }
            if (firstName !== "") {
                name += firstName;
            }
            if (lastName != "") {
                if (name !== "") {
                    name += " ";
                }
                name += lastName;
            }
        }

        if (name !== "") {
            user += name;
        }
        else {
            user += userId;
        }

        let userRole = "";
        if (isSysAdmin) {
            userRole = "(System Administrator)";
        } else if (isEngAdmin) {
            userRole = "(Engagement Administrator)";
        } else if (isEngUser) {
            userRole = "(Engagement User)";
        }

        let self = this;

        let remaining = "Remaining Time: " + this.props.authentication.remainingTime;
        let hasRemaining = (this.props.authentication.remainingTime !== undefined && this.props.authentication.remainingTime !== null && this.props.authentication.remainingTime !== "");

        /**
         * Handles a navigation click
         * @param {Object} info The information
         */
        function handleClick(info) {
            if (info.key === "SysAdmin") {
                navToSysAdmin();
            }
            if (info.key === "Files") {
                navToFiles();
            }
            if (info.key === "Notifications") {
                self.props.notificationActions.toggleNotificationsDisplay();
            }
            switch (info.key) {
                case "SysAdmin":
                    navToSysAdmin();
                    break;
                case "Files":
                    navToFiles();
                    break;
                case "ReitFiles":
                    navToReitFiles();
                    break;
                case "ContactAll":
                    navToContactAll();
                    break;
                case "MultipleUpload":
                    navToMultipleUpload();
                    break;
                case "Logoff":
                    navToLogoff();
                    break;
            }
        }

        const animation = {
            enter(node, done) {
                let height;
                return animate(node, 'rc-menu-collapse', {
                    start() {
                        height = node.offsetHeight;
                        node.style.height = 0;
                    },
                    active() {
                        node.style.height = `${height}px`;
                    },
                    end() {
                        node.style.height = '';
                        done();
                    },
                });
            },

            appear() {
                return this.enter.apply(this, arguments);
            },

            leave(node, done) {
                return animate(node, 'rc-menu-collapse', {
                    start() {
                        node.style.height = `${node.offsetHeight}px`;
                    },
                    active() {
                        node.style.height = 0;
                    },
                    end() {
                        node.style.height = '';
                        done();
                    },
                });
            },
        };


        const rsMenu = (
            <Menu onClick={handleClick} mode="horizontal" openAnimation="slide-up">
                {!this.props.clientIsAudit && this.props.client && this.props.client.id > 0 &&
                    <MenuItem key="MultipleUpload" tabIndex="0" className="inTopMenu">
                        <MotifIcon className="headerMenuItemIcons" src={fileIcFileUpload24px} title="TB Upload" >
                        </MotifIcon></MenuItem>
                }
                {showPeriodFilesNavLink && !this.props.clientIsAudit &&
                    <MenuItem key="Files" tabIndex="0" className="inTopMenu">
                        <MotifIcon className="headerMenuItemIcons" src={actionIcDescription24px} title="File Management" />
                    </MenuItem>
                }
                <MenuItem key="Notifications" tabIndex="0" className="inTopMenu">
                    <MotifIcon className="headerMenuItemIcons" src={socialIcNotificationsNone24px} title="Notifications" />
                    {(Array.isArray(this.props.notifications) && this.props.notifications.length > 0 && <Label color="red" circular floating style={{ top: '1.6em', left: '4.55em' }}>{this.props.notifications.length}</Label>) || null}
                </MenuItem>
                {isSysAdmin &&
                    <MenuItem key="SysAdmin" tabIndex="0" className="inTopMenu">
                        <MotifIcon className="headerMenuItemIcons" src={actionIcSupervisorAccount24px} title="Manage System Administrators" />
                    </MenuItem>
                }
                <MenuItem key="Logoff" tabIndex="0" className="inTopMenu">
                    <MotifIcon className="headerMenuItemIcons" src={actionIcExitToApp24px} title="Logoff" />
                </MenuItem>
            </Menu>);

        const navToSysAdmin = () => {
            this.state.activeMotifIconId = 4;
            this.context.router.push(`/adminauth`);
        };

        const navToFiles = () => {
            this.state.activeMotifIconId = 2;
            this.context.router.push(`/client/${this.props.client.id}/reit/${this.props.reit.reitid}/period/${this.props.period.reportPeriodID}/files`);
        };

        const navToReitFiles = () => {
            this.context.router.push(`/client/${this.props.client.id}/reit/${this.props.reit.reitid}/files`);
        };

        const navToContactAll = () => {
            this.context.router.push(`/contactUsers`);
        };

        const navToMultipleUpload = () => {
            this.state.activeMotifIconId = 1;
            // Bulk Upload will open a modal
            this.props.trialBalanceActions.showBulkUploadModal();
        };

        const navToLogoff = () => {
            this.state.activeMotifIconId = 5;
            notificationActions.stopNotificationPolling();
            this.context.router.push(`/external/logoff`);
        };

        const setNavigationState = () => {
            this.state.activeMotifIconId = 3;
        };

        function handleNotificationToggleDisplay() {
            setNavigationState();
            self.props.notificationActions.toggleNotificationsDisplay()
        }

        const handleToggleUserDropdown = () => {
            this.setState({ isUserDropdownOpen: !this.state.isUserDropdownOpen });
        };

        const handleToggleUserDropdownWhenClickedOutside = () => {
            if (this.state.isUserDropdownOpen === true) {
                this.setState({ isUserDropdownOpen: false });
            }
        };

        return (
            <div>
                <MotifHeader
                    fixed
                    logo={
                        <MotifHeaderLogo>
                            <a href="/">
                                <MotifIcon src={defaultLogo}  aria-label="EY Logo for REITSuite"/>
                                <span className="hide-for-accessibility">Home</span>
                            </a>
                        </MotifHeaderLogo>
                    }
                    appHeaderName="REITSuite"
                    iconsContainer={
                        <div style={{ width: '100%' }}>
                            {showHeaderIcons && (
                                <span style={{ float: 'right' }}>
                                    {showtbUploadNavLink &&
                                        !this.props.clientIsAudit &&
                                        this.props.client &&
                                        this.props.client.id > 0 && (
                                            <span>
                                                <MotifIconButton
                                                    aria-label="TB Upload"
                                                    type="button"
                                                    className={
                                                        this.state.activeMotifIconId == 1
                                                            ? 'motif-icon-active'
                                                            : ''
                                                    }
                                                    onClick={navToMultipleUpload}
                                                >
                                                    <MotifIcon
                                                        src={fileIcFileUpload24px}
                                                        title="TB Upload"
                                                    />
                                                </MotifIconButton>
                                            </span>
                                        )}

                                    {showPeriodFilesNavLink && !this.props.clientIsAudit && (
                                        <span>
                                            <MotifIconButton
                                                aria-label="File Management"
                                                type="button"
                                                className={
                                                    this.state.activeMotifIconId == 2
                                                        ? 'motif-icon-active'
                                                        : ''
                                                }
                                                onClick={navToFiles}
                                            >
                                                <MotifIcon
                                                    src={actionIcDescription24px}
                                                    title="File Management"
                                                />
                                            </MotifIconButton>
                                        </span>
                                    )}

                                    <span>
                                        <MotifIconButton
                                            aria-label="Notifications"
                                            type="button"
                                            className={
                                                this.props.showNotifications
                                                    ? 'motif-icon-active'
                                                    : ''
                                            }
                                            onClick={handleNotificationToggleDisplay}
                                        >
                                            <div style={{ position: 'relative' }}>
                                                <div style={{ width: '100%', height: '100%' }}>
                                                    <MotifIcon
                                                        src={socialIcNotificationsNone24px}
                                                        title="Notifications"
                                                    />
                                                </div>
                                                <div>
                                                    {(Array.isArray(this.props.notifications) &&
                                                        this.props.notifications.length > 0 && (
                                                            <Label color="red" circular floating>
                                                                {this.props.notifications.length}
                                                            </Label>
                                                        )) ||
                                                        null}
                                                </div>
                                            </div>
                                        </MotifIconButton>
                                    </span>

                                    {isSysAdmin && (
                                        <span>
                                            <MotifIconButton
                                                aria-label="Manage System Administrators"
                                                type="button"
                                                data-testId="btnMngSysAdmin"
                                                onClick={navToSysAdmin} >
                                                <MotifIcon src={actionIcSupervisorAccount24px} title="Manage System Administrators" />
                                            </MotifIconButton>
                                        </span>
                                    )}
                                    <span style={{ float: 'right' }}>
                                        <UserAvatarComponent
                                            isUserDropdownOpen={this.state.isUserDropdownOpen}
                                            handleToggleUserDropdownWhenClickedOutside={handleToggleUserDropdownWhenClickedOutside}
                                            handleToggleUserDropdown={handleToggleUserDropdown}
                                            userName={name}
                                            userEmail={userId}
                                            userRole={userRole}
                                            userProfilePictureUrl={this.props.userProfilePictureUrl}
                                            navToLogoff={navToLogoff}>
                                        </UserAvatarComponent>
                                    </span >
                                </span >
                            )
                            }
                        </div>
                    }
                ></MotifHeader >
            </div >
        );
    }
}

NavBar.propTypes = {
    user: PropTypes.object,
    client: PropTypes.object,
    reit: PropTypes.object,
    period: PropTypes.object,
    currentUserAuthorizations: PropTypes.array,
    children: PropTypes.element,
    notifications: PropTypes.array,
    notificationActions: PropTypes.object,
    trialBalanceActions: PropTypes.object,
    showNotifications: PropTypes.bool.isRequired,
    authentication: PropTypes.string,
    microsoftGraphApiActions: PropTypes.object,
    userProfilePictureUrl: PropTypes.string
};

NavBar.contextTypes = {
    router: PropTypes.object
};

/**
 * Maps items from state to properties of the component
 * @param {Object} state The state
 * @param {Object} ownProps The properties belonging to this component
 * @returns {Object} An object containing properties that the component can access
 */
function mapStateToProps(state, ownProps) {
    let result = {
        client: state.client,
        reit: state.reit,
        period: state.period,
        currentUserAuthorizations: state.currentUserAuthorizations,
        children: ownProps.children,
        notifications: state.notifications,
        authentication: state.authentication,
        showNotifications: state.showNotifications,
        clientIsAudit: state.client && state.client.isAudit,
        userProfilePictureUrl: state.userProfilePicture
    };

    return result;
}

/**
 * Binds actions to the dispatcher
 * @param {Object} dispatch The action dispatcher
 * @returns {Object} An object containing properties that the component can access
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(authActions, dispatch),
        notificationActions: bindActionCreators(notificationActions, dispatch),
        trialBalanceActions: bindActionCreators(trialBalanceActions, dispatch),
        microsoftGraphApiActions: bindActionCreators(microsoftGraphApiActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withMsal(NavBar));
