import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { SubmissionError } from "redux-form";
import { Message, Icon, Dropdown, Form } from 'semantic-ui-react';
import Select from 'react-select';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import * as errorCodes from "../constants/errorCodes";
import * as errorResponses from '../constants/errorResponses';
import * as stringHelpers from './stringHelpers';
import * as formParsers from "./formParsers";
import * as arrayHelpers from "./arrayHelpers";
import { useInput } from "./customHooks";
import { email as emailValidator } from './formValidators';
import { actionIcDelete24px, contentIcAdd24px, navigationIcArrowDropDown24px, navigationIcArrowDropUp24px } from '@ey-xd/motif-react/assets/icons';
import * as Constants from '../constants/other';
import {
    MotifLabel,
    MotifFormField,
    MotifInput,
    MotifSelect,
    MotifOption,
    MotifErrorMessage,
    MotifRadioButton,
    MotifToast,
    MotifCheckbox,
    MotifButton,
    MotifButtonGroup,
    MotifIcon,
    MotifTextArea
} from '@ey-xd/motif-react';

export const generateSubmissionError = errorResponse => {
    const errorData = {};
    if (errorResponse && errorResponse.errorCode === errorCodes.VALIDATION_FAILED) {
        errorData._error = "Validation failed.  Please check that all required fields are completed.";
        if (Array.isArray(errorResponse.validationErrors)) {
            for (let index = 0; index < errorResponse.validationErrors.length; index++) {
                const item = errorResponse.validationErrors[index];
                errorData[stringHelpers.camelize(item.element)] = item.message;
            }
        }
    } else if (errorResponse && errorResponse.errorCode && errorResponse.message) {
        errorData._error = errorResponse.message;
    } else {
        errorData._error = errorResponses.defaultErrorResponse.message;
    }

    throw new SubmissionError(errorData);
};

export const showSubmissionError = (error, handleDismiss) => {
    if (error) {
        return (<MotifToast className="mt-2 mb-2" variant="error" onClose={handleDismiss}>{error}</MotifToast>);
    } else {
        return (<span />);
    }
};

showSubmissionError.propTypes = {
    error: PropTypes.string,
    handleDismiss: PropTypes.func
};

export const renderReactMultiEmail = ({
    input,
    label,
    options,
    name,
    meta: { touched, error, warning },
    myStyle,
    wrapperClass,
    handleChange,
    float,
    domRef,
    emails,
    readonly,
    removeEYEmail=false
}) => {
    if(removeEYEmail)
    {
        let splicedArray = [];
        for (let i = 0; i < emails.length; i++) {
          const splitArray = emails[i].trim().split("@");
          if (
            splitArray[1] &&
            splitArray[1] != Constants.RejectedEyDomain &&
            !splitArray[1].includes(Constants.RejectedOtherEYDomain)
          ) {
            splicedArray.push(emails[i]);
          }
        }
        emails = splicedArray;
    }
    
    const { value } = input;
    float = float || "none";
    const getLabelHandler = (email, index, removeEmail) => {
        return (
            <div data-tag key={index} className="ui label">
                {email}
                <Icon
                    data-tag-handle
                    name="delete"
                    title="Remove this Email"
                    onClick={() => removeEmail(index)}
                />
            </div>
        );
    };

    const defaultChangeHandler = (e) => {
        input.onChange(e);
    };

    return (
        <div className={wrapperClass} style={{ float }}>
            <div id={input.name}>
                {(label && <label htmlFor={input.name}>{label}</label>) || null}
                {!readonly && (
                    <ReactMultiEmail
                        style={myStyle}
                        emails={emails || input.value}
                        onChange={handleChange || defaultChangeHandler}
                        placeholder="email"
                        ref={domRef}
                        getLabel={getLabelHandler}
                    />
                )}
                {readonly && (emails || input.value).join("; ")}
                {touched &&
                    ((error && (
                        <span className="text-danger" htmlFor={input.name}>
                            {error}
                        </span>
                    )) ||
                        (warning && (
                            <span className="text-warning" htmlFor={input.name}>
                                {warning}
                            </span>
                        )))}
            </div>
        </div>
    );
};

const motifMultiEmailData = {
    unexpandLast: undefined,
    expandId: undefined
};

export const renderMotifMultiEmail = ({
    input,
    label,
    options,
    name,
    meta: { touched, error, warning },
    myStyle,
    wrapperClass,
    handleChange,
    float = "none",
    domRef,
    emails,
    readonly,
    buttonStyle = {
        //minWidth: '250px',
        textAlign: 'left',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        border: 'unset'
    },
    onExpand = () => { },
    onCollapse = () => { }
}) => {
    const { value, onChange } = input;
    const emailArray = emails || value || [];

    const removeEmail = function (mail) {
        const newEmailSet = emailArray.filter((m) => m !== mail);
        onExpand(newEmailSet.length);
        onChange(newEmailSet);
    };

    const [expanded, setExpanded] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState();
    const [expandId] = useState(Math.random().toString());

    useEffect(() => {
        if (expanded) {
            if (
                typeof motifMultiEmailData.unexpandLast === "function" &&
                motifMultiEmailData.expandId !== expandId
            ) {
                motifMultiEmailData.unexpandLast();
            }

            motifMultiEmailData.unexpandLast = () => setExpanded(false);
            motifMultiEmailData.expandId = expandId;

            setTimeout(() => {
                onExpand(emailArray.length);
            });
        } else {
            onCollapse();
        }
    }, [expanded]);

    const shortenEmailText = (text, maxLength = 30) => {
        const [beforeAtSymbol, ...rest] = text.split("@");

        const excessLength = text.length - maxLength;

        if (excessLength <= 0 || beforeAtSymbol.length <= 5) {
            return text;
        } else {
            const newLength = Math.max(beforeAtSymbol.length - excessLength, 5);

            return [
                beforeAtSymbol.substring(0, newLength) + "...",
                ...rest,
            ].join("@");
        }
    };

    const inputProps = useInput('');

    const buttonText =
        emailArray.length === 0 ?
            `Add Email` :
            emailArray.length === 1 ?
                emailArray[0] :
                `${emailArray[0]} (+${emailArray.length - 1})`;

    const unexpandedButton = (
        <MotifButton
            variant="secondary"
            style={{ ...buttonStyle, border: '1px' }}
            onClick={() => setExpanded(true)}
            type="button"
            title={buttonText}>
            {shortenEmailText(buttonText)}
            <MotifIcon src={navigationIcArrowDropDown24px} />
        </MotifButton>
    );

    const addEmail = function (event, isKeyUpEvent = true) {
        if (isKeyUpEvent && event.keyCode === 27) {
            setExpanded(false);
            return;
        }

        if (isKeyUpEvent && event.keyCode !== 13) {
            return;
        }

        if (
            !inputProps.value ||
            typeof emailValidator(inputProps.value) !== "undefined"
        ) {
            return;
        }

        const newMailSet = new Set([inputProps.value, ...emailArray]);
        inputProps.setValue("");
        const newEmailSet = [...newMailSet];
        onExpand(newEmailSet.length);
        onChange(newEmailSet);

    };

    const buttons = emailArray.map((email, index, arr) => {
        const borderStyle = "black 1px solid";

        const borders = {
            borderLeft: borderStyle,
            borderRight: borderStyle,
        };

        if (index === 0) {
            borders.borderTop = borderStyle;
        }

        if (arr.length - 1 === index) {
            borders.borderBottom = borderStyle;
        }

        // const isHovered = hoveredIndex === index;

        const style = {
            ...buttonStyle,
            ...borders
        };

        return (
            <div className="row">
                <div className="col">
                    <MotifButton
                        style={style}
                        onClick={() => removeEmail(email)}
                        // onMouseEnter={() => setHoveredIndex(index)}
                        // onMouseLeave={() => setHoveredIndex()}
                        variant="secondary"
                        type="button"
                        title={email}>
                        {shortenEmailText(email, 25)}
                        <MotifIcon
                            src={actionIcDelete24px} // style={{ opacity: isHovered ? 1 : 0 }}
                        />
                    </MotifButton>
                </div>
            </div>
        );
    });

    // const addIsDisabled =
    //     !inputProps.value ||
    //     typeof emailValidator(inputProps.value) !== "undefined";

    const addInput = (
        <div className="d-flex flex-row w-100">
            <MotifFormField className="w-100" style={{ marginBottom: 0 }}>
                <MotifLabel>Add new Email</MotifLabel>
                <MotifInput
                    {...inputProps}
                    onKeyUp={addEmail}
                    onBlur={(event) => addEmail(event, false)}
                    autofocus
                />
            </MotifFormField>
            {/* <MotifButtonGroup size="small"> */}
            {/* <MotifButton
                    onClick={(event) => addEmail(event, false)}
                    variant="primary-alt"
                    style={{ minWidth: '1px' }}
                    type="button"
                    title="Add typed e-mail"
                    disabled={addIsDisabled}>
                    <MotifIcon src={contentIcAdd24px} />
                </MotifButton> */}
            <MotifButton
                variant="primary-alt"
                type="button"
                style={{ minWidth: '1px' }}
                className="ml-auto"
                onClick={() => setExpanded(false)}>
                <MotifIcon src={navigationIcArrowDropUp24px} />
            </MotifButton>
            {/* </MotifButtonGroup> */}
        </div>
    );

    return (
        <div className={wrapperClass} style={{ float }}>
            <div id={input.name}>
                {(label && <label htmlFor={input.name}>{label}</label>) || null}
                {expanded ? [addInput, ...buttons] : unexpandedButton}
                {touched &&
                    ((error && (
                        <span className="text-danger" htmlFor={input.name}>
                            {error}
                        </span>
                    )) ||
                        (warning && (
                            <span className="text-warning" htmlFor={input.name}>
                                {warning}
                            </span>
                        )))}
            </div>
        </div>
    );
};

renderReactMultiEmail.propTypes = {
    input: PropTypes.object,
    label: PropTypes.string,
    options: PropTypes.array,
    name: PropTypes.string,
    meta: PropTypes.object,
    myStyle: PropTypes.object,
    handleChange: PropTypes.func,
    float: PropTypes.string,
    domRef: PropTypes.func
};

const MOTIF_GREY = '#E1E1E6';
const MOTIF_LIGHT = '#F6F6FA'


export const renderReactSelect = ({ input, label, options, name, meta: { touched, error, warning }, float, disabled, wrapperStyle, clearable }) => {
    float = float || "none";
    disabled = !!disabled;
    clearable = clearable === undefined ? true : clearable;
    wrapperStyle = wrapperStyle || null;

    const reactSelectOnChange = (e) => {
        if (e) {
            return input.onChange(e);
        } else {
            return input.onChange('');
        }
    };

    const reactSelectOnBlur = () => {
        return input.onBlur(input.value);
    };

    const [isFocused, setIsFocused] = useState(false);
    const [hasValue, setHasValue] = useState(false);

    const selectStyles = {
        option: (provided, state) => {
            return {
                ...provided,
                backgroundColor:
                    state.isFocused || state.isSelected ? MOTIF_GREY
                        : 'white',
                color: 'black'
            };
        },
        control: (provided, state) => {
            setIsFocused(state.isFocused);
            setHasValue(state.hasValue);
            return {
                ...provided,
                backgroundColor: MOTIF_LIGHT,
                border: state.isFocused ? '1px solid #ffe600' : 'none',
                borderBottom: touched && (error || warning)
                    ? '1px solid red'
                    : '1px solid black',
                borderRadius: 0,
                gridColumn: 1,
                gridRow: 1
            };
        },
        singleValue: (provided, state) => ({ ...provided, color: disabled ? '#6e6e79' : '#2e2e38' })
    }

    const grid1_1 = {
        gridColumn: 1,
        gridRow: 1
    };

    const labelIsUp = isFocused || hasValue;

    const labelStyle = {
        ...grid1_1,
        padding: 0,
        margin: 0,
        marginLeft: labelIsUp ? '0.8rem' : '1rem',
        transform: labelIsUp ? 'none' : 'translateY(1rem)',
        fontSize: labelIsUp ? '90%' : '100%'
    };

    const placeholder = labelIsUp ? input.placeholder : '';

    return (
        <div style={{ float }}>
            <div style={{ display: 'grid', backgroundColor: labelIsUp ? MOTIF_GREY : MOTIF_LIGHT }}>
                {label && <label htmlFor={name} style={labelStyle}>{label}</label>}
                <div style={grid1_1}>
                    <Select
                        {...input}
                        name={name}
                        placeholder={placeholder}
                        options={options}
                        defaultValue={input}
                        onChange={reactSelectOnChange}
                        onBlur={reactSelectOnBlur}
                        onBlurResetsInput={false}
                        isClearable={clearable}
                        isDisabled={disabled}
                        aria-label={label ? label : name}
                        wrapperStyle={wrapperStyle}
                        styles={selectStyles}
                    />
                </div>
            </div>
            {
                touched &&
                ((error && <MotifErrorMessage htmlFor={input.name}>{error}</MotifErrorMessage>) ||
                    (warning && <MotifErrorMessage htmlFor={input.name}>{warning}</MotifErrorMessage>))
            }
        </div>
    );
};

renderReactSelect.propTypes = {
    input: PropTypes.object,
    options: PropTypes.array,
    name: PropTypes.string,
    meta: PropTypes.object,
    float: PropTypes.string,
    disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    clearable: PropTypes.bool,
    wrapperStyle: PropTypes.object
};

export const renderReactDropdown = ({
    input, options, name, defaultValue, className,
    meta: { touched, error, warning }, float, disabled,
    label, placeholder, multiple, fluid, selection, search,
    wrapperStyle, fullWidth
}) => {
    float = float || "none";
    disabled = !!disabled;
    wrapperStyle = wrapperStyle || null;
    const reactSelectOnChange = (e, data) => {
        if ((multiple && data.value.length > 0) || (!multiple && data.value)) {
            return input.onChange(data.value);
        } else {
            return input.onChange(multiple ? [] : '');
        }
    };
    const reactSelectOnBlur = () => {
        return input.onBlur(input.value);
    };

    return (<div style={Object.assign({}, { float: float, width: fullWidth ? '100%' : null })} className={className}>
        <div>
            <Form.Dropdown
                {...input}
                label={label}
                placeholder={placeholder}
                multiple={multiple}
                openOnFocus={false}
                fluid={fluid}
                selection={selection}
                search={search}
                name={name}
                options={options}
                value={input.value || defaultValue}
                onChange={reactSelectOnChange}
                onBlur={search ? reactSelectOnBlur : null}
                disabled={disabled}
                style={wrapperStyle}
            />
            {
                touched &&
                ((error && <span className="text-danger" htmlFor={input.name}>{error}</span>) ||
                    (warning && <span className="text-warning" htmlFor={input.name}>{warning}</span>))
            }
        </div>
    </div>
    );
};

renderReactDropdown.propTypes = {
    input: PropTypes.object,
    options: PropTypes.array,
    name: PropTypes.string,
    meta: PropTypes.object,
    float: PropTypes.string,
    disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    wrapperStyle: PropTypes.object
};

export const renderField = ({
    input,
    label,
    type,
    placeholder,
    meta: { touched, error, warning },
    maxLength,
    children,
    float,
    fullWidth,
    disabled,
    isPercentOwnership,
    inputClassName,
    labelLocationOnRight,
    selectStyle,
    id,
    dataTestId,
    isRequiredField
}) => {
    float = float || "none";
    disabled = !!disabled;

if(isRequiredField)
{
    label+=" (Required)"
}

    const reactSelectOnChange = (e) => {
        if (e) {
            input.onChange(e);
        } else {
            input.onChange('');
        }
    };


    if (type && type.toLowerCase() === "select") {
        return (
            <div style={{ float }}>
                <div>
                    {(label && (<label htmlFor={input.name}>{label}</label>)) || null}
                    <select {...input} onChange={reactSelectOnChange} placeholder={placeholder} disabled={disabled} style={selectStyle}>
                        {children}
                    </select>
                    {touched &&
                        ((error && <span className="text-danger" htmlFor={input.name}>{error}</span>) ||
                            (warning && <span className="text-warning" htmlFor={input.name}>{warning}</span>))}
                </div>
            </div>
        );
    }


    if (isPercentOwnership && input.value) {
        input.value = formParsers.percentageOfOwnership(String(input.value));
    }

    const labelElement = (label && type != 'radio' && (<MotifLabel htmlFor={input.name}>{label}</MotifLabel>)) || null;

    const motifCompatibleTypes =
        ["date", "email", "number", "text", "password", "tel"];

    let inputElement = undefined;

    if (motifCompatibleTypes.includes(type)) {
        inputElement =
            <MotifInput
                {...input}
                type={type}
                maxLength={maxLength}
                placeholder={placeholder}
                disabled={disabled}
                className={inputClassName}
                aria-label={label}
                hideClearButton={true}
                data-testid={dataTestId} />;
    } else if (type == "radio") {
        inputElement =
            <MotifRadioButton  {...input}
                type={type}
                maxLength={maxLength}
                id={id}
                placeholder={placeholder}
                disabled={disabled}
                className={inputClassName}
                onChange={reactSelectOnChange}
                data-testid={dataTestId} >{label}</MotifRadioButton>;
    } else if (type == "checkbox") {
        const { value, onChange, checked } = input;

        inputElement =
            <MotifFormField onClick={disabled ? undefined : () => onChange(!checked)}>
                <MotifCheckbox
                    {...input}
                    value={String(value)}
                    disabled={disabled}
                    onChange={() => onChange(!checked)}
                />
            </MotifFormField>;
    } else if(type == "textarea") {
        inputElement = 
            <MotifTextArea
                {...input}
                maxLength={maxLength}
                placeholder={placeholder}
                disabled={disabled}
                className={inputClassName}
                aria-label={label}
            />
    }else {
        inputElement =
            <input {...input}
                type={type}
                aria-label={label}
                maxLength={maxLength}
                placeholder={placeholder}
                disabled={disabled}
                className={inputClassName} />;
    }

    const elements = [];
    if (labelLocationOnRight) {
        elements.push(inputElement);
        elements.push(labelElement);
    } else {
        elements.push(labelElement);
        elements.push(inputElement);
    }

    const errorElements = [];

    if (touched && !!error) {
        errorElements.push(<MotifErrorMessage>{error}</MotifErrorMessage>);
    }

    if (touched && !!warning) {
        errorElements.push(<MotifErrorMessage>{warning}</MotifErrorMessage>);
    }

    return (
        <div style={Object.assign({}, { float: float, width: fullWidth ? '100%' : null })}>
            <MotifFormField>
                {elements.map((x, index) => <React.Fragment key={index}>{x}</React.Fragment>)}
                {errorElements.map((x, index) => <React.Fragment key={"error_" + index}>{x}</React.Fragment>)}
            </MotifFormField>
        </div >
    );
};

renderField.propTypes = {
    input: PropTypes.object,
    label: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    meta: PropTypes.object,
    maxLength: PropTypes.number,
    children: PropTypes.array,
    float: PropTypes.string,
    disabled: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    isRequiredField: PropTypes.bool,
};

export class SingleSelectComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = { shouldRender: true };
       
    }
       componentDidUpdate(prevProps) {
        const { input: { value } } = prevProps;
        // Compare previous options and current options
        if ( !arrayHelpers.arraysEqualComplex(this.props.options, prevProps.options)) {
            // Render dropdown again if setting another value or change in options
            this.setState(
                { shouldRender: false },
                () => this.setState({ shouldRender: true }))
        }
    }

    render() {
        const {
            input: {
                value,
                onChange,
                name
            },
            defaultValue,
            placeholder,
            label,
            showSelectAllButton,
            icons,
            noLabel,
            options = [],
            optionValue = o => o.value,
            optionText = o => o.text,
            optionKey = (o, _) => o.key || optionValue(o),
            meta: { touched, error, warning },
            disabled,
            renderUntilValueSet = false,
            renderUntilOptionsSet = false,
            style = {},
            filter = false,
            testingData,
        } = this.props;

        const parsers = {
            'string': v => v,
            'number': v => parseFloat(v),
            'boolean': v => v === 'true'
        }
       
        const parser = parsers[typeof value] || (v => v);
        const _onChange = v => onChange(parser(v));
        const errorElements = [];

        if (touched && !!error) {
            errorElements.push(
                <MotifErrorMessage key="__error">{error}</MotifErrorMessage>);
        }

        if (touched && !!warning) {
            errorElements.push(
                <MotifErrorMessage key="__warn">{warning}</MotifErrorMessage>);
        }

        const hasValue = v =>
            typeof v !== 'undefined' &&
            v !== null &&
            (!!value || typeof value === 'number');

        const hasOptions = opts => Array.isArray(opts) && opts.length > 0;

        const shouldRender =
            this.state.shouldRender &&
            (!renderUntilValueSet || hasValue(value)) &&
            (!renderUntilOptionsSet || hasOptions(options));

        const testingDataLabel = testingData ? testingData.label : '';
        const testingDataDiv = testingData ? testingData.div : '';

        return shouldRender ? (
            <MotifFormField style={style}>
                {!noLabel && <MotifLabel htmlFor={name} data-testid={testingDataLabel}>{label}</MotifLabel>}
                <MotifSelect
                    name={name}
                    value={String(defaultValue || value)}
                    onChange={_onChange}
                    placeholder={placeholder}
                    aria-label={label}
                    data-testid={testingDataDiv}
                    disabled={disabled}
                    filter={filter}
                    icons={icons}
                    showSelectAllButton={showSelectAllButton}
                    >
                    
                    {options.map((opt, idx) =>
                        <MotifOption
                            value={String(optionValue(opt, idx))}
                            key={String(optionKey(opt, idx))}
                            optionid={String(optionKey(opt, idx))}
                            data-testvalue={String(optionText(opt, idx))}
                            aria-label={String(optionText(opt, idx))}>
                            {String(optionText(opt, idx)).replace(/\s+/g, "\u2800")}
                        </MotifOption>)}
                </MotifSelect>
                {errorElements}
            </MotifFormField>) :
            <MotifInput readOnly value="Loading..." hideClearButton={true} style={style} />;
    }
}

export class MultipleSelect extends React.Component {
    render() {
        const {
            input: {
                value,
                onChange,
                name
            },
            placeholder,
            label,
            showSelectAllButton,
            options = [],
            optionValue = o => String(o),
            optionText = o => String(o),
            optionKey = (o, idx) => `__opt_${String(o)}_${idx}`,
            testingData,
            meta: { touched, error, warning }
        } = this.props;

        const errorElements = [];

        if (touched && !!error) {
            errorElements.push(
                <MotifErrorMessage key="__error">{error}</MotifErrorMessage>);
        }

        if (touched && !!warning) {
            errorElements.push(
                <MotifErrorMessage key="__warn">{warning}</MotifErrorMessage>);
        }

        const testingDataLabel = testingData ? testingData.label : '';
        const testingDataDiv = testingData ? testingData.div : '';

        return (
            <MotifFormField>
                <MotifLabel data-testid={testingDataLabel}  htmlFor={name}>{label}</MotifLabel>
                <MotifSelect
                    value={value}
                    multiple
                    name={name}
                    onChange={onChange}
                    data-testid={testingDataDiv}
                    showSelectAllButton={showSelectAllButton}
                    placeholder={placeholder}>
                    {options.map((opt, idx) =>
                        <MotifOption
                            value={String(optionValue(opt, idx))}
                            key={String(optionKey(opt, idx))}
                            optionid={String(optionKey(opt, idx))}
                            data-testvalue={String(optionText(opt, idx))}
                            aria-label={String(optionText(opt, idx))}>
                            {String(optionText(opt, idx)).replace(/\s+/g, "\u2800")}
                        </MotifOption>)}
                </MotifSelect>
                {errorElements}
            </MotifFormField>);
    }
}